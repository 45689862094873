import { ServiceInfoModel } from '../diagnostic-service/service-info.model';

export class StateChart {
    constructor(
        public id: string,
        public odxId: string,
        public shortName?: string,
        public longName?: string,
        public desc?: string,
        public states: State[] = new Array<State>(),
        public transitions: Transition[] = new Array<Transition>()) {}
}

export class State {
    constructor(
        public id: string,
        public odxId: string,
        public shortName?: string,
        public longName?: string,
        public desc?: string,
        public start?: boolean) {}
}

export class Transition {
    public services: ServiceTransitionInfo[] = new Array<ServiceTransitionInfo>();

    constructor(
        public id: string,
        public odxId: string,
        public shortName?: string,
        public longName?: string,
        public sourceState?: State,
        public targetState?: State) {}
}

export class ServiceTransitionInfo {
    odxId: string;
    name: string;
}