import { SpecificationVersion } from '../../../../../app-model/specification-version';
import { Specification } from '../../../../../app-model/specification';

export class SpecificSpecificationVersion {

  constructor(private _specificationVersion: SpecificationVersion) {
    }

  get specificationVersion(): SpecificationVersion {
        return this._specificationVersion;
    }

  get specification(): Specification {
        return this._specificationVersion.specification;
    }
}
