import { Component, EventEmitter, Output } from '@angular/core';
import { ScommFileImporterService } from 'app/app-services/scomm-file-importer.service';
import { SharedFilesEventsService } from 'app/app-services/shared-files-events.service';
import { SharedFilesService } from 'app/app-services/shared-files.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { tap } from 'rxjs/operators';

import { ScommFileType, ScommSharedFile, SharedFile, SharedFileType } from '../../app-model/legacy-file';
import { ScommSharedFileVersion } from '../../app-model/scomm-shared-file-version';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-create-shared-file',
  templateUrl: './create-shared-file.component.html',
  styleUrls: ['./create-shared-file.component.css']
})
export class CreateSharedFileComponent extends DialogBase {

  @Output()
  fileCreated: EventEmitter<string> = new EventEmitter();

  loading: boolean;
  modalClosing: EventEmitter<void> = new EventEmitter<void>();
  fileSpecificationToCreate: SharedFile;
  originalSpecificationFile: ScommSharedFile;
  sharedFileType = SharedFileType.Other;
  alwaysInclude: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  SharedFileType = SharedFileType;


  constructor(private specificationService: SpecificationService,
    private messageService: MessageService,
    private scommFileImporterService: ScommFileImporterService,
    private sharedFilesService: SharedFilesService,
    private sharedFileEventsService: SharedFilesEventsService) {
    super();
  }

  public scommFileType(): ScommFileType {
    switch (this.sharedFileType) {
      case SharedFileType.KeyFile:
        return ScommFileType.KeyFile;
      default:
        return ScommFileType.Other;
    }
  }

  public get hasCorrectDefinition(): boolean {
    return this.fileSpecificationToCreate !== undefined || this.fileSpecificationToCreate !== null;
  }

  setDefaultValues() {
    this.fileSpecificationToCreate = undefined;
    this.originalSpecificationFile = undefined;
  }

  fileChanged(e: Event) {
    this.loading = true;
    const target: HTMLInputElement = e.target as HTMLInputElement;
    if (target.files.length === 1) {
      console.log('importing file ' + target.files[0].name);

      this.fileSpecificationToCreate = new SharedFile();
      this.fileSpecificationToCreate.name = target.files[0].name;

      if (this.scommFileType !== undefined) {
        this.originalSpecificationFile = new ScommSharedFile();
        this.originalSpecificationFile.name = target.files[0].name;
        this.originalSpecificationFile.fileType = this.scommFileType();

        this.scommFileImporterService.getBase64(target.files[0]).pipe(tap(data => {
          this.originalSpecificationFile.data = data;
          this.loading = false;
        })).subscribe();
      } else {
        this.messageService.dispatchErrorMessage('The file ' + this.fileSpecificationToCreate.name +
          ' is not supported by Dcode. Only files with Ecu/Server/Security Access files are supported');
      }
    }
  }

  createFileSpecification() {
    if (this.fileSpecificationToCreate) {
      this.sharedFilesService.createSharedFileSpecification({
        name: this.fileSpecificationToCreate.name, id: 0, alwaysInclude: this.alwaysInclude,
        fileType: this.scommFileType()
      }).subscribe(specification => {
        this.originalSpecificationFile.fileType = this.scommFileType();
        const initialFileVersion = new ScommSharedFileVersion();
        initialFileVersion.file = this.originalSpecificationFile;
        initialFileVersion.labels = [];
        initialFileVersion.sharedFileId = specification.id;

        this.sharedFilesService.createSharedFileVersion(initialFileVersion).subscribe(versionPreview => {
          this.sharedFilesService.getSharedFileSpecificationVersion(versionPreview.id).subscribe(version => {
            this.fileCreated.next(this.fileSpecificationToCreate.name);
            this.sharedFileEventsService.sharedFileSpecificationSelected.next(specification);
            this.sharedFileEventsService.sharedFileSpecificationVersionSelected.next(version);
            this.close();
          });
        });
      });
    }
  }

  cancel() {
    this.close();
  }

  apply() {
    this.createFileSpecification();
  }

  close() {
    this.modalClosing.emit();
    this.setDefaultValues();
  }
}
