<div class="sdds-row" *ngIf="parameter && parameter.model">
    <div *ngIf="!isValidParameter()"
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <div class="sdds-banner sdds-banner-error">
            <div class="sdds-banner-body">
                <b>Parameter has invalid data. Changes will not be saved.</b>
                <ul>
                    <li>Make sure that the parameter is not overlapping with other parameter(s)</li>
                    <li>Check that the parameter size is enough to store the specified constant or internal value type.
                    </li>
                    <li>Check definitions of the computational methods.</li>
                </ul>
            </div>
        </div>
    </div>

    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16 overflow-hidden">
        <div class="sdds-row">
            <div
                class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
                <app-parameter-info [parameter]="parameter" [isOdxBasedSpecification]="isOdxBasedSpecification"
                    (parameterTypeChanged)="onParameterTypeChanged()" (parameterChanged)="notifyParameterChanged()"   [isAllowed]="isAllowed">
                </app-parameter-info>
            </div>

            <div *ngIf="!isReservedParameter"
              [ngClass]="{'sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 mb-16' : hasPhysicalValueConstraint}"
              [ngClass]="{'sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 mb-16' : !hasPhysicalValueConstraint}">
              <app-physical-value [parameter]="parameter" [isValueParameter]="isValueParameter"
                [isConstantParameter]="isConstantParameter" [isOdxBasedSpecification]="isOdxBasedSpecification"
                (parameterChanged)="notifyParameterChanged($event)"
                (physicalDataTypeChanged)="onPhysicalDataTyeChanged()" [isAllowed]="isAllowed"></app-physical-value>
            </div>

            <div *ngIf="!isReservedParameter"
              [ngClass]="{'sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 mb-16' : hasInternalValueConstraint}"
              [ngClass]="{'sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 mb-16' : !hasInternalValueConstraint}">
                <app-internal-value [parameter]="parameter" [isReservedParameter]="isReservedParameter"
                  [isValueParameter]="isValueParameter" [isOdxBasedSpecification]="isOdxBasedSpecification"
                  (parameterChanged)="notifyParameterChanged($event)"
                  (internalDataTypeChanged)="onInternalDataTypeChanged()"  [isAllowed]="isAllowed"></app-internal-value>
            </div>
        </div>

        <div class="sdds-row mb-16" *ngIf="isValueParameter || isConstantParameter">
            <div
                class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div class="sdds-checkbox-item">
                    <input class="sdds-form-input" type="checkbox" id="cbxEnableInternalToPhysCompMethod"
                        [(ngModel)]="hasInternalComputationalMethod" [disabled]="isOdxBasedSpecification || !isAllowed">
                    <label class="sdds-form-label" for="cbxEnableInternalToPhysCompMethod">Has internal to physical
                        computational method</label>
                </div>
            </div>

            <div
                class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div class="sdds-checkbox-item">
                    <input class="sdds-form-input" type="checkbox" id="cbxEnablePhysToInternalCompMethod"
                        [(ngModel)]="hasPhysicalComputationalMethod" [disabled]="isOdxBasedSpecification || !isAllowed">
                    <label class="sdds-form-label" for="cbxEnablePhysToInternalCompMethod">Has physical to internal
                        computational method</label>
                </div>
            </div>
        </div>

        <div class="overflow-hidden">
            <app-computational-method-view *ngIf="hasInternalComputationalMethod"
                [isOdxBasedSpecification]="isOdxBasedSpecification" [parameter]="parameter"
                [computationalMethod]="parameter.scalingData.model.internalToPhysicalComputationalMethod"
                [isInternalToPhysical]="true" (parameterChanged)="notifyParameterChanged($event)" [isAllowed]="isAllowed">
            </app-computational-method-view>
            <app-computational-method-view *ngIf="hasPhysicalComputationalMethod"
                [isOdxBasedSpecification]="isOdxBasedSpecification" [parameter]="parameter"
                [computationalMethod]="parameter.scalingData.model.physicalToInternalComputationalMethod"
                [isInternalToPhysical]="false" (parameterChanged)="notifyParameterChanged($event)" [isAllowed]="isAllowed">
            </app-computational-method-view>

            <app-position-length [parameter]="parameter" (parameterChanged)="notifyParameterChanged()"
                [isOdxBasedSpecification]="isOdxBasedSpecification" [isAllowed]="isAllowed"></app-position-length>
        </div>
    </div>
</div>
