import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthService } from './services/auth.service';
import { AuthguardService } from './services/authguard.service';
import { AuthorizationFailedComponent } from './authorization-failed/authorization-failed.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
  ],
  declarations: [
    AuthCallbackComponent,
    AuthorizationFailedComponent
  ],
  providers: [
    AuthService,
    AuthguardService
  ],
  exports: [
    AuthCallbackComponent,
    AuthorizationFailedComponent
  ]
})
export class AuthenticationModule { }
