import { TreeNode } from './tree-node';
import { TreeService } from '../service/tree.service';
import { ScommSharedFileVersion } from '../../../../../app-model/scomm-shared-file-version';

export class SharedFileVersionTreeNode extends TreeNode {
    fileVersion: ScommSharedFileVersion;

    constructor(fileVersion: ScommSharedFileVersion, treeService: TreeService) {
        super(fileVersion.revision + '', treeService);
        this.fileVersion = fileVersion;
    }

    get isSharedFileSpecificationVersionTreeNode() {
        return true;
    }

    get icon(): string {
        return 'description';
    }
}
