<div style="margin-top: -20px;">
  <div *ngFor="let expression of expressions">
    <div>
      <app-interval-comparison-expression-item style="margin-top: 10px;"
        *ngIf="hasCompareOperatorOfIntervalType(expression) && hasCompareValueNode(expression)"
        [legacyModeEnabled]="legacyModeEnabled" [expressionModel]="expression" [isReadOnly]="isReadOnly" [isAllowed]="isAllowed">
      </app-interval-comparison-expression-item>
      <app-value-comparison style="margin-top: 10px;"
        *ngIf="hasCompareValueNode(expression) && !hasCompareOperatorOfIntervalType(expression)"
        [legacyModeEnabled]="legacyModeEnabled" [expressionModel]="expression" [isReadOnly]="isReadOnly" [isAllowed]="isAllowed">
      </app-value-comparison>
      <app-operator-comparison style="margin-top: 10px;"
        *ngIf="expression.nodeType === ServerIdentificationExpressionNodeType.AndOperator"
        [legacyModeEnabled]="legacyModeEnabled" [expressionModel]="expression" [isReadOnly]="isReadOnly"
        [copyEnabled]="copyEnabled" [reorderEnabled]="reorderEnabled" [isAllowed]="isAllowed"></app-operator-comparison>
      <app-operator-comparison style="margin-top: 10px;"
        *ngIf="expression.nodeType === ServerIdentificationExpressionNodeType.OrOperator"
        [legacyModeEnabled]="legacyModeEnabled" [expressionModel]="expression" [isReadOnly]="isReadOnly"
        [copyEnabled]="copyEnabled" [reorderEnabled]="reorderEnabled" [isAllowed]="isAllowed"></app-operator-comparison>
      <app-can-address-comparison style="margin-top: 10px;"
        *ngIf="expression.nodeType === ServerIdentificationExpressionNodeType.CanAddressCompareOperand"
        [expressionModel]="expression" [isReadOnly]="isReadOnly" [isAllowed]="isAllowed"></app-can-address-comparison>
      <app-execution-mode-comparison style="margin-top: 10px;"
        *ngIf="expression.nodeType === ServerIdentificationExpressionNodeType.ExecutionModeCompareOperand"
        [expressionModel]="expression" [isReadOnly]="isReadOnly" [isAllowed]="isAllowed"></app-execution-mode-comparison>
      <app-special-comparison style="margin-top: 10px;"
        *ngIf="expression.nodeType === ServerIdentificationExpressionNodeType.SpecialCompareOperator"
        [expressionModel]="expression" [isReadOnly]="isReadOnly" [isAllowed]="isAllowed"></app-special-comparison>
      <app-does-not-exist-comparison style="margin-top: 10px;"
        *ngIf="expression.nodeType === ServerIdentificationExpressionNodeType.DoesNoExistCompareOperator"
        [expressionModel]="expression" [isReadOnly]="isReadOnly" [isAllowed]="isAllowed"></app-does-not-exist-comparison>
    </div>
  </div>
</div>
