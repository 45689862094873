import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ServerIdentificationCompareOperatorType,
  ServerIdentificationExpressionNodeType,
} from 'app/app-model/server-identification/server-identification.enums';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { DiagnosticFamilyService } from 'app/app-services/diagnostic-family.service';
import { ExpressionNodeHelperService } from 'app/app-services/expression-node-helper.service';
import { IdentificationExpressionItemService } from 'app/app-services/identification-expression-item.service';
import { SpecificationService } from 'app/app-services/specification-service';
import {
  ServerIdentificationCategoryItemComponent,
} from 'app/data-categories/server-identification/server-identification-category-item';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { Subscription } from 'rxjs';

import { DiagnosticProtocolType, ProductAffinityType } from '../../app-model/enums';
import { DiagnosticFamilyModel, GenerationModel } from '../../app-model/server-identification/diagnostic-family.model';
import { IdentificationExpressionService } from '../../app-services/identification-expression.service';
import { SpecificationStatusService } from '../../app-services/specification-status.service';
import { CategoryComponentBaseComponent } from '../../modules/shared/model/category-component-base';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { CategoryItem } from 'app/app-model/category-item';
import { NavigationService } from 'app/app-services/navigation.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-server-identification',
  templateUrl: './server-identification.component.html',
  styleUrls: ['./server-identification.component.css']
})
export class ServerIdentificationComponent extends CategoryComponentBaseComponent<ServerIdentificationCategoryItemComponent> implements OnInit, OnDestroy {
  @ViewChild('activateMoveExpressionConfirm', { static: true }) moveExpressionsConfirmDiag: ElementRef;

  _diagnosticFamilyService: DiagnosticFamilyService;
  _serveridentificationItem: ServerIdentificationCategoryItemComponent;
  _generationSubscription: Subscription;
  availableGenerations: Array<GenerationModel>;
  unavailableGenerations: Array<GenerationModel>;

  generationChangedEventEmitter: EventEmitter<string> = new EventEmitter<string>();
  movingExpressions = false;
  moveToOperatorPopupActive = false;
  _families: Array<DiagnosticFamilyModel> = [];
  unavailableFamilies: Array<DiagnosticFamilyModel>;

  lastExecutedAddExpressionCommand: () => void;


  get canCreateExpressionNode(): boolean {
    return !this.serveridentificationItem ? false : !this.expressionNodeCreatorService.isOperator(this.serveridentificationItem.model.expression);
  }

  get serveridentificationItem(): ServerIdentificationCategoryItemComponent {
    return this._serveridentificationItem;
  }

  set serveridentificationItem(serveridentificationItem: ServerIdentificationCategoryItemComponent) {
    this._serveridentificationItem = serveridentificationItem;
    if (this._generationSubscription != null) {
      this._generationSubscription.unsubscribe();
    }

    if (this.serveridentificationItem && this.serveridentificationItem.model) {
      if (this.specificationStatusService.isInRelease(this.specificationService.currentSpecificationVersion)) {
        this._diagnosticFamilyService.getGenerationForReleasedSpecification(this.serveridentificationItem.model.specificationVersion.id).subscribe({
          next: (generations) => {
            this.availableGenerations = generations;
          },
          error: (error) => {
            this.messageService.dispatchErrorMessageFromApi(error);
          }
        });
      } else {
        if (this.serveridentificationItem.model.diagnosticFamily) {
          this._generationSubscription = this._diagnosticFamilyService.getGenerations(this.serveridentificationItem.model.diagnosticFamily).subscribe({
            next: (generations) => {
              this.availableGenerations = generations.filter(f => f.isSelectable) as GenerationModel[];
              this.unavailableGenerations = generations.filter(f => !f.isSelectable) as GenerationModel[];
            },
            error: (error) => {
              this.messageService.dispatchErrorMessageFromApi(error);
            }
          });
        }
      }
    }
  }

  constructor(private diagnosticFamilyService: DiagnosticFamilyService, activatedRoute: ActivatedRoute,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    messageService: MessageService,
    navigationService: NavigationService,
    private identificationExpressionService: IdentificationExpressionService,
    private expressionNodeCreatorService: ExpressionNodeHelperService,
    private identificationExpressionItemService: IdentificationExpressionItemService,
    private specificationStatusService: SpecificationStatusService,
    private authService:AuthService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.Servers, navigationService);
    this._diagnosticFamilyService = diagnosticFamilyService;
  }

  ngOnInit() {
    if (!this.expressionNodeCreatorService.identificationExpressionService) {
      this.expressionNodeCreatorService.identificationExpressionService = this.identificationExpressionService;
    }

    this.componentSubscriptions.push(this.identificationExpressionService.modified.subscribe({
      next: () => {
        this.onContentChanged();
      }
    }));

    this.componentSubscriptions.push(this.itemChanged.subscribe({
      next: (item) => {
        this.serveridentificationItem = item;
      }
    }));

    this.componentSubscriptions.push(this.identificationExpressionItemService.onDelete.subscribe({
      next: (expression) => {
        if (expression === this.serveridentificationItem.model.expression) {
          this.deleteExpression(expression);
        }
      }
    }));

    if (this.diagnosticFamilyService._families && this.diagnosticFamilyService._families.length === 0) {
      this.diagnosticFamilyService.getFamilies().subscribe({
        next: (families) => {
          this._families = families.filter(family => family.isSelectable) as DiagnosticFamilyModel[];
          this.unavailableFamilies = families.filter(f => !f.isSelectable) as DiagnosticFamilyModel[];
        },
        error: (error) => {
          this.messageService.dispatchErrorMessageFromApi(error);
        }
      });
    } else {
      this._families = this.diagnosticFamilyService._families;
    }
  }

  hasUnavailableFamilies() {
    return this.unavailableFamilies && this.unavailableFamilies.length > 0;
  }

  hasUnavailableGenerations() {
    return this.unavailableGenerations && this.unavailableGenerations.length > 0;
  }
  ngOnDestroy() {
    this.clearSubscriptions();
  }

  clearSubscriptions() {
    if (this._generationSubscription != null) {
      this._generationSubscription.unsubscribe();
    }

    this.componentSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  onDiagnosticFamilySelected() {
    this.componentSubscriptions.push(this.diagnosticFamilyService.getFamilies().subscribe({
      next: (families) => {
        families.forEach(family => {
          if (family.name === this.serveridentificationItem.model.diagnosticFamily) {
            this.availableGenerations = family.generations;
            this.serveridentificationItem.model.generation = null;
            this.serveridentificationItem.notifyServerIdentificationChanged(() => {
              this.generationChangedEventEmitter.emit(this.serveridentificationItem.model.generation);
            });
          }
        });
      },
      error: (err) => {
        this.messageService.dispatchErrorMessageFromApi(err);
      }
    }));
  }

  onContentChanged() {
    if (this.hasValidExpressions()) {
      this.serveridentificationItem.notifyServerIdentificationChanged();
    } else {
      this.messageService.dispatchWarningMessage('You have an invalid expression, changes will not be saved!');
    }
  }

  onGenerationChanged() {
    this.serveridentificationItem.notifyServerIdentificationChanged(() => {
      this.generationChangedEventEmitter.emit(this.serveridentificationItem.model.generation);
      this.syncCategoryItem();
    });
  }

  syncCategoryItem(){
    var index = this.category.items.findIndex(item => item.id === this.serveridentificationItem.id);
    if(index>=0){
      this.category.items[index].displayName = this.serveridentificationItem.displayName;
    }
  }

  onProductAffinityChanged() {
    this.onContentChanged();
  }

  onClassNameChanged() {
    this.onContentChanged();
  }

  onMoveExpressionsConfirm() {
    /**
     * If the user accepts to move the old expressions and the new one to be created do the following
     *
     * 1. Create the new node by executing lastExecutedAddExpressionCommand
     * 2. Move the expressions through th expression node creator service.
     */
    this.movingExpressions = true;
    const currentExpression =
      this.expressionNodeCreatorService.cloneExpression(this.serveridentificationItem.model.expression);
    this.serveridentificationItem.model.expression = undefined;

    if (this.lastExecutedAddExpressionCommand === this.addIntervalComparisonExpressionNodes) {
      const operator = this.expressionNodeCreatorService.createExpressionNode(ServerIdentificationExpressionNodeType.AndOperator, undefined, undefined, 0);
      operator.expressions = [currentExpression];
      this.serveridentificationItem.model.expression = operator;
      this.lastExecutedAddExpressionCommand();

    } else {
      this.lastExecutedAddExpressionCommand();

      const newExpression =
        this.expressionNodeCreatorService.cloneExpression(this.serveridentificationItem.model.expression);

      const operator = this.expressionNodeCreatorService.createExpressionNode(ServerIdentificationExpressionNodeType.AndOperator, undefined, undefined, 0);
      operator.expressions = [currentExpression, newExpression];
      this.serveridentificationItem.model.expression = operator;
    }

    this.movingExpressions = false;
    this.moveToOperatorPopupActive = false;
    this.onContentChanged();
  }

  onMoveExpressionsCancel() {
    this.moveToOperatorPopupActive = false;
  }

  hasValidExpressions(): boolean {
    if (this._serveridentificationItem.hasValidExpressions()) {
      return true;
    }
    return false;
  }

  addValueComparisonNodeEqualTo() {
    this.lastExecutedAddExpressionCommand = this.addValueComparisonNodeEqualTo;
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.EqualTo);
  }

  addValueComparisonNodeLessThan() {
    this.lastExecutedAddExpressionCommand = this.addValueComparisonNodeLessThan;
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.LessThan);
  }

  addValueComparisonNodeLessThanOrEqualTo() {
    this.lastExecutedAddExpressionCommand = this.addValueComparisonNodeLessThanOrEqualTo;
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.LessThanOrEqualTo);
  }

  addValueComparisonNodeGreaterThan() {
    this.lastExecutedAddExpressionCommand = this.addValueComparisonNodeGreaterThan;
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.GreaterThan);
  }

  addValueComparisonNodeGreaterThanOrEqualTo() {
    this.lastExecutedAddExpressionCommand = this.addValueComparisonNodeGreaterThanOrEqualTo;
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.GreaterThanOrEqualTo);
  }

  addValueComparisonExpressionNode(compareOperator: ServerIdentificationCompareOperatorType) {
    if (this.serveridentificationItem.model.expression && this.movingExpressions === false && !this.moveToOperatorPopupActive) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
      this.moveToOperatorPopupActive = true;
    } else {
      this.serveridentificationItem.model.expression =
        this.expressionNodeCreatorService.createExpressionNode(
          this.nodeType,
          { data: '0', dataType: OdxDataType.UInt32 },
          compareOperator,
          0);
      if (!this.movingExpressions) {
        this.onContentChanged();
      }
    }
  }

  addIntervalComparisonExpressionNodes() {
    if (this.serveridentificationItem.model.expression && this.movingExpressions === false && !this.moveToOperatorPopupActive) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
      this.moveToOperatorPopupActive = true;
    } else {
      const leftExpressionNode = this.expressionNodeCreatorService.createExpressionNode(
        this.nodeType,
        { data: '0', dataType: OdxDataType.AsciiString },
        ServerIdentificationCompareOperatorType.GreaterThan,
        0);
      const rightExpressionNode = this.expressionNodeCreatorService.createExpressionNode(
        this.nodeType,
        { data: '0', dataType: OdxDataType.AsciiString },
        ServerIdentificationCompareOperatorType.LessThan,
        1);

      this.addOperatorForInterval(leftExpressionNode, rightExpressionNode);
    }
  }

  rootNodeIsOperatorNode(): boolean {
    if (this.movingExpressions) {
      return true;
    } else if (!this.serveridentificationItem.model.expression || this.serveridentificationItem.model.expression === null) {
      return false;
    } else {
      return this.serveridentificationItem.model.expression.nodeType === ServerIdentificationExpressionNodeType.AndOperator ||
        this.serveridentificationItem.model.expression.nodeType === ServerIdentificationExpressionNodeType.OrOperator;
    }
  }

  addValueEqualsComparisonNode() {
    this.lastExecutedAddExpressionCommand = this.addValueEqualsComparisonNode;

    if (this.serveridentificationItem.model.expression && this.movingExpressions === false && !this.moveToOperatorPopupActive) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
      this.moveToOperatorPopupActive = true;
    } else {
      this.serveridentificationItem.model.expression =
        this.expressionNodeCreatorService.createExpressionNode(this.nodeType,
          { data: '0', dataType: OdxDataType.AsciiString },
          ServerIdentificationCompareOperatorType.EqualTo,
          0);
      if (!this.movingExpressions) {
        this.onContentChanged();
      }
    }
  }

  addValueStartsWithComparisonNode() {
    this.lastExecutedAddExpressionCommand = this.addValueStartsWithComparisonNode;

    if (this.serveridentificationItem.model.expression && this.movingExpressions === false && !this.moveToOperatorPopupActive) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
      this.moveToOperatorPopupActive = true;
    } else {
      this.serveridentificationItem.model.expression =
        this.expressionNodeCreatorService.createExpressionNode(this.nodeType,
          { data: '0', dataType: OdxDataType.AsciiString },
          ServerIdentificationCompareOperatorType.StartsWith,
          0);
      if (!this.movingExpressions) {
        this.onContentChanged();
      }
    }
  }

  addValueContainsComparisonNode() {
    this.lastExecutedAddExpressionCommand = this.addValueContainsComparisonNode;

    if (this.serveridentificationItem.model.expression && this.movingExpressions === false && !this.moveToOperatorPopupActive) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
      this.moveToOperatorPopupActive = true;
    } else {
      this.serveridentificationItem.model.expression =
        this.expressionNodeCreatorService.createExpressionNode(this.nodeType,
          { data: '0', dataType: OdxDataType.AsciiString },
          ServerIdentificationCompareOperatorType.Contains,
          0);
      if (!this.movingExpressions) {
        this.onContentChanged();
      }
    }
  }

  addCanAddressComparisonNode() {
    this.lastExecutedAddExpressionCommand = this.addCanAddressComparisonNode;

    if (this.serveridentificationItem.model.expression && this.movingExpressions === false && !this.moveToOperatorPopupActive) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
      this.moveToOperatorPopupActive = true;
    } else {
      this.serveridentificationItem.model.expression =
        this.expressionNodeCreatorService.createExpressionNode(ServerIdentificationExpressionNodeType.CanAddressCompareOperand,
          { data: '0', dataType: OdxDataType.AsciiString },
          ServerIdentificationCompareOperatorType.EqualTo,
          0);
      if (!this.movingExpressions) {
        this.onContentChanged();
      }
    }
  }

  addExecutingModeComparisonNode() {
    this.lastExecutedAddExpressionCommand = this.addCanAddressComparisonNode;

    if (this.serveridentificationItem.model.expression && this.movingExpressions === false && !this.moveToOperatorPopupActive) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
      this.moveToOperatorPopupActive = true;
    } else {
      this.serveridentificationItem.model.expression =
        this.expressionNodeCreatorService.createExpressionNode(ServerIdentificationExpressionNodeType.ExecutionModeCompareOperand,
          { data: '', dataType: OdxDataType.AsciiString },
          ServerIdentificationCompareOperatorType.EqualTo,
          0);
      if (!this.movingExpressions) {
        this.onContentChanged();
      }
    }
  }

  addSpecialComparisonNode() {
    this.lastExecutedAddExpressionCommand = this.addSpecialComparisonNode;

    if (this.serveridentificationItem.model.expression && this.movingExpressions === false && !this.moveToOperatorPopupActive) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
      this.moveToOperatorPopupActive = true;
    } else {
      this.serveridentificationItem.model.expression =
        this.expressionNodeCreatorService.createExpressionNode(ServerIdentificationExpressionNodeType.SpecialCompareOperator,
          { data: '0', dataType: OdxDataType.AsciiString },
          ServerIdentificationCompareOperatorType.EqualTo,
          0);
      if (!this.movingExpressions) {
        this.onContentChanged();
      }
    }
  }

  addDoesNotExistComparisonNode() {
    this.lastExecutedAddExpressionCommand = this.addDoesNotExistComparisonNode;

    if (this.serveridentificationItem.model.expression && this.movingExpressions === false && !this.moveToOperatorPopupActive) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
      this.moveToOperatorPopupActive = true;
    } else {
      this.serveridentificationItem.model.expression =
        this.expressionNodeCreatorService.createExpressionNode(ServerIdentificationExpressionNodeType.DoesNoExistCompareOperator,
          { data: 'false', dataType: OdxDataType.AsciiString },
          ServerIdentificationCompareOperatorType.EqualTo,
          0);
      this.serveridentificationItem.model.expression.legacyIdentifierCode = 0;
      if (!this.movingExpressions) {
        this.onContentChanged();
      }
    }
  }

  addOperator() {
    this.lastExecutedAddExpressionCommand = this.addOperator;

    if (this.serveridentificationItem.model.expression && this.movingExpressions === false && !this.moveToOperatorPopupActive) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
      this.moveToOperatorPopupActive = true;
    } else {
      this.serveridentificationItem.model.expression =
        this.expressionNodeCreatorService.createExpressionNode(ServerIdentificationExpressionNodeType.AndOperator, undefined, undefined, 0);
      if (!this.movingExpressions) {
        this.onContentChanged();
      }
    }
  }

  addOperatorForInterval(leftExpression: ServeridentificationExpressionModel, rightExpression: ServeridentificationExpressionModel) {
    this.lastExecutedAddExpressionCommand = this.addOperator;

    if (this.serveridentificationItem.model.expression && this.movingExpressions === false && !this.moveToOperatorPopupActive) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
      this.moveToOperatorPopupActive = true;
    } else {
      if (this.movingExpressions) {
        const operator = this.expressionNodeCreatorService.createExpressionNode(ServerIdentificationExpressionNodeType.AndOperator, undefined, undefined, 0);
        operator.expressions = [leftExpression, rightExpression];
        this.serveridentificationItem.model.expression.expressions.push(operator);
      } else {
        this.serveridentificationItem.model.expression =
          this.expressionNodeCreatorService.createExpressionNode(ServerIdentificationExpressionNodeType.AndOperator, undefined, undefined, 0);
        this.serveridentificationItem.model.expression.expressions = [leftExpression, rightExpression];
      }
      if (!this.movingExpressions) {
        this.onContentChanged();
      }
    }
  }

  addValueComparisonNodeInterval() {
    this.addIntervalComparisonExpressionNodes();
    this.lastExecutedAddExpressionCommand = this.addIntervalComparisonExpressionNodes;
  }

  addIntervalLessThanNode() {
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.LessThan);
  }

  addIntervalGreaterThanNode() {
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.GreaterThan);
  }

  deleteExpression(expression: ServeridentificationExpressionModel) {
    this.serveridentificationItem.model.expression = undefined;

    this.onContentChanged();
  }

  get nodeType(): ServerIdentificationExpressionNodeType {
    if (this.specificationService.isLegacyVersion) {
      if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
        return ServerIdentificationExpressionNodeType.LegacyEcuIdentifierCompareOperand;
      } else {
        return ServerIdentificationExpressionNodeType.EcuIdentifierCompareOperand;
      }
    } else {
      return ServerIdentificationExpressionNodeType.EcuIdentifierCompareOperand;
    }
  }

  get availableAffinities(): string[] {
    return Object.keys(ProductAffinityType).filter(d => !this.isNumeric(d));
  }
  public isNumeric(value) {
    // Use the unary plus operator (+) to convert the value to a number
    // and check if it's a finite number
    return !isNaN(value) && !isNaN(parseFloat(value));
  }
  get isEditingAllowed(): boolean {
    return !this.specificationStatusService.isInRelease(this.specificationService.currentSpecificationVersion);
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  get legacyModeEnabled(): boolean {
    if (this.specification && this.specificationService.isLegacyVersion && this.specification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
      return true;
    }
    return false;
  }

  canEditServer():boolean{
    return this.authService.canEditServer();
  }
}
