/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export class ValidationIssue {
    issue: Issue;
    errorDetails: ErrorDetails;
}

export class Issue {
    message: string;
    help: string;
    severity: number;
}

export class ErrorDetails {
    errorObjectType: ErrorObjectType;
    objectName: string;
    objectId: number;
}

export enum ErrorObjectType {
    EcuIdentifier,
    Sequence,
    Reset,
    Fingerprint,
    IoList,
    Io,
    OperationalData,
    Parameter,
    SecurityAccess,
    ServerIdentification,
    IdentificationExpression,
    Service,
    Message,
    Session,
    SpecificationVersion,
    Value,
    FreezeFrame
}
