<sdds-modal [size]="modalSize" selector="#{{modalId}}" prevent>
    <ng-container *ngIf="showHeader">
        <h5 slot="sdds-modal-headline" *ngIf="showTitle">{{title}}</h5>
    </ng-container>

    <div slot="sdds-modal-body" [ngClass]="{'loading':loading}">
        <ng-container *ngTemplateOutlet="itemTemplate ? itemTemplate: defaultItemTemplate;"></ng-container>
    </div>

    <ng-container *ngIf="showFooter">
        <ng-container *ngTemplateOutlet="footerTemplate ? footerTemplate: defaultFooterTemplate"></ng-container>
    </ng-container>
</sdds-modal>

<ng-template #defaultItemTemplate>
    <p>No template defined!</p>
</ng-template>

<ng-template #defaultFooterTemplate>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-primary sdds-btn-md mr-12" (click)="onApply()" [disabled]="!applyEnabled" #applyBtn>{{applyCaption}}</button>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md" appCloseModal [close]="modalClosing" (click)="onCancel()" [disabled]="!cancelEnabled" *ngIf="showCancelButton">{{cancelCaption}}</button>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md hide-element" style="display: none;" appCloseModal [close]="modalClosing" (click)="onCancel()"></button>
</ng-template>