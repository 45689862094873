import { Injectable } from '@angular/core';
import { Label } from 'app/app-model/label/label';
import { SharedFile } from 'app/app-model/legacy-file';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { ScommSharedFileVersion } from '../app-model/scomm-shared-file-version';
import { ApiProxyService } from '../modules/shared/services/api-proxy.service';

@Injectable({
    providedIn: 'root'
})
export class SharedFilesService {

    fileSpecifications: SharedFile[] = [];
    scommSharedFileVersions: ScommSharedFileVersion[] = [];

    constructor(private apiProxy: ApiProxyService) {
    }

    public getSharedFileSpecifications(): Observable<SharedFile[]> {
        return this.apiProxy.get<SharedFile[]>(environment.apiUrl + 'sharedfiles');
    }

    public getSharedFileSpecification(id: number): Observable<SharedFile> {
        return this.apiProxy.get<SharedFile>(environment.apiUrl + 'sharedfiles/' + id);
    }

    public createSharedFileSpecification(fileSpecification: SharedFile): Observable<SharedFile> {
        return this.apiProxy.post<SharedFile>(environment.apiUrl + 'sharedfiles', fileSpecification);
    }

    public deleteSharedFileSpecification(id: number): Observable<any> {
        return this.apiProxy.delete<void>(environment.apiUrl + 'sharedfiles/' + id);
    }

    public getSharedFileSpecificationVersions(sharedFileSpecificationId: number): Observable<ScommSharedFileVersion[]> {
        return this.apiProxy.get<ScommSharedFileVersion[]>(environment.apiUrl + 'sharedfiles/' + sharedFileSpecificationId + '/revisions');
    }

    public getSharedFileSpecificationVersion(fileSpecificationVersionId: number): Observable<ScommSharedFileVersion> {
        return this.apiProxy.get<ScommSharedFileVersion>(environment.apiUrl + 'sharedfiles/0/revisions/' + fileSpecificationVersionId);
    }

    public createSharedFileVersion(revision: ScommSharedFileVersion): Observable<ScommSharedFileVersion> {
        return this.apiProxy.post<ScommSharedFileVersion>(environment.apiUrl + 'sharedfiles/' + revision.sharedFileId + '/revisions', revision);
    }

    public deleteSharedFileVersion(revision: ScommSharedFileVersion): Observable<any> {
        return this.apiProxy.delete<void>(environment.apiUrl + 'sharedfiles/' + revision.sharedFileId + '/revisions/' + revision.revision);
    }

    public getVersionLabels(scommSharedFileVersion: ScommSharedFileVersion): Observable<Label[]> {
        return this.apiProxy.get<Label[]>(environment.apiUrl + 'sharedfiles/0/revisions/' + scommSharedFileVersion.id + '/labels/');
    }

    public updateVersionLabels(scommSharedFileVersion: ScommSharedFileVersion, labels: Label[]): Observable<Label[]> {
        return this.apiProxy.put<Label[]>(environment.apiUrl + 'sharedfiles/' + scommSharedFileVersion.id + '/labels/', labels);
    }

    public getDcodeSpecificationsUsedBySharedFile(sharedFileSpecificationId: number): Observable<number[]> {
        return this.apiProxy.get<number[]>(environment.apiUrl + 'sharedfiles/dcodeSpecificationsUsedBySharedFile/' + sharedFileSpecificationId);
    }

    public getSharedFilesForSpecificationVersion(specificationVersionId: number): Observable<SharedFile[]> {
        return this.apiProxy.get<SharedFile[]>(environment.apiUrl + 'sharedfiles?specificationVersionId=' + specificationVersionId);
    }
}
