<mat-tab-group animationDuration="0ms" (selectedTabChange)="setActiveFunction($event)"
    [selectedIndex]="indexOfActiveFunction" mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab *ngFor="let function of enabledFunctions" [label]="function.name">
        <div>
            <app-property-table *ngIf="source.activeFunction && getActiveFunctionName() !== 'Reset'"
                [properties]="source.activeFunction.properties"
                [serviceExecutionSequence]="source.activeFunction.serviceExecutionSequence"
                [propertyType]="source.activeFunction.propertyType" [isReadOnly]="isReadOnly"></app-property-table>

            <app-prerequisites *ngIf="canEditPrerequisites" [loginServiceExecution]="loginServiceExecution"
                [fingerprintServiceExecution]="fingerprintServiceExecution" [isReadOnly]="isReadOnly"
                (loginCommandChanged)="onLoginCommandChanged($event)"
                (fingerprintCommandChanged)="onFingerprintCommandChanged($event)"></app-prerequisites>

            <app-assigned-services-viewer  *ngIf="source.activeFunction" [serviceExecutionSequence]="source.activeFunction.serviceExecutionSequence"
                [propertyType]="source.activeFunction.propertyType" 
                [activefunction]="source.activeFunction" [isReadOnly]="isReadOnly"
                [hideAddButtonWithOneService]="hideAddButtonWithOneService"></app-assigned-services-viewer>

            <app-postrequisites *ngIf="canEditPostrequisites" [ecuResetServiceExecution]="ecuResetServiceExecution"
                [isReadOnly]="isReadOnly" (ecuResetCommandChanged)="onEcuResetCommandChanged($event)">
            </app-postrequisites>
            <ng-container
                *ngIf="source.activeFunction && (dataCategoriesService.activeCategory.id === 'ios' || dataCategoriesService.activeCategory.id === 'values') && source.activeFunction.name === 'Read'">
                <app-read-id-status (readIdStatusServiceChanged)="onReadIdStatusServiceChanged($event)"
                    (readIdSequenceRemoved)="onReadIdStatusSequenceRemoved($event)"
                    [readIdServiceExecutionSequence]="readIdServiceExecutionSequence" [propertyType]="propertyType"
                    [isReadOnly]="isReadOnly" [hideAddButtonWithOneService]="hideAddButtonWithOneService">
                </app-read-id-status>
            </ng-container>
        </div>
    </mat-tab>
</mat-tab-group>
