<h2>Available Diagnostic Specification templates</h2>
<div class="sdds-banner w-1/2">
  <div class="sdds-banner-body">
      <h6 class="sdds-banner-header">What is a template?</h6>
      A template in DCode is a diagnostic specification that <b>reflects the diagnostic services defined in a Candela template</b>.
      It is used by legacy <b>Security Gateway ECUs</b> that doesn't have a Candela file but need to follow the UDS/KWP standards.
      <br /> <br />
      Diagnostic specifications connected to a template will <b>inherit</b> the contents from the template.
  </div>
</div>

<div class="sdds-banner w-1/2 mt-10">
  <div class="sdds-banner-body">
    <h6 class="sdds-banner-header">How to update a template?</h6>
    Navigate to any of the following available templates, from the <b>Specification Home</b> click on <b>Template Settings</b> and upload an <b>ODX file</b>.
    <br /><br />
    <span>
      <a class="sdds-detail-04" href="https://scaniaazureservices.sharepoint.com/teams/dcode/_layouts/OneNote.aspx?id=%2Fteams%2Fdcode%2FSiteAssets%2Fdcode%20Notebook&wd=target%28General.one%7C1DEF246F-F321-4719-8814-57F49A71C0D4%2FF.A.Q%20Onboarding%20Secure%20Update%5C%2FSecurity%20Gateway%20ECUs%7CB9F9FD34-90F3-4C1C-A15F-59D1A2F712FB%2F%29
      onenote:https://scaniaazureservices.sharepoint.com/teams/dcode/SiteAssets/dcode%20Notebook/General.one#F.A.Q%20Onboarding%20Secure%20Update/Security%20Gateway%20ECUs&section-id={1DEF246F-F321-4719-8814-57F49A71C0D4}&page-id={B9F9FD34-90F3-4C1C-A15F-59D1A2F712FB}&end" target="_blank">Learn more</a>
      <mat-icon>open_in_new</mat-icon>
    </span>
  </div>
</div>

<div>
  <div class="flex flex flex-wrap" *ngIf="availableTemplates">
    <div class="sdds-card sdds-background-blue-50 sdds-col-max-3 mr-10 mt-14 mb-2"
        *ngFor="let template of availableTemplates">
        <div class="flex">
          <div class="flex-initial">
            <mat-icon class="mt-5 mr-10">description</mat-icon>
          </div>
          <div class="flex-initial">
            <p class="sdds-headline-06 mt-10">Template</p>
          </div>
        </div>
        <div class="mt-5">
          <a class="sdds-detail-04 sdds-text-grey-700"
            [routerLink]="['/specification', template.id, template.specificationVersions[0].id]">
              {{template.name}}
              <mat-icon class="ml-5">open_in_new</mat-icon>
          </a>
        </div>
        <div class="mt-10">
          <p class="sdds-detail-04 sdds-text-grey-700">Protocol: {{diagnosticProtocolEnum[template.diagnosticProtocol]}}</p>
          <p class="sdds-detail-04 sdds-text-grey-700">Type: {{serverExecutionEnum[template.serverExecutionMode]}}</p>
        </div>
    </div>
</div>
