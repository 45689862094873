/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum Category {
    Services,
    Servers,
    Identification,
    Sessions,
    Fingerprints,
    EcuResets,
    Ios,
    Iolists,
    Values,
    OperationalData,
    LegacyFiles,
    SecurityAccess,
    UnreferencedTestSteps,
    FreezeFrames,
    IvdOverrides,
    VsocMonitoring
}
