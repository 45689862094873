import {  Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ScommFile, ScommFileType, SharedFile, SharedFileType } from 'app/app-model/legacy-file';
import { Specification } from 'app/app-model/specification';
import { LegacyFileModificationService } from 'app/app-services/legacy-file-modification.service';
import { LegacyFileToolbarService } from 'app/app-services/legacy-file-toolbar-service.service';
import { ScommFileImporterService } from 'app/app-services/scomm-file-importer.service';
import { SpecificationLegacyFiles } from 'app/app-services/specification-legacy-files.service';
import { SpecificationService } from 'app/app-services/specification-service';
import {
  SelectSharedFilesComponent,
} from 'app/data-categories/shared-files/select-shared-files/select-shared-files.component';
import {
  SharedFilesFilterOptions,
} from 'app/data-categories/shared-files/shared-files-assigner/shared-files-assigner.component';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { User } from 'app/modules/shared/model/user';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-legacy-server-keyfile-viewer',
  templateUrl: './legacy-server-keyfile-viewer.component.html',
  styleUrls: ['./legacy-server-keyfile-viewer.component.css']
})
export class LegacyServerKeyfileViewerComponent {
  @ViewChild('selectSharedFiles', { static: true }) selectKeySharedFiles: SelectSharedFilesComponent;
  @ViewChild('selectOtherSharedFiles', { static: false }) selectOtherSharedFiles: SelectSharedFilesComponent;

  @Input()
  currentUser: User;

  @Output()
  legacyXmlFileUploaded: EventEmitter<ScommFile> = new EventEmitter();

  @Output()
  legacyXmlFileRemoved: EventEmitter<ScommFile> = new EventEmitter();

  @Output()
  legacyFileCreated: EventEmitter<ScommFile> = new EventEmitter();

  @Output()
  openLegacyFileEv: EventEmitter<void> = new EventEmitter();

  @Output()
  assignOtherSharedFiles: EventEmitter<any> = new EventEmitter();

  @Output()
  unlinkSharedFile: EventEmitter<{ scommSharedFileSpecification: SharedFile; sharedFileType: SharedFileType }> = new EventEmitter();

  @Input()
  currentSpecification: Specification;

  @Input()
  isEditingAllowed: boolean;

  @Input()
  legacyServerFile: ScommFile;

  @Input()
  hasKeyFiles: boolean;

  @Input()
  hasOtherFiles: boolean;

  filterOptionsToUse = SharedFilesFilterOptions.All;

  public get keyFile(): any {
    return this._keyFile;
  }
  @Input()
  public set keyFile(v: any) {
    this._keyFile = v;
  }

  loading: boolean;
  uploaded: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SharedFilesFilterOptions = SharedFilesFilterOptions;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SharedFileType = SharedFileType;
  selectXmlSharedFilesModal = 'selectXmlSharedFilesModal';
  _hasLoadedFile: boolean;

  private _keyFile: any;
  private defaultServerFileTemplate =
    `<?xml version="1.0" encoding="iso-8859-1"?>
  <Root>
    <Meta>
      <Doctype>DiagnosticServerFile</Doctype>
      <Version>00000001</Version>
      <Comment>Default server file template.</Comment>
    </Meta>
    <Data>
      <XmlVersion>2.06</XmlVersion>
      <ServerInfo>
        <ServerExecutingMode>Application</ServerExecutingMode>
        <HardwareNumber>Id0x00</HardwareNumber>
      </ServerInfo>
      <ProtocolSettings>
        <Protocol>UDS</Protocol>
        <TimeoutAttempts>2</TimeoutAttempts>
      </ProtocolSettings>
    </Data>
  </Root>
  `;

  constructor(public specificationService: SpecificationService,
    private scommFileImporterService: ScommFileImporterService,
    private specificationLegacyFiles: SpecificationLegacyFiles,
    private messageService: MessageService,
    private legacyFileModificationService: LegacyFileModificationService,
    private legacyFileToolbarService: LegacyFileToolbarService,
    private authService:AuthService) { }

  @Input()
  public set hasLoadedFiles(v: boolean) {
    this._hasLoadedFile = v;
  }

  public get hasLoadedFiles() {
    return this._hasLoadedFile;
  }

  get hasLegacyServerFile(): boolean {

    return this.legacyServerFile !== null && this.legacyServerFile !== undefined;
  }
  get doYouWantToDeleteMessage() {
    return this.legacyServerFile.name
      ? 'Do you want to delete file ' +
      this.legacyServerFile.name +
      '?'
      : '';
  }
  public get isLegacyVersion() {
    return this.specificationService.isLegacyVersion;
  }

  xmlFileChanged(e: Event) {
    this.uploaded = true;
    this.fileChanged(e);
  }

  fileChanged(e: Event) {
    this.loading = true;
    const target: HTMLInputElement = e.target as HTMLInputElement;
    if (target.files.length === 1) {
      console.log('importing file ' + target.files[0].name);

      const file = new ScommFile();
      file.name = target.files[0].name;
      file.data = '';
      file.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
      this.getFileTypeAndUploadServerFile(target, file);
    }
  }

  getFileTypeAndUploadServerFile(target: HTMLInputElement, file: ScommFile) {
    this.scommFileImporterService.getFileType(target.files[0]).subscribe(fileType => {
      if (fileType != null) {
        file.fileType = fileType;
        this.scommFileImporterService.getBase64(target.files[0]).subscribe(
          data => {
            file.data = data;
            this.uploadLegacyServerFile(file).subscribe(legacyFile => {
              this.loading = false;
              this.legacyServerFile = legacyFile;
              target.files = undefined;
              target.value = '';
              this.legacyXmlFileUploaded.next(legacyFile);
            }, error => {
              target.files = undefined;
              target.value = '';
            });
          }
        );
      } else {
        this.messageService.dispatchErrorMessage(
          'The file ' + file.name + ` is not supported by dcode. Only files with Ecu/Server/Security Access files are supported`);
      }
    });
  }

  createServerFile() {
    this.loading = true;
    const file = new ScommFile();
    file.name = this.currentSpecification.name + '.XML';
    file.data = btoa(this.defaultServerFileTemplate);
    file.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
    file.fileType = ScommFileType.LegacyServerFile;

    this.uploadLegacyServerFile(file).subscribe(legacyFile => {
      this.loading = false;
      this.legacyServerFile = legacyFile;
      this.legacyFileCreated.next(legacyFile);
    });
  }

  openServerFile() {
    this.loading = true;
    this.legacyFileModificationService.getAllDraftFiles().subscribe(() => {
      this.openLegacyFileEv.next();
      this.loading = false;
    });
  }

  uploadLegacyServerFile(file: ScommFile): Observable<ScommFile> {
    return this.specificationLegacyFiles.saveSpecificationScommFile(file);
  }

  deleteSelectedFile(file: ScommFile) {
    if (this.isEditingAllowed) {
      this.specificationLegacyFiles.deleteSpecificationScommFile(file.id).subscribe(args => {
        this.legacyServerFile = undefined;
        this.legacyXmlFileRemoved.next(file);
      });
    } else {
      this.messageService.dispatchErrorMessage('The file ' + file.name + ' cannot be removed since the specification version is released.');
    }
  }

  downloadFile(file: ScommFile) {
    this.specificationLegacyFiles.getSpecificationScommFile(file.id).subscribe(fileFromSource => {
      const fileDataChars = new Array(fileFromSource.data.length);
      for (let i = 0; i < fileFromSource.data.length; i++) {
        fileDataChars[i] = fileFromSource.data.charCodeAt(i);
      }
      const dataAsBytes = new Uint8Array(fileDataChars);

      FileSaver.saveAs(new Blob([dataAsBytes], { type: 'text/xml;charset=iso-8859-1' }), fileFromSource.name);
    });
  }

  unlinkSelectedFileSpecification(sharedFile: SharedFile, fileType: SharedFileType) {
    if (this.isEditingAllowed) {
      this.unlinkSharedFile.next({ scommSharedFileSpecification: sharedFile, sharedFileType: fileType });
    }
  }

  onSelectKeySharedFiles() {
    this.selectKeySharedFiles.filterOptions = SharedFilesFilterOptions.Keyfile;
    this.selectKeySharedFiles.updateSharedFilesAssigner();
  }

  onSelectOtherSharedFiles() {
    this.selectOtherSharedFiles.filterOptions = SharedFilesFilterOptions.Other;
    this.selectOtherSharedFiles.updateSharedFilesAssigner();
  }

  onAssignSharedFilesEvent(sharedFiles: SharedFile[]) {
    this.specificationLegacyFiles.assignSharedFiles(this.specificationService.currentSpecificationVersion.id, sharedFiles)
      .subscribe(files => {
        console.log('successfully referensed shared files to specification');
        this.assignOtherSharedFiles.next(sharedFiles);
      });
  }

  canEditSCOMMFiles() {
    return this.authService.canEditSCOMMFiles();
  }
}
