import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChangeType } from "app/app-model/enums";
import { RbacService } from "app/app-services/rbac-file-service";
import { Rbac, RbacLog } from "app/modules/shared/model/rbac";

@Component({
    selector: 'app-rbac-log',
    templateUrl: './rbac-log.component.html',
    styleUrls: ['./rbac-log.component.css']
})

export class RbacLogComponent implements OnInit {

    public rbacId: string;
    public rbac: Rbac;
    public rbacLogs: RbacLog[] = [];

    constructor(private route: ActivatedRoute,
        private rbacService: RbacService) {
    }

    ngOnInit(): void {
        this.rbacId = this.route.snapshot.paramMap.get('rbacId');
        this.getRbac();
    }

    public get logs(): RbacLog[] {
        if (this.rbac)
            return this.rbacLogs
    }

    getRbac() {
        this.rbacService.getRbacFromAPI(this.rbacId).subscribe(res => {
            this.rbac = res
            this.rbacLogs = res.rbacLogs.sort((a, b) => {
                return <any>new Date(b.date) - <any>new Date(a.date);
            });
        });
    }

    getChangeType(type: number) {
        if (type === ChangeType.AddConnection)
            return "Add Connection";
        if (type === ChangeType.RemoveConnection)
            return "Remove Connection";
    }

}


