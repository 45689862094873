import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-move-expressions-confirm',
  templateUrl: './move-expressions-confirm.component.html',
  styleUrls: ['./move-expressions-confirm.component.css']
})
export class MoveExpressionsConfirmComponent {

  @Output()
  accept: EventEmitter<any> = new EventEmitter();

  @Output()
  cancelAction: EventEmitter<any> = new EventEmitter();


  apply() {
    this.accept.emit();
  }

  cancel() {
    this.cancelAction.emit();
  }
}
