import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { SharedFile } from '../app-model/legacy-file';
import { ScommSharedFileVersion } from '../app-model/scomm-shared-file-version';

@Injectable({
    providedIn: 'root'
})
export class SharedFilesEventsService {
    sharedFileSpecificationSelected: Subject<SharedFile> = new Subject();
    sharedFileSpecificationVersionSelected: BehaviorSubject<ScommSharedFileVersion> = new BehaviorSubject(undefined);
    versionCreated: Subject<ScommSharedFileVersion> = new Subject();

    referencedSharedFilesRemoved: Subject<void> = new Subject();
}
