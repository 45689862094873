<sdds-modal size="md" selector="#referencingDcodeSpecificationsModal" >
  <h5 slot="sdds-modal-headline">Specifications using this file</h5>
  <div slot="sdds-modal-body">
    <ul>
      <li *ngFor="let specificationVersion of dcodeReferenceSpecifications">
        <a style="cursor: pointer;"
          (click)="navigateToSpec(specificationVersion)">{{specificationVersion.specification.name}}
          | version {{specificationVersion.name}}</a>
      </li>
    </ul>
  </div>
  <button #cancelButton slot="sdds-modal-actions" modal-dismiss class="sdds-btn sdds-btn-secondary sdds-btn-md">Close</button>
</sdds-modal>
