import { Component, OnInit } from '@angular/core';
import { StateChart, State, Transition } from '../../app-model/state-chart/state-chart.model';
import { StateChartService } from '../../app-services/state-chart.service';
import { SpecificationService } from '../../app-services/specification-service';
import { SpecificationVersion } from '../../app-model/specification-version';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { PreconditionService } from '../../app-services/precondition.service';
import { DiagnosticServiceCategoryItem } from '../../modules/shared/model/service/diagnostic-service';
import { DiagnosticServiceProvider } from '../../app-services/diagnostic.service.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-precondition',
  templateUrl: './precondition.component.html',
  styleUrls: ['./precondition.component.css']
})
export class PreconditionComponent implements OnInit {

  charts: Array<StateChart>;
  loading: boolean;
  specificationVersion: SpecificationVersion;
  preconditionMap: Map<string, Map<string, boolean>>;
  activeMap: Map<string, string>;
  transitions: Array<Transition>;
  isReadOnly: boolean;

  constructor(
    private stateChartService: StateChartService,
    private preconditionService: PreconditionService,
    private specificationService: SpecificationService,
    private messageService: MessageService,
    private servicesProvider: DiagnosticServiceProvider,
    private authService:AuthService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.specificationService.selectedSpecificationEventEmitter.subscribe(specification => {
      if (specification && specification.specificationVersion) {
        this.specificationVersion = specification.specificationVersion;
        this.stateChartService.getAll(specification.specificationVersion.id).subscribe(data => {
          this.charts = data;
          this.activeMap = new Map(this.charts.map(chart => [chart.shortName, chart.states[0].shortName] as [string, string]));

          this.preconditionMap = new Map<string, Map<string, boolean>>(
            this.charts.map(chart => [chart.shortName, new Map<string, boolean>(
              chart.states.map(state => [state.shortName, true] as [string, boolean]))] as [string, Map<string, boolean>]));

          this.transitions = new Array<Transition>();
          this.loading = false;
        }, error => {
          this.loading = false;
          this.messageService.dispatchErrorMessageFromApi(error);
        });
      }
    });
  }


  getCharts(): string[] {
    if (!this.preconditionMap) {
      return new Array<string>();
    }
    return Array.from(this.preconditionMap.keys());
  }
  getStates(chart: string): string[] {
    if (!this.preconditionMap) {
      return new Array<string>();
    }
    return Array.from(this.preconditionMap.get(chart).keys());
  }

  isPrecondition(chart: string, state: string): boolean {
    return this.preconditionMap.get(chart).get(state);
  }

  togglePrecondition(chart: string, state: string): void {
    const newState = !this.preconditionMap.get(chart).get(state);
    this.preconditionMap.get(chart).set(state, newState);
  }

  isActive(chart: string, state: string): boolean {
    return this.activeMap.get(chart) === state;
  }

  setActive(chart: string, state: string): void {
    this.activeMap.set(chart, state);
  }

  loadTransitions(): void {
    const activeStates = new Array<string>();
    this.activeMap.forEach((value: string, key: string) => {
      activeStates.push(value);
      console.log(key, value);
    });

    const allowedStates = new Array<string>();
    this.preconditionMap.forEach((value: Map<string, boolean>, key: string) => {
      value.forEach((value2: boolean, key2: string) => {
        if (value2 === true) {
          allowedStates.push(key2);
        }
      });
    });
    this.preconditionService.get(this.specificationVersion.id, allowedStates, activeStates).subscribe(data => {
      this.transitions = data;
    });
  }

  onDiagnosticServicesAdded(addedServices: Array<DiagnosticServiceCategoryItem>) {
    if (addedServices.length > 0) {
      this.setAllPreconditions(true);

      this.servicesProvider.getItem(this.specificationVersion.id, addedServices[0].name).subscribe((service) => {
        service.chartPreconditions.forEach(chartPre => {
          const chart = chartPre.stateChart.shortName;
          this.setChartPreconditions(chart, false);
          chartPre.preconditions.forEach(pre => {
            const state = pre.state.shortName;
            this.preconditionMap.get(chart).set(state, true);
          });
        });
      });
    }

  }

  setChartPreconditions(chart: string, newValue: boolean) {
    this.preconditionMap.get(chart).forEach((value2: boolean, key2: string) => {
      this.preconditionMap.get(chart).set(key2, newValue);
    });
  }

  setAllPreconditions(newValue: boolean) {
    this.preconditionMap.forEach((value: Map<string, boolean>, key: string) => {
      value.forEach((value2: boolean, key2: string) => {
        this.preconditionMap.get(key).set(key2, newValue);
      });
    });
  }
  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
