<div class="sdds-row mt-16 mb-16">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <h3>IOs</h3>
    </div>

    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <button class="sdds-btn sdds-btn-secondary sdds-btn-sm mb-16 pb-2" id="ioSelectModal"
            [disabled]="isAddButtonDisabled||!canEditCategoryItems()">
            <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon> Add
        </button>
    </div>

    <app-ios-selector (iosAdded)="onIosAdded($event)" [ioRelations]="ioRelations"></app-ios-selector>

    <div class="column" *ngFor="let io of ioRelations">

        <div class="sdds-card sdds-clickable sdds-on-white-bg">
            <div class="sdds-card-body p-10">
                <div class="float-right">
                    <a *ngIf="canEditCategoryItems()" id="deleteBtn-spec" [ngClass]="{'hidden': isAddButtonDisabled}" style="cursor: pointer;" (click)="removeIoFromList(io)">
                        &times;</a>
                </div>
                <div>
                    <table class="table-auto">
                        <tr>
                            <td>
                                <span class="sdds-detail-02 ml-5">{{io.ioName}}</span>
                            </td>
                            <td>
                                <a [routerLink]="['/specification',specificationId,specificationVersionId,'ios']"
                                    [queryParams]="{itemId: io.ioName}"><img src="font/iconsrc/link.png"
                                        style="width: 20px; margin-left: 5px;" /></a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding"
        *ngIf="!ioRelations || ioRelations.length <= 0">
        <div class="sdds-banner sdds-banner-info">
            <div class="sdds-banner-body">
                No IOs were found. Add a new IO by selecting the <i>Add</i> option above.
            </div>
        </div>
    </div>
</div>