<app-dialog-base modalId="securityAccessSelectModal" title="Add a new security access" [itemTemplate]="securityAccessSelectorTemplate"
    [footerTemplate]="securityAccessFooter" applyCaption="Apply" #dialogBase></app-dialog-base>

<ng-template #securityAccessSelectorTemplate>
    <app-toolbar [filterEnabled]="true" (filterTextEventEmitter)="filter($event)"></app-toolbar>

    <div class="select-security-access" [ngClass]="{'loading': isLoading}">
        <select #select multiple class="h-full w-full">
            <option *ngFor="let securityAccess of securityAccessItems | categoryItemFilter: filterText" [hidden]="isSecurityAccessAlreadyAssigned(securityAccess)">
                {{securityAccess}}
            </option>
        </select>
    </div>
</ng-template>

<ng-template #securityAccessFooter>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-primary sdds-btn-md mr-12"
        (click)="onAddSecurityAccess()">{{okButtonName || 'Add'}}</button>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md">Cancel</button>
</ng-template>
