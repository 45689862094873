import { ComputationalMethodType } from 'app/app-model/diagnostic-service/computational.method.model';
import { LengthKind, TerminationType } from 'app/app-model/diagnostic-service/length.model';
import { ParameterDataModel } from 'app/app-model/diagnostic-service/parameter-data.model';
import { ScalingModel, ScaniaEncodingType } from 'app/app-model/diagnostic-service/parameter.scaling.model';
import { ParameterType } from 'app/modules/shared/model/service/parameters/parameter-type';
import { ScalingData } from 'app/modules/shared/model/service/parameters/scaling-data';
import { OdxDataType, TypedValueData } from 'app/modules/shared/model/service/parameters/typed-value-data';

/** Encapsulation of parameter data model with support to track property and service execution owners */
export class ParameterData {
  // These properties exsist only at runtime
  sourceService: string;
  propertyOwner: string;
  private _scalingData: ScalingData;

  private _parameterDataModel: ParameterDataModel = new ParameterDataModel();
  public get model(): ParameterDataModel {
    return this._parameterDataModel;
  }
  public set model(v: ParameterDataModel) {
    this._parameterDataModel = v;

    if (this._parameterDataModel.scaling) {
      this.scalingData = new ScalingData(this._parameterDataModel.scaling);
    }
  }

  public get name(): string {
    return this.model.name;
  }

  public set name(v: string) {
    this.model.name = v;
  }

  public get hasName(): boolean {
    return this.name && this.name.trim() !== '';
  }

  public get canEnableMinMaxLength(): boolean {
    return !this.model.scaling ? false :
      this.isDataTypeCompatibleWithMinMaxLength(this.model.scaling.internalDataType);
  }

  public get hexValue(): string {
    if(!this.hasNumericalPhysicalDataType()) {
      return this.rawValue;
    }

    if (this.model.value && this.model.value.data) {
      let hexValue = (+this.model.value.data).toString(16);

      if (this.model.length) {
        const padLength = (this.model.length.bitLength / 4) - hexValue.length;
        if (padLength > 0) {
          const padString = '0';
          hexValue = padString.repeat(padLength) + hexValue;
        }
      }

      return '0x' + hexValue.toUpperCase();
    }

    return '';
  }

  public get type() {
    return ParameterType[this.model.type];
  }
  public set type(t: string) {
    this.model.type = ParameterType[t];
  }

  public get value(): TypedValueData {
    return this.model.value;
  }

  public get rawValue(): string {
    if (this.model.value && this.model.value.data) {
      return this.model.value.data;
    }

    return '';
  }

  public set rawValue(data: string) {
    if (this.model.value && this.model.value.data) {
      this.model.value.data = data;
    }
  }

  public get hasRawValue(): boolean {
    return this.rawValue && this.rawValue.trim() !== '';
  }

  public get scalingData(): ScalingData {
    return this._scalingData;
  }
  public set scalingData(v: ScalingData) {
    this._scalingData = v;
  }

  public hasInternalToPhysicalComputationalMethod(): boolean {
    return this.scalingData.model.internalToPhysicalComputationalMethod !== undefined &&
      this.scalingData.model.internalToPhysicalComputationalMethod !== null;
  }

  public hasNumericalPhysicalDataType(): boolean{
    return this.value != null && this._parameterDataModel.scaling &&
      this._parameterDataModel.scaling.physicalDataType !== OdxDataType.AsciiString &&
        this._parameterDataModel.scaling.physicalDataType !== OdxDataType.ByteField;
  }

  public getPhysicalDataType(): string {
    return OdxDataType[this._parameterDataModel.scaling.physicalDataType];
  }

  public getEnumDataType(): OdxDataType {
    if (this.hasTextTable()) {
      return OdxDataType.AsciiString;
    } else {
      return this._parameterDataModel.scaling.physicalDataType;
    }
  }

  public isDataTypeCompatibleWithMinMaxLength(dataType: OdxDataType) {
    return dataType === OdxDataType.AsciiString || dataType === OdxDataType.ByteField;
  }

  public hasTextTable(): boolean {
    return this._parameterDataModel.scaling &&
      ((this._parameterDataModel.scaling.internalToPhysicalComputationalMethod !== null &&
        this._parameterDataModel.scaling.internalToPhysicalComputationalMethod !== undefined &&
        this._parameterDataModel.scaling.internalToPhysicalComputationalMethod.methodType === ComputationalMethodType.TextTable) ||
        (this._parameterDataModel.scaling.physicalToInternalComputationalMethod !== null &&
          this._parameterDataModel.scaling.physicalToInternalComputationalMethod !== undefined &&
          this._parameterDataModel.scaling.physicalToInternalComputationalMethod.methodType === ComputationalMethodType.TextTable));

  }

  public getInternalToPhysicalComputationalMethodValues(): string[] {
    const values = [];

    if (this._parameterDataModel.scaling.internalToPhysicalComputationalMethod) {
      this._parameterDataModel.scaling.internalToPhysicalComputationalMethod.intervals.forEach(textTableInterval => {
        values.push(textTableInterval.text);
      });
    }
    return values;
  }

  public initToConstant() {
    this.name = '';

    if (!this.model.scaling) {
      this.resetScaling();
    }

    this.model.value = { dataType: this.model.scaling.internalDataType, data: '0' };
    this.resetScaling();
    this.resetLength();
  }

  public initToValue() {
    this.name = '';
    this.model.value = undefined;
    this.resetScaling();
    this.resetLength();
  }

  public initToReserved() {
    this.name = '';
    this.model.value = undefined;
    this.scalingData = undefined;
    this.resetLength();
  }

  public resetLength() {
    this.model.length.minByteLength = 0;
    this.model.length.maxByteLength = 0;
    this.model.length.kind = LengthKind.Standard;
    this.model.length.termination = TerminationType.EndOfMessage;
  }

  public resetScaling() {
    const scalingModel = new ScalingModel();
    scalingModel.physicalDataType = OdxDataType.UInt32;
    scalingModel.unit = '-';
    scalingModel.internalDataType = OdxDataType.UInt32;
    scalingModel.encoding = ScaniaEncodingType.Default;

    const scalingData = new ScalingData(scalingModel);
    this.scalingData = scalingData;
    this.scalingData.reset();
  }
}
