import { CategoryItem } from "app/app-model/category-item";
import { CategoryName } from "app/app-model/enums";
import { VsocMonitoringItem } from "app/app-model/vsoc/vsoc-monitoring-item";
import { VsocMonitoringService } from "app/app-services/vsocMonitoringService";
import { PropertyType } from "app/modules/shared/model/properties/base-property";


export class VsocCategoryItem extends CategoryItem {

    _vsocService: VsocMonitoringService;

    private _model: VsocMonitoringItem;
    propertyType: PropertyType = PropertyType.Both;

    public get model(): VsocMonitoringItem {
        return this._model;
    }
    public set model(v: VsocMonitoringItem) {
        this._model = v;
        this.name = this.model.name;
    }

    constructor() {
        super();
        this.categoryType = CategoryName.VsocMonitoring;
    }

}