// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.alar-cli.json`.

export const environment = {
    production: true,
    apiUrl: 'https://api.dcode.devtest.aws.scania.com/',
    hubUrl: 'https://api.dcode.devtest.aws.scania.com/hub/',
    newUiUrl: 'https://next.dcode.devtest.aws.scania.com/',

    //[task-2215-Migrate user authentication from Keycloak to AzureAD]
    // identityUrl: 'https://fg.ciam.preprod.aws.scania.com/auth/realms/scania/',
    // checkIdentity: true,

    azureAD: {
      msalConfig: {
        auth: {
          clientId: '95e0740b-4399-49b8-bd09-ff3acd8a7434',
          authority: 'https://login.microsoftonline.com/3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac/',
        },
      },
    },

    apiConfig: [
      {
        key: 'profile',
        graphScopes: ['user.read'],
        apiScopes: [],
        uri: 'https://graph.microsoft.com/v1.0/me',
      },
      {
        key: 'profile-pic',
        graphScopes: ['user.read'],
        apiScopes: [],
        uri: 'https://graph.microsoft.com/v1.0/me/photo/$value',
      },
      {
        key: 'backend',
        apiScopes: ['95e0740b-4399-49b8-bd09-ff3acd8a7434/.default'],
        graphScopes: [],
        uri: 'https://api.dcode.devtest.aws.scania.com/*',
      },
    ],
  };
