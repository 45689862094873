<div class="modal fade" data-backdrop="static" id="addSpecificSpecificationVersion">
  <div class="modal-dialog">
    <div class="modal-content dialog">
      <div class="modal-header">
        <h4 class="modal-title">Add new specific specification version(s)</h4>
        <button type="button" class="close" [disabled]="loading" data-dismiss="modal" aria-hidden="true"
          (click)="modalClosing.emit()">&times;</button>
      </div>
      <div class="modal-body">
        <app-specification-version-selector #specVersionSelectorRef
          header="Select one or more specification versions to be part of this custom build." multiSelect="true"
          specifications="specifications" [selectedSpecificationVersions]="selectedSpecificationVersions">
        </app-specification-version-selector>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="modalClosing.emit()" class="btn btn-secondary" data-dismiss="modal"
          [disabled]="loading">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="createNewSpecificVersionItem();" data-dismiss="modal"
          [disabled]="loading || !canAdd">Ok</button>
      </div>
    </div>
  </div>
</div>