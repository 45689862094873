import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IosSelectorComponent } from 'app/dialogs/ios-selector/ios-selector.component';
import { OdxFileSelectorDialogComponent } from 'app/dialogs/odx-file-selector/odx-file-selector-dialog';
import { CategoryItemFilterPipe } from 'app/pipes/diagnostic-service-filter.pipe';
import { ResourcesModule } from 'app/resources/resources.module';

import { SpecificationNameFilterPipe } from '../../pipes/global-export-preview-filter.pipe';
import { AssignedIosViewerComponent } from './components/assigned-ios-viewer/assigned-ios-viewer.component';
import {
  AssignedSecurityAccessItemsViewerComponent,
} from './components/assigned-security-access-items-viewer/assigned-security-access-items-viewer.component';
import { CategoryHeaderComponent } from './components/category-header/category-header.component';
import { CommonListComponent } from './components/common-list/common-list.component';
import { ConfirmDialogueComponent } from './components/confirm-dialogue/confirm-dialogue.component';
import { DialogBaseComponent } from './components/dialog-base/dialog-base.component';
import { EditableTextboxComponent } from './components/editable-textbox/editable-textbox.component';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { FreezeFrameEnumComponent } from './components/freezeframe-enum/freezeframe-enum.component';
import { ImportPreviewComponent } from './components/import-preview/import-preview.component';
import { LoginPrerequisitesComponent } from './components/login-prerequisites/login-prerequisites.component';
import { MessageTemplateComponent } from './components/message-handler/message-templates/message-templates.component';
import { ImportViewComponent } from './components/odx-import-view/odx-import-view.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PostrequisitesComponent } from './components/postrequisites/postrequisites.component';
import { PrerequisitesComponent } from './components/prerequisites/prerequisites.component';
import { ReadIdStatusComponent } from './components/read-id-status/read-id-status.component';
import { ScommFileSelectorComponent } from './components/scomm-file-selector/scomm-file-selector.component';
import { SecurityAccessSelectorComponent } from './components/security-access-selector/security-access-selector.component';
import {
  ServiceParameterDefinitionComponent,
} from './components/service-parameter-definition/service-parameter-definition.component';
import { ServiceParametersTableComponent } from './components/service-parameters-table/service-parameters-table.component';
import { ServiceSelectorComponent } from './components/service-selector/service-selector.component';
import {
  SpecificationVersionSelectorComponent,
} from './components/specification-version-selector/specification-version-selector.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { NodeDescriptionComponent } from './components/tree/tree-node/node-description/node-description.component';
import { NodeDetailsComponent } from './components/tree/tree-node/node-details/node-details.component';
import { TreeNodeComponent } from './components/tree/tree-node/tree-node.component';
import { TreeComponent } from './components/tree/tree/tree.component';
import { CloseModalDirective } from './directives/close-modal.directive';
import { FocusDirective } from './directives/focus.directive';
import { OpenModalDirective } from './directives/open-modal.directive';
import { ReplaceElementDirective } from './directives/replace-element.directive';
import { GraphComponent } from './graph/graph.component';
import { FloorPipe } from './pipes/floor-pipe';
import { NameFilterPipe } from './pipes/name-filter-pipe';
import { ApiMessageService } from './services/api-message-service';
import { ApiProxyService } from './services/api-proxy.service';
import { MessageService } from './services/message-service.service';
import { SpecificationSelectorComponent } from './components/specification-selector/specification-selector.component';
import { RbaccFilterPipe } from './pipes/rbacc-file-filter-pipe';

// import { CodemirrorModule } from '@ctrl/ngx-codemirror';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ResourcesModule,
    // CodemirrorModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    DragDropModule,
    MatListModule,
    MatTabsModule,
    MatDialogModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ScrollingModule
  ],
  declarations: [
    CommonListComponent,
    IosSelectorComponent,
    AssignedIosViewerComponent,
    ConfirmDialogueComponent,
    EditableTextboxComponent,
    ToolbarComponent,
    CloseModalDirective,
    FocusDirective,
    OpenModalDirective,
    ReplaceElementDirective,
    PageNotFoundComponent,
    TreeComponent,
    TreeNodeComponent,
    FloorPipe,
    NameFilterPipe,
    RbaccFilterPipe,
    SpecificationNameFilterPipe,
    GraphComponent,
    ServiceParameterDefinitionComponent,
    ImportViewComponent,
    FilePreviewComponent,
    SpecificationVersionSelectorComponent,
    SpecificationSelectorComponent,
    NodeDescriptionComponent,
    NodeDetailsComponent,
    ScommFileSelectorComponent,
    MessageTemplateComponent,
    LoginPrerequisitesComponent,
    ImportPreviewComponent,
    CategoryHeaderComponent,
    ReadIdStatusComponent,
    ServiceSelectorComponent,
    ServiceParametersTableComponent,
    DialogBaseComponent,
    CategoryItemFilterPipe,
    PrerequisitesComponent,
    PostrequisitesComponent,
    SecurityAccessSelectorComponent,
    AssignedSecurityAccessItemsViewerComponent,
    OdxFileSelectorDialogComponent,
    FreezeFrameEnumComponent
  ],
  providers: [
    ApiProxyService,
    ApiMessageService,
    MessageService,
    DatePipe,
  ],
  exports: [
    CommonListComponent,
    AssignedIosViewerComponent,
    ConfirmDialogueComponent,
    EditableTextboxComponent,
    ToolbarComponent,
    CloseModalDirective,
    FocusDirective,
    OpenModalDirective,
    ReplaceElementDirective,
    TreeComponent,
    TreeNodeComponent,
    FloorPipe,
    NameFilterPipe,
    RbaccFilterPipe,
    SpecificationNameFilterPipe,
    // MessageHandlerComponent,
    GraphComponent,
    ImportViewComponent,
    FilePreviewComponent,
    SpecificationVersionSelectorComponent,
    SpecificationSelectorComponent,
    ScommFileSelectorComponent,
    MessageTemplateComponent,
    LoginPrerequisitesComponent,
    CategoryHeaderComponent,
    ReadIdStatusComponent,
    PrerequisitesComponent,
    PostrequisitesComponent,
    ServiceSelectorComponent,
    SecurityAccessSelectorComponent,
    ServiceParametersTableComponent,
    DialogBaseComponent,
    CategoryItemFilterPipe,
    AssignedSecurityAccessItemsViewerComponent,
    OdxFileSelectorDialogComponent,
    FreezeFrameEnumComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
