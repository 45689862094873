import {
  ServiceExecutionRequestValue,
} from 'app/app-model/diagnostic-service/sequence-execution-request-parameter-data.model';
import {
  ServiceExecutionResponseValue,
} from 'app/app-model/diagnostic-service/sequence-execution-response-parameter-data.model';

import { CommandType } from '../enums';


export class ServiceExecutionModel {
  sequenceId: number;
  index: number;
  service: string;
  command: ServiceCommand;
  requestParameters: ServiceExecutionRequestValue[] = [];
  responseParameters: ServiceExecutionResponseValue[] = [];
}

export class ServiceCommand {
  id: number;
  diagServiceExecutionDataIndex: number;
  diagServiceExecutionDataSequenceId: number;
  type: CommandType;
  arguments: string;
}


