import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';

export class FingerprintModel {
  name: string;
  readSequence: ServiceExecutionSequenceModel;
  writeSequence: ServiceExecutionSequenceModel;
  loginPrerequisites: WriteSecurity;
}

export class WriteSecurity {
  allowedLogin = '';
  loginToPerform = '';
}
