import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SpecificSpecificationVersion } from '../model/specific-specification-version';


@Component({
  selector: 'app-specification-version-preview',
  templateUrl: './specification-version-preview.component.html',
  styleUrls: ['./specification-version-preview.component.css']
})
export class SpecificationVersionPreviewComponent {

  @Input()
  specificSpecificationVersion: SpecificSpecificationVersion;

  @Input()
  isAllowed: boolean;

  @Output()
  removeSpecification: EventEmitter<SpecificSpecificationVersion> = new EventEmitter();

  isInWorkStatus(specificationVersion: SpecificSpecificationVersion): boolean {
    return specificationVersion.specificationVersion.releaseStatus === 0;
  }

}
