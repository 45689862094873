import { Injectable } from '@angular/core';
import { TaskStatusModel } from 'app/app-model/task-status/task-status-model';

import { MessageService } from '../modules/shared/services/message-service.service';
import { TaskStatusHub } from './hubs/task-status-hub.service';

@Injectable({
    providedIn: 'root'
})
export class TaskStatusService {
    model: TaskStatusModel;

    constructor(private messageService: MessageService,
        private taskStatusHub: TaskStatusHub) {
        this.model = new TaskStatusModel();
        this.model.taskStatusHub = taskStatusHub;
    }
}
