import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nameFilter' })
export class NameFilterPipe implements PipeTransform {

  transform(value: any[], filterText: string, propertyName = 'name'): any[] {
    if (!value || value.length <= 0) {
      return [];
    }
    if (!filterText || filterText === '') {
      return value;
    }

    const filteredItems = value.filter(item => item[propertyName] && item[propertyName].toLowerCase().includes(filterText.toLowerCase()));

    return filteredItems;
  }
}
