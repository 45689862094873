import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RbaccState } from 'app/app-model/enums';
import { RbacService } from 'app/app-services/rbac-file-service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { Rbac } from 'app/modules/shared/model/rbac';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-rbac-detail',
  templateUrl: './rbac-detail.component.html',
  styleUrls: ['./rbac-detail.component.css']
})
export class RbacDetailComponent implements OnInit {

  isFileUploaded: boolean = false;
  isDefaultEntity: boolean = false;
  loadingRbaccs: boolean = false;
  rbacItems: Rbac[] = [];
  filterText: string;
  selectedView: Rbac;
  viewFilterText: boolean;
  isViewSelected: boolean;
  addViewEventEmitter = new EventEmitter<void>();
  _activatedRoute: ActivatedRoute;
  rbacType: number = 1;
  searchInput: string = "";
  searchTypes: string[] = ["File Name", "Rbacc Guid", "Specification Name", "Generation Name"];
  selectedSearch: string = this.searchTypes[0];
  addOrEditRbacInfo:boolean = false;
  loadrbacItem:boolean = false;
  selectedViewRbacId: string;

  constructor(public rbacService: RbacService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService:AuthService) {
    this._activatedRoute = activatedRoute;
  }

  ngOnInit(): void {
    this.getAllRbacs();
   
   // this.checkURLForNewRbac();
  }

  // whenRbacFileUploaded(rbac: Rbac) {
  //   this.rbacService.createRbacFile(rbac).pipe(tap(_postRbac => {
  //     this.isDefaultEntity = rbac.defaultEntity;
  //     this.isFileUploaded = true;
  //     rbac.rbacGuid = _postRbac.rbacGuid;
  //     this.rbacItems.push(rbac);
  //     this.selectView(rbac);
  //   })).subscribe();
  // }

  getAllRbacs() {
    this.loadingRbaccs = true;
    this.rbacService.getRbacs()
      .pipe(tap(res => {
        this.loadingRbaccs = false;
        this.activatedRoute.paramMap.subscribe(params => this.whenRoutActivated(params))
        this.rbacItems = res;
      }))
      .subscribe();
  }

  set rbacList(rbacs: Rbac[]) {
    this.rbacItems = rbacs;
  }

  get rbacList() {
    return this.rbacItems;
  }

  whenRoutActivated(params: ParamMap): void {
    const rbacGuidFromRoute = params.get('rbacId');

    if (!rbacGuidFromRoute) {
      this.selectedView = undefined;
      return;
    }

    const rbacFileToSelect = this.rbacService.getByRbacGuid(rbacGuidFromRoute);

    if (rbacFileToSelect) {
      this.selectedView = rbacFileToSelect;
      this.addOrEditRbacInfo =false;
    }
  }

  selectView(view: Rbac) {
    if (view && view.rbaccState === RbaccState.Approved) {
      this.router.navigate(['/rbac-files/', view.rbacGuid]);
    } else if (view && view.rbaccState !== RbaccState.Approved) {
      this.selectedView = view;
      this.selectedViewRbacId = view.rbacGuid;
      this.router.navigate(['rbac-files/new-rbac/', view.rbacGuid]);
    } else{
      this.router.navigate(['/rbac-files/']);
    }
  }
T
 

  canEditRbacc() {
    return this.authService.canEditRbacc();
  }
  search() {
    if (this.searchInput === "" || this.searchInput === undefined)
      this.rbacItems = this.rbacService.rbacItems;

    if (this.selectedSearch === this.searchTypes[0]) {
      this.rbacItems = this.changeFilter()
        .filter(r => r.name.toLowerCase().includes(this.searchInput.toLowerCase()))
        this.rbacType = 1;
    }

    if (this.selectedSearch === this.searchTypes[1]) {
      this.rbacItems = this.rbacService.rbacItems
        .filter(r => r.rbacGuid === this.searchInput)
      this.rbacType = 1;
    }

    if (this.selectedSearch === this.searchTypes[2]) {
      this.rbacItems = this.searchForSpec();
      this.rbacType = 3;
    }

    if (this.selectedSearch === this.searchTypes[3]) {
      this.rbacItems = this.searchForGen();
      this.rbacType = 2;
    }

  }

  searchForSpec() {
    let rbacs = [];

    for (var rbac of this.rbacService.rbacItems) {

      if (rbac.connectedSpecificationVersions != null) {
        for (var spec of rbac.connectedSpecificationVersions) {
          if (rbacs.indexOf(rbac) === -1 &&
            spec.specificationName.toLowerCase().includes(this.searchInput.toLowerCase())) {
            rbacs.push(rbac);
            break;
          }
        }
      }
    }

    return rbacs;
  }

  searchForGen() {
    let rbacs = [];

    for (var rbac of this.rbacService.rbacItems) {
      if (rbac.connectedGenerations != null) {
        for (var gen of rbac.connectedGenerations) {
          if (rbacs.indexOf(rbac) === -1 &&
            (this.searchInput.toLowerCase() === gen.generationName.toLowerCase()
              || this.searchInput.toLowerCase() === gen.familyName.toLowerCase())) {
            rbacs.push(rbac);
            break;
          }
        }
      }
    }

    return rbacs;
  }

  clearSearch() {
    this.searchInput = "";
    this.rbacList = this.rbacService.rbacItems;
    this.rbacType = 1;
  }

  changeFilter() {
    if (this.rbacType === 1) {
      this.rbacItems = this.rbacService.rbacItems;
    }
    if (this.rbacType === 2) {
      this.rbacItems = this.rbacService.rbacItems.filter(s => s.defaultEntity);
    }
    if (this.rbacType === 3) {
      this.rbacItems = this.rbacService.rbacItems.filter(s => !s.defaultEntity);
    }

    return this.rbacItems;
  }

  addNewRbacFile() {     
   this.router.navigate(['/rbac-files/new-rbac']);
  }

  checkURLForNewRbac() {
    if(this.router.url.includes("rbac-files/new-rbac"))
    {
      this.addOrEditRbacInfo =true;
    }
  
  }

  addOrEditRbac(){
    if(this.router.url.includes("rbac-files/new-rbac"))
      {
        this.addOrEditRbacInfo =true;  
        return true;
      }
      else
      return false;    
  }  

    rbacIsApproved()
  {
    if (this.selectedView === undefined || this.selectedView === null)
      return false;
      if (this.selectedView.rbaccState === RbaccState.Approved){
        return true;
      } else {
        return false;
      }
   
  }

  rbacInWork(){
    if (this.selectedView === undefined)
      return false;
    return this.selectedView.rbaccState === RbaccState.InWork
    || this.selectedView.rbaccState === RbaccState.UnderReview;
  }

  // sendApprovedRbacc(approvedRbacc : Rbac){
  //   if(approvedRbacc && approvedRbacc !=undefined ){
  //     this.selectedView = approvedRbacc
  //   }
 
  // }


}