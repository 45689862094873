<app-dialog-base [modalId]="modalName" title="Add a new service" [itemTemplate]="serviceSelectorTemplate"
    [footerTemplate]="serviceSelectorFooter" applyCaption="Apply" #dialogBase></app-dialog-base>

<ng-template #serviceSelectorTemplate>
    <app-toolbar [filterEnabled]="true" (filterTextEventEmitter)="filter($event)"></app-toolbar>

    <div class="select-service" [ngClass]="{'loading': isLoading}">
        <select #select multiple class="h-full w-full" [(ngModel)]="selectedDiagnosticServices">
            <option *ngFor="let diagnosticService of diagnosticServices | categoryItemFilter: filterText" [ngValue]="diagnosticService">
                ({{diagnosticService.requestDid(diagnosticService)}}) {{diagnosticService.name}}
            </option>
        </select>
    </div>
</ng-template>

<ng-template #serviceSelectorFooter>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-primary sdds-btn-md mr-12"
        (click)="onAddDiagnosticServices()">{{okButtonName || 'Add'}}</button>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md">Cancel</button>
</ng-template>
