import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/merge/merge';
import 'codemirror/addon/scroll/annotatescrollbar';
import 'codemirror/addon/search/jump-to-line';
import 'codemirror/addon/search/matchesonscrollbar';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/selection/active-line';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/xml/xml';

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LegacyFileModificationService } from 'app/app-services/legacy-file-modification.service';
import { LegacyFileToolbarService } from 'app/app-services/legacy-file-toolbar-service.service';
import { SpecificationLegacyFiles } from 'app/app-services/specification-legacy-files.service';
import * as CodeMirror from 'codemirror';
import { Subscription } from 'rxjs';
import { DiffFileService } from 'app/app-services/diff-file.service';


@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.css'],
  providers: [DiffFileService]
})
export class FilePreviewComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('codeEditor') codeEditorElemRef: ElementRef;
  @ViewChild('mergeEditor', { static: false }) mergeEditorElemRef: ElementRef;

  @Input()
  xmlFileName: string;

  public diffSubscription: Subscription;
  public modalClosing: EventEmitter<void> = new EventEmitter<void>();
  public loading: boolean;
  public toggleMergeModeSubscription: Subscription;
  private _activeCodeEditorContent: string;
  private _codeEditor: CodeMirror.Editor;
  private _mergeEditor: CodeMirror.MergeView.MergeViewEditor;

  public get editMode(): boolean {
    return !this.mergeMode && !this.diffMode;
  }

  public get diffMode(): boolean {
    return this.legacyFileToolbarService.diffMode;
  }

  public get mergeMode(): boolean {
    return this.legacyFileToolbarService.mergeMode;
  }

  public set mergeMode(v: boolean) {
    this.legacyFileToolbarService.mergeMode = v;
    if (this.mergeMode) {
      this.initDiffMergeView();
    }
  }

  public get codeEditor(): CodeMirror.Editor {
    return this._codeEditor;
  }
  public set codeEditor(v: CodeMirror.Editor) {
    this._codeEditor = v;
  }

  public get mergeEditor(): CodeMirror.MergeView.MergeViewEditor {
    return this._mergeEditor;
  }
  public set mergeEditor(v: CodeMirror.MergeView.MergeViewEditor) {
    this._mergeEditor = v;
  }

  public get activeCodeEditorContent(): string {
    return this._activeCodeEditorContent;
  }

  public set activeCodeEditorContent(fileData: string) {
    this._activeCodeEditorContent = fileData;
    if (this._codeEditor !== undefined) {
      this.codeEditor.setValue(fileData);
    }
  }

  constructor(private specificationLegacyFiles: SpecificationLegacyFiles,
    private legacyFileToolbarService: LegacyFileToolbarService,
    private modificationService: LegacyFileModificationService) { }

  public get modifiedBy(): string {
    return this.modificationService.currentLegacyServerFile.modifiedBy ? this.modificationService.currentLegacyServerFile.modifiedBy.name : '';
  }

  public get modifiedByDate(): string {
    return this.modificationService.currentLegacyServerFile.modificationDate;
  }

  public get createdByDate(): string {
    if (!this.modificationService.currentLegacyServerFile.creationDate) {
      return '';
    }
    if (this.modificationService.currentLegacyServerFile.creationDate.startsWith('0001')) {
      return '2019-04-01 01:01:00';
    } else {
      return this.modificationService.currentLegacyServerFile.creationDate;
    }
  }

  public get getFileName(): string {
    if (this.xmlFileName) {
      return this.xmlFileName;
    }
  }

  ngOnInit() {
    this.mergeMode = false;
    this.toggleMergeModeSubscription = this.specificationLegacyFiles.toggleMergeMode.subscribe(event => {
      this.mergeMode = !this.mergeMode;
    });

    this.diffSubscription = this.legacyFileToolbarService.diff.subscribe(() => {
      this.modificationService.resetLeftRightContent();
      this.initDiffMergeView();
    });
  }

  ngAfterViewInit() {
    this.initCodeEditor();
  }

  ngOnDestroy(): void {
    this.toggleMergeModeSubscription.unsubscribe();
    this.diffSubscription.unsubscribe();
  }

  setLeftContent(content: string) {
    this.mergeEditor.leftOriginal().setValue(content);
  }

  setRightContent(content: string) {
    this.mergeEditor.editor().setValue(content);
  }

  close() {
    this.modalClosing.emit();
  }

  enableMerge() {
    this.mergeMode = true;
  }

  initCodeEditor() {
    const editorOptions: CodeMirror.EditorConfiguration = {
      lineNumbers: true,
      readOnly: true,
      styleActiveLine: true,
      theme: this.getEditorTheme(),
      mode: this.getEditorMode(),
      extraKeys: this.getExtraKeys(),
    };

    const element = this.codeEditorElemRef.nativeElement;
    this._codeEditor = CodeMirror(element, editorOptions);

    if (this.activeCodeEditorContent) {
      this._codeEditor.setValue(this.activeCodeEditorContent);
    }
  }

  initDiffMergeView() {
    setTimeout(() => {
      const element = this.mergeEditorElemRef.nativeElement;
      const editorOptions: CodeMirror.MergeView.MergeViewEditorConfiguration = {
        lineNumbers: true,
        styleActiveLine: true,
        theme: this.getEditorTheme(),
        mode: this.getEditorMode(),
        extraKeys: this.getExtraKeys(),
        revertButtons: this.legacyFileToolbarService.mergeMode,
        value: this.modificationService.currentRightFileContent ? this.modificationService.currentRightFileContent : '',
        orig: null,
        origLeft: this.modificationService.currentLeftFileContent ? this.modificationService.currentLeftFileContent : '',
        showDifferences: true,
        connect: 'align',
        collapseIdentical: false,
        readOnly: !this.legacyFileToolbarService.mergeMode
      };

      this._mergeEditor = CodeMirror.MergeView(element, editorOptions);

      this.mergeEditor.editor().on('change', () => {
        if (this.legacyFileToolbarService.mergeMode) {
          this.modificationService.currentRightFileContent = this._mergeEditor.editor().getValue();
        }
      });
    }, 500);
  }

  private getEditorTheme(): string {
    // return 'default';
    // return 'eclipse';
    // return 'elegant';
    // return 'idea';
    // return 'mdn-like';
    // return 'neat';
    // return 'neo';
    return 'ttcn';
    // return 'xq-light';
  }

  private getEditorMode(): string {
    return 'application/xml';
  }

  private getExtraKeys(): CodeMirror.KeyMap {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Ctrl-S': () => this.legacyFileToolbarService.triggerSaveDraft(),
    };
  }
}
