<div class="m-0">
  <div class="column">
    <div class="sdds-col-md-10 mb-10">
      <input class="sdds-textfield-input-sm" [(ngModel)]="generation.name" type="text" placeholder="Name..."
        (change)="generationNameChanged(generation.name)" [readonly]="!isAllowed">
      <button class=" sdds-btn sdds-btn-primary sdds-btn-sm" (click)="deleteGeneration()" [disabled]="!isAllowed">
        x
      </button>
    </div>
  </div>
</div>