import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DiagnosticFamilyModel, GenerationModel } from 'app/app-model/server-identification/diagnostic-family.model';
import { DiagnosticFamilyService } from 'app/app-services/diagnostic-family.service';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { tap } from 'rxjs/operators';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RbaccEcuDiagnosticProtocol, RbaccEcuDiagnosticSecurityType, RbaccEcuType, RbaccState } from 'app/app-model/enums';
import { RbacService } from 'app/app-services/rbac-file-service';
import { Rbac } from 'app/modules/shared/model/rbac';
import { ActivatedRoute, Router } from '@angular/router';
import { RbacFileSelectorComponent } from 'app/dialogs/rbac-file-selector/rbac-file-selector.component';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-new-rbac',
  templateUrl: './new-rbac.component.html',
  styleUrls: ['./new-rbac.component.css']
})



export class NewRbacComponent implements OnInit {

  form: FormGroup;

  _diagnosticFamilyService: DiagnosticFamilyService;

  availablefamilies: DiagnosticFamilyModel[] = [];
  generations: GenerationModel[];
  selectedFamily = new DiagnosticFamilyModel();
  selectedGeneration: GenerationModel;
  loadingFamilies = false;
  loadingGenerations = false;
  selectedFamilyName = "";
  SelectedFamilyId: number = -1;
  SelectedProtocolId: number = -1;
  SelectedDiagnosticSecurityTypeId: number = -1;
  SelectedRbaccEcuTypeId: number = -1;
  selectedRbacFile: { fileName: string, fileData: File };

  rbaccUploader: string;
  rbaccApprover: string;
  targetGenerations: string[] = [];
  public diagnosticProtocols = RbaccEcuDiagnosticProtocol;

  selectedProtocol: RbaccEcuDiagnosticProtocol = RbaccEcuDiagnosticProtocol.Kwp2000;
  selectedProtocolString = "";

  ecuDiagnosticSecurityType = RbaccEcuDiagnosticSecurityType;
  selectedDiagnosticSecurityType: RbaccEcuDiagnosticSecurityType = RbaccEcuDiagnosticSecurityType.SecureDiagnostic;

  rbaccEcuType = RbaccEcuType;
  selectedrbacECUType: RbaccEcuType = RbaccEcuType.FlashableByScania;

  isFlashable: boolean;
  defaultEntityChecked: boolean = false;
  isFileUploaded: boolean = false;

  rbacToSave: Rbac = new Rbac();
  selectedRbacFileName: string = "no file selected";
  selectedRbacGUID = "";
  makeDisable: boolean = false;
  isValid: boolean = false;
  currentRbacState: RbaccState;
  isUnderReview: boolean = false;
  disableSubmitReview: boolean = false;
  private _loadrbacItem: string;


  public get loadrbacItem(): string {
    if (this._loadrbacItem != undefined)
      return this._loadrbacItem;
  }

  @Input()
  public set loadrbacItem(v: string) {
    this._loadrbacItem = v;
    if (v != null || v != undefined) {
      // this.checkURLForRbacParam();
      this.loadRBACInfo(v);
    }
  }

  @ViewChild(RbacFileSelectorComponent) fileSelect: RbacFileSelectorComponent;

  diagnosticProtocolNames: { [key in RbaccEcuDiagnosticProtocol]: string } = {
    [RbaccEcuDiagnosticProtocol.Kwp2000]: 'Kwp2000',
    [RbaccEcuDiagnosticProtocol.Uds]: 'Uds',
    [RbaccEcuDiagnosticProtocol.KwpAndUds]: 'KwpAndUds'
  };

  ecuDiagnosticSecurityTypeNames: { [key in RbaccEcuDiagnosticSecurityType]: string } = {
    [RbaccEcuDiagnosticSecurityType.SecurityGateway]: 'SecurityGateway',
    [RbaccEcuDiagnosticSecurityType.SecureDiagnostic]: 'SecureDiagnostic',
    [RbaccEcuDiagnosticSecurityType.SecurityGatewayAndSecureDiagnostic]: 'SecurityGatewayAndSecureDiagnostic'
  }

  ecuTypeNames: { [key in RbaccEcuType]: string } = {
    [RbaccEcuType.FlashableByScania]: 'FlashableByScania',
    [RbaccEcuType.ParameterizationOnly]: 'ParameterizationOnly'
  }

  get generationFormArray() {
    return this.form.controls.genCheckboxes as FormArray;
  }



  constructor(private diagnosticFamilyService: DiagnosticFamilyService, private messageService: MessageService,
    private rbacService: RbacService, private formBuilder: FormBuilder,
    private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService,) {


    this.form = this.formBuilder.group({
      genCheckboxes: new FormArray([])
    });
  }

  ngOnInit() {
    this.getFamilies();
  }

  checkURLForRbacParam() {

    const obj = this.activatedRoute;
    if (this.activatedRoute.snapshot.paramMap.get("rbacId")) {
      this.selectedRbacGUID = this.activatedRoute.snapshot.paramMap.get('rbacId');
      this.loadRBACInfo(this.selectedRbacGUID);
      this.isValidRbacInfo();
    }
  }


  loadRBACInfo(rbacGUID: string) {
    this.rbacService.getRbacFromAPI(rbacGUID).subscribe(res => {
      this.rbacToSave = res;

      this.selectedRbacFileName = this.rbacToSave.name;
      this.defaultEntityChecked = this.rbacToSave.defaultEntity;
      this.selectedFamilyName = this.rbacToSave.ecuFamily;
      this.isFileUploaded = true;
      if (this.availablefamilies.length > 0 && this.selectedFamilyName != "") {
        this.SelectedFamilyId = this.availablefamilies.find(f => f.name === this.selectedFamilyName).id;
      }
      this.targetGenerations = this.rbacToSave.targetGenerations;
      this.loadGenerations(this.selectedFamilyName, this.targetGenerations);

      if (this.rbacToSave.rbaccState === RbaccState.UnderReview) {
        this.SelectedProtocolId = -1;
        this.SelectedDiagnosticSecurityTypeId = -1;
        this.SelectedRbaccEcuTypeId = -1;
      } else {
        this.SelectedProtocolId = this.rbacToSave.rbaccEcuDiagnosticProtocol;
        this.SelectedDiagnosticSecurityTypeId = this.rbacToSave.rbaccEcuDiagnosticSecurityType;
        this.SelectedRbaccEcuTypeId = this.rbacToSave.rbaccEcuType;
      }

      this.currentRbacState = this.rbacToSave.rbaccState
      this.isUnderReview = (this.currentRbacState === RbaccState.UnderReview ? true : false);
      this.submitRbaccForReviewDisabled();
    });
  }

  // loadRbacData(rbacLoad:Rbac){
  //   alert("loadRbacData called from input rbac prop")
  //   this.selectedRbacFileName = rbacLoad.name;
  //   this.defaultEntityChecked = rbacLoad.defaultEntity;
  //   this.selectedFamilyName = rbacLoad.ecuFamily;
  //   this.isFileUploaded = true;
  //  if(this.availablefamilies.length >0 && this.selectedFamilyName!=""){
  //   this.SelectedFamilyId = this.availablefamilies.find(f => f.name === this.selectedFamilyName).id;
  //  }

  //   this.targetGenerations = rbacLoad.targetGenerations;
  //   this.loadGenerations(this.selectedFamilyName, this.targetGenerations);
  //   this.SelectedProtocolId = rbacLoad.rbaccEcuDiagnosticProtocol;
  //   this.SelectedDiagnosticSecurityTypeId = rbacLoad.rbaccEcuDiagnosticSecurityType;
  //   this.SelectedRbaccEcuTypeId = rbacLoad.rbaccEcuType;
  //   this.currentRbacState = rbacLoad.rbaccState
  //   this.isUnderReview = (this.currentRbacState===RbaccState.UnderReview? true : false);
  //   this.isValidRbacInfo();
  //   this.submitRbaccForReviewDisabled();

  // }


  private addCheckboxes(data: GenerationModel[], chekedGenerations?: string[]) {


    this.generationFormArray.clear();
    var genToCheck: GenerationModel[] = [];
    for (let i = 0; i < data.length; i++) {
      if (chekedGenerations != null && chekedGenerations.includes(data[i].name)) {
        this.generationFormArray.push(new FormControl(true));
      } else {
        this.generationFormArray.push(new FormControl(false));
      }
    }
  }




  getFamilies() {
    if (this.availablefamilies.length > 0) {
      this.checkURLForRbacParam();
      return this.availablefamilies;
    }

    this.loadingFamilies = true;
    this.diagnosticFamilyService.getFamilies().pipe(tap(families => {
      this.availablefamilies = families.filter(f => f.name != null && f.generations != null); // to filter out empty item populated in angular         
      this.loadingFamilies = false;
      this.checkURLForRbacParam();
    }, error => {
      this.loadingFamilies = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    })).subscribe();
  }

  loadGenerations(strFamilyName: string, chekedGenerations?: string[]) {
    if (strFamilyName != "") {
      this.diagnosticFamilyService.getGenerations(strFamilyName).pipe(tap(generations => {
        this.generations = generations.filter(g => g.id != null && g.name != null); // to filter out empty item populated in angular
        this.addCheckboxes(this.generations, chekedGenerations);
        this.loadingGenerations = false;
      }, error => {
        this.loadingGenerations = false;
        this.messageService.dispatchErrorMessageFromApi(error);
      })).subscribe();

    }
  }

  getGenerations() {
    if (!this.selectedFamily) {
      return;
    }

    this.loadGenerations(this.selectedFamilyName);
    this.form = this.formBuilder.group({
      genCheckboxes: new FormArray([])
    });
    this.targetGenerations = [];
    this.loadingGenerations = true;

  }



  onDefaultEntityCheckboxChange(event: any) {
    if (event.target.checked) {
      this.defaultEntityChecked = true;
    } else {
      this.defaultEntityChecked = false;
    }

  }

  rbacfileChanged(event: Event) {
    const element = event.target as HTMLInputElement;
    if (element.files) {
      this.selectedRbacFile = { fileName: element.files[0].name, fileData: element.files[0] };
    }

  }

  onFamilyChange() {
    this.selectedFamilyName = this.availablefamilies.filter(a => a.id === Number(this.SelectedFamilyId))[0].name;
    this.getGenerations();
    this.isValidRbacInfo();
  }

  onProtocolChange(event: any) {
    this.SelectedProtocolId = event.target.value;
    this.isValidRbacInfo();
  }

  onSecurityTypeChange() {
    this.isValidRbacInfo();
  }
  onECUTypeChange() {
    this.isValidRbacInfo();
  }

  onCheckboxItemChange(event: any) {

    let searchText = event.target.name;

    if (event.target.checked) { //check
      if (this.targetGenerations.indexOf(searchText) == -1) { // if not present in a list before      
        this.targetGenerations.push(searchText);
      }
    } else { //un-check
      if (this.targetGenerations.indexOf(searchText) != -1) { // if present in a list before
        this.targetGenerations.splice(this.targetGenerations.indexOf(searchText), 1);
      }
    }
    this.isValidRbacInfo();
  }

  addRbaccInfo() {
    // this.rbacToSave = new Rbac();
    this.rbacToSave.defaultEntity = this.defaultEntityChecked;
    this.rbacToSave.ecuFamily = this.selectedFamilyName;
    this.rbacToSave.targetGenerations = this.targetGenerations;
    this.rbacToSave.rbaccEcuDiagnosticProtocol = this.selectedProtocol
    this.rbacToSave.rbaccEcuDiagnosticSecurityType = this.selectedDiagnosticSecurityType
    this.rbacToSave.rbaccEcuType = this.selectedrbacECUType

    this.rbacService.AddRbac(this.rbacToSave).pipe(tap(_postRbac => {
      this.defaultEntityChecked = this.rbacToSave.defaultEntity;
      this.isFileUploaded = true;
      this.rbacToSave.rbacGuid = _postRbac.rbacGuid;
      // public AddRbacToLocalCache(file: Rbac)
      this.rbacService.rbacItems.push(this.rbacToSave);
      // this.selectView(this.rbacToSave);
      // this.router.navigate(['/rbac-files/new-rbac'], { queryParams: { rbacId: this.rbacToSave.rbacGuid } });
      this.router.navigate(['/rbac-files/new-rbac', this.rbacToSave.rbacGuid]);

    })).subscribe();


  }

  isValidRbacInfo() {
    // return true;
    var isValidFlag = true;
    if (this.selectedRbacFileName == "no file selected") { //in case file is not selected
      return this.isValid = isValidFlag = false;
    }

    if (isValidFlag && this.SelectedFamilyId < 0) {  //ECU family is not selected
      return this.isValid = isValidFlag = false;
    }

    if (isValidFlag && this.targetGenerations.length < 1) {     //if genration is not checked
      return this.isValid = isValidFlag = false;
    }


    if (this.rbacToSave != null && this.rbacToSave.rbaccState === RbaccState.UnderReview) {
      return this.isValid = isValidFlag = true;
    }  // so that for existing Rbacc files, below code is skipped and that makes Protocol,ECU for Sec GW /Sec Diag, and ECU flashable fields non-mandatory
    else {
      if (isValidFlag && this.SelectedProtocolId < 0) {   //protocol is not selected
        return this.isValid = isValidFlag = false;
      }

      if (isValidFlag && this.SelectedDiagnosticSecurityTypeId < 0) { //Sec GW Sec Diag is not selected
        return this.isValid = isValidFlag = false;
      }

      if (isValidFlag && this.SelectedRbaccEcuTypeId < 0) { //ECU Type is not selected 
        return this.isValid = isValidFlag = false;
      }
    }



    this.isValid = isValidFlag;
    return this.isValid;



  }


  whenRbacFileUploaded(rbac: Rbac) {
    this.rbacToSave = rbac;
    this.selectedRbacFileName = rbac.name;
  }

  resetAll() {

    this.isValid = false;
    this.rbacToSave = new Rbac();
    this.selectedRbacFileName = "no file selected";
    this.isFileUploaded = false;
    this.defaultEntityChecked = false;
    this.SelectedFamilyId = -1;
    this.targetGenerations = [];
    this.generations = [];

    this.form = this.formBuilder.group({
      genCheckboxes: new FormArray([])
    });
    this.SelectedProtocolId = -1;
    this.SelectedDiagnosticSecurityTypeId = -1;
    this.SelectedRbaccEcuTypeId = -1;
    this.router
  }

  isRbacInWork() {
    if (this.rbacToSave && this.rbacToSave.rbaccState === RbaccState.InWork) {
      this.currentRbacState = RbaccState.InWork;
      this.isValidRbacInfo();
      return true;
    }
    else
      return false;
  }

  isRbacSubmitted() {
    if (this.rbacToSave && this.rbacToSave.rbaccState === RbaccState.UnderReview) {
      this.currentRbacState = RbaccState.UnderReview;
      this.isValidRbacInfo();
      return true;
    }
    else
      return false;
  }

  UpdateRbaccInfo() {
    this.rbacToSave.defaultEntity = this.defaultEntityChecked;
    if (this.availablefamilies.length > 0 && this.selectedFamilyName != "") {
      this.rbacToSave.ecuFamily = this.availablefamilies.find(f => f.id === this.SelectedFamilyId).name;
    }
    this.rbacToSave.targetGenerations = this.targetGenerations;
    this.rbacToSave.rbaccEcuDiagnosticProtocol = +this.SelectedProtocolId;
    this.rbacToSave.rbaccEcuDiagnosticSecurityType = +this.SelectedDiagnosticSecurityTypeId
    this.rbacToSave.rbaccEcuType = +this.SelectedRbaccEcuTypeId
    this.rbacService.UpdateRbac(this.rbacToSave).subscribe(res => {
      this.rbacToSave = res;
      this.messageService.dispatchSuccessMessage(`Rbacc file ${this.rbacToSave.name} is updated.`);
    })
  }

  submitRbaccInfoForReview() {

    this.rbacService.SubmitRbacForReview(this.rbacToSave.rbacGuid).subscribe(data => {
      this.rbacToSave = data;
      this.currentRbacState == data.rbaccState;
      if (this.currentRbacState === RbaccState.UnderReview) {
        this.isUnderReview = true;
        this.submitRbaccForReviewDisabled();
      }
    })
  }

  submitRbaccForReviewDisabled() {
    if (this.isValidRbacInfo()) { // if form is valid
      if (this.selectedRbacGUID === '' || !this.isRbacInWork()) {
        return this.disableSubmitReview = true;
      } else {
        return this.disableSubmitReview = false;
      }
    } else {
      return this.disableSubmitReview = true;
    }
  }

  canApproveRbacc(): boolean {
    return this.authService.canApproveRbacc();
  }

  approveRbacc() {
    this.rbacService.ApproveRbacc(this.rbacToSave.rbacGuid).subscribe(data => {
      this.rbacToSave = data;
      this.currentRbacState == this.rbacToSave.rbaccState;
      this.messageService.dispatchSuccessMessage(`Rbacc file name ${this.rbacToSave.name} is Approved.`);

      this.router.navigate(['/rbac-files/', this.rbacToSave.rbacGuid]).then(() => {
        window.location.reload();
      });
    })
  }

  rejectRbacc() {
    this.rbacService.RejectRbacc(this.rbacToSave.rbacGuid).subscribe(data => {
      this.rbacToSave = data;
      this.currentRbacState == data.rbaccState;
    })
  }

  downloadRbacFile() {
    this.rbacService.getRbaccFileAsXml(this.rbacToSave.rbacGuid).subscribe(rbaccXmlFile => {
      FileSaver.saveAs(new Blob([rbaccXmlFile]), `${this.rbacToSave.rbacGuid}.xml`);
    });
  }

  getCurrentRbaccState() {
    return RbaccState[this.rbacToSave.rbaccState];
  }



}

