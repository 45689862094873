import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogBase } from 'app/dialogs/dialog-base';

import { Label } from '../../../../app-model/label/label';
import { BuildParameters } from '../../model/export/build-parameters';
import { BuildService } from '../../services/build.service';

@Component({
  selector: 'app-build',
  templateUrl: './build.component.html',
  styleUrls: ['./build.component.css']
})
export class BuildComponent extends DialogBase {
  @Output()
  labelsUpdated = new EventEmitter<string>();

  @Input()
  labels: Label[];
  buildLabel: string;
  loading = false;
  modalClosing: EventEmitter<void> = new EventEmitter<void>();

  constructor(private buildService: BuildService) {
    super();
  }

  get canCreateBuild() {
    return this.buildLabel;
  }
  close() {
    this.modalClosing.emit();
  }
  apply(): void {
    const buildParameters = new BuildParameters();
    buildParameters.buildLabel = this.buildLabel;
    this.loading = true;
    this.buildService.createBuild(buildParameters).subscribe(
      () => {
        this.loading = false;
        this.buildLabel = null;
        this.labelsUpdated.emit(this.buildLabel);
      },
      () => {
        this.loading = false;
        this.buildLabel = null;
      }
    );
  }
  cancel(): void {
    this.close();
  }
}
