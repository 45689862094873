import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { IdentificationGroup } from '../../app-model/identification-group/identification-group';
import { ServerIdentificationModel } from '../../app-model/server-identification/server-identification.model';
import { CommonListElementSettings } from '../../modules/shared/components/common-list/common-list-element-settings';
import { Specification } from './../../app-model/specification';

@Component({
  selector: 'app-connected-server-identifications',
  templateUrl: './connected-server-identifications.component.html',
  styleUrls: ['./connected-server-identifications.component.css']
})
export class ConnectedServerIdentificationsComponent implements ControlValueAccessor {

  @Input()
  showFilter = true;

  @Input()
  showJumpToGroup = true;

  @Input()
  highlightedServerIdentifications: ServerIdentificationModel[];

  @Input()
  currentSpecification: Specification;
  serverList: ServerIdentificationModel[];
  originalList: IdentificationGroup;
  filteredList: ServerIdentificationModel[];
  serverItems:  ServerIdentificationModel[] ;
  identificationGroupItems: ServerIdentificationModel[];

  public get identificationGroup(): IdentificationGroup {
    return this._identificationGroup;
  }

  @Input()
  public set identificationGroup(v: IdentificationGroup) {
    this._identificationGroup = v;
    this.originalList = v;
    this.updateServersToShow();
  }

  @Output()
  orderChangedEventEmitter = new EventEmitter<ServerIdentificationModel[]>();

  refreshServerListEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  filterTextChanged = new Subject<string>();
  onChange: () => void;
  onTouched: () => void;
  onDisabled: boolean;
  serversToShow: ServerIdentificationModel[] = [];

  private _identificationGroup: IdentificationGroup;

  public get filteredIdentificationGroups(): ServerIdentificationModel[] {
  
    if(this.filteredList) {
      this.serverList = this.filteredList
      this.identificationGroupItems = this.serverList
    } else {
      this.serverList = this.originalList.serverIdentifications;
      this.identificationGroupItems = this.originalList.serverIdentifications;
    }
    if (this.filterText) { 
      return this.serverList.filter(item => {
        const filterTextLowerCase = this.filterText.toLowerCase();
        return item.generation.toLowerCase().includes(filterTextLowerCase) ||
          item.diagnosticFamily.toLowerCase().includes(filterTextLowerCase) ||
          (item.formattedExpression && item.formattedExpression.toLowerCase().includes(filterTextLowerCase)) ||
          item.specificationVersion.name.toLowerCase().includes(filterTextLowerCase);
      });
    } else {
      return this.serverList;
    }
  }

  private _filterText = '';
  public get filterText(): string {
    return this._filterText;
  }
  public set filterText(v: string) {
    this._filterText = v;
  }

  private _selectedFilterType: number;
  public get selectedFilterType(): number {
    return this._selectedFilterType;
  }

  public set selectedFilterType(v: number) {
    this.filterText = '';
    this._selectedFilterType = v;
    this.updateServersToShow();
    this.refreshServerListEventEmitter.emit();
  }

  constructor(private authService:AuthService) {
    this.selectedFilterType = 1;

    this.filterTextChanged.pipe(
      debounceTime(300),
      distinctUntilChanged()).subscribe(filterText => {
        this.filterText = filterText;
      });
  }

  get inWorkIdentifications() {
    const specificationNames: string[] = [];
    this.identificationGroup.serverIdentifications.filter(x => x.specificationVersion.releaseStatus === 0).forEach(x => {
      if (specificationNames && !specificationNames.includes(x.specificationVersion.specification.name)) {
        specificationNames.push(x.specificationVersion.specification.name);
      } else if (specificationNames == null) {
        specificationNames.push(x.specificationVersion.specification.name);
      }
    });
    return specificationNames;
  }

  public updateServersToShow(): void {
    if (!this.identificationGroup) {
      return;
    }

    this.serversToShow = [];
    const relevantServerContainers = new Array<{ specId: number; servers: ServerIdentificationModel[] }>();
    const filteredServersByReleaseType = this.originalList.serverIdentifications.filter(server => {
      if (this.selectedFilterType === 0) {
        return server.specificationVersion.releaseStatus !== 0;
      } else {
        return server;
      }
    });
      this.filteredList = filteredServersByReleaseType ;
      this.identificationGroupItems = filteredServersByReleaseType;

    filteredServersByReleaseType.forEach(serverIdentification => {
      const existingServerIdentificationContainer = relevantServerContainers
        .find(relevantServer => relevantServer.specId === serverIdentification.specificationVersion.specification.id);

      if (existingServerIdentificationContainer) {
        if (this.selectedFilterType === 1 && serverIdentification.specificationVersion.releaseStatus === 0) {
          existingServerIdentificationContainer.servers.push(serverIdentification);
        } else {
          // Get latest released servers in this container
          const releasedServersInContainer = existingServerIdentificationContainer.servers
            .filter(server => server.specificationVersion.releaseStatus === 1);

          if (releasedServersInContainer && releasedServersInContainer.length > 0) {
            const serverVersionName = +releasedServersInContainer[0].specificationVersion.name;
            if (serverVersionName < +serverIdentification.specificationVersion.name) {
              // Remove any previous existing released server(s) since the new one is newer
              releasedServersInContainer.forEach(releasedServer => {
                const itemToRemoveIndex = existingServerIdentificationContainer.servers
                  .findIndex(server => server.id === releasedServer.id);
                existingServerIdentificationContainer.servers.splice(itemToRemoveIndex, 1);
              });
              // Add the newer server
              existingServerIdentificationContainer.servers.push(serverIdentification);
            }
          } else {
            existingServerIdentificationContainer.servers.push(serverIdentification);
          }
        }
      } else {
        const newServerIdentificationContainer =
        {
          specId: serverIdentification.specificationVersion.specification.id,
          servers: [serverIdentification]
        };
        relevantServerContainers.push(newServerIdentificationContainer);
      }
    });

    // Flatten Servers from all containers

    relevantServerContainers.forEach(serverContainer => this.serversToShow = this.serversToShow.concat(serverContainer.servers));

    // return relevantServers;
  }

  get settingsInitiatorForServerIdentifications() {
    return (serverIdentification: ServerIdentificationModel, settings: CommonListElementSettings) => {
      settings.allowDrop = true;
      settings.draggable = true;
      if (this.highlightedServerIdentifications && this.highlightedServerIdentifications.some(x => x.id === serverIdentification.id)) {
        settings.highlighted = true;
      }
      settings.isVisible = this.serversToShow.some(x => x.id === serverIdentification.id);
    };
  }

  orderChanged(serverIdentifications) {
    this.orderChangedEventEmitter.emit(serverIdentifications);
  }

  writeValue(value: string): void {
    this.filterText = value ? value : '';
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.onDisabled = isDisabled;
  }
  canEditIdentificationsGroup():boolean{
    return this.authService.canEditIdentificationsGroup();
  }

}
