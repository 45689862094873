import { Component } from '@angular/core';
import { MessageTypeEnum } from 'app/modules/shared/model/message/messageTypeEnum';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-message-template-component',
  templateUrl: 'message-template-component.html',
  styles: [
    'img {width: 25px;}',
    'b {color: white;}',
    'a {color: white;}'
  ]
})
export class MessageTemplateComponent {
  private _details: string;

  messageType: MessageTypeEnum;
  label: string;
  message: string;
  dismissable: boolean;
  showDetailsExpanded: boolean;
  dismiss: Subject<void> = new Subject();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  MessageTypeEnum = MessageTypeEnum;

  public get details(): string {
    return this._details
      ?.replace(/(?:\r\n|\r|\n)/g, '<br>')
      ?.replace(/(?:\t)/g, '&emsp;')
      ?? '';
  }
  public set details(v: string) {
    this._details = v;
  }

  toggleDetails() {
    this.showDetailsExpanded = !this.showDetailsExpanded;
  }

  onDismiss() {
    this.dismiss.next();
  }
}

