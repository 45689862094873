import { Injectable } from '@angular/core';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { Rbac, RbacGeneration, RbacSpecificationVersion } from 'app/modules/shared/model/rbac';
import { ApiProxyService } from 'app/modules/shared/services/api-proxy.service';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class RbacService {

  public rbacItems: Rbac[] = [];

  constructor(private apiProxy: ApiProxyService, private authService: AuthService, private fileService: FileService) { }

  public get hasFecthedRbaccFiles(): boolean {
    return this.rbacItems && this.rbacItems.length > 0;
  }

  public getRbacs(): Observable<Rbac[]> {
    if (this.hasFecthedRbaccFiles) {
      return of(this.rbacItems);
    }

    return this.apiProxy.get<Rbac[]>(environment.apiUrl + 'rbac')
      .pipe(tap(rbaccFiles => this.rbacItems = rbaccFiles));
  }

  public createRbacFile(file: Rbac): Observable<Rbac> {
    return this.apiProxy.post<Rbac>(environment.apiUrl + 'rbac', file)
      .pipe(tap(createdRbacc => this.rbacItems.push(createdRbacc)));
  }

  public AddRbac(file: Rbac): Observable<Rbac> {
    return this.apiProxy.post<Rbac>(environment.apiUrl + 'rbac', file);
  }

  public AddRbacToLocalCache(file: Rbac) {
    if (this.rbacItems.findIndex(f => f.rbacGuid === file.rbacGuid) > 0) {
      return;
    }
    this.rbacItems.push(file);
  }

  // public deleteRbacFile(id: string): Observable<Rbac> {
  //   return this.apiProxy.delete<Rbac>(environment.apiUrl + 'rbac/' + id)
  // }

  public createGenConnection(rbacId: string, genId: number): Observable<RbacGeneration> {
    // return this.apiProxy.post<RbacGeneration>(environment.apiUrl + 'rbac/connectrbactogeneration/' + genId + '/' + rbacId, undefined);
    return this.apiProxy.post<RbacGeneration>(environment.apiUrl + 'rbac/connectGeneration/' + rbacId + '/' + genId, undefined);
  }

  public createSpecConnection(rbacId: string, specId: number): Observable<RbacSpecificationVersion> {
    return this.apiProxy.post<RbacSpecificationVersion>(environment.apiUrl + 'rbac/connectSpecification/' + rbacId + '/' + specId, undefined);
  }

  public getByRbacGuid(rbacId: string): Rbac {
    if (this.hasFecthedRbaccFiles) {
      const rbaccFile = this.rbacItems.find(rbaccItem => rbaccItem.rbacGuid === rbacId);
      return rbaccFile;
    }
    // return this.apiProxy.get<Rbac>(environment.apiUrl + 'rbac/byrbacguid/' + rbacId)
  }

  // public getRbacFromAPI(rbacId: string): Observable<Rbac> {
  //   return this.apiProxy.get<Rbac>(environment.apiUrl + 'rbac/rbacGuid/' + rbacId);
  // }

  public getRbacFromAPI(rbacId: string): Observable<Rbac> {
    return this.apiProxy.get<Rbac>(`${environment.apiUrl}rbac/${rbacId}`);
  }



  public getRbaccFileAsXml(rbacId: string): Observable<string> {
    return this.apiProxy.getString(environment.apiUrl + 'rbac/download/' + rbacId);

  }

  public deleteGenConnection(genId: number): Observable<RbacGeneration> {
    return this.apiProxy.delete<RbacGeneration>(environment.apiUrl + 'rbac/disconnectGeneration/' + genId);
  }
  public deleteSpecConnection(specId: number): Observable<RbacSpecificationVersion> {
    return this.apiProxy.delete<RbacSpecificationVersion>(environment.apiUrl + 'rbac/disconnectSpecificationVersion/' + specId);
  }

  public validateRbacFile(rbacFile: { fileName: string, fileData: File }): Observable<{ isValidFile: boolean, isValidContent: boolean, validationMessage: string }> {
    if (!this.fileService.isCompatibleRbacFile(rbacFile.fileName)) {
      return of({ isValidFile: false, isValidContent: true, validationMessage: `The selected file is not a valid Rbacc file. Only files with extension.xml are allowed` })
    }
    return of({ isValidFile: true, isValidContent: true, validationMessage: `` })

  }
  public getRbacVersionGuid(guid: string): Observable<Rbac> {
    let data = this.apiProxy.get<Rbac>(environment.apiUrl + 'rbac/versionGuid/' + guid).pipe(
      tap((res) => console.log('Succesfull 1st service result', res)),
      catchError((error) => {
        if (error) {
          return of(undefined);
        } else {
          throw error;
        }
      }))
    return data;
  }

  public UpdateRbac(file: Rbac): Observable<Rbac> {
    return this.apiProxy.put<Rbac>(environment.apiUrl + 'rbac', file);
  }


  public SubmitRbacForReview(rbacGuid: string): Observable<Rbac> {
    return this.apiProxy.put(`${environment.apiUrl}rbac/submitForReview/${rbacGuid}`, rbacGuid);
  }

  public ApproveRbacc(rbacGuid: string): Observable<Rbac> {
    return this.apiProxy.put(`${environment.apiUrl}rbac/approve/${rbacGuid}`, rbacGuid);
  }

  public RejectRbacc(rbacGuid: string): Observable<Rbac> {
    return this.apiProxy.put(`${environment.apiUrl}rbac/reject/${rbacGuid}`, rbacGuid);
  }


}


