import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ParameterService } from 'app/app-services/parameter-service';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { ParameterType } from 'app/modules/shared/model/service/parameters/parameter-type';

@Component({
  selector: 'app-parameter-info',
  templateUrl: './parameter-info.component.html',
  styleUrls: ['./parameter-info.component.css']
})
export class ParameterInfoComponent {

  @Input()
  isOdxBasedSpecification: boolean;

  @Input()
  isAllowed: boolean;

  @Output()
  parameterTypeChanged: EventEmitter<void> = new EventEmitter();

  @Output()
  parameterChanged: EventEmitter<void> = new EventEmitter();

  previousConstantValue: string;
  private _parameter: ParameterData;

  constructor(private parameterService: ParameterService) {
  }

  public get parameter(): ParameterData {
    return this._parameter;
  }

  @Input()
  public set parameter(v: ParameterData) {
    this._parameter = v;
    this.previousConstantValue = this.paramRawVal;
  }

  public get parameterTypes(): string[] {
    return this.parameterService.getParameterTypes();
  }

  public get paramName(): string {
    return this.parameter.name;
  }

  public set paramName(v: string) {
    this.parameter.name = v;
  }

  public get paramRawVal(): string {
    return this.convertRawValToPresentationVal();
  }

  get isValueParameter(): boolean {
    return this.parameter.model.type === ParameterType.Value;
  }

  get isConstantParameter(): boolean {
    return this.parameter.model.type === ParameterType.Constant;
  }

  get isReservedParameter(): boolean {
    return this.parameter.model.type === ParameterType.Reserved;
  }

  public set paramRawVal(v: string) {
    this.parameter.model.name = '';
    if (!v) {
      v = '0';
    }

    this.setParameterValueFromInput(v);
  }

  private convertRawValToPresentationVal() {
    if (this.parameter.hasNumericalPhysicalDataType()) {
      return `0x${(+this.parameter.rawValue).toString(16).padStart(2, '0').toUpperCase()}`;
    } else {
      return this.parameter.rawValue;
    }
  }

  private setParameterValueFromInput(constantValue: string) {
    this.previousConstantValue = this.paramRawVal;
    if (constantValue === this.previousConstantValue) {
      return;
    }

    if (this.parameter.hasNumericalPhysicalDataType()) {
      if (this.parameterService.isNumericInput(constantValue)) {
        this.parameter.rawValue = constantValue;
        this.parameterChanged.next();
      } else if (this.parameterService.isHexInput(constantValue)) {
        // eslint-disable-next-line radix
        this.parameter.rawValue = Number.parseInt(constantValue, undefined).toString();
        this.parameterChanged.next();
      } else {
        this.parameter.rawValue = '0';
      }
    } else {
      this.parameter.rawValue = constantValue;
      this.parameterChanged.next();
    }
  }
}
