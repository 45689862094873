import { Injectable } from '@angular/core';
import { DiagnosticServiceModel } from 'app/app-model/diagnostic-service/diagnostic.service.model';
import { ParameterDataModel } from 'app/app-model/diagnostic-service/parameter-data.model';
import { RequiredServicesModel } from 'app/app-model/diagnostic-service/required-services.model';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';
import { DiagnosticServiceView, ServiceViewBuilder } from 'app/modules/shared/model/service/diagnostic-service-view';
import { MessageData } from 'app/modules/shared/model/service/message-data';
import { MessageType } from 'app/modules/shared/model/service/message-type';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Observer, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { DiagnosticServiceViewModel } from '../app-model/diagnostic-service/diagnostic-service-view.model';
import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { CategoryServiceBase } from './category-base.service';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticServiceProvider extends CategoryServiceBase<DiagnosticServiceViewModel, DiagnosticServiceCategoryItem>  {

  views: DiagnosticServiceView[] = [];
  viewIsUpdated: boolean[] = [];
  newItemPreview: boolean;
  serviceRemoved: BehaviorSubject<number> = new BehaviorSubject(0);
  serviceParametersChanged: BehaviorSubject<void> = new BehaviorSubject(undefined);
  serviceChanged: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(protected apiProxy: ApiProxyService, private messageService: MessageService) {
    super(apiProxy);
    this.apiCategoryName = 'services';
  }

  public getRequiredDiagnosticServiceList(specificationId: number): Observable<RequiredServicesModel> {
    return this.apiProxy.get<RequiredServicesModel>(environment.apiUrl + 'services/required/' + specificationId);
  }

  public getDiagnosticServicesByView(specificationVersionId: number, view: number): Observable<DiagnosticServiceView> {
    const cachedView = this.views[view];
    if (cachedView && !this.viewIsUpdated[view]) {
      return of(cachedView);
    } else {
      this.viewIsUpdated[view] = false;

      return this.getItemsPreview(specificationVersionId)
        .pipe(
          map(items => new ServiceViewBuilder()
            .type(view)
            .services(items.map(item => item.model))
            .build()),
          tap(v => this.views[view] = v)
        );
    }
  }

  /**
   * Gets a preview of all available diagnostic services for the specified specification.
   * A preview contains the name and its unique id only
   */
  public getItemsPreview(specificationVersionId: number): Observable<DiagnosticServiceCategoryItem[]> {
    this.isPending = true;
    if (this.hasCachedItems && !this.newItemPreview) {
      this.isPending = false;
      return of(this.items);
    } else {
      return this.apiProxy.get<DiagnosticServiceModel[]>(environment.apiUrl + 'services/versionServices/' + specificationVersionId)
        .pipe(map(services => {
          this.items = new Array<DiagnosticServiceCategoryItem>();
          if (services) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let serviceIndex = 0; serviceIndex < services.length; serviceIndex++) {
              this.addDiagnosticServiceToCache(services[serviceIndex]);
            }
          }
          this.isPending = false;
          this.hasCachedItems = true;
          this.getItemsPreviewDone.next(this.isPending);
          this.newItemPreview = false;
          return this.items;
        }));
    }
  }

  public getItem(specificationVersionId: number, itemName: string): Observable<DiagnosticServiceCategoryItem> {
    const cachedItem = this.findCachedItem(itemName);

    if (cachedItem && !cachedItem.isPreview) {
      return of(cachedItem);
    } else {
      return this.apiProxy.get<DiagnosticServiceModel>(environment.apiUrl + 'services/' + specificationVersionId + '/' + itemName)
        .pipe(map(diagServiceModel => {
          cachedItem.isPreview = false;
          cachedItem.model = diagServiceModel;

          return cachedItem;
        }));
    }
  }

  public getDiagnosticServiceByName(specificationVersionId: number, name: string): Observable<DiagnosticServiceCategoryItem> {
    let cachedItem = this.findCachedItem(name);

    if (cachedItem && !cachedItem.isPreview) {
      return of(cachedItem);
    } else {
      return this.apiProxy.get<DiagnosticServiceModel>(environment.apiUrl + 'services/' + specificationVersionId + '/' + name)
        .pipe(map(diagServiceModel => {
          cachedItem = this.addDiagnosticServiceToCache(diagServiceModel);
          return cachedItem;
        }));
    }
  }

  public getCategoryItemModel(specificationVersionId: number, name: string): Observable<DiagnosticServiceCategoryItem> {
    throw new Error('Method not implemented.');
  }

  public getCompleteItems(specificationVersionId: number): Observable<DiagnosticServiceCategoryItem[]> {
    throw new Error('Method not implemented.');
  }

  public createItem(specificationVersionId: number, itemName?: string): Observable<DiagnosticServiceCategoryItem> {

    const itemToCreateModel = new DiagnosticServiceModel();
    itemToCreateModel.name = itemName;
    itemToCreateModel.specificationVersionId = specificationVersionId;

    const requestMessage = new MessageData();
    requestMessage.messageType = MessageType.Request;
    requestMessage.parameters = [];

    const responseMessage = new MessageData();
    responseMessage.messageType = MessageType.Response;
    responseMessage.parameters = [];

    this.setViewsToIsUpdated();
    this.newItemPreview = true;

    return new Observable((observer: Observer<DiagnosticServiceCategoryItem>) => {
      this.apiProxy.post<DiagnosticServiceModel>(environment.apiUrl + 'services/', itemToCreateModel).subscribe({
        next: (item) => {
          const categoryItem = new DiagnosticServiceCategoryItem(item);
          categoryItem.isPreview = false;
          this.items.push(categoryItem);

          requestMessage.serviceId = item.id;
          responseMessage.serviceId = item.id;

          this.apiProxy.post<MessageData>(environment.apiUrl + 'messages/', requestMessage).subscribe({
            next: (requestMessageModel) => {
              this.apiProxy.post<MessageData>(environment.apiUrl + 'messages/', responseMessage).subscribe({
                next: (responseMessageModel) => {
                  categoryItem.model.messages = [requestMessageModel, responseMessageModel];
                  observer.next(categoryItem);
                  observer.complete();
                }
              });
            }
          });
        }
      });
    });
  }

  public deleteItem(specificationVersionId: number, itemName: string): Observable<any> {
    return this.apiProxy.delete(environment.apiUrl + 'services/' + specificationVersionId + '/' + itemName).pipe(map(x => {
      this.removeCachedItem(itemName);
      this.setViewsToIsUpdated();
      this.newItemPreview = true;
      return x;
    }));
  }

  public updateItem(specificationVersionId: number, itemModel: DiagnosticServiceViewModel, itemName?: string, itemId?: number): Observable<DiagnosticServiceViewModel> {
    this.setViewsToIsUpdated();
    this.newItemPreview = true;
    throw new Error('Method not implemented.');
  }

  public updateDiagnosticService(itemModel: DiagnosticServiceModel): Observable<DiagnosticServiceModel> {
    return this.apiProxy.put(environment.apiUrl + 'services/' + itemModel.id, itemModel).pipe(map(serviceModel => {
      this.setViewsToIsUpdated();
      this.newItemPreview = true;
      this.serviceChanged.next(0);

      const cachedItem = this.findCachedItemById(itemModel.id) as DiagnosticServiceCategoryItem;
      cachedItem.name = serviceModel.name;
      cachedItem.model.name = serviceModel.name;

      return serviceModel;
    }));
  }

  public saveParameter(parameterModel: ParameterDataModel, serviceName: string): Observable<ParameterDataModel> {
    return this.apiProxy.post(environment.apiUrl + 'parameters/', parameterModel).pipe(map(parameter => {
      this.setViewsToIsUpdated();
      this.serviceParametersChanged.next(undefined);
      return parameter;
    }));
  }

  public removeParameter(paramId: number): any {
    return this.apiProxy.delete(environment.apiUrl + 'parameters/' + paramId).pipe(map(parameter => {
      this.setViewsToIsUpdated();
      this.serviceParametersChanged.next(undefined);
      return parameter;
    })).pipe(catchError(() => {
      this.messageService.
        dispatchErrorMessage('Parameter you are trying to remove has reference(s) to other parameters. To remove this parameter please remove this reference first');
      throw (new Error());
    }));
  }

  // Modification
  public updateParameter(parameterModel: ParameterDataModel, serviceName: string): Observable<ParameterDataModel> {
    return this.apiProxy.put(environment.apiUrl + 'parameters/' + parameterModel.id, parameterModel).pipe(map(parameter => {
      this.updateCachedItem(parameter);
      this.setViewsToIsUpdated();
      this.sortServiceParametersByBitAndBytePostion(serviceName, parameterModel.messageId);
      this.serviceParametersChanged.next(undefined);
      return parameter;
    })).pipe(catchError((err) => {
      this.messageService.dispatchErrorMessage(err.detailedMessage);
      throw (new Error());
    }));
  }

  public copyService(itemToCopyId: number, specificationVersionId: number): Observable<DiagnosticServiceCategoryItem> {
    return this.apiProxy.post<DiagnosticServiceModel>(environment.apiUrl + 'services/copy/' + itemToCopyId + '/' + specificationVersionId, undefined)
      .pipe(
        map(serviceCopy => {
          const categoryItem = this.addDiagnosticServiceToCache(serviceCopy);

          return categoryItem;
        }),
        tap(serviceCopy => {
          this.setViewsToIsUpdated();
          this.newItemPreview = true;
        }));
  }

  public sortServiceParametersByBitAndBytePostion(serviceName: string, messageId: number): void {
    if (serviceName) {
      const affectedService = this.items.find(item => item.name === serviceName);

      if (affectedService) {
        const affectedMessage = affectedService.model.messages.find(message => message.id === messageId);

        if (affectedMessage) {
          affectedMessage.parameters.sort((param1, param2) => param1.bytePosition - param2.bytePosition);
          affectedMessage.parameters.sort((param1, param2) => param1.bitPosition - param2.bitPosition);
        }
      }
    }
  }

  public reset(): void {
    super.reset();
    this.views = [];
  }

  public nameIsAvailable(name: string): boolean {
    return !this.items.find(item => item.name === name);
  }

  public setPreviewFlag(diagServiceName: string, isPreview: boolean): void {
    const diagService = this.items.find(item => item.name === diagServiceName);
    diagService.isPreview = isPreview;
  }

  public findCachedItem(itemName: string, itemId?: number): DiagnosticServiceCategoryItem {
    if (itemId) {
      return this.items.find(item => item.name === itemName && item.id == itemId);
    }

    return this.items.find(item => item.name === itemName);
  }

  protected setCategoryItemModelFromApi(itemName: string, categoryItem: any): Observable<void> {
    return this.apiProxy.get<DiagnosticServiceModel>(environment.apiUrl + this.apiCategoryName + '/' + itemName).pipe(map(service => {
      for (let i = 0; i < this.views.length; i++) {
        this.viewIsUpdated[i] = true;
      }

      categoryItem.isPreview = false;
      categoryItem.model = service;
    }));
  }

  private updateCachedItem(paramToUpdate: ParameterDataModel): void {
    const serviceToUpdate = this.items.find(service => service.getMessageById(paramToUpdate.messageId));
    const messageToUpdate = serviceToUpdate.getMessageById(paramToUpdate.messageId);
    const indexOfParamToUpdate = messageToUpdate.parameters.findIndex(param => param.id === paramToUpdate.id);

    if (indexOfParamToUpdate >= 0) {
      messageToUpdate.parameters[indexOfParamToUpdate] = paramToUpdate;
    }
  }

  private setViewsToIsUpdated(): void {
    for (let i = 0; i < this.views.length; i++) {
      this.viewIsUpdated[i] = true;
    }
  }

  private addDiagnosticServiceToCache(serviceModel: DiagnosticServiceModel): DiagnosticServiceCategoryItem {
    const cachedDiagnosticService = this.findCachedItem(serviceModel.name, serviceModel.id);

    if (!cachedDiagnosticService) {
      const diagnosticServiceItem = new DiagnosticServiceCategoryItem(serviceModel, false);
      diagnosticServiceItem.syncFromModel();
      this.items.push(diagnosticServiceItem);
      return diagnosticServiceItem;
    }

    return cachedDiagnosticService;
  }
}
