import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appInWork]'
})
export class InWorkDirective {

  constructor(element: ElementRef) {
    element.nativeElement.style.color = '#60b269';
    element.nativeElement.style.fontWeight = 'bold';
  }

}
