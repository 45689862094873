import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-remove-item-confirm',
  templateUrl: './remove-item-confirm.component.html',
  styleUrls: ['./remove-item-confirm.component.css']
})
export class RemoveItemConfirmComponent {

  @Input()
  itemToRemoveName: string;

  @Output()
  removeConfirm: EventEmitter<any> = new EventEmitter();

  confirmRemove() {
    this.removeConfirm.emit();
  }
}
