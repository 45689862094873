import { Label } from 'app/app-model/label/label';

import { ScommSharedFile } from './legacy-file';

export class ScommSharedFileVersion {
    id: number;
    sharedFileId: number;
    file: ScommSharedFile;
    revision: number;
    labels: Label[] = [];
}