import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TaskStatusService } from 'app/app-services/task-status-service';
import { Subscription } from 'rxjs';

import { TaskStatusModel } from './task-status-model';

@Component({
  selector: 'app-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.css']
})
export class TaskStatusComponent implements OnInit, OnDestroy {

  @Output()
  setTask: EventEmitter<string> = new EventEmitter();

  subscriptions: Subscription[] = new Array<Subscription>();
  taskStatus: string;
  isBusy: boolean;
  setTaskSubscription: Subscription;
  taskCompleteSubscription: Subscription;

  constructor(private taskStatusService: TaskStatusService) { }

  ngOnInit() {
    this.model.addDefaultSubscriptions();
    this.setTaskSubscription = this.model.setTask.subscribe(
      {
        next: (newTaskStatus) => {
          this.taskStatus = newTaskStatus;
          this.isBusy = true;
        }
      });
    this.taskCompleteSubscription = this.model.taskComplete.subscribe({
      next: (newTaskStatus) => {
        this.isBusy = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.setTaskSubscription.unsubscribe();
    this.taskCompleteSubscription.unsubscribe();
  }

  get model(): TaskStatusModel {
    return this.taskStatusService.model;
  }

}
