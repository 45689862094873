import { DiagnosticProtocolType, ServerExecutionModeType } from './enums';

export class SpecificationInfo {
    id: number;
    name: string;
    diagnosticProtocol: DiagnosticProtocolType;
    serverExecutionMode: ServerExecutionModeType;
}

export class NewSpecificationInfo {
    name: string;
    diagnosticProtocol: DiagnosticProtocolType;
    serverExecutionMode: ServerExecutionModeType;
    includeInScomm: boolean;
    specificationVersionTocloneFromId: number;
}
