/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export class TypedValueData {
    dataType: OdxDataType;
    data: string;
}

export enum OdxDataType {
    Int32 = 0,
    UInt32,
    Float32,
    Float64,
    ByteField,
    AsciiString,
    Utf8String,
    UnicodeString,
    ScaniaStateC,
    ScaniaStateD
}
