<div class="sdds-row mt-16 mb-16">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <h3>Connected Diagnostic Services</h3>
    </div>

    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <button class="sdds-btn sdds-btn-secondary sdds-btn-sm mb-16" id="serviceSelectModal"
            [disabled]="isAddButtonDisabled || !canEditCategoryItems()"
            matTooltip="Assign a diagnostic service to this diagnostic concept">
            <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon> Add
        </button>
    </div>

    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding"
        *ngIf="serviceExecutionSequence" [ngClass]="{'loading': !serviceExecutionSequence.isSyncDone}">
        <app-service-selector (diagnosticServicesAdded)="onDiagnosticServicesAdded($event)"
            [modalName]="'serviceSelectModal'" [currentServiceExecutions]="serviceExecutionSequence.serviceExecutions"
            [activeFunctionName]="getActiveFunctionName()">
        </app-service-selector>

        <app-service-parameters-table [isReadOnly]="isReadOnly" [serviceExecutionSequence]="serviceExecutionSequence"
            [propertyType]="propertyType" *ngIf="serviceExecutionSequence.serviceExecutions.length > 0">
        </app-service-parameters-table>
    </div>

    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding"
        *ngIf="!serviceExecutionSequence || serviceExecutionSequence.serviceExecutions.length <= 0">
        <div class="sdds-banner sdds-banner-info">
            <div class="sdds-banner-body">
                No services to execute where found. Add a new service to execute by selecting the <i>Add</i> option
                above.
            </div>
        </div>
    </div>
</div>