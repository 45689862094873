<div class="sdds-card mb-16">
    <div class="sdds-card-header">
        <h5 class="sdds-card-headline">Position and Length</h5>
    </div>

    <div class="sdds-card-body mt-16">
        <div class="sdds-row mb-16">
            <div
                class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-12 sdds-col-xs-12">
                <div class="sdds-checkbox-item">
                    <input class="sdds-form-input" type="checkbox" id="cbxHasMinMaxLength" [(ngModel)]="hasMinMaxLength"
                        [disabled]="!parameter.canEnableMinMaxLength || isOdxBasedSpecification || !isAllowed"
                        (change)="parameterChanged.next()">
                    <label class="sdds-form-label" for="cbxHasMinMaxLength">Has MinMax length</label>
                </div>
            </div>

            <div
                class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-9 sdds-col-md-9 sdds-col-sm-12 sdds-col-xs-12">
                <div class="sdds-banner">
                    <div class="sdds-banner-body">
                        Min Max Length requires internal data type <b>AsciiString</b> or <b>ByteField</b> in order to be
                        enabled.
                    </div>
                </div>
            </div>
        </div>

        <div class="sdds-row">
            <div class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-5 sdds-col-sm-12 sdds-col-xs-12"
                style="padding-right: 3rem !important;">
                <h5>Position</h5>

                <div class="sdds-row mb-16">
                    <div
                        class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                        <label for="bytePosition">Byte position</label>
                    </div>

                    <div
                        class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-on-white-bg">
                        <input name="bytePosition" class="sdds-textfield-input-md" type="number"
                            [(ngModel)]="parameter.model.bytePosition" (change)="parameterChanged.next()"
                            [readOnly]="isOdxBasedSpecification || !isAllowed">
                    </div>
                </div>

                <div class="sdds-row mb-16">
                    <div
                        class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                        <label for="bitPosition">Bit position</label>
                    </div>

                    <div
                        class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-on-white-bg">
                        <input name="bitPosition" class="sdds-textfield-input-md" type="number"
                            [(ngModel)]="parameter.model.bitPosition" (change)="parameterChanged.next()"
                            [readOnly]="isOdxBasedSpecification || !isAllowed">
                    </div>
                </div>

                <div class="sdds-row" *ngIf="!hasMinMaxLength">
                    <div
                        class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                        <label for="bitLength">Bit length</label>
                    </div>

                    <div
                        class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-on-white-bg">
                        <input name="bitLength" class="sdds-textfield-input-md" type="number"
                            [(ngModel)]="parameter.model.length.bitLength" (change)="parameterChanged.next()" min="1"
                            [readOnly]="isOdxBasedSpecification || !isAllowed">
                    </div>
                </div>
            </div>

            <div class="sdds-col-max-7 sdds-col-xxlg-7 sdds-col-xlg-7 sdds-col-lg-7 sdds-col-md-7 sdds-col-sm-12 sdds-col-xs-12"
                style="padding-left: 3rem !important;">
                <ng-container *ngIf="hasMinMaxLength">
                    <h5>Length</h5>

                    <div class="sdds-row mb-16">
                        <div
                            class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                            <label for="minByteLength">Min byte length</label>
                        </div>

                        <div
                            class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-on-white-bg">
                            <input name="minByteLength" class="sdds-textfield-input-md" type="number" min="0"
                                [(ngModel)]="parameter.model.length.minByteLength" (change)="parameterChanged.next()"
                                [readOnly]="isOdxBasedSpecification || !isAllowed">
                        </div>
                    </div>

                    <div class="sdds-row mb-16" *ngIf="hasMaxByteLength">
                        <div
                            class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                            <label for="maxByteLength">Max byte length</label>
                        </div>

                        <div
                            class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-on-white-bg">
                            <input name="maxByteLength" class="sdds-textfield-input-md" type="number" min="0"
                                [(ngModel)]="parameter.model.length.maxByteLength" (change)="parameterChanged.next()"
                                [readOnly]="isOdxBasedSpecification || !isAllowed">
                        </div>
                    </div>

                    <div class="sdds-row mb-16">
                        <div
                            class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                            <label for="terminationSelector">Termination</label>
                        </div>

                        <div
                            class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-dropdown sdds-dropdown-small sdds-on-white-bg">
                            <select name="terminationSelector" [(ngModel)]="lengthTermination"
                                (change)="parameterChanged.next()" [disabled]="isOdxBasedSpecification || !isAllowed">
                                <option
                                    *ngFor="let termination of parameterService.getLenghtTerminations(parameter.model.length.termination)">
                                    {{termination}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="sdds-row mb-16">
                        <div
                            class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                            <div class="sdds-checkbox-item">
                                <input class="sdds-form-input" type="checkbox" id="cbxHasMaxLength"
                                    [(ngModel)]="hasMaxByteLength"
                                    [disabled]="!hasMinMaxLength || isOdxBasedSpecification || !isAllowed"
                                    (change)="parameterChanged.next()">
                                <label class="sdds-form-label" for="cbxHasMaxLength">Has Max Length</label>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>