import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LegacyServerFile } from 'app/app-model/legacy-file';
import { DiffFileService } from 'app/app-services/diff-file.service';
import { LegacyFileModificationService } from 'app/app-services/legacy-file-modification.service';

@Component({
  selector: 'app-scomm-file-selector',
  templateUrl: './scomm-file-selector.component.html',
  styleUrls: ['./scomm-file-selector.component.css']
})
export class ScommFileSelectorComponent implements OnInit {

  @Output()
  fileSelected: EventEmitter<string> = new EventEmitter();

  private _selectedFile: LegacyServerFile;
  public get selectedFile(): LegacyServerFile {
    return this._selectedFile;
  }
  public set selectedFile(v: LegacyServerFile) {
    this._selectedFile = v;
    this.notifyFileSelected();
  }

  get availableFiles(): Array<LegacyServerFile> {
    return this.diffFileService.diffableFiles;
  }

  constructor(private diffFileService: DiffFileService, private legacFileModificationService: LegacyFileModificationService) { }

  ngOnInit() {
    this.getAvailableFiles();
  }

  getAvailableFiles() {
    // if (!this.diffFileService.loading) {
    //   this.diffFileService.loading = true;
    this.diffFileService.getAllDiffableFilesPreview(this.legacFileModificationService.currentLegacyServerFile.id).subscribe(() => {
      // this.diffFileService.loading = false;
    });
    // }
  }

  notifyFileSelected() {
    this.diffFileService.loading = true;
    this.diffFileService.getCompleteFile(this.selectedFile).subscribe(file => {
      this.diffFileService.loading = false;
      this.fileSelected.next(file.data);
    });
  }
}
