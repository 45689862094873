<div *ngIf="operationalDataItem">
  <div>

     <div class="sdds-on-white-bg my-10 ml-10">
      <div class="rows"> <b> Referenced Service(s) </b> </div>
      <div class="sdds-label" *ngFor="let serviceExecution of operationalDataItem.serviceExecutionSequence.serviceExecutions">
        <span>{{serviceExecution.diagnosticService?.name}}</span> <br>
      </div>
    </div>


    <div class="sdds-row ml-10">
      <div class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4">
        <b>General</b>
            <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg mt-10">
              <span class="sdds-dropdown-label-outside">Type</span>
              <select [(ngModel)]="operationalDataItem.model.type" name="operationalDataTypes"
                (change)="onDefinitionChanged(true)" [disabled]="isReadOnly||!canEditCategoryItems()">
                <option *ngIf="!operationalDataItem.model.type" selected value></option>
                <option *ngFor="let operationalDataType of getOperationalDataVariableTypeNames()"
                  [ngValue]="OperationalDataVariableType[operationalDataType]">{{operationalDataType}}</option>
              </select>
            </div>

            <div class="sdds-on-white-bg my-10">
              <sdds-textfield ngDefaultControl
                [(ngModel)]="operationalDataItem.model.description"
                type="text"
                size="md"
                [disabled]="isReadOnly||!canEditCategoryItems()"
                (customChange)="onDefinitionChanged(false)">
                <span slot="sdds-label">Short Description</span>
              </sdds-textfield>
            </div>


            <div class="sdds-on-white-bg w-1/6 my-10"
              *ngIf="operationalDataItem.shouldBeEnabledForCurrentODType(OperationalDataDefinitionSection.Columns)">

              <sdds-textfield ngDefaultControl
                [(ngModel)]="operationalDataItem.model.cols"
                type="number"
                size="md"
                [disabled]="isReadOnly||!canEditCategoryItems()"
                (customChange)="onDefinitionChanged(false)">
                <span slot="sdds-label">Columns</span>
              </sdds-textfield>
            </div>

            <div class="sdds-on-white-bg w-1/6 my-10"
                *ngIf="operationalDataItem.shouldBeEnabledForCurrentODType(OperationalDataDefinitionSection.Rows)">

                <sdds-textfield ngDefaultControl
                  [(ngModel)]="operationalDataItem.model.rows"
                  type="number"
                  size="md"
                  [disabled]="isReadOnly||!canEditCategoryItems()"
                  (customChange)="onDefinitionChanged(false)">
                  <span slot="sdds-label">Rows</span>
                </sdds-textfield>
            </div>

            <div class="sdds-on-white-bg w-1/6 my-10"
              *ngIf="operationalDataItem.shouldBeEnabledForCurrentODType(OperationalDataDefinitionSection.PeriodLength)">

              <sdds-textfield ngDefaultControl
                  [(ngModel)]="operationalDataItem.model.periodLength"
                  type="number"
                  size="md"
                  [disabled]="isReadOnly||!canEditCategoryItems()"
                  (customChange)="onDefinitionChanged(false)">
                  <span slot="sdds-label">Period Length</span>
              </sdds-textfield>
            </div>

            <div class="sdds-on-white-bg w-1/6 my-10"
              *ngIf="operationalDataItem.shouldBeEnabledForCurrentODType(OperationalDataDefinitionSection.PeriodUnit)">
              <sdds-textfield ngDefaultControl
                  [(ngModel)]="operationalDataItem.model.periodUnit"
                  type="text"
                  size="md"
                  [disabled]="isReadOnly||!canEditCategoryItems()"
                  (customChange)="onDefinitionChanged(false)">
                  <span slot="sdds-label">Period Unit</span>
              </sdds-textfield>
            </div>

            <div class="sdds-on-white-bg w-1/6 my-10" [ngClass]="{'has-error': operationalDataItem.hasAmbiguousAccUnit}">
              <sdds-textfield ngDefaultControl
                  [(ngModel)]="operationalDataItem.operationalDataVariableUnit"
                  type="text"
                  size="md"
                  [disabled]="isReadOnly||!canEditCategoryItems()"
                  (customChange)="onDefinitionChanged(false)">
                  <span slot="sdds-label">Accumulated Value Unit</span>
              </sdds-textfield>
            </div>
      </div>
      <div class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4">
        <div *ngIf="operationalDataItem.shouldBeEnabledForCurrentODType(OperationalDataDefinitionSection.XAxis)">
          <app-axis-definition axisTag="X" (axisDefinitionChanged)="onDefinitionChanged()"
            [axisModel]="operationalDataItem.model.xAxis"
            [disabled]="operationalDataItem.shouldBeReadOnlyForCurrentODType(OperationalDataDefinitionSection.XAxis) || isReadOnly"
            [isReadOnly]="isReadOnly"></app-axis-definition>
        </div>
      </div>
      <div class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4">
        <div *ngIf="operationalDataItem.shouldBeEnabledForCurrentODType(OperationalDataDefinitionSection.YAxis)">
          <app-axis-definition axisTag="Y" (axisDefinitionChanged)="onDefinitionChanged()"
            [axisModel]="operationalDataItem.model.yAxis" [disabled]="isReadOnly" [isReadOnly]="isReadOnly">
          </app-axis-definition>
        </div>
      </div>
    </div>
  </div>
</div>
