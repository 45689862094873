import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ScommSharedFile } from 'app/app-model/legacy-file';
import { ScommSharedFileVersion } from 'app/app-model/scomm-shared-file-version';
import { SharedFilesEventsService } from 'app/app-services/shared-files-events.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { Tree } from 'app/modules/shared/components/tree/model/tree';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shared-files',
  templateUrl: './shared-files.component.html',
  styleUrls: ['./shared-files.component.css']
})
export class SharedFilesComponent implements OnDestroy {

  fileTree: Tree;
  selectedSharedFile: ScommSharedFile;
  selectedFileVersion: ScommSharedFileVersion;
  fileSpecificationSelectedSubscription: Subscription;
  loadingViews: boolean;

  constructor(private specificationService: SpecificationService,
    private router: Router,
    private sharedFilesEventsService: SharedFilesEventsService,
    private messageService: MessageService,
    private authService:AuthService) { }

  ngOnDestroy(): void {
    this.sharedFilesEventsService.sharedFileSpecificationVersionSelected.next(undefined);
  }

  setSelectedSharedFile(file: ScommSharedFile) {
    this.selectedSharedFile = file;
  }

  setSelectedSharedFileVersion(version: ScommSharedFileVersion) {
    this.selectedFileVersion = version;
  }
  canEditSharedFiles(){
   return  this.authService.canEditSharedFiles();
  }
}
