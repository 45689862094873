<div class="sdds-row bg-color-gray pt-16 mb-16">
  <div
    class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12"
    [ngClass]="{'loading':loading}">

    <h4>Shared Files</h4>

    <app-toolbar [filterEnabled]="true" [removeEventEmitterEnabled]="true" (filterTextEventEmitter)="filter($event);"
      [addDataTargetEnabled]="true" (removeEventEmitter)="removeSelectedView();" [dataTargetEnabledAdd]="true"
      [dataTargetEnabledRemove]="false" [createItemId]="'createSharedFileModal'" [isAllowed]="isAllowed">
    </app-toolbar>

    <div *ngIf="!fileTree || fileTree.isEmpty" class="alert alert-info">
      <span>The are no available files to share. Add Scomm files to proceed.</span>
    </div>

    <div style="overflow-y: auto;max-height:60vh; margin-top: 5px;">
      <ng-container *ngIf="fileTree && !fileTree.isEmpty">
        <app-tree [tree]="fileTree"></app-tree>
      </ng-container>
    </div>

    <app-create-shared-file (fileCreated)="onFileCreated($event);"></app-create-shared-file>

    <a #fileReferenceBrowserRef id="referencingDcodeSpecificationsModal"></a>

    <app-file-reference-browser
      [dcodeReferenceSpecifications]="referencedDcodeSpecifications"></app-file-reference-browser>
  </div>
</div>