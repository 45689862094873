<app-category-header [text]=titleName></app-category-header>
<div class="sdds-row sdds-col-max-12">
  <div class="sdds-banner sdds-banner-info" *ngIf="bannerText">
    <div class="sdds-banner-body">
      <p class="sdds-banner-header">
        {{bannerText}}
      </p>
    </div>
  </div>
  <br />
  <div *ngIf="canSaveChanges()" class="my-10">
    <button 
      class="sdds-btn sdds-btn-primary sdds-btn-sm" 
      [disabled]="readModeOnly||!canEditCategoryItems()" 
      matTooltip="Save changes"
      (click)="saveChanges()"><mat-icon>save</mat-icon></button>

    <button 
      class="sdds-btn sdds-btn-primary sdds-btn-sm ml-12" 
      [disabled]="readModeOnly||!canEditCategoryItems()" 
      matTooltip="Delete document"
      (click)="deleteDocument()"><mat-icon>delete</mat-icon></button>
  </div>

  <div #codeEditor></div>

</div>
