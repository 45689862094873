<div class="sdds-row" *ngIf="computationalMethod">
    <div *ngIf="isTextTable" class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-16">
        <h6>Type: Text Table</h6>
        <table class="table">
            <thead>
                <th>Text</th>
                <th>Range</th>
                <th>Inverse Value</th>
                <th>Inverse Value To Use</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of sortedTextTableIntervals">
                    <td>{{item.text}}</td>
                    <td>
                        <app-range [range]="item.range"></app-range>
                    </td>
                    <td>
                        <span *ngIf="item.inverseInternalValue">{{item.inverseInternalValue.data}}</span>
                        <span *ngIf="!item.inverseInternalValue">-</span>
                    </td>
                    <td>
                        <span *ngIf="item.inverseInternalValue">{{item.inverseInternalValue.data}}</span>
                        <span *ngIf="!item.inverseInternalValue && item.lowerType !== DataLimitType.Open">{{item.range.lowerValue.data}}</span>
                        <span *ngIf="!item.inverseInternalValue && item.lowerType === DataLimitType.Open">-</span>
                    </td>
                </tr>
                <tr *ngIf="hasDefaultInterval">
                    <td>{{defaultIntervalText}}</td>
                    <td>Default</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="isLinearFormulaData" class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-16">
        <h6>Type: Linear Formula</h6>
        <span>{{linearFormula}}</span>
    </div>
</div>