import { Component, OnInit } from '@angular/core';
import { ErrorObjectType, ValidationIssue } from 'app/app-model/validation/validation-issue';
import { IdentificationGroupService } from 'app/app-services/identification-group.service';
import { NavigationService } from 'app/app-services/navigation.service';
import { SpecificationVersionValidationService } from 'app/app-services/specification-version-validation.service';
import { Category } from 'app/specification-structure/category-items/category.enum';

@Component({
  selector: 'app-validation-issue',
  templateUrl: './validation-issue.component.html',
  styleUrls: ['./validation-issue.component.css']
})
export class ValidationIssueComponent implements OnInit {
  warnings = 0;
  errors = 0;
  issues: Array<ValidationIssue>;

  constructor(
    private validationService: SpecificationVersionValidationService,
    private navigationService: NavigationService,
    private identificationGroupService: IdentificationGroupService
  ) { }

  ngOnInit() {
    this.validationService.issueChangedEventEmitter.subscribe(data => {
      this.issues = data.issues.sort(i => i.issue.severity);
      this.errors = data.errors;
      this.warnings = data.warnings;
    });
  }

  getErrorType(type: ErrorObjectType): any {
    switch (type) {
      case ErrorObjectType.Service:
        return { category: Category.Services, value: 'Diagnostic service' };
      case ErrorObjectType.Session:
        return { category: Category.Sessions, value: 'Ecu session' };
      case ErrorObjectType.EcuIdentifier:
        return { category: Category.Identification, value: 'Ecu identifier' };
      case ErrorObjectType.Io:
        return { category: Category.Ios, value: 'Ecu IO' };
      case ErrorObjectType.IoList:
        return { category: Category.Iolists, value: 'Ecu IOList' };
      case ErrorObjectType.OperationalData:
        return { category: Category.OperationalData, value: 'Operational data variable' };
      case ErrorObjectType.Fingerprint:
        return { category: Category.Fingerprints, value: 'Fingerprint' };
      case ErrorObjectType.SecurityAccess:
        return { category: Category.SecurityAccess, value: 'Security Access' };
      case ErrorObjectType.Reset:
        return { category: Category.EcuResets, value: 'Ecu Reset' };
      case ErrorObjectType.Value:
        return {category: Category.Values, value: 'Ecu Value'};
      case ErrorObjectType.FreezeFrame:
        return {category: Category.FreezeFrames, value: 'Freeze Frames'};
      default:
        return {};
      // we might need to check for these types in the future: sequence, parameter, Message, Value
    }
  }

  contextItemName(issue: ValidationIssue): string {
    if (!issue.errorDetails || !issue.errorDetails.errorObjectType) {
      return '-';
    } else {
      const resourceName = this.getErrorType(
        issue.errorDetails.errorObjectType
      )['value'];
      return `${resourceName}: ${issue.errorDetails.objectName}`;
    }
  }

  navigateToContext(issue: ValidationIssue) {
    if (!issue || !issue.errorDetails || !issue.errorDetails.errorObjectType) this.navigationService.navigateToSpecificationHome();

    switch (issue.errorDetails.errorObjectType) {
      case ErrorObjectType.EcuIdentifier:
        this.navigationService.navigateToEcuIdentifier(issue.errorDetails.objectName);
        break;
      case ErrorObjectType.ServerIdentification:
        this.navigationService.navigateToCategory(Category.Servers, issue.errorDetails.objectId.toString());
        break;
      case ErrorObjectType.IdentificationExpression:
        this.identificationGroupService.getServerIdentificationId(issue.errorDetails.objectId).subscribe(
          serverId => { this.navigationService.navigateToCategory(Category.Servers, serverId.toString()); });
        break;
      case ErrorObjectType.Service:
        this.navigationService.navigateToDiagnosticService(issue.errorDetails.objectName);
        break;
      case ErrorObjectType.SpecificationVersion:
        this.navigationService.navigateToSpecificationHome();
      default:
        this.navigationService.navigateToCategoryItemById(this.getErrorType(issue.errorDetails.errorObjectType)['category'], issue.errorDetails.objectName);
    }
  }
}
