<div class="p-6 m-11">
  <a [routerLink]="['./../../']">
    Return
    <mat-icon fontSet="material-icons-outlined" class="md-18 text-black">arrow_forward</mat-icon>
  </a> <br> <br>
  <div *ngIf="dataSource.data.length !== 0">
    <div>
      <sdds-textfield (keyup)="applyFilter($event.target.value)" size="md" type="text" autocomplete="off"
        placeholder="Search ..." id="finder" class="float-left mr-20"></sdds-textfield>
      <sdds-button type="secondary" (click)="applyFilter('')" onclick="document.getElementById('finder').value=''"
        text="Clear" size="sm" class="mt-8 md:my-8"></sdds-button>
    </div> <br> <br>
    <div class="mat-elevation-z8">
      <table mat-table class="full-width-table" matSort aria-label="Modifications" [dataSource]="dataSource">

        <ng-container matColumnDef="userThatModified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
          <td mat-cell *matCellDef="let row">{{row.userThatModified}}</td>
        </ng-container>

        <ng-container matColumnDef="modificationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let row">{{row.modificationDate | date: "yyyy-MM-dd HH:mm"}}</td>
        </ng-container>

        <ng-container matColumnDef="modificationType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Modification</th>
          <td mat-cell *matCellDef="let row">{{modificationType[row.modificationType]}}</td>
        </ng-container>

        <ng-container matColumnDef="modifiedItemName">
          <th mat-header-cell *matHeaderCellDef>Item Name</th>
          <td mat-cell *matCellDef="let row">{{row.modifiedItemName}}
            <!-- <a [routerLink]="['../../', row., row.modifiedItemId]">
                <img src="font/iconsrc/link.png" style="width: 20px; margin-left: 5px;" />
              </a> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="modifiedCategory">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified Category</th>
          <td mat-cell *matCellDef="let row">{{modifiedCategory[row.modifiedCategory]}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="50"
        [pageSizeOptions]="[25, 50, 100, 250]">
      </mat-paginator>
    </div>
  </div>
  <div *ngIf="dataSource.data.length === 0">
    <h1>No Modifications were applied to this specification version</h1>
  </div>
</div>