import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IoCategoryItemDirective } from 'app/data-categories/io/io-category-item';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-function-definition',
  templateUrl: './function-definition.component.html',
  styleUrls: ['./function-definition.component.css']
})
export class FunctionDefinitionComponent {

  constructor(
    private authService:AuthService) {
  }

  @Input()
  canSetRead = true;

  @Input()
  canSetWrite = false;

  @Input()
  canSetControl = true;

  @Input()
  canSetReset = true;

  @Input()
  isReadOnly: boolean;

  @Output()
  readFlagChanged: EventEmitter<boolean> = new EventEmitter();

  @Output()
  writeFlagChanged: EventEmitter<boolean> = new EventEmitter();

  @Output()
  controlFlagChanged: EventEmitter<boolean> = new EventEmitter();

  @Output()
  resetFlagChanged: EventEmitter<boolean> = new EventEmitter();

  _source: IoCategoryItemDirective;
  private _resetFlag: boolean;

  public get resetFlag(): boolean {
    return this._resetFlag;
  }
  @Input()
  public set resetFlag(v: boolean) {
    if (this.canSetReset) {
      this._resetFlag = v;
    }
  }

  private _controlFlag: boolean;
  public get controlFlag(): boolean {
    return this._controlFlag;
  }
  @Input()
  public set controlFlag(v: boolean) {
    if (this.canSetControl) {
      this._controlFlag = v;
    }
  }

  private _readFlag: boolean;
  public get readFlag(): boolean {
    return this._readFlag;
  }
  @Input()
  public set readFlag(v: boolean) {
    if (this.canSetRead) {
      this._readFlag = v;
    }
  }

  private _writeFlag: boolean;
  public get writeFlag(): boolean {
    return this._writeFlag;
  }
  @Input()
  public set writeFlag(v: boolean) {
    if (this.canSetWrite) {
      this._writeFlag = v;
    }
  }

  get source(): IoCategoryItemDirective {
    return this._source;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('source')
  set source(item: IoCategoryItemDirective) {
    this._source = item;
  }

  applyChanges() { }

  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }


}
