import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LegacyFileToolbarService {

  public fullScreenActive: boolean;
  public viewDraftsActive: boolean;
  public mergeMode: boolean;
  public diffMode: boolean;
  public isValidDraft: boolean;
  public draftValidationMessage: string;
  public userHasDraftFile: boolean;
  public draftFilesExists: boolean;
  public isCurrentUserDraftSelected: boolean;

  closeServerFile: Subject<void> = new Subject();
  fullScreen: Subject<void> = new Subject();
  createDraft: Subject<void> = new Subject();
  deleteDraft: Subject<void> = new Subject();
  viewDrafts: Subject<void> = new Subject();
  viewMasterFile: Subject<void> = new Subject();
  saveDraft: Subject<void> = new Subject();
  merge: Subject<void> = new Subject();
  diff: Subject<void> = new Subject();
  executeMerge: Subject<void> = new Subject();
  closeDiffMerge: Subject<void> = new Subject();

  constructor() { }

  get userCanSaveDraft() {
    return this.viewDraftsActive && this.userHasDraftFile && !this.diffMode;
  }

  get userCanDeleteDraft() {
    return !this.mergeMode && !this.diffMode && this.viewDraftsActive && this.userHasDraftFile;
  }

  get userCanCreateDraft() {
    return !this.userHasDraftFile && !this.diffMode;
  }

  get userCanViewDrafts() {
    return !this.viewDraftsActive && !this.mergeMode && !this.diffMode;
  }

  get userCanViewMasterFile() {
    return this.viewDraftsActive && !this.mergeMode && !this.diffMode;
  }

  triggerBackToScommFiles() {
    this.viewDraftsActive = false;
    this.mergeMode = false;
    this.diffMode = false;
    this.closeServerFile.next();
  }

  triggerFullScreen(isEnabled: boolean) {
    this.fullScreenActive = isEnabled;
    this.fullScreen.next();
  }

  triggerDeleteDraft() {
    this.deleteDraft.next();
  }

  triggerCreateDraft() {
    this.createDraft.next();
  }

  triggerViewDrafts() {
    this.resetMergeDiffOptions();
    this.viewDraftsActive = true;
    this.viewDrafts.next();
  }

  triggerViewMasterFiles() {
    this.viewDraftsActive = false;
    this.viewMasterFile.next();
  }

  triggerSaveDraft() {
    this.saveDraft.next();
  }

  triggerStartMerge() {
    this.mergeMode = true;
    this.merge.next();
  }

  triggerShowDiff() {
    this.diffMode = true;
    this.diff.next();
  }

  triggerExecuteMerge() {
    this.mergeMode = false;
    this.executeMerge.next();
  }

  triggerCloseDiffMerge() {
    this.mergeMode = false;
    this.diffMode = false;
    this.closeDiffMerge.next();
  }

  resetMergeDiffOptions() {
    this.mergeMode = false;
    this.diffMode = false;
    // this.viewDraftsActive = false;
  }

  reset() {
    this.fullScreenActive = false;
    this.viewDraftsActive = false;
    this.mergeMode = false;
    this.diffMode = false;
    this.isValidDraft = false;
    this.draftValidationMessage = '';
    this.userHasDraftFile = false;
    this.isCurrentUserDraftSelected = false;
    this.draftFilesExists = false;
  }
}
