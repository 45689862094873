import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogBase } from 'app/dialogs/dialog-base';

import { Encipher } from '../../app-model/key/encipher';
import { Key } from '../../app-model/key/key';
import { Keyset } from '../../app-model/key/keyset';
import { KeysetItem } from '../../app-model/key/keyset-item';
import { EncipherService } from '../../app-services/encipher.service';
import { KeysetService } from '../../app-services/keyset.service';
import { MessageService } from '../../modules/shared/services/message-service.service';

@Component({
  selector: 'app-add-keyset-item',
  templateUrl: './add-keyset-item.component.html',
  styleUrls: ['./add-keyset-item.component.css']
})
export class AddKeysetItemComponent extends DialogBase implements OnInit {

  @Output()
  modalClosing = new EventEmitter<void>();

  @Output()
  addDoneEventEmitter = new EventEmitter<KeysetItem>();

  @Input()
  selectedKeyset: Keyset;

  applying = false;
  selectedEncipher: Encipher;
  key: string;
  name: string;
  enciphers = [];
  loading: boolean;

  constructor(private keysetService: KeysetService, private messageService: MessageService, private encipherService: EncipherService) {
    super();
  }

  ngOnInit() {
    this.encipherService.getEnciphers().subscribe(x => {
      this.enciphers = x;
      this.selectedEncipher = x.length ? x[0] : null;
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  clear() {
    this.selectedEncipher = this.enciphers.length ? this.enciphers[0] : null;
    this.key = null;
    this.name = null;
  }

  add(name: string) {
    const keysetItem = new KeysetItem();
    keysetItem.name = name;

    const key = new Key();
    key.keyValue = this.key;
    key.encipher = this.selectedEncipher;

    keysetItem.key = key;
    this.applying = true;

    this.keysetService.createKeysetItem(this.selectedKeyset.id, keysetItem).subscribe(x => {
      this.applying = false;
      this.addDoneEventEmitter.emit(x);
      this.close();
    }, error => {
      this.applying = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  apply(): void {
  }
  cancel(): void {
  }

  close() {
    this.clear();
    this.modalClosing.emit();
  }
}
