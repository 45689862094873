<!-- Comment -->
<div class="sdds-banner">
  <div class="sdds-banner-body">
    Select specification versions to create a local release for testing. You can base your export on a label. If needed,
    you can exchange specifications with other versions.
  </div>
</div>

<div class="sdds-row my-20">
  <div id="exportPackage" class="sdds-col-max-3 toolbar-button" [ngClass]="{'loading':loadingViews}">
    <h2>Saved Export Packages</h2>
    <app-toolbar [filterEnabled]="true" (filterTextEventEmitter)="viewFilterText = $event"
      [dataTargetEnabledRemove]="true" [addDataTargetEnabled]="true" [createItemId]="'addView'" addDataTarget="#addView"
      [addEventEmitterEnabled]="true" (addEventEmitter)="addViewEventEmitter.emit();" [removeItemId]="'areYouSureModal'"
      [removeDataTargetEnabled]="true" [removeEventEmitter]="true" [isAllowed]="canEditExportPackage()">
    </app-toolbar>


    <app-add-view [initEventEmitter]="addViewEventEmitter" (viewAddedEventEmitter)="viewAdded($event);"></app-add-view>

    <app-confirm-dialogue [message]="doYouWantToDeleteMessage"
      (yesEventEmitter)="removeSelectedView();"></app-confirm-dialogue>

    <div style="margin-top: 5px;"></div>
    <div class="content-list-container">
      <app-common-list [list]="views | nameFilter: viewFilterText" [itemTemplate]="itemTemplate"
        (selectedItemEventEmitter)="selectView($event);" [selectedItem]="selectedView"></app-common-list>
    </div>

    <ng-template #itemTemplate let-item>
      <div class="sdds-row w-full">
        <div
          class="sdds-col-max-7 sdds-col-xxlg-7 sdds-col-xlg-7 sdds-col-lg-7 sdds-col-md-7 sdds-col-sm-7 sdds-col-xs-7">
          <div class="sdds-headline-07">{{item.name}}</div>
        </div>
        <div
          class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-5 sdds-col-sm-5 sdds-col-xs-5">
          <span class="sdds-detail-03">{{item.createdBy}}</span>
        </div>
      </div>
    </ng-template>
  </div>

  <div *ngIf="selectedView"
    class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-9 sdds-col-md-9 sdds-col-sm-9 sdds-col-xs-9"
    [ngClass]="{'loading':loading}">
    <div class="float-right">
      <button class="sdds-btn sdds-btn-primary sdds-btn-md ml-10" type="button" (click)="performExport();"
        [disabled]="!canEditExportPackage()">EXPORT
        PACKAGE</button>
    </div>
    <app-editable-textbox [overrideFont]="true" enclosingElement="h1" [text]="selectedView.name"
      [isAllowed]="canEditExportPackage()" (textEditedEventEmitter)="viewNameChanged($event)">
    </app-editable-textbox>

    <div>
      <p class="sdds-headline-06">Package created by {{selectedView.createdBy ? selectedView.createdBy : 'N/A'}}</p>
    </div>

    <div class="sdds-row my-20">
      <div
        class="sdds-col-max-2 sdds-col-xxlg-2 sdds-col-xlg-2 sdds-col-lg-2 sdds-col-md-2 sdds-col-sm-2 sdds-col-xs-2">
        <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg">
          <span class="sdds-dropdown-label-outside">Based on label</span>
          <select id="views" [(ngModel)]="selectedLabel" (change)="updateLabel(selectedLabel)">
            <option [ngValue]="null">No label</option>
            <option *ngFor="let label of labels" [ngValue]="label">
              {{label.name}}
            </option>
          </select>
        </div>
      </div>
      <div
        class="sdds-col-max-2 sdds-col-xxlg-2 sdds-col-xlg-2 sdds-col-lg-2 sdds-col-md-2 sdds-col-sm-2 sdds-col-xs-2">
        <div class="mt-20">
          <button id="inspectLabelModal" class="sdds-btn sdds-btn-secondary sdds-btn-sm"
            [ngClass]="{'hidden': selectedLabel === null}" type="button" (click)="inspectPackage();"
            [disabled]="!canEditExportPackage()">Inspect
            Label</button>
        </div>
      </div>
    </div>

    <div>
      <p class="sdds-headline-06">Specification versions to include in the package:</p>
      <br />
      <div>
        <div class="column" *ngFor="let spv of specificSpecificationVersions">
          <app-specification-version-preview [specificSpecificationVersion]="spv"
            (removeSpecification)="removeSelectedSpecificSpecificationVersion($event)"
            [isAllowed]="canEditExportPackage()">
          </app-specification-version-preview>
        </div>
      </div>
    </div>
    <div class="clear-left"></div>

    <div>
      <app-embedded-add-specific-version (specificationVersionsAdded)="specificationVersionsAdded($event)"
        [isAllowed]="canEditExportPackage()">
      </app-embedded-add-specific-version>
    </div>
    <div class="clear-left"></div>

    <sdds-modal size="lg" selector="#inspectLabelModal">
      <h5 slot="sdds-modal-headline">Specification versions with label {{selectedLabel ? selectedLabel.name : ''}}</h5>
      <div slot="sdds-modal-body">
        <app-global-export-preview [item]="exportCriteriaItem"></app-global-export-preview>
      </div>
      <button slot="sdds-modal-actions" data-dismiss-modal
        class="sdds-btn sdds-btn-secondary sdds-btn-md">Close</button>
    </sdds-modal>
  </div>
</div>