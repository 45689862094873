import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SpecificationService } from 'app/app-services/specification-service';

import { Specification } from '../../app-model/specification';
import { SpecificationVersion } from '../../app-model/specification-version';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-create-new-version',
  templateUrl: './create-new-version.component.html',
  styleUrls: ['./create-new-version.component.css']
})
export class CreateNewVersionComponent extends DialogBase {
  @Output()
  versionCreatedEventEmitter: EventEmitter<SpecificationVersion> = new EventEmitter<SpecificationVersion>();

  @Input()
  anyInWorkVersions: boolean;

  @Input()
  specification: Specification;

  @Input()
  public set versions(v: SpecificationVersion[]) {
    this._versions = v;
  }
  public get versions(): SpecificationVersion[] {
    return this._versions;
  }

  loading: boolean;
  modalClosing: EventEmitter<void> = new EventEmitter<void>();

  private _versions: SpecificationVersion[];

  constructor(private specificationService: SpecificationService, private messageService: MessageService) {
    super();
  }

  get basedOnVersion(): SpecificationVersion {
    if (this.releasedVersions.length === 0) {
      return null;
    }

    return this.releasedVersions[this.releasedVersions.length - 1];
  }

  get releasedVersions() {
    return this.versions ? this.versions.filter(v => v.releaseStatus === 1) : [];
  }

  apply() {
    if (!this.specification) {
      return;
    }

    this.loading = true;
    const version = new SpecificationVersion();
    version.specification = new Specification();

    version.specification.id = this.specification.id;

    if (this.basedOnVersion) {
      version.parent = new SpecificationVersion();
      version.parent.id = this.basedOnVersion.id;
      version.specificationType = this.basedOnVersion.specificationType;
      version.ivdOverrideEnabled = this.basedOnVersion.ivdOverrideEnabled;
    }

    this.specificationService.createSpecificationVersion(version).subscribe(data => {
      this.loading = false;
      this.versionCreatedEventEmitter.emit(data);
      this.close();
    },
      err => {
        this.loading = false;
      });
  }

  cancel() {
    this.close();
  }

  close() {
    this.modalClosing.emit();
  }
}