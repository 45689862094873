import { Component } from '@angular/core';

@Component({
  selector: 'app-release-browser-navbar',
  templateUrl: './release-browser-navbar.component.html',
  styleUrls: ['./release-browser-navbar.component.css']
})
export class ReleaseBrowserNavbarComponent {

}
