import {
    ComputationalMethodModel,
    ComputationalMethodType,
} from 'app/app-model/diagnostic-service/computational.method.model';
import { ScalingModel, ScaniaEncodingType } from 'app/app-model/diagnostic-service/parameter.scaling.model';
import { ComputationalMethodIntervalModel } from 'app/app-model/diagnostic-service/text.table.interval.data.model';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { ValueConstraintData } from 'app/modules/shared/model/service/parameters/value-constraint-data';

export class ScalingData {
    private _scalingModel: ScalingModel;
    private _physicalConstraintData: ValueConstraintData;
    private _internalConstraintData: ValueConstraintData;

    constructor(scalingModel: ScalingModel) {
        this.model = scalingModel;
        this.updateConstraints();
    }

    public get model(): ScalingModel {
        return this._scalingModel;
    }
    public set model(v: ScalingModel) {
        this._scalingModel = v;
    }

    get unit(): string {
        return this.model.unit ? this.model.unit : '-';
    }

    set unit(val: string) {
        this.model.unit = val;
    }

    get internalDataType(): string {
        return OdxDataType[this.model.internalDataType];
    }

    get physicalDataType(): string {
        return OdxDataType[this.model.physicalDataType];
    }

    get encoding(): string {
        return ScaniaEncodingType[this.model.encoding];
    }

    public get physicalConstraintData(): ValueConstraintData {
        return this._physicalConstraintData;
    }
    public set physicalConstraintData(v: ValueConstraintData) {
        this._physicalConstraintData = v;
        this.model.physicalConstraint = v ? v.model : undefined;
    }

    public get internalConstraintData(): ValueConstraintData {
        return this._internalConstraintData;
    }
    public set internalConstraintData(v: ValueConstraintData) {
        this._internalConstraintData = v;
        this.model.internalConstraint = v ? v.model : undefined;
    }

    disablePhysicalToInternalComputationalMethod() {
        this.model.physicalToInternalComputationalMethod = undefined;
    }

    enablePhysicalToInternalComputationalMethod(dataType: OdxDataType) {
        this.model.physicalToInternalComputationalMethod = this.createDefaultComputationalMethod(dataType);
    }

    disableInternalToPhysicalComputationalMethod() {
        this.model.internalToPhysicalComputationalMethod = undefined;
    }

    enableInternalToPhysicalComputationalMethod(dataType: OdxDataType) {
        this.model.internalToPhysicalComputationalMethod = this.createDefaultComputationalMethod(dataType);
    }

    updateConstraints(): any {
        if (this.model.physicalConstraint) {
            this.physicalConstraintData = new ValueConstraintData(this.model.physicalConstraint);
        } else {
            this.physicalConstraintData = undefined;
        }
        if (this.model.internalConstraint) {
            this.internalConstraintData = new ValueConstraintData(this.model.internalConstraint);
        } else {
            this.internalConstraintData = undefined;
        }
    }

    reset() {
        this.model.physicalConstraint = undefined;
        this.model.internalConstraint = undefined;
        this.model.internalToPhysicalComputationalMethod = undefined;
        this.model.physicalToInternalComputationalMethod = undefined;
        this.model.unit = '-';

        this.updateConstraints();
    }

    private createDefaultComputationalMethod(dataType: OdxDataType) {
        const compMethod = new ComputationalMethodModel();
        compMethod.methodType = ComputationalMethodType.LinearFormulaData;

        const defaultInterval = new ComputationalMethodIntervalModel();
        defaultInterval.numeratorConstant = 0;
        defaultInterval.numeratorCoefficient = 1;
        defaultInterval.denominatorConstant = 1;

        compMethod.intervals = [defaultInterval];

        return compMethod;
    }
}
