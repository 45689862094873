import { Component, EventEmitter, Output } from '@angular/core';

import { Keyset } from '../../app-model/key/keyset';
import { KeysetService } from '../../app-services/keyset.service';
import { MessageService } from '../../modules/shared/services/message-service.service';

@Component({
  selector: 'app-add-keyset',
  templateUrl: './add-keyset.component.html',
  styleUrls: ['./add-keyset.component.css']
})
export class AddKeysetComponent {

  @Output()
  modalClosing = new EventEmitter<void>();

  @Output()
  addDoneEventEmitter = new EventEmitter<Keyset>();

  loading: boolean;
  name: string;
  applying = false;

  constructor(private keysetService: KeysetService, private messageService: MessageService) { }

  clear() {
    this.name = null;
  }

  close() {
    this.clear();
    this.modalClosing.emit();
  }

  add(name: string) {
    const keyset = new Keyset();
    keyset.name = name;
    this.applying = true;

    this.keysetService.createKeyset(keyset).subscribe(x => {
      this.applying = false;
      this.addDoneEventEmitter.emit(x);
      this.close();
    }, error => {
      this.applying = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

}
