import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { iif, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';

import { Keyset } from '../app-model/key/keyset';
import { KeysetItem } from '../app-model/key/keyset-item';
import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { SecurityAccessService } from './security-access.service';

@Injectable({
  providedIn: 'root'
})
export class KeysetService {

  constructor(private apiProxy: ApiProxyService,
    private securityAccessService: SecurityAccessService) { }

  getKeysets(): Observable<Keyset[]> {
    return this.apiProxy.get(environment.apiUrl + 'keyset');
  }

  getKeysetsForSecurityAccess(specificationVersionId: number): Observable<Keyset[]> {
    const masterKeysetObsv = this.getMasterKeyset();
    const getKetsetFromApiObsv = this.apiProxy.get<Keyset[]>(environment.apiUrl + 'keyset');

    return this.securityAccessService.getItemsPreview(specificationVersionId)
      .pipe(mergeMap(securityAccessItems =>
        iif(() => securityAccessItems.length > 0 && this.hasAnySecurityAccessItemsWithKeySets(),
          masterKeysetObsv,
          getKetsetFromApiObsv)));
  }

  getKeysetsForGenerations(specificationVersionId: number, generations: string[]): Observable<Keyset[]> {
    return new Observable((observer: Observer<Keyset[]>) => {
      this.securityAccessService.getItemsPreview(specificationVersionId).subscribe(securityAccessItems => {
        if (securityAccessItems.length > 0 && this.hasAnySecurityAccessItemsWithKeySets()) {
          this.getMasterKeyset().subscribe(items => {
            observer.next(items);
            observer.complete();
          });
        } else {
          this.getKeysetsForSecurityAccess(specificationVersionId).subscribe(keySets => {
            let availableSecurityAccesModes = new Array<Keyset>();

            generations.forEach(generation => {
              availableSecurityAccesModes = availableSecurityAccesModes
                .concat(keySets
                  .filter(keyset => keyset.familyGenerationPairs
                    .findIndex(elem => elem.generation.name === generation) !== -1));
            });

            observer.next(availableSecurityAccesModes);
            observer.complete();
          });
        }
      });
    });
  }

  getKeyset(id: number): Observable<Keyset> {
    return this.apiProxy.get(environment.apiUrl + 'keyset/' + id);
  }

  createKeyset(keyset: Keyset): Observable<Keyset> {
    return this.apiProxy.post(environment.apiUrl + 'keyset', keyset);
  }

  updateKeyset(keyset: Keyset): Observable<Keyset> {
    return this.apiProxy.put(environment.apiUrl + 'keyset/' + keyset.id, keyset);
  }

  deleteKeyset(id: number): Observable<Keyset> {
    return this.apiProxy.delete(environment.apiUrl + 'keyset/' + id);
  }

  createKeysetItem(id: number, keysetItem: KeysetItem): Observable<KeysetItem> {
    return this.apiProxy.post(environment.apiUrl + 'keyset/' + id + '/keysetitem', keysetItem);
  }

  updateKeysetItem(id: number, keysetItem: KeysetItem): Observable<KeysetItem> {
    return this.apiProxy.put(environment.apiUrl + 'keyset/' + id + '/keysetitem/' + keysetItem.id, keysetItem);
  }

  deleteKeysetItem(id: number, keysetItemId: number): Observable<KeysetItem> {
    return this.apiProxy.delete(environment.apiUrl + 'keyset/' + id + '/keysetitem/' + keysetItemId);
  }

  /**
   * If there is already a security access item in the specification, then all the other security access items must be created from the
   * same security access keyset.
   */
  private getMasterKeyset(): Observable<Keyset[]> {
    return this.getKeyset(this.securityAccessService.items[0].model.keysetId).pipe(map(keyset => [keyset]));
  }

  private hasAnySecurityAccessItemsWithKeySets(): boolean {
    return this.securityAccessService.items.find(item => item.model.keysetId !== undefined) !== undefined;
  }
}
