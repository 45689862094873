import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedFile } from 'app/app-model/legacy-file';
import { ScommSharedFileVersion } from 'app/app-model/scomm-shared-file-version';
import { SharedFilesEventsService } from 'app/app-services/shared-files-events.service';
import { SharedFilesService } from 'app/app-services/shared-files.service';
import { SharedFileVersionTreeNode } from 'app/modules/shared/components/tree/model/shared-file-version-tree-node';
import { Tree } from 'app/modules/shared/components/tree/model/tree';
import { TreeService } from 'app/modules/shared/components/tree/service/tree.service';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { Observable, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Component({
  selector: 'app-file-version-browser',
  templateUrl: './file-version-browser.component.html',
  styleUrls: ['./file-version-browser.component.css']
})
export class FileVersionBrowserComponent implements OnInit, OnDestroy {

  @Input()
  isAllowed:boolean;

  versionTree: Tree;
  selectedVersion: ScommSharedFileVersion;
  sharedFilesEventsServiceSubscription: Subscription;
  fileSpecificationCreatedSubscription: Subscription;

  private _sharedFileSpecification: SharedFile;
  private readonly deleteAlwaysIncludeFileMessage = `You are about to delete an "Always Include" file.
  Deleting "Always Include" files could have side effects if they are expected to be in the AppDatabase. Do you want to proceeed?`;

  public get sharedFileSpecification(): SharedFile {
    return this._sharedFileSpecification;
  }

  public set sharedFileSpecification(v: SharedFile) {
    this._sharedFileSpecification = v;
    this.updateAvailableVersionsForFile();
  }

  constructor(private sharedFilesService: SharedFilesService,
    private sharedFilesEventsService: SharedFilesEventsService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.sharedFilesEventsServiceSubscription = this.sharedFilesEventsService.sharedFileSpecificationSelected.subscribe({
      next: (selectedSpecification) => {
        this.sharedFileSpecification = selectedSpecification;
        this.selectedVersion = undefined;
        this.sharedFilesEventsService.sharedFileSpecificationVersionSelected.next(this.selectedVersion);
      }
    });

    this.fileSpecificationCreatedSubscription = this.sharedFilesEventsService.versionCreated.subscribe({
      next: (createdVersion) => {
        this.selectedVersion = createdVersion;
        if (this.selectedVersion) {
          this.updateAvailableVersionsForFile();
        } else {
          this.sharedFilesEventsService.sharedFileSpecificationVersionSelected.next(this.selectedVersion);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.sharedFilesEventsServiceSubscription.unsubscribe();
    this.fileSpecificationCreatedSubscription.unsubscribe();
  }

  updateAvailableVersionsForFile() {
    if (this.sharedFileSpecification) {
      this.sharedFilesService.getSharedFileSpecificationVersions(this.sharedFileSpecification.id).pipe(tap(versions => {
        versions = versions.sort((first, second) => {
          if (first.revision < second.revision) {
            return 1;
          }
          if (first.revision > second.revision) {
            return -1;
          }
          return 0;
        });
        this.buildVersionsTree(versions);
      })).subscribe();
    }
  }

  buildVersionsTree(versions: ScommSharedFileVersion[]) {
    const treeService = new TreeService();
    this.versionTree = new Tree(treeService);

    versions.forEach(version => {
      const versionNode = new SharedFileVersionTreeNode(version, treeService);
      this.versionTree.addRootNode(versionNode);
    });

    this.versionTree.selectedTreeNodeEventEmitter.subscribe((t: any) => {
      console.log('version node selected');
      this.selectedVersion = t.fileVersion;
      this.sharedFilesEventsService.sharedFileSpecificationVersionSelected.next(this.selectedVersion);
    }, e => {
    });
  }

  removeSelectedView() {
    this.hasReferencesToSpecificationVersions().subscribe(hasReferences => {
      if (hasReferences) {
        this.messageService.dispatchErrorMessage('Shared files with references to specification versions cannot be removed.');
      } else {
        this.isAlwaysIncludeFileRevision().subscribe(isAlwaysInclude => {
          if (isAlwaysInclude && this.showConfirmDeleteAlwaysIncludeFileDialog()) {
            this.deleteSelectedVersion();
          } else if (!isAlwaysInclude) {
            this.deleteSelectedVersion();
          }
        });
      }
    });
  }

  private onVersionCreated(version: ScommSharedFileVersion) {
    this.selectedVersion = version;
    this.updateAvailableVersionsForFile();
  }

  private deleteSelectedVersion() {
    this.sharedFilesService.deleteSharedFileVersion(this.selectedVersion).subscribe(() => {
      this.updateAvailableVersionsForFile();
      this.selectedVersion = null;
      this.sharedFilesEventsService.sharedFileSpecificationVersionSelected.next(this.selectedVersion);
    });
  }

  private showConfirmDeleteAlwaysIncludeFileDialog(): boolean {
    return window.confirm(this.deleteAlwaysIncludeFileMessage);
  }

  private hasReferencesToSpecificationVersions(): Observable<boolean> {
    return of(false);
  }

  private isAlwaysIncludeFileRevision(): Observable<boolean> {
    return this.sharedFilesService.getSharedFileSpecification(this.selectedVersion.sharedFileId)
      .pipe(map(sharedFile => sharedFile.alwaysInclude));
  }
}
