import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { TreeService } from '../service/tree.service';
import { SharedFileTreeNode } from './shared-file-tree.node';
import { TreeNode } from './tree-node';

export class Tree {
  rootNodes: TreeNode[];
  selectedTreeNode: TreeNode;
  selectedTreeNodeEventEmitter: Observable<TreeNode>;
  private selectedTreeNodeSubject: Subject<TreeNode> = new Subject<TreeNode>();

  constructor(private _treeService: TreeService) {
    this.rootNodes = [];
    this.selectedTreeNodeEventEmitter = this.selectedTreeNodeSubject.pipe(debounceTime(50));
    this._treeService.treeNodeSelectedEventEmitter.subscribe(t => {
      this.treeNodeSelected(t);
    });

    this._treeService.treeNodeNavigateUpEventEmitter.subscribe(t => {
      this.treeNodeNavigateUp(t);
    });

    this._treeService.treeNodeNavigateDownEventEmitter.subscribe(t => {
      this.treeNodeNavigateDown(t);
    });
  }

  get isEmpty(): boolean {
    return this.rootNodes.length === 0;
  }

  addRootNode(treeNode: TreeNode) {
    this.rootNodes.push(treeNode);
  }

  showTreeNodesThatContainsText(text: string, append = false) {
    const filterText = text.toLowerCase().trim();
    this._treeService.clearSelection();

    if (!append) {
      this.getAllTreeNodes().forEach(r => {
        r.isVisible = true;
        r.filterMatch = false;
        if (r.isSelected) {
          r.isExpanded = true;
        } else {
          r.isExpanded = false;
        }
      });
    }

    if (filterText === '') {
      return;
    } else {
      this.rootNodes.forEach(r => this.filterNodes(filterText, r, !append));
    }
  }
  showSharedFileTreeNodesWithAlwaysInclude() {
    this._treeService.clearSelection();

    this.getAllTreeNodes().forEach(r => {
      r.isVisible = true;
      r.filterMatch = false;
      if (r.isSelected) {
        r.isExpanded = true;
      } else {
        r.isExpanded = false;
      }
    });

    this.rootNodes.forEach(node => {
      if (node instanceof SharedFileTreeNode) {
        const sharedFileNode = node as SharedFileTreeNode;
        if (!sharedFileNode.specificationFile.alwaysInclude) {
          node.isVisible = false;
          node.isExpanded = false;
          node.filterMatch = false;
        }
      }
    });
  }

  clearSelection() {
    this._treeService.clearSelection();
  }

  selectFirstTreeNodeThatMatchesPredicate(predicate: ((arg0: TreeNode) => boolean), notifySelection = true) {
    const foundTreeNode = this.getAllTreeNodes().find(t => predicate(t));

    if (foundTreeNode) {
      foundTreeNode.selectTreeNode(notifySelection);
      foundTreeNode.isExpanded = true;
    } else {
      this.clearSelection();
    }
  }

  expandEntireTree() {
    this.getAllTreeNodes().forEach(t => t.isExpanded = true);
  }

  collapseEntireTree() {
    this.getAllTreeNodes().forEach(t => t.isExpanded = false);
  }

  selectFirstNode() {
    if (this.rootNodes.length > 0) {
      this.rootNodes[0].selectTreeNode();
    }
  }

  treeNodeNavigateUp(treeNode: TreeNode) {
    if (!treeNode) {
      return;
    }

    const parentTreeNode = this.getAllTreeNodes().find(t => t.children.some(c => c === treeNode));

    if (!parentTreeNode) {
      const visibleRootNodes = this.rootNodes.filter(x => x.isVisible);
      const rootNodeIndex = visibleRootNodes.indexOf(treeNode);

      if (rootNodeIndex === 0) {
        treeNode.selectTreeNode();
      } else {
        const lastNode = this.findLastNoneExpandedNode(visibleRootNodes[rootNodeIndex - 1]);

        if (lastNode) {
          lastNode.selectTreeNode();
        }
      }
    } else {
      const visibleChildren = parentTreeNode.children.filter(x => x.isVisible);
      const treeNodeIndex = visibleChildren.indexOf(treeNode);

      if (treeNodeIndex !== 0) {

        const lastNode = this.findLastNoneExpandedNode(visibleChildren[treeNodeIndex - 1]);

        if (lastNode) {
          lastNode.selectTreeNode();
        }
      } else {
        parentTreeNode.selectTreeNode();
      }
    }
  }

  findLastNoneExpandedNode(treeNode: TreeNode) {
    if (!treeNode) {
      return;
    }

    if (treeNode.isExpanded === false) {
      return treeNode;
    }

    const visibleChildren = treeNode.children.filter(x => x.isVisible);

    if (!visibleChildren || visibleChildren.length === 0) {
      return treeNode;
    }

    return this.findLastNoneExpandedNode(visibleChildren[visibleChildren.length - 1]);
  }

  treeNodeNavigateDown(treeNode: TreeNode) {
    if (!treeNode) {
      return;
    }

    const visibleChildren = treeNode.children.filter(x => x.isVisible);

    if (treeNode.isExpanded && visibleChildren && visibleChildren.length > 0) {
      visibleChildren[0].selectTreeNode();
    } else {
      const nextSibling = this.findNextSibling(treeNode);

      if (nextSibling) {
        nextSibling.selectTreeNode();
      }
    }
  }

  findNextSibling(treeNode: TreeNode) {
    const allTreeNodes = this.getAllTreeNodes();

    const parentTreeNode = allTreeNodes.find(t => t.children.some(c => c === treeNode));

    if (!parentTreeNode) {
      const visibleRootNodes = this.rootNodes.filter(x => x.isVisible);
      const rootNodeIndex = visibleRootNodes.indexOf(treeNode);

      if (rootNodeIndex < visibleRootNodes.length - 1) {
        return visibleRootNodes[rootNodeIndex + 1];
      }
    } else {
      const visibleChildren = parentTreeNode.children.filter(x => x.isVisible);
      const indexInParentNode = visibleChildren.indexOf(treeNode);

      if (indexInParentNode < visibleChildren.length - 1) {
        return visibleChildren[indexInParentNode + 1];
      } else {
        return this.findNextSibling(parentTreeNode);
      }
    }

    return null;
  }

  updateTreeNodes(updateAction: ((arg0: TreeNode) => void)) {
    this.getAllTreeNodes().forEach(t => {
      updateAction(t);
    });
  }

  reset(): void {
    this.getAllTreeNodes().forEach(node => {
      node.isVisible = true;
      node.filterMatch = false;
      if (node.isSelected) {
        node.isExpanded = true;
      } else {
        node.isExpanded = false;
      }
    });
  }

  private treeNodeSelected(treeNode: TreeNode) {
    this.selectedTreeNode = treeNode;
    this.selectedTreeNodeSubject.next(treeNode);
  }

  private getAllTreeNodes(): TreeNode[] {
    let treeNodes: TreeNode[] = [];

    this.rootNodes.forEach(rn => {
      treeNodes.push(rn);
      treeNodes = treeNodes.concat(this.allTreeNodesInternal(rn));
    });

    return treeNodes;
  }

  private allTreeNodesInternal(treeNode: TreeNode): TreeNode[] {
    let treeNodes: TreeNode[] = [];

    treeNode.children.forEach(c => {
      treeNodes.push(c);
      treeNodes = treeNodes.concat(this.allTreeNodesInternal(c));
    });

    return treeNodes;
  }

  private filterNodes(filterText: string, treeNode: TreeNode, hideNodes: boolean = true) {

    if (treeNode.containsText(filterText)) {
      treeNode.isVisible = true;
      treeNode.isExpanded = true;
      treeNode.filterMatch = true;

      treeNode.children.forEach(c => {
        this.filterNodes(filterText, c, false);
      });

      return;
    } else if (hideNodes) {
      treeNode.isVisible = false;
      treeNode.isExpanded = false;
    }

    treeNode.children.forEach(c => {
      this.filterNodes(filterText, c, hideNodes);
    });
  }
}
