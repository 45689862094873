import { Component, Input } from '@angular/core';
import {
  ServerIdentificationCompareOperatorType,
  ServerIdentificationExpressionNodeType,
} from 'app/app-model/server-identification/server-identification.enums';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-expression-node-renderer',
  templateUrl: './expression-node-renderer.component.html',
  styleUrls: ['./expression-node-renderer.component.css']
})
export class ExpressionNodeRendererComponent  {
  @Input()
  copyEnabled = true;

  @Input()
  reorderEnabled = true;

  @Input()
  legacyModeEnabled: boolean;

  @Input()
  isReadOnly: boolean;

  @Input()
  isAllowed: boolean;

  constructor(private authService: AuthService){}

  public get expressions(): Array<ServeridentificationExpressionModel> {
    return this._expressions;
  }
  @Input()
  public set expressions(v: Array<ServeridentificationExpressionModel>) {
    this._expressions = v;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ServerIdentificationExpressionNodeType = ServerIdentificationExpressionNodeType;

  private _expressions: Array<ServeridentificationExpressionModel>;

  hasCompareOperatorOfIntervalType(expression: ServeridentificationExpressionModel): boolean {
    return expression.compareOperator === ServerIdentificationCompareOperatorType.LessThan ||
      expression.compareOperator === ServerIdentificationCompareOperatorType.GreaterThan ||
      expression.compareOperator === ServerIdentificationCompareOperatorType.LessThanOrEqualTo ||
      expression.compareOperator === ServerIdentificationCompareOperatorType.GreaterThanOrEqualTo;
  }

  hasCompareValueNode(expression: ServeridentificationExpressionModel): boolean {
    return expression.nodeType === ServerIdentificationExpressionNodeType.EcuIdentifierCompareOperand ||
      expression.nodeType === ServerIdentificationExpressionNodeType.LegacyEcuIdentifierCompareOperand;
  }
  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
