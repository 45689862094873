/* eslint-disable @typescript-eslint/naming-convention */
export class PathStep {
  sequenceId: number;
  executionIndex: number;
  index: number;
  stepType:  StepType;
  step: string;
}

// eslint-disable-next-line no-shadow
export enum StepType {
  StepByName = 0,
  StepByIndex  = 1
}
