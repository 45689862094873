import { Component, Input } from '@angular/core';
import { DataLimitType, RangeModel } from 'app/app-model/diagnostic-service/range.model';

@Component({
  selector: 'app-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.css']
})
export class RangeComponent {
  @Input()
  range: RangeModel;

  get rangePresentation(): string {
    let presentation = '';

    if (this.range.lowerType === DataLimitType.Open) {
      presentation += ']';
    } else if (this.range.lowerType === DataLimitType.Closed) {
      presentation += '[';
    } else if (this.range.lowerType === DataLimitType.Infinite) {
      presentation += '[-∞';
    }

    if (this.range.lowerType !== DataLimitType.Infinite && this.range.lowerValue) {
      presentation += this.range.lowerValue.data.toUpperCase();
    }

    presentation += ',';

    if (this.range.upperType !== DataLimitType.Infinite && this.range.upperValue) {
      presentation += this.range.upperValue.data.toUpperCase();
    }

    if (this.range.lowerType === DataLimitType.Open) {
      presentation += '[';
    } else if (this.range.lowerType === DataLimitType.Closed) {
      presentation += ']';
    } else if (this.range.lowerType === DataLimitType.Infinite) {
      presentation += '∞]';
    }

    return presentation;
  }
}
