import { Injectable } from '@angular/core';
import {
  ScommFile,
  ScommFileDraft,
  ScommFileDraftResult,
  ScommFileType,
  SharedFile,
  SharedFileType,
} from 'app/app-model/legacy-file';
import { FilePreviewComponent } from 'app/modules/shared/components/file-preview/file-preview.component';
import { User } from 'app/modules/shared/model/user';
import { concat, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApplicationService } from './application.service';
import { LegacyFileToolbarService } from './legacy-file-toolbar-service.service';
import { SharedFilesEventsService } from './shared-files-events.service';
import { SpecificationLegacyFiles } from './specification-legacy-files.service';
import { SpecificationService } from './specification-service';
import { UserService } from './user.service';


@Injectable({ providedIn: 'root' })
export class LegacyFileModificationService {

  fileDrafts: ScommFileDraftResult[] = [];
  currentUser: User;
  availableFiles: ScommFile[] = [];

  selectedDraftFile: ScommFileDraftResult;
  legacyServerFileSubscription: Subscription;
  toolbarEventSubscriptions: Subscription[] = [];

  currentSingleFileContent: string;
  currentLeftFileContent: string;
  currentRightFileContent: string;

  specificationVersionId: number;
  hasLoadedFiles: boolean;

  currentFileNameUpdater: (name: string) => void;
  openModeUpdater: (enabled: boolean) => void;
  fileEditorAccessor: () => FilePreviewComponent;
  onMergeCompleteToolbarAccessor: () => void;
  _userHasDraftFile: boolean;

  constructor(private applicationService: ApplicationService,
    private specificationLegacyFiles: SpecificationLegacyFiles,
    private specificationService: SpecificationService,
    private sharedFilesEventService: SharedFilesEventsService,
    private userService: UserService,
    private legacyFileToolbarService: LegacyFileToolbarService) {
  }

  public get getKeyFile(): SharedFile {
    const keyFiles = this.filterAndMapAvailableFiles(ScommFileType.KeyFile);
    if (keyFiles.length > 0) {
      return keyFiles[0];
    }

    return undefined;
  }

  public get getOtherFiles(): SharedFile[] {
    return this.filterAndMapAvailableFiles(ScommFileType.Other);
  }

  public get currentLegacyServerFile(): ScommFile {
    if (this.availableFiles && this.availableFiles.length > 0) {
      return this.availableFiles
        .filter(file => file.fileType === ScommFileType.LegacyServerFile)[0];
    }

    return undefined;
  }

  set userHasDraftFile(v: boolean) {
    this._userHasDraftFile = v;
  }
  get userHasDraftFile() {
    return this._userHasDraftFile;
  }

  get draftFilesExists() {
    return this.fileDrafts && this.fileDrafts.length > 0;
  }

  get isCurrentUserDraftSelected() {
    return this.selectedDraftFile
      && this.selectedDraftFile.draft.user.userName === this.currentUser.userName
      && !this.legacyFileToolbarService.diffMode;
  }

  get selectedDraftFileUser() {
    if (this.selectedDraftFile) {
      return this.selectedDraftFile.draft.user.firstName + ' ' + this.selectedDraftFile.draft.user.lastName;
    }

    return '';
  }

  get showEditingDraftFileTextEnabled() {
    return this.draftFilesExists && !this.legacyFileToolbarService.mergeMode && this.legacyFileToolbarService.viewDraftsActive;
  }

  filterAndMapAvailableFiles(fileType: ScommFileType): SharedFile[] {
    if (this.availableFiles && this.availableFiles.length > 0) {
      return this.availableFiles
        .filter(file => file.fileType === fileType)
        .map(keyFile => {
          const sharedFile = new SharedFile();
          sharedFile.fileType = keyFile.fileType;
          sharedFile.id = keyFile.sharedFileId;
          sharedFile.name = keyFile.name;
          return sharedFile;
        });
    }

    return [];
  }

  updateAvailableScommFiles(versionId: number) {
    this.specificationVersionId = versionId;
    // this.hasLoadedFiles = false;
    this.specificationLegacyFiles.getSpecificationScommFiles(versionId).subscribe({
      next: (files) => {
        this.availableFiles = files;
        this.hasLoadedFiles = true;
      }
    });
  }

  subscribeToToolbarEvents() {
    this.toolbarEventSubscriptions.forEach(subscrition => subscrition.unsubscribe());
    this.toolbarEventSubscriptions = [];

    this.toolbarEventSubscriptions.push(
      this.legacyFileToolbarService.closeServerFile.subscribe({
        next: (arg) => {
          this.whenCloseServerFile();
        }
      }));
    this.toolbarEventSubscriptions.push(
      this.legacyFileToolbarService.fullScreen.subscribe({
        next: (arg) => {
          this.whenToggleFullscreen();
        }
      })
    );
    this.toolbarEventSubscriptions.push(
      this.legacyFileToolbarService.createDraft.subscribe({
        next: (arg) => {
          this.whenCreateFileDraft();
        }
      })
    );
    this.toolbarEventSubscriptions.push(
      this.legacyFileToolbarService.deleteDraft.subscribe({
        next: (arg) => {
          this.whenDeleteFileDraft();
        }
      })
    );
    this.toolbarEventSubscriptions.push(
      this.legacyFileToolbarService.viewDrafts.subscribe({
        next: (arg) => {
          this.whenViewDrafts();
        }
      })
    );
    this.toolbarEventSubscriptions.push(
      this.legacyFileToolbarService.viewMasterFile.subscribe({
        next: (arg) => {
          this.viewMasterFile();
        }
      }));
    this.toolbarEventSubscriptions.push(
      this.legacyFileToolbarService.saveDraft.subscribe({
        next: (arg) => {
          this.whenSaveFileDraft();
        }
      }));
    this.toolbarEventSubscriptions.push(
      this.legacyFileToolbarService.merge.subscribe({
        next: (arg) => {
          this.whenMergeServerFile();
        }
      }));
    this.toolbarEventSubscriptions.push(
      this.legacyFileToolbarService.executeMerge.subscribe({
        next: (arg) => {
          this.whenExecuteMerge();
        }
      }));
    this.toolbarEventSubscriptions.push(
      this.legacyFileToolbarService.closeDiffMerge.subscribe({
        next: (arg) => {
          this.whenCloseDiffMerge();
        }
      }));
  }

  whenUnlinkSharedFile(params: { scommSharedFileSpecification: SharedFile; sharedFileType: SharedFileType }) {
    const matchingScommFile = this.availableFiles.find(file => file.sharedFileId === params.scommSharedFileSpecification.id);

    if (matchingScommFile) {
      this.specificationService.deleteSpecificationVersionSharedFile(this.specificationService.currentSpecificationVersion, matchingScommFile.id)
        .subscribe({
          next: (args) => {
            this.availableFiles.splice(this.availableFiles.indexOf(matchingScommFile), 1);
            this.sharedFilesEventService.referencedSharedFilesRemoved.next();
          }
        });
    }
  }

  whenLegacyFileUploaded(file: ScommFile) {
    this.updateAvailableScommFiles(this.specificationVersionId);
  }

  whenLegacyFileRemoved() {
    this.updateAvailableScommFiles(this.specificationVersionId);
  }

  whenCloseServerFile() {
    this.openModeUpdater(false);
    this.fileDrafts = [];
  }

  whenToggleFullscreen() {
    if (this.legacyFileToolbarService.fullScreenActive) {
      this.applicationService.requestFullScreen.next();
    } else {
      this.applicationService.requestNormalWindowMode.next();
    }
  }

  whenViewDrafts() {
    this.loadAndSelectDraftFile().subscribe({
      next: () => {
        this.legacyFileToolbarService.viewDraftsActive = true;
      }
    });
  }

  loadAndSelectDraftFile(): Observable<any> {
    return this.getAllDraftFiles().pipe(tap(fileDrafts => {
      const userDraft = this.findUserDraftFile();
      if (userDraft) {
        this.selectDraftFile(userDraft);
      } else {
        this.selectDraftFile(fileDrafts[0]);
      }
    }));
  }

  getAllDraftFiles(): Observable<ScommFileDraftResult[]> {

    return this.specificationLegacyFiles.getScommFileDrafts(this.currentLegacyServerFile.id).pipe(
      tap(fileDrafts => {
        this.fileDrafts = fileDrafts;
        this.userHasDraftFile = this.findUserDraftFile() !== undefined;
        this.legacyFileToolbarService.userHasDraftFile = this.userHasDraftFile;
        this.legacyFileToolbarService.draftFilesExists = this.draftFilesExists;
        this.legacyFileToolbarService.isCurrentUserDraftSelected = this.isCurrentUserDraftSelected;
      }));
  }

  findUserDraftFile() {
    if (!this.fileDrafts) {
      return undefined;
    }

    return this.fileDrafts.find(fileDraft => fileDraft.draft.user.userName === this.currentUser.userName);
  }

  viewMasterFile() {
    this.fileEditorAccessor().codeEditor.setOption('readOnly', true);
    this.currentFileNameUpdater(this.currentLegacyServerFile.name);
    this.fileEditorAccessor().activeCodeEditorContent = this.currentLegacyServerFile.data;
    this.legacyFileToolbarService.mergeMode = false;
    this.legacyFileToolbarService.viewDraftsActive = false;
    this.currentSingleFileContent = this.currentLegacyServerFile.data;
  }

  whenMergeServerFile() {
    if (this.fileEditorAccessor()) {
      this.loadAndSelectDraftFile().subscribe({ next: () => this.whenLoadAndSelectDraftFileIsDone() });
    }
  }

  whenCloseDiffMerge() {
    setTimeout(() => {
      this.fileEditorAccessor().initCodeEditor();
      this.viewMasterFile();
    }, 200);
  }

  whenExecuteMerge() {
    const newMasterFile = new ScommFile();
    newMasterFile.data = this.currentRightFileContent;
    newMasterFile.id = this.currentLegacyServerFile.id;
    newMasterFile.fileType = ScommFileType.LegacyServerFile;
    newMasterFile.specificationVersionId = this.currentLegacyServerFile.specificationVersionId;
    newMasterFile.name = this.currentLegacyServerFile.name;
    this.legacyFileToolbarService.mergeMode = false;

    this.specificationLegacyFiles.updateSpecificationScommFile(newMasterFile).subscribe(
      {
        next: (updatedMasterFile) => this.whenUpdateSpecificationScommFileIsDone(updatedMasterFile)
      });
  }

  updateMasterFile(updatedMasterFile: ScommFile) {
    const masterFileIndex = this.availableFiles.findIndex(file => file.id === this.currentLegacyServerFile.id);
    this.availableFiles[masterFileIndex] = updatedMasterFile;
  }

  whenCreateFileDraft() {
    const scommfileDraft = new ScommFileDraft();
    scommfileDraft.data = this.currentSingleFileContent;
    scommfileDraft.user = this.currentUser;
    scommfileDraft.fileType = ScommFileType.LegacyServerFile;
    scommfileDraft.name = this.specificationService.currentSpecification.name + '.draft';

    this.specificationLegacyFiles.updateFileDraft(this.currentLegacyServerFile.id, this.currentUser.userName, scommfileDraft).subscribe(draftResult => {
      this.fileEditorAccessor().activeCodeEditorContent = draftResult.draft.data;
      this.fileEditorAccessor().codeEditor.setOption('readOnly', false);
      this.legacyFileToolbarService.viewDraftsActive = true;
      this.legacyFileToolbarService.isValidDraft = draftResult.isValid;
      this.legacyFileToolbarService.draftValidationMessage = draftResult.validationMessage;
      this.whenViewDrafts();
    });
  }

  whenSaveFileDraft() {
    let scommfileDraft: ScommFileDraft;
    this.fileDrafts.forEach(draftResult => {
      const currentDraft = draftResult.draft;
      if (currentDraft.user.userName === this.currentUser.userName) {
        scommfileDraft = this.cloneScommFileDraft(currentDraft);
      }
    });

    if (this.legacyFileToolbarService.mergeMode) {
      scommfileDraft.data = this.currentRightFileContent;
    } else {
      scommfileDraft.data = this.fileEditorAccessor().codeEditor.getValue();
    }

    this.specificationLegacyFiles.updateFileDraft(this.currentLegacyServerFile.id, this.currentUser.userName, scommfileDraft)
      .subscribe(draftResult => {
        this.selectedDraftFile.draft.data = draftResult.draft.data;
        this.selectedDraftFile.isValid = draftResult.isValid;
        this.selectedDraftFile.validationMessage = draftResult.validationMessage;
        this.updateValidationStatusOfCurrentDraft(draftResult);
      });
  }

  whenDeleteFileDraft() {
    const deleteDraftObservable = this.specificationLegacyFiles.deleteFileDraft(this.currentLegacyServerFile.id, this.currentUser.userName)
      .pipe(tap(result => {
        this.viewMasterFile();
      }));
    const draftsObservable = this.specificationLegacyFiles.getScommFileDrafts(this.currentLegacyServerFile.id)
      .pipe(tap(fileDrafts => {
        this.fileDrafts = fileDrafts;
      }));
    const allDraftsObservable = this.getAllDraftFiles();

    concat(deleteDraftObservable, draftsObservable, allDraftsObservable).subscribe();
  }

  selectDraftFile(file: ScommFileDraftResult) {
    if (this.fileEditorAccessor()) {
      this.selectedDraftFile = file;
      this.currentSingleFileContent = this.selectedDraftFile.draft.data;
      this.legacyFileToolbarService.isCurrentUserDraftSelected = this.isCurrentUserDraftSelected;
      this.fileEditorAccessor().activeCodeEditorContent = this.currentSingleFileContent;
      this.currentFileNameUpdater(file.draft.name);
      this.updateValidationStatusOfCurrentDraft(this.selectedDraftFile);
      this.setEditorReadOnlyIfNeeded(file.draft);
    }
  }

  updateValidationStatusOfCurrentDraft(draftResult: ScommFileDraftResult) {
    this.legacyFileToolbarService.isValidDraft = draftResult.isValid;
    this.legacyFileToolbarService.draftValidationMessage = draftResult.validationMessage;
  }

  setEditorReadOnlyIfNeeded(file: ScommFileDraft) {
    this.userService.getCurrentUser().subscribe(currentUser => {
      if (file.user.userName === currentUser.userName) {
        this.fileEditorAccessor().codeEditor.setOption('readOnly', false);
      } else {
        this.fileEditorAccessor().codeEditor.setOption('readOnly', true);
      }
    });
  }

  cloneScommFileDraft(source: ScommFileDraft): ScommFileDraft {
    const scommfileDraft = new ScommFileDraft();
    scommfileDraft.created = source.created;
    scommfileDraft.data = source.data;
    scommfileDraft.fileType = source.fileType;
    scommfileDraft.id = source.id;
    scommfileDraft.modified = source.modified;
    scommfileDraft.name = source.name;
    scommfileDraft.user = source.user;

    return scommfileDraft;
  }

  reset() {
    this.fileDrafts = [];
    this.currentUser = undefined;
    this.availableFiles = [];
    this.currentSingleFileContent = '';
    this.selectedDraftFile = undefined;
    if (this.legacyServerFileSubscription) {
      this.legacyServerFileSubscription.unsubscribe();
      this.legacyServerFileSubscription = undefined;
    }

    if (this.toolbarEventSubscriptions) {
      this.toolbarEventSubscriptions.forEach(subscription => subscription.unsubscribe());
      this.toolbarEventSubscriptions = [];
    }

    this.currentLeftFileContent = '';
    this.currentRightFileContent = '';
    this.specificationVersionId = undefined;
  }

  resetLeftRightContent() {
    this.currentLeftFileContent = '';
    this.currentRightFileContent = '';
  }

  private whenLoadAndSelectDraftFileIsDone() {
    this.legacyFileToolbarService.viewDraftsActive = true;

    this.updateAvailableScommFiles(this.specificationVersionId);
    this.currentLeftFileContent = this.currentLegacyServerFile.data;
    this.fileDrafts.forEach(draftResult => {
      const currentDraft = draftResult.draft;
      if (currentDraft.user.userName === this.currentUser.userName) {
        this.currentRightFileContent = currentDraft.data;
      }
    });
    this.fileEditorAccessor().enableMerge();
  }

  private whenUpdateSpecificationScommFileIsDone(updatedMasterFile: ScommFile) {
    this.updateMasterFile(updatedMasterFile);
    this.updateAvailableScommFiles(this.specificationVersionId);

    const deleteDraftObservable = this.specificationLegacyFiles.deleteFileDraft(this.currentLegacyServerFile.id, this.currentUser.userName);
    const getAllDraftsObservable = this.getAllDraftFiles().pipe(tap(result => {
      this.legacyFileToolbarService.userHasDraftFile = this.userHasDraftFile;
      this.legacyFileToolbarService.isCurrentUserDraftSelected = this.isCurrentUserDraftSelected;
      this.fileEditorAccessor().initCodeEditor();
      this.viewMasterFile();
    }));

    concat(deleteDraftObservable, getAllDraftsObservable).subscribe();
  }
}

