import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  ServerIdentificationCompareOperatorType,
  ServerIdentificationExpressionNodeType,
} from 'app/app-model/server-identification/server-identification.enums';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';
import { ExpressionNodeHelperService } from 'app/app-services/expression-node-helper.service';
import { IdentificationExpressionService } from 'app/app-services/identification-expression.service';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { Subscription } from 'rxjs/internal/Subscription';

import { IdentificationGroup } from '../app-model/identification-group/identification-group';
import { IdentificationExpressionItemService } from '../app-services/identification-expression-item.service';
import { DiagnosticProtocolType } from 'app/app-model/enums';
import { SpecificationService } from 'app/app-services/specification-service';
import { tap } from 'rxjs/operators';


@Component({
  selector: 'app-identification-group-expression',
  templateUrl: './identification-group-expression.component.html',
  styleUrls: ['./identification-group-expression.component.css'],
  providers: [IdentificationExpressionItemService]
})
export class IdentificationGroupExpressionComponent implements OnInit, OnDestroy {

  @ViewChild('activateMoveExpressionConfirm') moveExpressionsConfirmDiag: ElementRef;

  @Input()
  isReadOnly: boolean;

  @Input()
  isAllowed: boolean;

  @Output()
  expressionUpdated: EventEmitter<void> = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ServerIdentificationExpressionNodeType = ServerIdentificationExpressionNodeType;
  movingExpressions = false;
  componentSubscriptions: Subscription[] = [];

  lastExecutedAddExpressionCommand: () => void;

  private _identificationGroup: IdentificationGroup;
  public get identificationGroup(): IdentificationGroup {
    return this._identificationGroup;
  }
  @Input()
  public set identificationGroup(v: IdentificationGroup) {
    this._identificationGroup = v;
  }

  get canCreateExpressionNode(): boolean {
    return this.identificationGroup &&
      (this.identificationGroup.expression === null || !this.expressionNodeCreatorService.isOperator(this.identificationGroup.expression));
  }

  constructor(private expressionNodeCreatorService: ExpressionNodeHelperService,
    private identificationExpressionService: IdentificationExpressionService,
    private specificationService: SpecificationService,
    private identificationExpressionItemService: IdentificationExpressionItemService) { }

  ngOnInit() {
    if (!this.expressionNodeCreatorService.identificationExpressionService) {
      this.expressionNodeCreatorService.identificationExpressionService = this.identificationExpressionService;
    }

    this.componentSubscriptions.push(this.identificationExpressionItemService.onDelete.pipe(tap(expression => {
      if (expression === this.identificationGroup.expression) {
        this.deleteExpression(expression);
      }
    })).subscribe());
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  clearSubscriptions() {
    this.componentSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  onMoveExpressionsConfirm() {
    /**
     * If the user accepts to move the old expressions and the new one to be created do the following
     *
     * 1. Create the new node by executing lastExecutedAddExpressionCommand
     * 2. Move the expressions through th expression node creator service.
     */
    this.movingExpressions = true;
    const currentExpression =
      this.expressionNodeCreatorService.cloneExpression(this.identificationGroup.expression);
    this.identificationGroup.expression = undefined;

    this.lastExecutedAddExpressionCommand();

    const newExpression =
      this.expressionNodeCreatorService.cloneExpression(this.identificationGroup.expression);

    const operator = this.expressionNodeCreatorService.createExpressionNode(ServerIdentificationExpressionNodeType.AndOperator, undefined, undefined, 0);
    operator.expressions = [currentExpression, newExpression];
    this.identificationGroup.expression = operator;

    this.movingExpressions = false;
    this.expressionUpdated.next();
  }

  addValueComparisonNodeEqualTo() {
    this.lastExecutedAddExpressionCommand = this.addValueComparisonNodeEqualTo;
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.EqualTo);
  }

  addValueComparisonNodeLessThan() {
    this.lastExecutedAddExpressionCommand = this.addValueComparisonNodeLessThan;
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.LessThan);
  }

  addValueComparisonNodeLessThanOrEqualTo() {
    this.lastExecutedAddExpressionCommand = this.addValueComparisonNodeLessThanOrEqualTo;
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.LessThanOrEqualTo);
  }

  addValueComparisonNodeGreaterThan() {
    this.lastExecutedAddExpressionCommand = this.addValueComparisonNodeGreaterThan;
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.GreaterThan);
  }

  addValueComparisonNodeGreaterThanOrEqualTo() {
    this.lastExecutedAddExpressionCommand = this.addValueComparisonNodeGreaterThanOrEqualTo;
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.GreaterThanOrEqualTo);
  }

  addValueComparisonExpressionNode(compareOperator: ServerIdentificationCompareOperatorType) {
    if (this.identificationGroup.expression && this.movingExpressions === false) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
    } else {
      this.identificationGroup.expression =
        this.expressionNodeCreatorService.createExpressionNode(
          this.nodeType,
          { data: '0', dataType: OdxDataType.UInt32 },
          compareOperator,
          0);
      if (!this.movingExpressions) {
        // this.expressionUpdated.next();
      }
    }
  }

  addValueEqualsComparisonNode() {
    this.lastExecutedAddExpressionCommand = this.addValueEqualsComparisonNode;

    if (this.identificationGroup.expression && this.movingExpressions === false) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
    } else {
      this.identificationGroup.expression =
        this.expressionNodeCreatorService.createExpressionNode(this.nodeType,
          { data: '', dataType: OdxDataType.AsciiString },
          ServerIdentificationCompareOperatorType.EqualTo,
          0);
      if (!this.movingExpressions) {
        // this.expressionUpdated.next();
      }
    }
  }

  addValueStartsWithComparisonNode() {
    this.lastExecutedAddExpressionCommand = this.addValueStartsWithComparisonNode;

    if (this.identificationGroup.expression && this.movingExpressions === false) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
    } else {
      this.identificationGroup.expression =
        this.expressionNodeCreatorService.createExpressionNode(this.nodeType,
          { data: '', dataType: OdxDataType.AsciiString },
          ServerIdentificationCompareOperatorType.StartsWith,
          0);
      if (!this.movingExpressions) {
        // this.expressionUpdated.next();
      }
    }
  }

  addCanAddressComparisonNode() {
    this.lastExecutedAddExpressionCommand = this.addCanAddressComparisonNode;

    if (this.identificationGroup.expression && this.movingExpressions === false) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
    } else {
      this.identificationGroup.expression =
        this.expressionNodeCreatorService.createExpressionNode(ServerIdentificationExpressionNodeType.CanAddressCompareOperand,
          { data: '', dataType: OdxDataType.AsciiString },
          ServerIdentificationCompareOperatorType.EqualTo,
          0);
      if (!this.movingExpressions) {
        // this.expressionUpdated.next();
      }
    }
  }

  addOperator() {
    this.lastExecutedAddExpressionCommand = this.addOperator;

    if (this.identificationGroup.expression && this.movingExpressions === false) {
      this.moveExpressionsConfirmDiag.nativeElement.click();
    } else {
      this.identificationGroup.expression =
        this.expressionNodeCreatorService.createExpressionNode(ServerIdentificationExpressionNodeType.AndOperator, undefined, undefined, 0);
      if (!this.movingExpressions) {
        this.expressionUpdated.next();
      }
    }
  }

  deleteExpression(expression: ServeridentificationExpressionModel) {
    this.identificationGroup.expression = undefined;
    this.expressionUpdated.next();
  }

  get nodeType(): ServerIdentificationExpressionNodeType {
    return ServerIdentificationExpressionNodeType.LegacyEcuIdentifierCompareOperand;
  }

}
