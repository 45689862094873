import { StructureEcuGeneration } from 'app/app-model/structure/structure-ecu-generation';

export class StructureEcuFamily {
  name: string;
  generations: StructureEcuGeneration[];

  constructor() {
    this.generations = [];
  }
}
