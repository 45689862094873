/* eslint-disable @typescript-eslint/naming-convention */
import { TypedValueData } from 'app/modules/shared/model/service/parameters/typed-value-data';

import { PathStep } from './path-step.model';

export class ServiceExecutionRequestValue {
  sequenceId: number;
  executionIndex: number;
  index: number;
  path: PathStep[] = [];
  value: TypedValueData;
  valueType: RequestParameterValueType;
}

// eslint-disable-next-line no-shadow
export enum RequestParameterValueType {
  Literal = 0,
  ContextItem = 1
}


