import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { delay } from 'rxjs/operators';

import { MessageTemplateComponent } from '../components/message-handler/message-templates/message-templates.component';
import { ApiErrorDetails } from '../model/api/api-error-details';
import { MessageTypeEnum } from '../model/message/messageTypeEnum';
import { ApiMessageService } from './api-message-service';

@Injectable()
export class MessageService {

  private _snackbarDismissDelay = 600;

  constructor(private apiMessageService: ApiMessageService, private snackBar: MatSnackBar) {

    if (this.apiMessageService) {
      apiMessageService.putMessage.subscribe(m => {
        const snackBarRef = this.dispatchUpdatingMessage();
        m.completed.pipe(delay(this._snackbarDismissDelay)).subscribe(() => snackBarRef.dismiss());
      });

      apiMessageService.postMessage.subscribe(m => {
        const snackBarRef = this.dispatchCreatingMessage();
        m.completed.pipe(delay(this._snackbarDismissDelay)).subscribe(() => snackBarRef.dismiss());
      });

      apiMessageService.deleteMessage.subscribe(m => {
        const snackBarRef = this.dispatchDeletingMessage();
        m.completed.pipe(delay(this._snackbarDismissDelay)).subscribe(() => snackBarRef.dismiss());
      });
    }
  }

  dispatchErrorMessageFromApi(apiErrorDetails: ApiErrorDetails, modal: boolean = true): MatSnackBarRef<MessageTemplateComponent> {
    if (apiErrorDetails.statusCode === 404) {
      return;
    }
    return this.openSnackbar('Api Error', apiErrorDetails.userMessage, apiErrorDetails.detailedMessage, MessageTypeEnum.Error, modal);
  }

  dispatchCantDeleteErrorMessage(message: string, modal: boolean = true): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar('Error', "This identification can not be deleted as it is being used in expressions", undefined, MessageTypeEnum.Error, modal);
  }

  dispatchErrorMessage(message: string, modal: boolean = true): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar('Error', message, undefined, MessageTypeEnum.Error, modal);
  }

  dispatchInfoMessage(message: string, modal: boolean = true): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar('Info', message, undefined, MessageTypeEnum.Info, modal);
  }

  dispatchWarningMessage(message: string, modal: boolean = true): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar('Warning', message, undefined, MessageTypeEnum.Warning, modal);
  }

  dispatchSuccessMessage(message: string, modal: boolean = true): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar('Success', message, undefined, MessageTypeEnum.Success, modal);
  }

  dispatchUpdatingMessage(modal: boolean = false): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar('Updating...', undefined, undefined, MessageTypeEnum.PerformingAction, modal);
  }

  dispatchCreatingMessage(modal: boolean = false): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar('Creating...', undefined, undefined, MessageTypeEnum.PerformingAction, modal);
  }

  dispatchDeletingMessage(modal: boolean = false): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar('Deleting...', undefined, undefined, MessageTypeEnum.PerformingAction, modal);
  }

  private openSnackbar(label: string, message: string, details: string, messageType: MessageTypeEnum, dismissable: boolean): MatSnackBarRef<MessageTemplateComponent> {
    const snackBarRef = this.snackBar.openFromComponent(MessageTemplateComponent, { duration: 1000000, verticalPosition: 'top' });
    snackBarRef.instance.label = label;
    snackBarRef.instance.message = message;
    snackBarRef.instance.details = details;
    snackBarRef.instance.messageType = messageType;
    snackBarRef.instance.dismissable = dismissable;
    snackBarRef.instance.dismiss.pipe(delay(this._snackbarDismissDelay)).subscribe(() => snackBarRef.dismiss());

    return snackBarRef;
  }
}
