import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { SpecificationVersionValidationService } from 'app/app-services/specification-version-validation.service';
import { DataCategoryModel } from 'app/data-categories/data-category-model';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { Subscription } from 'rxjs';

import { DiagnosticProtocolType, SpecificationType } from '../../app-model/enums';
import { Specification } from '../../app-model/specification';
import { SpecificationVersion } from '../../app-model/specification-version';

@Component({
  selector: 'app-specification-navbar',
  templateUrl: './specification-navbar.component.html',
  styleUrls: ['./specification-navbar.component.css']
})
export class SpecificationNavbarComponent implements OnInit, OnDestroy {
  availableCategories: DataCategoryModel[];
  currentSpecification: Specification;
  currentSpecificationVersion: SpecificationVersion;
  currentSpecificationVersionErrors: number;
  currentSpecificationVersionWarnings: number;
  issueChangedSubscription: Subscription;
  validationStateSubscription: Subscription;
  isLegacySpecification: boolean;
  validationRunning: boolean;
  specificationChangedSubscription: Subscription;

  constructor(private categoryService: DataCategoriesService,
    private specificationService: SpecificationService,
    private validationService: SpecificationVersionValidationService,
    private specificationStatusService: SpecificationStatusService) { }

  ngOnInit() {
    this.currentSpecification = this.specificationService.currentSpecification;
    this.currentSpecificationVersion = this.specificationService.currentSpecificationVersion;
    this.specificationService.selectedSpecificationEventEmitter.subscribe(event => {
      this.currentSpecification = event.specification;
      this.currentSpecificationVersion = event.specificationVersion;

      if (this.currentSpecificationVersion) {
        this.isLegacySpecification = this.isLegacyVersion;
      }

      this.specificationChangedSubscription = this.specificationService.selectedSpecificationEventEmitter.subscribe(_ => {
        this.availableCategories = this.categoryService.getDataCategories();
      });
    });

    this.issueChangedSubscription = this.validationService.issueChangedEventEmitter.subscribe(event => {
      this.currentSpecificationVersionWarnings = event.warnings; this.currentSpecificationVersionErrors = event.errors;
    });

    this.validationStateSubscription = this.validationService.validationStateEventEmitter.subscribe(validationRunning => {
      this.validationRunning = validationRunning;
    });
  }

  ngOnDestroy(): void {
    if (this.issueChangedSubscription) {
      this.issueChangedSubscription.unsubscribe();
    }

    if (this.validationStateSubscription) {
      this.validationStateSubscription.unsubscribe();
    }

    if (this.specificationChangedSubscription) {
      this.specificationChangedSubscription.unsubscribe();
    }
  }

  get enabledCategoriesForCurrentVersion(): DataCategoryModel[] {
    return this.availableCategories.filter(category => this.isEnabledForSpecificationVersion(category));
  }

  get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get showServices(): boolean {
    if (!this.currentSpecification || this.isLegacyVersion && this.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
      return false;
    }
    return true;
  }

  get showIdentifications(): boolean {
    if (!this.currentSpecification || this.isLegacyVersion && this.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
      return false;
    }
    return true;
  }

  public get isLegacyVersion() {
    return this.specificationService.isLegacyVersion;
  }

  isEnabledForSpecificationVersion(category: DataCategoryModel): boolean {
    if (!category.enabled) {
      return false;
    } else {
      if (this.isLegacyVersion) {
        if ((category.category === Category.Services || category.category === Category.Identification) &&
          this.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
          return false;
        }

        return category.hasLegacySupport;
      }
    }

    return true;
  }
}
