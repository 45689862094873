import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EcuResetCategoryItem } from 'app/data-categories/ecu-reset/ecu-reset-category-item';
import { ActivatedRoute } from '@angular/router';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { EcuResetService } from 'app/app-services/ecu-reset.service';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { CategoryComponentBaseComponent } from '../../modules/shared/model/category-component-base';
import { CommandType, EcuResetDelayType } from 'app/app-model/enums';
import { ServiceExecutionModel } from 'app/app-model/diagnostic-service/service-execution.model';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { NavigationService } from 'app/app-services/navigation.service';


@Component({
  selector: 'app-ecu-reset',
  templateUrl: './ecu-reset.component.html',
  styleUrls: ['./ecu-reset.component.css']
})
export class EcuResetComponent extends CategoryComponentBaseComponent<EcuResetCategoryItem> implements OnInit {

  ecuResetItem: EcuResetCategoryItem;
  ecuResetDelayType = EcuResetDelayType;

  constructor(activatedRoute: ActivatedRoute,
    private specificationStatusService: SpecificationStatusService,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    messageService: MessageService, private ecuResetService: EcuResetService, protected navigationService: NavigationService,private authService:AuthService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.EcuResets, navigationService);
  }

  @Output()
  definitionChanged: EventEmitter<{ wasDelayTypeChanged: boolean; requestSave: boolean }> = new EventEmitter();

  ngOnInit() {
    this.itemChanged.subscribe(item => {
      this.ecuResetItem = item;
    });
  }

  get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  get loginServiceExecution(): ServiceExecutionModel {
    return this.ecuResetItem.serviceExecutionSequence.findServiceExecutionWithCommandType(CommandType.Login);
  }

  editingNameDone(name: string) {
    if (name.trim().length > 0) {
      this.ecuResetItem.setName(name.trim());
    } else {
      this.messageService.dispatchErrorMessage('ECU Reset name can not be empty!');
    }
  }

  onResetChanged() {
    this.ecuResetItem.notifySessionChanged();
  }

  onLoginCommandChanged(prerequisitesCommand: string) {
    this.ecuResetItem.serviceExecutionSequence.addComandAsServiceExecution(prerequisitesCommand, CommandType.Login, 0);
  }

  getEcuResetDelayType(): string[] {
    return Object.keys(EcuResetDelayType).filter(d => !this.isNumeric(d))
  }

  public isNumeric(value) {
    // Use the unary plus operator (+) to convert the value to a number
    // and check if it's a finite number
    return !isNaN(value) && !isNaN(parseFloat(value));
  }
  
  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }

}
