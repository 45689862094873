import { Directive, Input, EventEmitter, ElementRef, Inject, OnChanges, SimpleChanges, AfterContentInit } from '@angular/core';

@Directive({
    selector: '[appFocus]'
})
export class FocusDirective {

    private _hasFocus;
    @Input('appFocus')
    set hasFocus(hasFocus: boolean) {
        if (this._hasFocus === hasFocus) {
            return;
        }

        this._hasFocus = hasFocus;
        if (hasFocus) {
            if (!this.element.nativeElement.tabIndex) {
                this.element.nativeElement.tabIndex = '-1';
            }

            setTimeout(() => {
                this.element.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
            });

        } else {
            this.element.nativeElement.blur();
        }
    }

    constructor(@Inject(ElementRef) private element: ElementRef) {
    }
}
