import { Component, Input } from '@angular/core';

import { ParameterData } from '../../model/service/parameters/parameter-data';

@Component({
  selector: 'app-service-parameter-definition',
  templateUrl: './service-parameter-definition.component.html',
  styleUrls: ['./service-parameter-definition.component.css']
})
export class ServiceParameterDefinitionComponent {

  private _requestValueParameters: ParameterData[];
  public get requestValueParameters(): ParameterData[] {
    return this._requestValueParameters;
  }
  @Input()
  public set requestValueParameters(v: ParameterData[]) {
    this._requestValueParameters = v;
  }
}
