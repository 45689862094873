import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { OdxDataType, TypedValueData } from 'app/modules/shared/model/service/parameters/typed-value-data';

export class ParameterValueValidator {

    /** Checks if the value assigned to a parameter is valid according to its value type */
    public hasValidParameterValue(parameterToEval: ParameterData, valueToEval: TypedValueData): boolean {
        if (!valueToEval || !valueToEval.data || valueToEval.data.length <= 0) {
            return false;
        }

        switch (parameterToEval.getEnumDataType()) {
            case OdxDataType.AsciiString:
            case OdxDataType.ByteField:
                return true;
            case OdxDataType.Float32:
            case OdxDataType.Float64:
            case OdxDataType.Int32:
                return this.isSignedNumeric(valueToEval.data);
            case OdxDataType.UInt32:
                return this.isUnsignedNumeric(valueToEval.data);
            default:
                return false;
        }
    }

    public hasValidParameterStringValue(valueToCheck: string): boolean {
        if (valueToCheck) {
            return true;
        } else {
            return false;
        }
    }

    public getDefaultValue(dataType: OdxDataType): string {
        switch (dataType) {
            case OdxDataType.AsciiString:
                return '';
            case OdxDataType.ByteField:
                return '';
            case OdxDataType.Float32:
            case OdxDataType.Float64:
            case OdxDataType.Int32:
            case OdxDataType.UInt32:
                return '0';

            default:
                return '';
        }
    }

    private isSignedNumeric(valueToCheck: string): boolean {
        return this.isNumeric(valueToCheck) && (+valueToCheck) >= -2147483648 && (+valueToCheck) <= 2147483647;
    }

    private isUnsignedNumeric(valueToCheck: string): boolean {
        return this.isNumeric(valueToCheck) && (+valueToCheck) >= 0 && (+valueToCheck) <= 4294967295;
    }

    public isNumeric(value) {
        // Use the unary plus operator (+) to convert the value to a number
        // and check if it's a finite number
        return !isNaN(value) && !isNaN(parseFloat(value));
      }
}
