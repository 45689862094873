<table>
    <tr>
        <td style="width: 150px;"><b>Save draft file</b></td>
        <td>Ctrl+S</td>
    </tr>
    <tr>
        <td><b>Start search</b></td>
        <td>Ctrl+F</td>
    </tr>
    <tr>
        <td><b>Find next</b></td>
        <td>Ctrl+G</td>
    </tr>
    <tr>
        <td><b>Find previous</b></td>
        <td>Ctrl+Shift+G</td>
    </tr>
    <tr>
        <td><b>Replace</b></td>
        <td>Ctrl+Shift+F</td>
    </tr>
    <tr>
        <td><b>Replace all</b></td>
        <td>Ctrl+Shift+R</td>
    </tr>
    <tr>
        <td><b>Jump to line</b></td>
        <td>Alt+G</td>
    </tr>
</table>