import { Injectable, OnDestroy } from '@angular/core';
import { LegacyContentModel } from 'app/app-model/legacyContent.model';
import { LegacyContentCategoryItem } from 'app/data-categories/teststeps/legacyContent-category-item';
import { ApiProxyService } from 'app/modules/shared/services/api-proxy.service';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CategoryServiceBase } from './category-base.service';
import { SpecificationService } from './specification-service';

@Injectable({ providedIn: 'root' })
export class LegacyContentService extends CategoryServiceBase<LegacyContentModel, LegacyContentCategoryItem> implements OnDestroy {

  constructor(protected apiProxy: ApiProxyService,
    private specificationService: SpecificationService) {
    super(apiProxy);
    this.apiCategoryName = 'teststeps';
  }

  ngOnDestroy(): void {
    super.unsubscribe();
  }

  getUnreferencedTestSteps(specificationVersionId: number): Observable<LegacyContentModel> {
    return this.apiProxy.get<LegacyContentModel>(`${environment.apiUrl}versions/${specificationVersionId}/teststeps`)
  }

  getCategoryItemModel(specificationVersionId: number, name: string): Observable<LegacyContentCategoryItem> {
    throw new Error('Method not implemented.');
  }

  getItemsPreview(specificationVersionId: number): Observable<LegacyContentCategoryItem[]> {
    this.isPending = true;
    if (this.hasCachedItems) {
      this.isPending = false;
      return of(this.items);
    } else {
      return this.apiProxy.get<LegacyContentModel>(`${environment.apiUrl}versions/${specificationVersionId}/teststeps`)
        .pipe(map(testStepsModel => {
          const testStepCategoryItem = new LegacyContentCategoryItem(this.specificationService, this);
          testStepCategoryItem.model = testStepsModel ?? new LegacyContentModel();
          this.isPending = false;
          return [testStepCategoryItem];
        }));
    }
  }
  getLegacyFreezeFrames(specificationVersionId: number): Observable<LegacyContentModel> {
    return this.apiProxy.get<LegacyContentModel>(`${environment.apiUrl}versions/${specificationVersionId}/legacyfreezframes`);
  }

  updateUnreferencedTestSteps(specificationVersionId: number, itemModel: LegacyContentModel, itemName?: string, itemId?: number): Observable<LegacyContentModel> {
    return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/teststeps/`, itemModel);
  }

  createUnreferencedTestStepsDocument(specificationVersionId: number): Observable<LegacyContentModel> {
    return this.apiProxy.post<LegacyContentModel>(`${environment.apiUrl}versions/${specificationVersionId}/teststeps/default`, undefined);
  }

  deleteItem(specificationVersionId: number, itemName?: string, itemId?: number): Observable<any> {
    return this.apiProxy.delete<LegacyContentModel>(`${environment.apiUrl}versions/${specificationVersionId}/teststeps`);
  }

  updateItem(specificationVersionId: number, itemModel: LegacyContentModel, itemName?: string, itemId?: number): Observable<LegacyContentModel> {
    // return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/teststeps/`, itemModel);
    throw new Error('Method not implemented.');
  }

  getCompleteItems(specificationVersionId: number): Observable<LegacyContentCategoryItem[]> {
    throw new Error('Method not implemented.');
  }

  createItem(specificationVersionId: number, itemName?: string, itemId?: number): Observable<LegacyContentCategoryItem> {
    throw new Error('Method not implemented.');
  }
}
