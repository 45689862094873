import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParameterDataModel } from 'app/app-model/diagnostic-service/parameter-data.model';
import {
  ServiceExecutionResponseValue,
} from 'app/app-model/diagnostic-service/sequence-execution-response-parameter-data.model';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { EcuIdentifierModel } from 'app/app-model/ecu-identification/ecu-identifier.model';
import { CategoryServiceBase } from 'app/app-services/category-base.service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { EcuIdentifierCategoryItem } from 'app/data-categories/identification-property/ecu-identification-category-item';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { environment } from 'environments/environment';
import { Observable, Observer, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { SpecificationVersionHubService } from './hubs/specification-version-hub.service';
import { SpecificationService } from './specification-service';

@Injectable({
  providedIn: 'root'
})
export class EcuIdentifierService extends CategoryServiceBase<EcuIdentifierModel, EcuIdentifierCategoryItem> implements OnDestroy {
  constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
    private specificationVersionHubService: SpecificationVersionHubService,
    private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected apiProxy: ApiProxyService) {
    super(apiProxy);
  }

  ngOnDestroy(): void {
    super.unsubscribe();
  }

  public getItemsPreview(specificationVersionId: number): Observable<EcuIdentifierCategoryItem[]> {
    if (this.hasCachedItems) {
      return of(this.items);
    }

    return this.apiProxy.get<EcuIdentifierModel[]>(`${environment.apiUrl}versions/${specificationVersionId}/identifiers`)
      .pipe(map((ecuIdentifiers) => {
        this.items = this.createEcuIdentifierCategoryItems(ecuIdentifiers);
        this.hasCachedItems = true;
        return this.items;
      }));
  }

  public getCompleteItems(specificationVersionId: number): Observable<EcuIdentifierCategoryItem[]> {
    return this.getItemsPreview(specificationVersionId);
  }

  public getItem(specificationVersionId: number, itemId?: string): Observable<EcuIdentifierCategoryItem> {
    const cachedItem = this.findCachedItem(itemId);

    if (!cachedItem.isPreview) {
      return of(cachedItem);
    } else {
      return this.apiProxy.get<EcuIdentifierModel>(
        `${environment.apiUrl}versions/${specificationVersionId}/identifiers/${cachedItem.model.name}`)
        .pipe(map(item => {
          cachedItem.isPreview = false;
          cachedItem.isStoredInSource = true;
          cachedItem.model = item;

          return cachedItem;
        }));
    }
  }

  public getCategoryItemModel(specificationVersionId: number, name: string): Observable<EcuIdentifierCategoryItem> {
    return this.apiProxy.get<EcuIdentifierModel>(
      `${environment.apiUrl}versions/${specificationVersionId}/identifiers/${name}`).pipe(map(item => {
        const ecuIdentificationItem = this.createEcuIdentifierCategoryItem(item, false);
        this.items.push(ecuIdentificationItem);
        return ecuIdentificationItem;
      }));
  }

  public createItem(specificationVersionId: number, itemName?: string): Observable<EcuIdentifierCategoryItem> {
    const ecuIdentifierModel = new EcuIdentifierModel();
    ecuIdentifierModel.name = itemName;
    ecuIdentifierModel.readSequence = new ServiceExecutionSequenceModel();
    ecuIdentifierModel.code = 1;

    return this.apiProxy.post<EcuIdentifierModel>(
      `${environment.apiUrl}versions/${specificationVersionId}/identifiers/`, ecuIdentifierModel).pipe(map(savedEcuIdentifierModel => {
        const ecuIdentificationItem = this.createEcuIdentifierCategoryItem(savedEcuIdentifierModel, false);
        this.items.push(ecuIdentificationItem);

        return ecuIdentificationItem;
      }));
  }

  public deleteItem(specificationVersionId: number, itemName?: string): Observable<any> {
    return this.apiProxy.delete<EcuIdentifierModel>(`${environment.apiUrl}versions/${specificationVersionId}/identifiers/${itemName}`)
      .pipe(tap(deletedItem => this.removeCachedItem(deletedItem.name)));
  }

  public updateItem(specificationVersionId: number, ecuIdDataModel: any, itemName?: string): Observable<EcuIdentifierModel> {
    const dataModel = ecuIdDataModel as EcuIdentifierModel;
    const cachedItem = this.findCachedItem(dataModel.name);

    if (!this.hasValidData(cachedItem.model)) {
      return of(dataModel);
    }

    return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/identifiers/${itemName}`, ecuIdDataModel);
  }

  public findCachedItem(itemName: string): EcuIdentifierCategoryItem {
    if (!this.items || this.items.length <= 0) {
      return null;
    }

    return this.items.find(item => (item.model.name === itemName) || item.name === itemName);
  }

  public addOutputToConnectedEcuIdentifiers(
    diagnosticService: DiagnosticServiceCategoryItem,
    outputParameter: ParameterDataModel,
    specificationVersionId: number): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      observer.next(true);
      observer.complete();
    });
  }

  public updateParameterNameToConnectedProperties(
    diagnosticService: DiagnosticServiceCategoryItem,
    modifiedParameter: ParameterDataModel,
    specificationVersionId: number): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      observer.next(true);
      observer.complete();
    });
  }

  public sortReponseValuesFromServiceExecutionOrder(
    responseValues: ServiceExecutionResponseValue[],
    responseValuesFromService: ParameterData[]): ServiceExecutionResponseValue[] {
    const orderedItems = [];

    responseValuesFromService.forEach(valFromService => {
      const item = responseValues.find(prop => prop.target.variable === valFromService.name);
      orderedItems.push(item);
    });

    return orderedItems;
  }

  private createEcuIdentifierCategoryItems(ecuIdentifierModels: EcuIdentifierModel[]): Array<EcuIdentifierCategoryItem> {
    const categoryItems = new Array<EcuIdentifierCategoryItem>();
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let ecuIdentifierIndex = 0; ecuIdentifierIndex < ecuIdentifierModels.length; ecuIdentifierIndex++) {
      categoryItems.push(this.createEcuIdentifierCategoryItem(ecuIdentifierModels[ecuIdentifierIndex], true));
    }

    return categoryItems;
  }

  private createEcuIdentifierCategoryItem(
    model: EcuIdentifierModel,
    isPreview: boolean): EcuIdentifierCategoryItem {
    const ecuIdentifierItem = new EcuIdentifierCategoryItem(this.locationService, this.router, this.activatedRoute);
    ecuIdentifierItem.ecuIdentifierService = this;
    ecuIdentifierItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
    ecuIdentifierItem.specificationService = this.specificationService;
    ecuIdentifierItem.isPreview = isPreview;
    ecuIdentifierItem.model = model;
    ecuIdentifierItem.isSyncingFromMaster = false;

    return ecuIdentifierItem;
  }

  private hasValidData(ecuIdentifierModel: EcuIdentifierModel) {
    return ecuIdentifierModel.code !== 0;
  }
}
