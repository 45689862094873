import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FreezeFrameEnumType } from 'app/app-model/enums';
import {
  FreezeFrameEnumInterval,
  FreezeFrameEnumValue,
  FreezeFramesModel,
} from 'app/app-model/freeze-frames/freeze-frames.model';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-freezeframe-enum',
  templateUrl: './freezeframe-enum.component.html',
  styleUrls: ['freezeframe-enum.component.css']
})
export class FreezeFrameEnumComponent implements OnInit {

  @Input()
  public isReadOnly: boolean;

  @Output()
  public changed: EventEmitter<void> = new EventEmitter();

  public freezeFrameDefinitionUpdate = new Subject<void>();

  private _freezeFrameModel: FreezeFramesModel;
  private _selectedFreezeFrameEnumType: string;
  private freezeFrameEnumDelimeter = ';';
  private freezeFrameEnumPartDelimeter = ',';
  private freezeFrameEnumValues: FreezeFrameEnumValue[] = [];
  private freezeFrameEnumIntervals: FreezeFrameEnumInterval[] = [];

  constructor(public dialog: MatDialog,private authService:AuthService) { }

  ngOnInit(): void {
    this.freezeFrameDefinitionUpdate.pipe(
      debounceTime(100),
      distinctUntilChanged())
      .subscribe(_ => this.notifyEnumerationChanged());

    return;
  }

  public get freezeFrameModel(): FreezeFramesModel {
    return this._freezeFrameModel;
  }

  @Input()
  public set freezeFrameModel(v: FreezeFramesModel) {
    this._freezeFrameModel = v;
    this.parseFreezeFrameEnums();
    this.selectedFreezeFrameEnumType = FreezeFrameEnumType[this._freezeFrameModel.freezeFrameEnumType];
  }

  public get selectedFreezeFrameEnumType(): string {
    return this._selectedFreezeFrameEnumType;
  }

  public set selectedFreezeFrameEnumType(v: string) {
    this._selectedFreezeFrameEnumType = v;
    this.freezeFrameModel.freezeFrameEnumType = (<any>FreezeFrameEnumType)[this.selectedFreezeFrameEnumType];
  }

  get availableFreezeFrameEnumType(): string[] {
    return Object.keys(FreezeFrameEnumType).filter(d => !this.isNumeric(d));
  }
  
  public isNumeric(value) {
    // Use the unary plus operator (+) to convert the value to a number
    // and check if it's a finite number
    return !isNaN(value) && !isNaN(parseFloat(value));
  } 
  get hasValueEnumerationEnabled(): boolean {
    return this.freezeFrameModel.freezeFrameEnumType === FreezeFrameEnumType.Value;
  }

  get hasIntervalEnumerationEnabled(): boolean {
    return this.freezeFrameModel.freezeFrameEnumType === FreezeFrameEnumType.Interval;
  }

  public removeValueEnum(indexOfItem: number) {
    if (indexOfItem < 0) { return; }
    this.freezeFrameEnumValues.splice(indexOfItem, 1);

    this.notifyEnumerationChanged();
  }

  public removeIntervalEnum(indexOfItem: number) {
    if (indexOfItem < 0) { return; }
    this.freezeFrameEnumIntervals.splice(indexOfItem, 1);

    this.notifyEnumerationChanged();
  }

  public addEnumerationItem(): void {
    if (this.hasValueEnumerationEnabled) {
      this.addDefaultEnumerationValueItem();
    }

    if (this.hasIntervalEnumerationEnabled) {
      this.addDefaultIntervalEnumerationItem();
    }

    this.notifyEnumerationChanged();
  }

  private addDefaultIntervalEnumerationItem() {
    this.freezeFrameEnumIntervals.push({
      ecuValueToWrite: 0,
      logicalValue: 0,
      lowerlimit: 0,
      upperLimit: 0,
      name: 'IntervalName'
    });
  }

  private addDefaultEnumerationValueItem() {
    this.freezeFrameEnumValues.push({
      ecuValue: 0,
      logicalValue: 0,
      name: 'ValueName'
    });
  }

  public freezeFrameEnumTypeChanged() {
    if (this.hasValueEnumerationEnabled) {
      this.freezeFrameEnumIntervals = [];
      this.freezeFrameModel.freezeFrameEnumType = FreezeFrameEnumType.Value;
      this.addDefaultEnumerationValueItem();
    }
    else {
      this.freezeFrameEnumValues = [];
      this.freezeFrameModel.freezeFrameEnumType = FreezeFrameEnumType.Interval;
      this.addDefaultIntervalEnumerationItem();
    }

    this.notifyEnumerationChanged();
  }

  public notifyEnumerationChanged(): void {
    this.saveEnumerationChangesToModel();
    this.changed.next();
  }

  private saveEnumerationChangesToModel() {
    if (this.hasValueEnumerationEnabled) {
      this.freezeFrameModel.freezeFrameEnumData = this.freezeFrameEnumValues.map(valueEnumeration => {
        return `${valueEnumeration.ecuValue}${this.freezeFrameEnumPartDelimeter}` +
          `${valueEnumeration.logicalValue}${this.freezeFrameEnumPartDelimeter}${valueEnumeration.name}`;
      }).join(this.freezeFrameEnumDelimeter);

      return;
    }

    this.freezeFrameModel.freezeFrameEnumData = this.freezeFrameEnumIntervals.map(enumInterval => {
      return `${enumInterval.lowerlimit}${this.freezeFrameEnumPartDelimeter}` +
        `${enumInterval.upperLimit}${this.freezeFrameEnumPartDelimeter}` +
        `${enumInterval.ecuValueToWrite}${this.freezeFrameEnumPartDelimeter}` +
        `${enumInterval.logicalValue}${this.freezeFrameEnumPartDelimeter}${enumInterval.name}`;
    }).join(this.freezeFrameEnumDelimeter);
  }

  private parseFreezeFrameEnums() {
    if (this.hasValueEnumerationEnabled && this.freezeFrameModel.freezeFrameEnumData) {
      this.parseFreezeFrameEnumValues();
    }

    if (this.hasIntervalEnumerationEnabled && this.freezeFrameModel.freezeFrameEnumData) {
      this.parseFreezeFrameEnumIntervals();
    }
  }

  private parseFreezeFrameEnumIntervals() {
    this.freezeFrameEnumIntervals = this.freezeFrameModel.freezeFrameEnumData.split(this.freezeFrameEnumDelimeter).map(freezeFrameItem => {
      const freezeFrameEnumIntervalParts = freezeFrameItem.split(this.freezeFrameEnumPartDelimeter);
      const freezeFrameEnumIntervalItem = new FreezeFrameEnumInterval();
      freezeFrameEnumIntervalItem.lowerlimit = +freezeFrameEnumIntervalParts[0];
      freezeFrameEnumIntervalItem.upperLimit = +freezeFrameEnumIntervalParts[1];
      freezeFrameEnumIntervalItem.ecuValueToWrite = +freezeFrameEnumIntervalParts[2];
      freezeFrameEnumIntervalItem.logicalValue = +freezeFrameEnumIntervalParts[3];
      freezeFrameEnumIntervalItem.name = freezeFrameEnumIntervalParts[4];

      return freezeFrameEnumIntervalItem;
    });
  }

  private parseFreezeFrameEnumValues() {
    this.freezeFrameEnumValues = this.freezeFrameModel.freezeFrameEnumData.split(this.freezeFrameEnumDelimeter).map(freezeFrameItem => {
      const freezeFrameEnumValueParts = freezeFrameItem.split(this.freezeFrameEnumPartDelimeter);
      const freezeFrameEnumValueItem = new FreezeFrameEnumValue();
      freezeFrameEnumValueItem.ecuValue = +freezeFrameEnumValueParts[0];
      freezeFrameEnumValueItem.logicalValue = +freezeFrameEnumValueParts[1];
      freezeFrameEnumValueItem.name = freezeFrameEnumValueParts[2];

      return freezeFrameEnumValueItem;
    });
  }
  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
