import { CategoryItem } from 'app/app-model/category-item';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { forkJoin, Observable, Observer, of, Subscription } from 'rxjs';
import { DiagnosticServiceCategoryItem } from '../../model/service/diagnostic-service';

import { FunctionBase } from './function-base';
import { FunctionType } from './functions';

export abstract class FunctionItem extends CategoryItem {

  defaultPropertyName = 'Data';
  functions: FunctionBase[];
  lastSelectedFunction: FunctionBase;
  readFunctionModifiedSubscription: Subscription;
  controlFunctionModifiedSubscription: Subscription;
  resetFunctionModifiedSubscription: Subscription;
  writeFunctionModifiedSubscritpion: Subscription;
  // diagnosticServiceProvider: DiagnosticServiceProvider;
  isStoredInSource = false;
  hasModificationSubscriptions = false;

  protected _serviceExecutionSequence: ServiceExecutionSequence;

  private _isSyncWriteDone: boolean;
  public get isSyncWriteDone(): boolean {
    return this._isSyncWriteDone;
  }
  public set isSyncWriteDone(v: boolean) {
    this._isSyncWriteDone = v;
  }

  private _isSyncReadDone: boolean;
  public get isSyncReadDone(): boolean {
    return this._isSyncReadDone;
  }
  public set isSyncReadDone(v: boolean) {
    this._isSyncReadDone = v;
    this.updateSyncingFromMasterState();
  }

  private _isSyncControlDone: boolean;
  public get isSyncControlDone(): boolean {
    return this._isSyncControlDone;
  }
  public set isSyncControlDone(v: boolean) {
    this._isSyncControlDone = v;
    this.updateSyncingFromMasterState();
  }

  private _isSyncResetDone: boolean;
  public get isSyncResetDone(): boolean {
    return this._isSyncResetDone;
  }
  public set isSyncResetDone(v: boolean) {
    this._isSyncResetDone = v;
    this.updateSyncingFromMasterState();
  }

  private _isSyncRequestSeedDone: boolean;
  public get isSyncRequestSeedDone(): boolean {
    return this._isSyncRequestSeedDone;
  }
  public set isSyncRequestSeedDone(v: boolean) {
    this._isSyncRequestSeedDone = v;
    this.updateSyncingFromMasterState();
  }

  private _isSyncSendKeyDone: boolean;
  public get isSyncSendKeyDone(): boolean {
    return this._isSyncSendKeyDone;
  }
  public set isSyncSendKeyDone(v: boolean) {
    this._isSyncSendKeyDone = v;
    this.updateSyncingFromMasterState();
  }

  private _isSyncingFromMaster: boolean;
  public get isSyncingFromMaster(): boolean {
    return this._isSyncingFromMaster;
  }
  public set isSyncingFromMaster(v: boolean) {
    this._isSyncingFromMaster = v;
  }

  private _activeFunction: FunctionBase;
  public get activeFunction(): FunctionBase {
    return this._activeFunction;
  }
  public set activeFunction(v: FunctionBase) {
    this.lastSelectedFunction = this.activeFunction;
    this._activeFunction = v;
  }

  private _model: any;
  public get model(): any {
    return this._model;
  }
  public set model(v: any) {
    this._model = v;
    this.syncFromModel();
  }

  private _ecuIdentifierHexCodePadded = '';
  get ecuIdentifierHexCodePadded(): string {
    return this._ecuIdentifierHexCodePadded;
  }

  fetchEcuIdentifierInUse(specificationVersionId: number, serviceName?: string) {
    if (!serviceName || serviceName == null) {
      this._ecuIdentifierHexCodePadded = '';
    }
    else {
      this.diagnosticServiceProvider.getDiagnosticServiceByName(specificationVersionId, serviceName).subscribe(
        diagnosticService => {
          if (diagnosticService) {
            this._ecuIdentifierHexCodePadded = diagnosticService.allRequestParameters[1].hexValue;
          }
        })
    }
  }

  private _diagnosticService1: DiagnosticServiceCategoryItem;
  public get diagnosticService(): DiagnosticServiceCategoryItem {
    return this._diagnosticService1;
  }
  public set diagnosticService(v: DiagnosticServiceCategoryItem) {
    this._diagnosticService1 = v;
  }
  setModelNoSync(v: any) {
    this._model = v;
  }

  public syncModel() {
    this.syncFromModel();
  }

  public get serviceExecutionSequence(): ServiceExecutionSequence {
    return this._serviceExecutionSequence;
  }

  public set serviceExecutionSequence(v: ServiceExecutionSequence) {
    this._serviceExecutionSequence = v;
    this.subscribeDefaultServcieExecutionSequenceEvents();
  }

  unsubscribe(): void {
    if (this.readFunctionModifiedSubscription) {
      this.readFunctionModifiedSubscription.unsubscribe();
    }

    if (this.controlFunctionModifiedSubscription) {
      this.controlFunctionModifiedSubscription.unsubscribe();
    }

    if (this.resetFunctionModifiedSubscription) {
      this.resetFunctionModifiedSubscription.unsubscribe();
    }

    if (this.writeFunctionModifiedSubscritpion) {
      this.writeFunctionModifiedSubscritpion.unsubscribe();
    }
  }

  public isFunctionEnabled(functionType: FunctionType) {
    const func = this.functions.find((funct, index) => funct.type === functionType);
    return func && func.enabled;
  }

  public get hasAnyFunctionEnabled(): boolean {
    return this.functions && (this.functions.find(func => func.enabled) !== undefined);
  }

  public enableFunction(functionType: FunctionType, isEnabled: boolean, reset: boolean) {
    const functionToEnable = this.getFunction(functionType);
    functionToEnable.enabled = isEnabled;

    if (isEnabled) {
      this.createServiceExecutionSequenceIfNeeded(functionType, functionToEnable);
      this.createProperties(functionToEnable);
      this.activeFunction = functionToEnable;
    } else {
      this.resetFunction(functionToEnable);
      this.activeFunction = this.findFirstEnabledFunction();
    }
  }

  protected getFunction(functionType: FunctionType): FunctionBase {
    return this.functions.find(func => func.type === functionType);
  }

  protected selectDefaultFunction(): void {
    if (this.activeFunction) {
      return;
    }
    const firstEnabledFunction = this.functions.find(func => func.enabled);

    if (firstEnabledFunction) {
      this.activeFunction = firstEnabledFunction;
    }
  }

  protected findFirstEnabledFunction(): FunctionBase {
    return this.functions.find(func => func.enabled);
  }

  protected syncReadSequenceFromModel(specificationVersionId: number): Observable<any> {
    if (this.model.readSequence) {
      return new Observable((observer: Observer<any>) => {
        const readFunction = this.getFunction(FunctionType.Read);
        readFunction.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
        readFunction.serviceExecutionSequence.specificationVersionId = specificationVersionId;
        readFunction.serviceExecutionSequence.setModel(this.model.readSequence).subscribe(_ => {
          readFunction.serviceExecutionSequence.updateAvailableParameters();
          readFunction.enabled = true;
          readFunction.syncFromModel();
          observer.next(true);
          observer.complete();
        });
      });
    } else {
      return of(undefined);
    }
  }

  protected syncResetSequenceFromModel(specificationVersionId: number): Observable<any> {
    if (this.model.resetSequence) {
      return new Observable((observer: Observer<any>) => {
        const resetFunction = this.getFunction(FunctionType.Reset);
        resetFunction.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
        resetFunction.serviceExecutionSequence.specificationVersionId = specificationVersionId;
        resetFunction.serviceExecutionSequence.setModel(this.model.resetSequence).subscribe(_ => {
          resetFunction.enabled = true;
          resetFunction.syncFromModel();
          observer.next(true);
          observer.complete();
        });
      });
    } else {
      return of(undefined);
    }
  }

  protected syncWriteSequenceFromModel(specificationVersionId: number): Observable<any> {
    if (this.model.writeSequence) {
      return new Observable((observer: Observer<any>) => {
        const writeFunction = this.getFunction(FunctionType.Write);
        writeFunction.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
        writeFunction.serviceExecutionSequence.specificationVersionId = specificationVersionId;
        writeFunction.serviceExecutionSequence.setModel(this.model.writeSequence).subscribe(_ => {
          writeFunction.serviceExecutionSequence.updateAvailableParameters();
          writeFunction.enabled = true;
          writeFunction.syncFromModel();
          observer.next(true);
          observer.complete();
        });
      });
    } else {
      return of(undefined);
    }
  }

  protected syncControlSequenceFromModel(specificationVersionId: number): Observable<any> {
    if (this.model.controlSequence) {
      return new Observable((observer: Observer<any>) => {
        const controlFunction = this.getFunction(FunctionType.Control);
        controlFunction.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
        controlFunction.serviceExecutionSequence.specificationVersionId = specificationVersionId;
        controlFunction.serviceExecutionSequence.setModel(this.model.controlSequence).subscribe(_ => {
          controlFunction.serviceExecutionSequence.updateAvailableParameters();
          controlFunction.enabled = true;
          controlFunction.syncFromModel();
          observer.next(true);
          observer.complete();
        });
      });
    } else {
      return of(undefined);
    }
  }

  protected syncSendKeyFromModel(specificationVersionId: number): Observable<any> {
    if (this.model.sendKeySequence) {
      return new Observable((observer: Observer<any>) => {
        const sendKeyFunction = this.getFunction(FunctionType.SendKey);
        sendKeyFunction.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
        sendKeyFunction.serviceExecutionSequence.specificationVersionId = specificationVersionId;
        sendKeyFunction.serviceExecutionSequence.setModel(this.model.sendKeySequence).subscribe(_ => {
          sendKeyFunction.serviceExecutionSequence.updateAvailableParameters();
          sendKeyFunction.enabled = true;
          sendKeyFunction.syncFromModel();
          observer.next(true);
          observer.complete();
        });
      });
    } else {
      return of(undefined);
    }
  }

  protected syncRequestSeedFromModel(specificationVersionId: number): Observable<any> {
    if (this.model.requestSeedSequence) {
      return new Observable((observer: Observer<any>) => {
        const requestSeedFunction = this.getFunction(FunctionType.RequestSeed);
        requestSeedFunction.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
        requestSeedFunction.serviceExecutionSequence.specificationVersionId = specificationVersionId;
        requestSeedFunction.serviceExecutionSequence.setModel(this.model.requestSeedSequence).subscribe(_ => {
          requestSeedFunction.serviceExecutionSequence.updateAvailableParameters();
          requestSeedFunction.enabled = true;
          requestSeedFunction.syncFromModel();
          observer.next(true);
          observer.complete();
        });
      });
    } else {
      return of(undefined);
    }
  }

  protected syncFreezeFrameSequenceFromModel(specificationVersionId: number): Observable<any> {
    if (this.model.freezeFrameSequence) {
      return new Observable((observer: Observer<any>) => {
        const controlFunction = this.getFunction(FunctionType.Read);
        controlFunction.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
        controlFunction.serviceExecutionSequence.specificationVersionId = specificationVersionId;
        controlFunction.serviceExecutionSequence.setModel(this.model.freezeFrameSequence).subscribe(_ => {
          controlFunction.serviceExecutionSequence.updateAvailableParameters();
          controlFunction.enabled = true;
          controlFunction.syncFromModel();
          observer.next(true);
          observer.complete();
        });
      });
    } else {
      return of(undefined);
    }
  }

  protected syncSequences(specificationVersionId: number): Observable<any> {
    const obsv =
      [this.syncControlSequenceFromModel(specificationVersionId),
      this.syncReadSequenceFromModel(specificationVersionId),
      this.syncWriteSequenceFromModel(specificationVersionId),
      this.syncResetSequenceFromModel(specificationVersionId),
      this.syncRequestSeedFromModel(specificationVersionId),
      this.syncSendKeyFromModel(specificationVersionId),
      this.syncFreezeFrameSequenceFromModel(specificationVersionId)];
    return forkJoin(obsv);
  }

  protected subscribeServiceExecutionModifiedEvents() {
    if (!this.hasModificationSubscriptions) {
      this._serviceExecutionSequence.modified.subscribe(args => {
        if (!this.isSyncingFromMaster) {
          this.notifyItemChanged();
        }
      });
      this.hasModificationSubscriptions = true;
    }
  }

  protected removeUnderlayingServiceExecutionSequence(funcType: FunctionType) {
    switch (funcType) {
      case FunctionType.Read:
        this.model.readSequence = null;
        break;
      case FunctionType.Write:
        this.model.writeSequence = null;
        break;
      case FunctionType.Control:
        this.model.controlSequence = null;
        break;
      case FunctionType.Reset:
        this.model.resetSequence = null;
        break;
      case FunctionType.RequestSeed:
        this.model.requestSeedSequence = null;
        break;
      case FunctionType.SendKey:
        this.model.sendKeySequence = null;
        break;
      default:
        break;
    }
  }

  protected resetFunction(func: FunctionBase) {
    func.resetProperties();
    func.resetServiceExecutionSequence();
    this.removeUnderlayingServiceExecutionSequence(func.type);
  }

  protected functionHasPropertyWithName(func: FunctionBase, propertyName: string) {
    return func.properties.find(f => f.name === propertyName);
  }

  abstract createProperties(f: FunctionBase);

  abstract createFunctions();

  abstract notifyItemChanged();

  abstract getPropertyNameForFunctionType(functionType: FunctionType): string;

  protected abstract syncFromModel();

  protected abstract subscribeDefaultServcieExecutionSequenceEvents();

  protected abstract createServiceExecutionSequenceIfNeeded(functionType: FunctionType, func: FunctionBase);

  protected abstract updateSyncingFromMasterState();
}
