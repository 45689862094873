import { Component, OnInit, ViewChild } from '@angular/core';
import { CategoryComponentBaseComponent } from 'app/modules/shared/model/category-component-base';
import { VsocCategoryItem } from './vsoc-category-item';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { NavigationService } from 'app/app-services/navigation.service';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { VsocMonitoringItem } from 'app/app-model/vsoc/vsoc-monitoring-item';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { CategoryName } from 'app/app-model/enums';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';
import { ServiceSelectorComponent } from 'app/modules/shared/components/service-selector/service-selector.component';

@Component({
  selector: 'app-vsoc-monitoring-component',
  templateUrl: './vsoc-monitoring-component.html',
  styleUrls: ['./vsoc-monitoring-component.css']
})
export class VsocMonitoringComponent extends CategoryComponentBaseComponent<VsocCategoryItem> implements OnInit {

  vsocMonitoringItem: VsocCategoryItem;

  public get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  constructor(activatedRoute: ActivatedRoute,
    private specificationStatusService: SpecificationStatusService,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    messageService: MessageService,
    navigationService: NavigationService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.VsocMonitoring, navigationService);
  }

  ngOnInit(): void {
    this.componentSubscriptions.push(this.itemChanged.subscribe(item => {
      this.vsocMonitoringItem = item;
    }));
  }

  vsocUpdate() {
    this.vsocMonitoringItem._vsocService.updateItem(this.specificationService.currentSpecificationVersion.id,
      this.vsocMonitoringItem.model).subscribe(item => {
        this.vsocMonitoringItem.model = item;
      });
  }

  toggleVsoc() {
    if (!this.vsocMonitoringItem.model.isSupported)
      this.vsocMonitoringItem.model.serviceName = "";
    this.vsocUpdate();
  }

  getActiveFunctionName() {
    return 'READ';
  }

  onDiagnosticServicesAdded(services: Array<DiagnosticServiceCategoryItem>) {
    if (services.length > 0) {
      this.vsocMonitoringItem.model.serviceName = services[0].name;
      this.vsocUpdate();
    }
  }

  removeService() {
    console.log(this.vsocMonitoringItem.model);
    this.vsocMonitoringItem.model.serviceName = "";
    this.vsocUpdate();
  }

  nagivateToDiagnosticService(diagnosticServiceName: string): void {
    this.navigationService.navigateToDiagnosticService(diagnosticServiceName);
  }

  canEditCategoryItems() {
    return this.specificationService.currentSpecificationVersion.releaseStatus === 0;
  }
}