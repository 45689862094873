import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValueConversionService } from 'app/app-services/value-conversion.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';

import { LabelType } from '../../../../app-model/label/label-type';
import { LabelService } from '../../../../app-services/label-service';
import { MessageService } from '../../../shared/services/message-service.service';
import { ExportCriteriaItem } from '../../model/export/export-criteria-item';
import { ExportCriteria } from '../../model/export/export-critieria';
import { ExportService } from '../../services/export.service';
import { ViewService } from '../../services/view.service';

@Component({
  selector: 'app-global-export',
  templateUrl: './global-export.component.html',
  styleUrls: ['./global-export.component.css']
})
export class GlobalExportComponent implements OnInit {
  loading: boolean;
  exporting: boolean;
  items: ExportCriteriaItem[];
  selectedItem: ExportCriteriaItem = null;

  constructor(private labelService:
    LabelService,
    private viewService: ViewService,
    private exportService: ExportService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private valueConversionService: ValueConversionService,
    private authService:AuthService) { }

  ngOnInit() {
    this.getItems(() => {
      this.subscribeToRouter();
    });
  }

  getItems(done: () => void = null) {
    this.loading = true;
    this.labelService.getLabels(LabelType.Branch).subscribe(branchLabels => {
      this.items = branchLabels.map(x => ExportCriteriaItem.createForLabel(x));

      this.labelService.getLabels(LabelType.Build).subscribe(buildLabels => {
        this.items = this.items.concat(buildLabels.map(x => ExportCriteriaItem.createForLabel(x)));

        this.viewService.getViews().subscribe(views => {
          this.items = this.items.concat(views.map(x => ExportCriteriaItem.createForView(x)));

          if (this.selectedItem) {
            const itemId = this.selectedItem.id;
            const isView = this.selectedItem.isView;
            this.setSelectedItem((x: ExportCriteriaItem) => x.id === itemId && x.isView === isView && x.isLabel === !isView);
          }

          if (done) {
            done();
          }
        }, error => {
          this.loading = false;
          this.messageService.dispatchErrorMessageFromApi(error);
        });
      }, error => {
        this.loading = false;
        this.messageService.dispatchErrorMessageFromApi(error);
      });
    }, error => {
      this.loading = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  subscribeToRouter() {
    this.activatedRoute.paramMap.subscribe(map => {
      const id = map.get('id');
      if (id) {
        const isView = id.startsWith('view');
        const itemId = +id.split('_')[1];

        this.setSelectedItem((x: ExportCriteriaItem) => x.id === itemId && x.isView === isView && x.isLabel === !isView);
      } else {
        this.selectedItem = null;
      }
    });
  }

  setSelectedItem(predicate: (arg0: ExportCriteriaItem) => boolean) {
    this.selectedItem = this.items.find(predicate);
  }
  onLabelsUpdate(e: string) {
    this.getItems();
  }

  get viewItems() {
    if (!this.items) {
      return [];
    }

    return this.items.filter(x => x.isView);
  }

  get labelItems() {
    if (!this.items) {
      return [];
    }

    return this.items.filter(x => x.isLabel);
  }

  get canExport() {
    return this.selectedItem;
  }

  performExport() {
    let exportObservable = null;
    let exportName = '';
    if (this.selectedItem.isView) {
      exportObservable = this.exportService.createGlobalExport(ExportCriteria.createForView(this.selectedItem.id));
      exportName = 'export_view_' + this.selectedItem.name;
    } else if (this.selectedItem.isLabel) {
      exportObservable = this.exportService.createGlobalExport(ExportCriteria.createForLabel(this.selectedItem.id));
      exportName = 'export_label_' + this.selectedItem.name;
    }

    this.subscribeAndHandleExport(exportObservable, exportName);
  }

  subscribeAndHandleExport(exportObservable: Observable<string>, exportName: string) {
    if (!exportObservable) {
      return;
    }

    this.exporting = true;

    exportObservable.subscribe(data => {
      FileSaver.saveAs(new Blob([this.valueConversionService.convertToByteArray(data)]), exportName + '.zip');
      this.getItems();
      this.exporting = false;
    }, error => {
      this.exporting = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  getUniqueIdForItem(item: ExportCriteriaItem) {
    return (item.isLabel ? 'label' : 'view') + '_' + item.id;
  }

  selectItem(item: ExportCriteriaItem) {
    this.router.navigate(['/release/export', this.getUniqueIdForItem(item)]);
  }

  canCreateExportLabel() {
    return this.authService.canCreateExportLabel();
  }

  canExportByLabel(): boolean {
    return this.authService.canExportByLabel();
  }
}
