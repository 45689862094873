import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SpecificationVersion } from 'app/app-model/specification-version';
import {
  SpecificationVersionSelectorComponent,
} from 'app/modules/shared/components/specification-version-selector/specification-version-selector.component';

@Component({
  selector: 'app-embedded-add-specific-version',
  templateUrl: './embedded-add-specific-version.component.html',
  styleUrls: ['./embedded-add-specific-version.component.css']
})
export class EmbeddedAddSpecificVersionComponent {

  @ViewChild('specificationVersionSelector', { static: true })
  specificationVersionSelector: SpecificationVersionSelectorComponent;

  @Input()
  isAllowed: boolean;

  @Output()
  specificationVersionsAdded: EventEmitter<Array<SpecificationVersion>> = new EventEmitter();

  addSpecVersionsExpanded = false;
  selectedSpecificationVersions = new Array<SpecificationVersion>();

  get hasSelectedSpecificationVersions(): boolean {
    return this.selectedSpecificationVersions && this.selectedSpecificationVersions.length > 0;
  }

  enableSpecVersionAssigner() {
    this.addSpecVersionsExpanded = !this.addSpecVersionsExpanded;
  }

  close() {
    this.addSpecVersionsExpanded = false;
  }

  okAdd() {
    // this.addSpecVersionsExpanded = false;
    this.specificationVersionsAdded.next(this.selectedSpecificationVersions);
    this.selectedSpecificationVersions = [];
    if (this.specificationVersionSelector) {
      this.specificationVersionSelector.reset();
    }
  }
}
