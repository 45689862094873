import { TaskStatusHub } from 'app/app-services/hubs/task-status-hub.service';
import { Subject, Subscription } from 'rxjs';

export class TaskStatusModel {
  taskStatusHub: TaskStatusHub;
  subscriptions: Subscription[] = new Array<Subscription>();
  hasSubscriptions: boolean;
  setTask: Subject<string> = new Subject<string>();
  taskComplete: Subject<string> = new Subject<string>();
  taskStatus: string;
  addDefaultSubscriptions() {
    if (!this.hasSubscriptions) {
      this.subscriptions.push(this.taskStatusHub.setTaskEvent.subscribe(
        {
          next: ({ taskStatus }) => {
            this.onSetTask(taskStatus);
          }
        }));
      this.subscriptions.push(this.taskStatusHub.taskCompleteEvent.subscribe(
        {
          next: ({ taskStatus }) => {
            this.onTaskComplete(taskStatus);
          }
        }));
    }
    this.hasSubscriptions = true;
  }
  clearSubscriptions() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.hasSubscriptions = false;
  }
  onSetTask(taskStatus: string) {
    this.setTask.next(taskStatus);
  }
  onTaskComplete(taskStatus: string) {
    this.taskComplete.next(taskStatus);
  }
}
