import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  OperationalDataAxisType,
  OperationalDataDefinitionSection,
  OperationalDataVariableType,
} from 'app/app-model/operational-data/operational-data-enums';
import { OperationalDataAxisModel } from 'app/app-model/operational-data/operational-data.model';
import { OperationalDataCategoryItem } from 'app/data-categories/operational-data/operational-data-category-item';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-operational-data-definition',
  templateUrl: './operational-data-definition.component.html',
  styleUrls: ['./operational-data-definition.component.css']
})
export class OperationalDataDefinitionComponent {

  @Input()
  operationalDataItem: OperationalDataCategoryItem;

  @Output()
  definitionChanged: EventEmitter<{ wasGraphTypeChanged: boolean; requestSave: boolean }> = new EventEmitter();

  @Input()
  isReadOnly: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  OperationalDataVariableType = OperationalDataVariableType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OperationalDataDefinitionSection = OperationalDataDefinitionSection;
  
  constructor( private authService: AuthService) { }

  getOperationalDataVariableTypeNames(): string[] {
    const keys = Object.keys(OperationalDataVariableType);
    return keys.slice(keys.length / 2);
  }

  onDefinitionChanged(wasGraphTypeChanged: boolean) {
    this.setupAxis();
    this.ensureDefinition();
    this.definitionChanged.emit({ wasGraphTypeChanged, requestSave: true });
  }

  /** Sets up the axis of the underlaying operational data model according to the current operational data definition */
  setupAxis() {
    /**
     * MinMaxValueCollection operational data variable type should have a x-axis with type set to 'Exact' but not be
     * able to be changed by the user
     */
    if (this.operationalDataItem.model.type === OperationalDataVariableType.MinMaxValueCollection) {
      this.createXaxisDefinitionifNeeded();
      this.operationalDataItem.model.yAxis = null;
      // This type of operational data should have a fixed number of properties.
      this.operationalDataItem.model.cols = 2;

      return;
    }

    if (this.operationalDataItem.shouldBeEnabledForCurrentODType(OperationalDataDefinitionSection.XAxis)) {
      this.createXaxisDefinitionifNeeded();
    } else {
      this.operationalDataItem.model.xAxis = null;
    }

    if (this.operationalDataItem.shouldBeEnabledForCurrentODType(OperationalDataDefinitionSection.YAxis)) {
      this.createYaxisDefinitionIfNeeded();
    } else {
      this.operationalDataItem.model.yAxis = null;
    }
  }

  private createXaxisDefinitionifNeeded() {
    if (!this.operationalDataItem.model.xAxis) {
      this.operationalDataItem.model.xAxis = new OperationalDataAxisModel();
      this.operationalDataItem.model.xAxis.type = this.getDefaultAxisType(this.operationalDataItem.model.type);
      this.operationalDataItem.model.xAxis.breakpoints = [];
    }
  }

  private getDefaultAxisType(odType: OperationalDataVariableType): OperationalDataAxisType {
    switch (odType) {
      case OperationalDataVariableType.MinMaxValueCollection:
      case OperationalDataVariableType.AccumulatedSlidingBarChart:
        return OperationalDataAxisType.Exact;

      default:
        return OperationalDataAxisType.Open;
    }
  }

  private createYaxisDefinitionIfNeeded() {
    if (!this.operationalDataItem.model.yAxis) {
      this.operationalDataItem.model.yAxis = new OperationalDataAxisModel();
      this.operationalDataItem.model.yAxis.type = OperationalDataAxisType.Open;
      this.operationalDataItem.model.yAxis.breakpoints = [];
    }
  }

  /** Ensures that the operational data definition has correct values */
  private ensureDefinition() {
    if (this.operationalDataItem.hasSingleAxisOperationalDataVariableType()) {
      if (!this.operationalDataItem.model.cols || this.operationalDataItem.model.cols < 1) {
        this.operationalDataItem.model.cols = 1;
      }
    }

    if (this.operationalDataItem.hasMatrixOperationalDataVariableType()) {
      if (!this.operationalDataItem.model.cols || this.operationalDataItem.model.cols < 1) {
        this.operationalDataItem.model.cols = 1;
      }
      if (!this.operationalDataItem.model.rows || this.operationalDataItem.model.rows < 1) {
        this.operationalDataItem.model.rows = 1;
      }
    }
  }
  
  canEditCategoryItems() {
    return this.authService.canEditCategoryItems();
  }
}
