<div class="modal fade" data-backdrop="static" id="addKeysetItemModal">
  <div class="modal-dialog">
    <div class="modal-content dialog">
      <div class="modal-header">
        <h4 class="modal-title">Add Security access mode</h4>
        <button type="button" class="close" [disabled]="loading" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body" [class.loading]="applying">
        <form #form="ngForm" name="form" role="form">
          <div class="form-group">
            <label for="nameInput">Name:</label>
            <input #nameInput="ngModel" name="nameInput" [required]="true" type="text" id="nameInput" [(ngModel)]="name" class="form-control">
          </div>
          <div class="form-group">
            <label for="algoritmCombobox">Encipher:</label>
            <select class="form-control" name="algoritmCombobox" id="algoritmCombobox" [(ngModel)]="selectedEncipher">
              <option *ngFor="let e of enciphers" [ngValue]="e">{{e.name}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="keyInput">Key:</label>
            <input #keyInput="ngModel" name="keyInput" [required]="true" type="text" id="keyInput" [(ngModel)]="key" class="form-control">
          </div>
          <div *ngIf="!form.valid" class="alert alert-danger">
            <div *ngIf="nameInput.errors && nameInput.errors.required">
              Name is required.
            </div>
            <div *ngIf="keyInput.errors && keyInput.errors.required">
              Key is required.
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" [disabled]="applying" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="add(name);" [disabled]="applying || form.invalid">Ok</button>
        <button appCloseModal [close]="modalClosing" type="button" class="btn btn-secondary hide-element" data-dismiss="modal"></button>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</div>