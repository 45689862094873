import { Location } from '@angular/common';
import { CategoryItem } from "app/app-model/category-item";
import { SpecificationService } from "app/app-services/specification-service";
import { Router, ActivatedRoute } from "@angular/router";
import { ServiceExecutionSequence } from "app/modules/shared/model/service-execution/service-execution-sequence";
import { CategoryName } from "app/app-model/enums";
import { FreezeframesService } from "app/app-services/freezeframes.service";
import { BaseProperty, PropertyType } from "app/modules/shared/model/properties/base-property";
import { FreezeFramesModel } from "app/app-model/freeze-frames/freeze-frames.model";
import { tap } from "rxjs/operators";
import { PropertyParameterDirective } from 'app/modules/shared/model/service/parameters/property.parameter';
import { ServiceExecutionResponseValue } from 'app/app-model/diagnostic-service/sequence-execution-response-parameter-data.model';
import { OutputProperty } from 'app/modules/shared/model/properties/output-property';
import { FunctionItem } from 'app/modules/shared/components/function/function.item';
import { FunctionType } from 'app/modules/shared/components/function/functions';
import { FunctionRead } from 'app/modules/shared/components/function/function-read';
import { FunctionBase } from 'app/modules/shared/components/function/function-base';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';


export class FreezeFramesCategoryItem extends FunctionItem {
  createProperties(func: FunctionBase) {
    func.properties = [];
    func.addProperty(this.getPropertyNameForFunctionType(func.type));
  }
  createFunctions() {
    if (!this.functions || this.functions.length === 0) {

      const readFunction = new FunctionRead(false, []);
      readFunction.addProperty(this.getPropertyNameForFunctionType(FunctionType.Read));
      readFunction.serviceExecutionSequence.modified.subscribe(val => {
        this.notifyItemChanged();
      });

      this.functions = [readFunction];
    }

  }

  public notifyItemChanged() {
    if (!this.isSyncingFromMaster) {
      console.log('FreezeFrame ' + this.name + ' modified...Saving changes');
      this.freezeFrameService.updateItem(this.specificationService.currentSpecificationVersion.id, this.model, this.oldName)
        .subscribe(updatedFreezeFrame => {
          this.model = updatedFreezeFrame;

          const newUrl = this.router.createUrlTree([], {
            relativeTo: this.activatedRoute,
            queryParams: { itemId: this.model.name }
          }).toString();
          this.locationService.go(newUrl);

          console.log('FreezeFrame' + this.name + ' successfully updated');
        }, err => {
        });
    }
  }

  getPropertyNameForFunctionType(functionType: FunctionType): string {
    return 'FreezeFrame Value';
  }

  protected subscribeDefaultServcieExecutionSequenceEvents() {
    if (!this.hasSyncDoneSubscriptions) {
      this._serviceExecutionSequence.syncDone.subscribe(args => {
        this.subscribeServiceExecutionModifiedEvents();
        this.isSyncingFromMaster = false;
      });
      this.hasSyncDoneSubscriptions = true;
    }
  }
  protected createServiceExecutionSequenceIfNeeded(functionType: FunctionType, func: FunctionBase) {
    if (!this.model.freezeFrameSequence ) {
      this.model.freezeFrameSequence = new ServiceExecutionSequenceModel();
    func.serviceExecutionSequence.model = this.model.freezeFrameSequence;
    this.isSyncReadDone = true;
    }

  }
  protected updateSyncingFromMasterState() {
    this.isSyncingFromMaster = false;
  }

  freezeFrameService: FreezeframesService;
  propertyType: PropertyType = PropertyType.Source;
  properties: Array<BaseProperty> = new Array<BaseProperty>();
  eneratingProperties = false;
  oldName = '';

  constructor(private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
    this._serviceExecutionSequence = new ServiceExecutionSequence();
    super.categoryType = CategoryName.FreezeFrames;
    super.specificationVersionId = this.specificationVersionId;
  }

  public setName(name: string) {
    this.oldName = this.model.name;
    this.name = name;
    this.model.name = name;
    this.notifyFreezeFrameChanged();
  }

  public getParameterAssignedToProperty(propName: string): PropertyParameterDirective {
    if (!propName) {
      return undefined;
    }
    const matchingProp = this.properties.find(prop => prop.name === propName);
    console.log("getParameterAssignedToProperty")
    if (matchingProp) {
      return matchingProp.selectedPropertyParameter;
    } else {
      return undefined;
    }
  }

  public CreatePropertyFromResponseValue(responseValue: ServiceExecutionResponseValue) {
    const serviceExecutions = this.serviceExecutionSequence.serviceExecutions;
    const serviceExecutiontoGenerateFrom = serviceExecutions.length === 1 ?
      serviceExecutions[0] : serviceExecutions[serviceExecutions.length - 1];
    const responsePropertyParams = serviceExecutiontoGenerateFrom.availableResponsePropertyParameters;

    const outputProperty = new OutputProperty();
    outputProperty.notifyIfPropertyChanges = false;
    outputProperty.name = responseValue.target.variable;

    if (!this.isPreview) {
      outputProperty.syncPropertyDone.subscribe({
        next: (updatedProperty) => {
          outputProperty.notifyIfPropertyChanges = true;
        }
      });
      outputProperty.setServiceExecutionSequenceModelSilent(this.serviceExecutionSequence);
      if (responsePropertyParams) {
        const propertyParameter = responsePropertyParams.find(x => x.parameter.name === outputProperty.name);
        if (propertyParameter) {
          outputProperty.setSelectedPropertyParameterSilent(propertyParameter);
        }
      }
    }

    this.properties.push(outputProperty);
    console.log(this.properties,"this.properties")
  }

  private generatePropertiesFromResponseValues() {
    console.log("generatePropertiesFromResponseValues")
    this.properties = [];

    if (this.serviceExecutionSequence.serviceExecutions.length <= 0) {
      return;
    }

    const serviceExecutions = this.serviceExecutionSequence.serviceExecutions;
    const serviceExecutiontoGenerateFrom = serviceExecutions.length === 1 ?
      serviceExecutions[0] : serviceExecutions[serviceExecutions.length - 1];
    const responseValuesFromModel = serviceExecutiontoGenerateFrom.model.responseParameters;

    responseValuesFromModel.forEach(responseVal => {
      if (!responseVal.target.variable) {
        responseVal.target.variable = responseVal.path[0].step;
      }

      this.CreatePropertyFromResponseValue(responseVal);
    });
  }
  notifyFreezeFrameChanged() {
    console.log('FreezeFrame ' + this.name + ' modified...Saving changes');
    this.freezeFrameService.updateItem(this.specificationService.currentSpecificationVersion.id, this.model, this.oldName)
      .pipe(tap(updatedFreezeFrame => {
        this.model = updatedFreezeFrame;

        const newUrl = this.router.createUrlTree([], {
          relativeTo: this.activatedRoute,
          queryParams: { itemId: this.model.name }
        }).toString();
        this.locationService.go(newUrl);

        console.log('FreezeFrame' + this.name + ' successfully updated');
      })).subscribe();
  }

  public setIsSyncingFromMasterState() {
    this.isSyncingFromMaster = true;
    this.isSyncReadDone = false;
  }


  protected syncFromModel() {
    this.setIsSyncingFromMasterState();
    this.name = this.model.name;
    this.oldName = this.name;
    this.createFunctions();
    if (!this.isPreview && this.model.freezeFrameSequence) {

      this.syncSequences(this.specificationService.currentSpecificationVersion.id).subscribe(_ => {
        this.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
      this.subscribeServiceExecutionModifiedEvents();
      this.serviceExecutionSequence.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
      this.serviceExecutionSequence.setModel(this.model.freezeFrameSequence).subscribe(_ => {
      this.updateSyncingFromMasterState();
      //this.generatePropertiesFromResponseValues();
      });
      super.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
      });
    }
  }
}
