<app-category-header [text]="'IVD Overrides'"></app-category-header>

<div class="sdds-row">
    <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">        
        <app-category-items *ngIf="category" [specification]="specification"
            [specificationVersion]="specificationVersion" [category]="category"
            [toolbarEnabled]="false"></app-category-items>
    </div>
    <div
        class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        
        <div *ngIf="ivdOverrideItem">
            <h2>{{ivdOverrideItem.name}}</h2>
            <div class="sdds-banner">
                <div class="sdds-banner-body">
                    This feature allows you to override which diagnostic service that will be executed when a client e.g. JPS is executing {{ivdOverrideItem.name}}.
                </div>
            </div>

            <app-assigned-services-viewer [serviceExecutionSequence]="ivdOverrideItem.serviceExecutionSequence"
                [propertyType]="ivdOverrideItem.propertyType" [isReadOnly]="!isEditingAllowed"
                [hideAddButtonWithOneService]="true">
            </app-assigned-services-viewer>

        </div>
    </div>
</div>