import { Component, EventEmitter, Input } from '@angular/core';
import { ScommFileImporterService } from 'app/app-services/scomm-file-importer.service';
import { SharedFilesEventsService } from 'app/app-services/shared-files-events.service';
import { SharedFilesService } from 'app/app-services/shared-files.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { MessageService } from 'app/modules/shared/services/message-service.service';

import { ScommSharedFile, SharedFile } from '../../app-model/legacy-file';
import { ScommSharedFileVersion } from '../../app-model/scomm-shared-file-version';
import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-create-shared-file-version',
  templateUrl: './create-shared-file-version.component.html',
  styleUrls: ['./create-shared-file-version.component.css']
})
export class CreateSharedFileVersionComponent extends DialogBase {
  @Input()
  parentFileSpecification: SharedFile;

  loading: boolean;
  fileVersionToCreate: ScommSharedFileVersion;
  modalClosing: EventEmitter<void> = new EventEmitter<void>();
  importError = false;

  constructor(private specificationService: SpecificationService,
    private messageService: MessageService,
    private scommFileImporterService: ScommFileImporterService,
    private sharedFilesService: SharedFilesService,
    private sharedFilesEventService: SharedFilesEventsService) {
    super();
  }

  fileChanged(e: Event) {
    this.loading = true;
    const target: HTMLInputElement = e.target as HTMLInputElement;
    if (target.files.length === 1) {
      console.log('importing file ' + target.files[0].name);
      if (this.parentFileSpecification.name !== target.files[0].name) {
        this.messageService.dispatchErrorMessage('The file name: ' + target.files[0].name + ' does not match parent file name: '
          + this.parentFileSpecification.name + '\nFile names must match.');
        this.importError = true;
        return;
      }

      this.fileVersionToCreate = new ScommSharedFileVersion();
      this.fileVersionToCreate.labels = [];
      this.fileVersionToCreate.sharedFileId = this.parentFileSpecification.id;

      if (this.parentFileSpecification.fileType != null) {
        const sharedFile = new ScommSharedFile();
        sharedFile.name = target.files[0].name;
        sharedFile.fileType = this.parentFileSpecification.fileType;

        this.scommFileImporterService.getBase64(target.files[0]).subscribe(
          data => {
            sharedFile.data = data;
            this.fileVersionToCreate.file = sharedFile;
            this.loading = false;
          }
        );
      } else {
        this.messageService.dispatchErrorMessage('The file ' + this.fileVersionToCreate.file.name +
          ' is not supported by Dcode. Only files with Ecu/Server/Security Access files are supported');
      }
    }
  }

  setDefaultValues() {
    this.fileVersionToCreate = null;
  }

  cancel() {
    this.close();
  }

  apply() {
    this.createVersion();
    this.close();
  }

  close() {
    this.modalClosing.emit();
    this.setDefaultValues();
  }

  createVersion() {
    if (this.fileVersionToCreate) {
      this.sharedFilesService.createSharedFileVersion(this.fileVersionToCreate).subscribe(fileVersion => {
        this.sharedFilesEventService.versionCreated.next(fileVersion);
      });
    }
  }
}
