<app-category-header [text]="'Fingerprint'"></app-category-header>

<div class="sdds-row">
    <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <!-- [ngClass]="{'loading': !category}"> -->
        <app-category-items *ngIf="category" [specification]="specification"
            [specificationVersion]="specificationVersion" [showHexcode]="true" [category]="category"
            [isAllowed]="canEditCategoryItems()"></app-category-items>
    </div>
    <div
        class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <div *ngIf="fingerprintItem">  <!--[ngClass]="{'loading': isLoading}"> -->
            <app-editable-textbox [overrideFont]="true" [text]="fingerprintItem.name"
                (textEditedEventEmitter)="editingNameDone($event)" enclosingElement="h1"
                [editingNotAllowed]="!isEditingAllowed" [editingNotAllowedReason]="editingNotAllowedReason"
                enclosingElement="h2" [isAllowed]="canEditCategoryItems()">
            </app-editable-textbox>

            <app-function-definition [source]="fingerprintItem" [canSetRead]="true"
                [readFlag]="fingerprintItem.isFunctionEnabled(FunctionType.Read)"
                (readFlagChanged)="enableFunction(FunctionType.Read, $event)" [canSetWrite]="true"
                [writeFlag]="fingerprintItem.isFunctionEnabled(FunctionType.Write)"
                (writeFlagChanged)="enableFunction(FunctionType.Write, $event)" [canSetReset]="false"
                [canSetControl]="false" [isReadOnly]="!isEditingAllowed"></app-function-definition>
            <app-function-dashboard [source]="fingerprintItem" *ngIf="fingerprintItem.hasAnyFunctionEnabled"
                [isReadOnly]="!isEditingAllowed">
            </app-function-dashboard>

            <app-login-prerequisites
                *ngIf="fingerprintItem.activeFunction && fingerprintItem.activeFunction.type === FunctionType.Write"
                [isEditingAllowed]="isEditingAllowed" [allowedLogin]="allowedLogins" [loginToPerform]="loginToPerform"
                (allowedLoginChanged)="allowedLoginUpdate($event)"
                (loginToPerformChanged)="loginToPerformUpdate($event)">
            </app-login-prerequisites>

            <div class="sdds-banner my-10" *ngIf="!fingerprintItem.activeFunction">
                <div class="sdds-banner-body">
                    No fingerprint functions enabled. To enable a function, select the corresponding checkbox.
                </div>
            </div>

        </div>
    </div>
</div>