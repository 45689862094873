import { Injectable } from '@angular/core';
import {
  CardReaderData,
  MessageType,
  ProcessMessage,
  SigningMessage,
  WebSocketStatus,
  WsMessage,
} from 'app/app-model/e-id/e-id.model';
import { SocketConnectionState } from 'app/app-model/enums';
import { BehaviorSubject, Subject } from 'rxjs';

import { HashGenService } from './hash-gen.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class EIdService {
  public cardReaderConnectionChanged = new BehaviorSubject<boolean>(false);
  public cardStateChanged = new BehaviorSubject<WebSocketStatus>(WebSocketStatus.Ejected);

  private eIdWebSocketUrl = 'ws://localhost:8080';

  public socketConnectionStateChanged = new BehaviorSubject<{ state: SocketConnectionState, message: string }>(
    { state: SocketConnectionState.Connecting, message: 'Connecting to eID signing service.' }
  );

  public availableCardReadersChanged = new Subject<void>();
  public signStatusChanged = new Subject<{ success: boolean, message: SigningMessage }>();
  private websocket: WebSocket;
  private socketState: WebSocketStatus;
  private cardReaders = [];

  constructor(private hashService: HashGenService, private userService: UserService) { }

  public sign(userEmail: string, message: ProcessMessage, contentToSignAsBase64: string): void {
    this.userService.getCurrentUser().subscribe(user => {
      if (!user.email || (userEmail.toLowerCase() !== user.email.toLocaleLowerCase())) {
        this.signStatusChanged.next({
          success: false,
          message: {
            Message: 'Logged in user email is not matching with the card. Please try again.'
          }
        });
        return;
      }

      setTimeout(() => {
        const byteData = this.base64ToArrayBuffer(contentToSignAsBase64);
        const byteHash = this.hashService.byteToHash(byteData);
        message.data = byteHash;

        const dataString = JSON.stringify(message);
        if (this.socketState === WebSocketStatus.Inserted && this.websocket.readyState === WebSocket.OPEN) {
          this.websocket.send(dataString);
        } else {
          this.websocket = new WebSocket(this.eIdWebSocketUrl);
          this.websocket.send(dataString);
        }
      }, 1000);
    });
  }

  public cardInfo(): CardReaderData[] {
    const readerValues: CardReaderData[] = [];
    this.cardReaders.forEach(reader => {
      const value = reader.State === 'Ejected' ? '' : reader.Name;
      readerValues.push({
        name: value, value: reader.Name + ': ' + reader.State + ' - ' + reader.CertificateInfo,
        email: reader.Email == null || reader.Email.length === 0 ? '' : reader.Email
      });
    });
    return readerValues;
  }

  public initWebSocket() {
    this.websocket = new WebSocket(this.eIdWebSocketUrl);

    this.socketConnectionStateChanged.next({
      state: SocketConnectionState.Connecting,
      message: 'Connecting to eID signing service.'
    });

    this.websocket.onopen = (evt) => {
      this.socketConnectionStateChanged.next({
        state: SocketConnectionState.Connected,
        message: 'Connected to eID signing service.'
      });
    };
    this.websocket.onclose = (evt) => {
      this.socketConnectionStateChanged.next({
        state: SocketConnectionState.Disconnected,
        message: 'Disconnected from eID signing service.'
      });
    };
    this.websocket.onmessage = (evt) => {
      const data: WsMessage = JSON.parse(evt.data);
      if (data.MessageType === MessageType.CardReaderInformation) {
        this.process(data.CardReaders);
        this.availableCardReadersChanged.next();
      }
      if (data.MessageType === MessageType.SigningResponse) {
        this.updateSignature(data);
      }
      if (data.MessageType === MessageType.VerificationResponse) {
        // to user when getting verification
      }
    };
    this.websocket.onerror = (evt) => {
      // this.cardReaderConnectionError.next('Unable to connect to the reader, check if the service is running');
      this.socketConnectionStateChanged.next({
        state: SocketConnectionState.ConnectionFailed,
        message: 'Unable to connect to the reader, check if the service is running'
      });
    };
  }

  private updateSignature(message: SigningMessage): void {
    this.signStatusChanged.next({
      success: message.Success && message.Signature !== null && message?.Signature?.length > 0,
      message: message
    });
  }

  private process(data): void {
    this.cardReaders = data;
    data.forEach(reader => {
      if (reader.State === 'Ejected') {
        this.socketState = WebSocketStatus.Ejected;
        this.cardStateChanged.next(this.socketState);
      }
      if (reader.State === 'Inserted') {
        this.socketState = WebSocketStatus.Inserted;
        this.cardStateChanged.next(this.socketState);
      }
    });
  }

  private base64ToArrayBuffer(base64: string): number[] {
    const binString = window.atob(base64);
    const len = binString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binString.charCodeAt(i);
    }
    return Array.from(bytes);
  }
}
