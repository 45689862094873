<div class="sdds-row">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
        <h1>Values</h1>
    </div>
</div>

<div class="sdds-row">
    <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <!-- [ngClass]="{'loading': !category}"> -->
        <app-category-items *ngIf="category" [specification]="specification"
            [specificationVersion]="specificationVersion" [showHexcode]="true" [category]="category"
            [isAllowed]="canEditCategoryItems()"> </app-category-items>
    </div>
    <div
        class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
         <div *ngIf="valueItem"> <!--[ngClass]="{'loading': isLoading}"> -->
            <app-editable-textbox [overrideFont]="true" [text]="valueItem.name"
                (textEditedEventEmitter)="editingNameDone($event)" [editingNotAllowed]="!isEditingAllowed"
                [editingNotAllowedReason]="editingNotAllowedReason" enclosingElement="h2"
                [isAllowed]="canEditCategoryItems()">
            </app-editable-textbox>
            <div class="my-10 w-2/4 sdds-on-white-bg">
                <sdds-textfield ngDefaultControl size="md" type="text" placeholder="Description"
                    [(ngModel)]="valueItem.model.description" (customChange)="updateDescription()"
                    [disabled]="!isEditingAllowed || !canEditCategoryItems()">
                    <span slot=" sdds-label">Description</span>
                </sdds-textfield>
            </div>
            <app-function-definition *ngIf="!valueItem.isSyncingFromMaster" [source]="valueItem" [canSetRead]="true"
                [readFlag]="valueItem.isFunctionEnabled(FunctionType.Read)"
                (readFlagChanged)="enableFunction(FunctionType.Read, $event)" [canSetWrite]="true"
                [writeFlag]="valueItem.isFunctionEnabled(FunctionType.Write)"
                (writeFlagChanged)="enableFunction(FunctionType.Write, $event, true)" [canSetReset]="false"
                [canSetControl]="false" [isReadOnly]="!isEditingAllowed"></app-function-definition>

            <app-function-dashboard [source]="valueItem" *ngIf="valueItem.functions.length > 0"
                [isReadOnly]="!isEditingAllowed" [hideAddButtonWithOneService]="true">
            </app-function-dashboard>

            <div class="sdds-banner" *ngIf="valueItem.functions.length === 0">
                <div class="sdds-banner-body">
                    No value functions enabled. Activate functions by clicking on one of the checkboxes above.
                </div>
            </div>
        </div>
    </div>
</div>