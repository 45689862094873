<app-category-header [text]="'VSOC Monitoring'"></app-category-header>

<div class="sdds-row">
    <div
        class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <app-category-items *ngIf="category" [specification]="specification"
            [specificationVersion]="specificationVersion" [category]="category"
            [toolbarEnabled]="false"></app-category-items>
    </div>

    <div
        class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">

        <div *ngIf="vsocMonitoringItem">
            <h2>{{vsocMonitoringItem.name}}</h2>

            <span class="sdds-toggle" [ngClass]="{'disabled': !isEditingAllowed}" tabindex="0" style="width: 40%;">
                <input id="vsocToggle" type="checkbox" class="sdds-toggle-input"
                    [(ngModel)]="this.vsocMonitoringItem.model.isSupported" (change)="toggleVsoc($event)">
                <span class="sdds-toggle-switch"></span>
                <label class="sdds-toggle-label" for="vsocToggle">Toggle support for this VSOC Monitor Item</label>
            </span>

            <div
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">

                <div class="my-20">
                    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm mb-16" [id]="vsocMonitoringItem.model.name"
                        [disabled]="!canEditCategoryItems()"
                        matTooltip="Assign a diagnostic service to this diagnostic concept"
                        [ngClass]="{'hidden': (!this.vsocMonitoringItem.model.isSupported || this.vsocMonitoringItem.model.serviceName)}">
                        <mat-icon fontSet="material-icons-outlined" class="md-14">add</mat-icon>Connect Diagnostic
                        Service
                    </button>

                </div>
            </div>

            <div
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
                <app-service-selector (diagnosticServicesAdded)="onDiagnosticServicesAdded($event)"
                    [modalName]="vsocMonitoringItem.model.name" [activeFunctionName]="getActiveFunctionName()">
                </app-service-selector>
            </div>

            <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding"
                *ngIf="!this.vsocMonitoringItem.model.isSupported">
                <div class="sdds-banner sdds-banner-info">
                    <div class="sdds-banner-body">
                        This VSOC Monitor is not supported. Enable support for this VSOC Monitor By clicking
                        <i>Toggle</i>
                        option above.
                    </div>
                </div>
            </div>
            <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding"
                *ngIf="this.vsocMonitoringItem.model.serviceName">
                <h3>Connected Diagnostic Services</h3>
                <div class="sdds-card mb-12">
                    <div class="sdds-card-header headerPadding">
                        <h4 class="sdds-card-headline">
                            <div class="flex justify-items-center">
                                <div class="shrink w-4/5 break-all mt-8">
                                    {{this.vsocMonitoringItem.model.serviceName}}
                                </div>

                                <div class="flex-none w-auto">
                                    <button class="sdds-btn sdds-btn-ghost sdds-btn-sm"
                                        (click)="nagivateToDiagnosticService(this.vsocMonitoringItem.model.serviceName)"
                                        matTooltip="Navigate to diagnostic service definition">
                                        <mat-icon fontSet="material-icons-outlined"
                                            class="md-18">arrow_forward</mat-icon>
                                    </button>

                                    <button class="sdds-btn sdds-btn-ghost sdds-btn-sm" *ngIf="canEditCategoryItems()"
                                        (click)="removeService()" matTooltip="Disconnect diagnostic service">
                                        <mat-icon fontSet="material-icons-outlined"
                                            class="md-18">delete_forever</mat-icon>
                                    </button>

                                </div>
                            </div>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>