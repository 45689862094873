import { Injectable } from '@angular/core';
import { LegacyServerFile, ScommFile, ScommFileDraftResult } from 'app/app-model/legacy-file';
import { Observable, of, zip, combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { SpecificationLegacyFiles } from './specification-legacy-files.service';
import { SpecificationService } from './specification-service';

@Injectable()
export class DiffFileService {
  loading = false;
  diffableFiles: Array<LegacyServerFile> = new Array<LegacyServerFile>();

  constructor(private specificationService: SpecificationService,
    private specificationLegacyFilesService: SpecificationLegacyFiles) { }

  getAllDiffableFilesPreview(sourceServerFileId: number): Observable<void> {
    const draftsObservable = this.specificationLegacyFilesService.getScommFileDrafts(sourceServerFileId, true)
      .pipe(map(drafts => this.convertDraftsAndSaveToDiffableFiles(drafts, sourceServerFileId)));

    const specificationVersionIds = this.specificationService.currentSpecification.specificationVersions.map(version => version.id);
    const serverFilesObservable = this.specificationLegacyFilesService.getAllServerFilesForSpecificationVersions(specificationVersionIds, true)
      .pipe(map(files => this.convertScommFilesAndSaveToDiffableFiles(files)));

    return combineLatest([draftsObservable, serverFilesObservable]).pipe(map(x => x[0]));
  }

  getCompleteFile(previewFile: LegacyServerFile): Observable<LegacyServerFile> {
    const cachedDiffFile = this.diffableFiles.find(diffableFile =>
      diffableFile.id === previewFile.id &&
      diffableFile.scommFileId === previewFile.scommFileId &&
      diffableFile.userName === previewFile.userName);

    // If the file already has its contents loaded
    if (cachedDiffFile && cachedDiffFile.data) {
      return of(cachedDiffFile);
    }

    // Load draft data
    if (cachedDiffFile.scommFileId) {
      return this.specificationLegacyFilesService.getUserDraft(cachedDiffFile.scommFileId, cachedDiffFile.userName)
        .pipe(map(completeDraft => {
          cachedDiffFile.data = completeDraft.draft.data;
          return cachedDiffFile;
        }));
    }

    // Load scomm file data
    return this.specificationLegacyFilesService.getSpecificationScommFile(cachedDiffFile.id)
      .pipe(map(completeScommFile => {
        cachedDiffFile.data = completeScommFile.data;
        return cachedDiffFile;
      }));
  }

  private convertDraftsAndSaveToDiffableFiles(drafts: ScommFileDraftResult[], serverFileDraftsSourceId: number) {
    if (drafts) {
      drafts.forEach(file => {
        const cachedDiffFile = this.diffableFiles.find(cachedFile =>
          cachedFile.scommFileId === serverFileDraftsSourceId &&
          cachedFile.userName === file.draft.user.userName);

        if (cachedDiffFile && file.draft.modified !== cachedDiffFile.modified) {
          /**
           * If the modified date is not the same then the data of cached
           * file must be cleared in order to get the latest version of it
           */
          cachedDiffFile.data = '';
        }

        if (!cachedDiffFile) {
          this.diffableFiles.push(
            {
              id: file.draft.id,
              scommFileId: serverFileDraftsSourceId,
              userName: file.draft.user.userName,
              name: `Draft of ${file.draft.user.name}`,
              data: file.draft.data,
              modified: file.draft.modified
            });
        }
      });
    }
  }

  private convertScommFilesAndSaveToDiffableFiles(scommFiles: ScommFile[]) {
    if (scommFiles) {
      scommFiles.forEach(file => {
        const cachedDiffFile = this.diffableFiles.find(cachedFile => cachedFile.id === file.id);

        if (cachedDiffFile && file.modificationDate !== cachedDiffFile.modified) {
          /**
           * If the modified date is not the same then the data of cached
           * file must be cleared in order to get the latest version of it
           */
          cachedDiffFile.data = '';
        }

        if (!cachedDiffFile) {
          const specificationVersionOfFile = this.specificationService.currentSpecification.specificationVersions
            .find(version => version.id === file.specificationVersionId);

          const specificfationVersionName = specificationVersionOfFile ? specificationVersionOfFile.name : '';

          this.diffableFiles.push(
            {
              id: file.id,
              scommFileId: undefined,
              userName: undefined,
              name: file.name + ` (Master file version ${specificfationVersionName})`,
              data: atob(file.data),
              modified: file.modificationDate
            });
        }
      });
    }
  }
}
