import { Directive, ElementRef, Input, EventEmitter } from '@angular/core';

@Directive({ selector: '[appCloseModal]' })
export class CloseModalDirective {

    private _el: ElementRef;

    constructor(el: ElementRef) {
        this._el = el;
    }

    @Input() set close(shouldCloseEvent: EventEmitter<void>) {
        shouldCloseEvent.subscribe(() => {
            this._el.nativeElement.click();
        });
    }
}
