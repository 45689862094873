import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-remove-parameter-confirm',
  templateUrl: './remove-parameter-confirm.component.html',
  styleUrls: ['./remove-parameter-confirm.component.css']
})
export class RemoveParameterConfirmComponent {

  @Output()
  accept: EventEmitter<any> = new EventEmitter();

  apply() {
    this.accept.emit();
  }

  cancel() {

  }
}
