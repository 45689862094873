<app-dialog-base modalId="createNewSpecificationModal" title="Create new specification"
  [itemTemplate]="createNewSpecificationTemplate" applyCaption="Apply" [applyEnabled]="canApply" (modalApply)="apply()"
  (modalCancel)="cancel()"></app-dialog-base>
<ng-template #createNewSpecificationTemplate>
  <form #form="ngForm" name="form" role="form">
    <div class="sdds-row">
      <div class="sdds-col-max-12 sdds-no-padding sdds-form-field">
        <div class="sdds-radio-button-group">
          <div class="sdds-radio-item">
            <input class="sdds-form-input" type="radio" name="createSpecificationOpt" id="createNewSpecification"
              [checked]="isOptionOne()" (click)="setSelectedOption($event.target)">
            <label class="sdds-form-label" for="createNewSpecification">
              Create ODX based specification </label>
          </div>
          <label class="font-bold text-gray-500 ml-20">(For ECUS with an ODX file, e.g. Secure Update ECUs)</label>
          <div class="sdds-radio-item">
            <input class="sdds-form-input" type="radio" name="createSpecificationOpt" id="cloneFromSpecification"
              (click)="setSelectedOption($event.target)">
            <label class="sdds-form-label" for="cloneFromSpecification">
              Create specification based on existing version
            </label>
          </div>
          <div class="sdds-radio-item">
            <input class="sdds-form-input" type="radio" name="createSpecificationOpt" id="cloneFromTemplate"
              (click)="setSelectedOption($event.target)">
            <label class="sdds-form-label" for="cloneFromTemplate">
              Create a specification based on a template</label>
          </div>
          <label class="font-bold text-gray-500 ml-20">(For ECUs without Candela/ODX files e.g. Security GateWay
            ECUs)</label>
        </div>
      </div>

      <div class="sdds-col-max-12 sdds-on-white-bg sdds-no-padding sdds-form-field">
        <sdds-textfield type="text" size="md" ngDefaultControl name="specificationName" [(ngModel)]="specificationName"
          label-inside="Specification name" placeholder="Enter specification name"
          [attr.state]="!specificationName ? 'error' : ''">
          <span *ngIf="!specificationName" slot="sdds-helper">* Required</span>
        </sdds-textfield>
      </div>

      <div *ngIf="isOptionOne()" class="sdds-col-max-12 sdds-on-white-bg sdds-no-padding sdds-form-field">
        <div class="sdds-checkbox-item my-5 py-5">
          <input class="sdds-form-input" type="checkbox" name="legacyModeFlag" id="legacyModeFlag"
            [(ngModel)]="legacyModeEnabled">
          <label class="sdds-form-label" for="legacyModeFlag">Legacy Mode</label>
        </div>
        <div>
          <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg my-5 py-5">
            <label class="sdds-dropdown-label-outside">Specification protocol</label>
            <select name="protocolList" id="specificationsProtocolInput" [(ngModel)]="selectedDiagnosticProtocol">
              <option *ngFor="let diagnosticProtocol of availableDiagnosticProtocols" [value]="diagnosticProtocol">
                {{diagnosticProtocol}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="legacyModeEnabled" class="mt-5">
          <label *ngIf="!hasUploadedLegacyServerFile">Upload a Legacy Server XML file</label><br>
          <label class="sdds-btn sdds-btn-primary sdds-btn-sm">
            Select Server File
            <input type="file" (change)="xmlFileSelected($event)">
          </label><br>
          <label>{{fileValidationMessage}}</label>
        </div>

        <div *ngIf="!legacyModeEnabled">
          <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg">
            <label>Server execution mode</label>
            <select name="modeList" id="modeInput" [(ngModel)]="selectedServerExecutionMode">
              <option *ngFor="let executionMode of availableServerExecutionModes" [value]="executionMode">
                {{executionMode}}</option>
            </select>
          </div>
        </div>
      </div>

      <div *ngIf="isOptionTwo() || isOptionThree()"
        class="sdds-col-max-12 sdds-on-white-bg sdds-no-padding sdds-form-field my-10">
        <div class="sdds-dropdown sdds-dropdown-small">
          <label *ngIf="isOptionTwo()">Select a specification </label>
          <label *ngIf="isOptionThree()">Select a template </label>
          <span *ngIf="!selectedSpecification" class="text-red-500 text-5xl">* Required</span>
          <div *ngIf="isOptionTwo()">
            <app-specification-version-selector
              [header]="'Select a specification version'"
              [multiSelect]="isMultiSelectEnabled"
              [selectedSpecificationVersions]="selectedSpecificationVersions"></app-specification-version-selector>
          </div>
          <div *ngIf="isOptionThree()">
            <select name="templateList" [(ngModel)]="selectedSpecification">
              <option *ngFor="let template of templates" [ngValue]="template">
                {{template.name}}
              </option>
            </select>
          </div>
        </div>

        <div *ngIf="isOptionThree()">
          <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg">
            <label>Server execution mode</label>
            <select name="modeList" id="modeInput" [(ngModel)]="selectedServerExecutionMode">
              <option *ngFor="let executionMode of availableServerExecutionModes" [value]="executionMode">
                {{executionMode}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="sdds-col-max-12 sdds-no-padding sdds-form-field">
        <div class="sdds-radio-button-group">
          <label>Inlcude in Scomm? </label>
          <span *ngIf="includeInScomm === undefined" class="sdds-helper text-5xl">* Required</span>
          <div class="sdds-radio-item">
            <input class="sdds-form-input" type="radio" id="includeScommYes" #includeInScommYesOpt
              name="includeInScommRadio" (click)="setIncludeInScommFlag(true)">
            <label class="sdds-form-label" for="includeScommYes">Yes</label><span class="font-bold text-gray-500"> (For
              specifications created by Scomm
              developers)</span>
          </div>
          <div class="sdds-radio-item">
            <input class="sdds-form-input" type="radio" id="includeScommNo" #includeInScommNoOpt
              name="includeInScommRadio" (click)="setIncludeInScommFlag(false)">
            <label class="sdds-form-label" for="includeScommNo">No</label><span class="font-bold text-gray-500">(For
              Secure Update and Security Gateway ECUs)</span>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>