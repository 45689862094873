import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonListElementSettings } from 'app/modules/shared/components/common-list/common-list-element-settings';
import { ToolbarComponent } from 'app/modules/shared/components/toolbar/toolbar.component';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { FamilyGenerationPair } from '../app-model/family-generation-pair';
import { IdentificationGroup } from '../app-model/identification-group/identification-group';
import { Label } from '../app-model/label/label';
import { LabelType } from '../app-model/label/label-type';
import { ServerIdentificationModel } from '../app-model/server-identification/server-identification.model';
import { IdentificationExpressionService } from '../app-services/identification-expression.service';
import { IdentificationGroupService } from '../app-services/identification-group.service';
import { LabelService } from '../app-services/label-service';
import { MessageService } from '../modules/shared/services/message-service.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-identification-groups',
  templateUrl: './identification-groups.component.html',
  styleUrls: ['./identification-groups.component.css'],
  providers: [IdentificationExpressionService]
})
export class IdentificationGroupsComponent implements OnInit, OnDestroy {


  @ViewChild('toolbarRef', { static: true }) toolbarRef: ToolbarComponent;

  nameOfNewGroup = 'No name';
  identificationGroups: IdentificationGroup[];
  loading = false;
  newExpression: string;
  newName: string;
  showAddNew = false;
  _selectedIdentificationGroup: IdentificationGroup;
  _modifiedSubscription: Subscription;
  _createItemSubscription: Subscription;
  selectedLabel: Label = null;
  labels: Label[];
  loadingLabels = false;
  canReorderItems = true;
  isAllowed:boolean;

  private _filterText: string;
  private lastDroppedGroupId: number;
  public get filterText(): string {
    return this._filterText;
  }
  public set filterText(text: string) {
    if (text) {
      this.canReorderItems = false;
    } else {
      this.canReorderItems = true;
    }

    this._filterText = text;
  }

  constructor(private identificationGroupService: IdentificationGroupService,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private identificationExpressionService: IdentificationExpressionService,
    private labelService: LabelService,
    private authService:AuthService) { }

  ngOnInit() {
    const done = () => {
      this.activatedRoute.paramMap.subscribe(map => {
        const id = +map.get('id');
        if (id) {
          this.identificationGroupService.getIdentificationGroup(id)
            .pipe(take(1))
            .subscribe(group => this.selectedIdentificationGroup = group);
        }
      });

      this.loadLabels();
    };

    this.getGroups(done);
    if (this._modifiedSubscription != null) {
      this._modifiedSubscription.unsubscribe();
    }

    this._modifiedSubscription = this.identificationExpressionService.modified.subscribe(x => this.updateExpression());
  }

  ngOnDestroy(): void {
    if (this._createItemSubscription) {
      this._createItemSubscription.unsubscribe();
    }

    if (this._modifiedSubscription) {
      this._modifiedSubscription.unsubscribe();
    }
  }

  updateExpression() {
    this.identificationGroupService.updateIdentificationGroup(this.selectedIdentificationGroup).subscribe(x => {
      this.selectedIdentificationGroup = x;
    },
      error => {
        this.messageService.dispatchErrorMessageFromApi(error);
      });
  }

  get canModifyExpression() {
    if (!this.selectedIdentificationGroup) {
      return false;
    }

    return !this.hasReleasedServerIdentifications(this.selectedIdentificationGroup);
  }

  get canRemoveItem() {
    if (!this.selectedIdentificationGroup) {
      return false;
    }

    return this.selectedIdentificationGroup.serverIdentifications.length === 0 && !this.selectedIdentificationGroup.isDefaultGroup;
  }

  get selectedIdentificationGroup(): IdentificationGroup {
    return this._selectedIdentificationGroup;
  }

  set selectedIdentificationGroup(value: IdentificationGroup) {
    this._selectedIdentificationGroup = value;
  }

  get settingsInitiatorForGroups() {
    return (serverIdentification: IdentificationGroup, settings: CommonListElementSettings) => {
      settings.allowDrop = true;
      settings.draggable = true;
    };
  }

  hasReleasedServerIdentifications(identificationGroup: IdentificationGroup): boolean {
    return identificationGroup.serverIdentifications.find(serverIdentification => serverIdentification.specificationVersion.releaseStatus === 1) !== undefined;
  }

  getGroups(done: () => void = null) {
    this.loading = true;
    this.identificationGroupService.getIdentificationGroups().pipe(take(1))
      .subscribe((data: IdentificationGroup[]) => {
        const defaultGroup = data.find(x => x.isDefaultGroup);
        const otherGroups = data.filter(x => !x.isDefaultGroup);

        this.identificationGroups = otherGroups.concat(defaultGroup);

        if (this.selectedIdentificationGroup) {
          this.selectedIdentificationGroup = this.identificationGroups.find(x => x.id === this.selectedIdentificationGroup.id);
        } else {
          this.selectedIdentificationGroup = null;
        }

        if (done) {
          done();
        }

        this.loading = false;
      }, error => {
        this.loading = false;
        this.messageService.dispatchErrorMessageFromApi(error);
      });
  }

  groupSelected(group: IdentificationGroup) {
    this.router.navigate(['../', group.id], { relativeTo: this.activatedRoute });
  }

  groupNameChanged(newName: string) {
    const oldName = this.selectedIdentificationGroup.name;
    this.selectedIdentificationGroup.name = newName;

    this.identificationGroupService.updateIdentificationGroup(this.selectedIdentificationGroup).subscribe(data => {

    }, error => {
      this.selectedIdentificationGroup.name = oldName;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  generateGroupNameFromFamilyAndGeneration() {
    if (this.selectedIdentificationGroup.familyGenerationPairs.length > 0) {
      let name = '';

      this.selectedIdentificationGroup.familyGenerationPairs.forEach((x, index) => {
        name += x.diagnosticFamily.name + ' ' + x.generation.name;
        if (index < this.selectedIdentificationGroup.familyGenerationPairs.length - 1) {
          name += ' ';
        }
      });

      return name;
    }

    return this.nameOfNewGroup;
  }

  generateItemName(identificationGroup: IdentificationGroup) {
    return identificationGroup.name;
  }

  add() {
    const newIdentificationGroup = new IdentificationGroup();
    newIdentificationGroup.name = this.nameOfNewGroup;
    this._createItemSubscription = this.identificationGroupService.createIdentificationGroup(newIdentificationGroup).subscribe(group => {
      this.identificationGroups = this.identificationGroupService.getCachedIdentificationGroups();
      this.router.navigate(['../', group.id], { relativeTo: this.activatedRoute });
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  remove() {
    this.identificationGroupService.deleteIdentificationGroup(this.selectedIdentificationGroup).subscribe(() => {
      this.selectedIdentificationGroup = null;
      this.identificationGroups = this.identificationGroupService.getCachedIdentificationGroups();
      this.router.navigate(['../', 0], { relativeTo: this.activatedRoute });
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  orderChanged(serverIdentificationModels: ServerIdentificationModel[]) {
    const oldOrder = this.selectedIdentificationGroup.serverIdentifications;
    this.selectedIdentificationGroup.serverIdentifications = serverIdentificationModels;

    // if (this.arraysEqual(oldOrder.map(x => x.id), serverIdentificationModels.map(x => x.id))) {
    //   return;
    // }

    this.identificationGroupService.updateServerIdentificationsForGroup(this.selectedIdentificationGroup).subscribe(data => {
      this.selectedIdentificationGroup = data;
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });

    this.filterText = this.filterText;
  }

  elementDropped(droppedSpecification: ServerIdentificationModel) {
    this.lastDroppedGroupId = droppedSpecification.id;
  }

  arraysEqual(arr1: any[], arr2: any[]) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = arr1.length; i >= 0; i--) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  familyGenerationsUpdated(familyGenerationPairs: FamilyGenerationPair[]) {
    const oldFamilyGenerationPairs = this.selectedIdentificationGroup.familyGenerationPairs;
    this.selectedIdentificationGroup.familyGenerationPairs = familyGenerationPairs;
    this.identificationGroupService.updateIdentificationGroup(this.selectedIdentificationGroup).subscribe(data => {
      this.selectedIdentificationGroup.familyGenerationPairs = data.familyGenerationPairs;
      if (this.selectedIdentificationGroup.name === this.nameOfNewGroup) {
        this.groupNameChanged(this.generateGroupNameFromFamilyAndGeneration());
      }
    }, error => {
      this.selectedIdentificationGroup.familyGenerationPairs = oldFamilyGenerationPairs;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  identificationGroupOrderChanged(reorderedItems: IdentificationGroup[]) {
    for (let i = 0; i < reorderedItems.length; i++) {
      reorderedItems[i].index = i;
    }
    this.identificationGroupService.updateIndices(reorderedItems).subscribe(updatedItems => {
      this.identificationGroups = updatedItems;
      this.selectLastDroppedGroup();
    });
  }

  public setIdentificationGroupDropped(droppedItem: IdentificationGroup) {
    this.lastDroppedGroupId = droppedItem.id;
  }

  private selectLastDroppedGroup() {
    this.toolbarRef.setFilterSilent(this.filterText);
    const groupToSelect = this.identificationGroups.find(item => item.id === this.lastDroppedGroupId);

    if (groupToSelect && this.selectedIdentificationGroup !== null && groupToSelect.id !== this.selectedIdentificationGroup.id) {
      /**
       * If the user selects other group other than the actual one, we need to navigate to the new item in order
       * to keep the app routing updated.
       */

      this.groupSelected(groupToSelect);
    } else {
      this.selectedIdentificationGroup = groupToSelect;
    }
    this.lastDroppedGroupId = -1;

  }

  private loadLabels() {
    this.loadingLabels = true;
    this.labels = [];
    this.labelService.getLabels(LabelType.Branch).subscribe(branchLabels => {
      this.labels = branchLabels;
    }, error => {
      this.loadingLabels = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }
  canEditIdentificationsGroup()
  {
    return this.authService.canEditIdentificationsGroup();
  }
}
