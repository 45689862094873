import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { ServiceCommand, ServiceExecutionModel } from 'app/app-model/diagnostic-service/service-execution.model';
import { CommandType } from 'app/app-model/enums';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { ServiceExecutionDirective } from 'app/modules/shared/model/service-execution/service-execution';
import { PropertyParameterDirective } from 'app/modules/shared/model/service/parameters/property.parameter';
import { BehaviorSubject, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

export class ServiceExecutionSequence {
  syncDone: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSyncDone = false;
  modified: Subject<void> = new Subject();
  serviceParamaterChangedSubscription: Subscription;
  serviceExecutions: Array<ServiceExecutionDirective> = [];
  syncedServiceExecutions = 0;
  maxAllowedServiceExecutions = -1;
  availablePropertyParameters: PropertyParameterDirective[] = [];
  specificationVersionId: number;

  private _serviceExecutionSequenceModel: ServiceExecutionSequenceModel = new ServiceExecutionSequenceModel();

  private _diagnosticServiceProvider: DiagnosticServiceProvider;
  public get diagnosticServiceProvider(): DiagnosticServiceProvider {
    return this._diagnosticServiceProvider;
  }
  public set diagnosticServiceProvider(v: DiagnosticServiceProvider) {
    this._diagnosticServiceProvider = v;
  }

  public get model(): ServiceExecutionSequenceModel {
    return this._serviceExecutionSequenceModel;
  }

  public set model(v: ServiceExecutionSequenceModel) {
    this._serviceExecutionSequenceModel = v;
    this.isSyncDone = true;
  }

  setModel(model: ServiceExecutionSequenceModel): Observable<any> {
    this._serviceExecutionSequenceModel = model;

    if (!this._serviceExecutionSequenceModel ||
      !this._serviceExecutionSequenceModel.executions ||
      this._serviceExecutionSequenceModel.executions.length === 0) {
      this.isSyncDone = true;
      return of(true);
    } else {
      this.serviceExecutions = [];

      const obsv = this._serviceExecutionSequenceModel.executions.map(executionModel => {
        const serviceExecution = new ServiceExecutionDirective();
        this.serviceExecutions.push(serviceExecution);
        serviceExecution.diagnosticServiceProvider = this.diagnosticServiceProvider;
        serviceExecution.specificationVersionId = this.specificationVersionId;
        return serviceExecution.setModel(executionModel);
      });

      return forkJoin(obsv).pipe(tap(_ => { this.isSyncDone = true; }));
    }
  }

  /**Fetches all parameters that can be selected/used by a property */
  updateAvailableParameters() {
    if (!this.serviceExecutions) {
      return;
    }

    this.availablePropertyParameters = [];

    this.serviceExecutions.forEach(serviceExecution => {
      this.availablePropertyParameters = this.availablePropertyParameters.concat(serviceExecution.availablePropertyParameters);
    });
  }

  addComandAsServiceExecution(commandArgs: string, commandType: CommandType, executionIndex: number) {
    const existingCommand = this.model.executions.find(exec => exec.command && exec.command.type === commandType);

    if (!commandArgs) {
      const indexToRemove = this.model.executions.indexOf(existingCommand);

      if (indexToRemove >= 0) {
        this.model.executions.splice(indexToRemove, 1);
        this.notifySequenceChanged();
      }
    } else {
      if (existingCommand) {
        existingCommand.command.arguments = commandArgs;
      } else {
        const serviceCommand = new ServiceCommand();
        serviceCommand.arguments = commandArgs;
        serviceCommand.type = commandType;

        const serviceExecution = new ServiceExecutionModel();
        serviceExecution.command = serviceCommand;
        serviceExecution.index = executionIndex;

        this.model.executions.push(serviceExecution);
      }
      this.notifySequenceChanged();
    }
  }

  findServiceExecutionWithCommandType(commandType: CommandType): ServiceExecutionModel {
    return this.model.executions.find(exec => exec.command && exec.command.type === commandType);
  }

  addServiceExecution(serviceExecution: ServiceExecutionDirective) {
    this.addServiceExecutionToModel(serviceExecution);
    this.serviceExecutions.push(serviceExecution);
    this.addAvailablePropertyParametersFor(serviceExecution);
  }

  addServiceExecutionToModel(serviceExecution: ServiceExecutionDirective) {
    if (this.hasServiceExecutionInModel(serviceExecution)) {
      return;
    }

    this.model.executions.push(serviceExecution.model);
  }

  removeServiceExecution(serviceExecution: ServiceExecutionDirective) {
    this.removeServiceExecutionFromModel(serviceExecution);
    this.removeAvailablePropertyParametersFor(serviceExecution);
    this.serviceExecutions.splice(this.serviceExecutions.indexOf(serviceExecution), 1);
  }

  removeServiceExecutionFromModel(serviceExecution: ServiceExecutionDirective) {
    if (!this.hasServiceExecutionInModel(serviceExecution)) {
      return;
    }

    this.model.executions.splice(this.model.executions.findIndex(exec => exec.service === serviceExecution.model.service), 1);
  }

  hasServiceExecutionWithServiceName(serviceName: string): boolean {
    return this.serviceExecutions.find(serviceExec =>
      this.hasDiagnosticServiceDefinition(serviceExec) && serviceExec.diagnosticService.name === serviceName) !== undefined;
  }

  hasDiagnosticServiceDefinition(serviceExecution: ServiceExecutionDirective) {
    return serviceExecution.diagnosticService && serviceExecution.diagnosticService.name;
  }

  hasServiceExecutions(): boolean {
    return this.model.executions && this.model.executions.length > 0;
  }

  hasServiceExecutionInModel(serviceExecution: ServiceExecutionDirective): boolean {
    return this.model.executions.find(exec => exec.service === serviceExecution.model.service) != null;
  }

  removeAvailablePropertyParametersFor(serviceExecution: ServiceExecutionDirective) {
    if (!this.availablePropertyParameters || this.availablePropertyParameters.length <= 0) {
      return;
    }

    serviceExecution.availableParameters.forEach(param => {
      const propertyParams = this.availablePropertyParameters.filter(param => param !== undefined);
      propertyParams.splice(propertyParams.findIndex(propertyParam =>
        propertyParam.parameter.model.id === param.model.id), 1);
    });
  }

  /** Extracts all parameters from the specified service execution as property parameters */
  addAvailablePropertyParametersFor(serviceExecution: ServiceExecutionDirective) {
    this.availablePropertyParameters = this.availablePropertyParameters.concat(serviceExecution.availablePropertyParameters);
  }

  getPropertyParameter(parameterId: number): PropertyParameterDirective {
    return this.availablePropertyParameters
      .find(propertyParam => propertyParam && propertyParam.parameter && propertyParam.parameter.model.id === parameterId);
  }

  getPropertyParameterByName(paramName: string) {
    return this.availablePropertyParameters
      .find(propertyParam => propertyParam && propertyParam.parameter && propertyParam.parameter.model.name === paramName);
  }

  moveServiceExecutionUp(serviceExecution: ServiceExecutionDirective) {
    const originIndex = this.serviceExecutions
      .findIndex((currentItem) => currentItem.diagnosticService.id === serviceExecution.diagnosticService.id);
    if (originIndex <= 0 || this.serviceExecutions.length <= 1) {
      return;
    }

    const targetIndex = originIndex - 1;

    // The target item is temporarly removed and the service execution to move takes its place.
    const previousItem = this.serviceExecutions.splice(targetIndex, 1, this.serviceExecutions[originIndex]);
    this.serviceExecutions[originIndex] = previousItem[0];
  }

  moveServiceExecutionDown(serviceExecution: ServiceExecutionDirective) {
    const originIndex = this.serviceExecutions.findIndex((currentItem) =>
      currentItem.diagnosticService.id === serviceExecution.diagnosticService.id);
    if ((originIndex >= this.serviceExecutions.length - 1) || this.serviceExecutions.length <= 1) {
      return;
    }

    const targetIndex = originIndex + 1;

    // The target item is temporarly removed and the service execution to move takes its place.
    const previousItem = this.serviceExecutions.splice(targetIndex, 1, this.serviceExecutions[originIndex]);
    this.serviceExecutions[originIndex] = previousItem[0];
  }

  notifySequenceChanged() {
    this.modified.next();
  }
}
