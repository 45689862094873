<ng-container *ngIf="category">
    <div [class.loading]="category.loadingView">
        <app-toolbar filterEnabled="true" [removeDataTargetEnabled]="canRemove" [addDataTargetEnabled]="canAdd"
            (filterTextEventEmitter)="filter($event);" [dataTargetEnabledAdd]="true" [dataTargetEnabledRemove]="true"
            [addNotAllowed]="!isEditingAllowed" [addNotAllowedReason]="editingNotAllowedReason"
            [removeNotAllowed]="!isEditingAllowed" [removeNotAllowedReason]="editingNotAllowedReason"
            [addEventEmitterEnabled]="canAdd" (addEventEmitter)="createItem('')" [collapseEnabled]="true"
            [expandEnabled]="true" (collapseEventEmitter)="collapseTree();" (expandEventEmitter)="expandTree();"
            [addButtonShow]="canModifyService" [removeButtonShow]="canModifyService" [createItemId]="'createItemModal'"
            [removeItemId]="'removeItemConfirmModal'" [isAllowed]="isAllowed">
        </app-toolbar>

        <app-remove-item-confirm (removeConfirm)="removeSelectedItem()"
            [itemToRemoveName]="category.activeItem ? category.activeItem.name: ''"></app-remove-item-confirm>

        <app-create-item (applyChangesEventEmitter)="createItem($event)"></app-create-item>

        <div class="sdds-row mb-12">
            <div
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding sdds-dropdown sdds-dropdown-small sdds-on-white-bg">
                <span class="sdds-dropdown-label-outside">
                    <label for="viewSelect">View</label>
                </span>

                <select name="viewSelect" id="viewSelect" [(ngModel)]="selectedView"
                    (ngModelChange)="viewSelected($event);">
                    <option [ngValue]="DiagnosticServiceViewType.ProtocolServices">Protocol Services</option>
                    <option [ngValue]="DiagnosticServiceViewType.Identifiers">Identifiers</option>
                    <option [ngValue]="DiagnosticServiceViewType.List">List</option>
                </select>
            </div>
        </div>

        <div class="sdds-no-padding diagnostic-service-browser overflow-y-auto min-h-v-50 max-h-v-70">
            <app-tree [tree]="tree"></app-tree>
        </div>
    </div>
</ng-container>