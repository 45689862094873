import { StructureSpecification } from './structure-specification';

export class StructureEcuGeneration {
  name: string;
  specifications: StructureSpecification[];

  constructor() {
    this.specifications = [];
  }
}
