import { Component, Input, OnInit } from '@angular/core';
import { LegacyContentModel } from 'app/app-model/legacyContent.model';
import { LegacyContentService } from 'app/app-services/legacyContent.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-teststeps',
  templateUrl: './teststeps.component.html',
  styleUrls: ['./teststeps.component.css']
})
export class TeststepsComponent implements OnInit {

  isReadOnly = false;
  testSteps: LegacyContentModel;
  specificationEventSubscription: Subscription;
  text = 'This is a XML document that allows you define any additional Test Steps ' +
    'that are required to be part of the server file when it is exported to a Scomm App database.';

  constructor(private legacyContentService: LegacyContentService,
    private specificationService: SpecificationService) { }

  ngOnInit() {
    this.specificationEventSubscription = this.specificationService.selectedSpecificationEventEmitter.subscribe(spec => {
      if (!spec.specification) { return; }

      this.isReadOnly = this.specificationService.isCurrentSpecificationVersionReleased;
      this.legacyContentService.getUnreferencedTestSteps(this.specificationService.currentSpecificationVersion.id).subscribe(
        teststeps => {
          if (teststeps.xmlContent)
            this.testSteps = teststeps;
        }
      );
    });
  }

  updateUnreferencedTeststeps(newData: string) {
    this.testSteps.xmlContent = newData;
    this.legacyContentService.updateUnreferencedTestSteps(this.specificationService.currentSpecificationVersion.id, this.testSteps)
      .subscribe();
  }

  createUnreferencedTestStepsDocument(): void {
    this.legacyContentService.createUnreferencedTestStepsDocument(this.specificationService.currentSpecificationVersion.id)
      .subscribe(data => this.testSteps = data);
  }

  deleteUnreferencedTestStepsDocument(): void {
    this.legacyContentService.deleteItem(this.specificationService.currentSpecificationVersion.id)
      .subscribe(data => this.testSteps = undefined);
  }
}
