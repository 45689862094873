<!-- <div *ngIf="requestValueParameters.lenght !== 0"> -->

<table class="table">
    <thead>
        <th>Parameter</th>
        <th>Data Type</th>
        <th>Resolve Type</th>
        <th>Resolve Data</th>
    </thead>
    <tbody>
        <tr *ngFor="let requestValueParameter of requestValueParameters; let i=index">
            <td>{{requestValueParameter.name}}</td>
            <td>Integer</td>
            <td>
                <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg">
                    <select>
                      <option>Constant Value</option>
                      <option>Parameter Value</option>
                      <option>Function Value</option>
                    </select>
                </div>
            </td>
            <td>
                <input class="sdds-textfield-input-md" type="text" placeholder="Enter value ..." />
            </td>
        </tr>
    </tbody>
</table>

<!-- </div> -->