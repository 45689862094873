import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LengthKind, TerminationType } from 'app/app-model/diagnostic-service/length.model';
import { ParameterService } from 'app/app-services/parameter-service';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';

@Component({
  selector: 'app-position-length',
  templateUrl: './position-length.component.html',
  styleUrls: ['./position-length.component.css']
})
export class PositionLengthComponent {

  @Input()
  parameter: ParameterData;

  @Input()
  isOdxBasedSpecification: boolean;

  @Input()
  isAllowed: boolean;

  @Output()
  parameterChanged: EventEmitter<void> = new EventEmitter();


  constructor(private parameterService: ParameterService) { }

  get hasMinMaxLength(): boolean {
    return this.parameter.model.length.kind === LengthKind.MinMax;
  }

  set hasMinMaxLength(enabled: boolean) {
    if (enabled) {
      this.parameter.resetLength();
      this.parameter.model.length.bitLength = 0;
      this.parameter.model.length.kind = LengthKind.MinMax;
    } else {
      this.parameter.resetLength();
      this.parameter.model.length.bitLength = 1;
      this.parameter.model.length.kind = LengthKind.Standard;
    }
  }

  get hasMaxByteLength(): boolean {
    return this.parameter.model.length.hasMaxByteLength;
  }

  set hasMaxByteLength(hasMaxByteLength: boolean) {
    this.parameter.model.length.hasMaxByteLength = hasMaxByteLength;
  }

  public get lengthTermination(): string {
    return TerminationType[this.parameter.model.length.termination];
  }

  public set lengthTermination(termination: string) {
    this.parameter.model.length.termination = TerminationType[termination];
  }
}
