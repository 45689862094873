<app-dialog-base 
    modalId="importIoPackageModal" 
    title="Import Ios from package" 
    applyCaption="Exit"
    modalSize="md"
    [showCancelButton]="false"
    [itemTemplate]="importIoPackageTemplate" 
    (modalApply)="apply()"
    (modalCancel)="apply()"></app-dialog-base>
<ng-template #importIoPackageTemplate>
    <div class="sdds-banner">
        <div class="sdds-banner-body">
            <p>Select a <b>{{importPackageExtension}}</b> file to import IOs to this diagnostic specification version.</p>
        </div>
    </div>
    <label class="sdds-btn sdds-btn-secondary sdds-btn-sm mt-5">
        1. Select a file
        <input #importZipBtn class="hidden" id="fileAttach" type="file"
            [accept]="importPackageExtension" (change)="setSelectedFile($event)">
    </label>
    
    <p *ngIf="selectedFile" class="mt-10">You are about to upload <b>{{selectedFile.name}}</b></p>    
    <button *ngIf="selectedFile" class="sdds-btn sdds-btn-secondary sdds-btn-sm mb-4" (click)="startUpload()">
        2. Upload package
    </button>
    <br /><br />

    <div *ngIf="isUploading" class="loader-container">
        <div class="loader"></div>
    </div>

    <div *ngIf="!isUploading && packageImportReport">
        <div *ngIf="importFinishedSuccessfully">
            <div class="sdds-banner">
                <div class="sdds-banner-body">
                    <div class="flex flex-row">
                        <div><mat-icon style="color:green">done</mat-icon></div>
                        <div class="ml-5"><p>Import finnished successfully!</p></div>
                    </div>                    
                </div>
            </div>
        </div>

        <div *ngIf="importFinishedWithWarnings">
            <div class="sdds-banner">
                <div class="sdds-banner-body">
                    <div class="flex flex-row">
                        <div><mat-icon style="color:orange">warning</mat-icon></div>
                        <div class="ml-5"><p>Import finished with warnings. Check the table below for more details</p></div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="importFinishedWithErrors">
            <div class="sdds-banner sdds-banner-error">                
                <div class="sdds-banner-body">
                    <div class="flex flex-row">
                        <div><mat-icon style="color:red">error</mat-icon></div>
                        <div class="ml-5"><p>Import finished with errors</p></div>                        
                    </div>
                    <div class="sdds-banner-subheader">{{packageImportReport.message}}</div>
                </div>
            </div>
        </div>
        <br />
        <div style="overflow-y:auto; max-height: 600px; padding-left: 60px;">
            <table class="sdds-table sdds-table--no-min-width" style="max-width: 100%; overflow-y: scroll ; width: 100%;">
                <thead class="sdds-table__header">
                <tr class="sdds-table__row">
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Item Name</th>
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Status</th>
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Message</th>
                </tr>
                </thead>
                <tbody class="sdds-table__body">
                <tr class="sdds-table__row" *ngFor="let reportItem of mappedReportItems">
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{reportItem.name}}</td>
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">                        
                        <mat-icon [matTooltip]="mapStatusToIconName(reportItem.status)">{{mapStatusToIconName(reportItem.status)}}</mat-icon>
                    </td>
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2"><span [innerHTML]=reportItem.message></span></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>