import { DiagnosticProtocolType, ServerExecutionModeType } from '../../../../../app-model/enums';
import { StructureSpecification } from '../../../../../app-model/structure/structure-specification';
import { TreeService } from '../service/tree.service';
import { TreeNode } from './tree-node';

export class StructureSpecificationTreeNode extends TreeNode {

    specification: StructureSpecification;
    deleteAction: (p: StructureSpecification) => void;
    openSpecificationAction: (p: StructureSpecification) => void;

    constructor(specification: StructureSpecification, treeService: TreeService,
        deleteAction: (p: StructureSpecification) => void,
        openSpecification: (p: StructureSpecification) => void) {
        super(specification.name, treeService);
        this.specification = specification;
        this.deleteAction = deleteAction;
        this.openSpecificationAction = openSpecification;
    }

    get isStructureSpecificationTreeNode(): boolean {
        return true;
    }

    get icon(): string {
        return 'description';
    }

    get diagnosticProtocol(): string {
        return DiagnosticProtocolType[this.specification.diagnosticProtocol];
    }

    get serverExecutionMode(): string {
        return ServerExecutionModeType[this.specification.serverExecutionMode];
    }


    get ownerName(): string {
        return this.specification.owner ? this.specification.owner.firstName + ' ' + this.specification.owner.lastName : undefined;
    }

    get canDeleteSpecification(): boolean {
        return !this.specification.specificationVersions.some(version => version.releaseStatus === 1);
    }

    canDelete(): boolean {
        return !this.specification.specificationVersions.some(version => version.releaseStatus === 1);
    }

    containsText(text: string): boolean {
        return super.containsText(text) || this.areTextsEqual(this.diagnosticProtocol, text) || this.areTextsEqual(this.serverExecutionMode, text);
    }

    performDeleteAction(): void {
        this.deleteAction(this.specification);
    }

    selectTreeNode(notifySelection = true) {
        super.selectTreeNode(notifySelection);

        if (this.openSpecificationAction && notifySelection) {
            this.openSpecificationAction(this.specification);
        }
    }
}
