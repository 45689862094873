/* eslint-disable @typescript-eslint/naming-convention */
import { RangeModel } from 'app/app-model/diagnostic-service/range.model';

export class ValueConstraintItem {
    type: ValueConstraintType;
    range: RangeModel;
}

// eslint-disable-next-line no-shadow
export enum ValueConstraintType {
    Valid,
    NotValid,
    NotDefined,
    NotAvailable
}
