<ng-container *ngIf="tree">
    <ng-container *ngFor="let t of tree.rootNodes">
        <app-tree-node [treeNode]="t"></app-tree-node>
    </ng-container>
</ng-container>

<a #removeDialogtrigger id="removeSpecificationModal"></a>
<sdds-modal size="md" selector="#removeSpecificationModal">
    <h5 slot="sdds-modal-headline">Delete Specification</h5>
    <p slot="sdds-modal-body">
        Are you sure you want to delete the specification?
    </p>
    <button slot="sdds-modal-actions"
        (click)="structureSpecificationTreeNode.performDeleteAction(); $event.stopPropagation();"
        class="sdds-btn sdds-btn-danger sdds-btn-md mr-5" data-dismiss-modal>Delete</button>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md">Cancel</button>
</sdds-modal>