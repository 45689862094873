<sdds-modal size="sm" selector="#odxFileSelectorModal" prevent>
  <div slot="sdds-modal-body">
    <div *ngIf="isShowingSelectOdxFileView">
      <h5>Step 1: Select an ODX file</h5>

      <h5 class="sdds-text-warning mt-10" *ngIf="currentSpecificationVersionIsUpgradedType">
        Warning!: You are about to convert this specification to a NON-Legacy specification.
        This means you will not be able to edit the diagnostic services in DCODE as
        these services are imported from the ODX file directly.
      </h5>

      <div class="sdds-row">
        <div
          class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm sdds-col-xs-8">
          <h5 class="sdds-text-red-600 mt-20" *ngIf="!selectedOdxFile">No ODX file has been selected</h5>
          <h5 class="sdds-text-positive mt-20" *ngIf="selectedOdxFile">{{selectedOdxFile.fileName}}</h5>
        </div>
        <div
          class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4">
          <label class="sdds-btn sdds-btn-primary sdds-btn-md mt-8">
            Select File
            <input #odxFileSelector class="hidden" id="fileAttach" type="file" (change)="odxFileSelected($event)">
          </label>
        </div>
      </div>

      <label class="sdds-text-warning" *ngIf="(!isValidOdxFile || !hasValidOdxFileContents) && selectedOdxFile">Issues
        in ODX file</label>
      <div *ngIf="(!isValidOdxFile || !hasValidOdxFileContents) && selectedOdxFile"
        class="sdds-detail-03 mt-10 overflow-auto" style="height: 200px;" [innerHTML]="odxFileValidationMessage"></div>
    </div>

    <div *ngIf="isShowingSignOdxFileView">
      <h5>Step 2: Sign the ODX file</h5>

      <div *ngIf="canShowCardSelectorAndSign">
        <p>Select a card reader and enter the pin code to sign the ODX file</p>
        <br />
        <div class="sdds-dropdown">
          <span class="sdds-dropdown-label-outside">Available card readers</span>
          <select name="cardReaderDropdown" id="cardReaderSelect" [(ngModel)]="selectedCard">
            <option *ngFor="let cardReader of availableCardReaders" [ngValue]="cardReader">{{cardReader.value}}</option>
          </select>
        </div>
        <div class="mt-10 w-1/2 sdds-on-white-bg">
          <sdds-textfield ngDefaultControl [(ngModel)]="cardPin" type="password">
            <span slot="sdds-label">Pin Code</span>
          </sdds-textfield>
        </div>

        <button class="mt-10 sdds-btn sdds-btn-primary sdds-btn-sm" [ngClass]="{'loading': signStarted}"
          [disabled]="!signEnabled" (click)="signSelectedOdxFile()">Sign</button>

        <h5 class="sdds-text-information mt-10" *ngIf="signCompleted">
          <p [ngClass]="{'sdds-text-information': signResult.success, 'sdds-text-negative': !signResult.success}">
            {{signResult.message.Message}}</p>
        </h5>
      </div>

      <div class="ml-28" *ngIf="!cardIsInserted && connectedToEidService">
        <img src="../../../assets/smart-card.png" alt="Insert smart card" />
        <p>Please insert smart card to the reader(Wait a few seconds after inserting the card).</p>
      </div>

      <div [ngSwitch]="currentSocketState.state" class="mt-32">
        <div *ngSwitchCase="socketConnectionState.Connecting" class="sdds-text-information">
          {{currentSocketState.message}}
        </div>
        <div *ngSwitchCase="socketConnectionState.Connected" class="sdds-text-positive">
          {{currentSocketState.message}}
        </div>
        <div *ngSwitchDefault class="sdds-text-negative">
          {{currentSocketState.message}}
          <button class="ml-10 sdds-btn sdds-btn-primary sdds-btn-sm"
            (click)="eidService.initWebSocket()">Retry</button>
        </div>
      </div>
    </div>

    <div *ngIf="isShowingConfirmOdxFileView">
      <h5>Step 3: Ready to use</h5>

      <p>The file {{selectedOdxFile.fileName}} is signed and ready to be used. Click on 'Confirm' to proceed.</p>
    </div>


  </div>

  <button slot="sdds-modal-actions" class="sdds-btn sdds-btn-primary sdds-btn-md ml-5" [disabled]="!canGoToPrevious"
    (click)="previous()" #applyBtn>Previous</button>

  <button slot="sdds-modal-actions" class="sdds-btn sdds-btn-primary sdds-btn-md ml-5" [disabled]="!canGoToNext"
    (click)="next()">Next</button>

  <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-primary sdds-btn-md ml-5"
    [disabled]="!canConfirmOdxFileSelection" (click)="apply()">Confirm</button>

  <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md ml-12"
    (click)="cancel()">Cancel</button>

</sdds-modal>
