<div class="sdds-row node-details-wrapper"
    *ngIf="(treeNode.isStructureSpecificationTreeNode || (treeNode.isSharedFileTreeNode && treeNode.isSelected))
|| (treeNode.isSpecificationVersionTreeNode && treeNode.specificationVersionTreeNode.labels && treeNode.specificationVersionTreeNode.labels.length > 0)">

    <div *ngIf="treeNode.isStructureSpecificationTreeNode || (treeNode.isSharedFileTreeNode && treeNode.isSelected)"
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 version-node-details">
        <ng-container *ngIf="treeNode.isStructureSpecificationTreeNode">
            <p>
                <b>Protocol: </b> {{treeNode.specificationTreeNode.diagnosticProtocol}}
            </p>
            <p>
                <b>Mode: </b> {{treeNode.specificationTreeNode.serverExecutionMode}}
            </p>
            <p *ngIf="treeNode.specificationTreeNode.ownerName">
                <b>Owner: </b> {{treeNode.specificationTreeNode.ownerName}}
            </p>
            <p *ngIf="diagnosticNo">
                <b>Diagnostic Number: </b> {{diagnosticNo}}
            </p> 
          
        </ng-container>

        <ng-container *ngIf="treeNode.isSharedFileTreeNode && treeNode.isSelected">
            <p><b>Type: </b>{{treeNode.sharedFileTreeNode.fileType}}</p>
            <p *ngIf="treeNode.sharedFileTreeNode.showReferencedFiles"><b>Referenced by: </b></p>
            <span>
                <button class="sdds-btn sdds-btn-secondary sdds-btn-sm referenced-by-btn"
                    [disabled]="!treeNode.sharedFileTreeNode.hasReferencedSpecifications"
                    (click)="treeNode.sharedFileTreeNode.showReferencedSpecifications()">
                    <ng-container *ngIf="treeNode.sharedFileTreeNode.isLoading">Loading...</ng-container>
                    <ng-container
                        *ngIf="!treeNode.sharedFileTreeNode.isLoading && !treeNode.sharedFileTreeNode.hasReferencedSpecifications">
                        No references
                    </ng-container>
                    <ng-container
                        *ngIf="!treeNode.sharedFileTreeNode.isLoading && treeNode.sharedFileTreeNode.hasReferencedSpecifications">
                        {{treeNode.sharedFileTreeNode.referencedDcodeSpecificationIds.length}} Specification(s)
                    </ng-container>
                </button>
            </span>
        </ng-container>
    </div>

    <div *ngIf="treeNode.isSpecificationVersionTreeNode"
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 version-node-details">
        <ng-container
            *ngIf="treeNode.specificationVersionTreeNode.labels && treeNode.specificationVersionTreeNode.labels.length > 0">
            <small><b>Labels:</b></small>
            <small *ngFor="let label of treeNode.specificationVersionTreeNode.labels; let isLast=last">
                {{label.name}}{{isLast ? '' : ','}}
            </small>
        </ng-container>
    </div>
</div>