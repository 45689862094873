<div *ngIf="sharedFileSpecification">
  <h4>Versions</h4>
  <app-toolbar [filterEnabled]="false" [removeEventEmitterEnabled]="true"
    (removeEventEmitter)="removeSelectedView();" [addDataTargetEnabled]="true" [f]
    createItemId="createSharedFileVersionModal"
    [dataTargetEnabledRemove]="false" addDataTarget="#createSharedFileVersionModal" [isAllowed]="isAllowed">
  </app-toolbar>
  <div *ngIf="versionTree && versionTree.isEmpty" class="alert alert-info">
    <span>The are no specification versions. Add a new specification version to proceed.</span>
  </div>
  <div style="margin-top: 5px;"></div>
  <ng-container *ngIf="versionTree && !versionTree.isEmpty">
    <app-tree [tree]="versionTree"></app-tree>
  </ng-container>

  <app-create-shared-file-version [parentFileSpecification]="sharedFileSpecification"></app-create-shared-file-version>
</div>
