import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Transition } from '../app-model/state-chart/state-chart.model';
import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { MessageService } from '../modules/shared/services/message-service.service';

@Injectable({
  providedIn: 'root'
})
export class PreconditionService {

  constructor(private apiProxy: ApiProxyService, private messageService: MessageService) {
  }

  get(specificationVersionId: number, allowedStates: string[], activeStates: string[]): Observable<Transition[]> {

    const activeParam = Array.from(activeStates.map(x => '&activeStates=' + x)).join('');
    const allowedParam = Array.from(allowedStates.map(x => '&allowedStates=' + x)).join('');

    return this.apiProxy.get<Transition[]>(`${environment.apiUrl}preconditions/transitions?specificationVersionId=${specificationVersionId}${activeParam}${allowedParam}`)
      .pipe(map(items => items));
  }
}
