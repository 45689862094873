import { Location } from '@angular/common';
import { Directive, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { CategoryName } from 'app/app-model/enums';
import { FingerprintService } from 'app/app-services/fingerprint.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { FunctionBase } from 'app/modules/shared/components/function/function-base';
import { FunctionRead } from 'app/modules/shared/components/function/function-read';
import { FunctionWrite } from 'app/modules/shared/components/function/function-write';
import { FunctionItem } from 'app/modules/shared/components/function/function.item';
import { FunctionType } from 'app/modules/shared/components/function/functions';

@Directive()
export class FingerprintCategoryItemDirective extends FunctionItem implements OnDestroy {

  @Input()
  isLoading = true;

  fingerprintService: FingerprintService;
  hasSyncDoneSubscriptions = false;
  oldName = '';
  serviceName = '';
  // specificationVersionId: number;

  constructor(private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
    super.categoryType = CategoryName.Fingerprint;
  }

  ngOnDestroy(): void {
    super.unsubscribe();
  }

  public setIsSyncingFromMasterState() {
    this.isSyncingFromMaster = true;
    this.isSyncReadDone = false;
    this.isSyncWriteDone = false;
  }

  public createFunctions() {
    if (!this.functions || this.functions.length === 0) {
      const readFunction = new FunctionRead(false, []);
      this.createDefaultFingerprintProperties(readFunction);
      readFunction.serviceExecutionSequence.modified.subscribe(val => {
        this.notifyItemChanged();
      });

      const writeFunction = new FunctionWrite(false, []);
      this.createDefaultFingerprintProperties(writeFunction);
      writeFunction.serviceExecutionSequence.modified.subscribe(val => {
        this.fingerprintService.tryAssignFingerprintConstants(writeFunction.serviceExecutionSequence);
        this.notifyItemChanged();
      });

      this.functions = [readFunction, writeFunction];
    }
  }

  public createProperties(func: FunctionBase) {
    this.createDefaultFingerprintProperties(func);
  }

  setName(name: string) {
    this.oldName = this.model.name;
    this.name = name;
    this.model.name = name;
    this.notifyItemChanged();
  }

  public getPropertyNameForFunctionType(functionType: FunctionType): string {
    return '';
  }

  public notifyItemChanged() {
    if (!this.isSyncingFromMaster) {
      console.log('Fingerprint ' + this.name + ' modified...Saving changes');
      this.fingerprintService.updateItem(this.specificationService.currentSpecificationVersion.id, this.model, this.oldName)
        .subscribe(updatedFp => {
          this.model = updatedFp;

          const newUrl = this.router.createUrlTree([], {
            relativeTo: this.activatedRoute,
            queryParams: { itemId: this.model.name }
          }).toString();
          this.locationService.go(newUrl);
          this.getServiceName();
          console.log('Fingerprint' + this.name + ' successfully updated');
        }, err => {
        });
    }
  }

  protected syncFromModel() {
    this.setIsSyncingFromMasterState();
    this.id = this.model.id;
    this.name = this.model.name;
    this.oldName = this.name;
    this.isLoading = true;
    this.createFunctions();
    if (this.specificationService.currentSpecification) {
      this.syncSequences(this.specificationService.currentSpecificationVersion.id).subscribe(_ => {
        this.updateSyncingFromMasterState();
        this.selectDefaultFunction();
        super.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
        this.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
        this.getServiceName();
      });
    }
  }

  protected createServiceExecutionSequenceIfNeeded(functionType: FunctionType, func: FunctionBase) {
    switch (functionType) {
      case FunctionType.Read:
        if (this.model.readSequence) {
          break;
        }
        this.model.readSequence = new ServiceExecutionSequenceModel();
        func.serviceExecutionSequence.model = this.model.readSequence;
        this.isSyncReadDone = true;
        break;
      case FunctionType.Write:
        if (this.model.writeSequence) {
          break;
        }
        this.model.writeSequence = new ServiceExecutionSequenceModel();
        func.serviceExecutionSequence.model = this.model.writeSequence;
        this.isSyncWriteDone = true;
        break;
      default:
        break;
    }
  }

  protected subscribeDefaultServcieExecutionSequenceEvents() {
    if (!this.hasSyncDoneSubscriptions) {
      this._serviceExecutionSequence.syncDone.subscribe(args => {
        this.subscribeServiceExecutionModifiedEvents();
        this.isSyncingFromMaster = false;
      });
      this.hasSyncDoneSubscriptions = true;
    }
  }

  protected updateSyncingFromMasterState() {
    this.isSyncingFromMaster = false;
    this.isLoading = false;
  }

  private createDefaultFingerprintProperties(fpFunction: FunctionBase) {
    fpFunction.properties = [];
    fpFunction.addProperty('Date');
    fpFunction.addProperty('KeySerialNumber');
    fpFunction.addProperty('ApplicationId');
    fpFunction.addProperty('ApplicationVersion');
  }
  public getServiceName() {
    if (this.model.readSequence &&
      this.model.readSequence.executions &&
      this.model.readSequence.executions.length > 0 &&
      this.model.readSequence.executions[0]
    ) {
      this.serviceName = this.model.readSequence.executions[0].service;
      if (this.model.readSequence.executions.length < 2) {
        this.fetchEcuIdentifierInUse(this.specificationVersionId, this.serviceName);
      }
    }

    else if (this.model.writeSequence &&
      this.model.writeSequence.executions &&
      this.model.writeSequence.executions.length > 0 &&
      this.model.writeSequence.executions[0]) {
      this.serviceName = this.model.writeSequence.executions[0].service;
      if (this.model.writeSequence.executions.length < 2) {
        this.fetchEcuIdentifierInUse(this.specificationVersionId, this.serviceName);
      }
    }

    else {
      this.fetchEcuIdentifierInUse(this.specificationVersionId);
    }

  }
}
