<div class="sdds-card" style="background: #f2f5f8; margin-bottom:15px;">
  <div style="margin-top: 10px; margin-right: 5px; float:right;">
    <app-expression-view-toolbar [expressionModel]="expressionModel" [isReadOnly]="isReadOnly"
      [copyEnabled]="copyEnabled" [reorderEnabled]="reorderEnabled"
      [isAllowed]="isAllowed"></app-expression-view-toolbar>
  </div>
  <div class="sdds-card-body" style="margin-bottom:10px;">
    <div *ngIf="substringComparisonEnabled" class="substringComparisonContainer">
      <b>Substring Comparison</b>
      <br />
      <span class="sdds-checkbox-item">
        <input class="sdds-form-input" name="cbxSubstringStartIndex" type="checkbox" id="withStartIndex"
          [(ngModel)]="withSubstringStartIndexChecked" [disabled]="isReadOnly||!isAllowed" />
        <label class="sdds-form-label" for="withStartIndex">
          With start index</label>
      </span>
      <span class="sdds-checkbox-item">
        <input class="sdds-form-input" name="cbxSubstringLength" type="checkbox" id="withLength"
          [(ngModel)]="withSubstringLengthChecked" [disabled]="isReadOnly||!isAllowed" />
        <label class="sdds-form-label" for="withLength">With length</label>
      </span>
    </div>

    <div class="sdds-row" style="margin-top: 10px;">
      <div
        class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3">
        <span *ngIf="legacyModeEnabled">
          <app-id-code-field *ngIf="hexLength > 0" [hexCount]="hexLength"
            [hexCode]="expressionModel.legacyIdentifierCode" [isEditingAllowed]="!isReadOnly"
            (hexCodeChanged)="onIdentifierHexCodeChanged($event)" [isAllowed]="isAllowed"></app-id-code-field>
          <app-id-code-field *ngIf="!hexLength" [hexCount]="1" [hexCode]="expressionModel.legacyIdentifierCode"
            [isEditingAllowed]="!isReadOnly" (hexCodeChanged)="onIdentifierHexCodeChanged($event)"
            [isAllowed]="isAllowed">
          </app-id-code-field>
        </span>

        <span *ngIf="!legacyModeEnabled">
          <button *ngIf="!assignedEcuIdentifier" class="sdds-btn sdds-btn-sm bg-white" (click)="showIdentifierSelector()">
            Set Ecu Identifier *
          </button>
          <a
            *ngIf="assignedEcuIdentifier"
            style="cursor: pointer"
            (click)="showIdentifierSelector()"
          >
            {{ assignedEcuIdentifier.displayName }} -
            {{
            expressionModel.ecuIdentifierPropertyToCompareWith.propertyName
            }}
          </a>
          <a *ngIf="assignedEcuIdentifier" style="cursor: pointer" title="Navigate"
            (click)="nagivateToEcuIdentifier(assignedEcuIdentifier.name)">
            <img src="font/iconsrc/link.png" style="width: 20px; margin-left: 5px;" />
          </a>
        </span>
      </div>

      <div class="sdds-col-max-3 text-center mt-5">
        <span>
          <b *ngIf="
              expressionModel.compareOperator ===
              ServerIdentificationCompareOperatorType.EqualTo
            ">Equals to</b>
          <b *ngIf="
              expressionModel.compareOperator ===
              ServerIdentificationCompareOperatorType.StartsWith
            ">Starts with</b>
          <b *ngIf="
              expressionModel.compareOperator ===
              ServerIdentificationCompareOperatorType.Contains
            ">Contains</b>

          <b *ngIf="
              expressionModel.compareOperator ===
              ServerIdentificationCompareOperatorType.LessThan
            ">Less than</b>
          <b *ngIf="
              expressionModel.compareOperator ===
              ServerIdentificationCompareOperatorType.LessThanOrEqualTo
            ">Less than or equal to</b>
          <b *ngIf="
              expressionModel.compareOperator ===
              ServerIdentificationCompareOperatorType.GreaterThanOrEqualTo
            ">Greater than or equal to</b>
          <b *ngIf="
              expressionModel.compareOperator ===
              ServerIdentificationCompareOperatorType.GreaterThan
            ">Greater than</b>
        </span>
      </div>

      <div
        class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3">
        <div *ngIf="legacyModeEnabled">
          <span>
            <sdds-textfield size="md" name="legacyValueControl" ngDefaultControl [(ngModel)]="compareValue"
              [disabled]="expressionModel.legacyIdentifierCode < 0 || isReadOnly||!isAllowed"
              (customChange)="notifyServerIdentificationChanged()">
              <span slot="sdds-helper">({{ kwpComparisonValueType }})</span>
            </sdds-textfield>
          </span>
        </div>

        <div *ngIf="!legacyModeEnabled">
          <span>
            <sdds-textfield size="md" name="valueControl" ngDefaultControl [(ngModel)]="compareValue"
              [disabled]="!hasAssignedEcuIdentifier || isReadOnly||!isAllowed" (customChange)="onValueChanged()">
            </sdds-textfield>
          </span>

          <div *ngIf="assignedEcuIdentifier">
            <span class="sdds-body-02">({{ assignedParameterValueTypeName }})</span>
          </div>
        </div>
      </div>

      <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3"
        *ngIf="
          expressionModel.compareOperator ===
          ServerIdentificationCompareOperatorType.Contains
        ">
        <span>
          <sdds-textfield size="md" label-inside="Search length" name="searchLengthvalueControl" type="number"
            ngDefaultControl [(ngModel)]="searchLengthValue" [disabled]="isReadOnly||!isAllowed"
            (customChange)="onSearchLengthValueChanged()">
          </sdds-textfield>
        </span>
      </div>

      <div class="sdds-col-max sdds-col-xxlg sdds-col-xlg sdds-col-lg sdds-col-md sdds-col-sm sdds-col-xs" *ngIf="
          expressionModel.compareOperator !==
          ServerIdentificationCompareOperatorType.StartsWith
        ">
        <app-substring-comparison-range [expressionModel]="expressionModel" [isReadOnly]="isReadOnly"
          [startIndexEnabled]="
              hasSubstringStartIndexEnabled || withSubstringStartIndexChecked
            " [lengthEnabled]="
              hasSubstringLengthEnabled || withSubstringLengthChecked
            " (substringComparisonChanged)="notifyServerIdentificationChanged()"></app-substring-comparison-range>
      </div>
    </div>

    <div class="sdds-row">
      <div
        class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-5 sdds-col-sm-5 sdds-col-xs-5">
        <div *ngIf="identifierSelectorEnabled" style="margin-top: 10px;">
          <app-identifier-selector *ngIf="identifierSelectorEnabled" [expressionModel]="expressionModel"
            (notifyEcuIdentifierSelected)="onEcuIdentifierSelected($event)" (notifyCollapse)="showIdentifierSelector()"
            [isReadOnly]="isReadOnly"></app-identifier-selector>
        </div>
      </div>
    </div>
  </div>
</div>