import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedFile, SharedFileType } from 'app/app-model/legacy-file';

import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-other-files-viewer',
  templateUrl: './other-files-viewer.component.html',
  styleUrls: ['./other-files-viewer.component.css']
})
export class OtherFilesViewerComponent {
  constructor(public dialog: MatDialog,private authService:AuthService) { }
  @Output()
  unlikSelectedFile: EventEmitter<{ scommSharedFileSpecification: SharedFile; sharedFileType: SharedFileType }> = new EventEmitter();


  public get otherFiles(): SharedFile[] {
    return this._otherFiles;
  }

  @Input()
  public set otherFiles(v: SharedFile[]) {
    this._otherFiles = v;
  }
  @Input()
  isReadOnly: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  openModalEventEmitter: EventEmitter<void> = new EventEmitter<void>();
  SharedFileType = SharedFileType;

  private _otherFiles: SharedFile[];
  unlinkSelectedFileSpecification(sharedFile: SharedFile, fileType: SharedFileType) {
    this.unlikSelectedFile.next({ scommSharedFileSpecification: sharedFile, sharedFileType: fileType });
  }

  openDialog(sharedFile: SharedFile, fileType: SharedFileType) {
    let dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        fileName:  sharedFile.name,
      },
    },);
    const sub = dialogRef.componentInstance.yesEventEmitter.subscribe(() => {
     this.unlinkSelectedFileSpecification(sharedFile,fileType)
    })
    dialogRef.afterClosed().subscribe(result => {
      dialogRef.componentInstance.yesEventEmitter.unsubscribe();
    });
  }
  canEditSCOMMFiles() {
    return this.authService.canEditSCOMMFiles();
  }
}
