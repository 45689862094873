import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';
import { Subscription } from 'rxjs';
import { SpecificationService } from 'app/app-services/specification-service';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { tap } from 'rxjs/operators';
import { IoCategoryItemDirective } from 'app/data-categories/io/io-category-item';
import { IoService } from 'app/app-services/io.service';
import { DialogBaseComponent } from 'app/modules/shared/components/dialog-base/dialog-base.component';
import { IoListModel, IoListIdCategoryItemId } from 'app/app-model/iolist/iolist.model';

@Component({
  selector: 'app-ios-selector',
  templateUrl: './ios-selector.component.html',
  styleUrls: ['./ios-selector.component.css']
})
export class IosSelectorComponent implements OnInit {
  @ViewChild('dialogBase', { static: true }) dialogBase: DialogBaseComponent;
  @ViewChild('select', { static: false }) selectElement: ElementRef;

  @Output()
  iosAdded: EventEmitter<IoCategoryItemDirective[]> = new EventEmitter();

  @Input()
  ioRelations: IoListIdCategoryItemId[];

  diagnosticServices: DiagnosticServiceCategoryItem[];   // Available diagnostic services
  isLoading: boolean;
  filterText: string;
  specificationVersionId: number;
  ioRemovedSubscription: Subscription;
  serviceChanged: Subscription;

  private _currentIos: IoCategoryItemDirective[];
  private _okButtonName = 'Add';
  // private _title = 'Add a new service';

  @Input()
  public set currentIos(v: IoCategoryItemDirective[]) {
    this._currentIos = v;
  }

  public get currentIos(): IoCategoryItemDirective[] {
    return this._currentIos;
  }

  public get okButtonName(): string {
    return this._okButtonName;
  }

  @Input()
  public set okButtonName(name: string) {
    this._okButtonName = name;
  }

  constructor(
    private specificationService: SpecificationService,
    private messageService: MessageService,
    private ioService: IoService) {
    this.isLoading = false;
  }

  isIoAlreadyAssigned(io: IoCategoryItemDirective): boolean{
    const ioAssigned =  this.ioRelations.find(iorelation => iorelation.ioName === io.name);
    if(ioAssigned){
      return true;
    }
    return false;
  }

  emitIosAdded(){
    const selectedOptions = this.selectElement.nativeElement.options;
    const selectedIos = new Array<IoCategoryItemDirective>();

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < selectedOptions.length; i++) {
      if (!selectedOptions[i].selected) {
        continue;
      }

      const ioToAssign = this.currentIos.find(item => item.name === selectedOptions[i].value);
      if (ioToAssign) {
        selectedIos.push(ioToAssign);
      }
    }

    this.iosAdded.emit(selectedIos);
  }

  ngOnInit() {
    this.isLoading = true;

    this.specificationService.selectedSpecificationEventEmitter.pipe(tap(specification => {
      if (specification && specification.specificationVersion) {
        this.specificationVersionId = specification.specificationVersion.id;
        this.ioService.getItemsPreview(this.specificationVersionId).subscribe(ios => {
          this.currentIos = ios;
          this.isLoading = false;
        });
      }
    })).subscribe();
  }

  filter(filterText: string) {
    this.filterText = filterText;
  }
}
