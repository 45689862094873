import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DiagnosticProtocolType} from 'app/app-model/enums';
import {
  ServerIdentificationCompareOperatorType,
  ServerIdentificationExpressionNodeType,
} from 'app/app-model/server-identification/server-identification.enums';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { ExpressionNodeHelperService } from 'app/app-services/expression-node-helper.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { Subscription } from 'rxjs';
import { IdentificationExpressionItemService } from '../../../app-services/identification-expression-item.service';
import { IdentificationExpressionService } from '../../../app-services/identification-expression.service';


@Component({
  selector: 'app-operator-comparison',
  templateUrl: './operator-comparison.component.html',
  styleUrls: ['./operator-comparison.component.css']
})
export class OperatorComparisonComponent implements OnInit, OnDestroy {

  @Input()
  expressionModel: ServeridentificationExpressionModel;

  @Input()
  parentComparison: OperatorComparisonComponent;

  @Input()
  isRootOperator = false;

  @Input()
  isReadOnly: boolean;

  @Input()
  isAllowed: boolean;

  public get legacyModeEnabled(): boolean {
    return this._legacyModeEnabled;
  }
  @Input()
  public set legacyModeEnabled(v: boolean) {
    this._legacyModeEnabled = v;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ServerIdentificationExpressionNodeType = ServerIdentificationExpressionNodeType;

  _copySubscription: Subscription;
  _deleteSubscription: Subscription;
  _moveDownSubscription: Subscription;
  _moveUpSubscription: Subscription;

  private _legacyModeEnabled: boolean;

  constructor(private identificationExpressionItemService: IdentificationExpressionItemService,
    private identificationExpressionService: IdentificationExpressionService,
    private expressionNodeCreatorService: ExpressionNodeHelperService,
    private specificationService: SpecificationService,
    private dataCategoryService: DataCategoriesService) {
  }

  ngOnInit() {
    this._copySubscription = this.identificationExpressionItemService.onCopy.subscribe({
      next: (expression) => {
        this.copyExpression(expression);
      }
    });
    this._deleteSubscription = this.identificationExpressionItemService.onDelete.subscribe({
      next: (expression) => {
        this.deleteExpression(expression);
      }
    });
    this._moveDownSubscription = this.identificationExpressionItemService.onMoveDown.subscribe({
      next: (expression) => {
        this.moveDownExpression(expression);
      }
    });
    this._moveUpSubscription = this.identificationExpressionItemService.onMoveUp.subscribe({
      next: (expression) => {
        this.moveUpExpression(expression);
      }
    });
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  clearSubscriptions() {
    if (this._copySubscription != null) {
      this._copySubscription.unsubscribe();
    }
    if (this._deleteSubscription != null) {
      this._deleteSubscription.unsubscribe();
    }
    if (this._moveDownSubscription != null) {
      this._moveDownSubscription.unsubscribe();
    }
    if (this._moveUpSubscription != null) {
      this._moveUpSubscription.unsubscribe();
    }
  }

  addValueComparisonExpressionNode(compareOperator: ServerIdentificationCompareOperatorType) {
    this.expressionNodeCreatorService.assignValueComparisonExpressionNode(this.expressionModel, compareOperator);
    this.notifyContentChangedIfKwpSpecification();
  }

  addIntervalComparisonExpressionNodes() {
    this.expressionNodeCreatorService.assignOperatorWithInterval(this.expressionModel);
    // this.notifyContentChangedIfKwpSpecification();
  }


  addValueComparisonNodeEqualTo() {
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.EqualTo);
  }

  addValueComparisonNodeLessThan() {
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.LessThan);
  }

  addValueComparisonNodeLessThanOrEqualTo() {
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.LessThanOrEqualTo);
  }

  addValueComparisonNodeGreaterThan() {
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.GreaterThan);
  }

  addValueComparisonNodeGreaterThanOrEqualTo() {
    this.addValueComparisonExpressionNode(ServerIdentificationCompareOperatorType.GreaterThanOrEqualTo);
  }

  addValueComparisonNodeInterval() {
    this.addIntervalComparisonExpressionNodes();
  }

  addValueEqualsComparisonNode() {
    this.expressionNodeCreatorService.assignValueEqualsComparisonNode(this.expressionModel);
    this.notifyContentChangedIfKwpSpecification();
  }

  addValueStartsWithComparisonNode() {
    this.expressionNodeCreatorService.assignValueStartsWithComparisonNode(this.expressionModel);
    this.notifyContentChangedIfKwpSpecification();
  }

  addValueContainsComparisonNode() {
    this.expressionNodeCreatorService.assignValueContainsComparisonNode(this.expressionModel);
    this.notifyContentChangedIfKwpSpecification();
  }

  addCanAddressComparisonNode() {
    this.expressionNodeCreatorService.assignCanAddressComparisonNode(this.expressionModel);
    this.notifyContentChangedIfKwpSpecification();
  }

  addExecutingModeComparisonNode() {
    this.expressionNodeCreatorService.assignExecutingModeComparisonNode(this.expressionModel);
    this.notifyOperatorContentChanged();
  }

  addDoesNotExistComparisonNode() {
    this.expressionNodeCreatorService.assignDoesNotExistComparisonNode(this.expressionModel);
    this.notifyContentChangedIfKwpSpecification();
  }

  addSpecialComparisonNode() {
    this.expressionNodeCreatorService.assignSpecialComparisonNode(this.expressionModel);
    this.notifyContentChangedIfKwpSpecification();
  }

  addOperator() {
    this.expressionNodeCreatorService.assignOperator(this.expressionModel);
  }

  deleteExpression(expression: ServeridentificationExpressionModel) {
    this.expressionNodeCreatorService.deleteExpression(this.expressionModel, expression);
  }

  copyExpression(expression: ServeridentificationExpressionModel) {
    this.expressionNodeCreatorService.copyExpression(this.expressionModel, expression);
  }

  moveUpExpression(expression: ServeridentificationExpressionModel) {
    this.expressionNodeCreatorService.moveUpExpression(this.expressionModel, expression);
  }

  moveDownExpression(expression: ServeridentificationExpressionModel) {
    this.expressionNodeCreatorService.moveDownExpression(this.expressionModel, expression);
  }

  onOperatorTypeChange() {
    this.notifyOperatorContentChanged();
  }

  hasCompareOperatorOfIntervalType(expression: ServeridentificationExpressionModel): boolean {
    return expression && (expression.compareOperator === ServerIdentificationCompareOperatorType.LessThan ||
      expression.compareOperator === ServerIdentificationCompareOperatorType.GreaterThan ||
      expression.compareOperator === ServerIdentificationCompareOperatorType.LessThanOrEqualTo ||
      expression.compareOperator === ServerIdentificationCompareOperatorType.GreaterThanOrEqualTo);
  }

  hasAnyIntervalNodeChilds(): boolean {
    return this.expressionModel.expressions && this.hasCompareOperatorOfIntervalType(this.expressionModel.expressions[0]);
  }

  private notifyContentChangedIfKwpSpecification() {
    if (this.hasServersCategoryActive()) {
      if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
        this.notifyOperatorContentChanged();
      }
    } else {
      this.notifyOperatorContentChanged();
    }
  }

  private hasServersCategoryActive(): boolean {
    return this.dataCategoryService.activeCategory &&
      this.dataCategoryService.activeCategory.category === Category.Servers;
  }

  private notifyOperatorContentChanged() {
    console.log('Operator Modified!');
    this.identificationExpressionService.modified.next(this.expressionModel);
  }

  get nodeType(): ServerIdentificationExpressionNodeType {
    if (this.specificationService.isLegacyVersion) {
      if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
        return ServerIdentificationExpressionNodeType.LegacyEcuIdentifierCompareOperand;
      } else {
        return ServerIdentificationExpressionNodeType.EcuIdentifierCompareOperand;
      }
    } else {
      return ServerIdentificationExpressionNodeType.EcuIdentifierCompareOperand;
    }
  }
}
