import { TypedValueData } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { RangeModel } from 'app/app-model/diagnostic-service/range.model';

export class ComputationalMethodIntervalModel {
    id: number;
    index: number;
    text: string;
    hasInverseInternalValue: boolean;
    inverseInternalValue: TypedValueData;
    range: RangeModel;
    numeratorConstant: number;
    numeratorCoefficient: number;
    denominatorConstant: number;
    formulaAsStr: string;
}
