import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SpecificationService } from 'app/app-services/specification-service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-assigned-security-access-items-viewer',
  templateUrl: './assigned-security-access-items-viewer.component.html',
  styleUrls: ['./assigned-security-access-items-viewer.component.css']
})
export class AssignedSecurityAccessItemsViewerComponent implements OnInit {
  @Output()
  public securityAccessItemsChangedEvent = new EventEmitter<string[]>();

  @Output()
  public securityAccessRemovedEvent = new EventEmitter<string>();

  @Input()
  isReadOnly: boolean;
  specificationVersionId: number;
  specificationId: number;

  private _securityAccessItems: string[];
  public get securityAccessItems(): string[] {
    return this._securityAccessItems;
  }
  @Input()
  public set securityAccessItems(v: string[]) {
    this._securityAccessItems = v;
  }

  constructor(private specificationService: SpecificationService, private authService:AuthService) { }

  ngOnInit(): void {
    this.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
    this.specificationId = this.specificationService.currentSpecificationVersion.specification.id;
  }

  removeSecurityAccessFromList(securityAccessToRemove: string){
    this.securityAccessRemovedEvent.emit(securityAccessToRemove);
  }

  onSecurityAccessItemsAdded(addedServices: Array<string>) {
    this.securityAccessItems = this.securityAccessItems.concat(addedServices);
    this.securityAccessItemsChangedEvent.emit(this.securityAccessItems);
  }

  get isAddButtonDisabled(): boolean{
    return this.isReadOnly;
  }

  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }

}
