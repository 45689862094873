import { DiagnosticProtocolType, ServerExecutionModeType, SpecificationType } from './enums';


export class NewSpecification {
    name: string;
    specificationType: SpecificationType;
    diagnosticProtocol: DiagnosticProtocolType;
    serverExecutionMode: ServerExecutionModeType;
    includeInScomm: boolean;
}
