import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryItem } from 'app/app-model/category-item';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { NavigationService } from 'app/app-services/navigation.service';
import { ServerIdentificationService } from 'app/app-services/server-identification.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { DataCategoryModel } from 'app/data-categories/data-category-model';
import { ToolbarComponent } from 'app/modules/shared/components/toolbar/toolbar.component';

import { Specification } from '../../app-model/specification';
import { SpecificationVersion } from '../../app-model/specification-version';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-category-items',
  templateUrl: './category-items.component.html',
  styleUrls: ['./category-items.component.css']
})
export class CategoryItemsComponent implements OnInit {

  @ViewChild(ToolbarComponent)
  apptoolbar: ToolbarComponent;

  @Input()
  specification: Specification;

  @Input()
  specificationVersion: SpecificationVersion;

  @Input()
  category: DataCategoryModel;

  @Input()
  canRemove = true;

  @Input()
  canAdd = true;

  @Input()
  dataTarget = '#createItemModal';

  @Input()
  toolbarEnabled = true;

  @Input()
  showHexcode: boolean;

  filterText: string;
  private identifications;
  private canNotBeDeleted: boolean;
  constructor(private categoryService: DataCategoriesService,
    private specificationService: SpecificationService,
    private specificationStatusService: SpecificationStatusService,
    private navigationService: NavigationService,
    private messageService: MessageService,
    private serverIdentificationService: ServerIdentificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService) { }

  ngOnInit() {
    this.categoryService.categoryContentModified.subscribe((value: { modifiedCategory: DataCategoryModel; itemId: string }) => {
      this.whenCategoryContentIsModified(value);
    });
    console.log("this.category", this.category);
  }

  private getIdentifiersExpressionName(expression: ServeridentificationExpressionModel): boolean {
    if (expression.expressions != null) {
      expression.expressions.forEach(element => {
        this.getIdentifiersExpressionName(element);
      });
    }
    if (expression.ecuIdentifierPropertyToCompareWith != null && expression.ecuIdentifierPropertyToCompareWith.ecuIdentifier != null && expression.ecuIdentifierPropertyToCompareWith.ecuIdentifier === this.category.activeItem.name) {
      this.canNotBeDeleted = true;
      return;
    }
  }
  get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  createItem(itemName: string) {
    this.categoryService.createAndAddItemToActiveCategory(itemName, this.specificationVersion.id).subscribe((item) => {
    }, err => {
      this.messageService.dispatchErrorMessageFromApi(err);
    });
  }

  removeSelectedItem() {
    const nextItemToSelect = this.nextItemToSelect(this.category.activeItem);

    this.categoryService.removeItemFromActiveCategory(this.specificationVersion.id, this.category.activeItem).subscribe(() => {
      this.apptoolbar.setFilterSilent('');
      this.navigationService.navigateToCategoryItem(this.category.category, nextItemToSelect);
    }, err => {
      this.messageService.dispatchErrorMessageFromApi(err);
    });
  }

  filter(filterText: string) {
    this.category.activeItem = undefined;
    this.filterText = filterText;
  }

  itemSelected(item: CategoryItem) {
    if (item) {
      this.navigationService.navigateToCategoryItem(this.category.category, item);
      this.canNotBeDeleted = undefined;
    }
  }

  private whenCategoryContentIsModified(modifiedCategoryInfo: { modifiedCategory: DataCategoryModel; itemId: string }) {
    if (!this.specification || !this.specificationVersion) {
      return;
    }
    if (!modifiedCategoryInfo.itemId) {
      this.navigationService.navigateToCategory(this.category.category);
    } else {
      this.navigationService.navigateToCategoryItemById(this.category.category, modifiedCategoryInfo.itemId);
    }
  }

  private nextItemToSelect(currentCategoryItem: CategoryItem): CategoryItem {
    const indexOfCurrentItem = this.category.itemIndex(currentCategoryItem);

    if (this.category.items.length === 1 || indexOfCurrentItem === 0) {
      return undefined;
    }

    return this.category.items[indexOfCurrentItem - 1];
  }
  canEditCategoryItems() {
    return this.authService.canEditCategoryItems();
  }

}