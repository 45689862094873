<app-category-header [text]="'ECU Resets'"></app-category-header>

<div class="sdds-row">
    <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">        
        <app-category-items *ngIf="category" [specification]="specification"
            [specificationVersion]="specificationVersion" [category]="category"
            [isAllowed]="canEditCategoryItems()"></app-category-items>
    </div>
    <div
        class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
         <div *ngIf="ecuResetItem">
            <app-editable-textbox [overrideFont]="true" [text]="ecuResetItem.name"
                (textEditedEventEmitter)="editingNameDone($event)" enclosingElement="h1"
                [editingNotAllowed]="!isEditingAllowed" [editingNotAllowedReason]="editingNotAllowedReason"
                enclosingElement="h2" [isAllowed]="canEditCategoryItems()">
            </app-editable-textbox>

            <div class="sdds-banner">
                <div class="sdds-banner-body">
                    The list bellow shows all diagnostic services that are going to be executed by this Ecu Reset.
                </div>
            </div>

            <div class="sdds-on-white-bg w-80 mt-16 mb-16">
                <sdds-textfield size="md" type="number" ngDefaultControl [(ngModel)]="ecuResetItem.model.resetDelay"
                    (customChange)="onResetChanged()" [disabled]="!isEditingAllowed||!canEditCategoryItems()"
                    placeholder="Enter delay in ms">
                    <span slot="sdds-label">Delay(ms)</span>
                </sdds-textfield>
            </div>

            <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg" style="width: 200px;">
                <span class="sdds-dropdown-label-outside">Delay Type</span>
                <select [(ngModel)]="ecuResetItem.model.delayType" name="ecuResetDelays" (change)="onResetChanged()"
                    [disabled]="isReadOnly||!canEditCategoryItems()">
                    <option *ngFor="let delayType of getEcuResetDelayType()" [ngValue]="ecuResetDelayType[delayType]">
                        {{delayType}}
                    </option>
                </select>
            </div>

            <app-prerequisites [loginServiceExecution]="loginServiceExecution" [canActivateWriteFingerprint]="false"
                (loginCommandChanged)="onLoginCommandChanged($event)" [isReadOnly]="!isEditingAllowed">
            </app-prerequisites>

            <app-assigned-services-viewer [serviceExecutionSequence]="ecuResetItem.serviceExecutionSequence"
                [propertyType]="ecuResetItem.propertyType" [isReadOnly]="!isEditingAllowed"
                [hideAddButtonWithOneService]="true">
            </app-assigned-services-viewer>
        </div>
    </div>
</div>