<div class="sdds-card">
    <div class="sdds-card-header headerPadding">
        <h4 class="sdds-card-headline sdds-row">
            <button class="sdds-btn sdds-btn-ghost sdds-btn-sm" (click)="toggleDisplay()">
                <mat-icon fontSet="material-icons-outlined" class="md-16">zoom_out_map</mat-icon>
            </button>
            <h4>Properties</h4>
        </h4>
    </div>
    <div class="sdds-card-body" *ngIf="isShow">
        <br>
        <div class="sdds-row" *ngIf="properties && properties.length > 0">
            <div
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Property</th>
                            <th scope="col">Data Type</th>
                            <th scope="col" *ngIf="propertyType === PropertyType.Target"> Set to request parameter
                            </th>
                            <th scope="col" *ngIf="propertyType === PropertyType.Source">Get from response parameter
                            </th>
                            <th scope="col" *ngIf="!isReadOnly && unlinkEnabled"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let property of propertiesToShow">
                            <td>{{property.name}}</td>

                            <td *ngIf="property.selectedPropertyParameter">
                                {{property.selectedPropertyParameter.parameter.getPhysicalDataType()}}
                            </td>

                            <td *ngIf="!property.selectedPropertyParameter">N/A</td>

                            <td>
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <select [(ngModel)]="property.selectedPropertyParameter"
                                                    [disabled]="isReadOnly||!canEditCategoryItems()">
                                                    <option *ngIf="!property.selectedPropertyParameter" selected value>
                                                    </option>
                                                    <optgroup
                                                        *ngFor="let serviceExecution of getMatchingServiceExecutionsForPropertyType()"
                                                        [label]="serviceExecution.diagnosticService.name">
                                                        <option
                                                            *ngFor="let propertyParameter of getRelevantPropertyParameters(property, serviceExecution); trackBy:trackByParamName"
                                                            [disabled]="parameterSelected(propertyParameter)||!canEditCategoryItems()"
                                                            [ngValue]="propertyParameter">
                                                            {{propertyParameter.parameter.model.name}}
                                                        </option>
                                                    </optgroup>
                                                </select>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="property.selectedPropertyParameter">
                                                    From service:
                                                    {{property.selectedPropertyParameter.parameter.sourceService}}
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>

                            <td *ngIf="!isReadOnly && unlinkEnabled">
                                <div class="sdds-toggle sdds-toggle-sm" tabindex="0">
                                    <input type="checkbox" class="sdds-toggle-input"
                                        [checked]="property.selectedPropertyParameter" (click)="property.unlink()"
                                        [disabled]="!property.selectedPropertyParameter||!canEditCategoryItems()"
                                        matTooltip="Disconnect parameter">
                                    <span class="sdds-toggle-switch"></span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <a class="cursor-hand text-red-600" *ngIf="canShowLessProperties" (click)="showLessProperties()">Show
                    less..</a>
                <a class="cursor-hand text-red-600 ml-5" *ngIf="canShowMoreProperties"
                    (click)="showMoreProperties()">{{'Show ' + propertiesDisplayBlockSize + ' more(' +
                    propertiesLeftToShow + ' remaining)..'}}</a>
            </div>

            <div *ngIf="!properties || properties.length <= 0"
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
                <div class="sdds-banner sdds-banner-info">
                    <div class="sdds-banner-body">
                        This item has no properties attached to it.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>