import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IoType, ScaniaState } from 'app/app-model/enums';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { IoService } from 'app/app-services/io.service';
import { NavigationService } from 'app/app-services/navigation.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { IoCategoryItemDirective } from 'app/data-categories/io/io-category-item';
import { FunctionType } from 'app/modules/shared/components/function/functions';
import { Category } from 'app/specification-structure/category-items/category.enum';

import { CategoryComponentBaseComponent } from '../../modules/shared/model/category-component-base';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';


@Component({
  selector: 'app-io',
  templateUrl: './io.component.html',
  styleUrls: ['./io.component.css']
})

export class IoComponent extends CategoryComponentBaseComponent<IoCategoryItemDirective> implements OnInit {
  @ViewChild('importZipBtn') importZipBtn: ElementRef;
  ioItem: IoCategoryItemDirective;
  functionType = FunctionType;
  ioType = IoType;
  scaniaState = ScaniaState;

  constructor(private specificationStatusService: SpecificationStatusService,
    private authService:AuthService,
    activatedRoute: ActivatedRoute,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    navigationService: NavigationService,
    private diagnosticService: DiagnosticServiceProvider,
    messageService: MessageService, private ioService: IoService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.Ios, navigationService);

  }
  public isNumeric(value) {
    // Use the unary plus operator (+) to convert the value to a number
    // and check if it's a finite number
    return !isNaN(value) && !isNaN(parseFloat(value));
  }
  
  get availableIoType(): string[] {
    return Object.keys(IoType).filter(d => !this.isNumeric(d));
  }

  get availableScaniaState(): string[] {
    return Object.keys(ScaniaState).filter(d => !this.isNumeric(d));
  }

  ngOnInit() {
    this.componentSubscriptions.push(this.itemChanged.subscribe(item => {
      this.ioItem = item;
    }));
  }

  get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get isImportFileAllowed(): boolean {
    return this.isEditingAllowed &&
      (this.specificationService.isUpgradedVersion || this.specificationService.isNativeVersion);
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  onIoTypeChange() {
    this.ioItem.notifyItemChanged();
  }

  onScaniaStateChange() {
    this.ioItem.notifyItemChanged();
  }

  editingNameDone(name: string) {
    if (name.trim().length > 0) {
      this.ioItem.setName(name.trim());
    } else {
      this.messageService.dispatchErrorMessage('IO name can not be empty!');
    }
  }

  updateDescription() {
    this.ioItem.model.description = this.ioItem.model.description.trim();
    this.ioItem.notifyItemChanged();
  }

  enableFunction(f: FunctionType, isEnabled: boolean) {
    this.ioItem.enableFunction(f, isEnabled, true);
    this.ioItem.notifyItemChanged();
  }

  onResetChanged() {
    this.ioItem.notifyItemChanged();
  }

  // importZipFile(event: any) {
  //   const selectedFile = event.target.files[0];
  //   const fileReader = new FileReader();
  //   fileReader.readAsBinaryString(selectedFile);
  //   fileReader.onload = () => {
  //     let fileData = fileReader.result;
  //     selectedFile.data = btoa(<string>fileReader.result);
  //     console.log(selectedFile.data);
  //     // this.ioService.addIosFromFile(this.specificationService.currentSpecificationVersion.id, selectedFile.data).subscribe(item => {
  //     //   this.refreshAllIos();
  //     // });
  //   };

  //   this.importZipBtn.nativeElement.value = '';
  // }

  refreshAllIos() {
    this.ioService.reset();
    this.categoryService.getCategory(Category.Ios).reset();
    this.categoryService.updateCategory(Category.Ios, this.specificationService.currentSpecificationVersion).subscribe(_ => {
      this.navigationService.navigateToFirstAvailableItem(this.category.category);
    });
  }

  canEditCategoryItems(): boolean {
    return this.authService.canEditCategoryItems();
  }
}
