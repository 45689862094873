// import { Byte } from '@angular/compiler/src/util';

export class CardReaderData {
    name?: string;
    value?: string;
    state?: string;
    certificateInfo?: string;
    email?: string;
}

export enum WebSocketStatus {
    None = 0,
    Inserted = 1,
    Ejected = 2,
}

export enum MessageType {
    CardReaderInformation = 'CardReaderInformation',
    SigningResponse = 'SigningResponse',
    VerificationResponse = 'VerificationResponse',
    UnknownCommandResponse = 'UnknownCommandResponse',
}

export interface WsMessage {
    MessageType?: MessageType;
    CardReaders?: CardReaderData[];
}

export class ProcessMessage {
    command?: string;
    readerName?: string;
    pinCode?: string;
    data?: number[];
}
export interface SigningMessage {
    MessageType?: MessageType;
    Message?: string;
    Signature?: number[];
    Certificate?: number[];
    Success?: boolean;
}
