import { MessageType } from 'app/modules/shared/model/service/message-type';
import { ParameterDataModel } from 'app/app-model/diagnostic-service/parameter-data.model';

export class MessageData {
    id: number;
    parameters: Array<ParameterDataModel>;
    messageType: MessageType;
    diagnosticService: any;
    serviceId: number;
}
