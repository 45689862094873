import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RangeModel } from 'app/app-model/diagnostic-service/range.model';
import { DataLimitType } from 'app/app-model/server-identification/server-identification.enums';


@Component({
  selector: 'app-editable-range',
  templateUrl: './editable-range.component.html',
  styleUrls: ['./editable-range.component.css']
})
export class EditableRangeComponent {

  @Output()
  rangeChange: EventEmitter<void> = new EventEmitter();

  DataLimitType = DataLimitType;

  private _range: RangeModel;
  public get range(): RangeModel {
    return this._range;
  }
  @Input()
  public set range(v: RangeModel) {
    this._range = v;
  }

  @Input()
  isOdxBasedSpecification: boolean;

  @Input()
  isAllowed: boolean;

  onLowerLimitSelect() {
    if (this.range.lowerType === DataLimitType.Infinite) {
      this.range.lowerValue.data = '';
    }

    this.onChange(undefined);
  }

  onUpperLimitSelect() {
    if (this.range.upperType === DataLimitType.Infinite) {
      this.range.upperValue.data = '';
    }

    this.onChange(undefined);
  }

  onChange(event: any) {
    this.rangeChange.next();
  }
}
