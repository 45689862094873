import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiagnosticProtocolType } from 'app/app-model/enums';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { EcuIdentifierCategoryItem } from 'app/data-categories/identification-property/ecu-identification-category-item';
import { Category } from 'app/specification-structure/category-items/category.enum';

import { CategoryComponentBaseComponent } from '../../modules/shared/model/category-component-base';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { NavigationService } from 'app/app-services/navigation.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-identification-property',
  templateUrl: './identification-property.component.html',
  styleUrls: ['./identification-property.component.css']
})
export class IdentificationPropertyComponent extends CategoryComponentBaseComponent<EcuIdentifierCategoryItem> implements OnInit {

  private _ecuIdentifierItem: EcuIdentifierCategoryItem;
  public get ecuIdentifierItem(): EcuIdentifierCategoryItem {
    return this._ecuIdentifierItem;
  }

  public set ecuIdentifierItem(v: EcuIdentifierCategoryItem) {
    this._ecuIdentifierItem = v;

    if (this._ecuIdentifierItem) {
      this._ecuIdentifierItem.specificationService = this.specificationService;
    }
  }

  get hexCount(): number {
    if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
      return 2;
    } else {
      return 4;
    }
  }

  get isEditingAllowed(): boolean {
    return !this.specificationStatusService.isInRelease(this.specificationService.currentSpecificationVersion);
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  constructor(private specificationStatusService: SpecificationStatusService,
    activatedRoute: ActivatedRoute,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    messageService: MessageService,
    navigationService: NavigationService,
    private authService: AuthService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.Identification, navigationService);
  }

  ngOnInit() {
    this.componentSubscriptions.push(this.itemChanged.subscribe(item => this.ecuIdentifierItem = item));

    if (this.ecuIdentifierItem) {
      this.ecuIdentifierItem.specificationService = this.specificationService;
    }
  }

  onHexCodeChanged(hexCode: number) {
    this.ecuIdentifierItem.model.code = hexCode;
    this.ecuIdentifierItem.notifyEcuIdentifierChanged();
  }

  editingNameDone(name: string) {
    if (name.trim().length > 0) {
      this.ecuIdentifierItem.setName(name.trim());
    } else {
      this.messageService.dispatchErrorMessage('Identification name can not be empty!');
    }
  }
  canEditCategoryItems(): boolean {
    return this.authService.canEditCategoryItems();
  }
}
