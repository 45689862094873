import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'app/app-services/application.service';
import { SpecificationVersionHubService } from 'app/app-services/hubs/specification-version-hub.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { UserService } from 'app/app-services/user.service';
import { Subscription } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { Specification } from '../app-model/specification';
import { SpecificationVersion } from '../app-model/specification-version';
import { User } from '../modules/shared/model/user';

@Component({
  selector: 'app-specification',
  templateUrl: './specification.component.html',
  styleUrls: ['./specification.component.css']
})

export class SpecificationComponent implements OnInit, OnDestroy {
  @ViewChild('mySidebar', { static: true })
  mySidebar: ElementRef;

  @ViewChild('main', { static: true })
  main: ElementRef;

  specification: Specification;
  specificationVersion: SpecificationVersion;
  subscriptions: Subscription[] = [];
  initHubSubscription: Subscription;
  user: User;
  showNavbar = true;
  isProjectStructureExpanded = true;

  private _specId: number;
  private _specVersionId: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private specificationService: SpecificationService,
    private specificationVersionHubService: SpecificationVersionHubService,
    private userService: UserService,
    private appService: ApplicationService) {
  }

  public get specId(): number {
    return this._specId;
  }
  public set specId(v: number) {
    this._specId = v;
  }

  public get specVersionId(): number {
    return this._specVersionId;
  }
  public set specVersionId(v: number) {
    this._specVersionId = v;
  }

  ngOnInit() {
    this.subscriptions = [];
    this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
      this.specId = +params['specId'];
      this.specVersionId = +params['specVersionId'];

      const getUserObsv = this.userService.getCurrentUser().pipe(tap(u => this.whenUserIsReady(u)));

      const initHubObsv = this.specificationVersionHubService.initHub().pipe(tap(() => {
        this.specificationVersionHubService.join(this.specId);
      }));

      const getSpecObsv = this.specificationService.getSpecification(this.specId).pipe(tap(specification => {
        this.joinSpecificationVersionGroup(specification, this.specVersionId);
        this.specification = specification;
      }));

      this.initHubSubscription = getUserObsv.pipe(mergeMap(() => initHubObsv)).pipe(mergeMap(() => getSpecObsv)).subscribe();
    }));

    this.subscriptions.push(this.appService.requestFullScreen.subscribe(arg => {
      this.showNavbar = false;
    }));

    this.subscriptions.push(this.appService.requestNormalWindowMode.subscribe(arg => {
      this.showNavbar = true;
    }));
  }

  whenUserIsReady(u: User) {
    this.user = u;
    this.specificationVersionHubService.user = this.user;
  }

  joinSpecificationVersionGroup(specification: Specification, specificationVersionId: number) {
    if (this.specificationVersion) {
      this.specificationVersionHubService.leave(this.specId);
    }

    this.specificationVersion = specification.specificationVersions.find(pv => pv.id === specificationVersionId);
    this.specificationService.setCurrentSpecification(specification, this.specificationVersion);
    this.specificationVersionHubService.join(this.specVersionId);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];

    if (this.initHubSubscription) {
      this.initHubSubscription.unsubscribe();
    }

    if (this.user && this.specificationVersion) {
      this.specificationVersionHubService.leave(this.specificationVersion.id);
    }
  }

  expandCollapse() {
    if (this.isProjectStructureExpanded) {
      this.isProjectStructureExpanded = false;
    } else {
      this.isProjectStructureExpanded = true;
    }
  }

}
