import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Label } from 'app/app-model/label/label';
import { ScommFileType, SharedFileType } from 'app/app-model/legacy-file';
import { ScommSharedFileVersion } from 'app/app-model/scomm-shared-file-version';
import { LabelService } from 'app/app-services/label-service';
import { SharedFilesEventsService } from 'app/app-services/shared-files-events.service';
import { SharedFilesService } from 'app/app-services/shared-files.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-file-version-details',
  templateUrl: './file-version-details.component.html',
  styleUrls: ['./file-version-details.component.css']
})
export class FileVersionDetailsComponent implements OnInit, OnDestroy {

  @Input()
  isAllowed:boolean;
  
  labels: any[] = [];
  fileVersion: ScommSharedFileVersion;
  fileSpecificationVersionSelectedSubscription: Subscription;
  applyingChanges: boolean;
  loadingLabels: boolean;
  alwaysIncluded: boolean;
  sharedFileType: SharedFileType;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  SharedFileType = SharedFileType;

  private _dcodeSpecificationIds: number[];

  constructor(private sharedFilesService: SharedFilesService,
    private sharedFilesEventsService: SharedFilesEventsService,
    private labelService: LabelService,
    private specificationService: SpecificationService,
    private messageService: MessageService
    ) { }

  public get currentVersionLabels(): Label[] {
    if (this.fileVersion) {
      return this.fileVersion.labels.sort((l1, l2) => {
        if (l1.id < l2.id) {
          return 1;
        }

        if (l1.id > l2.id) {
          return -1;
        }
        return 0;
      });
    } else {
      return [];
    }
  }

  public get dcodeSpecificationIds(): number[] {
    return this._dcodeSpecificationIds;
  }
  public set dcodeSpecificationIds(v: number[]) {
    this._dcodeSpecificationIds = v;
  }

  ngOnInit() {
    this.fileSpecificationVersionSelectedSubscription =
      this.sharedFilesEventsService.sharedFileSpecificationVersionSelected.subscribe({
        next: (selectedVersion) => {
          this.fileVersion = selectedVersion;

          this.loadAssignedLabelsForCurrentVersion();

          if (selectedVersion != null) {
            this.sharedFilesService.getSharedFileSpecification(this.fileVersion.sharedFileId).pipe(tap(fileSpecification => {
              this.alwaysIncluded = fileSpecification.alwaysInclude;
              this.sharedFileType = this.getSharedFileTypeFromScommFileType(fileSpecification.fileType);
            })).subscribe();
          }
        }
      });
  }

  getSharedFileTypeFromScommFileType(scommFileType: ScommFileType): SharedFileType {
    switch (scommFileType) {
      case ScommFileType.KeyFile:
        return SharedFileType.KeyFile;
      default:
        return SharedFileType.Other;
    }
  }

  init() {
    if (this.fileVersion) {
      this.applyingChanges = false;
    }
  }

  loadAssignedLabelsForCurrentVersion() {
    if (this.fileVersion) {
      this.sharedFilesService.getDcodeSpecificationsUsedBySharedFile(this.fileVersion.sharedFileId).pipe(tap(versionIds => {
        this.dcodeSpecificationIds = versionIds;
      })).subscribe();
    }
  }

  ngOnDestroy(): void {
    this.fileSpecificationVersionSelectedSubscription.unsubscribe();
  }

  download() {
    this.sharedFilesService.getSharedFileSpecificationVersion(this.fileVersion.id).pipe(tap(scommSharedFileVersion => {
      this.saveFileToLocal(scommSharedFileVersion);
    })).subscribe();
  }

  private saveFileToLocal(scommSharedFileVersion: ScommSharedFileVersion) {
    const fileLowerCase = scommSharedFileVersion.file.name.toLowerCase();
    const isBinFile = fileLowerCase.endsWith('.bin');

    if (isBinFile) {
      FileSaver.saveAs(new Blob([this.base64ToArrayBuffer(scommSharedFileVersion.file.data)]), scommSharedFileVersion.file.name);
    } else {
      FileSaver.saveAs(new Blob([atob(scommSharedFileVersion.file.data)]), scommSharedFileVersion.file.name);
    }
  }

  private base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
}

