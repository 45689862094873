import { Component, Input } from '@angular/core';
import {
  ComputationalMethodModel,
  ComputationalMethodType,
} from 'app/app-model/diagnostic-service/computational.method.model';
import { DataLimitType } from 'app/app-model/diagnostic-service/range.model';
import { ComputationalMethodIntervalModel } from 'app/app-model/diagnostic-service/text.table.interval.data.model';

@Component({
  selector: 'app-computational-method',
  templateUrl: './computational-method.component.html',
  styleUrls: ['./computational-method.component.css']
})
export class ComputationalMethodComponent {
  @Input()
  computationalMethod: ComputationalMethodModel;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  DataLimitType = DataLimitType;

  get isLinearFormulaData() {
    return this.computationalMethod.methodType === ComputationalMethodType.LinearFormulaData;
  }

  get isTextTable() {
    return this.computationalMethod.methodType === ComputationalMethodType.TextTable;
  }

  get sortedTextTableIntervals() {
    return this.computationalMethod.intervals.sort(this.textTableComparer);
  }

  get hasDefaultInterval(): boolean {
    return this.computationalMethod.defaultInterval !== undefined &&
      this.computationalMethod.defaultInterval !== null;
  }

  get defaultIntervalText(): string {
    return this.computationalMethod.defaultInterval;
  }

  get linearFormula(): string {
    let linearFormula = '';
    const linearFormulaInterval = this.computationalMethod.intervals[0];

    if (linearFormulaInterval.formulaAsStr) {
      return linearFormulaInterval.formulaAsStr;
    }

    if (linearFormulaInterval.numeratorConstant !== 0) {
      linearFormula += '(X' + linearFormulaInterval.numeratorConstant + ')';
    } else {
      linearFormula += 'X';
    }

    if (linearFormulaInterval.numeratorCoefficient !== 1 && linearFormulaInterval.numeratorCoefficient !== 0) {
      linearFormula += '*' + linearFormulaInterval.numeratorCoefficient;
    }

    if (linearFormulaInterval.denominatorConstant !== 1 && linearFormulaInterval.denominatorConstant !== 0) {
      linearFormula += '/' + linearFormulaInterval.denominatorConstant;
    }

    return linearFormula;
  }

  private textTableComparer(a: ComputationalMethodIntervalModel, b: ComputationalMethodIntervalModel) {
    return a.index - b.index;
  }
}
