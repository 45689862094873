import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { DataCategoryModel } from 'app/data-categories/data-category-model';
import { Specification } from 'app/app-model/specification';
import { SpecificationVersion } from 'app/app-model/specification-version';
import { Router } from '@angular/router';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { User } from 'app/modules/shared/model/user';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-header-inline-menu',
  templateUrl: './header-inline-menu.component.html',
  styleUrls: ['./header-inline-menu.component.css']
})
export class HeaderInlineMenuComponent implements OnInit {

  contentMenu = false;
  exportMenu = false;
  nameMenu = false;
  availableCategories: DataCategoryModel[];
  currentSpecification: Specification;
  currentSpecificationVersion: SpecificationVersion;
  router: Router;
  loadingUser: boolean;
  showReleaseEventEmitter = new EventEmitter<void>();
  performExportEventEmitter = new EventEmitter<void>();
  user: User;
  
  
  constructor(private dataCategoryService: DataCategoriesService, 
              router: Router,
              private specificationService: SpecificationService, 
              private authService: AuthService,   
        
    ) {
    this.router = router;
    this.loadingUser = true;
    this.user = new User();
    
  }
  
  @Input() username: string | undefined; 
  @Input() userRole: string | undefined;

  ngOnInit(): void {
    this.availableCategories = this.dataCategoryService.getDataCategories();
    this.specificationService.selectedSpecificationEventEmitter.subscribe((event) => {
      this.currentSpecification = event.specification;
    });
  }



  changeContentMenuState(): void {
    this.contentMenu = !this.contentMenu;
    this.exportMenu = false;
    this.nameMenu = false;
  }

  changeExportMenuState(): void {
    this.exportMenu = !this.exportMenu;
    this.contentMenu = false;
    this.nameMenu = false;
  }

  changeNameMenuState(): void {
    this.nameMenu = !this.nameMenu;
    this.contentMenu = false;
    this.exportMenu = false;
  }

  clearCurrentSpecification() {
    this.specificationService.setCurrentSpecification(undefined, undefined);
    this.router.navigate(['/']);
  }

  showReleasePopup() {
    this.showReleaseEventEmitter.emit();
  }

  performExport() {
    this.performExportEventEmitter.emit();
  }

  canManageTemplate():boolean{
    return this.authService.canManageTemplate();
  }

  onRbaccClick(){
    window.location.href = environment.newUiUrl + 'rbacc';
  }

  onLabelClick(){
    window.location.href = environment.newUiUrl + 'label';
  }
}
