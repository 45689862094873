import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ServiceIdentifier } from 'app/app-model/enums';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { ServiceExecutionDirective } from 'app/modules/shared/model/service-execution/service-execution';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MessageService } from '../../services/message-service.service';
import { DialogBaseComponent } from '../dialog-base/dialog-base.component';

@Component({
  selector: 'app-service-selector',
  templateUrl: './service-selector.component.html',
  styleUrls: ['./service-selector.component.css']
})
export class ServiceSelectorComponent implements OnInit {

  @ViewChild('dialogBase', { static: true }) dialogBase: DialogBaseComponent;
  @ViewChild('select', { static: false }) selectElement: ElementRef;

  @Output()
  diagnosticServicesAdded: EventEmitter<DiagnosticServiceCategoryItem[]> = new EventEmitter();


  public get modalName(): string {
    return this._modalName;
  }
  @Input()
  public set modalName(v: string) {
    this._modalName = v;
  }



  @Input()
  public set activeFunctionName(v: string) {
    this._activeFunctionName = v;
    this.assignSID();
  }
  public get activeFunctionName(): string {
    return this._activeFunctionName;
  }


  // modalName = 'serviceSelectModal';
  diagnosticServices: DiagnosticServiceCategoryItem[];   // Available diagnostic services
  selectedDiagnosticServices: DiagnosticServiceCategoryItem[];
  isLoading: boolean;
  filterText: string;
  specificationVersionId: number;
  serviceRemovedSubscription: Subscription;
  serviceChanged: Subscription;
  SID: string;

  private _currentServiceExecutions: ServiceExecutionDirective[];
  private _modalName: string;
  private _activeFunctionName: string;
  private _okButtonName = 'Add';
  // private _title = 'Add a new service';

  @Input()
  public set currentServiceExecutions(v: ServiceExecutionDirective[]) {
    this._currentServiceExecutions = v;
  }

  public get currentServiceExecutions(): ServiceExecutionDirective[] {
    return this._currentServiceExecutions;
  }

  public get okButtonName(): string {
    return this._okButtonName;
  }

  @Input()
  public set okButtonName(name: string) {
    this._okButtonName = name;
  }

  constructor(
    private diagnosticServiceProvider: DiagnosticServiceProvider,
    private specificationService: SpecificationService,
    private messageService: MessageService, private dataCategoryService: DataCategoriesService) {
    this.isLoading = false;
  }

  ngOnInit() {
    this.isLoading = true;
    this.specificationService.selectedSpecificationEventEmitter.pipe(tap(specification => {
      if (specification && specification.specificationVersion) {
        this.specificationVersionId = specification.specificationVersion.id;
        this.getAvailableDiagnosticServices();
      }
    })).subscribe();

    this.serviceRemovedSubscription = this.diagnosticServiceProvider.serviceRemoved.pipe(tap(itemId => {
      this.getAvailableDiagnosticServices();
    })).subscribe();

    this.serviceChanged = this.diagnosticServiceProvider.serviceChanged.pipe(tap(itemId => {
      this.getAvailableDiagnosticServices();
    })).subscribe();

  }

  assignSID() {
    var obj = this.dataCategoryService.activeCategory.category;
    switch (this.dataCategoryService.activeCategory.category) {
      case Category.Sessions:
        this.SID = ServiceIdentifier.Sessions;
        break;
      case Category.EcuResets:
        this.SID = ServiceIdentifier.EcuResets;
        break;
      case Category.SecurityAccess:
        this.SID = ServiceIdentifier.SecurityAccess;
        break;
      case Category.OperationalData:
        this.SID = ServiceIdentifier.OperationalDatas;
        break;
      case Category.Identification:
        this.SID = ServiceIdentifier.Identifications;
        break;
      case Category.Fingerprints:
        this.SID = '';
        break;
      case Category.FreezeFrames:
        this.SID = ServiceIdentifier.FreezeFrames;
        break;
      case Category.Ios:
        this.SID = ServiceIdentifier.IOs;
        break;
      case Category.Values:
        this.SID = ServiceIdentifier.Values;
        break;
      case Category.VsocMonitoring:
        this.SID = ServiceIdentifier.Vsoc;
        break;
      default:
        this.SID = '';
        break;
    }
    this.getAvailableDiagnosticServices();
  }
  getFingerPrintSID() {
    if (this.activeFunctionName != '') {
      if (this.activeFunctionName === "Write") {
        return "0x2E";
      }
      else {
        return ServiceIdentifier.Fingerprints;
      }
    }
  }

  getAvailableDiagnosticServices() {
    if (this.specificationVersionId) {
      this.diagnosticServiceProvider.getItemsPreview(this.specificationVersionId).pipe(tap((services) => {
        if (this.SID != '')
          this.diagnosticServices = services.filter(s => s.requestSid(s) == this.SID);
        else
          this.diagnosticServices = services;
        this.isLoading = false;
      }, err => {
        this.messageService.dispatchErrorMessageFromApi(err);
      })).subscribe();
    }
  }

  onAddDiagnosticServices() {
    this.diagnosticServicesAdded.emit(this.selectedDiagnosticServices);
  }

  filter(filterText: string) {
    this.filterText = filterText;
  }

  isServiceAlreadyAssigned(diagnosticServiceItem: DiagnosticServiceCategoryItem): boolean {
    if (!this.currentServiceExecutions || this.currentServiceExecutions.length <= 0) {
      return false;
    }

    if (this.currentServiceExecutions.some(c => c.diagnosticService && (c.diagnosticService.model.id === diagnosticServiceItem.model.id))) {
      return true;
    }

    return false;
  }
}
