import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StateChart } from '../app-model/state-chart/state-chart.model';
import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { MessageService } from '../modules/shared/services/message-service.service';

@Injectable({
  providedIn: 'root'
})
export class StateChartService {

  constructor(private apiProxy: ApiProxyService, private messageService: MessageService) {
  }

  getAll(specificationVersionId: number): Observable<StateChart[]> {
    return this.apiProxy.get<StateChart[]>(`${environment.apiUrl}charts?specificationVersionId=${specificationVersionId}`)
      .pipe(map(items => items));
  }
}
