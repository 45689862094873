<div class="sdds-row mt-16" *ngIf="valueConstraint">
    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-16">
        <h6>Constraints</h6>
        <table class="table">
            <tbody>
                <tr>
                    <td>Valid range</td>
                    <td>
                        <app-range [range]="valueConstraint.validRangeData.model"></app-range>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="valueConstraint.valueConstraintItems" class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-16">
        <table class="table">
            <thead>
                <th>Type</th>
                <th>Range</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of valueConstraint.valueConstraintItems">
                    <td>{{item.type}}</td>
                    <td>
                        <app-range [range]="item.range.model"></app-range>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>