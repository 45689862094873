import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GlobalExportComponent } from 'app/modules/release/components/global-export/global-export.component';

import { SharedModule } from '../shared/shared.module';
import { BuildComponent } from './components/build/build.component';
import { GlobalExportPreviewComponent } from './components/global-export-preview/global-export-preview.component';
import {
  AddSpecificSpecificationVersionComponent,
} from './components/view/add-specific-specification-version/add-specific-specification-version.component';
import { AddViewComponent } from './components/view/add-view/add-view.component';
import {
  EmbeddedAddSpecificVersionComponent,
} from './components/view/embedded-add-specific-version/embedded-add-specific-version.component';
import {
  SpecificationVersionPreviewComponent,
} from './components/view/specification-version-preview/specification-version-preview.component';
import { ViewComponent } from './components/view/view.component';
import { BuildService } from './services/build.service';
import { ExportService } from './services/export.service';
import { ViewService } from './services/view.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [
    BuildComponent,
    GlobalExportComponent,
    GlobalExportPreviewComponent,
    ViewComponent,
    AddSpecificSpecificationVersionComponent,
    AddViewComponent,
    SpecificationVersionPreviewComponent,
    EmbeddedAddSpecificVersionComponent,
  ],
  providers: [
    BuildService,
    ExportService,
    ViewService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReleaseModule { }
