import { DiagnosticServiceGroupModel } from 'app/app-model/diagnostic-service/diagnostic-service-group.model';
import { DiagnosticServiceViewModel } from 'app/app-model/diagnostic-service/diagnostic-service-view.model';
import { DiagnosticServiceModel } from 'app/app-model/diagnostic-service/diagnostic.service.model';
import { DiagnosticServiceGroupType } from 'app/app-model/enums';

import { DiagnosticServiceCategoryItem } from './diagnostic-service';
import { DiagnosticServiceCategoryItemGroup } from './diagnostic-service-category-item-group';
import { MessageType } from './message-type';
import { ParameterType } from './parameters/parameter-type';
import { OdxDataType } from './parameters/typed-value-data';

export class DiagnosticServiceView {
  children: DiagnosticServiceCategoryItemGroup[];
  diagnosticServices: DiagnosticServiceCategoryItem[];
  model: DiagnosticServiceViewModel;

  constructor(model: DiagnosticServiceViewModel) {

    this.children = [];
    this.diagnosticServices = [];

    this.model = model;
    model.children.forEach(x => {
      this.addChild(new DiagnosticServiceCategoryItemGroup(x));
    });
    model.diagnosticServices.forEach(x => {
      this.addService(new DiagnosticServiceCategoryItem(x));
    });
  }

  addChild(child: DiagnosticServiceCategoryItemGroup) {
    this.children.push(child);
  }

  addService(service: DiagnosticServiceCategoryItem) {
    this.diagnosticServices.push(service);
  }
}

export class ServiceViewBuilder {
  private readonly _instance: DiagnosticServiceView;
  private _services: DiagnosticServiceModel[];
  private _viewType: number;

  constructor() {
    const model = new DiagnosticServiceViewModel();
    model.children = [];
    model.diagnosticServices = [];
    this._instance = new DiagnosticServiceView(model);
  }

  services(services: DiagnosticServiceModel[]): ServiceViewBuilder {
    this._services = services;
    return this;
  }

  type(viewType: number): ServiceViewBuilder {
    this._viewType = viewType;
    return this;
  }

  build(): DiagnosticServiceView {

    // build plain top level list
    if (this._viewType === 0) {
      this._services.forEach(s => {
        this._instance.diagnosticServices.push(new DiagnosticServiceCategoryItem(s));
      });
      // todo: add Identfiers type
      // build tree grouped by sid/did
    } else {
      this._services.forEach(s => {
        if (s.messages) {
          const request = s.messages.find(m => m.messageType === MessageType.Request);
          if (request && request.parameters && request.parameters.length > 0) {
            if (request.parameters[0].type === ParameterType.Constant && request.parameters[0].value.dataType === OdxDataType.UInt32) {
              const sid = +request.parameters[0].value.data; //  === 0x0010) { // Diagnostic Session Control

              if (+request.parameters[0].value.data === 0x0022 && request.parameters[1].value) { // Read Data By Identifier
                if (request.parameters.length > 1 && request.parameters[0].type === ParameterType.Constant && request.parameters[1].value.dataType === OdxDataType.UInt32) {
                  const did = +request.parameters[1].value.data;
                  this.addProtocolService(sid, did, s);
                  return;
                }
              } else {
                this.addProtocolService(sid, null, s);
                return;
              }
            }
          }
        }
        // not recognized as a protocol service, just add at root
        this._instance.diagnosticServices.push(new DiagnosticServiceCategoryItem(s));
      });
    }

    return this._instance;
  }

  addProtocolService(sid: number, did: number, diagnosticService: DiagnosticServiceModel) {
    const groupName = '0x' + sid.toString(16).toUpperCase() + ' ' + this.getSidFriendlyName(sid);
    let sidNode = this._instance.children.find(x => x.type === DiagnosticServiceGroupType.ProtocolService && x.name === groupName);
    if (!sidNode) {
      const groupModel = new DiagnosticServiceGroupModel();
      groupModel.name = groupName;
      groupModel.type = DiagnosticServiceGroupType.ProtocolService;
      groupModel.children = [];
      groupModel.diagnosticServices = [];
      sidNode = new DiagnosticServiceCategoryItemGroup(groupModel);
      this._instance.children.push(sidNode);
    }
    if (did) {
      const subGroupName = '0x' + did.toString(16).toUpperCase();
      let didNode = sidNode.children.find(x => x.type === DiagnosticServiceGroupType.DataIdentifierType && x.name === subGroupName);
      if (!didNode) {
        const subGroupModel = new DiagnosticServiceGroupModel();
        subGroupModel.name = subGroupName;
        subGroupModel.type = DiagnosticServiceGroupType.DataIdentifiers2Byte;
        subGroupModel.children = [];
        subGroupModel.diagnosticServices = [];
        didNode = new DiagnosticServiceCategoryItemGroup(subGroupModel);
        didNode.diagnosticServices.push(new DiagnosticServiceCategoryItem(diagnosticService));
        sidNode.children.push(didNode);
      }
    } else {
      sidNode.diagnosticServices.push(new DiagnosticServiceCategoryItem(diagnosticService));
    }

  }

  getSidFriendlyName(sid: number): string {
    if (sid === 0x0034) {
      return 'RequestDownload';
    }
    if (sid === 0x0035) {
      return 'RequestUpload';
    }
    if (sid === 0x0036) {
      return 'TransferData';
    }
    if (sid === 0x0037) {
      return 'RequestTransferExit';
    }
    if (sid === 0x0029) {
      return 'Authentication';
    }
    if (sid === 0x0084) {
      return 'SecuredDataTransmission';
    }
    if (sid === 0x0010) {
      return 'DiagnosticSessionControl';
    }
    if (sid === 0x0011) {
      return 'EcuReset';
    }
    if (sid === 0x0012) {
      return 'ReadFreezeFrameData';
    }
    if (sid === 0x0014) {
      return 'ClearDiagnosticInformation';
    }
    if (sid === 0x0017) {
      return 'ReadStatusOfDiagnosticTroubleCodes';
    }
    if (sid === 0x0018) {
      return 'RadDiagnosticTroubleCodeByStatus';
    }
    if (sid === 0x0019) {
      return 'ReadDtcInformation';
    }
    if (sid === 0x0022) {
      return 'ReadDataByIdentifier';
    }
    if (sid === 0x0023) {
      return 'WriteMemoryByAddress';
    }
    if (sid === 0x0027) {
      return 'SecurityAccess';
    }
    if (sid === 0x0028) {
      return 'CommunicationControl';
    }
    if (sid === 0x002c) {
      return 'DynamicallyDefineDataIdentifier';
    }
    if (sid === 0x002e) {
      return 'WriteDataByIdentifier';
    }
    if (sid === 0x002f) {
      return 'InputOutputControlByIdentifier ';
    }
    if (sid === 0x0031) {
      return 'StartRoutineByLocalIdentifier';
    }
    if (sid === 0x0032) {
      return 'StopRoutineByLocalIdentifier';
    }
    if (sid === 0x0033) {
      return 'RequestRoutineResultsByLocalIdentifier';
    }
    if (sid === 0x003e) {
      return 'TesterPresent';
    }
    if (sid === 0x0085) {
      return 'ControlDtcSetting';
    }
    if (sid === 0x0087) {
      return 'LinkControl';
    }
    if (sid === 0x001A) {
      return 'ReadEcuIdentification';
    }
    if (sid === 0x0021) {
      return 'ReadDataByLocalIdentifier';
    }
    if (sid === 0x00B1) {
      return 'ProgrammingService';
    }
    if (sid === 0x00B2) {
      return 'ReadStatusOfDiagnosticEventCodes';
    }
    if (sid === 0x00B3) {
      return 'ReadDiagnosticEventCodesByStatus';
    }
    if (sid === 0x00B5) {
      return 'ReadMemoryByAddressUDS';
    }
    if (sid === 0x00B6) {
      return 'WriteMemoryByAddressUDS';
    }
    if (sid === 0x00C1) {
      return 'ScalingByte';
    }
    return 'Unknown';
  }
}
