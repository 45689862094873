import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataCategoryModel } from 'app/data-categories/data-category-model';
import { DiagnosticServiceDataCategoryModel } from 'app/data-categories/diagnostic-service-data-category-model';
import { Category } from 'app/specification-structure/category-items/category.enum';

import { CategoryItemsService } from './category-items-service';

@Injectable({
  providedIn: 'root'
})
export class DataCategoryCreatorService {
  private categoryType: Category;
  private categoryItemsService: CategoryItemsService;
  private withoutPromptEnabled: boolean;
  private hasLegacySupport: boolean;
  private name: string;
  private id: string;
  private router: Router;
  private route: ActivatedRoute;
  private isEnabled = true;

  setCategoryType(categoryType: Category): DataCategoryCreatorService {
    this.categoryType = categoryType;
    return this;
  }

  setCategoryItemsService(categoryItemsService: CategoryItemsService): DataCategoryCreatorService {
    this.categoryItemsService = categoryItemsService;
    return this;
  }

  setWithoutPromptEnabled(withoutPromptEnabled: boolean): DataCategoryCreatorService {
    this.withoutPromptEnabled = withoutPromptEnabled;
    return this;
  }

  sethasLegacySupport(hasLegacySupport: boolean): DataCategoryCreatorService {
    this.hasLegacySupport = hasLegacySupport;
    return this;
  }

  setName(name: string): DataCategoryCreatorService {
    this.name = name;
    return this;
  }

  setId(id: string): DataCategoryCreatorService {
    this.id = id;
    return this;
  }

  setRouter(router: Router): DataCategoryCreatorService {
    this.router = router;
    return this;
  }

  setRoute(route: ActivatedRoute): DataCategoryCreatorService {
    this.route = route;
    return this;
  }

  setEnabled(isEnabled: boolean): DataCategoryCreatorService {
    this.isEnabled = isEnabled;
    return this;
  }

  create(): DataCategoryModel {
    if (this.categoryType === Category.Services) {
      const diagnosticServiceCategory = new DiagnosticServiceDataCategoryModel(this.router, this.route);
      diagnosticServiceCategory.category = this.categoryType;
      diagnosticServiceCategory.categoryItemsService = this.categoryItemsService;
      diagnosticServiceCategory.name = this.name;
      diagnosticServiceCategory.id = this.id;
      diagnosticServiceCategory.enabled = this.isEnabled;
      diagnosticServiceCategory.hasLegacySupport = this.hasLegacySupport;

      return diagnosticServiceCategory;
    } else {
      const dataCategoryModel = new DataCategoryModel(this.router, this.route);
      dataCategoryModel.category = this.categoryType;
      dataCategoryModel.addWithoutPromptEnabled = this.withoutPromptEnabled;
      dataCategoryModel.categoryItemsService = this.categoryItemsService;
      dataCategoryModel.hasLegacySupport = this.hasLegacySupport;
      dataCategoryModel.name = this.name;
      dataCategoryModel.id = this.id;
      dataCategoryModel.enabled = this.isEnabled;

      return dataCategoryModel;
    }
  }
}
