import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ServiceExecutionModel } from 'app/app-model/diagnostic-service/service-execution.model';
import { NavigationService } from 'app/app-services/navigation.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';

import { PropertyType } from '../../model/properties/base-property';
import { ServiceExecutionDirective } from '../../model/service-execution/service-execution';
import { DiagnosticServiceCategoryItem } from '../../model/service/diagnostic-service';
import { FunctionItem } from '../function/function.item';

@Component({
  selector: 'app-read-id-status',
  templateUrl: './read-id-status.component.html',
  styleUrls: ['./read-id-status.component.css']
})
export class ReadIdStatusComponent {

  @Output()
  readIdStatusServiceChanged: EventEmitter<DiagnosticServiceCategoryItem> = new EventEmitter();

  @Output()
  readIdSequenceRemoved: EventEmitter<DiagnosticServiceCategoryItem> = new EventEmitter();

  @Input()
  source: FunctionItem;

  @Input()
  propertyType: PropertyType;

  @Input()
  isReadOnly: boolean;

  @Input()
  hideAddButtonWithOneService: boolean;

  private _readIdServiceExecutionSequence: ServiceExecutionSequence;

  @Input()
  public set readIdServiceExecutionSequence(v: ServiceExecutionSequence) {
    this._readIdServiceExecutionSequence = v;
  }
  public get readIdServiceExecutionSequence(): ServiceExecutionSequence {
    return this._readIdServiceExecutionSequence;
  }

  constructor(private navigationService: NavigationService, private authService:AuthService) { }

  public onDiagnosticServicesAdded(addedServices: Array<DiagnosticServiceCategoryItem>) {
    const serviceToUse = addedServices[0];
    this.readIdStatusServiceChanged.emit(serviceToUse);
  }

  createReadIdStatusServiceExecutionModel(service: DiagnosticServiceCategoryItem): ServiceExecutionDirective {
    const serviceExecutionModel = new ServiceExecutionModel();
    serviceExecutionModel.service = service.name;

    const serviceExecution = new ServiceExecutionDirective();
    serviceExecution.model = serviceExecutionModel;

    return serviceExecution;
  }

  nagivateToDiagnosticService(diagnosticServiceName: string): void {
    this.navigationService.navigateToDiagnosticService(diagnosticServiceName);
  }

  removeServiceExecution() {
    this.readIdSequenceRemoved.emit();
  }

  get isAddButtonDisabled(): boolean {
    return this.isReadOnly ||
      (this.hideAddButtonWithOneService && (this.readIdServiceExecutionSequence && this.readIdServiceExecutionSequence.model
        && this.readIdServiceExecutionSequence.model.executions && this.readIdServiceExecutionSequence.model.executions.length === 1));
  }
  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
