import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-create-expression-node',
  templateUrl: './create-expression-node.component.html',
  styleUrls: ['./create-expression-node.component.css']
})
export class CreateExpressionNodeComponent {

  @Input()
  isReadOnly: boolean;

  @Input()
  legacyModeEnabled: boolean;

  @Input()
  isAllowed: boolean;

  @Output()
  createLegacyValueEquals: EventEmitter<any> = new EventEmitter();

  @Output()
  createLegacyValueStartsWith: EventEmitter<any> = new EventEmitter();

  @Output()
  createLegacyValueContains: EventEmitter<any> = new EventEmitter();

  @Output()
  createValueStartsWith: EventEmitter<any> = new EventEmitter();

  @Output()
  createValueContains: EventEmitter<any> = new EventEmitter();

  @Output()
  createCanAddressComparison: EventEmitter<any> = new EventEmitter();

  @Output()
  createSpecialComparison: EventEmitter<any> = new EventEmitter();

  @Output()
  createDoesNotExistComparison: EventEmitter<any> = new EventEmitter();

  @Output()
  createOperator: EventEmitter<any> = new EventEmitter();

  @Output()
  createValueComparisonNodeEqualTo: EventEmitter<any> = new EventEmitter();

  @Output()
  createValueComparisonNodeLessThan: EventEmitter<any> = new EventEmitter();

  @Output()
  createValueComparisonNodeLessThanOrEqualTo: EventEmitter<any> = new EventEmitter();

  @Output()
  createValueComparisonNodeGreaterThan: EventEmitter<any> = new EventEmitter();

  @Output()
  createValueComparisonNodeGreaterThanOrEqualTo: EventEmitter<any> = new EventEmitter();

  @Output()
  createValueComparisonNodeInterval: EventEmitter<any> = new EventEmitter();

  @Output()
  createExecutingModeComparison: EventEmitter<any> = new EventEmitter();

  constructor(private authService:AuthService) { }
  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
