import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-remove-diagnostics-family-confirm',
  templateUrl: './remove-diagnostics-family-confirm.component.html',
  styleUrls: ['./remove-diagnostics-family-confirm.component.css']
})
export class RemoveDiagnosticFamiliesConfirmComponent {

  @Input()
  itemToRemoveName: string;

  @Output()
  removeConfirm: EventEmitter<any> = new EventEmitter();

  confirmRemove() {
    this.removeConfirm.emit();
  }
}
