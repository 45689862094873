
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CardReaderData, ProcessMessage, SigningMessage, WebSocketStatus } from 'app/app-model/e-id/e-id.model';
import { RbacFileDialogStep, SocketConnectionState } from 'app/app-model/enums';
import { EIdService } from 'app/app-services/e-id.service';
import { FileService } from 'app/app-services/file.service';
import { RbacService } from 'app/app-services/rbac-file-service';
import { UserService } from 'app/app-services/user.service';
import { Rbac } from 'app/modules/shared/model/rbac';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rbac-file-selector',
  templateUrl: './rbac-file-selector.component.html',
  styleUrls: ['./rbac-file-selector.component.css']
})
export class RbacFileSelectorComponent implements OnInit,OnDestroy {

  @ViewChild('rbacFileSelector', { static: false })
  rbacFileSelector: ElementRef;

  @Output()
  applySelection: EventEmitter<Rbac> = new EventEmitter();

  currentRbacFileDialogStep = RbacFileDialogStep.SelectFile;
  selectedRbacFile: { fileName: string, fileData: File };
  signResult: { success: boolean, message: SigningMessage };
  isValidRbacFile: boolean = true;
  signCompleted: boolean;
  cardPin: string;
  signStarted: boolean;
  defaultEntityChecked:boolean=false;
  hasValidRbacFileContents: boolean=true;
  rbacFileValidationMessage: string;

  isCardReaderConnected: boolean;
  currentCardState: WebSocketStatus;
  currentSocketState: { state: SocketConnectionState, message: string };
  socketConnectionState = SocketConnectionState;

  cardConnectionSubscription: Subscription;
  cardStateDubscription: Subscription;
  socketStateSubscription: Subscription;
  availableCardsSubscription: Subscription;
  signStatusSubscription: Subscription;

  private _selectedCard: CardReaderData;

  public get selectedCard(): CardReaderData {
    return this._selectedCard;
  }
  public set selectedCard(v: CardReaderData) {
    this._selectedCard = v;
  }
 
  private _availableCardReaders: CardReaderData[];
  public get availableCardReaders(): CardReaderData[] {
    return this._availableCardReaders;
  }
  public set availableCardReaders(v: CardReaderData[]) {
    this._availableCardReaders = v;
  }

  constructor(private eidService: EIdService, 
    private fileService: FileService,
    private userService: UserService,
    private rbacService:RbacService) { }

  ngOnInit(): void {
    this.subscribeToEidServiceEvents();
    this.eidService.initWebSocket();
  }

  ngOnDestroy(): void {
    this.resetDialog();
    this.unsubscribeToEvents();
  }

  rbacFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    if (element.files) {
      this.selectedRbacFile = { fileName: element.files[0].name, fileData: element.files[0] };
      this.resetSignatureFields();
      this.validateSelectedRbacFile();
    }
  }

  validateSelectedRbacFile(): void {
    this.rbacService.validateRbacFile(this.selectedRbacFile).subscribe(validationResult=>{
      this.isValidRbacFile=validationResult.isValidFile;
      this.hasValidRbacFileContents = validationResult.isValidContent;
      this.rbacFileValidationMessage = validationResult.validationMessage;
    })
  }

  private resetSignatureFields() {
    this.signStarted = false;
    this.signCompleted = false;
    this.signResult = undefined;
    this.cardPin = undefined;
  }

  
  get cardIsInserted(): boolean {
    return this.currentCardState === WebSocketStatus.Inserted;
  }

  get connectedToEidService(): boolean {
    return this.currentSocketState.state === SocketConnectionState.Connected;
  }

  get canShowCardSelectorAndSign(): boolean {
    return this.connectedToEidService && this.cardIsInserted;
  }



  get isShowingSelectRbacFileView(): boolean {
    return this.currentRbacFileDialogStep === RbacFileDialogStep.SelectFile;
  }

  get isShowingSignRbacFileView(): boolean {
    return this.currentRbacFileDialogStep === RbacFileDialogStep.SignRbac;
  }

  get canGoToPrevious(): boolean {
    return this.currentRbacFileDialogStep === RbacFileDialogStep.SelectFile ? false : true;
  }

  get canGoToNext(): boolean {
    if (this.currentRbacFileDialogStep === RbacFileDialogStep.ConfirmSelection) {
      return false;
    }

    if (this.currentRbacFileDialogStep === RbacFileDialogStep.SelectFile &&
      this.selectedRbacFile && this.isValidRbacFile) {
      return true;
    }

    if (this.currentRbacFileDialogStep === RbacFileDialogStep.SignRbac && this.signCompleted && this.signResult.success) {
      return true;
    }

    return false;
  }

  get canConfirmRbacFileSelection(): boolean {
    return this.currentRbacFileDialogStep === RbacFileDialogStep.ConfirmSelection;
  }

  get signEnabled() {
    return this.selectedCard && this.cardPin && !this.signStarted;
  }
  get isShowingConfirmRbacFileView(): boolean {
    return this.currentRbacFileDialogStep === RbacFileDialogStep.ConfirmSelection;
  }

  previous() {
    if (this.currentRbacFileDialogStep === RbacFileDialogStep.SignRbac) {
      this.currentRbacFileDialogStep = RbacFileDialogStep.SelectFile;
    }
    else if (this.currentRbacFileDialogStep === RbacFileDialogStep.ConfirmSelection) {
      this.currentRbacFileDialogStep = RbacFileDialogStep.SignRbac;
    }
  }

  next() {
    if (this.currentRbacFileDialogStep === RbacFileDialogStep.SelectFile) {
      this.currentRbacFileDialogStep = RbacFileDialogStep.SignRbac;
    }
    else if (this.currentRbacFileDialogStep == RbacFileDialogStep.SignRbac) {
      this.currentRbacFileDialogStep = RbacFileDialogStep.ConfirmSelection;
    }
  }


  signSelectedRbacFile(): void {
    this.signStarted = true;
    const message: ProcessMessage = new ProcessMessage();
    message.command = 'SignHash';
    message.readerName = this.selectedCard.name;
    message.pinCode = this.cardPin;;
  this.fileService.getRbacFileContentsAsBase64(this.selectedRbacFile.fileData).subscribe(rbacFileAsBase64 => {
      this.eidService.sign(this.selectedCard.email, message, rbacFileAsBase64);
    }); 
  }

  unsubscribeToEvents() {
    this.cardConnectionSubscription.unsubscribe();
    this.cardStateDubscription.unsubscribe();
    this.socketStateSubscription.unsubscribe();
    this.availableCardsSubscription.unsubscribe();
    this.signStatusSubscription.unsubscribe();
  }

  private resetDialog() {
    this.resetSignatureFields();
    this.isValidRbacFile = true;
    this.selectedRbacFile = undefined;
    this.rbacFileValidationMessage = undefined;
    this.defaultEntityChecked=false;
    this.currentRbacFileDialogStep = RbacFileDialogStep.SelectFile;

    if (this.rbacFileSelector) {
      this.rbacFileSelector.nativeElement.value = '';
    }
  }

  cancel() {
    this.resetDialog();
  } 

  apply() {
    const rbacFileToUse = new Rbac();
    this.fileService.getRbacFileContentsAsBase64(this.selectedRbacFile.fileData).subscribe(base64Data => {
      rbacFileToUse.data = base64Data;
      rbacFileToUse.name = this.selectedRbacFile.fileName;
      rbacFileToUse.defaultEntity=this.defaultEntityChecked;
      rbacFileToUse.certificate=this.signResult.message.Certificate;
      rbacFileToUse.signature=this.signResult.message.Signature;
      this.resetDialog();
      this.applySelection.emit(rbacFileToUse);
    });

  }

  private subscribeToEidServiceEvents(): void {
    this.cardConnectionSubscription =
      this.eidService.cardReaderConnectionChanged.subscribe(isCardReaderConnected => this.isCardReaderConnected = isCardReaderConnected);

    this.cardStateDubscription =
      this.eidService.cardStateChanged.subscribe(currentCardState => this.currentCardState = currentCardState);

    this.socketStateSubscription =
      this.eidService.socketConnectionStateChanged.subscribe(currentSocketState => this.currentSocketState = currentSocketState);

    this.availableCardsSubscription =
      this.eidService.availableCardReadersChanged.subscribe(_ => this.updateAvailableCardReaders());

    this.signStatusSubscription =
      this.eidService.signStatusChanged.subscribe(status => {
        this.signResult = status;
        this.signStarted = false;
        this.signCompleted = true;
      });
  }

  private updateAvailableCardReaders(): void {
    this.availableCardReaders = this.eidService.cardInfo();
    if (this.availableCardReaders) {
      this.selectedCard = this.availableCardReaders[0];
    }
  }

}
