import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OperationalDataFile, OperationalDataModel } from 'app/app-model/operational-data/operational-data.model';
import { CategoryServiceBase } from 'app/app-services/category-base.service';
import { CategorySaveService } from 'app/app-services/category-save.service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { OperationalDataCategoryItem } from 'app/data-categories/operational-data/operational-data-category-item';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiProxyService } from '../modules/shared/services/api-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class OperationalDataService extends CategoryServiceBase<OperationalDataModel, OperationalDataCategoryItem> implements OnDestroy {
  constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
    private categorySaveService: CategorySaveService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected apiProxy: ApiProxyService) {
    super(apiProxy);
    this.apiCategoryName = 'variables';
  }

  ngOnDestroy(): void {
    super.unsubscribe();
  }

  public getItemsPreview(specificationVersionId: number): Observable<OperationalDataCategoryItem[]> {
    this.isPending = true;

    if (this.hasCachedItems) {
      this.isPending = false;
      return of(this.items);
    }

    return this.apiProxy.get<OperationalDataModel[]>(environment.apiUrl + 'versions/' + specificationVersionId + '/variables?preview=true')
      .pipe(map((operationalDataModels) => {
        this.items = [];

        const mappedOperationalDatas = operationalDataModels.map(operationalDataModel => {
          const operationalDataItem = this.createOperationalDataCategoryItem(operationalDataModel, specificationVersionId);
          operationalDataItem.isPreview = true;
          return operationalDataItem;
        });

        this.items = mappedOperationalDatas;
        this.hasCachedItems = true;
        this.isPending = false;
        this.getItemsPreviewDone.next(this.isPending);

        return this.items;
      }));
  }

  public createItem(specificationVersionId: number, itemName?: string): Observable<OperationalDataCategoryItem> {
    const itemToCreate = new OperationalDataModel();
    itemToCreate.name = itemName;

    return this.apiProxy.post<OperationalDataModel>(`${environment.apiUrl}versions/${specificationVersionId}/variables/`, itemToCreate)
      .pipe(map(model => {
        const operationalDataItem = new OperationalDataCategoryItem(this.locationService, this.router, this.activatedRoute);
        operationalDataItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
        operationalDataItem.categorySaveService = this.categorySaveService;
        operationalDataItem.operationalDataService = this;
        operationalDataItem.isPreview = false;
        operationalDataItem.model = model;
        operationalDataItem.specificationVersionId = specificationVersionId;

        this.items.push(operationalDataItem);

        return operationalDataItem;
      }));
  }

  public importJSONFile(specificationVersionId: number, jsonFile: OperationalDataFile): Observable<any> {
    return this.apiProxy.post<OperationalDataFile>(`${environment.apiUrl}versions/${specificationVersionId}/importJson/`, jsonFile).pipe(map(_ => { }));;
  }

  public deleteItem(specificationVersionId: number, itemId?: string): Observable<any> {
    return this.apiProxy.delete(`${environment.apiUrl}versions/${specificationVersionId}/variables/${itemId}`)
      .pipe(tap(deletedItem => this.removeCachedItem(deletedItem.name)));
  }

  public updateItem(specificationVersionId: number, operationalDataModel: any, itemId?: string): Observable<OperationalDataModel> {
    return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/variables/${itemId}/`, operationalDataModel);
  }

  public getCompleteItems(specificationVersionId: number): Observable<OperationalDataCategoryItem[]> {
    throw new Error('Method not implemented.');
  }

  public getCategoryItemModel(specificationVersionId: number, name: string): Observable<OperationalDataCategoryItem> {
    return this.apiProxy.get<OperationalDataModel>(`${environment.apiUrl}versions/${specificationVersionId}/variables/${name}/`).pipe(map(model => {
      const operationalDataItem = new OperationalDataCategoryItem(this.locationService, this.router, this.activatedRoute);
      operationalDataItem.operationalDataService = this;
      operationalDataItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      operationalDataItem.categorySaveService = this.categorySaveService;
      operationalDataItem.isPreview = false;
      operationalDataItem.model = model;
      operationalDataItem.specificationVersionId = specificationVersionId;

      this.items.push(operationalDataItem);

      return operationalDataItem;
    }));
  }

  private createOperationalDataCategoryItem(model: OperationalDataModel, specificationVersionId: number): OperationalDataCategoryItem {
    const operationalDataItem = new OperationalDataCategoryItem(this.locationService, this.router, this.activatedRoute);
    operationalDataItem.operationalDataService = this;
    operationalDataItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
    operationalDataItem.categorySaveService = this.categorySaveService;
    operationalDataItem.model = model;
    operationalDataItem.specificationVersionId = specificationVersionId;

    return operationalDataItem;
  }
}
