import { Injectable } from '@angular/core';
import { TerminationType } from 'app/app-model/diagnostic-service/length.model';
import { ScaniaEncodingType } from 'app/app-model/diagnostic-service/parameter.scaling.model';
import { ValueConstraint } from 'app/app-model/diagnostic-service/value.constraint.model';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { ParameterType } from 'app/modules/shared/model/service/parameters/parameter-type';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { ValueConstraintData } from 'app/modules/shared/model/service/parameters/value-constraint-data';

@Injectable({
    providedIn: 'root'
})
export class ParameterService {
    private numberPattern = '^\\d([,.]?\\d+)?$';
    private hexPattern = '^0[xX][0-9a-fA-F]+$';

    public hasNumericalDataType(parameter: ParameterData): boolean {
        if (parameter.value != null) {
            return parameter.value.dataType !== OdxDataType.AsciiString &&
                parameter.value.dataType !== OdxDataType.ByteField;
        }
        return false;
    }

    public isNumericInput(inputTocCheck: string): boolean {
        const isMatch = inputTocCheck.match(this.numberPattern);
        const isNumericVal = isMatch && isMatch.length !== 0;

        return isNumericVal;
    }

    public isHexInput(inputToCheck: string): boolean {
        const isMatch = inputToCheck.match(this.hexPattern);
        const isHexVal = isMatch && isMatch.length !== 0;

        return isHexVal;
    }

    public getParameterTypes(): string[] {
        const keys = Object.keys(ParameterType);
        const slicedKeys = keys.slice(keys.length / 2);

        return slicedKeys;
    }

    public getPhysicalValueDataTypes(): string[] {
        const keys = Object.keys(OdxDataType);
        const slicedKeys = keys.slice(keys.length / 2);

        return slicedKeys;
    }

    public getInternalValueDataTypes(): string[] {
        const keys = Object.keys(OdxDataType);
        const slicedKeys = keys.slice(keys.length / 2);

        return slicedKeys;
    }

    public getEncodings(): string[] {
        const keys = Object.keys(ScaniaEncodingType);
        const slicedKeys = keys.slice(keys.length / 2);

        return slicedKeys;
    }

    public createNewValueConstraint(dataType: OdxDataType): ValueConstraintData {
        const constraintModel = new ValueConstraint();
        constraintModel.id = 0;
        constraintModel.items = [];

        const constraintData = new ValueConstraintData(constraintModel);
        constraintData.addDefaultValidRange(dataType);
        constraintData.addNewItem(dataType);

        return constraintData;
    }

    public getLenghtTerminations(currentTermination: TerminationType): string[] {
        const keys = Object.keys(TerminationType);
        const slicedKeys = keys.slice(keys.length / 2);
        const filteredKeys = [];
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < slicedKeys.length; i++) {
            if (!slicedKeys[i].includes('ValueZeroOrFf')) {
                filteredKeys.push(slicedKeys[i]);
            } else if (currentTermination === TerminationType.ValueZeroOrFf) {
                filteredKeys.push(slicedKeys[i]);
            }
        }

        return filteredKeys;
    }
}
