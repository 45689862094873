import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../shared/services/message-service.service';

@Component({
  selector: 'app-authorization-failed',
  templateUrl: './authorization-failed.component.html',
  styleUrls: ['./authorization-failed.component.css']
})
export class AuthorizationFailedComponent implements OnInit {

  constructor(private messageService: MessageService) { }

  ngOnInit() {
    this.messageService.dispatchErrorMessage('Failed to authorize!');
  }
}
