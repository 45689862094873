import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { SpecificationVersionInfo } from '../specification-version-info';
import { EcuResetDelayType } from '../enums';

export class EcuResetModel {
  id: number;
  name: string;
  delayType: EcuResetDelayType;
  resetDelay: number;
  resetSequence: ServiceExecutionSequenceModel;
  specificationVersion: SpecificationVersionInfo;
}
