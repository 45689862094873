import { Injectable } from '@angular/core';
import { SpecificationStatusHubService } from 'app/app-services/hubs/specification-status-hub.service';
import { OdxService } from 'app/app-services/odx-service';
import { SpecificationService } from 'app/app-services/specification-service';

import { OdxImportViewModel } from '../modules/shared/components/odx-import-view/odx-import-view-model';
import { MessageService } from '../modules/shared/services/message-service.service';

@Injectable({
    providedIn: 'root'
})
export class OdxImportViewService {
    model: OdxImportViewModel;

    constructor(private messageService: MessageService,
        private odxService: OdxService,
        private specificationService: SpecificationService,
        private specificationStatusHubService: SpecificationStatusHubService) {
        this.model = new OdxImportViewModel();
        this.model.messageService = messageService;
        this.model.odxService = odxService;
        this.model.specificationStatusHubService = specificationStatusHubService;
        this.model.specificationService = specificationService;
    }
}
