<div class="flex flex-wrap bg-gray-50 pr-10 pl-10 pt-16 pb-16 ml-16 mb-10">
  <span class="border-r-2">
    <a class="ml-5 mr-5 font-light active:text-gray-950 visited:text-gray-950 focus:text-gray-950 hover:text-gray-950" routerLinkActive="active-link"
      [routerLinkActiveOptions]="{exact: true}"
      [routerLink]="['/specification', currentSpecification.id, currentSpecificationVersion.id]">
      Home</a>
  </span>
  <span class="border-r-2" *ngFor="let category of enabledCategoriesForCurrentVersion">
    <a class="ml-5 mr-7 font-light active:text-gray-950 visited:text-gray-950 focus:text-gray-950 hover:text-gray-950"
        routerLinkActive="active-link"
        [routerLink]="['/specification', currentSpecification.id, currentSpecificationVersion.id, category.id]">{{category.name}}</a>
  </span>

  <div class="flex ml-5">
    <span>
      <a class="mr-5 font-light active:text-gray-950 visited:text-gray-950 focus:text-gray-950 hover:text-gray-950"
        [ngClass]="{'loading': validationRunning}"
        routerLinkActive="active-link"
        [routerLink]="['/specification', currentSpecification.id, currentSpecificationVersion.id, 'issues']">
        Issues
      </a>
    </span>
    <span>
      <sdds-badges class="warning" *ngIf="currentSpecificationVersionWarnings > 0 && currentSpecificationVersionErrors === 0" value={{currentSpecificationVersionWarnings}}></sdds-badges>
      <sdds-badges class="danger" *ngIf="currentSpecificationVersionErrors > 0" value="{{currentSpecificationVersionWarnings + currentSpecificationVersionErrors}}"></sdds-badges>
      <sdds-badges class="success" *ngIf="currentSpecificationVersionWarnings === 0 && currentSpecificationVersionErrors === 0" value="0"></sdds-badges>
    </span>
  </div>
</div>
