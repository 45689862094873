import { ApiProxyService } from 'app/modules/shared/services/api-proxy.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Observer, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';


export abstract class CategoryServiceBase<T, S> {

  hasCachedItems: boolean;
  // isPending: boolean;
  items: Array<S> = new Array<S>();
  getItemsPreviewDoneSubscription: Subscription;
  getItemsPreviewDone: BehaviorSubject<boolean> = new BehaviorSubject(true);
  apiCategoryName = '';

  
  private _isPending : boolean;
  public get isPending() : boolean {
    return this._isPending;
  }
  public set isPending(v : boolean) {
    this._isPending = v;
  }
  
  constructor(protected apiProxy: ApiProxyService) { }

  unsubscribe(): void {
    if (this.getItemsPreviewDoneSubscription) {
      this.getItemsPreviewDoneSubscription.unsubscribe();
    }
  }

  public getItem(specificationVersionId: number, itemId?: string): Observable<S> {
    return new Observable((observer: Observer<S>) => {
      this.getItemsPreviewDone.subscribe({
        next: (pending) => this.whenItemsPreviewIsDone(pending, itemId, observer, specificationVersionId)
      });
    });
  }

  public findCachedItem(itemId: string): any {
    return this.items.find(item => ((item as any).model.name === itemId) || (item as any).model.resourceId === itemId);
  }

  public findCachedItemById(itemId: number): any {
    return this.items.find(item => (item as any).model.id === itemId);
  }

  public reset() {
    this.hasCachedItems = false;
    this.isPending = false;
    this.items = [];
  }

  public removeCachedItem(removedItemName: string) {
    const itemToRemoveFromCacheIndex = this.items.findIndex(item => (item as any).model.name === removedItemName);

    if (itemToRemoveFromCacheIndex >= 0) {
      this.items.splice(itemToRemoveFromCacheIndex, 1);
    }
  }

  public removeCachedItemById(itemid: number): void {
    const itemToRemoveFromCacheIndex = this.items.findIndex(item => (item as any).model.id === itemid);

    if (itemToRemoveFromCacheIndex >= 0) {
      this.items.splice(itemToRemoveFromCacheIndex, 1);
    }
  }

  protected setCategoryItemModelFromApi(itemName: string, categoryItem: any, specificationVersionId: number): Observable<void> {
    return this.apiProxy.get<T>(`${environment.apiUrl}versions/${specificationVersionId}/${this.apiCategoryName}/${itemName}`).pipe(map(categoryItemModel => {
      categoryItem.isPreview = false;
      categoryItem.model = categoryItemModel;
    }));
  }

  private whenItemsPreviewIsDone(pending: boolean, itemId: string, observer: Observer<S>, specificationVersionId: number) {
    if (!pending) {
      const cachedItem = this.findCachedItem(itemId);

      if (cachedItem && !cachedItem.isPreview) {
        observer.next(cachedItem);
        observer.complete();
      } else if (cachedItem && cachedItem.isPreview) {
        this.setCategoryItemModelFromApi(itemId, cachedItem, specificationVersionId).subscribe({
          next: (arg) => {
            observer.next(cachedItem);
            observer.complete();
          }
        });
      }
    }
  }

  abstract getCategoryItemModel(specificationVersionId: number, name: string): Observable<S>;

  abstract getItemsPreview(specificationVersionId: number): Observable<S[]>;

  abstract getCompleteItems(specificationVersionId: number): Observable<S[]>;

  abstract createItem(specificationVersionId: number, itemName?: string, itemId?: number): Observable<S>;

  abstract deleteItem(specificationVersionId: number, itemName?: string, itemId?: number): Observable<any>;

  abstract updateItem(specificationVersionId: number, itemModel: T, itemName?: string, itemId?: number): Observable<T>;
}
