import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appOpenSpec]'
})
export class OpenSpecDirective {

  constructor(elementRef: ElementRef) {
    elementRef.nativeElement.style.backgroundColor = 'blue';
  }
}
