import { FunctionBase } from './function-base';
import { FunctionType } from './functions';
import { BaseProperty, PropertyType } from 'app/modules/shared/model/properties/base-property';

export class FunctionReset extends FunctionBase {
    constructor(enabled: boolean, properties: Array<BaseProperty>) {
        super(enabled, properties);
        this.name = 'Reset';
        this.propertyType = PropertyType.Target;
        this.type = FunctionType.Reset;
    }

    addProperty(propertyName: string): void {
        /**
         * Reset functions doesn't have any properties.
         */
    }

    syncFromModel(): void {
        /**
         * Nothing to sync
         */
    }
}
