/* eslint-disable no-shadow */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Specification } from 'app/app-model/specification';
import { StructureEcuFamily } from 'app/app-model/structure/structure-ecu-family';
import { CategoryItemsService } from 'app/app-services/category-items-service';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { StructureService } from 'app/app-services/structure-service';
import { UserSettingsService } from 'app/app-services/user-settings.service';
import { Subscription } from 'rxjs';

import { SpecificationVersion } from '../app-model/specification-version';
import { StructureSpecification } from '../app-model/structure/structure-specification';
import { StructureSpecificationTreeNode } from '../modules/shared/components/tree/model/structure-specification-tree-node';
import { Tree } from '../modules/shared/components/tree/model/tree';
import { TreeNode } from '../modules/shared/components/tree/model/tree-node';
import { TreeService } from '../modules/shared/components/tree/service/tree.service';
import { MessageService } from '../modules/shared/services/message-service.service';
import { tap } from 'rxjs/operators';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-specification-browser',
  templateUrl: './specification-browser.component.html',
  styleUrls: ['./specification-browser.component.css']
})
export class SpecificationBrowserComponent implements OnInit, OnDestroy {
  ecuFamilies: StructureEcuFamily[];
  ecuFamilytree: Tree;
  selectedSpecification: StructureSpecification;
  getStructureSubscription: Subscription;
  selectedSpecificationSubscriber: Subscription;
  selectedVersion: SpecificationVersion;
  loading: boolean;
  specificationVersions: StructureSpecificationTreeNode[];

  constructor(private structureService: StructureService, private specificationService: SpecificationService, private router: Router,
    private userSettingsService: UserSettingsService, private messageService: MessageService,
    private categoryItemsService: CategoryItemsService, private dataCategoriesService: DataCategoriesService,private authService:AuthService) {
  }

  ngOnInit() {
    this.selectedSpecificationSubscriber = this.specificationService.selectedSpecificationEventEmitter.subscribe({
      next: (spec) => {
        this.selectCurrentSpecificationTreeNodeOrDefault();
      }
    });
    this.loading = true;
    this.getStructure();
  }

  ngOnDestroy(): void {
    if (this.getStructureSubscription) {
      this.getStructureSubscription.unsubscribe();
    }

    if (this.selectedSpecificationSubscriber) {
      this.selectedSpecificationSubscriber.unsubscribe();
    }
  }

  getStructure() {
    this.structureService.getStructure().subscribe(structure => {
      this.ecuFamilies = structure;
      this.buildFamilyTree(this.ecuFamilies);
      this.getStructureSubscription = this.ecuFamilytree.selectedTreeNodeEventEmitter.subscribe(treeNode => {
        this.whenTreeNodeSelected(treeNode);
      }, e => {
      });

      this.loading = false;
    }, e => {
      this.loading = false;
      this.messageService.dispatchErrorMessageFromApi(e);
    });
  }

  buildFamilyTree(ecuFamilies: StructureEcuFamily[]) {

    const treeService = new TreeService();
    const tree = new Tree(treeService);

    ecuFamilies.forEach(ef => {
      const efTreeNode = new TreeNode(ef.name, treeService);
      tree.addRootNode(efTreeNode);

      ef.generations.forEach(eg => {
        const egTreeNode = new TreeNode(eg.name, treeService);
        efTreeNode.addChild(egTreeNode);

        eg.specifications.forEach(structureSpecification => {
          const pTreeNode = this.createStructureSpecificationNode(structureSpecification, treeService);
          pTreeNode.isEditable = true;
          pTreeNode.editAction = this.getEditSpecificationTreeNodeAction(tree);
          egTreeNode.addChild(pTreeNode);
        });
      });
    });

    this.ecuFamilytree = tree;
    this.selectCurrentSpecificationTreeNodeOrDefault();
  }

  selectCurrentSpecificationTreeNodeOrDefault() {
    if (this.ecuFamilytree) {
      if (this.specificationService.currentSpecification) {
        this.ecuFamilytree.selectFirstTreeNodeThatMatchesPredicate(predicate => predicate.name === this.specificationService.currentSpecification.name, false);
      } else {
        this.ecuFamilytree.selectFirstNode();
      }
    }
  }

  filter(text: string): void {
    if (this.ecuFamilytree) {
      this.ecuFamilytree.showTreeNodesThatContainsText(text);
    }
  }

  collapseTree() {
    if (this.ecuFamilytree) {
      this.ecuFamilytree.collapseEntireTree();
    }
  }

  expandTree() {
    if (this.ecuFamilytree) {
      this.ecuFamilytree.expandEntireTree();
    }
  }

  onSpecificationCreated() {
    console.log('Specification created!');
    this.getStructure();
  }

  private createStructureSpecificationNode(structureSpecification: StructureSpecification, treeService: TreeService): StructureSpecificationTreeNode {
    return new StructureSpecificationTreeNode(structureSpecification, treeService, this.getRemoveSpecAction(), this.getOpenSpecAction());
  }

  private getOpenSpecAction(): (p: StructureSpecification) => void {
    return specToOpen => {
      const latestSpecificationVersionFilter = specToOpen.specificationVersions.filter(specVersion =>
        +specVersion.name === Math.max(...specToOpen.specificationVersions.map(version => +version.name)));

      if (latestSpecificationVersionFilter && latestSpecificationVersionFilter.length > 0) {
        const latestSpecificationVersion = latestSpecificationVersionFilter[0];
        this.specificationService.setCurrentSpecification(specToOpen, latestSpecificationVersion);
        this.dataCategoriesService.resetCategories(true);
        this.categoryItemsService.resetCategories();
        this.specificationService.getSpecification(specToOpen.id).subscribe({
          next: (specification) => {
            this.router.navigate(['/specification', specification.id, latestSpecificationVersion.id]);
          }
        });
      }
    };
  }

  private getRemoveSpecAction(): (p: StructureSpecification) => void {
    return specToDelete => {
      this.specificationService.deleteSpecification(specToDelete).pipe(tap(removedSpec => {
        if (this.specificationService.currentSpecification && removedSpec.id === this.specificationService.currentSpecification.id) {
          this.specificationService.setCurrentSpecification(undefined, undefined);
        }
        this.router.navigate(['/content']);
      })).subscribe();
    };
  }

  private loadSpecification(specificationToEdit: StructureSpecification,
    newName: string,
    oldName: string,
    editTreeNode: StructureSpecificationTreeNode,
    treeNode: TreeNode,
    tree: Tree) {
    this.specificationService.getSpecification(specificationToEdit.id).subscribe(latestSpecification => {
      latestSpecification.name = newName;
      this.specificationService.updateSpecification(latestSpecification).subscribe(updatedSpecification => {
        this.whenSpecificationUpdated(updatedSpecification, editTreeNode, specificationToEdit, tree);
      },
        e => {
          treeNode.name = oldName;
          this.messageService.dispatchErrorMessageFromApi(e);
        });
    }, e => {
      treeNode.name = oldName;
      this.messageService.dispatchErrorMessageFromApi(e);
    });
  }

  private getStructureSpecificationNodeSelectionPredicate(): (TreeNode: any) => boolean {
    return (node) => {
      if (node instanceof StructureSpecificationTreeNode && node.specification.id === this.userSettingsService.startComponentSettings.selectedSpecification) {
        return true;
      }
      return false;
    };
  }

  private getEditSpecificationTreeNodeAction(tree: Tree): (treeNode: TreeNode, newName: string) => void {
    return (treeNode: TreeNode, newName: string) => {
      if (treeNode instanceof StructureSpecificationTreeNode === false) {
        return;
      }

      const editTreeNode = treeNode as StructureSpecificationTreeNode;
      const oldName = treeNode.name;
      treeNode.name = newName;

      const specificationToEdit = editTreeNode.specification;
      this.loadSpecification(specificationToEdit, newName, oldName, editTreeNode, treeNode, tree);
    };
  }

  private whenSpecificationUpdated(updatedSpecification: Specification,
    editTreeNode: StructureSpecificationTreeNode,
    specificationToEdit: StructureSpecification,
    tree: Tree) {
    if (this.specificationService.currentSpecification) {
      this.specificationService.currentSpecification.name = updatedSpecification.name;
    }

    if (editTreeNode.isSelected) {
      this.selectedSpecification = updatedSpecification;
    }

    editTreeNode.specification = updatedSpecification;
    tree.updateTreeNodes((treeNodeToUpdate => {
      if (treeNodeToUpdate instanceof StructureSpecificationTreeNode) {
        if ((treeNodeToUpdate as StructureSpecificationTreeNode).specification.id === specificationToEdit.id) {
          (treeNodeToUpdate as StructureSpecificationTreeNode).specification = updatedSpecification;
          (treeNodeToUpdate as StructureSpecificationTreeNode).name = updatedSpecification.name;
        }
      }
    }));
  }

  private whenTreeNodeSelected(treeNode: TreeNode) {
    if (treeNode instanceof StructureSpecificationTreeNode) {

      if (this.selectedSpecification !== treeNode.specification) {
        this.selectedSpecification = treeNode.specification;
        this.selectedVersion = null;

        this.userSettingsService.startComponentSettings.selectedSpecification = this.selectedSpecification.id;
      }
    } else {
      this.selectedSpecification = null;
    }
  }
  canCreateDeleteSpecification(){
    return this.authService.canCreateDeleteSpecification();
  }
}
