/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ScommFile,
  ScommFileDraft,
  ScommFileDraftResult,
  ScommFileValidationResult,
  SharedFile,
} from '../app-model/legacy-file';
import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { SpecificationVersionHubService } from './hubs/specification-version-hub.service';

@Injectable({
  providedIn: 'root'
})
export class SpecificationLegacyFiles {
  public toggleMergeMode: Subject<void> = new Subject();

  constructor(private apiProxy: ApiProxyService,
    private specificationVersionHubService: SpecificationVersionHubService,) {
  }

  public getSpecificationScommFiles(specificationVersionId: number): Observable<ScommFile[]> {
    return this.apiProxy.get<ScommFile[]>(environment.apiUrl + 'scommfiles?specificationVersionId=' + specificationVersionId).pipe(map(scommFiles => {
      const convertedFiles = [];
      scommFiles.forEach(scommFile => {
        convertedFiles.push(this.decodeScommFile(scommFile));
      });

      return convertedFiles;
    }));
  }

  public getSpecificationScommFilesWithSharedFileRefs(specificationVersionId: number): Observable<ScommFile[]> {
    return this.getSpecificationScommFiles(specificationVersionId).pipe(map(files => {
      if (files && files.length > 0) {
        const filesWithSharedFileSpecificationRef: ScommFile[] = [];
        files.forEach(file => {
          if (file.sharedFileId !== null) {
            filesWithSharedFileSpecificationRef.push(file);
          }
        });

        return filesWithSharedFileSpecificationRef;
      }
    }));
  }

  public getSpecificationScommFile(fileId: number): Observable<ScommFile> {
    return this.apiProxy.get<ScommFile>(environment.apiUrl + 'scommfiles/' + fileId).pipe(
      map(scommFile => this.decodeScommFile(scommFile))
    );
  }

  public getAllServerFilesForSpecificationVersions(specificationVersionIds: number[], preview = false): Observable<ScommFile[]> {

    const serverFilesObservables = new Array<Observable<ScommFile>>();

    specificationVersionIds.forEach(specificationVersionId => {
      serverFilesObservables.push(this.apiProxy.get<ScommFile>(environment.apiUrl + 'scommfiles/legacyServerFile/' + specificationVersionId + '/' + preview));
    });

    return forkJoin(serverFilesObservables);
  }

  public saveSpecificationScommFile(file: ScommFile): Observable<ScommFile> {
    return this.apiProxy.post<ScommFile>(environment.apiUrl + 'scommfiles/', file).pipe(map(updatedFile => {
      updatedFile.data = atob(updatedFile.data);
      return updatedFile;
    }));
  }

  public updateSpecificationScommFile(file: ScommFile): Observable<ScommFile> {
    file.data = btoa(file.data);
    return this.apiProxy.put<ScommFile>(environment.apiUrl + 'scommfiles/', file).pipe(map(updatedFile => {
      updatedFile.data = atob(updatedFile.data);
      return updatedFile;
    }));
  }

  public assignSharedFiles(specificationVersionId: number, sharedFiles: SharedFile[]): Observable<SharedFile[]> {
    return this.apiProxy.post<SharedFile[]>(environment.apiUrl + 'scommfiles/specificationId/' + specificationVersionId + '/sharedFiles', sharedFiles)
      .pipe(map(assignedSharedFile => assignedSharedFile));
  }

  public deleteSpecificationScommFile(fileId: number): Observable<any> {
    return this.apiProxy.delete(environment.apiUrl + 'scommfiles/' + fileId);
  }

  public validateScommFile(file: ScommFile): Observable<ScommFileValidationResult> {
    return this.apiProxy.post<ScommFile, ScommFileValidationResult>(environment.apiUrl + 'scommfiles/validate', file);
  }

  public getUserDraft(serverFileId: number, userName: string): Observable<ScommFileDraftResult> {
    return this.apiProxy.get<ScommFileDraftResult>(environment.apiUrl + 'scommfiles/' + serverFileId + '/userdraft/' + userName)
      .pipe(map(draftResult => {
        draftResult.draft = this.decodeScommFileDraft(draftResult.draft);
        return draftResult;
      }));
  }

  public getScommFileDrafts(serverFileId: number, preview = false): Observable<ScommFileDraftResult[]> {
    return this.apiProxy.get<ScommFileDraftResult[]>(environment.apiUrl + 'scommfiles/' + serverFileId + '/drafts/' + preview)
      .pipe(map(draftResults => {
        if (draftResults && draftResults.length > 0) {
          draftResults.forEach(draftResult => {
            draftResult.draft = this.decodeScommFileDraft(draftResult.draft);
          });

          return draftResults;
        }
      }));
  }

  public updateFileDraft(specificationVersionId: number, userName: string, scommFileDraft: ScommFileDraft) {
    scommFileDraft.data = btoa(scommFileDraft.data);

    return this.apiProxy.put<ScommFileDraft, ScommFileDraftResult>(environment.apiUrl + 'scommfiles/' + specificationVersionId + '/drafts/' + userName, scommFileDraft)
      .pipe(map(fileDraftResult => {
        fileDraftResult.draft = this.decodeScommFileDraft(fileDraftResult.draft);
        return fileDraftResult;
      }));
  }

  public deleteFileDraft(legacyXmlFileid: number, userName: string): Observable<any> {
    return this.apiProxy.delete(environment.apiUrl + 'scommfiles/' + legacyXmlFileid + '/drafts/' + userName);
  }

  private decodeScommFile(scommFile: ScommFile): ScommFile {
    const convertedFile = new ScommFile();
    convertedFile.fileType = scommFile.fileType;
    convertedFile.id = scommFile.id;
    convertedFile.name = scommFile.name;
    convertedFile.sharedFileId = scommFile.sharedFileId;
    convertedFile.specificationVersionId = scommFile.specificationVersionId;
    convertedFile.data = atob(scommFile.data);
    convertedFile.creationDate = scommFile.creationDate;
    convertedFile.modificationDate = scommFile.modificationDate;
    convertedFile.modifiedBy = scommFile.modifiedBy;

    return convertedFile;
  }

  private decodeScommFileDraft(scommFileDraft: ScommFileDraft): ScommFileDraft {
    const convertedFileDraft = new ScommFileDraft();
    convertedFileDraft.fileType = scommFileDraft.fileType;
    convertedFileDraft.id = scommFileDraft.id;
    convertedFileDraft.name = scommFileDraft.name;
    convertedFileDraft.data = atob(scommFileDraft.data);
    convertedFileDraft.user = scommFileDraft.user;
    convertedFileDraft.created = scommFileDraft.created;
    convertedFileDraft.modified = scommFileDraft.modified;

    return convertedFileDraft;
  }
}
