<app-dialog-base title="Add shared file to version" modalId="createSharedFileVersionModal" [itemTemplate]="createSharedFileVersionTemplate"
  [cancelEnabled]="!loading || importError" [applyEnabled]="!loading && fileVersionToCreate" (modalApply)="apply()" (modelCancel)="cancel()" applyCaption="Create"></app-dialog-base>

<ng-template #createSharedFileVersionTemplate>

  <label class="sdds-btn sdds-btn-primary sdds-btn-sm">
    Upload new shared file
    <input class="hidden" type="file" (change)="fileChanged($event)" onclick="this.value = null">
  </label>

  <br />
  <label class="my-10" *ngIf="fileVersionToCreate && fileVersionToCreate.file">Click on "Create" to add {{fileVersionToCreate.file.name}} to shared files.</label>
</ng-template>
