<div class="sdds-card mb-16">
    <div class="sdds-card-header">
        <h5 class="sdds-card-headline" *ngIf="isInternalToPhysical">Internal To Physical Computational Method</h5>
        <h5 class="sdds-card-headline" *ngIf="!isInternalToPhysical">Physical To Internal Computational Method</h5>
    </div>

    <div class="sdds-card-body mt-16">
        <form [id]="'internalToPhysCompMethod_'+isInternalToPhysical">
            <app-editable-computational-method [isOdxBasedSpecification]="isOdxBasedSpecification"
                [computationalMethod]="computationalMethod" [internalDataType]="internalValueDataType"
                [physicalDataType]="physicalValueDataType" [isInternalToPhysical]="isInternalToPhysical"
                (compMethodChange)="parameterChanged.next($event)"
                [isAllowed]="isAllowed"></app-editable-computational-method>
        </form>
    </div>
</div>