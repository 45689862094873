<div class="sdds-card" style="background: #f2f5f8; margin-bottom:10px;">
  <div class="sdds-card-body" style="margin-top: 10px">
    <div *ngIf="substringComparisonEnabled"
      style="padding: 5px; border-style: solid; border-width:0px 0px 0px 2px; border-color: gray;">
      <b>Substring Comparison</b>
      <br />
      <span class="sdds-checkbox-item">
        <input class="sdds-form-input" name="cbxSubstringStartIndex" type="checkbox" id="withStartIndex"
          [(ngModel)]="withSubstringStartIndexChecked" [disabled]="isReadOnly||!isAllowed" />
        <label class="sdds-form-label" for="withStartIndex">
          With start index</label>
      </span>
      <span class="sdds-checkbox-item">
        <input class="sdds-form-input" name="cbxSubstringLength" type="checkbox" id="withLength"
          [(ngModel)]="withSubstringLengthChecked" [disabled]="isReadOnly||!isAllowed" />
        <label class="sdds-form-label" for="withLength">With length</label>
      </span>
    </div>
    <div style="margin-top: 15px;"></div>

    <div class="sdds-row">
      <div class="sdds-col-max-4" style="margin-left: 15px;">
        <span *ngIf="legacyModeEnabled" class="form-group">
          <app-id-code-field *ngIf="hexLength > 0" [hexCount]="hexLength"
            [hexCode]="expressionModel.legacyIdentifierCode" [isEditingAllowed]="!isReadOnly"
            (hexCodeChanged)="onIdentifierHexCodeChanged($event)" [isAllowed]="isAllowed"></app-id-code-field>
          <app-id-code-field *ngIf="!hexLength" [hexCount]="3" [hexCode]="expressionModel.legacyIdentifierCode"
            [isEditingAllowed]="!isReadOnly" (hexCodeChanged)="onIdentifierHexCodeChanged($event)"
            [isAllowed]="isAllowed">
          </app-id-code-field>
        </span>

        <span *ngIf="!legacyModeEnabled">
          <a class="btn btn-secondary btn-sm dropdown-toggle" *ngIf="!assignedEcuIdentifier" style="cursor: pointer"
            (click)="showIdentifierSelector()">Set identifier</a>
          <a *ngIf="assignedEcuIdentifier" style="cursor: pointer" (click)="showIdentifierSelector()">
            {{ assignedEcuIdentifier.name }} -
            {{
            expressionModel.ecuIdentifierPropertyToCompareWith.propertyName
            }}
          </a>
          <a *ngIf="assignedEcuIdentifier" style="cursor: pointer" title="Navigate"
            (click)="nagivateToEcuIdentifier(assignedEcuIdentifier.name)">
            <img src="font/iconsrc/link.png" style="width: 20px; margin-left: 5px;" />
          </a>
        </span>
      </div>

      <div class="sdds-col-max-1" *ngIf="!isReadOnly">
        <div>
          <button mat-raised-button [matMenuTriggerFor]="rangeNodeMenu" id="dropdownMenu1">{{
            getCompareOperatorSign(expressionModel.compareOperator) }}</button>
          <mat-menu #rangeNodeMenu="matMenu">
            <button mat-menu-item type="button" *ngIf="
                expressionModel.compareOperator ===
                  ServerIdentificationCompareOperatorType.LessThan ||
                expressionModel.compareOperator ===
                  ServerIdentificationCompareOperatorType.LessThanOrEqualTo"
              (click)="operatorType=ServerIdentificationCompareOperatorType.LessThan" [disabled]="!isAllowed">&lt; (Less
              than)</button>

            <button mat-menu-item type="button" *ngIf="
                expressionModel.compareOperator ===
                  ServerIdentificationCompareOperatorType.LessThanOrEqualTo ||
                expressionModel.compareOperator ===
                  ServerIdentificationCompareOperatorType.LessThan"
              (click)="operatorType =ServerIdentificationCompareOperatorType.LessThanOrEqualTo" [disabled]="!isAllowed">
              &#8804; (Less than or equal to)</button>

            <button mat-menu-item type="button" *ngIf="
              expressionModel.compareOperator === ServerIdentificationCompareOperatorType.GreaterThan ||
              expressionModel.compareOperator === ServerIdentificationCompareOperatorType.GreaterThanOrEqualTo"
              (click)="operatorType = ServerIdentificationCompareOperatorType.GreaterThan" [disabled]="!isAllowed">
              &gt; (Greater than)
            </button>

            <button mat-menu-item type="button" *ngIf="
              expressionModel.compareOperator === ServerIdentificationCompareOperatorType.GreaterThan ||
              expressionModel.compareOperator === ServerIdentificationCompareOperatorType.GreaterThanOrEqualTo"
              (click)="operatorType = ServerIdentificationCompareOperatorType.GreaterThanOrEqualTo"
              [disabled]="!isAllowed">
              &#8805; (Greater than or equal to)
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="sdds-col-max-1" *ngIf="isReadOnly">
        <span class="sdds-headline-07">
          {{ getCompareOperatorSign(expressionModel.compareOperator) }}
        </span>
      </div>

      <div class="sdds-col-max-3">
        <div *ngIf="legacyModeEnabled">
          <span style="margin-left: 10px; ">
            <sdds-textfield size="md" name="legacyValueControl" type="text" ngDefaultControl [(ngModel)]="compareValue"
              [disabled]="expressionModel.legacyIdentifierCode < 0 || isReadOnly||!isAllowed"
              (customChange)="notifyServerIdentificationChanged()">
            </sdds-textfield>
          </span>
        </div>

        <div *ngIf="!legacyModeEnabled">
          <span [ngClass]="{
              'has-error': !isValidValue,
              'has-success': isValidValue
            }">
            <sdds-textfield size="md" name="valueControl" type="text" ngDefaultControl [(ngModel)]="compareValue"
              [disabled]="!hasAssignedEcuIdentifier || isReadOnly||!isAllowed" (customChange)="onValueChanged()">
            </sdds-textfield>
          </span>

          <span *ngIf="assignedEcuIdentifier">
            ({{ assignedParameterValueTypeName }})
          </span>
        </div>
      </div>
      <div class="sdds-col-max sdds-col-xxlg sdds-col-xlg sdds-col-lg sdds-col-md sdds-col-sm sdds-col-xs">
        <span *ngIf="
            expressionModel.compareOperator !== ServerIdentificationCompareOperatorType.StartsWith">
          <app-substring-comparison-range [expressionModel]="expressionModel" [isReadOnly]="isReadOnly"
            [startIndexEnabled]="hasSubstringStartIndexEnabled || withSubstringStartIndexChecked"
            [lengthEnabled]="hasSubstringLengthEnabled || withSubstringLengthChecked"
            (substringComparisonChanged)="notifyServerIdentificationChanged()"
            [isAllowed]="isAllowed"></app-substring-comparison-range>
        </span>
      </div>
    </div>
    <div class="sdds-row">
      <div
        class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-5 sdds-col-sm-5 sdds-col-xs-5">
        <div style="margin-top: 10px;">
          <app-identifier-selector *ngIf="identifierSelectorEnabled" [expressionModel]="expressionModel"
            (notifyEcuIdentifierSelected)="onEcuIdentifierSelected($event)" (notifyCollapse)="showIdentifierSelector()"
            [isReadOnly]="isReadOnly" [isAllowed]="isAllowed"></app-identifier-selector>
        </div>
      </div>
    </div>
  </div>
</div>