import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';

import { IdentificationGroup } from '../app-model/identification-group/identification-group';
import { ServerIdentificationModel } from '../app-model/server-identification/server-identification.model';
import { ApiProxyService } from '../modules/shared/services/api-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class IdentificationGroupService {

  private cachedIdentificationGroups = new Map<number, { group: IdentificationGroup; isPreview: boolean }>();

  constructor(private apiProxy: ApiProxyService) { }

  getIdentificationGroups(preview: boolean = true): Observable<IdentificationGroup[]> {
    this.cachedIdentificationGroups.clear();

    return this.apiProxy.get<IdentificationGroup[]>(environment.apiUrl + `identificationGroup?preview=${preview}`)
      .pipe(
        tap(groups => this.addGroupsToCache(groups, preview)),
        map(() => Array.from(this.cachedIdentificationGroups.values()).map(cachedGroup => cachedGroup.group))
      );
  }

  getIdentificationGroup(identificationGroupId: number): Observable<IdentificationGroup> {
    const cachedIdentificationGroup = this.getCachedItem(identificationGroupId);

    if (!cachedIdentificationGroup.isPreview) {
      return of(cachedIdentificationGroup.group);
    } else {
      return this.apiProxy.get<IdentificationGroup>(environment.apiUrl + `identificationgroup/${identificationGroupId}`)
        .pipe(tap(group => this.addGroupToCache(group, false)),
          map(group => this.getCachedItem(group.id).group));
    }
  }

  getServerIdentificationId(identificationExpressionId: number): Observable<number> {
    return this.apiProxy.get<number>(environment.apiUrl + `identificationgroup/getServerId/${identificationExpressionId}`);
  }

  createIdentificationGroup(identificationGroup: IdentificationGroup): Observable<IdentificationGroup> {
    return this.apiProxy.post(environment.apiUrl + 'identificationGroup', identificationGroup)
      .pipe(tap(group => this.addGroupToCache(group, false)),
        map(group => this.getCachedItem(group.id).group));
  }

  updateIdentificationGroup(identificationGroup: IdentificationGroup): Observable<IdentificationGroup> {
    return this.apiProxy.put<IdentificationGroup>(environment.apiUrl + 'identificationGroup/' + identificationGroup.id, identificationGroup)
      .pipe(tap(group => this.addGroupToCache(group, false)),
        map(group => this.getCachedItem(group.id).group));
  }

  deleteIdentificationGroup(identificationGroup: IdentificationGroup): Observable<IdentificationGroup> {
    return this.apiProxy.delete<IdentificationGroup>(environment.apiUrl + 'identificationGroup/' + identificationGroup.id)
      .pipe(tap(group => this.removeGroupFromCache(group.id)));
  }

  addServerIdentificationToGroup(identificationGroupId: number, serverIdentification: ServerIdentificationModel): Observable<ServerIdentificationModel> {
    return this.apiProxy.post(environment.apiUrl + 'identificationGroup/' + identificationGroupId + '/servers', serverIdentification);
  }

  updateServerIdentificationsForGroup(identificationGroup: IdentificationGroup): Observable<IdentificationGroup> {
    return this.apiProxy.put(environment.apiUrl + 'identificationGroup/' + identificationGroup.id + '/servers', identificationGroup);
  }

  deleteServerIdentificationFromGroup(identificationGroup: IdentificationGroup, serverIdentification: ServerIdentificationModel): Observable<IdentificationGroup> {
    return this.apiProxy.delete(environment.apiUrl + 'identificationGroup/' + identificationGroup.id + '/servers/' + serverIdentification.id);
  }

  updateIndices(items: IdentificationGroup[]): Observable<IdentificationGroup[]> {
    const indicesToUpdate = items.map((x) => ({ id: x.id, index: x.index }) as { id: number; index: number });

    return this.apiProxy.put(environment.apiUrl + 'identificationGroup/updateIndices', indicesToUpdate);
  }

  getCachedIdentificationGroups(): IdentificationGroup[] {
    return Array.from(this.cachedIdentificationGroups.values()).map(group => group.group);
  }

  private addGroupsToCache(groups: IdentificationGroup[], preview: boolean): void {
    if (!groups) {
      return;
    } else {
      groups.forEach(group => {
        this.addGroupToCache(group, preview);
      });
    }
  }

  private addGroupToCache(group: IdentificationGroup, preview: boolean) {
    this.cachedIdentificationGroups.set(group.id, { group, isPreview: preview });
  }

  private removeGroupFromCache(groupId: number) {
    this.cachedIdentificationGroups.delete(groupId);
  }

  private getCachedItem(identificationGroupId: number): { group: IdentificationGroup; isPreview: boolean } {
    return this.cachedIdentificationGroups.get(identificationGroupId);
  }
}
