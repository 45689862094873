import {
  RequestParameterValueType,
  ServiceExecutionRequestValue,
} from 'app/app-model/diagnostic-service/sequence-execution-request-parameter-data.model';
import { OdxDataType, TypedValueData } from 'app/modules/shared/model/service/parameters/typed-value-data';

import { BaseProperty } from './base-property';

export class InputProperty extends BaseProperty {
  /** Links this property to the specified parameter. */
  linkAssignedParameter() {
    if (!this.serviceExecutionSequence) {
      return;
    }

    if (this.previousSelectedProperty) {
      this.unlinkPreviousSelectedProperty();
    }

    const selectedPropertyServiceExecution = this.serviceExecutionSequence.serviceExecutions.find(serviceExec => serviceExec.hasPropertyParameter(this.selectedPropertyParameter));

    const parameterToEdit = selectedPropertyServiceExecution.model.requestParameters.find(requestParam =>
      requestParam.path.find(reqParam => reqParam.step === this.selectedPropertyParameter.parameter.name) !== undefined);

    const value = new TypedValueData();
    value.dataType = OdxDataType[this.selectedPropertyParameter.parameter.getPhysicalDataType()];
    value.data = this.name;

    parameterToEdit.value = value;
    parameterToEdit.valueType = RequestParameterValueType.ContextItem;

    this.selectedPropertyParameter.usedByProperties = [this.name];
    this.selectedPropertyParameter.assignedValue.data = '';
    this.selectedPropertyParameter.owner = this;
  }

  unlink(): void {
    if (!this.serviceExecutionSequence) {
      return;
    }

    const serviceExecutionToEdit = this.serviceExecutionSequence.serviceExecutions.find(serviceExec => serviceExec.hasPropertyParameter(this.selectedPropertyParameter));

    const parameterToReset = serviceExecutionToEdit.model.requestParameters.find(requestParam =>
      requestParam.path.find(reqParam => reqParam.step === this.selectedPropertyParameter.parameter.name) !== undefined);

    parameterToReset.value = undefined;
    parameterToReset.valueType = RequestParameterValueType.Literal;

    this.selectedPropertyParameter.assignedValue.data = '';
    this.selectedPropertyParameter.usedByProperties = [];
    this.selectedPropertyParameter.owner = undefined;
    this.selectedPropertyParameter = undefined;
  }

  unlinkSilent() {
    if (!this.serviceExecutionSequence) {
      return;
    }
    this.notifyIfPropertyChanges = false;

    const serviceExecutionToEdit = this.serviceExecutionSequence.serviceExecutions.find(serviceExec => serviceExec.hasPropertyParameter(this.selectedPropertyParameter));

    const parameterToReset = serviceExecutionToEdit.model.requestParameters.find(requestParam =>
      requestParam.path.find(reqParam => reqParam.step === this.selectedPropertyParameter.parameter.name) !== undefined);

    parameterToReset.value = undefined;
    parameterToReset.valueType = RequestParameterValueType.Literal;

    this.selectedPropertyParameter.assignedValue.data = '';
    this.selectedPropertyParameter.usedByProperties = [];
    this.selectedPropertyParameter.owner = undefined;
    this.selectedPropertyParameter = undefined;

    this.notifyIfPropertyChanges = true;
  }

  syncFromModel(): void {
    const matchingParameter = this.findRequestParameterByVariableName(this.name);

    if (!matchingParameter) {
      return;
    }
    this.selectedPropertyParameter = this.serviceExecutionSequence.getPropertyParameterByName(matchingParameter.path[0].step);

    if (this.selectedPropertyParameter) {
      this.selectedPropertyParameter.usedByProperties = [this.name];
    }
  }

  syncFromModelSilent(): void {
    const matchingParameter = this.findRequestParameterByVariableName(this.name);

    if (!matchingParameter) {
      return;
    }

    this.setSelectedPropertyParameterSilent(this.serviceExecutionSequence.getPropertyParameterByName(matchingParameter.path[0].step));

    if (this.selectedPropertyParameter) {
      this.selectedPropertyParameter.usedByProperties = [this.name];
    }
  }

  unlinkPreviousSelectedProperty() {
    const previousValue = this.findRequestParameterByParameterName(this.previousSelectedProperty.parameter.name);
    previousValue.value = undefined;
    previousValue.valueType = RequestParameterValueType.Literal;

    const propToRemoveIndex = this.previousSelectedProperty.usedByProperties.findIndex(prop => prop === this.name);

    if (propToRemoveIndex > -1) {
      this.previousSelectedProperty.usedByProperties.splice(propToRemoveIndex, 1);
    }
  }

  reset(): void {
  }

  private findRequestParameterByParameterName(parameterName: string): ServiceExecutionRequestValue {
    let matchingParameter: ServiceExecutionRequestValue;

    this.serviceExecutionSequence.serviceExecutions.forEach(serviceExec => {
      const param = serviceExec.model.requestParameters.find(requestParam => requestParam.path[0].step === parameterName);

      if (param) {
        matchingParameter = param;
        return;
      }
    });

    return matchingParameter;
  }

  private findRequestParameterByVariableName(variableName: string): ServiceExecutionRequestValue {
    let matchingParameter: ServiceExecutionRequestValue;

    this.serviceExecutionSequence.serviceExecutions.forEach(serviceExec => {
      const param = serviceExec.model.requestParameters.find(requestParam => requestParam.value && requestParam.value.data === variableName);

      if (param) {
        matchingParameter = param;
        return;
      }
    });

    return matchingParameter;
  }
}
