import { DiagnosticServiceProvider } from '../app-services/diagnostic.service.service';

export class CategoryItem {
    resourceId: string;
    name: string;
    displayName: string;
    key: string;          //TODO: What is this used for?
    id: number;           //TODO: start using the resourceId instead
    isPreview = true;
    hasSyncDoneSubscriptions = false;
    hasModificationSubscriptions = false;
    diagnosticServiceProvider: DiagnosticServiceProvider;
    categoryType: number;
    specificationVersionId: number;

    private _isSelected: boolean;
    public get isSelected(): boolean {
        return this._isSelected;
    }
    public set isSelected(v: boolean) {
        this._isSelected = v;
        if (v) {
            this.onSelected();
        }
    }

    onSelected() { }
}
