import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DiagnosticFamilyModel, GenerationModel } from '../app-model/server-identification/diagnostic-family.model';
import { DiagnosticFamilyService } from '../app-services/diagnostic-family.service';
import { MessageService } from '../modules/shared/services/message-service.service';

@Component({
  selector: 'app-diagnostic-families',
  templateUrl: './diagnostic-families.component.html',
  styleUrls: ['./diagnostic-families.component.css']
})
export class DiagnosticFamiliesComponent implements OnInit {
  diagnosticFamilies: DiagnosticFamilyModel[];
  loading = false;
  newExpression: string;
  newName: string;
  showAddNew = false;
  _selectedDiagnosticFamily: DiagnosticFamilyModel;
  genericName = 'Generation Name';
  _modifiedSubscription: Subscription;
  filterText: string;

  constructor(private diagnosticFamilyService: DiagnosticFamilyService, private messageService: MessageService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private authService:AuthService) { }

  ngOnInit() {
    const done = () => {
      this.activatedRoute.paramMap.subscribe({
        next: (map) => {
          const id = +map.get('id');
          if (id) {
            this._selectedDiagnosticFamily = this.diagnosticFamilies.find(x => x.id === id);
          }
        }
      });
    };
    this.getDiagnosticFamilies(done);
    if (this._modifiedSubscription != null) {
      this._modifiedSubscription.unsubscribe();
    }
  }

  getDiagnosticFamilies(done: () => void = null) {
    this.loading = true;
    this.diagnosticFamilyService.getFamilies().pipe(tap((data: DiagnosticFamilyModel[]) => {
      this.diagnosticFamilies = data;

      if (this.selectedDiagnosticFamily) {
        this.selectedDiagnosticFamily = this.diagnosticFamilies.find(x => x.id === this.selectedDiagnosticFamily.id);
        this.router.navigate(['/content/diagnostic-families/' + this.selectedDiagnosticFamily.id]);
      } else {
        this.selectedDiagnosticFamily = null;
      }

      if (done) {
        done();
      }
      this.loading = false;
    }, error => {
      this.loading = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    })).subscribe();
  }

  get selectedDiagnosticFamily(): DiagnosticFamilyModel {
    return this._selectedDiagnosticFamily;
  }

  set selectedDiagnosticFamily(diagnosticFamily: DiagnosticFamilyModel) {
    this._selectedDiagnosticFamily = diagnosticFamily;
  }

  diagnosticFamilySelected(family: DiagnosticFamilyModel) {
    this.router.navigate(['../', family.id], { relativeTo: this.activatedRoute });
  }

  diagnosticFamilyNameChanged(newName: string) {
    const oldName = this.selectedDiagnosticFamily.name;
    this.selectedDiagnosticFamily.name = newName;
    this.diagnosticFamilyService.updateDiagnosticFamily(this.selectedDiagnosticFamily).pipe(tap(result => { },
      error => {
        this.selectedDiagnosticFamily.name = oldName;
        this.messageService.dispatchErrorMessageFromApi(error);
      })).subscribe();
    this.diagnosticFamilies = this.diagnosticFamilies;
  }

  addDiagnosticFamily() {
    const newDiagnosticFamily = new DiagnosticFamilyModel();
    newDiagnosticFamily.name = this.genericName;
    this.diagnosticFamilyService.createDiagnosticFamily(newDiagnosticFamily).pipe(tap(data => {
      this.selectedDiagnosticFamily = data;
      this.getDiagnosticFamilies();
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    })).subscribe();
  }

  addGeneration() {
    const newGenerationModel = new DiagnosticFamilyModel();
    newGenerationModel.name = this.genericName;
    this.diagnosticFamilyService.createDiagnosticFamilyGeneration(this.selectedDiagnosticFamily.id, newGenerationModel).pipe(tap(data => {
      this.selectedDiagnosticFamily.generations = data.generations;
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    })).subscribe();
  }

  delete(diagnosticFamily: { generations: GenerationModel[] }) {
    this.selectedDiagnosticFamily.generations = diagnosticFamily.generations;
  }

  deleteDiagnosticFamily() {
    this.diagnosticFamilyService.deleteDiagnosticFamily(this.selectedDiagnosticFamily.id).pipe(tap(result => {
      this.selectedDiagnosticFamily = null;
      this.getDiagnosticFamilies();
      this.router.navigate(['/content/diagnostic-families']);

    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    })).subscribe();
  }

  canEditDignosticFamilies(){
    return this.authService.canEditDignosticFamilies();
  }
}
