import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { SpecificationType } from 'app/app-model/enums';
import { OdxFile } from 'app/app-model/odx-import/odx-file';
import { OdxImportIssue } from 'app/app-model/odx-import/odx-import-issue';
import { IncomingOdxFile, OdxUpdate, OdxUpdateAffectedCategoryItem } from 'app/app-model/odx-import/odx-update';
import { SpecificationStatisticItem } from 'app/app-model/specification-statistic-item.model';
import { CategoryItemsService } from 'app/app-services/category-items-service';
import { OdxImportService } from 'app/app-services/odx-import.service';
import { OdxService } from 'app/app-services/odx-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { OdxFileSelectorDialogComponent } from 'app/dialogs/odx-file-selector/odx-file-selector-dialog';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { OdxImportViewModel } from 'app/modules/shared/components/odx-import-view/odx-import-view-model';
import { Subscription } from 'rxjs';

import { OdxImportViewService } from '../../../../app-services/odx-import-view.service';
import { MessageService } from '../../services/message-service.service';
import { MessageTemplateComponent } from '../message-handler/message-templates/message-templates.component';

@Component({
  selector: 'app-import-view',
  templateUrl: './odx-import-view.component.html',
  styleUrls: ['./odx-import-view.component.css']
})

export class ImportViewComponent implements OnInit, OnDestroy {

  @Output()
  importDone: EventEmitter<OdxFile> = new EventEmitter();

  @Output()
  importDoneOdxIssues: EventEmitter<OdxImportIssue[]> = new EventEmitter();

  incomingOdxFile: IncomingOdxFile;
  incomingOdx: OdxFile;
  odxUpdate: OdxUpdate;
  odxUpdateAffectedCategoryItem: OdxUpdateAffectedCategoryItem;
  selectorId: string;
  modalHeader = 'Replace ODX File';
  modalBody = 'You are about to convert this specification to a NON-Legacy specification. ' +
    'This means you will not be able to edit the diagnostic services in DCODE as these services are imported from the ODX file directly. ' +
    'Would you like to proceed ?';

  showDialog: boolean;
  private _importedOdxFile: OdxFile;
  private _previousOdxFile: OdxFile;
  private importDoneSubscription: Subscription;
  private importDoneOdxIssuesSubscription: Subscription;
  private statisticsSubscription: Subscription;
  private statusMessageToken: MatSnackBarRef<MessageTemplateComponent>;
  private replaceTimeStamp: string;
  private dataCategoryStatistics: SpecificationStatisticItem[] = [];

  private readonly msgFileNotCompatible = 'The file extension is not supported by Dcode. Only \'.odx-d\', \'.pdx\'  extension is supported';

  public get importedODXFile(): OdxFile {
    return this._importedOdxFile;
  }

  @Input()
  public set importedODXFile(v: OdxFile) {
    this.model.odxFile = v;
    this._importedOdxFile = v;
  }

  constructor(private specificationService: SpecificationService,
    private specificationStatusService: SpecificationStatusService,
    private odxImportViewService: OdxImportViewService,
    private categoryItemsService: CategoryItemsService,
    private messageService: MessageService,
    private odxService: OdxService,
    private statusService: SpecificationStatusService,
    public datePipe: DatePipe,
    private odxImportService: OdxImportService,
    private dialog: MatDialog,
    private authService:AuthService) {
  }

  ngOnInit(): void {
    this.importDoneSubscription = this.model.importDone.subscribe(updatedOdxFile => {
      this.importDone.next(updatedOdxFile);
      this.selectorId = this.getSelectorId();
    });

    this.importDoneOdxIssuesSubscription = this.model.importDoneOdxIssues.subscribe(updatedOdxImportIssues => {
      this.importDoneOdxIssues.next(updatedOdxImportIssues);
    });

    this.specificationService.selectedSpecificationEventEmitter.subscribe(spec => {
      this.updateCanShowDialogBeforeUpload();
    });
  }

  ngOnDestroy(): void {
    this.importDoneSubscription.unsubscribe();
    this.importDoneOdxIssuesSubscription.unsubscribe();
  }

  get model(): OdxImportViewModel {
    return this.odxImportViewService.model;
  }

  get isImportAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get importNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  loadOdxLayers() {
    this.model.loading = true;
  }

  whenOdxFileSelected(odxFile: OdxFile) {
    // Use the id of the previous ODX file if it exists.
    odxFile.id = this.model.odxFile ? this.model.odxFile.id : 0;

    if (!this.hasExistingOdxFile()) {
      this.model.setOdxFile(odxFile);
      this.model.importDefaultOdxLayerToSpecificationVersion();
    } else {
      this.compareAndFindAffectedCategoryItem(odxFile);
    }
  }

  getSelectorId() {
    return this.specificationService.currentSpecificationVersion.id.toString();
  }

  buttonTitle(importedODXFile: string) {
    if (importedODXFile != null) {
      return 'Replace ODX file';
    } else {
      return 'Select ODX file';
    }
  }

  openUploadOdxFileDialog(): void {
    this.dialog.open(OdxFileSelectorDialogComponent);
  }

  updateCanShowDialogBeforeUpload(): void {
    this.showDialog = this.specificationService.currentSpecificationVersion.specificationType === SpecificationType.Upgraded;
  }

  private hasExistingOdxFile(): boolean {
    return this.model.odxFile !== undefined;
  }

  private compareAndFindAffectedCategoryItem(incomingOdxFile: OdxFile) {
    this.odxService.getOdxFile(incomingOdxFile.id).subscribe(currentOdxFile => {
      if (currentOdxFile.data === incomingOdxFile.data) {
        this.messageService.dispatchErrorMessage(`The selected ODX file content and previously stored ODX file
                                  content are same. Please select a new ODX file with different file content!`);
      } else {
        this.findAffectedCategoryItemAndReplaceOdx(incomingOdxFile);
      }
    }, e => {
      this.messageService.dispatchErrorMessageFromApi(e);
    });
  }

  private findAffectedCategoryItemAndReplaceOdx(incomingOdxFile: OdxFile) {
    this.incomingOdxFile = { fileName: incomingOdxFile.fileName, size: incomingOdxFile.size, data: incomingOdxFile.data };
    this.odxUpdate = {
      incomingOdxFile: this.incomingOdxFile,
      specificationVersionId: this.specificationService.currentSpecificationVersion.id
    };

    this.odxImportService.getOdxUpdateAffectedCategoryItem(this.odxUpdate).subscribe(affectedItemData => {
      this.odxUpdateAffectedCategoryItem = affectedItemData;

      this.replaceCurrentOdxFileWithIncoming(incomingOdxFile);
      this.model.importDone.next(incomingOdxFile);
    }, e => {
      this.messageService.dispatchErrorMessageFromApi(e);
    });
  }

  private replaceCurrentOdxFileWithIncoming(odxFile: OdxFile) {
    this.odxService.replaceOdxFile(this.model.odxFile.id, odxFile).subscribe(replacedOdxFile => {
      this.model.odxFile = replacedOdxFile;
      this.importedODXFile = replacedOdxFile;
      this.categoryItemsService.resetCategories();
      //this.loadStatistics();

      this.statusMessageToken = this.messageService.dispatchInfoMessage('ODX file has been replaced successfully.', true);
      this.replaceTimeStamp = ', Replaced at : ' + this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss');
      this.specificationService.currentSpecificationVersion.specificationType = SpecificationType.OdxBased;
      this.updateCanShowDialogBeforeUpload();
    }, e => {
      this.messageService.dispatchErrorMessageFromApi(e);
    });
  }
  canEditCategoryItems() {
    return this.authService.canEditCategoryItems();
  }
}
