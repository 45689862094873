import { DataLimitType } from 'app/app-model/diagnostic-service/range.model';
import { SpecificationVersionInfo } from '../specification-version-info';
import {
  ServerIdentificationCompareOperatorType,
  ServerIdentificationExpressionNodeType,
} from 'app/app-model/server-identification/server-identification.enums';
import { TypedValueData } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { ProductAffinityType } from 'app/app-model/enums';

export class ServerIdentificationModel {
  id: number;
  specificationVersion: SpecificationVersionInfo;
  generation: string;
  generationId: number;
  productAffinity = 'Other';
  diagnosticFamily: string;
  diagnosticFamilyId: number;
  className: string;
  expression: ServeridentificationExpressionModel;
  formattedExpression: string;
}

export class ServeridentificationExpressionModel {
  id: number;
  index: number;
  nodeType: ServerIdentificationExpressionNodeType;
  expressions: Array<ServeridentificationExpressionModel> = [];
  compareValue: TypedValueData;
  compareCanAddress: string;
  legacyIdentifierCode: number;
  compareOperator: ServerIdentificationCompareOperatorType;
  ecuIdentifierPropertyToCompareWith: EcuIdentifierPropertyModel;
  compareRange: TypedRangeModel;
  subStringStart: number;
  subStringLength: number;
  searchLength: number;
}

export class ServerIdentificationCompareExpressionModel {
  id: number;
  compareType: ServerIdentificationCompareOperatorType;
  ecuIdentifierProperty: EcuIdentifierPropertyModel;
  compareValue: TypedValueData;
  compareRange: TypedRangeModel;
}

export class TypedRangeModel {
  minValue: TypedValueData;
  maxValue: TypedValueData;
  minLimitType: DataLimitType;
  maxLimitType: DataLimitType;
}

export class EcuIdentifierPropertyModel {
  ecuIdentifierId: number;
  ecuIdentifier: string;
  propertyName: string;
}
