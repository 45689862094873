import { SharedFilesService } from 'app/app-services/shared-files.service';
import { SpecificationService } from 'app/app-services/specification-service';

import { ScommFileType, SharedFile } from '../../../../../app-model/legacy-file';
import { TreeService } from '../service/tree.service';
import { TreeNode } from './tree-node';

export class SharedFileTreeNode extends TreeNode {
  // specificationFile: SharedFile;
  specificationService: SpecificationService;
  sharedFileService: SharedFilesService;
  hasReferencedSpecifications: boolean;
  referencedDcodeSpecificationIds: number[];
  showReferencedFiles: boolean;
  isLoading: boolean;
  loaded: boolean;
  loadedSpecs = 0;

  showReferecedSpecificationsAction: (p: number[]) => void;

  constructor(specification: SharedFile,
    treeService: TreeService,
    specificationService: SpecificationService,
    sharedFileService: SharedFilesService,
    showReferecedSpecificationsAction: (p: number[]) => void,
    showReferencedFiles = true) {
    super(specification.name, treeService);
    this.showReferencedFiles = showReferencedFiles;
    this.specificationFile = specification;
    this.specificationService = specificationService;
    this.sharedFileService = sharedFileService;
    this.showReferecedSpecificationsAction = showReferecedSpecificationsAction;
    this.isSharedFileTreeNode = true;
  }

  get fileType(): string {
    return ScommFileType[this.specificationFile.fileType];
  }

  selectTreeNode() {
    super.selectTreeNode();
    if (!this.loaded && this.showReferencedFiles) {
      this.loadReferencedSpecifications();
    }
  }

  showReferencedSpecifications() {
    this.showReferecedSpecificationsAction(this.referencedDcodeSpecificationIds);
  }

  private loadReferencedSpecifications() {
    this.isLoading = true;
    this.sharedFileService.getDcodeSpecificationsUsedBySharedFile(this.specificationFile.id)
      .subscribe(specificationIds => {
        if (specificationIds && specificationIds.length > 0) {
          this.referencedDcodeSpecificationIds = specificationIds;
          this.hasReferencedSpecifications = true;
          this.setLoadComplete();
        } else {
          this.setLoadComplete();
          this.hasReferencedSpecifications = false;
        }
      });
  }

  private setLoadComplete() {
    this.isLoading = false;
    this.loaded = true;
    this.loadedSpecs = 0;
  }
}
