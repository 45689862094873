<div *ngIf="axisModel">
  <b>{{axisTag}}-Axis definition</b>
      <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg mt-10">
        <span class="sdds-dropdown-label-outside">Type</span>
        <select name="axisTypes"
          (change)="onAxisDefinitionChange()" [(ngModel)]="axisModel.type" [disabled]="isReadOnly||!canEditCategoryItems()">
          <option *ngFor="let axisType of getAxisTypes()" [ngValue]="OperationalDataAxisType[axisType]"
            [disabled]="disabled || isReadOnly||!canEditCategoryItems()">{{axisType}}</option>
        </select>
      </div>

      <div class="sdds-on-white-bg w-1/6 my-10">
        <sdds-textfield ngDefaultControl
            [(ngModel)]="axisModel.unit"
            type="text"
            size="md"
            [disabled]="isReadOnly||!canEditCategoryItems()"
            (customChange)="onAxisDefinitionChange()">
          <span slot="sdds-label">Unit</span>
        </sdds-textfield>
      </div>
</div>
