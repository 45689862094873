<ul class="nav navbar-nav" data-id="nav-level-two-developer">
  <li routerLinkActive="active">
    <a *ngIf="!currentSpecificationVersion" [routerLink]="['/content/specifications']">Diagnostic Specifications</a>
    <a *ngIf="currentSpecificationVersion"
      [routerLink]="['/specification', currentSpecification.id, currentSpecificationVersion.id]">Diagnostic
      Specifications</a>

  </li>
  <li routerLinkActive="active">
    <a [routerLink]="['/content/identification-groups']">Identification groups</a>
  </li>
  <li routerLinkActive="active">
    <a [routerLink]="['/content/shared-files']">Shared Files</a>
  </li>
  <!-- <li routerLinkActive="active">
    <a [routerLink]="['/content/keyset']">Security Access Keysets</a>
  </li> -->
</ul>
