import { Injectable } from '@angular/core';
import { SpecificationVersionModification } from 'app/modules/shared/model/specification-version-modification';
import { ApiProxyService } from 'app/modules/shared/services/api-proxy.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModificationInfoService {

  constructor(private apiProxy: ApiProxyService) { }

  public getAllModification(specificationVersionId: number): Observable<SpecificationVersionModification[]> {
    return this.apiProxy.get<SpecificationVersionModification[]>(`${environment.apiUrl}SpecificationVersionModification/AllModificationInfo/${specificationVersionId}`);
  }

  public getLastModification(specificationVersionId: number) {
    return this.apiProxy.get<SpecificationVersionModification>(`${environment.apiUrl}SpecificationVersionModification/GetLastModification/${specificationVersionId}`);
  }
}