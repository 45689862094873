<div class="sdds-card ml-4 mr-4" *ngIf="!isReservedParameter && parameter.scalingData">
    <div class="sdds-card-header">
        <h5 class="sdds-card-headline">Physical value</h5>
    </div>

    <div class="sdds-card-body mt-16">
        <div class="sdds-row mb-16">
            <div
                class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                <label for="physDataTypeSelector">Data Type</label>
            </div>

            <div
                class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-dropdown sdds-dropdown-small sdds-on-white-bg">
                <select name="physDataTypeSelector" [(ngModel)]="physicalValueDataType"
                    [disabled]="isOdxBasedSpecification || !isAllowed">
                    <option *ngIf="!physicalValueDataType" disabled active></option>
                    <option *ngFor="let dataType of parameterService.getPhysicalValueDataTypes()">{{dataType}}</option>
                </select>
            </div>
        </div>

        <div class="sdds-row mb-16">
            <div
                class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                <label for="physValUnit">Unit</label>
            </div>

            <div
                class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-on-white-bg">
                <input name="physValUnit" class="sdds-textfield-input-md" type="text"
                    [(ngModel)]="parameter.scalingData.unit" (change)="parameterChanged.next()"
                    [readonly]="isOdxBasedSpecification || !isAllowed">
            </div>
        </div>

        <div class="sdds-row" *ngIf="isValueParameter">
            <div
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div class="sdds-checkbox-item">
                    <input class="sdds-form-input" type="checkbox" id="cbxHasPhysicalValConstraint"
                        [(ngModel)]="hasPhysicalValueConstraint" [ngModelOptions]="{standalone: true}"
                        (change)="parameterChanged.next()" [disabled]="isOdxBasedSpecification || !isAllowed">
                    <label class="sdds-form-label" for="cbxHasPhysicalValConstraint">Has physical value
                        constraint</label>
                </div>
            </div>
        </div>

        <app-editable-value-constraint *ngIf="hasPhysicalValueConstraint"
            [valueConstraint]="parameter.scalingData.physicalConstraintData"
            [isOdxBasedSpecification]="isOdxBasedSpecification" [dataType]="physicalValueDataTypeEnum"
            (valueConstraintChange)="parameterChanged.next($event)"
            [isAllowed]="isAllowed"></app-editable-value-constraint>
    </div>
</div>