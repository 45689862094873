<div *ngIf="!category" class="loader-container">
  <div class="loader"></div>
</div>

<app-category-header [text]="'Services'"></app-category-header>

<div class="sdds-row">
  <div
    class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3 mb-16">
    <!-- [ngClass]="{'loading': !category}"> -->
    <app-category-items-diagnostic-services [update]="updateStructure" *ngIf="category" [specification]="specification"
      [specificationVersion]="specificationVersion" [category]="category" [isAllowed]="canEditDiagnosticService()"
      #categoryItemsViewRef>
    </app-category-items-diagnostic-services>
  </div>

  <div
    class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-9 sdds-col-md-9 sdds-col-sm-9 sdds-col-xs-9 mb-16">
    <app-remove-parameter-confirm (accept)="onRemoveParameterConfirm()"></app-remove-parameter-confirm>

    <a #activateRemoveParameterConfirm id="removeParameterConfirmModal"></a>

    <ng-container *ngIf="category">
      <ng-container *ngIf="category.selectedGroupItem">
        <h2>{{category.selectedGroupItem.name}}</h2>
        Type: {{category.selectedGroupItem.typeText}}
      </ng-container>

      <ng-container *ngIf="category.selectedItem">
        <div class="flex flex-wrap justify-left">
          <div class="grow">
            <app-editable-textbox [overrideFont]="true" [text]="category.selectedItem.model.name" [isEditable]="!isReadOnly"
              (textEditedEventEmitter)="editingNameDone($event)" enclosingElement="h2"  [isAllowed]="canEditDiagnosticService()">
            </app-editable-textbox>
          </div>
          <div *ngIf="category.selectedItem.model.isTemplateService" style="height: 20px" class="flex justify-center items-center mt-10 font-bold py-1 px-2 rounded-full text-white bg-blue-950 border border-blue-950">
            <div class="font-bold leading-none max-w-full flex-initial p-4">Inherited from template</div>
          </div>
        </div>

        <div class="sdds-row">
          <div class="sdds-col-max-12 sdds-no-padding mb-16">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start"
              (selectedTabChange)="serviceTabChanged($event)" animationDuration="0ms">
              <mat-tab label="Parameters"></mat-tab>
              <mat-tab label="Preconditions"></mat-tab>
              <mat-tab label="Transitions"></mat-tab>
            </mat-tab-group>
          </div>
        </div>

        <ng-container *ngIf="parametersViewSet">
          <div class="sdds-row mb-16" *ngIf="(requestParameters && requestParameters.length > 0) || !isReadOnly">
            <div
              class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
              <h4>Request parameters</h4>
            </div>

            <div *ngIf="!isReadOnly"
              class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-12">
              <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" (click)="createRequestParameter()"
                [disabled]="isOdxBasedSpecification || !canEditDiagnosticService()">
                Create new <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon>
              </button>
            </div>

            <app-common-list name="request" [list]="requestParameters" [isReadOnly]="isReadOnly" [bubbleMode]="true"
              [itemTemplate]="parameterItemTemplate" [selectedItem]="selectedParameter"
              (selectedItemEventEmitter)="navigateToParameter($event, RequestMessageName)">
            </app-common-list>

            <label *ngFor="let requestParam of requestParameters"></label>
          </div>

          <div class="sdds-row mb-16" *ngIf="(responseParameters && responseParameters.length > 0) || !isReadOnly">
            <div
              class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
              <h4>Response parameters</h4>
            </div>

            <div *ngIf="!isReadOnly"
              class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-12">
              <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" (click)="createResponseParameter()"
                [disabled]="isOdxBasedSpecification || !canEditDiagnosticService()">
                Create new <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon>
              </button>
            </div>

            <app-common-list name="response" [list]="responseParameters" [isReadOnly]="isReadOnly" [bubbleMode]="true"
              [itemTemplate]="parameterItemTemplate" [selectedItem]="selectedParameter"
              (selectedItemEventEmitter)="navigateToParameter($event, ResponseMessageName)">
            </app-common-list>
          </div>

          <hr />

          <ng-container *ngIf="selectedParameter && isReadOnly">
            <app-parameter [parameter]="selectedParameter" [isAllowed]="canEditDiagnosticService()"></app-parameter>
          </ng-container>

          <div class="sdds-row mt-16" *ngIf="selectedParameter && !isReadOnly">
            <div
              class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-16">
              <button class="sdds-btn sdds-btn-secondary sdds-btn-sm float-right" (click)="removeSelectedParameter()"
                [disabled]="isOdxBasedSpecification || !canEditDiagnosticService()">
                <mat-icon fontSet="material-icons-outlined" class="md-18">delete_forever</mat-icon>
                Remove parameter
              </button>
            </div>

            <div
              class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
              <app-editable-parameter [parameter]="selectedParameter" [diagnosticService]="diagnosticServiceItem"
                (parameterChange)="onParameterChanged($event)" [isAllowed]="canEditDiagnosticService()">
              </app-editable-parameter>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="preconditionsViewSet">
          <div class="sdds-row" *ngIf="!chartPreconditions || !chartPreconditions.length">
            <div
              class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
              <div class="sdds-banner">
                <div class="sdds-banner-body">
                  This service has no preconditions.
                </div>
              </div>
            </div>
          </div>

          <div class="sdds-row">
            <div *ngFor="let chartPrecondition of chartPreconditions"
              class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-12 preconditions">
              <b>{{chartPrecondition.stateChart.shortName}}</b>
              <a [routerLink]="['/specification', specificationVersion.specification.id, specificationVersion.id, 'charts']"
                [queryParams]="{itemId: chartPrecondition.stateChart.id}">
                <mat-icon fontSet="material-icons-outlined" class="md-18">arrow_forward</mat-icon>
              </a>

              <app-common-list [list]="chartPrecondition.preconditions" [bubbleMode]="true" [isState]="true"
                [data]="chartPrecondition.stateChart" [itemTemplate]="preconditionItemTemplate" [isSelectable]="false">
              </app-common-list>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="transitionsViewSet">
          <div class="sdds-row" *ngIf="!transitions.length">
            <div
              class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
              <div class="sdds-banner">
                <div class="sdds-banner-body">
                  This service has no transitions.
                </div>
              </div>
            </div>
          </div>

          <div class="sdds-row" *ngIf="transitions.length > 0">
            <div
              class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
              <div class="sdds-banner">
                <div class="sdds-banner-body">
                  The diagnostic server can perform a transition automatically when all request
                  parameter values can be resolved. Values can be resolved from constants, response
                  parameter values from other services or from well known functions (ie encipher
                  functions).
                  Below you can specify how the diagnostic server should resolve each parameter value.
                </div>
              </div>
            </div>
          </div>

          <div class="sdds-row">
            <div *ngFor="let transition of transitions; let i=index"
              class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-16">
              <h5>{{transition.shortName}}</h5>

              <app-graph [leftToRight]="true" [dcodeGraph]="transitionGraphs[i]"></app-graph>

              <p *ngIf="requestValueParameters.length === 0" class="row alert alert-success">
                <span>No parameters needs to be resolved..</span>
              </p>
              <ng-container *ngIf="requestValueParameters.length !== 0">
                <app-service-parameter-definition [requestValueParameters]="requestValueParameters">
                </app-service-parameter-definition>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #parameterItemTemplate let-item>
      <span>
        {{getParameterItemHeader(item)}}
      </span>
    </ng-template>

    <ng-template #preconditionItemTemplate let-item let-stateChart="data">
      <span *ngIf="item.transitionState === null">
        {{item.state.shortName}}
      </span>
      <span *ngIf="item.transitionState !== null">
        {{item.state.shortName}} (-> {{item.transitionState.shortName}})
      </span>
    </ng-template>
  </div>
</div>
