
<div>
  <button mat-stroked-button [matMenuTriggerFor]="expressionNodeMenu" [disabled]="!canEditCategoryItems()||!isAllowed" id="dropdownMenu1">Add expression node</button>
  
  <mat-menu #expressionNodeMenu="matMenu">
    <ng-container *ngIf="legacyModeEnabled">
      <button mat-menu-item type="button" (click)="createLegacyValueEquals.emit()">Ecu Id Comparison (Equals to)</button>
      <button mat-menu-item type="button" (click)="createLegacyValueStartsWith.emit()">Ecu Id Comparison (Starts with)</button>
      <button mat-menu-item type="button" (click)="createLegacyValueContains.emit()">Ecu Id Comparison (Contains)</button>
      <button mat-menu-item type="button" (click)="createValueComparisonNodeInterval.emit()">Ecu Id Comparison (Interval)</button>
      <button mat-menu-item type="button" (click)="createCanAddressComparison.emit()">Diagnostic Address On CAN Comparison</button>
      <button mat-menu-item type="button" (click)="createSpecialComparison.emit()">Special Comparison</button>
      <button mat-menu-item type="button" (click)="createDoesNotExistComparison.emit()">Does Not Exist Comparison</button>
      <button mat-menu-item type="button" (click)="createExecutingModeComparison.emit()">Executing Mode Comparison</button>
      <button mat-menu-item type="button" (click)="createOperator.emit()">Operator</button>
    </ng-container>
    <ng-container *ngIf="!legacyModeEnabled">
      <button mat-menu-item type="button" (click)="createValueComparisonNodeEqualTo.emit()">Ecu Id Comparison (Equals to)</button>
      <button mat-menu-item type="button" (click)="createValueStartsWith.emit()">Ecu Id Comparison (Starts with)</button>
      <button mat-menu-item type="button" (click)="createValueContains.emit()">Ecu Id Comparison (Contains)</button>
      <button mat-menu-item type="button" (click)="createValueComparisonNodeInterval.emit()">Ecu Id Comparison (Interval)</button>
      <button mat-menu-item type="button" (click)="createExecutingModeComparison.emit()">Executing Mode Comparison</button>
      <button mat-menu-item type="button" (click)="createOperator.emit()">Operator</button>
    </ng-container>
  </mat-menu>
</div>
