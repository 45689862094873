<app-dialog-base modalId="ioSelectModal" title="Add a new IO" [itemTemplate]="ioSelectorTemplate"
    [footerTemplate]="ioSelectorFooter" applyCaption="Apply" #dialogBase></app-dialog-base>

<ng-template #ioSelectorTemplate>
    <app-toolbar [filterEnabled]="true" (filterTextEventEmitter)="filter($event)"></app-toolbar>

    <div class="select-io" [ngClass]="{'loading': isLoading}">
        <select #select multiple class="h-full w-full">
            <option *ngFor="let io of currentIos | categoryItemFilter: filterText " [hidden]="isIoAlreadyAssigned(io)">
                {{io.name}}
            </option>
        </select>
    </div>
</ng-template>

<ng-template #ioSelectorFooter>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-primary sdds-btn-md mr-12"
        (click)="emitIosAdded()">{{okButtonName || 'Add'}}</button>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md">Cancel</button>
</ng-template>