<div class="sdds-card" style="background: #f2f5f8; margin-bottom:15px;">
  <div class="sdds-card-body" style="padding: 10px;">
    <div style="float:right;">
      <app-expression-view-toolbar [expressionModel]="expressionModel" [isReadOnly]="isReadOnly"
        [copyEnabled]="copyEnabled" [reorderEnabled]="reorderEnabled" [isAllowed]="isAllowed"></app-expression-view-toolbar>
    </div>
    <div style="margin-top: 15px;"></div>
    <div class="sdds-row">

      <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3">
        <span class="sdds-detail-04">Executing mode is</span>
      </div>
      <div class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-5 sdds-col-sm-5 sdds-col-xs-5">
        <span class="sdds-dropdown sdds-dropdown-small">
          <select [disabled]="isReadOnly||!isAllowed" name="executingModeSelector"
            style="width: 200px; padding: 6px;" [(ngModel)]="expressionModel.compareValue.data"
            (change)="notifyServerIdentificationChanged()">
            <option disabled active></option>
            <option ngValue="Application">Application</option>
            <option ngValue="Bootloader">Bootloader</option>
          </select>
        </span>
      </div>
      <div class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4"></div>
    </div>
  </div>
</div>
