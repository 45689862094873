import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { SessionsService } from 'app/app-services/sessions.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { SessionsCategoryItem } from 'app/data-categories/sessions/sessions-category-item';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { CategoryComponentBaseComponent } from '../../modules/shared/model/category-component-base';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { SecurityAccessService } from 'app/app-services/security-access.service';
import { NavigationService } from 'app/app-services/navigation.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.css']
})
export class SessionsComponent extends CategoryComponentBaseComponent<SessionsCategoryItem> implements OnInit, OnDestroy {

  @Output()
  public availableSecurityAccessModeChanged = new EventEmitter<string>();

  sessionItem: SessionsCategoryItem;
  availableSecurityAccessModesInputChanged = new Subject<string>();
  availableSecurityAccessModesInputChangedSubscription: Subscription;

  constructor(activatedRoute: ActivatedRoute,
    private specificationStatusService: SpecificationStatusService,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    navigationService: NavigationService,
    messageService: MessageService, sessionsService: SessionsService, private securityAccessService: SecurityAccessService,private authService:AuthService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.Sessions, navigationService);
  }

  ngOnInit() {
    this.componentSubscriptions.push(this.itemChanged.subscribe(item => {
      this.sessionItem = item;
    }));
  }

  ngOnDestroy(): void {
    if (this.availableSecurityAccessModesInputChangedSubscription) {
      this.availableSecurityAccessModesInputChangedSubscription.unsubscribe();
    }
  }

  get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  availableSecurityAccessItemsChanged(securityAccessItems: string[]) {
    this.sessionItem.model.availableSecurityAccessModes = securityAccessItems;
    this.sessionItem.notifySessionChanged();
  }

  availableSecurityAccessItemRemoved(securityAccessItemToRemove: string) {
    const index = this.sessionItem.model.availableSecurityAccessModes.findIndex(securityAccess => securityAccess === securityAccessItemToRemove);
    this.sessionItem.model.availableSecurityAccessModes.splice(index, 1);
    this.sessionItem.notifySessionChanged();
  }

  editingNameDone(name: string) {
    if (name.trim().length > 0) {
      this.sessionItem.setName(name.trim());
    } else {
      this.messageService.dispatchErrorMessage('Session name can not be empty!');
    }
  }

  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
