import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FingerprintModel, WriteSecurity } from 'app/app-model/fingerprint/fingerprint.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { FingerprintService } from 'app/app-services/fingerprint.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { FingerprintCategoryItemDirective } from 'app/data-categories/fingerprint/fingerprint-category-item';
import { FunctionType } from 'app/modules/shared/components/function/functions';
import { Category } from 'app/specification-structure/category-items/category.enum';

import { CategoryComponentBaseComponent } from '../../modules/shared/model/category-component-base';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { NavigationService } from 'app/app-services/navigation.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-fingerprint',
  templateUrl: './fingerprint.component.html',
  styleUrls: ['./fingerprint.component.css']
})
export class FingerprintComponent extends CategoryComponentBaseComponent<FingerprintCategoryItemDirective> implements OnInit {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  FunctionType = FunctionType;
  fingerprintItem: FingerprintCategoryItemDirective;

  constructor(activatedRoute: ActivatedRoute,
    private specificationStatusService: SpecificationStatusService,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    messageService: MessageService, private fingerprintService: FingerprintService,
    protected navigationService: NavigationService,private authService:AuthService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.Fingerprints, navigationService);
  }

  ngOnInit(): void {
    this.componentSubscriptions.push(this.itemChanged.subscribe(item => {
      this.fingerprintItem = item;
    }));
  }

  get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  get allowedLogins() {
    return this.fingerprintItem.model.loginPrerequisites ?
      this.fingerprintItem.model.loginPrerequisites.allowedLogin :
      '';
  }

  set allowedLogins(v: string) {
    this.ensureHasLoginPrerequisites();
    this.fingerprintItem.model.loginPrerequisites.allowedLogin = v;
  }
  get loginToPerform() {
    return this.fingerprintItem.model.loginPrerequisites ?
      this.fingerprintItem.model.loginPrerequisites.loginToPerform :
      '';
  }

  set loginToPerform(v: string) {
    this.ensureHasLoginPrerequisites();
    this.fingerprintItem.model.loginPrerequisites.loginToPerform = v;
  }

  editingNameDone(name: string) {
    if (name.trim().length > 0) {
      this.fingerprintItem.setName(name.trim());
    } else {
      this.messageService.dispatchErrorMessage('Fingerprint name can not be empty!');
    }
  }

  enableFunction(f: FunctionType, isEnabled: boolean) {
    this.fingerprintItem.enableFunction(f, isEnabled, true);
    this.fingerprintItem.notifyItemChanged();
  }

  onResetChanged() {
    this.fingerprintItem.notifyItemChanged();
  }

  allowedLoginUpdate(allowedLoginContent: string) {
    const fingerprintModel = this.fingerprintItem.model as FingerprintModel;
    this.ensureHasLoginPrerequisites();
    fingerprintModel.loginPrerequisites.allowedLogin = allowedLoginContent.trim();
    this.fingerprintItem.notifyItemChanged();
  }

  loginToPerformUpdate(loginToPerformContent: string) {
    const fingerprintModel = this.fingerprintItem.model as FingerprintModel;
    this.ensureHasLoginPrerequisites();
    fingerprintModel.loginPrerequisites.loginToPerform = loginToPerformContent.trim();
    this.fingerprintItem.notifyItemChanged();
  }

  private ensureHasLoginPrerequisites() {
    if (!this.fingerprintItem.model.loginPrerequisites) {
      this.fingerprintItem.model.loginPrerequisites = new WriteSecurity();
    }
  }
  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
