import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import {
  DataType,
  DiagnosticProtocolType,
  FreezeFrameDataIndication,
  FreezeFrameEnumType,
  FreezeFrameSource,
  FreezeFrameType,
  ImportSourceType,
  PackageImportStatus,
  ScalingDataType,
} from 'app/app-model/enums';
import { FreezeFramesModel } from 'app/app-model/freeze-frames/freeze-frames.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { FreezeframesService } from 'app/app-services/freezeframes.service';
import { NavigationService } from 'app/app-services/navigation.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { CategoryComponentBaseComponent } from 'app/modules/shared/model/category-component-base';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { Subscription } from 'rxjs/internal/Subscription';

import { FreezeFramesCategoryItem } from './freeze-frames-category-item';

@Component({
  selector: 'app-harmonized-freeze-frames',
  templateUrl: './harmonized-freeze-frames.component.html',
  styleUrls: ['./harmonized-freeze-frames.component.css']
})

export class HarmonizedFreezeFramesComponent extends CategoryComponentBaseComponent<FreezeFramesCategoryItem> implements OnInit {
  @ViewChild('importZipBtn') importZipBtn: ElementRef;
  specificationEventSubscription: Subscription;
  harmonizedFreezeFrames: FreezeFramesModel[];
  selectedHarmonizedFreezeFrame: FreezeFramesModel;
  harmonizedFFName = '';
  freezeFrameSource = FreezeFrameSource
  sourceDescription = '';
  selectedFreezeFrameSource = '';
  selectedharmonizedFFSource = '';
  harmonizedFFDescription = '';
  freezeFrameDataIndication = FreezeFrameDataIndication
  selectedharmonizedDataIndication: string;
  freezeFrameType = FreezeFrameType
  selectedFreezeFramesType: string;
  userDataType = DataType;
  scalingDataType = ScalingDataType;
  formulaForReading = '';
  unit = '';
  bytePosition: number;
  byteLength: number;
  bitPosition: number;
  bitLength: number;
  hexLength: number;
  FreezeFrameDID: string;
  freezeFrameItem: FreezeFramesCategoryItem;
  bitPosBitLengthChecked: boolean = false;
  isCommonRequiredFreezeframe = true;

  private _computationalMethodType: string;

  private readonly IdentityCompMethodType = 'None';
  private readonly EnumerationCompMethodType = 'Enumeration';
  private readonly LinearFormulaCompMethodType = 'Linear Formula';
  private readonly DefaultFreezeFrameEnumData = '0,0,ValueName';
  private readonly DefaultLinearFormula = 'X';

  constructor(activatedRoute: ActivatedRoute,
    private specificationStatusService: SpecificationStatusService,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    messageService: MessageService, private freezeFramesService: FreezeframesService,
    navigationService: NavigationService, private authService: AuthService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.FreezeFrames, navigationService);
  }

  public get selectedComputationalMethodType(): string {
    return this._computationalMethodType;
  }

  public set selectedComputationalMethodType(v: string) {
    this._computationalMethodType = v;
  }

  get isImportFileAllowed(): boolean {
    return this.isEditingAllowed;
  }
  public isNumeric(value) {
    // Use the unary plus operator (+) to convert the value to a number
    // and check if it's a finite number
    return !isNaN(value) && !isNaN(parseFloat(value));
  }
  get availableFreezeFrameSource(): string[] {
    return Object.keys(FreezeFrameSource).filter(d =>
      !this.isNumeric(d));
  }

  get availableFreezeFrameDataIndication(): string[] {
    return Object.keys(FreezeFrameDataIndication).filter(d => !this.isNumeric(d));
  }

  get availableFreezeFrameTypes(): string[] {
    return Object.keys(FreezeFrameType).filter(d => !this.isNumeric(d));
  }

  get availableFreezeFrameUserDataTypes(): string[] {
    return Object.keys(DataType).filter(d => !this.isNumeric(d));
  }

  get availableFreezeFrameScalingDataType(): string[] {
    return Object.keys(ScalingDataType).filter(d => !this.isNumeric(d));
  }

  get availableFreezeFrameComputationalMethodTypes(): string[] {
    return [this.IdentityCompMethodType, this.LinearFormulaCompMethodType, this.EnumerationCompMethodType];
  }

  get editingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  get scalingDataTypeIsPacket(): boolean {
    return this.freezeFrameItem.model.scalingDataType === ScalingDataType.packet;
  }

  get isEnumerationEnabled(): boolean {
    // There can only be enumerations if there is no formula for reading and the scalingDataType is set to packet
    return !this.freezeFrameItem.model.formulaForReading && this.freezeFrameItem.model.scalingDataType === ScalingDataType.packet;
  }

  get isLinearFormulaEnabled(): boolean {
    // There can only be linear formulas if no freezeFrameEnumData has been added previously
    return !this.freezeFrameItem.model.freezeFrameEnumData;
  }

  get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  ngOnInit(): void {
    if (this.specificationService.currentSpecification) {
      if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
        this.hexLength = 1;
      } else if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Uds) {
        this.hexLength = 2;
      } else {
        this.hexLength = 1;
      }
    } else {
      this.hexLength = 1;

    }

    this.componentSubscriptions.push(this.itemChanged.subscribe(item => {
      if (item) {
        this.freezeFrameItem = item;
        this.specificationEventSubscription = this.specificationService.selectedSpecificationEventEmitter.subscribe(spec => {
          if (spec.specification) {
            this.freezeFramesService.getHarmonizedFreezeFrames(this.specificationService.currentSpecificationVersion.id).subscribe(data => {
              this.harmonizedFreezeFrames = data;
              if (this.freezeFrameItem) {
                this.selectedHarmonizedFreezeFrame = data.find(s => s.name == this.freezeFrameItem.name);
                this.setComputationalMethodTypeFromModel();
                if (this.selectedHarmonizedFreezeFrame) {
                  this.selectedharmonizedFFSource = FreezeFrameSource[this.selectedHarmonizedFreezeFrame.freezeFrameSource];
                  this.harmonizedFFDescription = this.selectedHarmonizedFreezeFrame.description;
                  this.selectedharmonizedDataIndication = FreezeFrameDataIndication[this.selectedHarmonizedFreezeFrame.freezeFrameDataIndication];
                  this.selectedFreezeFramesType = FreezeFrameType[this.selectedHarmonizedFreezeFrame.freezeFrameType];
                  this.FreezeFrameDID = this.selectedHarmonizedFreezeFrame.did;
                }
                this.selectedFreezeFrameBitChanged();
                this.selectedFreezeFrameModelChanged();
              }
            });
          }
        });
      }
    }))

    this.bitPosBitLengthChecked = false;
  }

  selectedFreezeFrameBitChanged() {
    if (this.freezeFrameItem.model.bitPosition || this.freezeFrameItem.model.bitLength > 0)
      this.bitPosBitLengthChecked = true;
    else
      this.bitPosBitLengthChecked = false;
  }

  selectedFreezeFrameModelChanged() {
    if (this.freezeFrameItem.model.freezeFrameType === 0)
      this.isCommonRequiredFreezeframe = true;
    else
      this.isCommonRequiredFreezeframe = false;
  }

  selectedComputationalMethodChanged(event: MatRadioChange): void {
    if (!event.value) { return; }

    switch (event.value) {
      case this.IdentityCompMethodType:
        this.freezeFrameItem.model.formulaForReading = '';
        this.freezeFrameItem.model.freezeFrameEnumData = '';
        break;
      case this.EnumerationCompMethodType:
        this.freezeFrameItem.model.formulaForReading = '';
        this.freezeFrameItem.model.freezeFrameEnumData = this.DefaultFreezeFrameEnumData
        this.freezeFrameItem.model.freezeFrameEnumType = FreezeFrameEnumType.Value;
        this.freezeFrameItem.model.scalingDataType = ScalingDataType.packet;
        break;
      case this.LinearFormulaCompMethodType:
        this.freezeFrameItem.model.freezeFrameEnumData = '';
        this.freezeFrameItem.model.formulaForReading = this.DefaultLinearFormula;
        this.freezeFrameItem.model.scalingDataType = ScalingDataType.unsigned;
        break;
      default:
        break;
    }

    this.onChange();
  }

  setComputationalMethodTypeFromModel(): void {
    if (!this.freezeFrameItem.model.formulaForReading && !this.freezeFrameItem.model.freezeFrameEnumData) {
      this.selectedComputationalMethodType = this.IdentityCompMethodType;
      return;
    }

    if (this.isEnumerationEnabled) {
      this.selectedComputationalMethodType = this.EnumerationCompMethodType;
      return;
    }

    this.selectedComputationalMethodType = this.LinearFormulaCompMethodType;
  }

  editingNameDone(name: string) {
    if (name.trim().length > 0) {
      this.freezeFrameItem.setName(name.trim());
    } else {
      this.messageService.dispatchErrorMessage('Freeze Frame name can not be empty!');
    }
  }

  onHexCodeChanged(hexCode: number) {
    this.freezeFrameItem.notifyFreezeFrameChanged();
  }

  onChange() {
    this.freezeFrameItem.notifyFreezeFrameChanged();
    this.selectedFreezeFrameModelChanged();
    this.selectedFreezeFrameBitChanged();
  }

  importZipFile(event: any) {
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = () => {
      let fileData = fileReader.result;
      selectedFile.data = btoa(<string>fileReader.result);
      console.log(selectedFile.data);
      this.freezeFramesService.importFreezeFramesFromPackage(this.specificationService.currentSpecificationVersion.id, selectedFile.data, ImportSourceType.Zip).subscribe(importReport => {
        if(importReport.packageImportStatus === PackageImportStatus.Error) {
          this.messageService.dispatchErrorMessage(`${importReport.message}`);
          return;
        }

        this.refreshAllFreezeFrames();
      });
    };

    this.importZipBtn.nativeElement.value = '';
  }

  private refreshAllFreezeFrames() {
    this.freezeFramesService.reset();
    this.categoryService.getCategory(Category.FreezeFrames).reset();
    this.categoryService.updateCategory(Category.FreezeFrames, this.specificationService.currentSpecificationVersion).subscribe(_ => {
      this.navigationService.navigateToFirstAvailableItem(this.category.category);
      this.messageService.dispatchInfoMessage('Freeze Frames package successfully imported to diagnostic specification.');
    });
  }

  canEditCategoryItems() {
    return this.authService.canEditCategoryItems();
  }
}
