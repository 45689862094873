import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appIsOpen]'
})
export class IsOpenDirective {

  constructor(element: ElementRef) {
    element.nativeElement.style.color = '#607D8B';
    element.nativeElement.style.fontWeight = 'bold';
  }

}
