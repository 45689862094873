<div class="sdds-card mb-20">
    <div class="sdds-card-header">
      <h6 class="sdds-card-headline">Login Prerequisites</h6>
    </div>
    <div class="sdds-card-body">
      <div class="sdds-banner mt-10">
        <div class="sdds-banner-body">
          Login Prerequisites are needed if it is required to write a fingerprint.
        </div>
      </div>
      <div class="w-1/2 my-10 sdds-on-white-bg">
        <sdds-textfield ngDefaultControl
            [(ngModel)]="allowedLogin"
            type="text"
            size="md"
            placeholder="Please enter Allowed Login"
            [disabled]="!isEditingAllowed"
            (customChange)="allowedLoginInputChanged.next($event.target.value)">
              <span slot="sdds-label">Allowed login</span>
        </sdds-textfield>
      </div>

      <div class="w-1/2 sdds-on-white-bg">
        <sdds-textfield ngDefaultControl
            [(ngModel)]="loginToPerform"
            type="text"
            size="md"
            placeholder="Please enter Login To Perform"
            [disabled]="!isEditingAllowed"
            (customChange)="loginToPerformInputChanged.next($event.target.value)">
              <span slot="sdds-label">Login to perform</span>
        </sdds-textfield>
      </div>
    </div>
</div>
