import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Specification } from 'app/app-model/specification';

import { MessageService } from '../../services/message-service.service';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { SpecificationService } from './../../../../app-services/specification-service';

@Component({
  selector: 'app-specification-selector',
  templateUrl: './specification-selector.component.html',
  styleUrls: ['./specification-selector.component.css']
})
export class SpecificationSelectorComponent implements OnInit {

  @ViewChild('select', { static: true }) selectElement: ElementRef;
  @ViewChild('filterToolbar', { static: true }) filterToolbar: ToolbarComponent;

  @Input()
  header: string;

  @Input()
  multiSelect: boolean;

  @Input()
  selectedSpecifications = Array<Specification>();

  filterText: string;
  loading: boolean;
  private _specifications: Specification[];
  // private _filteredSpecifications: Specification[];

  public get specifications(): Specification[] {
    return this.filterText ?
      this._specifications.filter(p => p.name && p.name.toLowerCase().includes(this.filterText.toLowerCase())) :
      this._specifications;
  }
  public set specifications(v: Specification[]) {
    this._specifications = v;
  }

  constructor(private specificationService: SpecificationService, private messageService: MessageService) { }

  ngOnInit() {
    this.loading = true;
    this.specifications = null;
    this.specificationService.getSpecifications().subscribe(specifications => {
      this.specifications = specifications;
      for (const spec of this.specifications) {
        spec.specificationVersions.sort((a, b) => (a.id < b.id) ? 1 : -1);
      }
      this.loading = false;
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
      this.loading = false;
    });
  }

  filter(filterText: string) {
    this.filterText = filterText;
  }

  updateSelectedSpecifications() {
    this.selectedSpecifications.splice(0, this.selectedSpecifications.length);
    const selectedValues = this.selectElement.nativeElement.options;

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < selectedValues.length; i++) {
      if (selectedValues[i].selected) {
        const specificationName = selectedValues[i].value;
        const specification = this._specifications.find(s => s.name === specificationName);
        // const specificationVersion = specification.specificationVersions.find(projVer => projVer.name === idAndSpecificationVersion[1]);

        this.selectedSpecifications.push(specification);
      }
    }
  }

  reset(): any {
    this.filterToolbar.filterText = undefined;
    this.selectedSpecifications = [];
  }
}
