import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.css']
})
export class DialogBaseComponent {

  @Output()
  modalClosing: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  modalCancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  modalApply: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  loading: boolean;

  @Input()
  cancelEnabled = true;

  @Input()
  cancelDisplay = true;

  @Input()
  applyEnabled = true;

  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  footerTemplate: TemplateRef<any>;

  @Input()
  title: string;

  @Input()
  modalId: string;

  @Input()
  cancelCaption = 'Cancel';

  @Input()
  applyCaption = 'Apply';

  @Input()
  showHeader = true;

  @Input()
  showTitle = true;

  @Input()
  showFooter = true;

  @Input()
  showCancelButton = true;

  @Input()
  modalSize = 'sm';
  
  @ViewChild('applyBtn', { static: false }) applyBtn: ElementRef;

  onCancel() {
    this.modalCancel.emit();
  }

  onApply() {
    this.modalApply.emit();
    this.modalClosing.emit();
  }
}
