import { Input, Directive } from '@angular/core';
import { ParameterValueValidator } from 'app/app-services/parameter-value-validator.service';
import { BaseProperty } from 'app/modules/shared/model/properties/base-property';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { TypedValueData } from 'app/modules/shared/model/service/parameters/typed-value-data';

@Directive()
export class PropertyParameterDirective {
  @Input()
  isValidValue: boolean;

  valueValidator = new ParameterValueValidator();
  parameter: ParameterData;
  owner: BaseProperty;
  assignedValue: TypedValueData = new TypedValueData();
  usedByProperties: string[] = [];

  constructor(parameter: ParameterData) {
    this.parameter = parameter;
  }

  validateParameterValue() {
    this.isValidValue = this.valueValidator.hasValidParameterValue(this.parameter, this.assignedValue);
  }

  hasValidParameterValue(): boolean {
    return this.valueValidator.hasValidParameterValue(this.parameter, this.assignedValue);
  }
}
