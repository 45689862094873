/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
import { TypedValueData } from 'app/modules/shared/model/service/parameters/typed-value-data';

export class RangeModel {
    id: number;
    lowerValue: TypedValueData;
    lowerType: DataLimitType;
    upperValue: TypedValueData;
    upperType: DataLimitType;
}

export enum DataLimitType {
    Open,
    Closed,
    Infinite
}
