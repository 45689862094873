import { Injectable } from '@angular/core';
import { Label } from 'app/app-model/label/label';
import { LabelType } from 'app/app-model/label/label-type';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { ApiProxyService } from '../modules/shared/services/api-proxy.service';

@Injectable({
    providedIn: 'root'
})
export class LabelService {

    constructor(private apiProxy: ApiProxyService) {
    }

    public getLabels(type: LabelType): Observable<Label[]> {
        return this.apiProxy.get<Label[]>(environment.apiUrl + 'labels?labelType=' + type);
    }
}
