import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { EcuResetModel } from 'app/app-model/ecu-reset/ecu-reset.model';
import { CategoryServiceBase } from 'app/app-services/category-base.service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { EcuResetCategoryItem } from 'app/data-categories/ecu-reset/ecu-reset-category-item';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { SpecificationService } from './specification-service';

@Injectable({
  providedIn: 'root'
})
export class EcuResetService extends CategoryServiceBase<EcuResetModel, EcuResetCategoryItem> implements OnDestroy {

  constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
    private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected apiProxy: ApiProxyService) {
    super(apiProxy);
    this.apiCategoryName = 'resets';
  }

  ngOnDestroy(): void {
    super.unsubscribe();
  }

  getItemsPreview(specificationVersionId: number): Observable<EcuResetCategoryItem[]> {
    this.isPending = true;

    if (this.hasCachedItems) {
      this.isPending = false;
      return of(this.items);
    } else {
      return this.apiProxy.get<EcuResetModel[]>(`${environment.apiUrl}versions/${specificationVersionId}/resets`).pipe(map(sessions => {
        this.items = new Array<EcuResetCategoryItem>();
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < sessions.length; i++) {
          const ecuReset = new EcuResetCategoryItem(this.specificationService, this.locationService, this.router, this.activatedRoute);
          ecuReset.ecuResetService = this;
          ecuReset.diagnosticServiceProvider = this.diagnosticServiceProvider;
          ecuReset.model = sessions[i];

          this.items.push(ecuReset);
        }

        this.hasCachedItems = true;
        this.isPending = false;
        this.getItemsPreviewDone.next(this.isPending);

        return this.items;
      }));
    }
  }

  getCompleteItems(specificationVersionId: number): Observable<EcuResetCategoryItem[]> {
    throw new Error('Method not implemented.');
  }

  createItem(specificationVersionId: number, itemName?: string): Observable<EcuResetCategoryItem> {
    const itemToCreate = new EcuResetModel();
    itemToCreate.name = itemName;
    itemToCreate.resetDelay = 0;
    itemToCreate.resetSequence = new ServiceExecutionSequenceModel();
    itemToCreate.specificationVersion = {
      id: specificationVersionId, name: '',
      specificationType: this.specificationService.currentSpecificationVersion.specificationType,
      specification: null, releaseStatus: 0, labels: null
    };

    return this.apiProxy.post<EcuResetModel>(`${environment.apiUrl}versions/${specificationVersionId}/resets/`, itemToCreate).pipe(map(item => {
      const ecuResetCategoryItem = new EcuResetCategoryItem(this.specificationService, this.locationService, this.router, this.activatedRoute);
      ecuResetCategoryItem.ecuResetService = this;
      ecuResetCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      ecuResetCategoryItem.isPreview = false;
      ecuResetCategoryItem.model = item;

      this.items.push(ecuResetCategoryItem);

      return ecuResetCategoryItem;
    }));
  }

  public getCategoryItemModel(specificationVersionId: number, name: string): Observable<EcuResetCategoryItem> {
    return this.apiProxy.get<EcuResetModel>(`${environment.apiUrl}versions/${specificationVersionId}/resets/${name}/`).pipe(map(item => {
      const ecuResetCategoryItem = new EcuResetCategoryItem(this.specificationService, this.locationService, this.router, this.activatedRoute);
      ecuResetCategoryItem.ecuResetService = this;
      ecuResetCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      ecuResetCategoryItem.isPreview = false;
      ecuResetCategoryItem.model = item;

      this.items.push(ecuResetCategoryItem);
      return ecuResetCategoryItem;
    }));
  }

  public deleteItem(specificationVersionId: number, itemName?: string): Observable<any> {
    return this.apiProxy.delete(`${environment.apiUrl}versions/${specificationVersionId}/resets/${itemName}`)
      .pipe(tap(deletedItem => this.removeCachedItem(deletedItem.name)));
  }

  public updateItem(specificationVersionId: number, ecuResetDataModel: any, itemName?: string): Observable<EcuResetModel> {
    return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/resets/${itemName}/`, ecuResetDataModel);
  }
  public getResetItem(specificationVersionId: number, name: string): Observable<EcuResetCategoryItem> {
    return this.apiProxy.get<EcuResetCategoryItem>(`${environment.apiUrl}versions/${specificationVersionId}/resets/${name}/`);
  }
}
