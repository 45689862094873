<div class="alert alert-danger">
Authorization failed!  
<br/>
Kindly raise role access on 
<a class="visited:text-orange-700" href="https://iga.scania.com/identityiq/home.jsf">
   IGA  <mat-icon>open_in_new</mat-icon>
  </a>

</div>

