import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IoModel } from 'app/app-model/io/io.model';
import { CategoryServiceBase } from 'app/app-services/category-base.service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { IoCategoryItemDirective } from 'app/data-categories/io/io-category-item';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { SpecificationService } from './specification-service';
import { PackageImportReport } from 'app/app-model/package-import-report.model';
import { ImportSourceType } from 'app/app-model/enums';

@Injectable({
  providedIn: 'root'
})

export class IoService extends CategoryServiceBase<IoModel, IoCategoryItemDirective> implements OnDestroy {

  constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
    private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected apiProxy: ApiProxyService) {
    super(apiProxy);
    this.apiCategoryName = 'ios';
  }

  ngOnDestroy(): void {
    super.unsubscribe();
  }

  public getItemsPreview(specificationVersionId: number): Observable<IoCategoryItemDirective[]> {
    this.isPending = true;

    if (this.hasCachedItems) {
      this.isPending = false;
      return of(this.items);
    } else {
      return this.apiProxy.get<IoModel[]>(`${environment.apiUrl}versions/${specificationVersionId}/ios`).pipe(map((ios) => {
        this.items = new Array<IoCategoryItemDirective>();
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < ios.length; i++) {
          const ioCategoryItem = new IoCategoryItemDirective(this.specificationService, this.locationService, this.router, this.activatedRoute);
          ioCategoryItem.ioService = this;
          ioCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
          ioCategoryItem.model = ios[i];

          this.items.push(ioCategoryItem);
        }

        this.hasCachedItems = true;
        this.isPending = false;
        this.getItemsPreviewDone.next(this.isPending);

        return this.items;
      }));
    }
  }

  public createItem(specificationVersionId: number, itemName?: string): Observable<IoCategoryItemDirective> {
    const itemToCreate = new IoModel();
    itemToCreate.name = itemName;

    return this.apiProxy.post<IoModel>(`${environment.apiUrl}versions/${specificationVersionId}/ios/`, itemToCreate).pipe(map(item => {
      const ioCategoryItem = new IoCategoryItemDirective(this.specificationService, this.locationService, this.router, this.activatedRoute);
      ioCategoryItem.ioService = this;
      ioCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      ioCategoryItem.isPreview = false;
      ioCategoryItem.model = item;
      ioCategoryItem.isSyncingFromMaster = false;

      this.items.push(ioCategoryItem);

      return ioCategoryItem;
    }));
  }

  getCategoryItemModel(specificationVersionId: number, name: string): Observable<IoCategoryItemDirective> {
    return this.apiProxy.get<IoCategoryItemDirective>(`${environment.apiUrl}versions/${specificationVersionId}/ios/${name}/`).pipe(map(item => {
      const ioCategoryItem = new IoCategoryItemDirective(this.specificationService, this.locationService, this.router, this.activatedRoute);
      ioCategoryItem.ioService = this;
      ioCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      ioCategoryItem.isPreview = false;
      ioCategoryItem.model = item;
      ioCategoryItem.isSyncingFromMaster = false;

      this.items.push(ioCategoryItem);
      return ioCategoryItem;
    }));
  }

  deleteItem(specificationVersionId: number, itemName?: string): Observable<any> {
    return this.apiProxy.delete(`${environment.apiUrl}versions/${specificationVersionId}/ios/${itemName}`)
      .pipe(tap(deletedItem => this.removeCachedItem(deletedItem.name)));
  }

  updateItem(specificationVersionId: number, ioDataModel: any, itemName?: string): Observable<IoModel> {
    return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/ios/${itemName}/`, ioDataModel);
  }

  public getCompleteItems(specificationVersionId: number): Observable<IoCategoryItemDirective[]> {
    throw new Error('Method not implemented.');
  }

  public getIoItem(specificationVersionId: number, name: string): Observable<IoCategoryItemDirective> {
    return this.apiProxy.get<IoCategoryItemDirective>(`${environment.apiUrl}versions/${specificationVersionId}/ios/${name}/`);
  }

  public addIosFromFile(specificationVersionId: number, file: string, importSourceType: ImportSourceType): Observable<PackageImportReport<IoModel>> {
    return this.apiProxy.postString<PackageImportReport<IoModel>>(`${environment.apiUrl}versions/addIos/${specificationVersionId}?importSourceType=${importSourceType}`, file);
  }
}
