import { EventEmitter, Injectable } from '@angular/core';
import { TreeNode } from '../model/tree-node';
import { Tree } from '../model/tree';

@Injectable()
export class TreeService {

  treeNodeSelectedEventEmitter: EventEmitter<TreeNode> = new EventEmitter<TreeNode>(true);
  treeNodeNavigateUpEventEmitter: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  treeNodeNavigateDownEventEmitter: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();

  private selectedTreeNode: TreeNode;

  selectTreeNode(treeNode: TreeNode, notifySelection = true) {

    if (treeNode && treeNode.isSelected) {
      return;
    }

    if (this.selectedTreeNode) {
      this.selectedTreeNode.isSelected = false;
    }

    this.selectedTreeNode = treeNode;

    if (this.selectedTreeNode) {
      this.selectedTreeNode.isSelected = true;
    }

    if (notifySelection) {
      this.treeNodeSelectedEventEmitter.emit(this.selectedTreeNode);
    }
  }

  clearSelection() {
    this.selectTreeNode(null);
  }

  navigateUpFromTreeNode(treeNode: TreeNode) {
    this.treeNodeNavigateUpEventEmitter.emit(treeNode);
  }

  navigateDownFromTreeNode(treeNode: TreeNode) {
    this.treeNodeNavigateDownEventEmitter.emit(treeNode);
  }
}
