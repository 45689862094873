<app-category-header [text]="'IOs'"></app-category-header>
<div class="mb-10 ml-14">
    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" id="importIoPackageModal" [disabled]="!isEditingAllowed">
        <div class="flex flex-row">
            <div><mat-icon class="-mt-4">download</mat-icon></div>
            <div class="ml-5">Import</div>
        </div>        
    </button>
</div>
<div class="sdds-row">
    <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <app-category-items *ngIf="category" [specification]="specification"
            [specificationVersion]="specificationVersion" [showHexcode]="true" [category]="category"
            [isAllowed]="canEditCategoryItems()"></app-category-items>
    </div>

    <div
        class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
         <div *ngIf="ioItem"> <!--[ngClass]="{'loading': isLoading}"> -->
            <app-editable-textbox [overrideFont]="true" [text]="ioItem.name"
                (textEditedEventEmitter)="editingNameDone($event)" [editingNotAllowed]="!isEditingAllowed"
                [editingNotAllowedReason]="editingNotAllowedReason" enclosingElement="h2"
                [isAllowed]="canEditCategoryItems()">
            </app-editable-textbox>

            <div class="my-10 w-2/4 sdds-on-white-bg">
                <sdds-textfield ngDefaultControl size="md" type="text" placeholder="Description"
                    [(ngModel)]="ioItem.model.description" (customChange)="updateDescription()"
                    [disabled]="!isEditingAllowed || !canEditCategoryItems()">
                    <span slot="sdds-label">Description</span>
                </sdds-textfield>
            </div>
            <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg m-10 w-1/4">
                <span class="sdds-dropdown-label-outside"> <b>IO Type</b> </span>
                <select [(ngModel)]="ioItem.model.ioType" name="ioTypeModel" (change)="onIoTypeChange()"
                    [disabled]="!isEditingAllowed">
                    <option *ngFor="let type of availableIoType" [ngValue]="ioType[type]">{{type}}</option>
                </select>
            </div>
            <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg m-10 w-1/4">
                <span class="sdds-dropdown-label-outside"> <b>Scania State</b> </span>
                <select [(ngModel)]="ioItem.model.scaniaState" name="scaniaStateModel" (change)="onScaniaStateChange()"
                    [disabled]="!isEditingAllowed">
                    <option *ngFor="let state of availableScaniaState" [ngValue]="scaniaState[state]">{{state}}</option>
                </select>
            </div>
            <br />
            <app-function-definition [source]="ioItem" [canSetRead]="true"
                [readFlag]="ioItem.isFunctionEnabled(functionType.Read)"
                (readFlagChanged)="enableFunction(functionType.Read, $event)" [canSetWrite]="false" [canSetReset]="true"
                [resetFlag]="ioItem.isFunctionEnabled(functionType.Reset)"
                (resetFlagChanged)="enableFunction(functionType.Reset, $event)" [canSetControl]="true"
                [controlFlag]="ioItem.isFunctionEnabled(functionType.Control)"
                (controlFlagChanged)="enableFunction(functionType.Control, $event)" [isReadOnly]="!isEditingAllowed">
            </app-function-definition>

            <app-function-dashboard [source]="ioItem" *ngIf="ioItem.hasAnyFunctionEnabled"
                [isReadOnly]="!isEditingAllowed" [hideAddButtonWithOneService]="true">
            </app-function-dashboard>

            <div class="sdds-banner" *ngIf="!ioItem.activeFunction">
                <div class="sdds-banner-body">
                    No IO functions enabled. To enable a function, select the corresponding checkbox.
                </div>
            </div>
        </div>
    </div>
</div>

<app-import-category-item-package-dialog 
    [specificationVersion]="specificationVersion"
    (modalApply)="refreshAllIos()"></app-import-category-item-package-dialog>