/* eslint-disable @typescript-eslint/naming-convention */
export class SpecificationVersionModification {
    public specificationVersionId: number;
    public userThatModified: string;
    public modificationDate: string;
    public modificationType: ModificationType;
    public modifiedItemId: number;
    public modifiedItemName: string;
    public modifiedCategory: ModifiedCategory;
    public changes: string;
}

// eslint-disable-next-line no-shadow
export enum ModificationType {
    Add,
    Update,
    Delete,
    Upgrade,
    Unlink,
    Link
}

// eslint-disable-next-line no-shadow
export enum ModifiedCategory {
    Mode,
    Identifier,
    Fingerprint,
    IO,
    OperationalDataVariable,
    Reset,
    Session,
    Value,
    ServerFile,
    KeyFile
}