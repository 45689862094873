import { Component, OnInit } from '@angular/core';
import { Specification } from 'app/app-model/specification';
import { SpecificationVersion } from 'app/app-model/specification-version';
import { SpecificationService } from 'app/app-services/specification-service';

@Component({
  selector: 'app-content-navbar',
  templateUrl: './content-navbar.component.html',
  styleUrls: ['./content-navbar.component.css']
})
export class ContentNavbarComponent implements OnInit {
  currentSpecification: Specification;
  currentSpecificationVersion: SpecificationVersion;

  constructor(private specificationService: SpecificationService) { }

  ngOnInit() {
    this.specificationService.selectedSpecificationEventEmitter.subscribe((event) => {
      this.currentSpecification = event.specification;
      this.currentSpecificationVersion = event.specificationVersion;
    });
  }

}
