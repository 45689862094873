import { Injectable } from '@angular/core';

export class StartComponentSettings {
  private _selectedSpecification: number;
  public get selectedSpecification(): number {
    return this._selectedSpecification;
  }
  public set selectedSpecification(v: number) {
    this._selectedSpecification = v;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  private _startComponentSettings: StartComponentSettings;

  constructor() {
    this.startComponentSettings = new StartComponentSettings();
  }

  public get startComponentSettings(): StartComponentSettings {
    return this._startComponentSettings;
  }
  public set startComponentSettings(v: StartComponentSettings) {
    this._startComponentSettings = v;
  }
}
