import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-confirm',
  templateUrl: './action-confirm.component.html',
  styleUrls: ['./action-confirm.component.css']
})
export class ActionConfirmComponent {

  @Output()
  accept: EventEmitter<void> = new EventEmitter();

  @Input()
  confirmTitle: string;

  @Input()
  confirmDesc: string;

  apply() {
    this.accept.next();
  }

  cancel() {

  }
}
