import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { IoType, ScaniaState } from '../enums';

export class IoModel {
  name: string;
  description: string;
  readSequence: ServiceExecutionSequenceModel;
  controlSequence: ServiceExecutionSequenceModel;
  resetSequence: ServiceExecutionSequenceModel;
  readIdStatusSequence: ServiceExecutionSequenceModel;
  ioType: IoType;
  scaniaState: ScaniaState;
}
