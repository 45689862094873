<div class="sdds-row">
  <div class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-5 sdds-col-sm-5 sdds-col-xs-5" style="overflow-y: auto;max-height:60vh;">
    <h4>Available Files</h4>
    <div *ngIf="!fileTree || fileTree.isEmpty" class="sdds-banner my-10">
      <div class="sdds-banner-body">
        No available shared files to assign.
      </div>
    </div>

    <ng-container *ngIf="fileTree && !fileTree.isEmpty">
      <app-toolbar #toolbar [filterEnabled]="true" [addDataTargetEnabled]="false" addDataTarget="#createNewSpecificationsModal"
        (filterTextEventEmitter)="filter($event);" [collapseEnabled]="false" [dataTargetEnabledRemove]="true" [expandEnabled]="false"></app-toolbar>

      <app-tree [tree]="fileTree"></app-tree>
    </ng-container>
  </div>

  <div class="sdds-col-max-1 sdds-col-xxlg-1 sdds-col-xlg-1 sdds-col-lg-1 sdds-col-md-1 sdds-col-sm-1 sdds-col-xs-1">
    <button class="sdds-btn sdds-btn-primary sdds-btn-sm mt-24" [disabled]="!selectedSharedFileSpecification || assignTreeDoesNotContainFile" (click)="assignSelectedFile()">
      >>
    </button>
    <br />
    <button *ngIf="filesToAssignTree && !filesToAssignTree.isEmpty" [disabled]="!selectedAssignedSharedFileSpecification"
      class="sdds-btn sdds-btn-primary sdds-btn-sm mt-24" style="margin-top: 20px" (click)="unAssignSelectedFile()">
      &lt;&lt;
    </button>
  </div>

  <div class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-5 sdds-col-sm-5 sdds-col-xs-5 ml-5" *ngIf="filesToAssignTree && !filesToAssignTree.isEmpty" style="overflow-y: auto;max-height:60vh;">
    <h4>Files to assign</h4>
    <ng-container>
      <app-tree [tree]="filesToAssignTree"></app-tree>
    </ng-container>
  </div>
</div>
