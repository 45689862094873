import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthService } from '../../authentication/services/auth.service';
import { ApiErrorDetails } from '../model/api/api-error-details';
import { ApiMessageService } from './api-message-service';

@Injectable({
  providedIn: 'root'
})
export class ApiProxyService {

  constructor(private http: HttpClient, private apiMessageService: ApiMessageService, private authService: AuthService, private messageService: MessageService) {
  }

  getString(url: string): Observable<string> {
    const apiMessage = this.apiMessageService.sendGetMessage();
    // const headers = this.createHeaders();
    const httpOptions = { responseType: 'text' as 'text' };

    return this.http.get(url, httpOptions).pipe(tap(() => {
      apiMessage.sendCompleted();
    })).pipe(catchError((e: any) => {
      const errorMessage = this.errorHandler(e);
      apiMessage.sendError(errorMessage);
      if (!environment.production) {
        this.messageService.dispatchErrorMessageFromApi(errorMessage);
      }
      return throwError(errorMessage);
    }));
  }

  get<T>(url: string): Observable<T> {
    const apiMessage = this.apiMessageService.sendGetMessage();
    const headers = this.createHeaders();
    return this.http.get<T>(url).pipe(tap(() => {
      apiMessage.sendCompleted();
    })).pipe(catchError((e: any) => {
      const errorMessage = this.errorHandler(e);
      apiMessage.sendError(errorMessage);
      if (!environment.production) {
        this.messageService.dispatchErrorMessageFromApi(errorMessage);
      }
      return throwError(errorMessage);
    }));
  }

  put<T, Z = T>(url: string, data: T): Observable<Z> {
    const apiMessage = this.apiMessageService.sendPutMessage();
    return this.http.put<Z>(url, data, { headers: this.createHeaders() }).pipe(tap(() => {
      apiMessage.sendCompleted();
    }), catchError((e: any) => {
      const errorMessage = this.errorHandler(e);
      apiMessage.sendError(errorMessage);
      // if (!environment.production) {
      this.messageService.dispatchErrorMessageFromApi(errorMessage);
      // }
      return throwError(errorMessage);
    }));
  }

  postString<Y>(url: string, payload: string): Observable<Y> {
    const apiMessage = this.apiMessageService.sendPostMessage();
    const httpOptions = { headers: this.createTextPlainHeaders() };

    return this.http.post<Y>(url, payload, httpOptions).pipe(tap(() => {
      apiMessage.sendCompleted();
    }), catchError((e: any) => {
      const errorMessage = this.errorHandler(e);
      apiMessage.sendError(errorMessage);
      // if (!environment.production) {
      this.messageService.dispatchErrorMessageFromApi(errorMessage);
      // }
      return throwError(errorMessage);
    }));

  }

  post<T, Y = T>(url: string, data: T): Observable<Y> {
    const apiMessage = this.apiMessageService.sendPostMessage();
    return this.http.post<Y>(url, data, { headers: this.createHeaders() }).pipe(tap(() => {
      apiMessage.sendCompleted();
    }), catchError((e: any) => {
      const errorMessage = this.errorHandler(e);
      apiMessage.sendError(errorMessage);
      // if (!environment.production) {
      this.messageService.dispatchErrorMessageFromApi(errorMessage);
      // }
      return throwError(errorMessage);
    }));
  }

  delete<T>(url: string): Observable<T> {
    const apiMessage = this.apiMessageService.sendDeleteMessage();
    return this.http.delete<T>(url, { headers: this.createHeaders() }).pipe(tap(() => {
      apiMessage.sendCompleted();
    }), catchError((e: any) => {
      const errorMessage = this.errorHandler(e);
      apiMessage.sendError(errorMessage);
      if (!environment.production) {
        this.messageService.dispatchErrorMessageFromApi(errorMessage);
      }
      return throwError(errorMessage);
    }));
  }

  private createHeadersWithStringAsContentType() {
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      // Authorization: this.authService.getAuthorizationHeaderValue(),
      ContentType: 'text/plain',
      Accept: 'text/plain',
      ResponseType: 'text'
    });
    return headers;
  }

  private createHeaders() {
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      // Authorization: this.authService.getAuthorizationHeaderValue(),
    });

    const authorizationHeaderValue = headers.get('Authorization');
    return headers;
  }

  private createTextPlainHeaders() {
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      // Authorization: this.authService.getAuthorizationHeaderValue(),
      ContentType: 'text/plain;charset=UTF-8'
    });
    return headers;
  }

  private errorHandler(error: any) {
    const httpErrorResponse = error as HttpErrorResponse;
    const apiErrorDetails = new ApiErrorDetails();
    apiErrorDetails.userMessage = 'An error occurred. Try again, otherwise contact your local helpdesk.';

    if (httpErrorResponse) {
      apiErrorDetails.statusCode = httpErrorResponse.status;
      apiErrorDetails.statusText = httpErrorResponse.statusText;
      apiErrorDetails.detailedMessage = httpErrorResponse.error ? httpErrorResponse.error.detailedMessage : '';

      const jsonObject = this.getJsonObject(httpErrorResponse.error);

      if (jsonObject instanceof Error) {
        apiErrorDetails.detailedMessage = httpErrorResponse.error.message;
      } else if (jsonObject instanceof ProgressEvent) {
        apiErrorDetails.detailedMessage = 'An error occurred while communicating with the server. Make sure your network connection is functioning properly.';
      } else if (jsonObject && jsonObject.userMessage) {
        apiErrorDetails.detailedMessage = jsonObject.detailedMessage;
        apiErrorDetails.userMessage = jsonObject.userMessage;
      } else if (jsonObject && jsonObject.errorType === 2) {
        apiErrorDetails.detailedMessage = jsonObject.detailedMessage;
        apiErrorDetails.userMessage = 'Api validation error';
      } else if (jsonObject && jsonObject.errorType === 7) {
        apiErrorDetails.detailedMessage = jsonObject.detailedMessage;
        apiErrorDetails.userMessage = 'Resource authorization failed';
      } else if (typeof httpErrorResponse.error === 'string') {
        apiErrorDetails.detailedMessage = httpErrorResponse.error;
      }
    }

    return apiErrorDetails;
  }

  private getJsonObject(str) {
    try {

      if (typeof str === 'object') {
        return str;
      }

      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }
}
