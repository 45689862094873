import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EcuResetComponent } from 'app/data-categories/ecu-reset/ecu-reset.component';
import { LegacyFilesComponent } from 'app/data-categories/legacy-files/legacy-files.component';
import { OperationalDataComponent } from 'app/data-categories/operational-data/operational-data.component';

import { AdminDataTableComponent } from './admin-user-management/admin-data-table.component';
import { DiagnosticServicesComponent } from './data-categories/diagnostic-services/diagnostic-services.component';
import { FingerprintComponent } from './data-categories/fingerprint/fingerprint.component';
import { FreezeFramesComponent } from './data-categories/freeze-frames/freeze-frames.component';
import {
  IdentificationPropertyComponent,
} from './data-categories/identification-property/identification-property.component';
import { IoComponent } from './data-categories/io/io.component';
import { IolistComponent } from './data-categories/iolist/iolist.component';
import { PreconditionComponent } from './data-categories/precondition/precondition.component';
import { SecurityAccessComponent } from './data-categories/security-access/security-access.component';
import { ServerIdentificationComponent } from './data-categories/server-identification/server-identification.component';
import { SessionsComponent } from './data-categories/sessions/sessions.component';
import { SharedFilesComponent } from './data-categories/shared-files/shared-files.component';
import { StateChartComponent } from './data-categories/state-chart/state-chart.component';
import { TeststepsComponent } from './data-categories/teststeps/teststeps.component';
import { ValidationIssueComponent } from './data-categories/validation-issue/validation-issue.component';
import { ValuesComponent } from './data-categories/values/values.component';
import { DiagnosticFamiliesComponent } from './diagnostic-families/diagnostic-families.component';
import { IdentificationGroupsComponent } from './identification-groups/identification-groups.component';
import { KeysetComponent } from './keyset/keyset.component';
import { ModificationInfoTableComponent } from './modification-info-table/modification-info-table.component';
import { AuthCallbackComponent } from './modules/authentication/auth-callback/auth-callback.component';
import { AuthorizationFailedComponent } from './modules/authentication/authorization-failed/authorization-failed.component';
import { AuthguardService } from './modules/authentication/services/authguard.service';
import { BuildComponent } from './modules/release/components/build/build.component';
import { GlobalExportComponent } from './modules/release/components/global-export/global-export.component';
import { ViewComponent } from './modules/release/components/view/view.component';
import { PageNotFoundComponent } from './modules/shared/components/page-not-found/page-not-found.component';
import { RbacDetailComponent } from './rbac-detail/rbac-detail.component';
import { SpecificationBrowserComponent } from './specification-browser/specification-browser.component';
import { SpecificationHomeComponent } from './specification-home/specification-home.component';
import { SpecificationComponent } from './specification/specification.component';
import { StartPageComponent } from './start-page/start-page.component';
import { ManageTemplatesComponent } from './manage-templates/manage-templates.component';
import { RbacLogComponent } from './rbac-log/rbac-log.component';
import { NewRbacComponent } from './rbac/new-rbac/new-rbac.component';
import { RbacViewComponent } from './rbac-view/rbac-view.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { AppComponent } from './app.component';
import { IvdCategoryItem } from './data-categories/ivd-override/ivd-category-item';
import { IvdOverrideComponent } from './data-categories/ivd-override/ivd-override.component';
import { RoleGuard } from './modules/authentication/RoleGuard';
import { VsocMonitoringComponent } from './data-categories/vsoc-monitoring/vsoc-monitoring-component';

const routes: Routes = [
  // {
  //   path: 'auth-callback',
  //   component: AuthCallbackComponent
  // },
  {
    path: 'authorization-failed',
    component: AuthorizationFailedComponent
  },

  {
    path: '',
    // canActivate: [AuthguardService],
    canActivate: [MsalGuard,RoleGuard],
    canActivateChild: [MsalGuard, RoleGuard],
    children: [
      {
        path: 'release',
        children: [
          {
            path: 'view/:viewId',
            component: ViewComponent,
          },
          {
            path: 'export/:id',
            component: GlobalExportComponent
          },
          {
            path: 'export',
            component: PageNotFoundComponent
          },
          {
            path: 'build',
            component: BuildComponent
          },
          {
            path: '',
            redirectTo: 'view/',
            pathMatch: 'full'
          },
          {
            path: 'view',
            redirectTo: 'view/',
            pathMatch: 'full'
          },
          {
            path: '**',
            component: PageNotFoundComponent
          }]
      },
      {
        path: 'rbac-files',
        redirectTo: 'rbac-files/',
        pathMatch: 'full'
        // component: RbacDetailComponent,
      },
      {
        path: 'rbac-files/new-rbac',
        component: PageNotFoundComponent,
      },
      {
        path: 'rbac-files/new-rbac/:rbacId',
        component: PageNotFoundComponent,
      },
      {
        path: 'rbac-files/:rbacId',
        component: PageNotFoundComponent,
      },
      {
        path: 'rbac-files/:rbacId/history',
        component: PageNotFoundComponent,
      },
      {
        path: 'specification/:specId/:specVersionId',
        component: SpecificationComponent,
        canActivate: [MsalGuard],
        canActivateChild: [MsalGuard],
        children: [
          {
            path: '',
            component: SpecificationHomeComponent
          },
          {
            path: 'services',
            component: DiagnosticServicesComponent
          },
          {
            path: 'services/:itemId',
            component: DiagnosticServicesComponent
          },
          {
            path: 'services/:itemId/:parameterId',
            component: DiagnosticServicesComponent
          },
          {
            path: 'services/:itemId/:parameterId/:messageType',
            component: DiagnosticServicesComponent
          },
          {
            path: 'servers',
            component: ServerIdentificationComponent
          },
          {
            path: 'servers/:itemId',
            component: ServerIdentificationComponent
          },
          {
            path: 'sessions',
            component: SessionsComponent
          },
          {
            path: 'sessions/:itemId',
            component: SessionsComponent
          },
          {
            path: 'ecu-resets',
            component: EcuResetComponent
          },
          {
            path: 'ecu-resets/:itemId',
            component: EcuResetComponent
          },
          {
            path: 'ivd-overrides',
            component: IvdOverrideComponent
          },
          {
            path: 'ivd-overrides/:itemId',
            component: IvdOverrideComponent
          },
          {
            path: 'identifications',
            component: IdentificationPropertyComponent
          },
          {
            path: 'identifications/:itemId',
            component: IdentificationPropertyComponent
          },
          {
            path: 'ios',
            component: IoComponent
          },
          {
            path: 'ios/:itemId',
            component: IoComponent
          },
          {
            path: 'iolists',
            component: IolistComponent
          },
          {
            path: 'iolists/:itemId',
            component: IolistComponent
          },
          {
            path: 'test-steps',
            component: TeststepsComponent
          },
          {
            path: 'fingerprints',
            component: FingerprintComponent
          },
          {
            path: 'fingerprints/:itemId',
            component: FingerprintComponent
          },
          {
            path: 'values',
            component: ValuesComponent
          },
          {
            path: 'values/:itemId',
            component: ValuesComponent
          },
          {
            path: 'operational-datas',
            component: OperationalDataComponent
          },
          {
            path: 'operational-data/:itemId',
            component: OperationalDataComponent
          },
          {
            path: 'security-access',
            component: SecurityAccessComponent
          },
          {
            path: 'security-access/:itemId',
            component: SecurityAccessComponent
          },
          {
            path: 'issues',
            component: ValidationIssueComponent
          },
          {
            path: 'files',
            component: LegacyFilesComponent
          },
          {
            path: 'charts',
            component: StateChartComponent
          },
          {
            path: 'charts/:itemId',
            component: StateChartComponent
          },
          {
            path: 'preconditions',
            component: PreconditionComponent
          },
          {
            path: 'freezeframes',
            component: FreezeFramesComponent
          },
          {
            path: 'vsoc',
            component: VsocMonitoringComponent
          },
          {
            path: 'vsoc/:itemId',
            component: VsocMonitoringComponent
          },
          {
            path: 'modification-table/:specificationVersionId',
            component: ModificationInfoTableComponent
          },
          {
            path: '**',
            component: PageNotFoundComponent
          }
        ]
      },
      {
        path: 'content',
        canActivate: [MsalGuard,RoleGuard],
        canActivateChild: [MsalGuard, RoleGuard],
        children: [
          {
            path: '',
            redirectTo: 'specifications',
            pathMatch: 'full'
          },
          {
            path: 'keyset/:id',
            component: KeysetComponent
          },
          {
            path: 'keyset',
            redirectTo: 'keyset/0',
            pathMatch: 'full'
          },
          {
            path: 'shared-files',
            component: SharedFilesComponent
          },
          {
            path: 'admin-data-table',
            component: AdminDataTableComponent
          },
          {
            path: 'identification-groups/:id',
            component: IdentificationGroupsComponent
          },
          {
            path: 'identification-groups',
            redirectTo: 'identification-groups/0',
            pathMatch: 'full'
          },
          {
            path: 'diagnostic-families',
            redirectTo: 'diagnostic-families/0',
            pathMatch: 'full'
          },
          {
            path: 'diagnostic-families/:id',
            component: DiagnosticFamiliesComponent
          },
          {
            path: 'manage-templates',
            component: ManageTemplatesComponent
          },
          {
            path: 'specifications',
            component: StartPageComponent,
          },
          {
            path: '**',
            component: PageNotFoundComponent
          }
        ]
      },
      {
        path: 'content/specifications/:specId/:specVersionId',
        component: SpecificationBrowserComponent,
      },
      {
        path: '',
        redirectTo: '/content/specifications',
        pathMatch: 'full',
      },
      {
        // Dedicated route for redirects
        path: 'auth',
        component: MsalRedirectComponent,
      },
      {
        path: '**',
        component: PageNotFoundComponent
      }
    ]
  }];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
