import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryItem } from 'app/app-model/category-item';
import { CategoryName } from 'app/app-model/enums';
import { CategoryItemsService } from 'app/app-services/category-items-service';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class DataCategoryModel {
  name: string;
  id: string;
  category: Category;
  items: Array<CategoryItem> = new Array<CategoryItem>();
  router: Router;
  route: ActivatedRoute;
  isUpdated: boolean;
  hasLegacySupport: boolean;
  categoryItemsService: CategoryItemsService;
  addWithoutPromptEnabled: boolean;
  numberOfItems = 0;
  // enabled: boolean;

  
  private _enabled : boolean;
  public get enabled() : boolean {
    return this._enabled;
  }
  public set enabled(v : boolean) {
    this._enabled = v;
  }
  
  private _activeItem: CategoryItem;

  constructor(
    router: Router,
    route: ActivatedRoute) {
    this.route = route;
    this.router = router;
  }

  public get activeItem(): CategoryItem {
    return this._activeItem;
  }

  public set activeItem(v: CategoryItem) {
    this._activeItem = v;
    this.selectItem(v);
  }

  public getPreviousItem() {
    const itemIndex = this.items.indexOf(this.activeItem);

    if (itemIndex === 0) {
      return null;
    }

    return this.items[itemIndex - 1];
  }

  public getNextItem() {
    const itemIndex = this.items.indexOf(this.activeItem);

    if (itemIndex + 1 === this.items.length) {
      return null;
    }

    return this.items[itemIndex + 1];
  }

  createNewItem(itemName: string, specificationVersionId: number): Observable<CategoryItem> {
    return this.categoryItemsService.createNewItem(specificationVersionId, this.category, itemName);
  }

  setItems(items: Array<CategoryItem>) {
    this.items = new Array<CategoryItem>().concat(items);
    this.numberOfItems = items.length;
  }

  addItemToContainer(item: CategoryItem) {
    const itemToPush = [item];
    this.items = itemToPush.concat(this.items);
  }

  removeItem(specificationVersionId: number, itemToRemove: CategoryItem): Observable<any> {
    const itemId = this.getItemIdToUseInRoute(itemToRemove);
    return this.categoryItemsService.deleteItem(specificationVersionId, this.category, itemId);
  }

  reset() {
    this.isUpdated = false;
    this.items = [];
    this.numberOfItems = 0;
    this.resetActiveItem();
  }

  removeItemFromContainerAndGetNext(itemToRemove: CategoryItem): CategoryItem {
    const indexOfItemToRemove = this.itemIndex(itemToRemove);
    let nextItem: CategoryItem;

    if (indexOfItemToRemove === 0 && this.items.length > 1) {
      nextItem = this.items[1];
    } else if (this.items.length > 1) {
      nextItem = this.items[indexOfItemToRemove - 1];
    } else {
      nextItem = undefined;
    }

    this.items.splice(indexOfItemToRemove, 1);

    return nextItem;
  }

  itemIndex(categoryItem: CategoryItem): number {
    return this.items.findIndex(item => item.name ? (item.name === categoryItem.name) : item.id === categoryItem.id);
  }

  getItem(itemIndex: number): CategoryItem {
    return this.items[itemIndex];
  }

  setActiveItem(specificationVersionId: number, itemId: string) {
    return this.getCompleteItem(specificationVersionId, itemId).pipe(tap(item => {
      this.activeItem = item;
    }));
  }

  getCompleteItem(specificationVersionId: number, itemId: string): Observable<CategoryItem> {
    return this.categoryItemsService.getCompleteItem(specificationVersionId, this.category, itemId);
  }

  resetActiveItem() {
    this.activeItem = null;
  }

  findItem(id: number): CategoryItem {
    return this.items.find(i => i.id === id);
  }

  selectFirstOrDefault() {
  }

  hasItems(): boolean {
    return this.items && this.items.length > 0;
  }

  private selectItem(item: CategoryItem) {
    this.items.forEach(i => {
      i.isSelected = false;
    });

    if (item) {
      item.isSelected = true;
    }
  }

  private getItemIdToUseInRoute(item: CategoryItem): string {
    if (item.categoryType == CategoryName.OperationalDataVariable) {
      return item.resourceId;
    }

    return item.name ? item.name : item.id + '';
  }
}
