import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { SpecificationVersion } from '../../../app-model/specification-version';
import { ApiProxyService } from '../../shared/services/api-proxy.service';
import { View } from '../model/view';

@Injectable()
export class ViewService {
  constructor(private apiProxy: ApiProxyService) {
  }

  getView(id: number): Observable<View> {
    return this.apiProxy.get(environment.apiUrl + 'view/' + id);
  }

  getViews(): Observable<View[]> {
    return this.apiProxy.get(environment.apiUrl + 'view').pipe(map((v: View[]) => v.map(viewToMap => this.mapToView(viewToMap))));
  }

  addView(view: View): Observable<View> {
    return this.apiProxy.post(environment.apiUrl + 'view', view).pipe(map((v: View) => this.mapToView(v)));
  }

  updateView(view: View): Observable<View> {
    return this.apiProxy.put(environment.apiUrl + 'view/' + view.id, view).pipe(map((v: View) => this.mapToView(v)));
  }

  deleteView(view: View): Observable<View> {
    return this.apiProxy.delete(environment.apiUrl + 'view/' + view.id).pipe(map((v: View) => this.mapToView(v)));
  }

  addSpecificationVersionToView(view: View, specificationVersion: SpecificationVersion): Observable<SpecificationVersion> {
    return this.apiProxy.post(environment.apiUrl + 'view/' + view.id + '/specificationVersion', specificationVersion);
  }

  deleteSpecificationVersionFromView(view: View, specificationVersion: SpecificationVersion): Observable<SpecificationVersion> {
    return this.apiProxy.delete(environment.apiUrl + 'view/' + view.id + '/specificationVersion/' + specificationVersion.id);
  }

  mapToView(viewResponse: View): View {
    const view = new View();
    view.updateFrom(viewResponse);
    return view;
  }
}
