import { Location } from '@angular/common';
import { IvdCategoryItem } from "app/data-categories/ivd-override/ivd-category-item";
import { CategoryServiceBase } from "./category-base.service";
import { IvdOverrideModel } from "app/app-model/ivd/ivd.model";
import { Observable, of } from "rxjs";
import { environment } from "environments/environment";
import { map, tap } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiProxyService } from "app/modules/shared/services/api-proxy.service";
import { SpecificationService } from "./specification-service";
import { DiagnosticServiceProvider } from "./diagnostic.service.service";
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class IvdOverrideService extends CategoryServiceBase<IvdOverrideModel, IvdCategoryItem>{

    constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
        private specificationService: SpecificationService,
        private locationService: Location,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        protected apiProxy: ApiProxyService) {
        super(apiProxy);
    }

    getCategoryItemModel(specificationVersionId: number, name: string): Observable<IvdCategoryItem> {        
        return this.apiProxy.get<IvdOverrideModel>(`${environment.apiUrl}versions/ivd-overrides/by-version/${specificationVersionId}?name=${name}/`).pipe(map(ivdOverrideModel => {
            var ivdOverrideCategoryItem = this.createIvdCategoryItemFromModel(ivdOverrideModel);
            this.items.push(ivdOverrideCategoryItem);
            return ivdOverrideCategoryItem;
        }));
    }

    getItemsPreview(specificationVersionId: number): Observable<IvdCategoryItem[]> {
        this.isPending = true;

        if (this.hasCachedItems) {
            this.isPending = false;
            return of(this.items);
        } else {
            return this.apiProxy.get<IvdOverrideModel[]>(`${environment.apiUrl}versions/${specificationVersionId}/ivd-overrides`).pipe(map(ivdOverrides => {
                this.items.splice(0, this.items.length);
                let ivdOverrideCategoryItems = ivdOverrides.map(ivdOverrideModel => this.createIvdCategoryItemFromModel(ivdOverrideModel));
                this.items = this.items.concat(ivdOverrideCategoryItems);
                this.hasCachedItems = true;
                this.isPending = false;
                this.getItemsPreviewDone.next(this.isPending);

                return this.items;
            }));
        }
    }

    toggleIvdOverride(specificationVersionId: number, isEnabled: boolean) {
        return this.apiProxy.post<IvdOverrideModel[]>(`${environment.apiUrl}versions/${specificationVersionId}/ivd-overrides/toggle?enabled=${isEnabled}`, undefined)
            .pipe(tap(ivdOverrides => {
                this.items.splice(0, this.items.length);
                let ivdOverrideCategoryItems = ivdOverrides.map(ivdOverrideModel => this.createIvdCategoryItemFromModel(ivdOverrideModel));
                this.items = this.items.concat(ivdOverrideCategoryItems);
            }));
    }

    updateItem(specificationVersionId: number, itemModel: IvdOverrideModel, itemName?: string, itemId?: number): Observable<IvdOverrideModel> {
        return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/ivd-overrides`, itemModel);
    }

    getCompleteItems(specificationVersionId: number): Observable<IvdCategoryItem[]> {
        throw new Error("Method not implemented.");
    }

    createItem(specificationVersionId: number, itemName?: string, itemId?: number): Observable<IvdCategoryItem> {
        throw new Error("This category does not have support for adding items");
    }

    deleteItem(specificationVersionId: number, itemName?: string, itemId?: number): Observable<any> {
        throw new Error("This category does not have support for removing items");
    }

    private createIvdCategoryItemFromModel(ivdOverrideModel: IvdOverrideModel): IvdCategoryItem {
        const ivdOverrideCategoryItem = new IvdCategoryItem(this.specificationService, this.locationService, this.router, this.activatedRoute);
        ivdOverrideCategoryItem.ivdOverrideService = this;
        ivdOverrideCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
        ivdOverrideCategoryItem.isPreview = false;
        ivdOverrideCategoryItem.model = ivdOverrideModel;

        return ivdOverrideCategoryItem;
    }
}