import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { UserService } from 'app/app-services/user.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { User } from '../modules/shared/model/user';
import { AdminDataTableDataSource } from './admin-data-table-datasource';

@Component({
  selector: 'app-admin-data-table',
  templateUrl: './admin-data-table.component.html',
  styleUrls: ['./admin-data-table.component.css'],
  providers: [UserService]
})
export class AdminDataTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<User>;
  dataSource: MatTableDataSource<any>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['firstName', 'lastName', 'userName', 'lastSeen', 'removeuser'];
  private adminDataTableDataSource: AdminDataTableDataSource;

  constructor(private userService: UserService,
    private authService: AuthService) { }

  ngOnInit() {
    this.initDataSource();
    this.setCurrentUser();
  }

  initDataSource() {
    this.adminDataTableDataSource = new AdminDataTableDataSource();
    this.getUsersFromService().subscribe(users => {
      this.dataSource = new MatTableDataSource(users);
      this.adminDataTableDataSource.setUsers(users);
      this.dataSource.filterPredicate = (data, filter: string) =>
        (data.firstName != null && data.firstName.toLowerCase().includes(filter)) ||
        (data.lastName != null && data.lastName.toLowerCase().includes(filter)) ||
        (data.userName != null && data.userName.toLowerCase().includes(filter));
      this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLocaleLowerCase().trim();
  }

  deleteUser(user: User) {
    this.userService.deleteUser(user).subscribe(deletedUser => {
      this.dataSource.data = this.removeUserFromList(user);
    });
  }

  setCurrentUser() {
    if (this.canEditUsers()) {
      this.displayedColumns = ['firstName', 'lastName', 'userName', 'lastSeen', 'removeuser'];
    }
    else {
      this.displayedColumns = ['firstName', 'lastName', 'userName'];
    }
  }

  editFirstName(newfirstName: string, user: User) {
    if (newfirstName != null && newfirstName !== '') {
      user.firstName = newfirstName;
      this.updateUser(user);
    }
  }

  editLastName(newLastName: string, user: User) {
    if (newLastName != null && newLastName !== '') {
      user.lastName = newLastName;
      this.updateUser(user);
    }
  }

  private getUsersFromService() {
    return this.userService.getUsers();
  }

  private updateUser(user: User) {
    this.userService.updateUser(user).subscribe();
  }

  private removeUserFromList(user: User): User[] {
    if (this.dataSource.data == null || this.dataSource.data.length === 0 || this.dataSource.data.indexOf(user) < 0) {
      return this.dataSource.data;
    }
    console.log(this.dataSource.data.splice(this.dataSource.data.indexOf(user), 1));
    return this.dataSource.data;
  }

  canEditUsers() {
    return this.authService.canEditUsers();
  }

}
