/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum ServerIdentificationExpressionNodeType {
    AndOperator,
    OrOperator,
    NotOperator,
    XorOperator,
    EcuIdentifierCompareOperand,
    LegacyEcuIdentifierCompareOperand,
    CanAddressCompareOperand,
    ExecutionModeCompareOperand,
    SpecialCompareOperator,
    DoesNoExistCompareOperator
}

export enum ServerIdentificationCompareOperatorType {
    EqualTo,
    NotEqualTo,
    LessThan,
    LessThanOrEqualTo,
    GreaterThanOrEqualTo,
    GreaterThan,
    StartsWith,
    Contains,
    DoesNotExist
}

export enum DataLimitType {
    Open,
    Closed,
    Infinite
}
