<button [routerLink]="['./../']" class="sdds-btn sdds-btn-primary sdds-btn-sm export-main-btn">
    Return
</button>

<div class="flex flex-col">
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div class="overflow-hidden">
                <table class="min-w-full text-center text-6xl font-light">
                    <thead class="border-b font-medium dark:border-neutral-500">
                        <tr>
                            <th scope="col" class="px-6 py-4">Changed Item</th>
                            <th scope="col" class="px-6 py-4">Action</th>
                            <th scope="col" class="px-6 py-4">User</th>
                            <th scope="col" class="px-6 py-4">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let log of logs"
                            class="border-b border-success-200 bg-success-100 text-neutral-800">
                            <td class="whitespace-nowrap px-6 py-4 font-medium">
                                {{log.changedItem}}
                            </td>
                            <td *ngIf="log.changeType === 0" class="whitespace-nowrap px-6 py-4 text-green-500">
                                {{getChangeType(log.changeType)}}
                            </td>
                            <td *ngIf="log.changeType === 1" class="whitespace-nowrap px-6 py-4 text-red-500">
                                {{getChangeType(log.changeType)}}
                            </td>
                            <td class="whitespace-nowrap px-6 py-4">{{log.user}}</td>
                            <td class="whitespace-nowrap px-6 py-4">{{log.date | date: "yyyy-MM-dd HH:mm"}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>