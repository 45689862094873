<div *ngIf="loading" class="loader-container">
  <div class="loader"></div>
</div>

<div class="sdds-row">
  <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3">
    <!-- [class.loading]="loading"> -->
    <div class="bg-color-gray p-16 mb-16">
      <h4>Diagnostic Families</h4>

      <app-toolbar [filterEnabled]="true" [removeEventEmitterEnabled]="true"
        (filterTextEventEmitter)="filterText = $event" [addEventEmitterEnabled]="true"
        (addEventEmitter)="addDiagnosticFamily();" [dataTargetEnabledAdd]="false" [dataTargetEnabledRemove]="false"
        (removeEventEmitter)="deleteDiagnosticFamily();" [isAllowed]="canEditDignosticFamilies()"></app-toolbar>

      <div *ngIf="diagnosticFamilies">
        <div class="content-list-container">
          <app-common-list [list]="diagnosticFamilies | nameFilter: filterText"
            (selectedItemEventEmitter)="diagnosticFamilySelected($event);" [itemTemplate]="diagnosticFamilyTemplate"
            [selectedItem]="selectedDiagnosticFamily" [isAllowed]="canEditDignosticFamilies()"></app-common-list>
        </div>

        <ng-template #diagnosticFamilyTemplate let-item>
          <div>{{item.name}}</div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-9 sdds-col-md-9 sdds-col-sm-9 sdds-col-xs-9"
    *ngIf="selectedDiagnosticFamily">
    <div class="alert alert-info">
      You can change the name for the diagnostic family, add generations and edit generation names. <strong>Notice
      </strong> if you change the name on one of the previous
      mentioned, you will update all specification that uses the family/generation for server identification. This
      will
      <strong>ONLY</strong> affect work that are not released.
    </div>
    <br>
    <div class="sdds-row">
      <h1>Family name:</h1>
      <app-editable-textbox enclosingElement="h2" [text]="selectedDiagnosticFamily.name"
        (textEditedEventEmitter)="diagnosticFamilyNameChanged($event);" class="sdds-col-md-6"
        [isAllowed]="canEditDignosticFamilies()">
      </app-editable-textbox>
    </div>
    <div class="banner text-white w-full p-1.5 pl-3 mb-0">
      <h3>Generations of {{selectedDiagnosticFamily.name}}</h3>
    </div>
    <div class="sdds-card">
      <div class="sdds-card-body overflow-auto">
        <button class="sdds-btn sdds-btn-sm sdds-btn-secondary float-right" (click)="addGeneration()"
          [disabled]="!canEditDignosticFamilies()">
          Add generation
        </button> <br> <br>
        <div class="ml-10">
          <div *ngFor="let generation of selectedDiagnosticFamily.generations | orderedGenerations">
            <app-diagnostic-family-generation [generation]="generation"
              [diagnosticFamilyId]="selectedDiagnosticFamily.id" (deleteGenerationEventEmitter)="delete($event)"
              [isAllowed]="canEditDignosticFamilies()">
            </app-diagnostic-family-generation>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>