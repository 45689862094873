<div class="sdds-card">
  <div class="sdds-card-header">
    <h6 class="sdds-card-headline">Select an identifier and a property from the lists below</h6>
  </div>
  <div class="sdds-card-body">
    <div class="sdds-dropdown sdds-dropdown-small">
      <span class="sdds-dropdown-label-outside">Identifier</span>
      <select class="form-control" [(ngModel)]="ecuIdentifier" name="identifierSelect" style="min-width: 100px;"
        (change)="onIdentifierSelected()" [disabled]="isReadOnly||!isAllowed">
        <option disabled active></option>
        <option *ngFor="let identifier of ecuIdentifierCategoryItems" [ngValue]="identifier.model.name">{{identifier.displayName}}</option>
      </select>
    </div>

    <div class="sdds-checkbox-item" style="margin-top: 10px">
      <input class="sdds-form-input" type="checkbox" [disabled]="isReadOnly || !hasEcuIdentifierSelected ||!isAllowed" [(ngModel)]="withProperty">
      <label class="sdds-form-label" for="cb-option-1">With property</label>
    </div>

    <div *ngIf="withProperty" class="sdds-dropdown sdds-dropdown-small">
      <span class="sdds-dropdown-label-outside">Property</span>
      <select class="form-control" [(ngModel)]="propertyName" name="paramSelect" style="min-width: 100px;" (change)="onIdentifierParamSelected()"
        [disabled]="isReadOnly||!isAllowed">
        <option disabled active></option>
        <option *ngFor="let param of availableParameters" [ngValue]="param">{{param}}</option>
      </select>
    </div>

    <div style="margin-top: 10px">
      <a (click)="notifyCollapse.emit()" style="cursor: pointer; text-decoration: none">
       <mat-icon svgIcon="eject-fill" style="width:15px;"></mat-icon> Hide</a>
    </div>
  </div>
</div>
