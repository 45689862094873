import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { IoCategoryItemDirective } from 'app/data-categories/io/io-category-item';
import { IoListService } from 'app/app-services/iolist.service';
import { IoListIdCategoryItemId } from 'app/app-model/iolist/iolist.model';
import { SpecificationService } from 'app/app-services/specification-service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-assigned-ios-viewer',
  templateUrl: './assigned-ios-viewer.component.html',
  styleUrls: ['./assigned-ios-viewer.component.css']
})
export class AssignedIosViewerComponent implements OnInit {
  @Output()
  public iosChangedEvent = new EventEmitter<IoListIdCategoryItemId[]>();

  @Output()
  public ioRemovedEvent = new EventEmitter<IoListIdCategoryItemId>();

  @Input()
  isReadOnly: boolean;

  specificationVersionId: number;
  specificationId: number;

  private _ioRelations: IoListIdCategoryItemId[];
  public get ioRelations(): IoListIdCategoryItemId[] {
    return this._ioRelations;
  }
  @Input()
  public set ioRelations(v: IoListIdCategoryItemId[]) {
    this._ioRelations = v;
  }

  constructor(private ioListService: IoListService,
    private specificationService: SpecificationService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
    this.specificationId = this.specificationService.currentSpecificationVersion.specification.id;
  }

  removeIoFromList(ioToRemove: IoListIdCategoryItemId) {
    this.ioRemovedEvent.emit(ioToRemove);
  }

  onIosAdded(addedServices: Array<IoCategoryItemDirective>) {
    const newIoRelations = addedServices.map(io => {
      const ioRelation = new IoListIdCategoryItemId();
      ioRelation.ioName = io.name;
      return ioRelation;
    });
    this.ioRelations = this.ioRelations.concat(newIoRelations);
    this.iosChangedEvent.emit(this.ioRelations);
  }

  get isAddButtonDisabled(): boolean {
    return this.isReadOnly;
  }

  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
