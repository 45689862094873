import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComputationalMethodModel } from 'app/app-model/diagnostic-service/computational.method.model';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';

@Component({
  selector: 'app-computational-method-view',
  templateUrl: './computational-method.component.html',
  styleUrls: ['./computational-method.component.css']
})
export class ComputationalMethodViewComponent {

  @Input()
  isInternalToPhysical: boolean;

  @Input()
  computationalMethod: ComputationalMethodModel;

  @Input()
  parameter: ParameterData;

  @Input()
  isOdxBasedSpecification: boolean;

  @Input()
  isAllowed: boolean;

  public get internalValueDataType(): OdxDataType {
    return this.parameter.scalingData.model.internalDataType;
  }

  public get physicalValueDataType(): OdxDataType {
    return this.parameter.scalingData.model.physicalDataType;
  }

  @Output()
  parameterChanged: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
