/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { PropertyParameterDirective } from 'app/modules/shared/model/service/parameters/property.parameter';
import { Subject, Subscription } from 'rxjs';

export abstract class BaseProperty {
  name: string;
  isRemovableProperty: boolean;
  availableParameters: PropertyParameterDirective[];
  notifyIfPropertyChanges = true;
  syncDoneSubscripion: Subscription;
  syncPropertyDone: Subject<BaseProperty> = new Subject();
  previousSelectedProperty: PropertyParameterDirective;

  private _serviceExecutionSequence: ServiceExecutionSequence;
  public get serviceExecutionSequence(): ServiceExecutionSequence {
    return this._serviceExecutionSequence;
  }
  public set serviceExecutionSequence(v: ServiceExecutionSequence) {
    this._serviceExecutionSequence = v;
    this._serviceExecutionSequence.syncDone.subscribe(arg => {
      this.syncFromModel();
      this.syncPropertyDone.next(this);
    });
  }

  private _selectedPropertyParameter: PropertyParameterDirective;
  public get selectedPropertyParameter(): PropertyParameterDirective {
    return this._selectedPropertyParameter;
  }
  public set selectedPropertyParameter(v: PropertyParameterDirective) {
    this.previousSelectedProperty = this._selectedPropertyParameter;
    this._selectedPropertyParameter = v;

    if (this._selectedPropertyParameter && this._selectedPropertyParameter.parameter) {
      this.linkAssignedParameter();
    }

    if (this.notifyIfPropertyChanges) {
      this.serviceExecutionSequence.notifySequenceChanged();
      this.syncPropertyDone.next(this);
    }
  }

  public setServiceExecutionSequenceModelSilent(model: ServiceExecutionSequence) {
    this._serviceExecutionSequence = model;
    this.syncFromModelSilent();
  }

  public setSelectedPropertyParameterSilent(propertyParameter: PropertyParameterDirective) {
    if (this.selectedPropertyParameter) {
      this.reset();
      this.selectedPropertyParameter.owner = null;
    }

    this._selectedPropertyParameter = propertyParameter;

    if (this.selectedPropertyParameter && this.selectedPropertyParameter.parameter) {
      this.linkAssignedParameter();
    }

    this.syncPropertyDone.next(this);
  }

  protected removeThisPropertyNameFromAssignedParameter() {
    if (!this.selectedPropertyParameter) {
      return;
    }
    if (this.selectedPropertyParameter.usedByProperties.length <= 0) {
      return;
    }

    const propertyToRemoveIndex = this.selectedPropertyParameter.usedByProperties.findIndex(propName => propName === this.name);

    if (propertyToRemoveIndex >= 0) {
      this.selectedPropertyParameter.usedByProperties.splice(propertyToRemoveIndex, 1);
    }
  }

  /**Links a parameter to this property. This will also create an input/output in the underlaying service execution sequence */
  abstract linkAssignedParameter(): void;

  abstract unlink(): void;

  abstract unlinkSilent(): void;

  abstract unlinkPreviousSelectedProperty(): void;

  abstract reset(): void;

  abstract syncFromModel(): void;

  abstract syncFromModelSilent(): void;
}

export enum PropertyType {
  Source,
  Target,
  Both
}
