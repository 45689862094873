import { Injectable } from '@angular/core';
import { CategoryItem } from 'app/app-model/category-item';
import { CategoryItemNameAndType } from 'app/app-model/category-name-and-type';
import { DiagnosticFamilyService } from 'app/app-services/diagnostic-family.service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { EcuIdentifierService } from 'app/app-services/ecu-identification.service';
import { EcuResetService } from 'app/app-services/ecu-reset.service';
import { FingerprintService } from 'app/app-services/fingerprint.service';
import { IoService } from 'app/app-services/io.service';
import { OperationalDataService } from 'app/app-services/operational.data.service';
import { ServerIdentificationService } from 'app/app-services/server-identification.service';
import { SessionsService } from 'app/app-services/sessions.service';
import { ValuesService } from 'app/app-services/values.service';
import { ApiProxyService } from 'app/modules/shared/services/api-proxy.service';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { environment } from 'environments/environment';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { CategoryName, DiagnosticServiceViewType } from '../app-model/enums';
import { DiagnosticFamilyModel } from '../app-model/server-identification/diagnostic-family.model';
import { IoListService } from './iolist.service';
import { SecurityAccessService } from './security-access.service';
import { FreezeframesService } from './freezeframes.service';
import { IvdOverrideService } from './ivd-override-service';
import { VsocMonitoringService } from './vsocMonitoringService';

@Injectable({
  providedIn: 'root'
})

export class CategoryItemsService {

  constructor(
    private ioService: IoService,
    private iolistService: IoListService,
    private operationalDataService: OperationalDataService,
    private diagnosticServiceProvider: DiagnosticServiceProvider,
    private diagnosticFamilyService: DiagnosticFamilyService,
    private ecuIdentifierService: EcuIdentifierService,
    private serverIdentificationService: ServerIdentificationService,
    private sessionsService: SessionsService,
    private ecuResetService: EcuResetService,
    private fingerprintService: FingerprintService,
    private valuesService: ValuesService,
    private securityAccessService: SecurityAccessService,
    private freezeFrameService: FreezeframesService,
    private ivdOverrideService: IvdOverrideService,
    private vsocService: VsocMonitoringService,
    private apiProxy: ApiProxyService) {
  }

  getPreviewItems(specificationVersionId: number, itemCategory: Category): Observable<CategoryItem[]> {
    switch (itemCategory) {
      case Category.Servers:
        const ecuIdentifiersObservable = this.ecuIdentifierService.getItemsPreview(specificationVersionId);
        const serverIdentificationObservable = this.serverIdentificationService.getItemsPreview(specificationVersionId);
        return zip(ecuIdentifiersObservable, serverIdentificationObservable).pipe(map(result => result[1]));

      case Category.OperationalData:
        return this.operationalDataService.getItemsPreview(specificationVersionId);
      case Category.Identification:
        return this.ecuIdentifierService.getItemsPreview(specificationVersionId);
      case Category.Services:
        return this.diagnosticServiceProvider.getItemsPreview(specificationVersionId);
      case Category.Sessions:
        return this.sessionsService.getItemsPreview(specificationVersionId);
      case Category.EcuResets:
        return this.ecuResetService.getItemsPreview(specificationVersionId);
      case Category.Fingerprints:
        return this.fingerprintService.getItemsPreview(specificationVersionId);
      case Category.Ios:
        return this.ioService.getItemsPreview(specificationVersionId);
      case Category.Iolists:
        return this.iolistService.getItemsPreview(specificationVersionId);
      case Category.Values:
        return this.valuesService.getItemsPreview(specificationVersionId);
      case Category.SecurityAccess:
        return this.securityAccessService.getItemsPreview(specificationVersionId);
      case Category.FreezeFrames:
        return this.freezeFrameService.getItemsPreview(specificationVersionId);
      case Category.IvdOverrides:
        return this.ivdOverrideService.getItemsPreview(specificationVersionId);
      case Category.VsocMonitoring:
        return this.vsocService.getItemsPreview(specificationVersionId);
      default:
        return of([]);
    }
  }

  /** Makes sure that the item with the specified id is a complete item.
   * If the item is in preview mode, the rest of its data will be synchronized
   * to the current instance of that item.
   */
  getCompleteItem(specificationVersionId: number, itemCategory: Category, itemId?: string): Observable<CategoryItem> {
    switch (itemCategory) {
      case Category.OperationalData:
        return this.operationalDataService.getItem(specificationVersionId, itemId);
      case Category.Identification:
        return this.ecuIdentifierService.getItem(specificationVersionId, itemId);
      case Category.Servers:
        return this.serverIdentificationService.getItem(specificationVersionId, itemId);
      case Category.Services:
        return this.diagnosticServiceProvider.getItem(specificationVersionId, itemId);
      case Category.Sessions:
        return this.sessionsService.getItem(specificationVersionId, itemId);
      case Category.EcuResets:
        return this.ecuResetService.getItem(specificationVersionId, itemId);
      case Category.Fingerprints:
        return this.fingerprintService.getItem(specificationVersionId, itemId);
      case Category.Ios:
        return this.ioService.getItem(specificationVersionId, itemId);
      case Category.Iolists:
        return this.iolistService.getItem(specificationVersionId, itemId);
      case Category.Values:
        return this.valuesService.getItem(specificationVersionId, itemId);
      case Category.SecurityAccess:
        return this.securityAccessService.getItem(specificationVersionId, itemId);
      case Category.FreezeFrames:
        return this.freezeFrameService.getItem(specificationVersionId, itemId);
      case Category.IvdOverrides:
        return this.ivdOverrideService.getItem(specificationVersionId, itemId);
      case Category.VsocMonitoring:
        return this.vsocService.getItem(specificationVersionId, itemId);
      default:
        return of(undefined);
    }
  }

  createNewItem(specificationVersionId: number, itemCategory: Category, itemName?: string, itemId?: number): Observable<CategoryItem> {
    switch (itemCategory) {
      case Category.OperationalData:
        return this.operationalDataService.createItem(specificationVersionId, itemName);
      case Category.Identification:
        return this.ecuIdentifierService.createItem(specificationVersionId, itemName);
      case Category.Servers:
        return this.serverIdentificationService.createItem(specificationVersionId, itemName);
      case Category.Sessions:
        return this.sessionsService.createItem(specificationVersionId, itemName);
      case Category.EcuResets:
        return this.ecuResetService.createItem(specificationVersionId, itemName);
      case Category.Fingerprints:
        return this.fingerprintService.createItem(specificationVersionId, itemName);
      case Category.Ios:
        return this.ioService.createItem(specificationVersionId, itemName);
      case Category.Iolists:
        return this.iolistService.createItem(specificationVersionId, itemName);
      case Category.Values:
        return this.valuesService.createItem(specificationVersionId, itemName);
      case Category.SecurityAccess:
        return this.securityAccessService.createItem(specificationVersionId, itemName);
      case Category.Services:
        return this.diagnosticServiceProvider.createItem(specificationVersionId, itemName);
      case Category.FreezeFrames:
        return this.freezeFrameService.createItem(specificationVersionId, itemName);
      default:
        return of(undefined);
    }
  }

  deleteItem(specificationVersionId: number, itemCategory: Category, itemId?: string): Observable<CategoryItem> {
    switch (itemCategory) {
      case Category.OperationalData:
        return this.operationalDataService.deleteItem(specificationVersionId, itemId);
      case Category.Identification:
        return this.ecuIdentifierService.deleteItem(specificationVersionId, itemId);
      case Category.Servers:
        return this.serverIdentificationService.deleteItem(+itemId);
      case Category.Sessions:
        return this.sessionsService.deleteItem(specificationVersionId, itemId);
      case Category.EcuResets:
        return this.ecuResetService.deleteItem(specificationVersionId, itemId);
      case Category.Fingerprints:
        return this.fingerprintService.deleteItem(specificationVersionId, itemId);
      case Category.Ios:
        return this.ioService.deleteItem(specificationVersionId, itemId);
      case Category.Iolists:
        return this.iolistService.deleteItem(specificationVersionId, itemId);
      case Category.Values:
        return this.valuesService.deleteItem(specificationVersionId, itemId);
      case Category.SecurityAccess:
        return this.securityAccessService.deleteItem(specificationVersionId, itemId);
      case Category.Services:
        return this.diagnosticServiceProvider.deleteItem(specificationVersionId, itemId);
      case Category.FreezeFrames:
        return this.freezeFrameService.deleteItem(specificationVersionId, itemId);
      default:
        return of(undefined);
    }
  }

  getDiagnosticServices(specificationVersionId: number): Observable<CategoryItem[]> {
    return this.diagnosticServiceProvider.getItemsPreview(specificationVersionId);
  }

  getDiagnosticView(specificationVersionId: number, viewType: DiagnosticServiceViewType) {
    return this.diagnosticServiceProvider.getDiagnosticServicesByView(specificationVersionId, viewType);
  }

  getAvailableDiagnosticFamilies(): Observable<DiagnosticFamilyModel[]> {
    return this.diagnosticFamilyService.getFamilies();
  }

  resetCategories() {
    this.operationalDataService.reset();
    this.ecuIdentifierService.reset();
    this.serverIdentificationService.reset();
    this.sessionsService.reset();
    this.ecuResetService.reset();
    this.fingerprintService.reset();
    this.ioService.reset();
    this.iolistService.reset();
    this.valuesService.reset();
    this.diagnosticServiceProvider.reset();
    this.securityAccessService.reset();
    this.freezeFrameService.reset();
    this.ivdOverrideService.reset();
    this.vsocService.reset();
  }

  public versionIsReleasedStatus(versionId: number): Observable<boolean> {
    return this.apiProxy.get<boolean>(`${environment.apiUrl}versions/versionIsReleased/${versionId}`);
  }

  public getSpecificationVersionId(itemId: number): any {
    this.getItemSpecVersion(itemId).subscribe(res => {
      console.log(res);
      return res;
    });
  }

  public copyCategoryItemByName(specificationVersionId: number, categoryItemName: string, categoryItemType: number): Observable<CategoryItemNameAndType> {
    return this.apiProxy.post<CategoryItemNameAndType>(`${environment.apiUrl}versions/copyItemByName/${specificationVersionId}/${categoryItemName}/${categoryItemType}`, null);
  }

  public getSpecificItem(specificationVersionId: number, categoryItemName: string, categoryItemType: number): Observable<any> {
    switch (categoryItemType) {
      case CategoryName.OperationalDataVariable:
        return this.operationalDataService.getCategoryItemModel(specificationVersionId, categoryItemName);
      case CategoryName.Io:
        return this.ioService.getCategoryItemModel(specificationVersionId, categoryItemName);
      case CategoryName.EcuIdentifier:
        return this.ecuIdentifierService.getCategoryItemModel(specificationVersionId, categoryItemName);
      case CategoryName.Fingerprint:
        return this.fingerprintService.getCategoryItemModel(specificationVersionId, categoryItemName);
      case CategoryName.EcuValue:
        return this.valuesService.getCategoryItemModel(specificationVersionId, categoryItemName);
      case CategoryName.EcuReset:
        return this.ecuResetService.getCategoryItemModel(specificationVersionId, categoryItemName);
      case CategoryName.EcuSession:
        return this.sessionsService.getCategoryItemModel(specificationVersionId, categoryItemName);
      case CategoryName.SecurityAccessMode:
        return this.securityAccessService.getCategoryItemModel(specificationVersionId, categoryItemName);
      case CategoryName.FreezeFrames:
        return this.freezeFrameService.getCategoryItemModel(specificationVersionId, categoryItemName);
      case CategoryName.IvdOverride:
        return this.ivdOverrideService.getCategoryItemModel(specificationVersionId, categoryItemName);
      case Category.VsocMonitoring:
        return this.vsocService.getCategoryItemModel(specificationVersionId, categoryItemName);
      default:
        return null;
    }
  }

  private getItemSpecVersion(itemId: number): Observable<number> {
    return this.apiProxy.get<number>(`${environment.apiUrl}versions/getVersionId/${itemId}`);
  }
}
