import { DataSource } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, of } from 'rxjs';
import { merge } from 'rxjs';
import { SpecificationVersionModification } from 'app/modules/shared/model/specification-version-modification';

export class ModificationInfoTableDataSource extends DataSource<SpecificationVersionModification> {
  data: SpecificationVersionModification[] = [];
  paginator: MatPaginator;
  sort: MatSort;

  constructor() {
    super();
  }

  setModificationList(list: SpecificationVersionModification[]) {
    this.data = list;
  }

  connect(): Observable<SpecificationVersionModification[]> {  
    const dataMutations = [
      observableOf(this.data),
      of(this.paginator.page),
      of(this.sort.sortChange)
    ];

    return merge(...dataMutations).pipe(map(() =>
      this.getPagedData(this.getSortedData([...this.data]))));
  }

  disconnect() { }

  private getPagedData(data: SpecificationVersionModification[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  private getSortedData(data: SpecificationVersionModification[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'userThatModified': return this.compare(a.userThatModified, b.userThatModified, isAsc);
        case 'modificationDate': return this.compare(+a.modificationDate, +b.modificationDate, isAsc);
        case 'modifiedCategory': return this.compare(+a.modifiedCategory, +b.modifiedCategory, isAsc);
        case 'modificationType': return this.compare(+a.modificationType, +b.modificationType, isAsc);
        default: return 0;
      }
    });
  }

  /** Simple sort comparator for example ID/Name columns (for client-side sorting). */
  private compare(a: string | number, b: string | number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}