import { Component, Input, OnDestroy } from '@angular/core';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { ServiceExecutionModel } from 'app/app-model/diagnostic-service/service-execution.model';
import { CommandType } from 'app/app-model/enums';
import { IoModel } from 'app/app-model/io/io.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { PropertyType } from 'app/modules/shared/model/properties/base-property';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { Subscription } from 'rxjs';

import { FunctionBase } from '../function-base';
import { FunctionItem } from '../function.item';

@Component({
  selector: 'app-function-dashboard',
  templateUrl: './function-dashboard.component.html',
  styleUrls: ['./function-dashboard.component.css']
})
export class FunctionDashboardComponent implements OnDestroy {
  @Input()
  propertyType: PropertyType;

  @Input()
  isReadOnly: boolean;

  @Input()
  hideAddButtonWithOneService: boolean;

  readIdServiceExecutionSequence: ServiceExecutionSequence;
  readIdServiceExecutionSequenceSubscription: Subscription;

  private _serviceExecutionSequence: ServiceExecutionSequence;
  public get serviceExecutionSequence(): ServiceExecutionSequence {
    return this._serviceExecutionSequence;
  }

  @Input()
  public set serviceExecutionSequence(v: ServiceExecutionSequence) {
    this._serviceExecutionSequence = v;
  }

  private _source: FunctionItem;
  public get source(): FunctionItem {
    return this._source;
  }
  @Input()
  public set source(v: FunctionItem) {
    this._source = v;
    this.updateReadIdStatusServiceExecution();
  }

  constructor(private dataCategoriesService: DataCategoriesService) { }

  ngOnDestroy() {
    this.readIdServiceExecutionSequenceSubscription.unsubscribe();
  }

  get enabledFunctions(): FunctionBase[] {
    return this.source.functions.filter(func => func.enabled);
  }

  get indexOfActiveFunction(): number {
    return this.enabledFunctions.findIndex(func => func === this.source.activeFunction);
  }

  get loginServiceExecution(): ServiceExecutionModel {
    if (this.source.activeFunction) {
      return this.source.activeFunction.serviceExecutionSequence.findServiceExecutionWithCommandType(CommandType.Login);
    }
  }

  get fingerprintServiceExecution(): ServiceExecutionModel {
    if (this.source.activeFunction) {
      return this.source.activeFunction.serviceExecutionSequence.findServiceExecutionWithCommandType(CommandType.WriteFingerprint);
    }
  }

  get ecuResetServiceExecution(): ServiceExecutionModel {
    if (this.source.activeFunction) {
      return this.source.activeFunction.serviceExecutionSequence.findServiceExecutionWithCommandType(CommandType.EcuReset);
    }
  }

  get canEditPrerequisites(): boolean {
    const activeCategory = this.dataCategoriesService.activeCategory;
    return activeCategory.category === Category.Ios ||
      activeCategory.category === Category.Values || activeCategory.category === Category.EcuResets;
  }

  get canEditPostrequisites(): boolean {
    const activeCategory = this.dataCategoriesService.activeCategory;
    return activeCategory.category === Category.Ios || activeCategory.category === Category.Values;
  }

  updateReadIdStatusServiceExecution() {
    this.readIdServiceExecutionSequence = new ServiceExecutionSequence();
    this.readIdServiceExecutionSequence.setModel(this.source.model.readIdStatusSequence);

    this.readIdServiceExecutionSequenceSubscription = this.readIdServiceExecutionSequence.modified.subscribe(data => {
      this.source.notifyItemChanged();
    });
  }

  setActiveFunction(tabSelected: MatTabChangeEvent) {
    const activeFunction = this.enabledFunctions.find(func => func.name === tabSelected.tab.textLabel);
    console.log(this.source);
    this.source.activeFunction = activeFunction;
  }

  getActiveFunctionName() {
    if (this.source && this.source.activeFunction) {
      return this.source.activeFunction.name;
    }
    else
      return '';
  }

  onReadIdStatusServiceChanged(service: DiagnosticServiceCategoryItem) {
    const sequenceModel = new ServiceExecutionSequenceModel();
    const serviceExecutionModel = this.createReadIdStatusServiceExecutionModel(service);
    sequenceModel.executions = [serviceExecutionModel];

    (this.source.model as IoModel).readIdStatusSequence = sequenceModel;

    this.updateReadIdStatusServiceExecution();
    this.source.notifyItemChanged();
  }

  onLoginCommandChanged(prerequisitesCommand: string) {
    this.source.activeFunction.serviceExecutionSequence.addComandAsServiceExecution(prerequisitesCommand, CommandType.Login, 0);
  }

  onFingerprintCommandChanged(fingerprintCommand: string) {
    this.source.activeFunction.serviceExecutionSequence.addComandAsServiceExecution(fingerprintCommand, CommandType.WriteFingerprint, 1);
  }

  onEcuResetCommandChanged(ecuResetCommand: string) {
    this.source.activeFunction.serviceExecutionSequence.addComandAsServiceExecution(ecuResetCommand, CommandType.EcuReset, 3);
  }

  createReadIdStatusServiceExecutionModel(service: DiagnosticServiceCategoryItem): ServiceExecutionModel {
    const serviceExecutionModel = new ServiceExecutionModel();
    serviceExecutionModel.service = service.name;

    return serviceExecutionModel;
  }

  onReadIdStatusSequenceRemoved(): void {
    (this.source.model as IoModel).readIdStatusSequence = undefined;
    this.updateReadIdStatusServiceExecution();
    this.source.notifyItemChanged();
  }

}

