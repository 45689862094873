import { Location } from '@angular/common';
import { Directive, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { CategoryName } from 'app/app-model/enums';
import { SpecificationService } from 'app/app-services/specification-service';
import { FunctionBase } from 'app/modules/shared/components/function/function-base';
import { FunctionRequestSeed } from 'app/modules/shared/components/function/function-request-seed';
import { FunctionSendKey } from 'app/modules/shared/components/function/function-send-key';
import { FunctionItem } from 'app/modules/shared/components/function/function.item';
import { FunctionType } from 'app/modules/shared/components/function/functions';
import { PropertyType } from 'app/modules/shared/model/properties/base-property';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { Subscription } from 'rxjs';

import { SecurityAccessService } from '../../app-services/security-access.service';

@Directive()
export class SecurityAccessCategoryItemDirective extends FunctionItem implements OnDestroy {

  @Input()
  isLoading = true;

  securityAccessService: SecurityAccessService;
  propertyType: PropertyType = PropertyType.Both;
  securityAccessUpdateSubscription: Subscription;
  functionRequestModifiedSubscription: Subscription;
  functionSendModifiedSubscription: Subscription;
  oldName = '';

  constructor(private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
    this._serviceExecutionSequence = new ServiceExecutionSequence();
    super.categoryType = CategoryName.SecurityAccessMode;
  }

  createFunctions() {
    if (!this.functions || this.functions.length === 0) {
      const functionRequestSeed = new FunctionRequestSeed(false, []);
      functionRequestSeed.addProperty('RequestSeed');
      functionRequestSeed.serviceExecutionSequence.modified.subscribe(val => {
        this.notifyItemChanged();
      });

      const functionSendKey = new FunctionSendKey(false, []);
      functionSendKey.addProperty('SendKey');
      functionSendKey.serviceExecutionSequence.modified.subscribe(val => {
        this.notifyItemChanged();
      });

      this.functions = [functionRequestSeed, functionSendKey];
      this.activeFunction = functionRequestSeed;
    }
  }

  createProperties(func: FunctionBase) {
    func.addProperty(this.getPropertyNameForFunctionType(func.type));
  }

  ngOnDestroy(): void {
    super.unsubscribe();
    if (this.securityAccessUpdateSubscription) {
      this.securityAccessUpdateSubscription.unsubscribe();
    }

    if (this.functionRequestModifiedSubscription) {
      this.functionRequestModifiedSubscription.unsubscribe();
    }

    if (this.functionSendModifiedSubscription) {
      this.functionSendModifiedSubscription.unsubscribe();
    }
  }

  setName(name: string) {
    this.oldName = this.model.name;
    this.name = name;
    this.model.name = name;
    this.notifyItemChanged();
  }

  syncFromModel() {
    this.setIsSyncingFromMasterState();
    this.name = this.model.name;
    this.oldName = this.model.name;
    this.id = this.model.id;
    this.isLoading = true;
    this.createFunctions();
    this.syncSequences(this.specificationService.currentSpecificationVersion.id).subscribe(_ => {
      this.updateSyncingFromMasterState();
      super.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
    });
  }

  public setIsSyncingFromMasterState() {
    this.isSyncingFromMaster = true;
    this.isSyncRequestSeedDone = false;
    this.isSyncSendKeyDone = false;
  }

  notifyItemChanged() {
    if (!this.isSyncingFromMaster) {
      console.log('Security Access ' + this.name + ' modified...Saving changes');
      this.securityAccessUpdateSubscription = this.securityAccessService.updateItem(this.specificationService.currentSpecificationVersion.id, this.model, this.oldName)
        .subscribe(updatedItem => {
          this.model = updatedItem;

          const newUrl = this.router.createUrlTree([], {
            relativeTo: this.activatedRoute,
            queryParams: { itemId: this.model.name }
          }).toString();
          this.locationService.go(newUrl);

          console.log('Security Access ' + this.name + ' successfully updated');
        });
    }
  }

  getPropertyNameForFunctionType(functionType: FunctionType): string {
    switch (functionType) {
      case FunctionType.RequestSeed:
        return 'Seed Value';
      case FunctionType.SendKey:
        return 'Key Value';
      default:
        return this.defaultPropertyName;
    }
  }

  protected updateSyncingFromMasterState() {
    this.isSyncingFromMaster = false;
    this.isLoading = false;
  }

  protected createServiceExecutionSequenceIfNeeded(functionType: FunctionType, func: FunctionBase) {
    switch (functionType) {
      case FunctionType.RequestSeed:
        if (this.model.requestSeedSequence) {
          break;
        }
        this.model.requestSeedSequence = new ServiceExecutionSequenceModel();
        func.serviceExecutionSequence.model = this.model.requestSeedSequence;
        break;
      case FunctionType.Control:
        if (this.model.sendKeySequence) {
          break;
        }
        this.model.sendKeySequence = new ServiceExecutionSequenceModel();
        func.serviceExecutionSequence.model = this.model.sendKeySequence;
        break;
      default:
        break;
    }
  }

  protected subscribeDefaultServcieExecutionSequenceEvents() {
    throw new Error('Method not implemented.');
  }
}
