import { Location } from '@angular/common';
import { Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpecificationService } from 'app/app-services/specification-service';
import { CategoryItem } from 'app/app-model/category-item';
import { IoListModel } from 'app/app-model/iolist/iolist.model';
import { tap } from 'rxjs/operators';
import { IoListService } from 'app/app-services/iolist.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class IoListCategoryItem extends CategoryItem {

    iolistService: IoListService;
    oldName = '';

    private _model: IoListModel;
    public get model(): IoListModel {
        return this._model;
    }
    public set model(v: IoListModel) {
        this._model = v;
        this.syncFromModel();
    }

    constructor(private specificationService: SpecificationService,
        private locationService: Location,
        private router: Router,
        private activatedRoute: ActivatedRoute) {
        super();
    }

    setName(name: string) {
        this.oldName = this.model.name;
        this.name = name;
        this.model.name = name;
        this.notifyIolistChanged();
    }

    notifyIolistChanged() {
        console.log('Iolist ' + this.name + ' modified...Saving changes');
        this.iolistService.updateItem(this.specificationService.currentSpecificationVersion.id, this.model, this.oldName)
            .pipe(tap(updatedIolist => {
                this.model = updatedIolist;

                const newUrl = this.router.createUrlTree([], {
                    relativeTo: this.activatedRoute,
                    queryParams: { itemId: this.model.name }
                }).toString();
                this.locationService.go(newUrl);

                console.log('Iolist' + this.name + ' successfully updated');
            })).subscribe();
    }

    private syncFromModel() {
        this.name = this.model.name;
        this.oldName = this.name;
    }
}
