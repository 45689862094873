import { Injectable } from '@angular/core';
import { LegacyConversion } from 'app/app-model/legacy-conversion.model';
import { SpecificationVersion } from 'app/app-model/specification-version';
import { ApiProxyService } from 'app/modules/shared/services/api-proxy.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LegacyConversionService {

  constructor(private apiProxy: ApiProxyService) { }

  public upgradeSpecificationVersion(specificationVersion: SpecificationVersion): Observable<SpecificationVersion> {
    const legacyConversion = new LegacyConversion();
    legacyConversion.legacyVersionId = specificationVersion.id;
    legacyConversion.newSpecification = false;

    const upgradedVersionObservable = this.apiProxy.post<LegacyConversion>(`${environment.apiUrl}conversions/`, legacyConversion)
      .pipe(mergeMap(result => this.apiProxy.get<SpecificationVersion>(environment.apiUrl + 'versions/' + result.convertedVersionId)));

    return upgradedVersionObservable;
  }

  public syncOdxFileOnUpgradedSpecification(specificationVersion: SpecificationVersion): Observable<number>{
    return this.apiProxy.put<number>(`${environment.apiUrl}conversions/syncOdxFile/${specificationVersion.id}`, specificationVersion.id);
  }
}
