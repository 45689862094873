/* eslint-disable @typescript-eslint/naming-convention */
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { ValueConstraint } from 'app/app-model/diagnostic-service/value.constraint.model';
import { ComputationalMethodModel } from 'app/app-model/diagnostic-service/computational.method.model';

export class ScalingModel {
    id: number;
    unit: string;
    physicalDataType: OdxDataType;
    internalDataType: OdxDataType;
    encoding: ScaniaEncodingType;
    physicalConstraint: ValueConstraint;
    internalConstraint: ValueConstraint;
    internalToPhysicalComputationalMethod: ComputationalMethodModel;
    physicalToInternalComputationalMethod: ComputationalMethodModel;
}

// eslint-disable-next-line no-shadow
export enum ScaniaEncodingType {
    Default,
    BCD_P,
    BCD_UP,
    SignMagnitude,
    OnesComplement
}
