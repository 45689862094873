<div class="sdds-card" style="background: #f2f5f8; margin-bottom:15px;">
  <div class="sdds-card-body" style="padding: 10px;">
    <div style="float:right;">
      <app-expression-view-toolbar [expressionModel]="expressionModel" [isReadOnly]="isReadOnly"
        [copyEnabled]="copyEnabled" [reorderEnabled]="reorderEnabled"
        [isAllowed]="isAllowed"></app-expression-view-toolbar>
    </div>
    <div class="sdds-row">
      <div
        class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3">
        <app-id-code-field [hexCount]="hexCount" [hexCode]="this.expressionModel.legacyIdentifierCode"
          [isEditingAllowed]="!isReadOnly" (hexCodeChanged)="onHexCodeChanged($event)"
          [isAllowed]="isAllowed"></app-id-code-field>
      </div>

      <div
        class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3">
        <span class="sdds-detail-04">Does not exist</span>
      </div>
      <div
        class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-6">
      </div>
    </div>
  </div>
</div>