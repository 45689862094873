import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValueConstraintType } from 'app/app-model/diagnostic-service/value.constraint.item.model';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { ValueConstraintData } from 'app/modules/shared/model/service/parameters/value-constraint-data';
import { ValueConstraintItemData } from 'app/modules/shared/model/service/parameters/value-contraint-item-data';

@Component({
  selector: 'app-editable-value-constraint',
  templateUrl: './editable-value-constraint.component.html',
  styleUrls: ['./editable-value-constraint.component.css']
})
export class EditableValueConstraintComponent {

  @Output()
  valueConstraintChange: EventEmitter<void> = new EventEmitter();

  @Input()
  valueConstraint: ValueConstraintData;

  @Input()
  isOdxBasedSpecification: boolean;

  @Input()
  isAllowed: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ValueConstraintType = ValueConstraintType;

  _dataType: OdxDataType;
  get dataType(): OdxDataType {
    return this._dataType;
  }

  @Input()
  set dataType(val: OdxDataType) {
    this._dataType = val;
    if (this.valueConstraint) {
      this.valueConstraint.changeDataType(val);
    }
  }

  getValueConstraintDataTypes(): string[] {
    const keys = Object.keys(ValueConstraintType);
    const slicedKeys = keys.slice(keys.length / 2);

    return slicedKeys;
  }

  addNewValueConstraintItem() {
    this.valueConstraint.addNewItem(this.dataType);
    this.onChanged();
  }

  removeConstraintItem(itemToRemove: ValueConstraintItemData) {
    this.valueConstraint.removeItem(itemToRemove);
    this.onChanged();
  }

  onChanged(event?: any) {
    if (!event) {
      this.valueConstraintChange.next();
    }
  }
}
