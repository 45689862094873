import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryItem } from 'app/app-model/category-item';
import { CategoryIdType, CategoryName, CategoryNameType } from 'app/app-model/enums';
import { Specification } from 'app/app-model/specification';
import { CategoryItemsService } from 'app/app-services/category-items-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { DataCategoryModel } from 'app/data-categories/data-category-model';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SpecificationVersion } from '../app-model/specification-version';
import { MessageService } from '../modules/shared/services/message-service.service';
import { DataCategoryCreatorService } from './data-category-creator';
import { ServerIdentificationService } from './server-identification.service';

@Injectable({
  providedIn: 'root'
})
export class DataCategoriesService {

  // @Output()

  activeCategoryChanged: BehaviorSubject<DataCategoryModel> = new BehaviorSubject(undefined);
  categoryContentModified: Subject<{ modifiedCategory: DataCategoryModel; itemId: string }> = new Subject();
  activeCategory: DataCategoryModel;
  availableCategories: DataCategoryModel[];
  selectedSpecificationSubscription: Subscription;

  constructor(
    private categoryItemsService: CategoryItemsService,
    private specificationService: SpecificationService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private dataCategoryCreatorService: DataCategoryCreatorService,
    private serverIdentificationService: ServerIdentificationService) {

    
    this.specificationService.selectedSpecificationEventEmitter.subscribe({
      next: (specification) => {
        this.setAvailableCategories(router, route);
        this.disableNonTemplateCategories(specification.specification);
      }
    });
  }

  disableNonTemplateCategories(specification: Specification) {
    if (!specification || !specification.isTemplate) {
      return;
    }

    this.getCategory(Category.Servers).enabled = false;
    this.getCategory(Category.UnreferencedTestSteps).enabled = false;
    this.getCategory(Category.LegacyFiles).enabled = false;
  }

  setAvailableCategories(router: Router, route: ActivatedRoute) {
    this.availableCategories = [
      this.createServersCategory(route, router),
      this.createIdentificationCategory(route, router),
      this.createSessionsCategory(route, router),
      this.createSecurityAccessCategory(route, router),
      this.createFingerprintsCategory(route, router),
      this.createEcuResetsCategory(route, router),
      this.createIvdOverrideCategory(route, router),
      this.createIosCategory(route, router),
      this.createIolistsCategory(route, router),
      this.createValuesCategory(route, router),
      this.createOperationalDataCategory(route, router),
      this.createServicesCategory(route, router),
      this.createLegacyFilesCategory(route, router),
      this.createUnreferencedTestStepsCategory(route, router),
      this.createFreezeFrameCategory(route, router),
      this.createVsocCategory(route, router),
    ];
  }

  getCategory(category: Category) {
    return this.availableCategories.find((categoryItem) => categoryItem.category === category);
  }

  getCategoryByName(categoryName: string): DataCategoryModel {
    return this.availableCategories.find(dc => dc.name === categoryName);
  }

  updateCategory(category: Category, specificationVersion: SpecificationVersion): Observable<DataCategoryModel> {
    const categoryToUpdate = this.getCategory(category);

    if (categoryToUpdate.isUpdated) {
      return of(categoryToUpdate);
    } else {
      return this.categoryItemsService.getPreviewItems(specificationVersion.id, category)
        .pipe(
          tap(items => {
            categoryToUpdate.setItems(items);
            categoryToUpdate.isUpdated = true;
          }),
          map(items => categoryToUpdate));
    }
  }

  public getDataCategories(): DataCategoryModel[] {
    return this.availableCategories;
  }

  public findCategory(categoryEnum: Category): DataCategoryModel {
    return this.availableCategories.find(category => category.category === categoryEnum);
  }

  public setActiveCategory(category: DataCategoryModel) {
    this.activeCategory = category;
    this.activeCategoryChanged.next(this.activeCategory);
  }

  public copyItem(element: CategoryItem) {
    if (element.name) {
      this.categoryItemsService.copyCategoryItemByName(element.specificationVersionId, element.name, element.categoryType).subscribe(
        result => {
          this.categoryItemsService.getSpecificItem(element.specificationVersionId,
            result.name, result.categoryType).subscribe(res => this.addItemToCategoryAndSetAsActive(res));
        }
      );
    }
  }

  public createAndAddItemToActiveCategory(itemName: string, specificationVersionId: number): Observable<CategoryItem> {
    return this.activeCategory.createNewItem(itemName, specificationVersionId).pipe(tap(item => {
      this.activeCategory.addItemToContainer(item);
      const itemId = this.getItemIdToUseInRoute(item);
      this.categoryContentModified.next({ modifiedCategory: this.activeCategory, itemId: itemId });
    }));
  }

  public removeItemFromActiveCategory(specificationVersionId: number, item: CategoryItem) {
    return this.activeCategory.removeItem(specificationVersionId, item).pipe(tap(i => {
      const nextItem = this.activeCategory.removeItemFromContainerAndGetNext(item);
      const nextItemId = this.getItemIdToUseInRoute(nextItem);
      this.activeCategory.activeItem = null;
      this.categoryContentModified.next({ modifiedCategory: this.activeCategory, itemId: nextItemId });
    }));
  }

  public resetCategories(resetServices: boolean = false) {
    this.availableCategories.forEach(category => {
      if (category.category === Category.Services && !resetServices) {
        return;
      }
      category.reset();
    });
  }

  public addItemToCategoryAndSetAsActive(item: CategoryItem) {
    this.activeCategory.addItemToContainer(item);
    this.router.navigate(['/specification', this.specificationService.currentSpecification.id,
      this.specificationService.currentSpecificationVersion.id, this.activeCategory.id], { queryParams: { itemId: item.name ? item.name : item.id } });
  }

  private preloadGlobalItems(specificationVersion: SpecificationVersion) {
    if (specificationVersion) {
      // Diagnostic service previews must be preloaded
      this.categoryItemsService.getPreviewItems(specificationVersion.id, Category.Services).subscribe(items => {
        const category = this.getCategory(Category.Services);
        category.setItems(items);
      });
    }
  }

  private createServersCategory(route: ActivatedRoute, router: Router) {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.Servers)
      .setWithoutPromptEnabled(true)
      .sethasLegacySupport(true)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType[CategoryNameType.Servers])
      .setId(CategoryIdType[CategoryIdType.servers])
      .setEnabled(true)
      .create();
  }

  private createIdentificationCategory(route: ActivatedRoute, router: Router) {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.Identification)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(true)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType[CategoryNameType.Identifications])
      .setId(CategoryIdType[CategoryIdType.identifications])
      .setEnabled(true)
      .create();
  }

  private createSessionsCategory(route: ActivatedRoute, router: Router) {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.Sessions)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(false)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType[CategoryNameType.Sessions])
      .setId(CategoryIdType[CategoryIdType.sessions])
      .setEnabled(true)
      .create();
  }

  private createSecurityAccessCategory(route: ActivatedRoute, router: Router) {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.SecurityAccess)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(false)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType.SecurityAccess)
      .setId(CategoryIdType.security_access)
      .setEnabled(true)
      .create();
  }

  private createFingerprintsCategory(route: ActivatedRoute, router: Router) {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.Fingerprints)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(false)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType[CategoryNameType.Fingerprints])
      .setId(CategoryIdType[CategoryIdType.fingerprints])
      .setEnabled(true)
      .create();
  }

  private createEcuResetsCategory(route: ActivatedRoute, router: Router) {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.EcuResets)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(false)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType.EcuResets)
      .setId(CategoryIdType.ecuResets)
      .setEnabled(true)
      .create();
  }

  private createIosCategory(route: ActivatedRoute, router: Router) {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.Ios)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(false)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType[CategoryNameType.IOs])
      .setId(CategoryIdType[CategoryIdType.ios])
      .setEnabled(true)
      .create();
  }

  private createIolistsCategory(route: ActivatedRoute, router: Router) {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.Iolists)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(false)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType[CategoryNameType.IOLists])
      .setId(CategoryIdType[CategoryIdType.iolists])
      .setEnabled(true)
      .create();
  }

  private createValuesCategory(route: ActivatedRoute, router: Router) {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.Values)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(false)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType[CategoryNameType.Values])
      .setId(CategoryIdType[CategoryIdType.values])
      .setEnabled(true)
      .create();
  }

  private createOperationalDataCategory(route: ActivatedRoute, router: Router) {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.OperationalData)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(false)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType.OperationalDatas)
      .setId(CategoryIdType.operational_datas)
      .setEnabled(true)
      .create();
  }

  private createLegacyFilesCategory(route: ActivatedRoute, router: Router) {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.LegacyFiles)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(true)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType.SCOMMFiles)
      .setId(CategoryIdType[CategoryIdType.files])
      .setEnabled(true)
      .create();
  }

  private createServicesCategory(route: ActivatedRoute, router: Router) {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.Services)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(true)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType[CategoryNameType.Services])
      .setId(CategoryIdType[CategoryIdType.services])
      .setEnabled(true)
      .create();
  }

  private createUnreferencedTestStepsCategory(route: ActivatedRoute, router: Router): DataCategoryModel {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.UnreferencedTestSteps)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(false)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType.UnreferencedTestSteps)
      .setId(CategoryIdType.unreferenced_testSteps)
      .setEnabled(true)
      .create();
  }
  private createFreezeFrameCategory(route: ActivatedRoute, router: Router): DataCategoryModel {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.FreezeFrames)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(false)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType.FreezeFrames)
      .setId(CategoryIdType[CategoryIdType.freezeframes])
      .setEnabled(true)
      .create();
  }

  private createIvdOverrideCategory(route: ActivatedRoute, router: Router): DataCategoryModel {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.IvdOverrides)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(false)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType.IvdOverride)
      .setId('ivd-overrides')
      .setEnabled(this.specificationService?.currentSpecificationVersion?.ivdOverrideEnabled ?? false)
      .create();
  }

  private createVsocCategory(route: ActivatedRoute, router: Router): DataCategoryModel {
    return this.dataCategoryCreatorService
      .setRoute(route)
      .setRouter(router)
      .setCategoryType(Category.VsocMonitoring)
      .setWithoutPromptEnabled(false)
      .sethasLegacySupport(false)
      .setCategoryItemsService(this.categoryItemsService)
      .setName(CategoryNameType.Vsoc)
      .setId('vsoc')
      .setEnabled(true)
      .create();
  }

  private getItemIdToUseInRoute(item: CategoryItem): string {
    if (!item) {
      return undefined;
    }
    if (item.categoryType == CategoryName.OperationalDataVariable) {
      return item.resourceId;
    }

    return item.name ? item.name : item.id + '';
  }
}
