/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
import { ComputationalMethodIntervalModel } from 'app/app-model/diagnostic-service/text.table.interval.data.model';

export class ComputationalMethodModel {
    id: number;
    methodType: ComputationalMethodType;
    defaultInterval: string;
    intervals: ComputationalMethodIntervalModel[];
}

export enum ComputationalMethodType {
    LinearFormulaData,
    TextTable
}
