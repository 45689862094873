<app-dialog-base modalId="versionReleaseModal" title="Release specification version {{currentSpecificationVersionName}}"
    [itemTemplate]="versionReleaseTemplate" [footerTemplate]="versionReleaseFooter"></app-dialog-base>

<ng-template #versionReleaseTemplate>
    <ng-container *ngIf="currentSpecificationVersion">

        <div class="sdds-banner">
            <div class="sdds-banner-body">
                <ng-container *ngIf="currentSpecificationVersionReleased">
                    The specification version is already released.
                </ng-container>

                <ng-container *ngIf="!currentSpecificationVersionReleased">
                    <b>Note!</b> After releasing you can't make any more changes to the content. Is the order of the
                    identifications correct? Make sure you check the <a target="_blank"
                        href="/content/identification-groups">Identification Group <mat-icon
                            fontSet="material-icons-outlined" class="md-18">open_in_new</mat-icon></a> and order the
                    specifications.
                </ng-container>
            </div>
        </div>

        <div>
            <h4>Comment</h4>           
            <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-on-white-bg sdds-no-padding sdds-form-field">
                <sdds-textfield type="text" size="md" ngDefaultControl [(ngModel)]="currentSpecificationVersion.comment" 
                                label-inside="Comment" placeholder="Write here.." [attr.state]="!currentSpecificationVersion.comment ? 'error' : ''">
                    <span *ngIf="!currentSpecificationVersion.comment" slot="sdds-helper">* Required</span>
                </sdds-textfield>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #versionReleaseFooter>
    <ng-container *ngIf="currentSpecificationVersion && currentSpecificationVersionReleased">
        <button slot="sdds-modal-actions" modal-dismiss class="sdds-btn sdds-btn-primary sdds-btn-md" (click)="close()"
            [disabled]="loading">Ok</button>
    </ng-container>

    <ng-container *ngIf="currentSpecificationVersion && !currentSpecificationVersionReleased">
        <button slot="sdds-modal-actions" class="sdds-btn sdds-btn-primary sdds-btn-md mr-12" (click)="release()"
            [disabled]="loading || !currentSpecificationVersion.comment">Confirm Release</button>
        <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md"
            (click)="close()" [disabled]="loading">Cancel</button>
    </ng-container>

    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md hide-element"
        style="display: none;" appCloseModal [close]="modalClosing"></button>
</ng-template>