import { Node } from './node';

export class Edge {

  source: Node;
  target: Node;
  links: Link[];

  constructor(source: Node, target: Node) {
    this.source = source;
    this.target = target;
    this.links = [];
  }

  addLink(link: Link) {
    this.links.push(link);
  }
}

export class Link {
  name: string;
  link: string;

  constructor(name: string, link: string) {
    this.name = name;
    this.link = link;
  }
}
