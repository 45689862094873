import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'app/app-services/navigation.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { DataCategoryModel } from 'app/data-categories/data-category-model';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';
import { DiagnosticServiceCategoryItemGroup } from 'app/modules/shared/model/service/diagnostic-service-category-item-group';
import { BehaviorSubject } from 'rxjs';

import { CategoryItem } from '../../app-model/category-item';
import { DiagnosticServiceViewType } from '../../app-model/enums';
import { Specification } from '../../app-model/specification';
import { SpecificationVersion } from '../../app-model/specification-version';
import { DataCategoriesService } from '../../app-services/data-categories-service';
import { DiagnosticServiceProvider } from '../../app-services/diagnostic.service.service';
import { DiagnosticServiceDataCategoryModel } from '../../data-categories/diagnostic-service-data-category-model';
import {
  DiagnosticServiceGroupTreeNode,
} from '../../modules/shared/components/tree/model/diagnostic-service-group-tree-node';
import { DiagnosticServiceTreeNode } from '../../modules/shared/components/tree/model/diagnostic-service-tree-node';
import { Tree } from '../../modules/shared/components/tree/model/tree';
import { TreeNode } from '../../modules/shared/components/tree/model/tree-node';
import { TreeService } from '../../modules/shared/components/tree/service/tree.service';
import { MessageService } from '../../modules/shared/services/message-service.service';

@Component({
  selector: 'app-category-items-diagnostic-services',
  templateUrl: './category-items-diagnostic-services.component.html',
  styleUrls: ['./category-items-diagnostic-services.component.css']
})
export class CategoryItemsDiagnosticServicesComponent implements OnInit {
  @Input()
  specification: Specification;

  @Input()
  specificationVersion: SpecificationVersion;

  @Input()
  category: DiagnosticServiceDataCategoryModel;

  @Input()
  update: BehaviorSubject<any>;

  @Input()
  canRemove = true;

  @Input()
  canAdd = true;

  @Input()
  dataTarget = '#createItemModal';

  @Input()
  isAllowed: boolean;

  get selectedView() {
    return this._selectedView;
  }

  @Input()
  set selectedView(value: DiagnosticServiceViewType) {
    this._selectedView = value;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  DiagnosticServiceViewType = DiagnosticServiceViewType;
  tree: Tree;
  loading = false;
  loadedView: DiagnosticServiceViewType;
  filterText: string;

  private _selectedView: DiagnosticServiceViewType = DiagnosticServiceViewType.ProtocolServices;

  constructor(private categoryService: DataCategoriesService,
    private diagnosticService: DiagnosticServiceProvider,
    private router: Router,
    private messageService: MessageService,
    private specificationStatusService: SpecificationStatusService,
    private specificationService: SpecificationService,
    private navigationService: NavigationService) { }

  get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  public get canModifyService() {
    return this.specificationService.isLegacyVersion || this.specificationService.isUpgradedVersion || this.specificationService.isNativeVersion;
  }

  ngOnInit() {
    this.update.subscribe((x: { item: CategoryItem; viewId: number }) => {

      if (x === null) {
        return;
      }

      this.selectedView = x.viewId;
      this.createViewTree(x.viewId);
      this.loadedView = x.viewId;

      if (this.filterText) {
        this.filter(this.filterText);
      }

      if (x.item) {
        this.selectNodeInTree(x.item.name);
      }
    });

    this.categoryService.categoryContentModified.subscribe((value: { modifiedCategory: DataCategoryModel; itemId: string }) => {
      if (!this.specification || !this.specificationVersion) {
        return;
      }

      if (!value.itemId) {
        this.router.navigate(['/specification', this.specification.id, this.specificationVersion.id, value.modifiedCategory.id]);
      } else {
        this.router.navigate(['/specification', this.specification.id, this.specificationVersion.id, value.modifiedCategory.id], { queryParams: { itemId: +value.itemId } });
      }
    });
  }

  createItem(itemName: string) {
    if (itemName) {
      this.categoryService.createAndAddItemToActiveCategory(itemName, this.specificationVersion.id).subscribe(() => {
        this.router.navigate(['/specification', this.specification.id, this.specificationVersion.id, this.category.id],
          {
            queryParams: {
              viewId: +this.selectedView, itemId: itemName
            }
          });
      }, err => {
        this.messageService.dispatchErrorMessageFromApi(err);
      });
    }
  }

  removeSelectedItem() {
    this.categoryService.removeItemFromActiveCategory(this.specificationVersion.id, this.category.activeItem).subscribe(() => {
      this.router.navigate(['/specification', this.specification.id, this.specificationVersion.id, this.category.id],
        {
          queryParams: {
            viewId: +this.selectedView
          }
        });
    }, err => {
      this.messageService.dispatchErrorMessageFromApi(err);
    });
  }

  selectNodeInTree(name: string) {
    if (this.tree) {
      this.tree.selectFirstTreeNodeThatMatchesPredicate(t => {
        if (t instanceof DiagnosticServiceTreeNode) {
          return t.diagnosticServiceCategoryItem.name === name;
        }

        return false;
      });
    }
  }

  filter(filterText: string) {
    this.filterText = filterText;

    if (this.tree) {
      this.tree.showTreeNodesThatContainsText(filterText);
    }
  }

  updateRoute(itemName: string) {
    if (!itemName) {
      return;
    }

    if (itemName) {
      if (this.category.activeItem && this.category.activeItem.name === itemName) {
        return;
      }

      this.router.navigate(['/specification', this.specification.id, this.specificationVersion.id, this.category.id],
        { queryParams: { viewId: +this.selectedView, itemId: itemName } });
    }
    // else {
    //   this.router.navigate(['/specification', this.specification.id, this.specificationVersion.id, this.category.id],
    //     { skipLocationChange: true, queryParams: { viewId: +this.selectedView, itemId: itemName, trash: this.tree.selectedTreeNode.name } });
    // }
  }

  viewSelected(view: DiagnosticServiceViewType) {
    this.router.navigate(['/specification', this.specification.id, this.specificationVersion.id, this.category.id], { queryParams: { viewId: +view } });
  }

  createViewTree(type: DiagnosticServiceViewType) {
    const treeService = new TreeService();
    const tree = new Tree(treeService);

    tree.selectedTreeNodeEventEmitter.subscribe(x => {
      if (x instanceof DiagnosticServiceTreeNode) {
        this.setDiagnosticServiceItem(x.diagnosticServiceCategoryItem);
      } else {
        if (x instanceof DiagnosticServiceGroupTreeNode) {
          this.setDiagnosticServiceGroupItem(x.diagnosticServiceCategoryItemGroup);
        }
      }
    });

    this.tree = tree;

    this.category.currentView.children.forEach(x => {
      const node = new DiagnosticServiceGroupTreeNode(x, treeService);
      tree.addRootNode(node);

      this.createViewTreeGroup(node, x, treeService);
    });

    this.category.currentView.diagnosticServices.forEach(x => {
      tree.addRootNode(new DiagnosticServiceTreeNode(x, this.specificationVersion, treeService, this.specificationService,
        () => this.specificationVersion.releaseStatus === 0,
        (itemToCopy) => this.copyDagnosticService(itemToCopy)));
    });
  }

  copyDagnosticService(itemToCopy: any) {
    console.log('Copying diagnostic service');
    this.diagnosticService.copyService(itemToCopy.id, this.specificationVersion.id).subscribe(copyItem => {
      this.categoryService.activeCategory.addItemToContainer(copyItem);
      this.categoryService.activeCategory.activeItem = copyItem;
      this.categoryService.categoryContentModified.next({ modifiedCategory: this.categoryService.activeCategory, itemId: copyItem.id + '' });
      this.navigationService.navigateToDiagnosticService(copyItem.name);
    });
  }

  createViewTreeGroup(node: TreeNode, group: DiagnosticServiceCategoryItemGroup, treeService: TreeService) {
    if (!group) {
      return;
    }

    group.diagnosticServices.forEach(d => {
      node.addChild(new DiagnosticServiceTreeNode(d, this.specificationVersion, treeService, this.specificationService,
        () => this.specificationVersion.releaseStatus === 0,
        (itemToCopy) => this.copyDagnosticService(itemToCopy)));
    });

    group.children.forEach(x => {
      const groupNode = new DiagnosticServiceGroupTreeNode(x, treeService);
      this.createViewTreeGroup(groupNode, x, treeService);

      node.addChild(groupNode);
    });
  }

  expandTree() {
    if (this.tree) {
      this.tree.expandEntireTree();
    }
  }

  collapseTree() {
    if (this.tree) {
      this.tree.collapseEntireTree();
    }
  }

  onCurrentItemNameChanged(newItemName: string) {
    this.tree.selectedTreeNode.name = newItemName;
  }

  private setDiagnosticServiceItem(serviceItem: DiagnosticServiceCategoryItem) {
    this.category.selectedGroupItem = undefined;
    // this.category.selectedItem = serviceItem;
    this.updateRoute(serviceItem.name);
  }

  private setDiagnosticServiceGroupItem(serviceGroup: DiagnosticServiceCategoryItemGroup) {
    this.category.selectedGroupItem = serviceGroup;
    this.category.selectedItem = undefined;
    this.updateRoute(undefined);
  }
}
