<app-category-header [text]="'Servers'"></app-category-header>

<div class="sdds-row">
    <div
        class="sdds-col-max-2 sdds-col-xxlg-2 sdds-col-xlg-2 sdds-col-lg-2 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <app-category-items *ngIf="category" [specification]="specification"
            [specificationVersion]="specificationVersion" [category]="category" [isAllowed]="canEditServer()"></app-category-items>
    </div>

    <div
        class="sdds-col-max-10 sdds-col-xxlg-10 sdds-col-xlg-10 sdds-col-lg-10 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <div *ngIf="serveridentificationItem">
            <form name="idForm">
                <div class="overflow-hidden">
                    <div class="column">
                        <div class="sdds-dropdown sdds-dropdown-small">
                            <h3 class="form-horizontal">Diagnostic Family</h3>
                            <select name="famSel" [(ngModel)]="serveridentificationItem.model.diagnosticFamily"
                                (change)="onDiagnosticFamilySelected()" [disabled]="!isEditingAllowed||!canEditServer()">
                                <option *ngFor="let family of _families" [ngValue]="family.name">{{family.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="column">
                        <div class="sdds-dropdown sdds-dropdown-small">
                            <h3 class="form-horizontal">Generation</h3>
                            <select name="genSel" [(ngModel)]="serveridentificationItem.model.generation"
                                (ngModelChange)="onGenerationChanged();" [disabled]="!isEditingAllowed||!canEditServer()">
                                <option active></option>
                                <option *ngFor="let generation of availableGenerations" [ngValue]="generation.name">
                                    {{generation.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="column">
                        <div class="sdds-dropdown sdds-dropdown-small">
                            <div class="form-horizontal">
                                <h3>Product Affinity
                                    <i class="scania-icon-info-round xs"
                                        title="Information about what product affinity is."></i>
                                </h3>
                            </div>

                            <select name="productAffinitySelect"
                                [(ngModel)]="serveridentificationItem.model.productAffinity"
                                (ngModelChange)="onProductAffinityChanged();" [disabled]="!isEditingAllowed||!canEditServer()">
                                <optgroup label="Available affinities">
                                    <option *ngFor="let productAffinity of availableAffinities"
                                        [ngValue]="productAffinity">
                                        {{productAffinity}}
                                    </option>
                            </select>
                        </div>
                    </div>

                    <div class="column sdds-on-white-bg">
                        <h3 class="form-horizontal">SCOMM Class Name</h3>
                    
                            <input type="text" name="Item name"
                            ngDefaultControl [(ngModel)]="serveridentificationItem.model.className"
                            label-inside="Item name"  (customChange)="onClassNameChanged()"
                           > 
                    </div>
                </div>

                <div class="overflow-hidden mt-16">
                    <div>
                        <h3 class="form-horizontal">Identification group</h3>
                        <app-identification-group-selector [serverIdentificationItem]="serveridentificationItem"
                            [serverIdentificationGenerationChangedEventEmitter]="generationChangedEventEmitter"
                            [isReadOnly]="!isEditingAllowed">
                        </app-identification-group-selector>
                    </div>

                    <div>
                        <div>
                            <h3 class="form-horizontal">Identification Method</h3>
                            <div class="mb-16">
                                <app-create-expression-node *ngIf="canCreateExpressionNode"
                                    [legacyModeEnabled]="legacyModeEnabled" [isReadOnly]="!isEditingAllowed"
                                    (createLegacyValueEquals)="addValueEqualsComparisonNode()"
                                    (createLegacyValueStartsWith)="addValueStartsWithComparisonNode()"
                                    (createLegacyValueContains)="addValueContainsComparisonNode()"
                                    (createValueStartsWith)="addValueStartsWithComparisonNode()"
                                    (createValueContains)="addValueContainsComparisonNode()"
                                    (createCanAddressComparison)="addCanAddressComparisonNode()"
                                    (createSpecialComparison)="addSpecialComparisonNode()"
                                    (createExecutingModeComparison)="addExecutingModeComparisonNode()"
                                    (createDoesNotExistComparison)="addDoesNotExistComparisonNode()"
                                    (createOperator)="addOperator()"
                                    (createValueComparisonNodeEqualTo)="addValueComparisonNodeEqualTo()"
                                    (createValueComparisonNodeInterval)="addValueComparisonNodeInterval()"
                                    [isAllowed]="canEditServer()">
                                </app-create-expression-node>
                            </div>
                            <br />
                            <app-expression-node-renderer *ngIf="serveridentificationItem.model.expression"
                                [legacyModeEnabled]="legacyModeEnabled" [isReadOnly]="!isEditingAllowed"
                                [expressions]="[serveridentificationItem.model.expression]" [copyEnabled]="false"
                                [reorderEnabled]="false" [isAllowed]="canEditServer()"> 
                            </app-expression-node-renderer>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <app-move-expressions-confirm (accept)="onMoveExpressionsConfirm()" (cancelAction)="onMoveExpressionsCancel()">
        </app-move-expressions-confirm>
        <a #activateMoveExpressionConfirm id="moveExpressionsToOperatorModal"></a>
    </div>
</div>
