import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ServiceExecutionModel } from 'app/app-model/diagnostic-service/service-execution.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { Observable, zip } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { DiagnosticRequisitesBase } from '../../model/diagnostic-requisites-base';

@Component({
  selector: 'app-prerequisites',
  templateUrl: './prerequisites.component.html',
  styleUrls: ['./prerequisites.component.css']
})
export class PrerequisitesComponent extends DiagnosticRequisitesBase implements OnInit {

  @Input()
  isReadOnly: boolean;

  @Input()
  canActivateWriteFingerprint = true;

  public get loginServiceExecution(): ServiceExecutionModel {
    return this._loginServiceExecution;
  }

  @Input()
  public set loginServiceExecution(v: ServiceExecutionModel) {
    this._loginServiceExecution = v;

    if (this._loginServiceExecution) {
      this.initLoginRequisites();
    } else {
      this.resetLoginRequisites();
    }
  }

  private _fingerprintServiceExecution: ServiceExecutionModel;
  public get fingerprintServiceExecution(): ServiceExecutionModel {
    return this._fingerprintServiceExecution;
  }

  @Input()
  public set fingerprintServiceExecution(v: ServiceExecutionModel) {
    this._fingerprintServiceExecution = v;

    if (this._fingerprintServiceExecution) {
      this.initFingerprintRequisites();
    } else {
      this.resetFingerprintRequisites();
    }
  }

  @Output()
  loginCommandChanged: EventEmitter<string> = new EventEmitter();

  @Output()
  fingerprintCommandChanged: EventEmitter<string> = new EventEmitter();

  availableSessions: string[] = [];
  availableSecurityAccessModes: string[] = [];
  availableFingerprints: string[] = [];

  sessionControl = new FormControl();
  securityAcessModeControl = new FormControl();
  fingerprintControl = new FormControl();

  filteredSessions: Observable<string[]>;
  filteredSecurityAccessModes: Observable<string[]>;
  filteredFingerprints: Observable<string[]>;

  selectedSession: string;
  selectedSecurityAccessMode: string;
  selectedFingerprint: string;

  selectedSessionChanged: boolean = false;
  selectedSecurityAccessModeChanged: boolean = false;
  selectedFingerprintChanged: boolean = false;

  loginEnabled: boolean;
  writeFingerprintEnabled: boolean;
  _loginServiceExecution: ServiceExecutionModel;

  constructor(private dataCategoriesService: DataCategoriesService,
    private specificationService: SpecificationService,
    private authService: AuthService) {
    super();
  }

  get canConfirmLogin(): boolean {
    return (this.selectedSessionChanged && !this.isReadOnly) || (this.selectedSecurityAccessModeChanged && !this.isReadOnly);
  }

  get canConfirmFingerprint(): boolean {
    return this.selectedFingerprintChanged && !this.isReadOnly;
  }

  ngOnInit(): void {
    this.loadCategoryItems();
  }

  confirmEnteredLogin() {
    this.loginCommandChanged.emit(this.selectedSecurityAccessMode ? `${this.selectedSession},${this.selectedSecurityAccessMode}` : this.selectedSession);
    this.selectedSessionChanged = false;
    this.selectedSecurityAccessModeChanged = false;
  }

  confirmEnteredFingerprint() {
    this.fingerprintCommandChanged.emit(this.selectedFingerprint);
    this.selectedFingerprintChanged = false;
  }

  onActivateLogin() {
    this.loginEnabled = !this.loginEnabled;

    if (!this.loginEnabled) {
      this.selectedSession = '';
      this.selectedSecurityAccessMode = '';
      this.loginCommandChanged.emit('');

      // Fingerprint can't be enabled if the Login is disabled.
      if (this.writeFingerprintEnabled) {
        setTimeout(() => {
          this.onActivateWriteFingerprint();
        }, 250);
      }
    }
  }

  onActivateWriteFingerprint() {
    this.writeFingerprintEnabled = !this.writeFingerprintEnabled;

    if (!this.writeFingerprintEnabled) {
      this.selectedFingerprint = '';
      this.fingerprintCommandChanged.emit('');
    }
  }

  protected loadCategoryItems() {
    const currentSpecificationversion = this.specificationService.currentSpecificationVersion;
    const sessionObservable = this.dataCategoriesService.updateCategory(Category.Sessions, currentSpecificationversion);
    const secAccessModeObservable = this.dataCategoriesService.updateCategory(Category.SecurityAccess, currentSpecificationversion);
    const fingerprintsObservable = this.dataCategoriesService.updateCategory(Category.Fingerprints, currentSpecificationversion);

    // Run & wait until all category items have loaded before trying to access them
    zip(sessionObservable, secAccessModeObservable, fingerprintsObservable).subscribe(_ => {
      this.availableSessions = this.dataCategoriesService.getCategory(Category.Sessions).items.map(item => item.name);
      this.availableSecurityAccessModes = this.dataCategoriesService.getCategory(Category.SecurityAccess).items.map(item => item.name);
      this.availableFingerprints = this.dataCategoriesService.getCategory(Category.Fingerprints).items.map(item => item.name);

      this.enableOrDisableInputControls();
      this.initFilters();
      this.controlValueChanges();
    });
  }

  protected enableOrDisableInputControls() {
    if (this.isReadOnly || !this.availableSessions) {
      this.sessionControl.disable();
    }
    if (this.isReadOnly || !this.availableSecurityAccessModes) {
      this.securityAcessModeControl.disable();
    }
    if (this.isReadOnly || !this.availableFingerprints) {
      this.fingerprintControl.disable();
    }
  }

  protected initFilters() {
    this.filteredSessions = this.sessionControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterValues(value, this.availableSessions))
    );

    this.filteredSecurityAccessModes = this.securityAcessModeControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterValues(value, this.availableSecurityAccessModes))
    );

    this.filteredFingerprints = this.fingerprintControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterValues(value, this.availableFingerprints))
    );
  }

  canEditCategoryItems(): boolean {
    return this.authService.canEditCategoryItems();
  }

  protected controlValueChanges() {
    this.sessionControl.valueChanges.subscribe(value => {
      if (this.selectedSession != value) {
        this.selectedSession = value;
        this.selectedSessionChanged = true;
      }
    });

    this.securityAcessModeControl.valueChanges.subscribe(value => {
      if (this.selectedSecurityAccessMode != value) {
        this.selectedSecurityAccessMode = value;
        this.selectedSecurityAccessModeChanged = true;
      }
    });

    this.fingerprintControl.valueChanges.subscribe(value => {
      if (this.selectedFingerprint != value) {
        this.selectedFingerprint = value;
        this.selectedFingerprintChanged = true;
      }
    });
  }

  private initLoginRequisites() {
    if (this.loginServiceExecution) {
      this.loginEnabled = true;
      const loginCommandArgs = this.loginServiceExecution.command.arguments.split(',');
      this.selectedSession = loginCommandArgs[0];
      this.selectedSecurityAccessMode = loginCommandArgs[1];
    }
  }

  private resetLoginRequisites() {
    this.loginEnabled = false;
    this.selectedSession = undefined;
    this.selectedSecurityAccessMode = undefined;
  }

  private initFingerprintRequisites() {
    if (this.fingerprintServiceExecution) {
      this.writeFingerprintEnabled = true;
      this.selectedFingerprint = this.fingerprintServiceExecution.command.arguments;
    }
  }

  private resetFingerprintRequisites() {
    this.writeFingerprintEnabled = false;
    this.selectedFingerprint = undefined;
  }
}
