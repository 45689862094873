import { Injectable } from '@angular/core';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { User } from 'app/modules/shared/model/user';
import { ApiProxyService } from 'app/modules/shared/services/api-proxy.service';
import { environment } from 'environments/environment';
import { Observable, Subscription, of } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUser: User;
  userSubscription: Subscription;

  constructor(private apiProxy: ApiProxyService, private authService: AuthService) { }

  public getUsers(): Observable<User[]> {
    return this.apiProxy.get<User[]>(environment.apiUrl + 'users');
  }

  public deleteUser(user: User) {
    return this.apiProxy.delete<User>(`${environment.apiUrl}users/${user.userName}`);
  }

  public updateUser(user: User) {
    return this.apiProxy.put<User>(environment.apiUrl + 'users', user);
  }

  public getUser(username: string) {
    return this.apiProxy.get<User>(environment.apiUrl + 'users/' + username);
  }

  public userIsAdmin(username: string) {
    return this.apiProxy.get(environment.apiUrl + 'users/isAdmin' + username);
  }

  public getCurrentUser(): Observable<User> {
    return this.authService.userSubject.pipe(filter(usr => usr !== null), mergeMap(user => this.getLoggedInUser()));
  }

  private getLoggedInUser(): Observable<User> {
    if (this.currentUser) {
      return of(this.currentUser);
    }
    return this.apiProxy.get<User>(environment.apiUrl + 'users/getCurrentUser')
      .pipe(tap(user => this.currentUser = user));
  }

}
