import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Specification } from 'app/app-model/specification';
import { SpecificationVersion } from 'app/app-model/specification-version';

import { MessageService } from '../../services/message-service.service';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { SpecificationService } from './../../../../app-services/specification-service';

@Component({
  selector: 'app-specification-version-selector',
  templateUrl: './specification-version-selector.component.html',
  styleUrls: ['./specification-version-selector.component.css']
})
export class SpecificationVersionSelectorComponent implements OnInit {

  @ViewChild('select', { static: true }) selectElement: ElementRef;
  @ViewChild('filterToolbar', { static: true }) filterToolbar: ToolbarComponent;

  @Input()
  header: string;

  @Input()
  multiSelect: boolean;

  @Input()
  selectedSpecificationVersions = Array<SpecificationVersion>();

  filterText: string;
  loading: boolean;

  private _specifications: Specification[];
  public get specifications(): Specification[] {
    return this.filterText ?
      this._specifications.filter(p => p.name && p.name.toLowerCase().includes(this.filterText.toLowerCase())) :
      this._specifications;
  }
  public set specifications(v: Specification[]) {
    this._specifications = v;
  }

  constructor(private specificationService: SpecificationService, private messageService: MessageService) { }

  ngOnInit() {
    this.loading = true;
    this.specifications = null;
    this.specificationService.getSpecifications().subscribe(specifications => {
      this.specifications = specifications;
      for (const spec of this.specifications) {
        spec.specificationVersions.sort((a, b) => (a.id < b.id) ? 1 : -1);
      }
      this.loading = false;
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
      this.loading = false;
    });
  }

  filter(filterText: string) {
    this.filterText = filterText;
  }

  updateSelectedVersions() {
    this.selectedSpecificationVersions.splice(0, this.selectedSpecificationVersions.length);
    const currentValues = this.selectElement.nativeElement.options;

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < currentValues.length; i++) {
      if (currentValues[i].selected) {
        const idAndSpecificationVersion = currentValues[i].value.split('|');
        const specification = this.specifications.find(s => s.id === +idAndSpecificationVersion[0]);
        const specificationVersion = specification.specificationVersions.find(projVer => projVer.name === idAndSpecificationVersion[1]);

        this.selectedSpecificationVersions.push(specificationVersion);
      }
    }
  }

  reset(): any {
    this.filterToolbar.filterText = undefined;
    this.selectedSpecificationVersions = [];
  }
}
