import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StepType } from 'app/app-model/diagnostic-service/path-step.model';
import { FingerprintModel } from 'app/app-model/fingerprint/fingerprint.model';
import { CategoryServiceBase } from 'app/app-services/category-base.service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { FingerprintCategoryItemDirective } from 'app/data-categories/fingerprint/fingerprint-category-item';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { SpecificationService } from './specification-service';

@Injectable({
  providedIn: 'root'
})
export class FingerprintService extends CategoryServiceBase<FingerprintModel, FingerprintCategoryItemDirective> implements OnDestroy {

  constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
    private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected apiProxy: ApiProxyService) {
    super(apiProxy);
    this.apiCategoryName = 'fingerprints';
  }

  ngOnDestroy(): void {
    super.unsubscribe();
  }

  getItemsPreview(specificationVersionId: number): Observable<FingerprintCategoryItemDirective[]> {
    this.isPending = true;

    if (this.hasCachedItems) {
      this.isPending = false;
      return of(this.items);
    } else {
      return this.apiProxy.get<FingerprintModel[]>(
        `${environment.apiUrl}versions/${specificationVersionId}/fingerprints`).pipe(map(fingerprintModels => {
          this.items = new Array<FingerprintCategoryItemDirective>();
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let i = 0; i < fingerprintModels.length; i++) {
            const fingerprint = new FingerprintCategoryItemDirective(
              this.specificationService,
              this.locationService,
              this.router,
              this.activatedRoute);
            fingerprint.fingerprintService = this;
            fingerprint.diagnosticServiceProvider = this.diagnosticServiceProvider;
            fingerprint.model = fingerprintModels[i];

            this.items.push(fingerprint);
          }

          this.hasCachedItems = true;
          this.isPending = false;
          this.getItemsPreviewDone.next(this.isPending);

          return this.items;
        }));
    }
  }

  getCategoryItemModel(specificationVersionId: number, name: string): Observable<FingerprintCategoryItemDirective> {
    return this.apiProxy.get<FingerprintModel>(
      `${environment.apiUrl}versions/${specificationVersionId}/fingerprints/${name}`).pipe(map(fingerprintModel => {
        const fingerprintItem = this.createFingerprintItemFromModel(fingerprintModel);
        this.items.push(fingerprintItem);
        return fingerprintItem;
      }));
  }

  getCompleteItems(specificationVersionId: number): Observable<FingerprintCategoryItemDirective[]> {
    throw new Error('Method not implemented.');
  }

  createItem(specificationVersionId: number, itemName?: string): Observable<FingerprintCategoryItemDirective> {
    const itemToCreate = new FingerprintModel();
    itemToCreate.name = itemName;

    return this.apiProxy.post<FingerprintModel>(
      `${environment.apiUrl}versions/${specificationVersionId}/fingerprints/`, itemToCreate).pipe(map(fingerprintModel => {
        const fingerprintItem = this.createFingerprintItemFromModel(fingerprintModel);
        this.items.push(fingerprintItem);

        return fingerprintItem;
      }));
  }

  public deleteItem(specificationVersionId: number, itemName?: string): Observable<any> {
    return this.apiProxy.delete(`${environment.apiUrl}versions/${specificationVersionId}/fingerprints/${itemName}`)
      .pipe(tap(deletedItem => this.removeCachedItem(deletedItem.name)));
  }

  public updateItem(specificationVersionId: number, fingerprintDataModel: any, itemName?: string): Observable<FingerprintModel> {
    return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/fingerprints/${itemName}/`, fingerprintDataModel);
  }

  public getFingerprint(specificationVersionId: number, name: string): Observable<FingerprintCategoryItemDirective> {
    return this.apiProxy.get<FingerprintCategoryItemDirective>(
      `${environment.apiUrl}versions/${specificationVersionId}/fingerprints/${name}`);
  }

  public tryAssignFingerprintConstants(sequence: ServiceExecutionSequence) {
    if (!sequence) { return; }
    if (!sequence.serviceExecutions || sequence.serviceExecutions.length === 0) { return; }

    /**
     * Default constants are assigned by best efford if the diagnostic service contains at least
     * 12 request value parameters. Parameters from index 4 to 11 will be populated with the constants specified
     * in the harmonized UDS standard
     */
    const fingerprintConstants = ['0', '0x00000000', '255', '65535', '0', '0x00000000', '255', '65535'];
    const harmonizedFingerprintParameterCount = 12;
    const fingerprintParameterStartIndex = 4;

    const serviceExecutionToModify = sequence.serviceExecutions[0];

    if (serviceExecutionToModify.availableRequestPropertyParameters.length !== harmonizedFingerprintParameterCount) { return; }

    let fingerprintConstantIndex = 0;

    for (let paramIndex = fingerprintParameterStartIndex; paramIndex < serviceExecutionToModify.model.requestParameters.length; paramIndex++) {
      const propertyParameter = serviceExecutionToModify.availableRequestPropertyParameters[paramIndex];
      const serviceExecutionParameter = serviceExecutionToModify.model.requestParameters[paramIndex];

      serviceExecutionParameter.path = [{
        step: propertyParameter.parameter.name,
        stepType: StepType.StepByName,
        executionIndex: 0,
        index: 0,
        sequenceId: 0
      }];
      serviceExecutionParameter.value = {
        dataType: propertyParameter.parameter.getEnumDataType(),
        data: fingerprintConstants[fingerprintConstantIndex]
      };

      fingerprintConstantIndex++;
    }
  }

  private createFingerprintItemFromModel(fingerprintModel: FingerprintModel): FingerprintCategoryItemDirective {
    const fingerprintItem = new FingerprintCategoryItemDirective(
      this.specificationService,
      this.locationService,
      this.router,
      this.activatedRoute);
    fingerprintItem.fingerprintService = this;
    fingerprintItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
    fingerprintItem.isPreview = false;
    fingerprintItem.model = fingerprintModel;
    fingerprintItem.isSyncingFromMaster = false;

    return fingerprintItem;
  }
}
