export class DiagnosticFamilyModel {
    id: number;
    name: string;
    generations: Array<GenerationModel> = [];
    scommInternal: boolean;
    isSelectable: boolean;
}

export class GenerationModel {
    id: number;
    name: string;
    isSelectable: boolean;

}
