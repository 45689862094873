import { PathStep } from './path-step.model';

export class ServiceExecutionResponseValue {
  sequenceId: number;
  executionIndex: number;
  index: number;
  path: PathStep[] = [];
  target: TargetVariable;
}

export class TargetVariable {
  variable: string;
}
