import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryItem } from 'app/app-model/category-item';
import { CategoryName } from 'app/app-model/enums';
import { SessionsModel } from 'app/app-model/sessions/sessions.model';
import { SessionsService } from 'app/app-services/sessions.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { PropertyType } from 'app/modules/shared/model/properties/base-property';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { tap } from 'rxjs/operators';

export class SessionsCategoryItem extends CategoryItem {

  sessionsService: SessionsService;
  propertyType: PropertyType = PropertyType.Both;
  oldName = '';

  private _model: SessionsModel;
  public get model(): SessionsModel {
    return this._model;
  }
  public set model(v: SessionsModel) {
    this._model = v;
    this.syncFromModel();
  }

  private _serviceExecutionSequence: ServiceExecutionSequence;
  public get serviceExecutionSequence(): ServiceExecutionSequence {
    return this._serviceExecutionSequence;
  }

  public set serviceExecutionSequence(v: ServiceExecutionSequence) {
    this._serviceExecutionSequence = v;
  }

  constructor(private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
    this._serviceExecutionSequence = new ServiceExecutionSequence();
    this.categoryType = CategoryName.EcuSession;
  }

  setName(name: string) {
    this.oldName = this.model.name;
    this.name = name;
    this.model.name = name;
    this.notifySessionChanged();
  }

  notifySessionChanged() {
    console.log('Session ' + this.name + ' modified...Saving changes');
    this.sessionsService.updateItem(this.specificationService.currentSpecificationVersion.id, this.model, this.oldName)
      .pipe(tap(updatedSession => {
        this.model = updatedSession;

        const newUrl = this.router.createUrlTree([], {
          relativeTo: this.activatedRoute,
          queryParams: { itemId: this.model.name }
        }).toString();
        this.locationService.go(newUrl);

        console.log('Session' + this.name + ' successfully updated');
      })).subscribe();
  }

  private syncFromModel() {
    this.name = this.model.name;
    this.oldName = this.name;

    if (!this.isPreview && this.model.setSequence) {
      this.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
      this.serviceExecutionSequence.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
      this.serviceExecutionSequence.setModel(this.model.setSequence).subscribe(_ => {
        this.subscribeServiceExecutionModifiedEvents();
        super.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
      });
    }
  }

  private subscribeServiceExecutionModifiedEvents() {
    if (!this.hasModificationSubscriptions) {
      this._serviceExecutionSequence.modified.subscribe({
        next: () => this.notifySessionChanged()
      });

      this.hasModificationSubscriptions = true;
    }
  }
}
