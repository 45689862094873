<div class="sdds-row" *ngIf="parameter">
    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-16">
        <h5>Parameter Info</h5>
        <table class="table">
            <tbody>
                <tr>
                    <td>Type</td>
                    <td>{{parameter.type}}</td>
                </tr>
                <tr *ngIf="parameter.hasName">
                    <td>Name</td>
                    <td>{{parameter.name}}</td>
                </tr>
                <tr *ngIf="parameter.hexValue">
                    <td>Value</td>
                    <td>{{parameter.hexValue}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="parameter.scalingData" class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-16">
        <h5>Physical Value</h5>
        <table class="table">
            <tbody>
                <tr>
                    <td>Data Type</td>
                    <td>{{parameter.getPhysicalDataType()}}</td>
                </tr>
                <tr>
                    <td>Unit</td>
                    <td>{{parameter.scalingData.unit}}</td>
                </tr>
            </tbody>
        </table>

        <app-value-constraint [valueConstraint]="parameter.scalingData && parameter.scalingData.physicalConstraintData"></app-value-constraint>
    </div>

    <div *ngIf="parameter.scalingData && parameter.scalingData.model.internalToPhysicalComputationalMethod" class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-16">
        <h5>Internal To Physical Computational Method</h5>

        <app-computational-method [computationalMethod]="parameter.scalingData.model.internalToPhysicalComputationalMethod"></app-computational-method>
    </div>

    <div *ngIf="parameter.scalingData && parameter.scalingData.model.physicalToInternalComputationalMethod" class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-16">
        <h5>Physical To Internal Computational Method</h5>

        <app-computational-method [computationalMethod]="parameter.scalingData.model.physicalToInternalComputationalMethod"></app-computational-method>
    </div>

    <div *ngIf="parameter.scalingData" class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-16">
        <h5>Internal Value</h5>
        <table class="table">
            <tbody>
                <tr>
                    <td>Data Type</td>
                    <td>{{parameter.scalingData.internalDataType}}</td>
                </tr>
                <tr>
                    <td>Encoding</td>
                    <td>{{parameter.scalingData.encoding}}</td>
                </tr>
            </tbody>
        </table>

        <app-value-constraint [valueConstraint]="parameter.scalingData.internalConstraintData"></app-value-constraint>
    </div>

    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-16">
        <h5>Position</h5>
        <table class="table">
            <tbody>
                <tr>
                    <td>Byte position</td>
                    <td>{{parameter.model.bytePosition}}</td>
                </tr>
                <tr>
                    <td>Bit position</td>
                    <td>{{parameter.model.bitPosition}}</td>
                </tr>
                <tr *ngIf="!hasMinMaxLength">
                    <td>Bit length</td>
                    <td>{{parameter.model.length.bitLength}}</td>
                </tr>
                <ng-container *ngIf="hasMinMaxLength">
                    <tr>
                        <td>Min-Byte-Length</td>
                        <td>{{parameter.model.length.minByteLength}}</td>
                    </tr>
                    <tr *ngIf="parameter.model.length.hasMaxByteLength">
                        <td>Max-Byte-Length</td>
                        <td>{{parameter.model.length.maxByteLength}}</td>
                    </tr>
                    <tr>
                        <td>Termination</td>
                        <td>{{termination}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>