<div class="sdds-row mt-16 mb-16">
  <div class="sdds-col-max-12 sdds-no-padding">
    <h3>Prerequisites</h3>
  </div>
  <div class="sdds-col-max-12 sdds-no-padding mb-10">
    <span class="sdds-checkbox-item" style="display: initial;">
      <input class="sdds-form-input" type="checkbox" name="loginCommand" id="loginCommand" [checked]="loginEnabled"
        (change)="onActivateLogin()" [disabled]="isReadOnly||!canEditCategoryItems()">
      <label class="sdds-form-label" style="padding-left: 0px !important;" for="loginCommand">
        Login
      </label>
    </span>
  </div>

  <div class="flex" *ngIf="loginEnabled">
    <div class="flex-none">
      <mat-form-field appearance="fill" class="mat-form-field-custom">
        <mat-label>Session</mat-label>
        <input type="text" matInput matNativeControl placeholder="Select a session" [formControl]="sessionControl"
          [matAutocomplete]="sessionAutoComplete" [(ngModel)]="selectedSession" [disabled]="!canEditCategoryItems()" />
        <mat-autocomplete #sessionAutoComplete="matAutocomplete">
          <mat-option *ngFor="let session of filteredSessions | async" [value]="session"
            [disabled]="!canEditCategoryItems()">
            <p class="break-words text-5xl">{{ session }}</p>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="flex-initial ml-10">
      <mat-form-field appearance="fill">
        <mat-label>Security access mode</mat-label>
        <input type="text" matInput matNativeControl placeholder="Select a security access mode"
          [formControl]="securityAcessModeControl" [matAutocomplete]="accessModeAutoComplete"
          [(ngModel)]="selectedSecurityAccessMode" [disabled]="!canEditCategoryItems()" />
        <mat-autocomplete #accessModeAutoComplete="matAutocomplete">
          <mat-option *ngFor="let accessMode of filteredSecurityAccessModes | async" [value]="accessMode"
            [disabled]="!canEditCategoryItems()">
            <p class="break-words text-5xl">{{ accessMode }}</p>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="flex-initial ml-10">
      <button class="sdds-btn sdds-btn-secondary sdds-btn-md sdds-no-padding" id="confirmPrerequisiteBtn"
        (click)="confirmEnteredLogin()" [disabled]="!canConfirmLogin || !canEditCategoryItems()">
        Update
      </button>
    </div>
  </div>

  <div class="sdds-col-max-12 sdds-no-padding">
    <label class="text-blue-600" *ngIf="canConfirmLogin">Press on update to save changes</label>
  </div>

  <div *ngIf="loginEnabled && canActivateWriteFingerprint" class="sdds-col-max-12 sdds-no-padding mb-10 mt-10">
    <span class="sdds-checkbox-item" style="display: initial;">
      <input class="sdds-form-input" type="checkbox" name="writeFingerprintCommand" id="writeFingerprintCommand"
        [checked]="writeFingerprintEnabled" (change)="onActivateWriteFingerprint()"
        [disabled]="isReadOnly||!canEditCategoryItems()">
      <label class="sdds-form-label" style="padding-left: 0px !important;" for="writeFingerprintCommand">
        Write Fingerprint
      </label>
    </span>
  </div>

  <div class="flex" *ngIf="writeFingerprintEnabled">
    <div class="flex-none">
      <mat-form-field appearance="fill">
        <mat-label>Fingerprint</mat-label>
        <input type="text" matInput matNativeControl placeholder="Select a fingerprint"
          [formControl]="fingerprintControl" [matAutocomplete]="fingerprintAutoComplete"
          [(ngModel)]="selectedFingerprint" [disabled]="!canEditCategoryItems()" />
        <mat-autocomplete #fingerprintAutoComplete="matAutocomplete">
          <mat-option *ngFor="let fingerprint of filteredFingerprints | async" [value]="fingerprint"
            [disabled]="!canEditCategoryItems()">
            <p class="break-words text-5xl">{{ fingerprint }}</p>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="flex-initial">
      <button class="sdds-btn sdds-btn-secondary sdds-btn-md ml-10 sdds-no-padding" id="confirmPrerequisiteBtn"
        (click)="confirmEnteredFingerprint()" [disabled]="!canConfirmFingerprint || !canEditCategoryItems()">
        Update
      </button>
    </div>
  </div>

  <div class="sdds-col-max-12 sdds-no-padding">
    <label class="text-blue-600" *ngIf="canConfirmFingerprint">Press on update to save changes</label>
  </div>
</div>