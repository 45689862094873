<app-dialog-base modalId="upgradeVersionModal" title="Upgrade version to ODX based specification" [itemTemplate]="upgradeVersionTemplate" [footerTemplate]="upgradeVersionFooter"></app-dialog-base>

<ng-template #upgradeVersionTemplate>
    <ng-container *ngIf="hasUpgradeSupport">
        <ng-container *ngIf="!anyInWorkVersions">
            <p *ngIf="basedOnVersion">This will upgrade the specification version {{basedOnVersion.name}} and create an ODX based specification version.</p>
            <p>Click on the 'Upgrade' to perform the upgrade.</p>
        </ng-container>

        <ng-container *ngIf="anyInWorkVersions">
            <p>There are currently in work versions of this specification. There can only be one in work version at a time, hence a new version cannot be created.</p>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!hasUpgradeSupport">
        <p>This specification version doesn't have ODX upgrade support yet.</p>
    </ng-container>
</ng-template>

<ng-template #upgradeVersionFooter>
    <ng-container *ngIf="!anyInWorkVersions">
        <button slot="sdds-modal-actions" class="sdds-btn sdds-btn-primary sdds-btn-md mr-12" *ngIf="hasUpgradeSupport" (click)="apply()" [disabled]="loading">Upgrade</button>
        <button slot="sdds-modal-actions" modal-dismiss class="sdds-btn sdds-btn-secondary sdds-btn-md" (click)="close()" [disabled]="loading">Cancel</button>
    </ng-container>

    <ng-container *ngIf="anyInWorkVersions && hasUpgradeSupport">
        <button slot="sdds-modal-actions" modal-dismiss class="sdds-btn sdds-btn-primary sdds-btn-md" (click)="close()" [disabled]="loading">Ok</button>
    </ng-container>

    <button slot="sdds-modal-actions" modal-dismiss class="sdds-btn sdds-btn-secondary sdds-btn-md hide-element" style="display: none;" appCloseModal [close]="modalClosing"></button>
</ng-template>