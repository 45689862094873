import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { ApiProxyService } from '../../shared/services/api-proxy.service';
import { ExportCriteria } from '../model/export/export-critieria';
import { ExportPreview } from '../model/export/export-preview';

@Injectable()
export class ExportService {
  constructor(private apiProxy: ApiProxyService) {
  }

  createGlobalExport(exportCritiera: ExportCriteria): Observable<string> {
    return this.apiProxy.post(environment.apiUrl + 'export/', exportCritiera);
  }

  createGlobalExportNoCritiera(exportData: number[]): Observable<string> {
    return this.apiProxy.post(environment.apiUrl + 'export/noCritiera/' , exportData);
  }

  createGlobalExportPreview(exportCritiera: ExportCriteria): Observable<ExportPreview> {
    return this.apiProxy.post(environment.apiUrl + 'export/preview', exportCritiera);
  }

  createGlobalExportPreviewNoCriteria(exportData: number[]): Observable<ExportPreview> {
    return this.apiProxy.post(environment.apiUrl + 'export/previewNoCritiera', exportData);
  }
}
