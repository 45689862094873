import { Component, Input, OnInit } from '@angular/core';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';
import { IdentificationExpressionService } from 'app/app-services/identification-expression.service';

@Component({
  selector: 'app-does-not-exist-comparison',
  templateUrl: './does-not-exist-comparison.component.html',
  styleUrls: ['./does-not-exist-comparison.component.css']
})
export class DoesNotExistComparisonComponent {

  @Input()
  isReadOnly: boolean;

  @Input()
  reorderEnabled = true;

  @Input()
  copyEnabled = true;

  @Input()
  isAllowed :boolean;

  @Input()
  expressionModel: ServeridentificationExpressionModel;

  canAddress: string;
  hexCount = 1;

  constructor(private identificationExpressionService: IdentificationExpressionService) { }


  get hexValSample(): string {
    if (this.hexCount === 1) {
      return 'e.g. 0x33';
    } else {
      return 'e.g. 0x0033';
    }
  }

  notifyServerIdentificationChanged() {
    this.identificationExpressionService.modified.next(this.expressionModel);
  }

  onHexCodeChanged(hexCode: number) {
    if (hexCode >= 1000000) {
      hexCode -= 1000000;
    }
    this.expressionModel.legacyIdentifierCode = hexCode;
    this.notifyServerIdentificationChanged();
  }

}
