import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IoListModel } from 'app/app-model/iolist/iolist.model';
import { CategoryServiceBase } from 'app/app-services/category-base.service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { IoListCategoryItem } from 'app/data-categories/iolist/iolist-category-item';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { SpecificationService } from './specification-service';

@Injectable({
  providedIn: 'root'
})

export class IoListService extends CategoryServiceBase<IoListModel, IoListCategoryItem> implements OnDestroy {

  constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
    private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected apiProxy: ApiProxyService) {
    super(apiProxy);
    this.apiCategoryName = 'ioList';
  }

  ngOnDestroy(): void {
    super.unsubscribe();
  }

  public getItemsPreview(specificationVersionId: number): Observable<IoListCategoryItem[]> {
    this.isPending = true;

    if (this.hasCachedItems) {
      this.isPending = false;
      return of(this.items);
    } else {
      return this.apiProxy.get<IoListModel[]>(`${environment.apiUrl}versions/${specificationVersionId}/ioList`).pipe(map((iolists) => {
        this.items = new Array<IoListCategoryItem>();
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < iolists.length; i++) {
          const ioCategoryItem = new IoListCategoryItem(this.specificationService, this.locationService, this.router, this.activatedRoute);
          ioCategoryItem.iolistService = this;
          ioCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
          ioCategoryItem.model = iolists[i];

          this.items.push(ioCategoryItem);
        }

        this.hasCachedItems = true;
        this.isPending = false;
        this.getItemsPreviewDone.next(this.isPending);

        return this.items;
      }));
    }
  }

  public createItem(specificationVersionId: number, itemName?: string): Observable<IoListCategoryItem> {
    const itemToCreate = new IoListModel();
    itemToCreate.name = itemName;

    return this.apiProxy.post<IoListModel>(`${environment.apiUrl}versions/${specificationVersionId}/ioList/`, itemToCreate).pipe(map(item => {
      const ioCategoryItem = new IoListCategoryItem(this.specificationService, this.locationService, this.router, this.activatedRoute);
      ioCategoryItem.iolistService = this;
      ioCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      ioCategoryItem.isPreview = false;
      ioCategoryItem.model = item;

      this.items.push(ioCategoryItem);
      return ioCategoryItem;
    }));
  }

  deleteItem(specificationVersionId: number, itemName?: string): Observable<any> {
    return this.apiProxy.delete(`${environment.apiUrl}versions/${specificationVersionId}/ioList/${itemName}`)
      .pipe(tap(deletedItem => this.removeCachedItem(deletedItem.name)));
  }
  updateItem(specificationVersionId: number, ioListDataModel: any, itemName?: string): Observable<IoListModel> {
    return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/ioList/${itemName}/`, ioListDataModel);
  }

  getCategoryItemModel(specificationVersionId: number, name: string): Observable<IoListCategoryItem> {
    throw new Error('Method not implemented.');
  }

  public getCompleteItems(specificationVersionId: number): Observable<IoListCategoryItem[]> {
    throw new Error('Method not implemented.');
  }
}
