<div class="modal fade" data-backdrop="static" id="importModal">
  <div class="modal-dialog">
    <div class="modal-content dialog">
      <div class="modal-header">
        <h4 class="modal-title">Import ODX</h4>
        <button type="button" class="close" [disabled]="loading" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body" [ngClass]="{'loading':loading}">
        <ng-container *ngIf="!isImportAllowed">
          <p class="alert alert-info">{{importNotAllowedReason}}</p>
        </ng-container>
        <ng-container *ngIf="isImportAllowed">
          <div>
            <!-- <br> -->
            <div class="form-group">
              <label class="btn btn-primary">Select file
                <input type="file" (change)="fileChanged($event)">
              </label>
              <label class="fileName" *ngIf="odxFile">{{odxFile.fileName}}</label>
            </div>
          </div>
          <div *ngIf="odxFile">
            <table class="table table-hover table-condensed">
              <thead>
                <tr>
                  <th>Layer Type</th>
                  <th>Short Name</th>
                  <th>Long Name</th>
                  <th>Services</th>
                  <th>Charts</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let layer of layers" (click)="setLayer(layer)" class="hover" [class.active]="selectedLayer && layer.shortName === selectedLayer.shortName">
                  <td>{{layer.type}}</td>
                  <td>{{layer.shortName}}</td>
                  <td>{{layer.longName}}</td>
                  <td>{{layer.serviceCount}}</td>
                  <td>{{layer.stateChartCount}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="odxLayer">
            <h1>{{odxLayer.longName}}
              <small>{{odxLayer.type}}</small>
            </h1>
            <p>{{odxLayer.desc}}</p>

            <!-- <div class="row">
              <div  class="col-md-4">
                <div class="panel panel-info">
                  <div class='panel-heading'>Services</div>
                  <div class='panel-body'>
                    <h2>{{odxLayer.services.length}}</h2>
                  </div>
                </div>
              </div>
              <div  class="col-md-4">
                  <div class="panel panel-info">
                    <div class='panel-heading'>Charts</div>
                    <div class='panel-body'>
                      <h2>{{odxLayer.stateCharts.length}}</h2>
                    </div>
                  </div>
                </div>
                <div  class="col-md-4">
                    <div class="panel panel-warning">
                      <div class='panel-heading'>Issues</div>
                      <div class='panel-body'>
                        <h2>{{odxLayer.importIssues.length}}</h2>
                      </div>
                    </div>
                  </div>
            </div> -->
            <h2>Layer Issues</h2>
            <table class="table table-condensed">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Service</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of pageItems; let i=index">
                  <td>{{(currentPage-1)*pageSize + i + 1}}</td>
                  <td>{{item.serviceName}}</td>
                  <td>{{item.message}}</td>
                </tr>
              </tbody>
            </table>
            <ul class="pagination">
              <li *ngFor="let page of pages " [ngClass]="{'active': (currentPage === page)}">
                <a role="button" (click)="setIssuePage(page) ">{{page}}</a>
              </li>
            </ul>
      </div>
    </ng-container>
    <div class="modal-footer">
      <ng-container *ngIf="!isImportAllowed">
        <button type="button" class="btn btn-primary" data-dismiss="modal" [disabled]="loading">Ok</button>
      </ng-container>
      <ng-container *ngIf="isImportAllowed">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" [disabled]="loading">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="!odxLayer || loading" (click)="import()">Import</button>
      </ng-container>
      <button style="display: none;" appCloseModal type="button" class="btn btn-secondary hide-element" data-dismiss="modal"></button>
    </div>
  </div>
</div>
</div>
</div>
