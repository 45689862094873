import { ChangeType, RbaccEcuDiagnosticProtocol, RbaccEcuDiagnosticSecurityType, RbaccEcuType, RbaccState } from "app/app-model/enums";

 export class Rbac {
  rbacGuid: string;
  name: string;
  data: string;
  defaultEntity: boolean;
  signature: number[];
  certificate: number[];
  owner: string;
  uploadDate: Date;
  connectedSpecificationVersions: RbacSpecificationVersion[]=[];
  connectedGenerations: RbacGeneration[]=[];
  rbacLogs: RbacLog[]=[];

  rbaccUploader : string;
  rbaccApprover  : string;
  ecuFamily   : string;
  targetGenerations   : string[];

  rbaccEcuDiagnosticProtocol:RbaccEcuDiagnosticProtocol;
  rbaccEcuDiagnosticSecurityType:RbaccEcuDiagnosticSecurityType;
  rbaccState:RbaccState;
  rbaccEcuType:RbaccEcuType;
  

}

export class RbacSpecificationVersion {
  rbacGuid: string;
  specificationVersionGuid: string;
  specificationId: number;
  specificationName: string;
  specificationVersionId: string;
  specificationVersionName: string;
}

export class RbacGeneration {
  rbacGuid: string;
  generationId: number;
  generationName: string;
  familyName: string;
}

export class RbacLog{
  rbacGuid: string;
  changeType: ChangeType;
  changedItem: string;
  user: string;
  date: Date;
}
