import { Pipe, PipeTransform } from '@angular/core';
import { CategoryItem } from 'app/app-model/category-item';

@Pipe({ name: 'categoryItemFilter' })
export class CategoryItemFilterPipe implements PipeTransform {

  transform(items: any[], filterText: string): CategoryItem[] {
    if (!items || items.length <= 0) {
      return [];
    }
    if (!filterText || filterText === '') {
      return items;
    }

    const filteredItems = items.filter(item => {
      let contentToSearch = item.name;

      if (item.displayName) {
        contentToSearch += item.displayName;
      }

    if(item.ecuIdentifierHexCodePadded){
      contentToSearch+=item.ecuIdentifierHexCodePadded;
    }

      return contentToSearch && contentToSearch.toLowerCase().includes(filterText.toLowerCase());
    });

    return filteredItems;
  }
}
