import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SpecificationVersionTreeNode } from '../model/specification-version-tree-node';
import { TreeNode } from '../model/tree-node';
import { SpecificationService } from 'app/app-services/specification-service';
import { ServerIdentificationService } from 'app/app-services/server-identification.service';
import { Subscription } from 'rxjs';
import { TreeService } from '../service/tree.service';
import { DiagnosticProtocolType } from 'app/app-model/enums';

@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.css']
})
export class TreeNodeComponent implements OnInit {

  diagnosticNumber: string = "";
  diagnosticNo: string = "";
  identifierName: string[] = [];
  treeService: TreeService;
  selectedSpecificationSubscription: Subscription;
  private _treeNode: TreeNode;
  constructor(private activeRoute: ActivatedRoute,
    public specificationService: SpecificationService,
    public serverIdentificationService: ServerIdentificationService) {
  }

  ngOnInit() {
    this.selectTreeNodeFromQuery();
    this.specificationService.selectedSpecificationEventEmitter.subscribe(_ => {
      if (this.specificationService.currentSpecification && this.specificationService.currentSpecification.name === this.treeNode.name && this.diagnosticNo === "") {
        this.getDiagnosticNumber()
      }
    });
  }

  @Input()
  set treeNode(treeNode: TreeNode) {
    this._treeNode = treeNode;
  }

  get treeNode(): TreeNode {
    return this._treeNode;
  }

  selectTreeNode(): void {
    if (this.specificationService.currentSpecification && this.specificationService.currentSpecification.name === this.treeNode.name) {
      this.getDiagnosticNumber()
    }
    this.treeNode.selectTreeNode();
  }

  selectTreeNodeFromQuery() {
    if (this.activeRoute.snapshot.queryParams.treeNodeName) {
      if (this.treeNode.name === this.activeRoute.snapshot.queryParams.treeNodeName) {
        this.treeNode.selectTreeNode();
      }
    }
  }

  keydown(event: KeyboardEvent) {
    const keyCode = event.key;
    if (keyCode === 'ArrowRight') {
      this.treeNode.isExpanded = true;
      event.preventDefault();
    } else if (keyCode === 'ArrowLeft') {
      this.treeNode.isExpanded = false;
      event.preventDefault();
    } else if (keyCode === 'ArrowDown') {
      this.treeNode.navigateDown();
      event.preventDefault();
    } else if (keyCode === 'ArrowUp') {
      this.treeNode.navigateUp();
      event.preventDefault();
    } else if (keyCode === 'Enter') {
      if (this.treeNode instanceof SpecificationVersionTreeNode) {
        (this.treeNode as SpecificationVersionTreeNode).performOpenAction();
      }
    }
  }

  getDiagnosticNumber() {
    if (this.diagnosticNo) { return; }
    if (this.specificationService.currentSpecificationVersion) {
      this.serverIdentificationService.getItemsPreview(this.specificationService.currentSpecificationVersion.id).subscribe(serverItems => {
        if (!serverItems || serverItems.length == 0) {
          return;
        }

        this.diagnosticNumber = serverItems[0].model.formattedExpression;
        let words = this.diagnosticNumber.split('&');
        words.forEach(identifier => {
          identifier = identifier.replace(/\(|\)/g, "")
          this.identifierName.push(identifier)
        })

        if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
          this.identifierName.forEach(entry => {
            entry = entry.replace(" ", "")
            if (entry.startsWith("0x9F")) {
              this.diagnosticNo += '(' + entry + ')' + '&';
            }
          })
          if (this.diagnosticNo.endsWith('&')) {
            this.diagnosticNo = this.diagnosticNo.replace(/.$/, "");
          }
        }

        if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Uds) {
          this.identifierName.forEach(entry => {
            entry = entry.replace(" ", "")
            if (entry.startsWith("0xF1B0")) {
              this.diagnosticNo += '(' + entry + ')' + '&';
            }
          })
          if (this.diagnosticNo.endsWith('&')) {
            this.diagnosticNo = this.diagnosticNo.replace(/.$/, "");
          }
        }
      })
    }
  }

  expandCollapseTreeNode(event: any) {
    this.treeNode.isExpanded = !this.treeNode.isExpanded;
    this.selectTreeNode();
  }
}
