import { Component, OnInit, Input } from '@angular/core';
import { ValueConstraint } from 'app/app-model/diagnostic-service/value.constraint.model';
import { ValueConstraintData } from 'app/modules/shared/model/service/parameters/value-constraint-data';

@Component({
  selector: 'app-value-constraint',
  templateUrl: './value-constraint.component.html',
  styleUrls: ['./value-constraint.component.css']
})
export class ValueConstraintComponent {

  @Input()
  valueConstraint: ValueConstraintData;
}
