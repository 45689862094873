import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerIdentificationCompareOperatorType } from 'app/app-model/server-identification/server-identification.enums';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { EcuIdentifierService } from 'app/app-services/ecu-identification.service';
import { NavigationService } from 'app/app-services/navigation.service';
import { ParameterValueValidator } from 'app/app-services/parameter-value-validator.service';
import { EcuIdentifierCategoryItem } from 'app/data-categories/identification-property/ecu-identification-category-item';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { Observable, Observer, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DiagnosticProtocolType } from '../../../app-model/enums';
import { IdentificationExpressionService } from '../../../app-services/identification-expression.service';
import { SpecificationService } from '../../../app-services/specification-service';

@Component({
  selector: 'app-value-comparison',
  templateUrl: './value-comparison.component.html',
  styleUrls: ['./value-comparison.component.css']
})
export class ValueComparisonComponent implements OnInit, OnDestroy {

  @Input()
  expressionModel: ServeridentificationExpressionModel;

  @Input()
  isReadOnly: boolean;

  @Input()
  isAllowed: boolean;

  @Input()
  reorderEnabled = true;

  @Input()
  copyEnabled = true;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  OdxDataType = OdxDataType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ServerIdentificationCompareOperatorType = ServerIdentificationCompareOperatorType;
  valueValidator = new ParameterValueValidator();
  ecuIdentifierSyncSubscription: Subscription;
  selectedSpecificationSubscription: Subscription;
  specificationUpdatedSubscription: Subscription;

  isValidValue = false;
  identifierSelectorEnabled = false;
  hexLength: number;

  private _assignedEcuIdentifier: EcuIdentifierCategoryItem;
  private _legacyModeEnabled: boolean;

  constructor(private ecuIdentifierService: EcuIdentifierService,
    private identificationExpressionService: IdentificationExpressionService,
    private navigationService: NavigationService,
    private specificationService: SpecificationService,
    private router: Router,
    private diagnosticServiceProvider: DiagnosticServiceProvider,
    private dataCategoryService: DataCategoriesService,
    private messageService: MessageService) { }

  public get assignedEcuIdentifier(): EcuIdentifierCategoryItem {
    return this._assignedEcuIdentifier;
  }
  public set assignedEcuIdentifier(v: EcuIdentifierCategoryItem) {
    this._assignedEcuIdentifier = v;
  }

  public get withSubstringStartIndexChecked(): boolean {
    return this.hasSubstringStartIndexEnabled;
  }
  public set withSubstringStartIndexChecked(checked: boolean) {
    this.setWithSubstringStartIndex(checked);
  }

  public get withSubstringLengthChecked(): boolean {
    return this.hasSubstringLengthEnabled;
  }
  public set withSubstringLengthChecked(checked: boolean) {
    this.setWithSubstringLength(checked);
  }

  public get compareValue(): string {
    return this.expressionModel.compareValue ? this.expressionModel.compareValue.data : '';
  }
  public set compareValue(v: string) {
    if (this.expressionModel.compareValue) {
      this.expressionModel.compareValue.data = v;
    }
  }

  public get searchLengthValue(): number {
    return this.expressionModel.searchLength ? this.expressionModel.searchLength : 0;
  }
  public set searchLengthValue(v: number) {
    this.expressionModel.searchLength = v;
  }

  public get legacyModeEnabled(): boolean {
    return this._legacyModeEnabled;
  }
  @Input()
  public set legacyModeEnabled(v: boolean) {
    this._legacyModeEnabled = v;
  }

  public get hasSubstringStartIndexEnabled(): boolean {
    return this.expressionModel &&
      this.substringComparisonEnabled &&
      this.expressionModel.subStringStart !== undefined &&
      this.expressionModel.subStringStart !== null;
  }

  public get hasSubstringLengthEnabled(): boolean {
    return this.expressionModel &&
      this.substringComparisonEnabled &&
      this.expressionModel.subStringLength !== undefined &&
      this.expressionModel.subStringLength !== null;
  }

  public get substringComparisonEnabled() {
    if (!this.specificationService.currentSpecification || this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
      return this.hasCompatibleComparisonForSubstring;
    } else {
      return this.hasCompatibleComparisonForSubstring &&
        this.assignedParameterHasStringValue;
    }
  }

  public get hasCompatibleComparisonForSubstring() {
    return this.expressionModel.compareOperator === ServerIdentificationCompareOperatorType.EqualTo ||
      this.expressionModel.compareOperator === ServerIdentificationCompareOperatorType.GreaterThan ||
      this.expressionModel.compareOperator === ServerIdentificationCompareOperatorType.GreaterThanOrEqualTo ||
      this.expressionModel.compareOperator === ServerIdentificationCompareOperatorType.LessThan ||
      this.expressionModel.compareOperator === ServerIdentificationCompareOperatorType.LessThanOrEqualTo;
  }

  get hasSubsstringStartDefined(): boolean {
    return this.expressionModel.subStringStart !== null || this.expressionModel.subStringStart !== undefined;
  }

  get hasSubstringLengthDefined(): boolean {
    return this.expressionModel.subStringLength !== null || this.expressionModel.subStringLength !== undefined;
  }

  public get assignedParameterHasStringValue() {
    return OdxDataType[this.OdxDataType.AsciiString] === this.assignedParameterValueTypeName;
  }

  public get assignedParameterValueTypeName() {
    if (!this.assignedEcuIdentifier) {
      return '';
    }

    const assignedParameter = this.assignedEcuIdentifier.getParameterAssignedToProperty(this.expressionModel.ecuIdentifierPropertyToCompareWith.propertyName);
    if (assignedParameter) {
      return this.getPropertyTypeAsString(assignedParameter.parameter.getEnumDataType());
    } else {
      return this.getPropertyTypeAsString(OdxDataType.AsciiString);
    }
  }

  public get kwpComparisonValueType(): string {
    return this.hasComparisonOfIntervalType ? OdxDataType[OdxDataType.AsciiString] + ' as version' : OdxDataType[OdxDataType.AsciiString];
  }

  public get hasComparisonOfIntervalType(): boolean {
    return this.expressionModel.compareOperator === ServerIdentificationCompareOperatorType.GreaterThan ||
      this.expressionModel.compareOperator === ServerIdentificationCompareOperatorType.GreaterThanOrEqualTo ||
      this.expressionModel.compareOperator === ServerIdentificationCompareOperatorType.LessThan ||
      this.expressionModel.compareOperator === ServerIdentificationCompareOperatorType.LessThanOrEqualTo;
  }

  getPropertyTypeAsString(propertyType: OdxDataType): string {
    if (this.hasComparisonOfIntervalType && propertyType === OdxDataType.AsciiString) {
      return OdxDataType[propertyType] + ' as version';
    } else {
      return OdxDataType[propertyType];
    }
  }

  get hexValSample(): string {
    if (this.hexLength === 1) {
      return 'e.g. 0x33';
    } else {
      return 'e.g. 0x0033';
    }
  }

  get hasAssignedEcuIdentifier(): boolean {
    return this.assignedEcuIdentifier !== undefined;
  }

  ngOnInit() {
    if (this.specificationService.currentSpecification) {
      if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
        this.hexLength = 1;
      } else if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Uds) {
        this.hexLength = 2;
      } else {
        this.hexLength = 1;
      }

      const id = this.specificationService.currentSpecificationVersion != null
        ? this.specificationService.currentSpecificationVersion.id
        : this.expressionModel.id;

      this.diagnosticServiceProvider.getItemsPreview(id).pipe(tap(() => {
        this.getAssignedEcuIdentifierIfAvailable();
        this.setEcuIdentifierHexLength();
      })).subscribe();
    } else {
      this.hexLength = 1;
    }
  }

  ngOnDestroy(): void {
    if (this.ecuIdentifierSyncSubscription) {
      this.ecuIdentifierSyncSubscription.unsubscribe();
    }

    if (this.selectedSpecificationSubscription) {
      this.selectedSpecificationSubscription.unsubscribe();
    }
  }

  setEcuIdentifierHexLength() {
    this.selectedSpecificationSubscription = this.specificationService.selectedSpecificationEventEmitter.subscribe({
      next: (specificationData) => {
        if (specificationData.specification && this.specificationService.isLegacyVersion) {
          if (this.router.url.includes('identification-groups')) {
            this.hexLength = 3;
          } else if (specificationData.specification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
            this.hexLength = 1;
          } else if (specificationData.specification.diagnosticProtocol === DiagnosticProtocolType.Uds) {
            this.hexLength = 2;
          } else {
            this.hexLength = 3;
          }
        }
      }
    });
  }

  resetSubstringLength(notifyAsChanged = true) {
    this.expressionModel.subStringLength = null;

    if (notifyAsChanged) {
      this.notifyServerIdentificationChanged();
    }
  }

  setSubstringLengthDefaults() {
    if (this.hasSubstringLengthDefined) {
      this.expressionModel.subStringLength = 0;
    }
  }

  resetSubstringStartIndex(notifyAsChanged = true) {
    this.expressionModel.subStringStart = null;

    if (notifyAsChanged) {
      this.notifyServerIdentificationChanged();
    }
  }

  setSubstringStartIndexDefaults() {
    if (this.hasSubsstringStartDefined) {
      this.expressionModel.subStringStart = 0;
    }
  }

  onEcuIdentifierSelected(value) {
    this.resetSubstringLength(false);
    this.resetSubstringStartIndex(false);

    /**
     * Every time the ecu identifier is changed, the value type must be set according to to underlaying parameter type in the identifier item.
     */
    const ecuIdentifier = this.expressionModel.ecuIdentifierPropertyToCompareWith.ecuIdentifier;
    const specificationVersionId = this.specificationService.currentSpecificationVersion.id;
    // TODO: change id with name instead
    this.ecuIdentifierService.getItem(specificationVersionId, ecuIdentifier).subscribe({
      next: (item) => {
        const identificationParam = item.getParameterAssignedToProperty(this.expressionModel.ecuIdentifierPropertyToCompareWith.propertyName);

        if (identificationParam) {
          /**
           * The type of the value to compare is set here.
           */
          this.expressionModel.compareValue.dataType = identificationParam.parameter.getEnumDataType();
        } else {
          /**
           * If no ecu identifier property is selected, the default data type of the campare value is AsciiString
           */
          this.expressionModel.compareValue.dataType = OdxDataType.AsciiString;
        }

        this.getAssignedEcuIdentifierIfAvailable();
        this.notifyServerIdentificationChanged();
      }
    });
  }

  getAssignedEcuIdentifierIfAvailable() {
    console.log(this.hasServersCategoryActive(), this.specificationService.currentSpecification.diagnosticProtocol);
    if (this.hasServersCategoryActive() && this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Uds) {
      this.getAssignedEcuIdentifier().subscribe({
        next: (item) => {
          console.log(item);
          this.assignedEcuIdentifier = item;
          this.assignedEcuIdentifier.syncDone.subscribe();
          if (this.hasEcuIdentifierPropertyToCompareWith()) {
            this.validateParameterValue().subscribe();
          }
        }
      });
    }
    console.log(this.assignedEcuIdentifier);
  }

  onIdentifierHexCodeChanged(hexCode: number) {
    if (hexCode >= 1000000) {
      hexCode -= 1000000;
    }
    this.notifyServerIdentificationChangedWithHexCode(hexCode);
  }

  hasEcuIdentifierPropertyToCompareWith(): boolean {
    return this.expressionModel.ecuIdentifierPropertyToCompareWith != null &&
           this.expressionModel.ecuIdentifierPropertyToCompareWith != undefined &&
           this.expressionModel.ecuIdentifierPropertyToCompareWith.ecuIdentifier != null &&
           this.expressionModel.ecuIdentifierPropertyToCompareWith.ecuIdentifier != undefined;
  }

  getAssignedEcuIdentifier(): Observable<EcuIdentifierCategoryItem> {
    let ecuIdentifier;
    return new Observable((observer: Observer<EcuIdentifierCategoryItem>) => {
    if(  this.expressionModel.ecuIdentifierPropertyToCompareWith.ecuIdentifier!=null){
       ecuIdentifier = this.expressionModel.ecuIdentifierPropertyToCompareWith?.ecuIdentifier ?? undefined;
    }
      const specificationVersionId = this.specificationService.currentSpecificationVersion.id;

      if (this.expressionModel.ecuIdentifierPropertyToCompareWith != null && this.expressionModel.ecuIdentifierPropertyToCompareWith) {
        // TODO: change id with name instead
        this.ecuIdentifierService.getItem(specificationVersionId, ecuIdentifier).subscribe({
          next: (message) => {
            // When the ECU identifier has been retrieved, get diagnostic services connected to it.
            let completedExecutions = 0;
            message.model.readSequence.executions.forEach(execution => {
              this.diagnosticServiceProvider.getDiagnosticServiceByName(specificationVersionId, execution.service).subscribe({
                next: (item) => {
                  completedExecutions++;
                  if (completedExecutions <= message.model.readSequence.executions.length) {
                    observer.next(message);
                    observer.complete();
                  }
                }
              });
            });
          }
        });
      }
    });
  }

  onValueChanged() {
    this.validateParameterValue().pipe(tap(isValid => {
      if (isValid) {
        this.notifyServerIdentificationChanged();
      } else {
        this.messageService.dispatchErrorMessage('You have entered a value that is not compatible with data type ' + this.assignedParameterValueTypeName);
      }
    })).subscribe();
  }

  onSearchLengthValueChanged() {
    this.validateParameterValue().pipe(tap(isValid => {
      if (isValid) {
        this.notifyServerIdentificationChanged();
      } else {
        this.messageService.dispatchErrorMessage('You have entered a value that is not compatible with data type ' + this.assignedParameterValueTypeName);
      }
    })).subscribe();
  }
  validateParameterValue(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {

      if (!this.expressionModel.ecuIdentifierPropertyToCompareWith) {
        this.isValidValue = this.valueValidator.hasValidParameterStringValue(this.expressionModel.compareValue.data);
        observer.next(this.isValidValue);
        observer.complete();
      } else {
        const ecuIdentifier = this.expressionModel.ecuIdentifierPropertyToCompareWith.ecuIdentifier;

        const specificationVersionId = this.specificationService.currentSpecificationVersion.id;
        // TODO: change id with name instead
        this.ecuIdentifierService.getItem(specificationVersionId, ecuIdentifier).subscribe({
          next: (item) => {
            this.ecuIdentifierSyncSubscription = item.syncDone.subscribe({
              next: (param) => {
                const identificationParam = item.getParameterAssignedToProperty(this.expressionModel.ecuIdentifierPropertyToCompareWith.propertyName);

                /**
                 * If the expression item has an identifier property selected, the validator will use the parameter to validate the
                 * comparison value.
                 * If no property is available, the contents will be validated as an AsciiString, which means that any value will be accepted.
                 */
                if (identificationParam) {
                  this.isValidValue = this.valueValidator.hasValidParameterValue(identificationParam.parameter, this.expressionModel.compareValue);
                  observer.next(this.isValidValue);
                  observer.complete();
                } else {
                  this.isValidValue = this.valueValidator.hasValidParameterStringValue(this.expressionModel.compareValue.data);
                  observer.next(this.isValidValue);
                  observer.complete();
                }
              }
            });
          }
        });
      }
    });
  }

  notifyServerIdentificationChanged() {
    this.identificationExpressionService.modified.next(this.expressionModel);
  }

  notifyServerIdentificationChangedWithHexCode(hexCode: number) {
    this.expressionModel.legacyIdentifierCode = hexCode;
    this.identificationExpressionService.modified.next(this.expressionModel);
  }

  nagivateToEcuIdentifier(ecuIdentifierName: string): void {
    this.navigationService.navigateToEcuIdentifier(ecuIdentifierName);
  }

  setWithSubstringStartIndex(checked: boolean, notifyChanges = true) {
    if (checked) {
      this.setSubstringStartIndexDefaults();

      if (notifyChanges) {
        this.notifyServerIdentificationChanged();
      }
    }

    if (!checked && this.hasSubsstringStartDefined) {
      // Should not be able to have the length checked if the starts with is disabled
      if (this.withSubstringLengthChecked) {
        this.setWithSubstringLength(false, false);
      }

      this.resetSubstringStartIndex(notifyChanges);
    }
  }

  setWithSubstringLength(checked: boolean, notifyChanges = true) {
    if (checked) {
      this.setSubstringLengthDefaults();

      // Should not be able to only have the length checkbox enabled. StartWith is mandatory if the length is checked
      if (!this.withSubstringStartIndexChecked) {
        this.setWithSubstringStartIndex(true, false);
      }

      if (notifyChanges) {
        this.notifyServerIdentificationChanged();
      }
    }

    if (!checked && this.hasSubstringLengthDefined) {
      this.resetSubstringLength(notifyChanges);
    }
  }

  showIdentifierSelector() {
    this.identifierSelectorEnabled = !this.identifierSelectorEnabled;
  }

  private hasServersCategoryActive(): boolean {
    return this.dataCategoryService.activeCategory &&
      this.dataCategoryService.activeCategory.category === Category.Servers;
  }
}
