import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ModificationInfoService } from 'app/app-services/modification-info.service';
import { ModificationType, ModifiedCategory } from 'app/modules/shared/model/specification-version-modification';

import { ModificationInfoTableDataSource } from './modification-info-table-datasource';


@Component({
  selector: 'app-modification-info-table',
  templateUrl: './modification-info-table.component.html',
  styleUrls: ['./modification-info-table.component.css'],
  providers: [ModificationInfoService]
})
export class ModificationInfoTableComponent implements OnInit {
  @ViewChild(MatPaginator) set paginator(content: MatPaginator) {
    if (content) {
      this.dataSource.paginator = content;
    }
  }
  @ViewChild(MatSort) set sort(content: MatSort) {
    if (content) {
      this.dataSource.sort = content;
    }
  }
  dataSource = new MatTableDataSource<any>([]);
  specificationVersionId: number;
  modificationType = ModificationType;
  modifiedCategory = ModifiedCategory;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['userThatModified', 'modificationDate', 'modificationType', 'modifiedItemName', 'modifiedCategory'];
  private modificationInfoTableDataSource: ModificationInfoTableDataSource;

  constructor(private modificationService: ModificationInfoService, private route: ActivatedRoute) {
    this.specificationVersionId = +this.route.snapshot.paramMap.get('specificationVersionId');
  }

  ngOnInit() {
    this.initDataSource();
  }

  initDataSource() {
    this.modificationInfoTableDataSource = new ModificationInfoTableDataSource();
    this.getModificationsFromService(this.specificationVersionId).subscribe(mods => {
      this.dataSource = new MatTableDataSource(mods);
      this.modificationInfoTableDataSource.setModificationList(mods);
      this.dataSource.filterPredicate = (data, filter: string) =>
        (data.userThatModified != null && data.userThatModified.toLowerCase().includes(filter)) ||
        (data.modifiedCategory != null && ModifiedCategory[data.modifiedCategory].toLowerCase().includes(filter)) ||
        ModificationType[data.modificationType].toLowerCase().includes(filter) ||
        data.modificationDate.substring(0, 10).includes(filter) ||
        data.modifiedItemId.toString().includes(filter);
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLocaleLowerCase().trim();
  }

  private getModificationsFromService(versionId: number) {
    return this.modificationService.getAllModification(this.specificationVersionId);
  }
}
