<div>
  <h4>
    Valid for following ECU software generations
  </h4>
  <div *ngIf="!familyGenerationPairsExists" class="sdds-banner my-10">
    <div class="sdd-banner-body">
      No family/generation pairs have been added.
    </div>
  </div>
  <div style="float: left;">
    <app-common-list
      [isReadOnly]="true"
      [isSelectable]="false"
      [bubbleMode]="true"
      [list]="familyGenerationPairs"
      [itemTemplate]="familyGenerationPairTemplate"
      [isAllowed]="isAllowed"
    ></app-common-list>
    <ng-template #familyGenerationPairTemplate let-item>
      <span class="sdds-headline-06 ">{{ item.diagnosticFamily.name }} {{ item.generation.name }}</span><span *ngIf="isAllowed"class="ml-10" (click)="remove(item)"><i class="sdds-icon scania-cross"></i></span>
    </ng-template>
  </div>
  <span>
    <button
      class="sdds-btn sdds-btn-primary sdds-btn-sm ml-5"
      (click)="showFamilyGenerationSelector()" [disabled]="!isAllowed"
    >
      <span>
        <i class="sdds-icon scania-plus" style="font-size: 15px;"></i>
      </span>
    </button>
  </span>
</div>
<div style="clear: both;"></div>

<div *ngIf="showFamilyGenerationSelectorEnabled" style="margin-top: 10px;">
  <p class="sdds-headline-07">Select the family and generation you would like to add</p>
  <div class="sdds-row -ml-16">
    <div class="sdds-col-max sdds-col-xxlg sdds-col-xlg sdds-col-lg sdds-col-md sdds-col-sm sdds-col-xs">
      <div class="sdds-dropdown sdds-dropdown-small">
        <select
        (ngModelChange)="familyChanged($event)"
        [(ngModel)]="selectedFamily">
        <ng-container *ngIf="families">
          <option *ngFor="let family of families" [ngValue]="family">{{
            family.name
          }}</option>
        </ng-container>
        </select>
      </div>
    </div>
    <div class="sdds-col-max sdds-col-xxlg sdds-col-xlg sdds-col-lg sdds-col-md sdds-col-sm sdds-col-xs">
      <div class="sdds-dropdown sdds-dropdown-small">
        <select
        (ngModelChange)="generationChanged($event)"
        [(ngModel)]="selectedGeneration">
        <ng-container *ngIf="generations">
          <option
            *ngFor="let generation of generations"
            [ngValue]="generation"
            >{{ generation.name }}</option
          >
        </ng-container>
      </select>
      </div>
    </div>
    <div class="sdds-col-max sdds-col-xxlg sdds-col-xlg sdds-col-lg sdds-col-md sdds-col-sm sdds-col-xs">
      <button class="sdds-btn sdds-btn-primary sdds-btn-sm mt-5" [ngClass]="{'disabled':!canAdd}" (click)="add()" [disabled]="!isAllowed">
        Add
      </button>
    </div>
    <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3"></div>
  </div>
</div>
