<div class="sdds-row">
  <div class="sdds-col-max-12">
    <app-toolbar #filterToolbar [filterEnabled]="true" (filterTextEventEmitter)="filter($event);"></app-toolbar>
  </div>

  <div class="sdds-col-max-12">
    <div class="sdds-dropdown sdds-dropdown-small" [ngClass]="{'loading': loading}">
      <span class="sdds-dropdown-label-outside">Available versions</span>
      <select id="allSpecifications" #select [multiple]="multiSelect" size="5" (change)="updateSelectedVersions()">
        <optgroup *ngFor="let p of specifications" [label]="p.name">
          <option #opt *ngFor="let pv of p.specificationVersions" [value]="p.id + '|' +pv.name">Version {{pv.name}}
          </option>
        </optgroup>
      </select>
      <span class="sdds-dropdown-helper" *ngIf="multiSelect">Hold down Ctrl key to multiselect</span>
    </div>
  </div>

  <div class="sdds-col-max-12"
    *ngIf="multiSelect && selectedSpecificationVersions && selectedSpecificationVersions.length > 0">
    <p class="sdds-detail-06">Specification versions to add:</p>
    <ul>
      <li *ngFor="let selectedSpecificationVersion of selectedSpecificationVersions">
        {{selectedSpecificationVersion.specification.name + ', version ' + selectedSpecificationVersion.name}}</li>
    </ul>
  </div>
</div>
