<div class="sdds-row">
  <div class="sdds-col-max-12">
    <app-toolbar #filterToolbar [filterEnabled]="true" (filterTextEventEmitter)="filter($event);"></app-toolbar>
  </div>

  <div class="sdds-col-max-12">
    <div class="sdds-dropdown sdds-dropdown-small" [ngClass]="{'loading': loading}">
      <span class="sdds-dropdown-label-outside">Available specifications</span>
      <select id="allSpecifications" #select [multiple]="multiSelect" (change)="updateSelectedSpecifications()">
        <option #opt *ngFor="let spec of specifications" [label]="spec.name">{{spec.name}}</option>
      </select>
      <span class="sdds-dropdown-helper">Hold down Ctrl key to multiselect</span>
    </div>
  </div>
</div>
