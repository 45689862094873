import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SpecificationService } from 'app/app-services/specification-service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MessageService } from '../../services/message-service.service';
import { DialogBaseComponent } from '../dialog-base/dialog-base.component';
import { SecurityAccessCategoryItemDirective } from 'app/data-categories/security-access/security-access-category-item';
import { SecurityAccessService } from 'app/app-services/security-access.service';

@Component({
  selector: 'app-security-access-selector',
  templateUrl: './security-access-selector.component.html',
  styleUrls: ['./security-access-selector.component.css']
})
export class SecurityAccessSelectorComponent implements OnInit {

  @ViewChild('dialogBase', { static: true }) dialogBase: DialogBaseComponent;
  @ViewChild('select', { static: false }) selectElement: ElementRef;

  @Output()
  securityAccessItemsAdded: EventEmitter<string[]> = new EventEmitter();


  public get modalName(): string {
    return this._modalName;
  }
  @Input()
  public set modalName(v: string) {
    this._modalName = v;
  }

  securityAccessItems: string[];
  isLoading: boolean;
  filterText: string;
  specificationVersionId: number;
  securityAccessChanged: Subscription;

  private _currentSecurityAccessItems: string[];
  private _modalName: string;
  private _okButtonName = 'Add';

  @Input()
  public set currentSecurityAccessItems(v: string[]) {
    this._currentSecurityAccessItems = v;
  }

  public get currentSecurityAccessItems(): string[] {
    return this._currentSecurityAccessItems;
  }

  public get okButtonName(): string {
    return this._okButtonName;
  }

  @Input()
  public set okButtonName(name: string) {
    this._okButtonName = name;
  }

  constructor(
    private securityAccessService: SecurityAccessService,
    private specificationService: SpecificationService,
    private messageService: MessageService) {
    this.isLoading = false;
  }

  ngOnInit() {
    this.isLoading = true;

    this.specificationService.selectedSpecificationEventEmitter.pipe(tap(specification => {
      if (specification && specification.specificationVersion) {
        this.specificationVersionId = specification.specificationVersion.id;
        this.getAvailableSecurityAccessItems();
      }
    })).subscribe();

    this.securityAccessChanged = this.securityAccessService.securityAccessItemsChanged.pipe(tap(() => {
      this.getAvailableSecurityAccessItems();
    })).subscribe();

  }

  getAvailableSecurityAccessItems() {
    if (this.specificationVersionId) {
      this.securityAccessService.getItemsPreview(this.specificationVersionId).pipe(tap((securityAccessItems) => {
        this.securityAccessItems = securityAccessItems.map(securityAccessItem => securityAccessItem.name);
        this.isLoading = false;
      }, err => {
        this.messageService.dispatchErrorMessageFromApi(err);
      })).subscribe();
    }
  }

  onAddSecurityAccess() {
    const selectedOptions = this.selectElement.nativeElement.options;
    const selectedSecurityAccessItems = new Array<string>();

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < selectedOptions.length; i++) {
      if (!selectedOptions[i].selected) {
        continue;
      }

      const securityAccessToAssign = this.securityAccessItems.find(item => item === selectedOptions[i].value);
      if (securityAccessToAssign) {
        selectedSecurityAccessItems.push(securityAccessToAssign);
      }
    }

    this.securityAccessItemsAdded.emit(selectedSecurityAccessItems);
  }

  filter(filterText: string) {
    this.filterText = filterText;
  }

  isSecurityAccessAlreadyAssigned(securityAccessItem: string): boolean {
    if (!this.currentSecurityAccessItems || this.currentSecurityAccessItems.length <= 0) {
      return false;
    }

    if (this.currentSecurityAccessItems.some(c =>  (c === securityAccessItem))) {
      return true;
    }

    return false;
  }
}
