import { Injectable } from '@angular/core';
import { OperationalDataVariableType } from 'app/app-model/operational-data/operational-data-enums';
import { OperationalDataModel } from 'app/app-model/operational-data/operational-data.model';
import { BaseProperty } from 'app/modules/shared/model/properties/base-property';
import { OutputProperty } from 'app/modules/shared/model/properties/output-property';

@Injectable({
  providedIn: 'root'
})
export class PropertyGenerator {

  private readonly defaultPropertyName: string = 'Default';
  private readonly accSlidingBarChartExtraPropName = 'CurrentPeriodProgress';

  /**Generates properties for one operational data item based on its current definition.
   * Existing properties will be merged/shrinked or wiped depending on the overwriteExisting flag.
   */
  generatePropertiesFromDefinition(currentProperties: BaseProperty[], model: OperationalDataModel, overwriteExisting: boolean) {
    if (overwriteExisting) {
      this.removeAndUnlinkProperties(currentProperties, 0, currentProperties.length);
    }

    switch (model.type) {
      case OperationalDataVariableType.AccumulatedScalar:
      case OperationalDataVariableType.MomentaryScalar:
        this.generatePropertiesForScalar(currentProperties);
        break;
      case OperationalDataVariableType.AccumulatedHistogram:
      case OperationalDataVariableType.MomentaryBarChart:
        this.appendPropertiesForColumnsIdNeeded(currentProperties, model.cols, overwriteExisting, false);
        break;
      case OperationalDataVariableType.AccumulatedSlidingBarChart:
        this.appendAdditionalProperty(currentProperties, this.accSlidingBarChartExtraPropName);
        this.appendPropertiesForColumnsIdNeeded(currentProperties, model.cols, overwriteExisting, true);
        break;
      case OperationalDataVariableType.MinMaxValueCollection:
        this.appendPropertiesForColumnsIdNeeded(currentProperties, model.cols, overwriteExisting, false);
        break;
      case OperationalDataVariableType.AccumulatedHistogramWithDefault:
        this.appendAdditionalProperty(currentProperties, this.defaultPropertyName);
        this.appendPropertiesForColumnsIdNeeded(currentProperties, model.cols, overwriteExisting, true);
        break;
      case OperationalDataVariableType.AccumulatedMatrix:
      case OperationalDataVariableType.MomentaryMatrix:
        this.appendPropertiesForMatrix(currentProperties, model.cols, model.rows, overwriteExisting, false);
        break;
      case OperationalDataVariableType.AccumulatedMatrixWithDefault:
        this.appendAdditionalProperty(currentProperties, this.defaultPropertyName);
        this.appendPropertiesForMatrix(currentProperties, model.cols, model.rows, overwriteExisting, true);
        break;
    }
  }

  private appendAdditionalProperty(currentProperties: BaseProperty[], additionalPropertyName: string) {
    const additionalProperty = this.findPropertyWithName(currentProperties, additionalPropertyName);

    if (additionalProperty) {
      return;
    } else {
      const property = new OutputProperty();
      property.name = additionalPropertyName;
      currentProperties.push(property);
    }
  }

  private generatePropertiesForScalar(currentProperties: BaseProperty[]) {
    const propertyName = 'AccValue';

    /** Scalars cannot have more than one property */
    if (currentProperties.length === 1 && currentProperties[0].name === propertyName) {
      return;
    }

    const property = new OutputProperty();
    property.name = propertyName;

    currentProperties.push(property);
  }

  private appendPropertiesForColumnsIdNeeded(currentProperties: BaseProperty[], numberOfColumns: number, overwriteExisting: boolean, withDefault: boolean) {
    const noOfPropertiesToCreate = withDefault ? numberOfColumns + 1 : numberOfColumns;

    // No new properties if number of columns equals number of current properties,
    if (currentProperties.length === noOfPropertiesToCreate) {
      return;
    }

    if (noOfPropertiesToCreate < currentProperties.length) {
      const numberOfPropertiesToRemove = currentProperties.length - noOfPropertiesToCreate;
      const removingStartIndex = currentProperties.length - numberOfPropertiesToRemove;
      this.removeAndUnlinkProperties(currentProperties, removingStartIndex, numberOfPropertiesToRemove);
      return;
    }

    const numberOfPropertiesToCreate = noOfPropertiesToCreate - currentProperties.length;
    const creationStartIndex = (noOfPropertiesToCreate - numberOfPropertiesToCreate) - (withDefault ? 1 : 0);

    for (let i = creationStartIndex; i < creationStartIndex + numberOfPropertiesToCreate; i++) {
      const property = new OutputProperty();
      property.name = `AccValue-${i}`;
      currentProperties.push(property);
    }
  }

  private appendPropertiesForMatrix(currentProperties: BaseProperty[],
    numberOfColumns: number,
    numberOfRows: number,
    overwriteExisting: boolean,
    withDefault: boolean) {

    const numberOfTargetProperties = (numberOfColumns * numberOfRows) + (withDefault ? 1 : 0);

    // No new properties if number of columns * rows equals number of current properties,
    if (currentProperties.length === numberOfTargetProperties) {
      return;
    }

    if (numberOfTargetProperties < currentProperties.length) {
      const numberOfPropertiesToRemove = currentProperties.length - numberOfTargetProperties;
      const removingStartIndex = currentProperties.length - numberOfPropertiesToRemove;
      this.removeAndUnlinkProperties(currentProperties, removingStartIndex, numberOfPropertiesToRemove);
      return;
    }

    for (let i = 0; i < numberOfRows; i++) {
      for (let j = 0; j < numberOfColumns; j++) {
        const propertyName = `AccValue-${j}-${i}`;
        const foundPropertyName = this.findPropertyWithName(currentProperties, propertyName);
        if (!foundPropertyName) {
          const property = new OutputProperty();
          property.name = propertyName;
          currentProperties.push(property);
        }
      }
    }
  }

  private removeAndUnlinkProperties(currentProperties: BaseProperty[], startIndex: number, count: number) {
    const removedProperties = currentProperties.splice(startIndex, count);
    removedProperties.forEach(property => {
      if (property.name !== this.defaultPropertyName) {
        property.unlink();
      }
    });
  }

  private findPropertyWithName(currentProperties: BaseProperty[], propName: string): BaseProperty {
    return currentProperties.find(property => property.name === propName);
  }
}
