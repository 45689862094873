import { Directive, ElementRef, Input, EventEmitter } from '@angular/core';

@Directive({ selector: '[appOpenModal]' })
export class OpenModalDirective {

    private _el: ElementRef;

    constructor(el: ElementRef) {
        this._el = el;
    }

    @Input() set open(openModalEvent: EventEmitter<void>) {
        openModalEvent.subscribe(() => {
            this._el.nativeElement.click();
        });
    }
}
