import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  get allowedOdxFileExtensions(): string[] {
    return ['odx', 'odx-d'];
    //return ['odx', 'odx-d', 'pdx'];
  }

  get allowedRbacFileExtensions(): string[] {
    return ['xml'];
    //return ['odx', 'odx-d', 'pdx'];
  }

  public isCompatibleOdxFile(fileName: string): boolean {
    const currentOdxFileExtension = fileName.split('.').pop();

    if (!this.allowedOdxFileExtensions.find(allowedExtension => allowedExtension === currentOdxFileExtension)) {
      return false;
    }

    return true;
  }

  public isCompatibleRbacFile(fileName: string): boolean {
    const currentRbacFileExtension = fileName.split('.').pop();

    if (!this.allowedRbacFileExtensions.find(allowedExtension => allowedExtension === currentRbacFileExtension)) {
      return false;
    }

    return true;
  }

  public getOdxFileContentsFromPdxAsBase64(pdxFile: File): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const zipFileName = pdxFile.name.substr(0, pdxFile.name.lastIndexOf('.')) + '.zip';

      pdxFile.arrayBuffer().then(dataFromPDX => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const blobParts = [new Blob([dataFromPDX])];
        const zipFile = new File(blobParts, zipFileName, { type: 'application/x-zip-compressed' });
        const jsZip = require('jszip');
        // const odxFile = new OdxFile();
        jsZip.loadAsync(zipFile).then(zip => {
          Object.keys(zip.files).forEach((filename) => {
            const fileExtension = filename.split('.').pop();
            if (fileExtension === 'odx-d') {
              zip.files[filename].async('string').then((fileData) => {
                this.getOdxFileContentsAsBase64(new Blob([fileData])).subscribe(
                  data => {
                    observer.next(data);
                    observer.complete();
                  }
                );
              });
            }
          });
        });
      });
    });
  }

  public getOdxFileContentsAsBase64(file: Blob): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onloadend = () => {
        observer.next(btoa(fileReader.result.toString()));
        observer.complete();
      };
    });
  }

  public getRbacFileContentsAsBase64(file: Blob): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onloadend = () => {
        observer.next(btoa(fileReader.result.toString()));
        observer.complete();
      };
    });
  }
}
