import { DiagnosticProtocolType, ServerExecutionModeType } from 'app/app-model/enums';
import { SpecificationVersion } from './specification-version';
import { User } from 'app/modules/shared/model/user';

export class Specification {
  id: number;
  code: string;
  translationId: number;
  name: string;
  diagnosticProtocol: DiagnosticProtocolType;
  serverExecutionMode: ServerExecutionModeType;
  specificationVersions: SpecificationVersion[];
  includeInScomm: boolean;
  isTemplate: boolean;
  owner: User;
}
