import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogBaseComponent } from 'app/modules/shared/components/dialog-base/dialog-base.component';

@Component({
  selector: 'app-create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.css']
})

export class CreateItemComponent {

  @Output()
  applyChangesEventEmitter: EventEmitter<string> = new EventEmitter();

  @ViewChild('dialogBase', { static: true }) dialogBase: DialogBaseComponent;
  modalClosing: EventEmitter<void> = new EventEmitter<void>();
  str: boolean;

  private _itemName: string;
  get itemName(): string {
    return this._itemName;
  }
  set itemName(value: string) {
    this.str = this.hasWhiteSpace(value);
    this._itemName = value;
  }

  apply() {
    if (!this.str) {
      this.applyChangesEventEmitter.emit(this.itemName);
    }
    this.itemName = '';
    this.close();
  }

  hasWhiteSpace(s) {
    return s.indexOf(' ') >= 0;
  }

  cancel(): void {
    this.itemName = '';
    this.close();
  }

  close() {
    this.modalClosing.emit();
  }

  get canApply() {
    return this.itemName && this.itemName !== null;
  }
}
