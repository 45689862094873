import { SpecificationVersion } from 'app/app-model/specification-version';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';

import { TreeService } from '../service/tree.service';
import { TreeNode } from './tree-node';
import { SpecificationService } from 'app/app-services/specification-service';

export class DiagnosticServiceTreeNode extends TreeNode {
  diagnosticServiceCategoryItem: DiagnosticServiceCategoryItem;
  copyAction: (p: DiagnosticServiceCategoryItem) => void;

  private specificationVersion: SpecificationVersion;
  private canCopy: () => boolean;

  constructor(
    diagnosticServiceModel: DiagnosticServiceCategoryItem,
    specificationVersion: SpecificationVersion,
    treeService: TreeService,
    private specificationService: SpecificationService,
    canCopy: () => boolean,
    copyAction: (p: DiagnosticServiceCategoryItem) => void) {
    super(diagnosticServiceModel.name, treeService);
    this.specificationVersion = specificationVersion;
    this.canCopy = canCopy;
    this.copyAction = copyAction;
    this.diagnosticServiceCategoryItem = diagnosticServiceModel;
    this.isDiagnosticServiceTreeNode = true;
  }

  get icon(): string {
    return '';
  }

  get isInWorkStatus(): boolean {
    return this.specificationVersion.releaseStatus === 0;
  }

  get isServiceFromDbSource(): boolean {
    return this.specificationService.isLegacyVersion ||
    this.specificationService.isUpgradedVersion ||
    this.specificationService.isNativeVersion;
  }

  performCopyAction(): void {
    if (this.canCopy) {
      this.copyAction(this.diagnosticServiceCategoryItem);
    }
  }
}
