<div class="sdds-row bg-gray-50 pt-16 mb-16">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
        <h2>Specifications</h2>
    </div>

    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12"
        [ngClass]="{'loading':loading}">
        <app-toolbar [addDataTargetEnabled]="false" addDataTarget="#createNewSpecificationModal"
            [collapseEnabled]="true" [dataTargetEnabledRemove]="false" [expandEnabled]="true"
            (collapseEventEmitter)="collapseTree();" (expandEventEmitter)="expandTree();"
            [addButtonText]="'Add specification'" [createSpecification]="true"
            [createButtonId]="'createNewSpecificationModal'" [filterEnabled]="true"
            (filterTextEventEmitter)="filter($event);" [isAllowed]="canCreateDeleteSpecification()"></app-toolbar>

        <div *ngIf="ecuFamilytree && ecuFamilytree.isEmpty">
            <p>The tree is empty. Add a new specification to proceed.</p>
        </div>

        <div class="overflow-y-auto sdds-no-padding h-screen">
            <ng-container *ngIf="ecuFamilytree && !ecuFamilytree.isEmpty">
                <app-tree [tree]="ecuFamilytree"></app-tree>
            </ng-container>
        </div>
    </div>
</div>

<app-create-new-specification (newSpecificationCreated)="onSpecificationCreated()"></app-create-new-specification>