import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LengthKind } from 'app/app-model/diagnostic-service/length.model';
import { ScaniaEncodingType } from 'app/app-model/diagnostic-service/parameter.scaling.model';
import { ParameterService } from 'app/app-services/parameter-service';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { MessageService } from 'app/modules/shared/services/message-service.service';

@Component({
  selector: 'app-internal-value',
  templateUrl: './internal-value.component.html',
  styleUrls: ['./internal-value.component.css']
})
export class InternalValueComponent {

  @ViewChild('internalDatatypeSelect', { static: true }) internalDatatypeSelect: ElementRef;

  @Input()
  parameter: ParameterData;

  @Input()
  isReservedParameter: boolean;

  @Input()
  isValueParameter: boolean;

  @Input()
  isOdxBasedSpecification: boolean;

  @Input()
  isAllowed: boolean;

  @Output()
  parameterChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  internalDataTypeChanged: EventEmitter<void> = new EventEmitter();

  constructor(private parameterService: ParameterService, private messageService: MessageService) { }

  public get internalValueDataType(): string {
    return this.parameter.scalingData.internalDataType;
  }

  public set internalValueDataType(valueType: string) {
    if (this.hasMinMaxLength && !this.parameter.isDataTypeCompatibleWithMinMaxLength(OdxDataType[valueType])) {
      this.messageService.dispatchWarningMessage('You need to disable MinMax length in order to change the internal data type.');
      this.internalDatatypeSelect.nativeElement.value = OdxDataType[this.parameter.model.scaling.internalDataType];
    } else {
      this.parameter.model.scaling.internalDataType = OdxDataType[valueType];

      setTimeout(() => {
        this.internalDataTypeChanged.next();
      }, 500);
    }
  }

  get hasMinMaxLength(): boolean {
    return this.parameter.model.length.kind === LengthKind.MinMax;
  }

  set hasMinMaxLength(enabled: boolean) {
    if (enabled) {
      this.parameter.resetLength();
      this.parameter.model.length.bitLength = 0;
      this.parameter.model.length.kind = LengthKind.MinMax;
    } else {
      this.parameter.resetLength();
      this.parameter.model.length.bitLength = 1;
      this.parameter.model.length.kind = LengthKind.Standard;
    }
  }

  public get internalValueEncoding(): string {
    return this.parameter.scalingData.encoding;
  }

  public set internalValueEncoding(encoding: string) {
    this.parameter.scalingData.model.encoding = ScaniaEncodingType[encoding];
  }

  public get hasInternalValueConstraint(): boolean {
    return this.parameter.scalingData.internalConstraintData !== undefined;
  }

  public set hasInternalValueConstraint(v: boolean) {
    if (v) {
      this.setDefaultInternalvalueConstraint();

    } else {
      this.parameter.scalingData.internalConstraintData = undefined;
    }
  }

  public get internalValueDataTypeEnum(): OdxDataType {
    return this.parameter.scalingData.model.internalDataType;
  }

  setDefaultInternalvalueConstraint() {
    const constraintData = this.parameterService.createNewValueConstraint(this.internalValueDataTypeEnum);
    this.parameter.scalingData.internalConstraintData = constraintData;
  }
}
