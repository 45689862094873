import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ServerIdentificationCategoryItemComponent,
} from 'app/data-categories/server-identification/server-identification-category-item';
import { AuthService } from 'app/modules/authentication/services/auth.service';

import { IdentificationGroup } from '../../app-model/identification-group/identification-group';
import { ServerIdentificationModel } from '../../app-model/server-identification/server-identification.model';
import { IdentificationGroupService } from '../../app-services/identification-group.service';
import { MessageService } from '../../modules/shared/services/message-service.service';

@Component({
  selector: 'app-identification-group-selector',
  templateUrl: './identification-group-selector.component.html',
  styleUrls: ['./identification-group-selector.component.css']
})
export class IdentificationGroupSelectorComponent implements OnInit {

  @Input()
  serverIdentificationGenerationChangedEventEmitter: EventEmitter<string>;

  @Input()
  isReadOnly: boolean;

  _serveridentificationItem: ServerIdentificationCategoryItemComponent;
  selectedIdentificationGroup: IdentificationGroup;
  isEditingAllowed = true;
  allIdentificationGroups: IdentificationGroup[];
  selectableIdentificationGroups: IdentificationGroup[];
  loading = false;
  _generation: string;

  constructor(private identificationGroupService: IdentificationGroupService,
    private messageService: MessageService, private router: Router,
    private authService: AuthService) { }

  ngOnInit() {
    if (this.serverIdentificationGenerationChangedEventEmitter) {
      this.serverIdentificationGenerationChangedEventEmitter.subscribe((x: string) => {
        this.generation = x;
      });
    }

    this.getIdentificationGroups();
  }

  get serverIdentificationItem(): ServerIdentificationCategoryItemComponent {
    return this._serveridentificationItem;
  }

  @Input()
  set serverIdentificationItem(serverIdentificationItem: ServerIdentificationCategoryItemComponent) {
    this._serveridentificationItem = serverIdentificationItem;

    if (serverIdentificationItem) {
      this.getIdentificationGroups();
    }
  }

  getIdentificationGroups() {
    this.loading = true;

    this.identificationGroupService.getIdentificationGroups(false).subscribe(data => {
      this.allIdentificationGroups = data;
      this.calculateAndSetSelectableGroups(this.serverIdentificationItem.model.generation);
      this.loading = false;
    },
      error => {
        this.messageService.dispatchErrorMessageFromApi(error);
        this.loading = false;
      });
  }

  findIdentificationGroup(groups: IdentificationGroup[]) {
    if (groups) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < groups.length; ++i) {
        const g = groups[i];
        if (g.serverIdentifications.some(s => s.id === this.serverIdentificationItem.model.id)) {
          return g;
        }
      }
    }

    return null;
  }

  identificationGroupChanged(newIdentificationGroup: IdentificationGroup) {
    this.attachToNewIdentificationGroup(newIdentificationGroup);
  }

  attachToNewIdentificationGroup(newIdentificationGroup: IdentificationGroup) {
    if (!newIdentificationGroup) {
      return;
    }

    const serverIdentification = new ServerIdentificationModel();

    serverIdentification.id = this.serverIdentificationItem.model.id;

    this.identificationGroupService.addServerIdentificationToGroup(newIdentificationGroup.id, serverIdentification).subscribe(data => {
      this.getIdentificationGroups();
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  get generation() {
    return this._generation;
  }

  set generation(value: string) {
    if (this._generation === value) {
      return;
    }

    this._generation = value;

    this.calculateAndSetSelectableGroups(value);
    this.addToSelectableGroup();
  }

  addToSelectableGroup() {
    if (!this.allIdentificationGroups) {
      return;
    }

    if (!this.serverIdentificationItem) {
      return;
    }

    const currentIdentificationGroup = this.findIdentificationGroup(this.allIdentificationGroups);

    if (!currentIdentificationGroup) {
      this.identificationGroupChanged(this.selectableIdentificationGroups[0]);
    } else if (!this.selectableIdentificationGroups.some(x => x.id === currentIdentificationGroup.id)) {
      this.identificationGroupChanged(this.selectableIdentificationGroups[0]);
    }
  }

  calculateAndSetSelectableGroups(generation: string) {
    if (!this.allIdentificationGroups) {
      return;
    }

    if (!this.serverIdentificationItem) {
      return;
    }

    const groupsWithGenerations = this.allIdentificationGroups.filter(identificationGroup => identificationGroup.familyGenerationPairs && identificationGroup.familyGenerationPairs.length > 0);
    const defaultGroup = this.allIdentificationGroups.find(identificationGroup => identificationGroup.isDefaultGroup === true);

    const groupsMatchingGeneration = groupsWithGenerations.filter(identificationGroup => identificationGroup.familyGenerationPairs.some(fgp => fgp.generation.name === generation));

    let selectableGroups: IdentificationGroup[];

    if (groupsMatchingGeneration.length === 0) {
      selectableGroups = [defaultGroup];
    } else {
      selectableGroups = groupsMatchingGeneration;
      // if the server identification belongs to the default group, it should be added
      if (defaultGroup.serverIdentifications.some(x => x.id === this.serverIdentificationItem.id)) {
        selectableGroups.unshift(defaultGroup);
      }
    }

    this.selectableIdentificationGroups = selectableGroups;
    this.selectedIdentificationGroup = this.findIdentificationGroup(this.selectableIdentificationGroups);
  }

  get groupLink(): any[] {
    if (this.selectedIdentificationGroup) {
      return ['/content/identification-groups', this.selectedIdentificationGroup.id];
    } else {
      return ['/content/identification-groups'];
    }
  }
  canEditCategoryItems() {
    return this.authService.canEditCategoryItems();
  }
}
