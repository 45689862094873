import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InWorkDirective } from 'app/modules/shared/directives/in-work.directive';
import { IsOpenDirective } from 'app/modules/shared/directives/is-open.directive';
import { LegacyDirective } from 'app/modules/shared/directives/legacy.directive';
import { ReleasedDirective } from 'app/modules/shared/directives/released.directive';
import { ActiveUsersDirective } from 'app/modules/shared/directives/active-users.directive';

@NgModule({
  declarations: [
    InWorkDirective,
    IsOpenDirective,
    LegacyDirective,
    ReleasedDirective,
    ActiveUsersDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    InWorkDirective,
    IsOpenDirective,
    LegacyDirective,
    ReleasedDirective,
    ActiveUsersDirective
  ]
})
export class ResourcesModule { }
