import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { SpecificationVersion } from 'app/app-model/specification-version';
import { LegacyConversionService } from 'app/app-services/legacy-conversion.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upgrade-version-modal',
  templateUrl: './upgrade-version-modal.component.html',
  styleUrls: ['./upgrade-version-modal.component.css']
})
export class UpgradeVersionModalComponent implements OnDestroy {

  @Input()
  anyInWorkVersions: boolean;

  @Input()
  loading: boolean;

  @Output()
  versionCreatedEventEmitter: EventEmitter<SpecificationVersion> = new EventEmitter<SpecificationVersion>();

  hasUpgradeSupport: boolean;
  upgradeSupportSubscription: Subscription;
  modalClosing: EventEmitter<void> = new EventEmitter<void>();

  private _basedOnVersion: SpecificationVersion;
  public get basedOnVersion(): SpecificationVersion {
    return this._basedOnVersion;
  }

  @Input()
  public set basedOnVersion(v: SpecificationVersion) {
    this._basedOnVersion = v;
    this.checkUpgradeSupport();
  }

  constructor(private legacyConversionService: LegacyConversionService, private specificationService: SpecificationService) { }

  ngOnDestroy(): void {
    if (this.upgradeSupportSubscription) {
      this.upgradeSupportSubscription.unsubscribe();
    }
  }

  apply() {
    this.loading = true;
    this.legacyConversionService.upgradeSpecificationVersion(this.basedOnVersion).subscribe(upgradedVersion => {
      this.versionCreatedEventEmitter.emit(upgradedVersion);
      this.loading = false;
      this.close();
    }, error => {
      this.loading = false;
    });
  }

  checkUpgradeSupport() {
    if (this.basedOnVersion) {
      this.upgradeSupportSubscription = this.specificationService.hasOdxUpgradeSupport(this.basedOnVersion.id).subscribe(result => {
        this.hasUpgradeSupport = result;
      });
    } else {
      this.hasUpgradeSupport = false;
    }
  }

  close() {
    this.modalClosing.emit();
  }
}
