/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
import { Label } from 'app/app-model/label/label';
import { User } from 'app/modules/shared/model/user';

export enum ScommFileType {
    LegacyServerFile,
    ServerFile,
    EcuIdFile,
    KeyFile,
    Other
}

export enum SharedFileType {
    XmlFile,
    KeyFile,
    Other
}

export class ScommFile {
    id: number;
    specificationVersionId: number;
    name: string;
    data: string;
    fileType: ScommFileType = ScommFileType.EcuIdFile;
    sharedFileId: number;
    creationDate: string;
    modificationDate: string;
    modifiedBy: User;
}

export class ScommFileDraft {
    id: number;
    data: string;
    name: string;
    created: string;
    modified: string;
    user: User;
    fileType: ScommFileType = ScommFileType.EcuIdFile;
}

export class ScommFileDraftResult {
    draft: ScommFileDraft;
    isValid: boolean;
    validationMessage: string;
}

export class ScommSharedFile {
    id: number;
    name: string;
    data: string;
    labels: Label[];
    fileType: ScommFileType = ScommFileType.EcuIdFile;
}

export class SharedFile {
    id: number;
    name: string;
    alwaysInclude: boolean;
    fileType: ScommFileType;
}

export class LegacyServerFile {
    id: number;
    scommFileId: number;
    name: string;
    userName: string;
    data: string;
    modified: string;
}

export class ScommFileValidationResult {
    isValid: boolean;
    validationMessage: string;
    relationalValidationMessage: string;
}
