/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ComputationalMethodModel,
  ComputationalMethodType,
} from 'app/app-model/diagnostic-service/computational.method.model';
import { DataLimitType } from 'app/app-model/diagnostic-service/range.model';
import { ComputationalMethodIntervalModel } from 'app/app-model/diagnostic-service/text.table.interval.data.model';
import { LinearFormulaType } from 'app/app-model/enums';
import { DiagnosticServiceValidator } from 'app/app-services/diagnostic-service-validator';
import { ServiceModificationService } from 'app/app-services/service-modification.service';
import { OdxDataType, TypedValueData } from 'app/modules/shared/model/service/parameters/typed-value-data';

@Component({
  selector: 'app-editable-computational-method',
  templateUrl: './editable-computational-method.component.html',
  styleUrls: ['./editable-computational-method.component.css']
})
export class EditableComputationalMethodComponent {

  @Input()
  isInternalToPhysical: boolean;

  @Input()
  isOdxBasedSpecification: boolean;

  @Input()
  isAllowed: boolean;

  @Output()
  compMethodChange: EventEmitter<void> = new EventEmitter();

  DataLimitType = DataLimitType;
  OdxDataType = OdxDataType;
  LinearFormulaType = LinearFormulaType;

  _internalDataType: OdxDataType;
  get internalDataType(): OdxDataType {
    return this._internalDataType;
  }

  @Input()
  set internalDataType(val: OdxDataType) {
    this._internalDataType = val;
    this.setCurrentDataTypeToExistingIntervals();
  }

  private _physicalDataType: OdxDataType;
  public get physicalDataType(): OdxDataType {
    return this._physicalDataType;
  }

  @Input()
  public set physicalDataType(v: OdxDataType) {
    this._physicalDataType = v;
  }

  private _computationalMethod: ComputationalMethodModel;
  public get computationalMethod(): ComputationalMethodModel {
    return this._computationalMethod;
  }

  @Input()
  public set computationalMethod(v: ComputationalMethodModel) {
    this._computationalMethod = v;
    this.syncLinearFormulaTypeFromModel();
  }

  constructor(private diagnosticServiceValidator: DiagnosticServiceValidator,
    private serviceModificationService: ServiceModificationService) { }

  removeTextTableInterval(itemToRemove: any) {
    const indexItemToRemove = this.computationalMethod.intervals.findIndex(p => p === itemToRemove);
    this.computationalMethod.intervals.splice(indexItemToRemove, 1);

    this.onChange();
  }

  private _selectedLinearFormulaType: LinearFormulaType;
  public get selectedLinearFormulaType(): LinearFormulaType {
    return this._selectedLinearFormulaType;
  }

  public set selectedLinearFormulaType(v: LinearFormulaType) {
    this._selectedLinearFormulaType = v;
    this.resetLinearFormulaFields();
  }

  get hasFixedFormulaType(): boolean {
    return this.selectedLinearFormulaType === LinearFormulaType.Fixed;
  }

  get hasTextFormulaType(): boolean {
    return this.selectedLinearFormulaType === LinearFormulaType.Customized;
  }

  get hasNumericalDataType(): boolean {
    return this.internalDataType !== OdxDataType.AsciiString &&
      this.internalDataType !== OdxDataType.ByteField;
  }

  get hasStringAsciiDataType(): boolean {
    return this.internalDataType === OdxDataType.AsciiString;
  }

  get isLinearFormulaData() {
    return this.computationalMethod.methodType === ComputationalMethodType.LinearFormulaData;
  }

  get isTextTable() {
    return this.computationalMethod.methodType === ComputationalMethodType.TextTable;
  }

  get availableLinearFormulaTypes(): string[] {
    return Object.keys(LinearFormulaType).filter(d => !this.isNumeric(d));
  }
  public isNumeric(value) {
    // Use the unary plus operator (+) to convert the value to a number
    // and check if it's a finite number
    return !isNaN(value) && !isNaN(parseFloat(value));
  }
  get sortedTextTableIntervals() {
    if (this.computationalMethod.intervals) {
      return this.computationalMethod.intervals.sort(this.textTableComparer);
    } else {
      return [];
    }
  }

  get hasDefaultInterval(): boolean {
    return this.computationalMethod.defaultInterval !== undefined &&
      this.computationalMethod.defaultInterval !== null;
  }

  set hasDefaultInterval(enabled: boolean) {
    this.computationalMethod.defaultInterval = enabled ? '' : undefined;
  }

  get defaultIntervalText(): string {
    return this.computationalMethod.defaultInterval;
  }

  get numeratorCoefficient(): number {
    return this.computationalMethod.intervals[0].numeratorCoefficient;
  }

  set numeratorCoefficient(val: number) {
    this.computationalMethod.intervals[0].numeratorCoefficient = val;
  }

  get numeratorConstant(): number {
    return this.computationalMethod.intervals[0].numeratorConstant;
  }

  set numeratorConstant(val: number) {
    this.computationalMethod.intervals[0].numeratorConstant = val;
  }

  get denominatorConstant(): number {
    return this.computationalMethod.intervals[0].denominatorConstant;
  }

  set denominatorConstant(val: number) {
    this.computationalMethod.intervals[0].denominatorConstant = val;
  }

  public get formulaAsStr(): string {
    return this.computationalMethod.intervals[0].formulaAsStr;
  }

  public set formulaAsStr(formula: string) {
    this.computationalMethod.intervals[0].formulaAsStr = formula;
  }

  get hasValidTextTableDataTypes(): boolean {
    return this.diagnosticServiceValidator.hasValidTextTableDataTypes(this.physicalDataType);
  }

  get hasValidLinearFormulaDataTypes(): boolean {
    return this.diagnosticServiceValidator
      .hasValidLinearFormulaDataTypes(this.internalDataType, this.physicalDataType, this.isInternalToPhysical);
  }

  clearTextFormula() {
    this.formulaAsStr = 'X';
    this.onChange();
  }

  resetLinearFormulaFields() {
    if (this.selectedLinearFormulaType === LinearFormulaType.Customized) {
      this.computationalMethod.intervals[0].formulaAsStr = 'X';
      this.computationalMethod.intervals[0].numeratorConstant = 0;
      this.computationalMethod.intervals[0].numeratorCoefficient = 1;
      this.computationalMethod.intervals[0].denominatorConstant = 1;

      return;
    }

    this.computationalMethod.intervals[0].formulaAsStr = '';
  }

  syncLinearFormulaTypeFromModel() {
    if (this.computationalMethod.methodType !== ComputationalMethodType.LinearFormulaData) {
      return;
    }

    if (this.computationalMethod.intervals[0].formulaAsStr) {
      this._selectedLinearFormulaType = LinearFormulaType.Customized;
      return;
    }

    this._selectedLinearFormulaType = LinearFormulaType.Fixed;
    return;
  }

  enableTextTable() {
    this.resetTextTable();
    this.computationalMethod.methodType = ComputationalMethodType.TextTable;
    this.onChange();
  }

  enableLinearFormula() {
    this.resetLinearFormula();
    this.computationalMethod.methodType = ComputationalMethodType.LinearFormulaData;
    this.addNewInterval(false);

    this.numeratorConstant = 0;
    this.numeratorCoefficient = 1;
    this.denominatorConstant = 1;
    this.serviceModificationService.changePhysicalDataType(OdxDataType.Float64);

    if (this.hasNumericalDataType) {
      this.onChange();
    }
  }

  addNewInterval(notifyChange: boolean = true) {
    if (!this.computationalMethod.intervals) {
      this.computationalMethod.intervals = [this.createTextTableInterval()];
    } else {
      this.computationalMethod.intervals.push(this.createTextTableInterval());
    }

    if (notifyChange) {
      this.onChange();
    }
  }

  onChange(event?: any) {
    if (!event && this.isValidComputationalMethod()) {
      this.compMethodChange.next();
    }
  }

  isValidComputationalMethod(): boolean {
    return this.diagnosticServiceValidator.isValidComputationalMethod(this.computationalMethod.methodType,
      this.internalDataType,
      this.physicalDataType,
      this.isInternalToPhysical);
  }

  private resetTextTable() {
    this.computationalMethod.defaultInterval = undefined;
    this.computationalMethod.intervals = [];
  }

  private createTextTableInterval(): ComputationalMethodIntervalModel {
    return {
      index: 0,
      id: 0,
      text: '',
      hasInverseInternalValue: false,
      inverseInternalValue: undefined,
      range: {
        id: 0,
        lowerType: DataLimitType.Closed,
        lowerValue: this.createDefaultValue(this.internalDataType),
        upperType: DataLimitType.Closed,
        upperValue: this.createDefaultValue(this.internalDataType)
      },
      denominatorConstant: 0,
      numeratorCoefficient: 0,
      numeratorConstant: 0,
      formulaAsStr: undefined
    };
  }

  private textTableComparer(a: ComputationalMethodIntervalModel, b: ComputationalMethodIntervalModel) {
    return a.index - b.index;
  }

  private resetLinearFormula() {
    this.resetTextTable();
  }

  private setCurrentDataTypeToExistingIntervals() {
    const targetDataType = this.internalDataType;

    if (this.computationalMethod.methodType === ComputationalMethodType.LinearFormulaData) {
      return;
    }

    if (this.computationalMethod.intervals) {
      this.computationalMethod.intervals.forEach(interval => {
        const sourceDataType = interval.range.lowerValue.dataType;
        interval.range.lowerValue.dataType = targetDataType;
        interval.range.upperValue.dataType = targetDataType;

        if (!this.isSwitchingBetweenNumericalDataTypes(sourceDataType, targetDataType)) {
          interval.range.lowerValue = this.createDefaultValue(targetDataType);
          interval.range.upperValue = this.createDefaultValue(targetDataType);
        }
      });
    }
  }

  private createDefaultValue(dataType: OdxDataType): TypedValueData {
    switch (dataType) {
      case OdxDataType.UInt32:
      case OdxDataType.Int32:
      case OdxDataType.Float32:
      case OdxDataType.Float64:
        return { data: '0', dataType };
      case OdxDataType.AsciiString:
      case OdxDataType.ByteField:
        return { data: '', dataType };
      default:
        return { data: '', dataType };
    }
  }

  private isSwitchingBetweenNumericalDataTypes(sourceDataType: OdxDataType, targetDataType: OdxDataType): boolean {
    return this.isNumericDataType(sourceDataType) && this.isNumericDataType(targetDataType);
  }

  private isNumericDataType(dataType: OdxDataType): boolean {
    return dataType === OdxDataType.Float32 ||
      dataType === OdxDataType.Float64 ||
      dataType === OdxDataType.Int32 ||
      dataType === OdxDataType.UInt32;
  }
}
