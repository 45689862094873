import { Injectable } from '@angular/core';
import { OdxFile } from 'app/app-model/odx-import/odx-file';
import { environment } from 'environments/environment';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { OdxLayer, OdxLayerInfo } from '../app-model/odx-import/odx-import';
import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { DiagnosticServiceProvider } from './diagnostic.service.service';
import { FileService } from './file.service';
import { SpecificationService } from './specification-service';

@Injectable({
  providedIn: 'root'
})
export class OdxService {

  specificationSubscription: Subscription;
  specificationId: number;

  constructor(private apiProxy: ApiProxyService,
    private diagnosticServiceprovider: DiagnosticServiceProvider,
    private fileService: FileService,
    private specificationService: SpecificationService) {
    this.specificationSubscription = this.specificationService.selectedSpecificationEventEmitter.subscribe(spec => {
      if (spec.specification)
        this.specificationId = spec.specification.id;
    });
  }

  public validateOdxFile(odxFile: { fileName: string, fileData: File }): Observable<{ isValidFile: boolean, isValidContent: boolean, validationMessage: string }> {
    if (!this.fileService.isCompatibleOdxFile(odxFile.fileName)) {
      return of({ isValidFile: false, isValidContent: false, validationMessage: `The selected file is not a valid ODX file. Only files with extension ODX or ODX-d are allowed` })
    }

    return this.validateOdxFileContents(odxFile.fileData);
  }

  public validateOdxFileContents(fileData: File): Observable<{ isValidFile: boolean, isValidContent: boolean, validationMessage: string }> {
    return this.fileService.getOdxFileContentsAsBase64(fileData).pipe(
      switchMap(fileAsBase64 => {
        return this.diagnosticServiceprovider.getRequiredDiagnosticServiceList(this.specificationId).pipe(map(requiredServices => {
          let validationResult = { isValidFile: true, isValidContent: true, validationMessage: `<ul>` };
          const odxFileData = atob(fileAsBase64);

          if (requiredServices.items && requiredServices.items.length != 0) {
            const servicesInOdx = this.getServicesInOdxFile(odxFileData);
            requiredServices.items.forEach(requiredService => {
              if (!servicesInOdx.includes(requiredService.serviceName)) {
                validationResult.isValidContent = false;
                validationResult.validationMessage += `<li>${requiredService.serviceName} ${requiredService.serviceCode} is missing.</li>`;
              }
            });
          }
          validationResult.validationMessage += '</ul>'
          return validationResult;
        }));
      })
    );
  }

  public getOdxFile(id: number): Observable<OdxFile> {
    // Make the HTTP request:
    return this.apiProxy.get<OdxFile>(environment.apiUrl + 'odxfiles/' + id);
  }

  public getOdxFilePreview(id: number): Observable<OdxFile> {
    // Make the HTTP request:
    return this.apiProxy.get<OdxFile>(environment.apiUrl + 'odxfiles/' + id + '/preview');
  }

  public createOdxFile(odxFile: OdxFile): Observable<number> {
    return this.apiProxy.post<OdxFile, number>(environment.apiUrl + 'odxfiles/odxtask', odxFile);
  }

  public getOdxFileLayer(id: number, shortName: string): Observable<OdxLayer> {
    return this.apiProxy.get<OdxLayer>(environment.apiUrl + 'odxfiles/' + id + '/layertask/' + shortName);
  }

  public getCompleteOdxFileLayer(taskId: string): Observable<OdxLayer> {
    return this.apiProxy.get<OdxLayer>(environment.apiUrl + 'odxfiles/layer/complete/' + taskId);
  }

  public getOdxFileLayers(id: number): Observable<OdxLayerInfo[]> {
    return this.apiProxy.get<OdxLayerInfo[]>(environment.apiUrl + 'odxfiles/' + id + '/alllayertask');
  }

  public getCompleteOdxFileLayers(taskId: string): Observable<OdxLayerInfo[]> {
    return this.apiProxy.get<OdxLayerInfo[]>(environment.apiUrl + 'odxfiles/layers/completed/' + taskId);
  }

  public replaceOdxFile(currentOdxFileId: number, incomingOdxFile: OdxFile): Observable<OdxFile> {
    return this.apiProxy.put<any, OdxFile>(environment.apiUrl + 'odxfiles/' + currentOdxFileId + '/odxreplace', incomingOdxFile);
  }

  public updateDiagnosticSpecificationTemplateFromOdxFile(templateSpecificationId: number, odxFile: string): Observable<any> {
    return this.apiProxy.postString(`${environment.apiUrl}admin/updateTemplate/${templateSpecificationId}`, odxFile);
  }

  private getServicesInOdxFile(odxFileData: string): Array<string> {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(odxFileData, "text/xml");
    const nodes = xmlDoc.querySelectorAll('DIAG-SERVICE SHORT-NAME');
    const serviceNames = [];
    nodes.forEach(node => serviceNames.push(node.innerHTML));

    return serviceNames;
  }
}

