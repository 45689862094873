import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-login-prerequisites',
  templateUrl: './login-prerequisites.component.html',
  styleUrls: ['./login-prerequisites.component.css']
})
export class LoginPrerequisitesComponent implements OnInit, OnDestroy {


  @Input()
  public isEditingAllowed: boolean;

  @Input()
  public allowedLogin: string;

  @Input()
  public loginToPerform: string;

  @Output()
  public allowedLoginChanged: EventEmitter<string> = new EventEmitter();

  @Output()
  public loginToPerformChanged: EventEmitter<string> = new EventEmitter();

  allowedLoginInputChanged = new Subject<string>();
  loginToPerformInputChanged = new Subject<string>();

  allowedLoginInputChangedSubscription: Subscription;
  loginToPerformInputChangedSubscription: Subscription;


  constructor() { }

  ngOnInit(): void {
    this.listenForAllowedLoginInputChanges();
    this.listenForLoginToPerformInputChanges();
  }

  ngOnDestroy(): void {
    if (this.allowedLoginInputChangedSubscription) {
      this.allowedLoginInputChangedSubscription.unsubscribe();
    }

    if (this.loginToPerformInputChangedSubscription) {
      this.loginToPerformInputChangedSubscription.unsubscribe();
    }
  }

  private listenForAllowedLoginInputChanges() {
    this.allowedLoginInputChangedSubscription = this.allowedLoginInputChanged.pipe(debounceTime(10), distinctUntilChanged())
      .subscribe(value => {
        this.allowedLoginChanged.next(this.allowedLogin);
      });
  }

  private listenForLoginToPerformInputChanges() {
    this.loginToPerformInputChangedSubscription = this.loginToPerformInputChanged.pipe(debounceTime(10), distinctUntilChanged())
      .subscribe(value => {
        this.loginToPerformChanged.next(this.loginToPerform);
      });
  }

}
