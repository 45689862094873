<div *ngIf="loading" class="loader-container">
  <div class="loader"></div>
</div>

<div class="sdds-row">
  <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3">
    <!-- [class.loading]="loading"> -->
    <div class="bg-color-gray p-16 mb-16">
      <h4>Identification Groups</h4>

      <app-toolbar #toolbarRef [filterEnabled]="true" [removeEventEmitterEnabled]="canRemoveItem"
        (filterTextEventEmitter)="filterText = $event" [addEventEmitterEnabled]="true" [dataTargetEnabledAdd]="false"
        [dataTargetEnabledRemove]="false" (addEventEmitter)="add()" (removeEventEmitter)="remove()"
        [isAllowed]="canEditIdentificationsGroup()"></app-toolbar>

      <div *ngIf="identificationGroups">
        <div class="content-list-container bg-color-gray overflow-y-auto max-h-v-70 m-1">
          <app-common-list [isDragList]="canEditIdentificationsGroup()" [list]="identificationGroups | nameFilter: filterText"
            [itemTemplate]="groupItemTemplate" [selectedItem]="selectedIdentificationGroup"
            (selectedItemEventEmitter)="groupSelected($event)"
            (orderChangedEventEmitter)="identificationGroupOrderChanged($event)"
            (elementDropped)="setIdentificationGroupDropped($event)"
            [commonListElementSettingsInitiator]="settingsInitiatorForGroups"
            [isAllowed]="canEditIdentificationsGroup()"></app-common-list>
        </div>

        <ng-template #groupItemTemplate let-item>
          <p class="sdds-headline-07">{{ generateItemName(item) }}</p>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-9 sdds-col-md-9 sdds-col-sm-9 sdds-col-xs-9">
    <div *ngIf="selectedIdentificationGroup">
      <app-editable-textbox enclosingElement="h1" [text]="selectedIdentificationGroup.name"
        [isEditable]="!selectedIdentificationGroup.isDefaultGroup" (textEditedEventEmitter)="groupNameChanged($event)"
        [isAllowed]="canEditIdentificationsGroup()">
      </app-editable-textbox>

      <div *ngIf="selectedIdentificationGroup.isDefaultGroup">
        <div class="sdds-banner sdds-banner-info">
          <div class="sdds-banner-body">
            This is the default group. Any server identification that is not connected to a group will appear in this
            group
          </div>
        </div>
      </div>

      <div *ngIf="!selectedIdentificationGroup.isDefaultGroup">
        <div>
          <app-family-generation [familyGenerationPairs]="
              selectedIdentificationGroup.familyGenerationPairs
            " (update)="familyGenerationsUpdated($event)" [isReadonly]="!canModifyExpression"
            [isAllowed]="canEditIdentificationsGroup()"></app-family-generation>
        </div>
        <br />
        <br />
        <h4>Expression</h4>
        <div class="sdds-banner sdds-banner-info">
          <div class="sdds-banner-body">
            <b>Warning!</b> Modifying expressions in identification groups could
            affect released specifications, change with caution.
          </div>
        </div>
        <br />
        <div>
          <app-identification-group-expression [identificationGroup]="selectedIdentificationGroup"
            [expressionUpdater]="updateExpression" (expressionUpdated)="updateExpression()"
            [isAllowed]="canEditIdentificationsGroup()"></app-identification-group-expression>
        </div>
      </div>
      <br />
      <div class="sdds-divider-light"></div>
      <br />
      <div>
        <h4>Connected Server Identifications</h4>
        <app-connected-server-identifications [identificationGroup]="selectedIdentificationGroup"
          (orderChangedEventEmitter)="orderChanged($event)"></app-connected-server-identifications>
      </div>
    </div>
  </div>
</div>
