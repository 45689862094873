import { Component, OnInit, Input, Pipe, PipeTransform, Output, EventEmitter } from '@angular/core';
import { ExportCriteriaItem } from '../../model/export/export-criteria-item';
import { ExportPreview } from '../../model/export/export-preview';
import { Subject } from 'rxjs';
import { Tree } from '../../../shared/components/tree/model/tree';
import { ExportService } from '../../services/export.service';
import { MessageService } from '../../../shared/services/message-service.service';
import { ExportCriteria } from '../../model/export/export-critieria';
import { TreeService } from '../../../shared/components/tree/service/tree.service';
import { TreeNode } from '../../../shared/components/tree/model/tree-node';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-global-export-preview',
  templateUrl: './global-export-preview.component.html',
  styleUrls: ['./global-export-preview.component.css'],
})

export class GlobalExportPreviewComponent implements OnInit {
  items: ExportCriteriaItem[];
  loading = false;
  itemSubject = new Subject<ExportCriteriaItem>();
  exportDataSubject = new Subject<number[]>();
  exportDataObservable = this.exportDataSubject.pipe(debounceTime(50));
  itemObservable = this.itemSubject.pipe(debounceTime(50));
  identificationTree: Tree;
  filterText: string;

  private _exportPreview: ExportPreview;
  private _item: ExportCriteriaItem = null;
  private _exportData: number[] = [];

  constructor(private exportService: ExportService, private messageService: MessageService) { }

  ngOnInit() {
    this.itemObservable.subscribe(x => {
      this.getPreview(x);
    });
    this.exportDataObservable.subscribe(x => {
      this.getPreviewNoCriteria(x);
    });
  }



  public get exportPreview(): ExportPreview {
    return this._exportPreview;
  }
  public set exportPreview(v: ExportPreview) {
    this._exportPreview = v;
  }

  @Input()
  set item(value: ExportCriteriaItem) {
    this._item = value;
    this.itemSubject.next(value);
  }

  get item() {
    return this._item;
  }

  @Input()
  set exportData(exportData: number[]) {
    this._exportData = exportData;
    this.exportDataSubject.next(exportData);
  }

  get exportData() {
    return this._exportData;
  }

  filterIdentifications(text: string) {
    if (this.identificationTree) {
      this.identificationTree.showTreeNodesThatContainsText(text);
    }
  }

  filter(filterText: string) {
    this.filterText = filterText;
  }

  getExportPreview(critiera: ExportCriteria) {
    this.loading = true;
    this.exportService.createGlobalExportPreview(critiera).subscribe(x => {
      if (x !== null) {
        this.exportPreview = x;
        this.createIdentificationTree(x);
        this.loading = false;
      } else {
        this.loading = false;
        this.messageService.dispatchErrorMessage('Could not create an export preview. The response from the API was empty.');
      }
    }, error => {
      this.exportPreview = null;
      this.loading = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  getExportPreviewNoCriteria(exportData: number[]) {
    this.loading = true;
    this.exportService.createGlobalExportPreviewNoCriteria(exportData).subscribe(x => {
      if (x !== null) {
        this.exportPreview = x;
        this.createIdentificationTree(x);
        this.loading = false;
      } else {
        this.loading = false;
        this.messageService.dispatchErrorMessage('Could not create an export preview. The response from the API was empty.');
      }
    }, error => {
      this.exportPreview = null;
      this.loading = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  createIdentificationTree(exportPreview: ExportPreview) {
    const treeService = new TreeService();
    this.identificationTree = new Tree(new TreeService());

    exportPreview.identificationGroups.forEach(x => {
      const groupNode = new TreeNode(x.name, treeService);

      if (x.formattedExpression) {
        groupNode.name = x.name + ' (' + x.formattedExpression + ')';
      }

      x.serverIdentifications.forEach(y => {
        let serverName = y.specificationVersion.specification.name;

        if (y.formattedExpression) {
          serverName += ' (' + y.formattedExpression + ')';
        }

        const serverIdentificationNode = new TreeNode(serverName, treeService);

        groupNode.addChild(serverIdentificationNode);
      });

      this.identificationTree.addRootNode(groupNode);
    });
  }

  getPreview(item: ExportCriteriaItem) {
    if (!item) {
      return;
    }

    if (item.isLabel) {
      this.getExportPreview(ExportCriteria.createForLabel(item.id));
    } else if (item.isView) {
      this.getExportPreview(ExportCriteria.createForView(item.id));
    }
  }

  getPreviewNoCriteria(exportData: number[]) {
    if (!exportData) {
      return;
    }

    this.getExportPreviewNoCriteria(exportData);
  }
}
