import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DiagnosticFamilyModel, GenerationModel } from 'app/app-model/server-identification/diagnostic-family.model';
import { Specification } from 'app/app-model/specification';
import { DiagnosticFamilyService } from 'app/app-services/diagnostic-family.service';
import { RbacService } from 'app/app-services/rbac-file-service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import {
  SpecificationSelectorComponent,
} from 'app/modules/shared/components/specification-selector/specification-selector.component';
import { ToolbarComponent } from 'app/modules/shared/components/toolbar/toolbar.component';
import { Rbac, RbacSpecificationVersion } from 'app/modules/shared/model/rbac';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import * as FileSaver from 'file-saver';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-rbac-view',
  templateUrl: './rbac-view.component.html',
  styleUrls: ['./rbac-view.component.css']
})
export class RbacViewComponent implements OnInit {

  @ViewChild('select', { static: true }) selectElement: ElementRef;
  @ViewChild('filterToolbar', { static: true }) filterToolbar: ToolbarComponent;
  @ViewChild('specificationSelector', { static: false }) specificationSelector: SpecificationSelectorComponent;

  public get rbacItem(): Rbac {
    return this._rbacItem;
  }
  @Input()
  public set rbacItem(v: Rbac) {
    this._rbacItem = v;
    this.addNewGenerationEnabled = false
    this.addNewSpecificationEnabled = false;
  }

  loadingFamilies = false;
  loadingGenerations = false;
  loadingSpecifications = false;
  addNewSpecificationEnabled = false;
  addNewGenerationEnabled = false;
  filterText: string;
  loading: boolean;
  rbacSpecInfo: RbacSpecificationVersion[];
  families: DiagnosticFamilyModel[];
  generations: GenerationModel[];
  selectedFamily: DiagnosticFamilyModel;
  selectedGeneration: GenerationModel;
  selectedSpecifications: Specification[] = [];
  private _rbacItem: Rbac;

  constructor(
    private diagnosticFamilyService: DiagnosticFamilyService, private messageService: MessageService,
    private rbacService: RbacService, private authService: AuthService) { }

  ngOnInit() {
    this.getFamilies();
  }

  getFamilies() {
    this.loadingFamilies = true;
    this.diagnosticFamilyService.getFamilies().pipe(tap(families => {
      this.families = families;
      if (families && families.length > 0) {
        this.familyChanged(families[0]);
      } else {
        this.familyChanged(null);
      }
      this.loadingFamilies = false;
    }, error => {
      this.loadingFamilies = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    })).subscribe();
  }

  connectSelectedSpecifications() {
    if (!this.selectedSpecifications) { return; }

    var observables =
      this.selectedSpecifications.map(selectedSpec =>
        this.rbacService.createSpecConnection(this.rbacItem.rbacGuid, selectedSpec.id).pipe(tap(
          newSpec => {
            this.rbacItem.connectedSpecificationVersions.push(newSpec);
          }
        )));

    forkJoin(observables).subscribe(_ => {
      this.specificationSelector.reset();
      this.addNewSpecificationEnabled = false;
    });
  }

  familyChanged(family: DiagnosticFamilyModel) {
    this.selectedFamily = family;
    this.getGenerations();
  }

  getGenerations() {
    if (!this.selectedFamily) {
      return;
    }
    this.loadingGenerations = true;
    this.diagnosticFamilyService.getGenerations(this.selectedFamily.name).pipe(tap(generations => {

      this.generations = generations;
      if (generations && generations.length > 0) {
        this.generationChanged(generations[0]);
      } else {
        this.generationChanged(null);
      }
      this.loadingGenerations = false;
    }, error => {
      this.loadingGenerations = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    })).subscribe();
  }

  generationChanged(generation: GenerationModel) {
    this.selectedGeneration = generation;
  }

  addGeneration() {
    this.rbacService.createGenConnection(this.rbacItem.rbacGuid, this.selectedGeneration.id).pipe(tap(
      newGen => {
        this.rbacItem.connectedGenerations.push(newGen);
      }
    )).subscribe(_ => this.addNewGenerationEnabled = false);
  }

  downloadFile() {
    this.rbacService.getRbaccFileAsXml(this.rbacItem.rbacGuid).subscribe(rbaccXmlFile => {
      FileSaver.saveAs(new Blob([rbaccXmlFile]), `${this.rbacItem.rbacGuid}.xml`);
    });
  }

  removeConnectedGeneration(item: number) {
    this.rbacService.deleteGenConnection(item).subscribe(deletedGen => {
      const orginalView = this.rbacItem.connectedGenerations.find(v => v.generationId === deletedGen.generationId);
      this.rbacItem.connectedGenerations.splice(this.rbacItem.connectedGenerations.indexOf(orginalView), 1);
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  removeConnectedSpecification(specificationId: number) {
    this.rbacService.deleteSpecConnection(specificationId).subscribe(deletedSpec => {
      const orginalView = this.rbacItem.connectedSpecificationVersions.find(v => v.specificationVersionId === deletedSpec.specificationVersionId)
      this.rbacItem.connectedSpecificationVersions.splice(this.rbacItem.connectedSpecificationVersions.indexOf(orginalView), 1);
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  enableAddNewSpecification() {
    this.addNewSpecificationEnabled = !this.addNewSpecificationEnabled;
  } 

  enableAddNewGeneration() {
    this.addNewGenerationEnabled = !this.addNewGenerationEnabled;
  }

  canEditRbacc() {
    return this.authService.canEditRbacc();
  }
}
