import { ServiceExecutionSequenceModel } from '../diagnostic-service/service-execution-sequence.model';
import {
  CategoryIdType,
  DataType,
  FreezeFrameDataIndication,
  FreezeFrameEnumType,
  FreezeFrameSource,
  FreezeFrameType,
  ScalingDataType,
} from '../enums';

export class FreezeFramesModel {
  resourceType: CategoryIdType.freezeframes;
  name: string;
  description: string;
  freezeFrameSource: FreezeFrameSource;
  freezeFrameDataIndication: FreezeFrameDataIndication;
  freezeFrameType: FreezeFrameType;
  freezeFrameSequence: ServiceExecutionSequenceModel;
  formulaForReading: string;
  unit: string;
  bytePosition: number;
  byteLength: number;
  bitPosition: number;
  bitLength: number;
  userDataType: DataType;
  scalingDataType: ScalingDataType;
  did: string;
  freezeFrameEnumData: string;
  freezeFrameEnumType: FreezeFrameEnumType = FreezeFrameEnumType.Value;
}

export class FreezeFrameEnumValue {
  ecuValue: number;
  logicalValue: number;
  name: string;
}

export class FreezeFrameEnumInterval {
  lowerlimit: number;
  upperLimit: number;
  ecuValueToWrite: number;
  logicalValue: number;
  name: string;
}
