import { ApiProxyService } from "app/modules/shared/services/api-proxy.service";
import { FileService } from "./file.service";
import { Injectable } from "@angular/core";
import { VsocCategoryItem } from "app/data-categories/vsoc-monitoring/vsoc-category-item";
import { map, Observable, of, tap } from "rxjs";
import { VsocMonitoringItem } from "app/app-model/vsoc/vsoc-monitoring-item";
import { environment } from "environments/environment";
import { CategoryServiceBase } from "./category-base.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SpecificationService } from "./specification-service";
import { DiagnosticServiceProvider } from "./diagnostic.service.service";
import { Location } from '@angular/common';
@Injectable({
  providedIn: 'root'
})

export class VsocMonitoringService extends CategoryServiceBase<VsocMonitoringItem, VsocCategoryItem> {


  getCategoryItemModel(specificationVersionId: number, name: string): Observable<VsocCategoryItem> {
    return this.apiProxy.get<VsocMonitoringItem>(`${environment.apiUrl}vsoc-monitoring/${specificationVersionId}/${name}`)
      .pipe(map(vsocMonitor => {
        var vsocCategoryItem = this.createVsocMonitorCategoryItemFromModel(vsocMonitor);
        this.items.push(vsocCategoryItem);
        this.getItemsPreviewDone.next(this.isPending);
        return vsocCategoryItem;
      }));
  }
  getItemsPreview(specificationVersionId: number): Observable<VsocCategoryItem[]> {
    // this.isPending = true;
    if (this.hasCachedItems) {
      this.isPending = false;
      return of(this.items);
    } else {
      return this.apiProxy.get<VsocMonitoringItem[]>(environment.apiUrl
        + 'vsoc-monitoring/get-all/' + specificationVersionId)
        .pipe(map(vsocItemList => {
          this.items.splice(0, this.items.length);
          let vsocCategoryList = vsocItemList.map(vsocModel => this.createVsocMonitorCategoryItemFromModel(vsocModel));
          this.items = this.items.concat(vsocCategoryList);
          this.hasCachedItems = true;
          this.isPending = false;
          this.getItemsPreviewDone.next(this.isPending);
          return this.items;
        }));
    }
  }
  getCompleteItems(specificationVersionId: number): Observable<VsocCategoryItem[]> {
    // this.isPending = true;
    return this.apiProxy.get<VsocMonitoringItem[]>(environment.apiUrl
      + 'vsoc-monitoring/get-all/' + specificationVersionId)
      .pipe(map(vsocItemList => {
        this.items.splice(0, this.items.length);
        let vsocCategoryList = vsocItemList.map(vsocModel => this.createVsocMonitorCategoryItemFromModel(vsocModel));
        this.items = this.items.concat(vsocCategoryList);
        this.hasCachedItems = true;
        this.isPending = false;
        this.getItemsPreviewDone.next(this.isPending);
        return this.items;
      }));
  }
  createItem(specificationVersionId: number, itemName?: string, itemId?: number): Observable<VsocCategoryItem> {
    throw new Error("Method not implemented.");
  }
  deleteItem(specificationVersionId: number, itemName?: string, itemId?: number): Observable<any> {
    throw new Error("Method not implemented.");
  }
  updateItem(specificationVersionId: number, itemModel: VsocMonitoringItem, itemName?: string, itemId?: number): Observable<VsocMonitoringItem> {
    return this.apiProxy.put<VsocMonitoringItem>(`${environment.apiUrl}vsoc-monitoring/${specificationVersionId}/`, itemModel);
  }


  constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
    private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    apiProxy: ApiProxyService) {
    super(apiProxy);
  }

  private createVsocMonitorCategoryItemFromModel(vsocItem: VsocMonitoringItem): VsocCategoryItem {
    const vsocCatogyItem = new VsocCategoryItem();
    vsocCatogyItem._vsocService = this;
    vsocCatogyItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
    vsocCatogyItem.isPreview = false;
    vsocCatogyItem.model = vsocItem;
    return vsocCatogyItem;
  }

}