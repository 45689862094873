import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValueConversionService {

  public convertToByteArray(data: string): Uint8Array {
    const raw = atob(data);

    const array = new Uint8Array(new ArrayBuffer(raw.length));

    for (let i = 0; i < raw.length; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
}
