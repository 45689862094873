import { PackageImportStatus } from "./enums";

export class PackageImportReport<T> {
    packageImportStatus: PackageImportStatus;
    message: string;
    importItems: ImportItem<T>[];
}

export class ImportItem<T> {
    status: PackageImportStatus;
    messages: string[];
    item: T;
}

export class PackageImportReportTableModel {
    name: string;
    status: PackageImportStatus;
    message: string;
}