<div *ngIf="testSteps">
  <app-xml-code-viewer 
    [titleName]="'Unreferenced Teststeps'" 
    [bannerText]="text" 
    [readModeOnly]="isReadOnly"
    [xmlString]=[testSteps.xmlContent] 
    (modifiedData)="updateUnreferencedTeststeps($event)"
    (delete)="deleteUnreferencedTestStepsDocument()">
  </app-xml-code-viewer>
</div>
<div *ngIf="!testSteps" class="sdds-col-max-12">
  <app-category-header [text]="'Unreferenced Teststeps'"></app-category-header>
  <div class="sdds-banner sdds-banner-info">
    <div class=" sdds-banner-body">
      <p class="sdds-banner-header">
        This specification Version does not have Unreferenced Test Steps defined. Click on <b>Create document</b> to start editing unreferenced test steps.
      </p>
    </div>
  </div>

  <button 
    class="sdds-btn sdds-btn-primary sdds-btn-sm mt-10" 
    [disabled]="isReadOnly" 
    (click)="createUnreferencedTestStepsDocument()">Create Document</button>
</div>
