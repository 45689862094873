import { Injectable } from '@angular/core';
import { ScommFileType } from 'app/app-model/legacy-file';
import { Observable, Observer, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScommFileImporterService {

  getFileType(file: File, isSharedFile = false): Observable<ScommFileType> {
    const isXmlFile = file.name.toLowerCase().endsWith('xml');
    const isBinFile = file.name.toLowerCase().endsWith('bin');

    if (!isXmlFile && !isBinFile) {
      return of(null);
    }

    if (isBinFile) {
      return of(ScommFileType.KeyFile);
    }

    if (isXmlFile) {
      return this.getFileContentAsString(file).pipe(
        map(fileContent => {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(fileContent, 'text/xml');
          const docTypeElements = xmlDoc.getElementsByTagName('Doctype');

          if (!docTypeElements || docTypeElements.length <= 0) {
            return null;
          } else {
            const content = docTypeElements[0].innerHTML;

            if (content && content === 'DiagnosticServerFile') {
              return ScommFileType.LegacyServerFile;
            } else if (content && content === 'EcuIdFile') {
              return ScommFileType.EcuIdFile;
            } else if (content && content === 'ServerFile') {
              return ScommFileType.ServerFile;
            } else if (isSharedFile) {
              return ScommFileType.Other;
            } else {
              return ScommFileType.Other;
            }
          }
        }));
    }
  }

  getFileContentAsString(file: File): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const fileReader = new FileReader();
      fileReader.readAsText(file);
      fileReader.onloadend = () => {
        observer.next(fileReader.result.toString());
        observer.complete();
      };
    });
  }

  getBase64(file): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onloadend = () => {
        observer.next(btoa(fileReader.result.toString()));
        observer.complete();
      };
    });
  }
}
