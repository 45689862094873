import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { tap } from 'rxjs/operators';

import { DiagnosticFamilyModel, GenerationModel } from '../../app-model/server-identification/diagnostic-family.model';
import { DiagnosticFamilyService } from '../../app-services/diagnostic-family.service';
import { MessageService } from '../../modules/shared/services/message-service.service';


@Component({
  selector: 'app-diagnostic-family-generation',
  templateUrl: './generation.component.html',
  styleUrls: ['./generation.component.css']
})
export class GenerationComponent {

  @Input()
  generation: GenerationModel;

  @Input()
  diagnosticFamilyId: number;

  @Input()
  isAllowed: boolean;

  @Output()
  deleteGenerationEventEmitter: EventEmitter<GenerationModel> = new EventEmitter<GenerationModel>();

  constructor(private diagnosticFamilyService: DiagnosticFamilyService, private messageService: MessageService, private authService: AuthService) { }

  generationNameChanged(newName: string) {
    const oldName = this.generation.name;
    this.generation.name = newName;
    this.diagnosticFamilyService.updateDiagnosticFamilyGeneration(this.diagnosticFamilyId, this.generation as DiagnosticFamilyModel)
      .pipe(tap((data) => {
        this.generation = data.generations.find(x => x.id === this.generation.id);
      }, error => {
        this.generation.name = oldName;
        this.messageService.dispatchErrorMessageFromApi(error);
      })).subscribe();
  }

  deleteGeneration() {
    this.diagnosticFamilyService.deleteDiagnosticFamilyGeneration(this.diagnosticFamilyId, this.generation.id).pipe(tap((data) => {
      this.deleteGenerationEventEmitter.emit(data);
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    })).subscribe();
  }

}
