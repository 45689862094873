import { Observable } from 'rxjs';
import { EventEmitter } from '@angular/core';

export class ObservableExecutor<T> {

  onSuccessEventEmitter: EventEmitter<void> = new EventEmitter<void>();
  onErrorEventEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(private observableTasks: ObservableTask<T>[]) {
    this.observableTasks = observableTasks.reverse();
  }

  start() {
    this.executeTasks(this.observableTasks);
  }

  private executeTasks(observableTasks: ObservableTask<T>[]) {

    if (observableTasks.length === 0) {
      this.onSuccessEventEmitter.emit();
      return;
    }

    const observableTask = observableTasks.pop();

    observableTask.observable.subscribe(c => {
      observableTask.onSuccess(c);

      this.executeTasks(observableTasks);
    }, e => {
      this.onErrorEventEmitter.emit(e);
    });
  }
}

export class ObservableTask<T> {
  observable: Observable<T>;
  onSuccess: (arg0: T) => void;

  constructor(observable: Observable<T>, onSuccess: (arg0: T) => void) {
    this.observable = observable;
    this.onSuccess = onSuccess;
  }
}
