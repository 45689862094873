import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';

@Component({
  selector: 'app-substring-comparison-range',
  templateUrl: './substring-comparison-range.component.html',
  styleUrls: ['./substring-comparison-range.component.css']
})
export class SubstringComparisonRangeComponent {

  @Output()
  substringComparisonChanged: EventEmitter<void> = new EventEmitter();

  @Input()
  isReadOnly: boolean;

  @Input()
  startIndexEnabled: boolean;

  @Input()
  lengthEnabled: boolean;

  @Input()
  isAllowed: boolean;

  private _expressionModel: ServeridentificationExpressionModel;

  constructor() { }

  public get expressionModel(): ServeridentificationExpressionModel {
    return this._expressionModel;
  }

  @Input()
  public set expressionModel(v: ServeridentificationExpressionModel) {
    this._expressionModel = v;
  }

  notifySubstringComparisonChanged() {
    this.substringComparisonChanged.next();
  }

}
