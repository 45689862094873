import { Component, OnDestroy } from '@angular/core';
import { CategoryItem } from 'app/app-model/category-item';
import {
  ServeridentificationExpressionModel,
  ServerIdentificationModel,
} from 'app/app-model/server-identification/server-identification.model';
import { ServerIdentificationService } from 'app/app-services/server-identification.service';

import { Subscription } from '../../../../node_modules/rxjs';

@Component({
  template: ''
})
export class ServerIdentificationCategoryItemComponent extends CategoryItem implements OnDestroy {

  serverIdentificationService: ServerIdentificationService;
  isSyncingFromMaster = false;
  updateItemSubscription: Subscription;

  private _model: ServerIdentificationModel;
  public get model(): ServerIdentificationModel {
    return this._model;
  }
  public set model(v: ServerIdentificationModel) {
    this._model = v;
    this.syncFromModel();
  }

  constructor() {
    super();
  }

  public updateName() {
    if (this.model.diagnosticFamily && this.model.generation) {
      this.displayName = this.model.diagnosticFamily + '-' + this.model.generation;
    } else {
      this.displayName = 'Unknown Server Identification';
    }
  }

  public notifyServerIdentificationChanged(done: () => void = null) {
    if (!this.isSyncingFromMaster) {
      console.log('Server Identification changed ' + this.name + ' modified...Saving changes');
      this.updateItemSubscription = this.serverIdentificationService.updateItem(undefined, this.model).subscribe({
        next: (updatedOb) => {
          console.log('Server identification successfully changed');
          this._model = updatedOb;
          this.updateName();
          if (done) {
            done();
          }
        }
      }
      );
    }
  }

  public ngOnDestroy(): void {
    if (this.updateItemSubscription != null) {
      this.updateItemSubscription.unsubscribe();
    }
  }

  public hasValidExpressions(): boolean {
    let isValid = true;

    // Check if nodetype is a comparevalue
    if (this._model.expression) {
      if (this._model.expression.nodeType === 4 || this._model.expression.nodeType === 5) {
        // Check is expression has invalid values
        if (this._model.expression.compareValue) {
          if (this._model.expression.compareValue.data === '' || this._model.expression.legacyIdentifierCode === undefined && !this._model.expression.ecuIdentifierPropertyToCompareWith) {
            isValid = false;
          } else if (this._model.expression.subStringLength) {
            isValid = !isNaN(Number(this._model.expression.subStringLength));
          }
          if (this._model.expression.subStringStart && isValid) {
            isValid = !isNaN(Number(this._model.expression.subStringStart));
          }
        }

      }
      // Check all expressions belonging to this expression
      if (this._model.expression.expressions.length > 0) {
        this._model.expression.expressions.forEach(newExpression => {
          if (!this.checkInnerExpressions(newExpression)) {
            isValid = false;
          }
        });

      }
    }
    return isValid;
  }

  private checkInnerExpressions(expression: ServeridentificationExpressionModel): boolean {
    let isValid = true;
    if (!expression) {
      return;
    }
    // Check if nodetype is a comparevalue
    if (expression) {
      if (expression.nodeType === 4 || expression.nodeType === 5) {
        // Check is expression has invalid values
        if (expression.compareValue) {
          if (expression.compareValue.data === '' || expression.legacyIdentifierCode === undefined && !expression.ecuIdentifierPropertyToCompareWith) {
            isValid = false;
          } else if (expression.subStringLength) {
            isValid = !isNaN(Number(expression.subStringLength));
          }
          if (expression.subStringStart && isValid) {
            isValid = !isNaN(Number(expression.subStringStart));
          }
        }
      }
      // Check all expressions belonging to this expression
      if (expression.expressions.length > 0) {
        expression.expressions.forEach(newExpression => {
          if (!this.checkInnerExpressions(newExpression)) {
            isValid = false;
          }
        });
      }
    }
    return isValid;
  }

  private syncFromModel() {
    this.isSyncingFromMaster = true;
    this.id = this.model.id;
    this.updateName();
    this.isSyncingFromMaster = false;
  }
}
