<app-category-header [text]="'Specification Home'"></app-category-header>

<div class="sdds-row" *ngIf="specification && specificationVersion">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
        <button class="sdds-btn sdds-btn-primary sdds-btn-sm float-right ml-8"
            *ngIf="!currentSpecificationVersionIsLegacy && _specification.includeInScomm === true && specificationVersion.releaseStatus === 0"
            matTooltipPosition="below" (click)="validateServerFile();" [disabled]="!canValidateServerFile()">Validate
            server file</button>

        <button class="sdds-btn sdds-btn-primary sdds-btn-sm export-main-btn" (click)="exportWithMain();"
            [disabled]="!canEditCategoryItems()">Export with main</button>
    </div>
    <div>
        <h5 class="sdds-text-information mt-10" *ngIf="signCompleted">
            <p [ngClass]="{'sdds-text-information': signResult.success, 'sdds-text-negative': !signResult.success}">
                {{signResult.message.Message}}</p>
        </h5>
    </div>
    <div class="sdds-col-max-12 mb-16">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="homeTabChanged($event)"
            animationDuration="0ms">
            <mat-tab [label]="overviewTabLabel"></mat-tab>
            <mat-tab [label]="odxTabLabel" *ngIf="!isLegacyVersion && !isTemplateSpecification && !isNativeVersion"></mat-tab>
            <mat-tab [label]="settingsLabel" *ngIf="!isLegacyVersion && !isTemplateSpecification"></mat-tab>
            <mat-tab [label]="templateSettingsTabLabel" *ngIf="isTemplateSpecification"></mat-tab>
        </mat-tab-group>
    </div>
</div>

<ng-container *ngIf="versionView">
    <div class="sdds-row" *ngIf="specificationVersion && specification">
        <div *ngIf="specification"
            class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
            <app-specification-versions [specificationId]="specification.id"
                [selectedspecificationVersion]="specificationVersion"
                (specificationVersionSelectedEventEmitter)="selectedVersion=$event"
                (specificationVersionsEventEmitter)="specificationVersions = $event"></app-specification-versions>
        </div>

        <div
            class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
            <app-specification-version-details [specificationVersion]="specificationVersion">
            </app-specification-version-details>
        </div>
    </div>
</ng-container>

<div class="sdds-row" *ngIf="odxView && !isNativeVersion" [ngClass]="{'loading': loadingOdxFile}">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
        <app-import-view [importedODXFile]="odxFile" (importDone)="onImportDone($event)"></app-import-view>
    </div>

    <div *ngIf="!odxFile"
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
        <div class="sdds-banner sdds-banner-error">
            <div class="sdds-banner-body">
                No ODX file has been imported to this specification yet.
            </div>
        </div>
    </div>

    <div *ngIf="odxFile"
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mt-10">
        <h4>Current ODX file details</h4>
        <p>
            <b>File:</b><span *ngIf="odxFile">{{odxFile.fileName}}</span>
        </p>
        <p>
            <b>Size (KB)</b>&nbsp; : &nbsp;<span *ngIf="odxFile">{{ (odxFile.size / 1024) | floor }}</span>
        </p>
    </div>

    <div *ngIf="odxImportIssues "
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 odx-import-issue">
        <h5>ODX file issues from last import</h5>
        <p *ngIf="odxImportIssues.length === 0">
            No issues found!
        </p>
        <ul *ngIf="odxImportIssues.length>0" class="sdds-navbar-menu-list">
            <li [title]="getPathAsString(i.path)" *ngFor=" let i of odxImportIssues" class="sdds-navbar-menu-item">
                <i [ngClass]="{ 'icon-question icon-sm': i.tag === 1, 'icon-exclamation icon-sm': i.tag === 0}"></i>
                <span *ngIf="i.serviceName"><b>{{i.serviceName}}</b> - </span>{{i.message}}
            </li>
        </ul>
    </div>
</div>

<div *ngIf="specificaitonSettingsViewEnabled" class="ml-36">
    <h4>Specification Settings</h4>
        
    <span class="sdds-toggle" [ngClass]="{'disabled': !isEditingAllowed}" tabindex="0" style="width: 30%;">
        <input
            id="ivdOverrideToggle"
            type="checkbox" 
            class="sdds-toggle-input"
            [(ngModel)]="specificationVersion.ivdOverrideEnabled"
            (change)="onIvdOverrideToggle($event)">
        <span class="sdds-toggle-switch"></span>
        <label class="sdds-toggle-label" for="ivdOverrideToggle">Toggle IVD override for this specification version</label>
    </span>
    <p>(This setting allows the specification to override the default diagnostic services to perform Ivd requests.)</p>
</div>

<div *ngIf="templateSettingsView"
    class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mt-10">
    <h4>Template Settings</h4>

    <div class="sdds-banner">
        <div class="sdds-banner-body">
            <b>Warning!</b>
            <br />
            Make sure to use the correct ODX file when updating a template. Every diagnostic specification associated
            with this template will be affected
            by this change and can lead to breaking changes. Use with caution.
        </div>
    </div>

    <!-- <button class="sdds-btn sdds-btn-primary sdds-btn-sm export-main-btn" (click)="updateTemplateFromOdxFile();">Update template from ODX file</button> -->
    <label class="sdds-btn sdds-btn-primary sdds-btn-sm ml-14">
        Update template from ODX file
        <input #uploadOdxBtn class="hidden" id="fileAttach" type="file" accept=".odx-d"
            (change)="uploadTemplateAsOdxFile($event)">
    </label>
</div>

<!-- <ng-container *ngIf="userView">
    <app-specification-user [specification]="specification"></app-specification-user>
</ng-container> -->

<div class="sdds-row pl-0" *ngIf="userView">
    <!-- <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
        <h4>Upload a new ODX file to this specification version</h4>
    </div> -->

    <div>
        <app-specification-user [specification]="specification"></app-specification-user>
    </div>
</div>