/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum OperationalDataVariableType {
    AccumulatedScalar,
    MomentaryScalar,
    AccumulatedHistogram,
    AccumulatedHistogramWithDefault,
    MomentaryBarChart,
    MinMaxValueCollection,
    AccumulatedSlidingBarChart,
    AccumulatedMatrix,
    AccumulatedMatrixWithDefault,
    MomentaryMatrix
}

export enum OperationalDataAxisType {
    None,
    Open,
    Exact,
    Closed,
    UpwardsOpen,
    DownwardsOpen
}

export enum OperationalDataDefinitionSection {
    Columns,
    Rows,
    PeriodLength,
    PeriodUnit,
    XAxis,
    YAxis,
    Unit
}

export enum OperationalDataSection {
    Data,
    Breakpoints
}
