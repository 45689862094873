<div *ngIf="identificationGroup">

  <div *ngIf="!identificationGroup.expression">
    <div class="sdds-banner sdds-banner-info mb-10">
      <div class="sdds-banner-body">
        No expression exists.
      </div>
    </div>
  </div>
  <div>
  <app-create-expression-node *ngIf="canCreateExpressionNode" [legacyModeEnabled]="true" [isReadOnly]="isReadOnly"
    (createLegacyValueEquals)="addValueEqualsComparisonNode()" (createLegacyValueStartsWith)="addValueStartsWithComparisonNode()"
    (createLegacyValueContains)="addValueContainsComparisonNode()"
    (createCanAddressComparison)="addCanAddressComparisonNode()" (createOperator)="addOperator()"
    (createValueComparisonNodeEqualTo)="addValueComparisonNodeEqualTo()" (createValueComparisonNodeLessThan)="addValueComparisonNodeLessThan()"
    (createValueComparisonNodeLessThanOrEqualTo)="addValueComparisonNodeLessThanOrEqualTo()"
    (createValueComparisonNodeGreaterThan)="addValueComparisonNodeGreaterThan()"
    (createValueComparisonNodeGreaterThanOrEqualTo)="addValueComparisonNodeGreaterThanOrEqualTo()" [isAllowed]="isAllowed"></app-create-expression-node>
  <br />
  <br/>
  <app-expression-node-renderer *ngIf="identificationGroup.expression" [legacyModeEnabled]="true" [isReadOnly]="isReadOnly"
    [expressions]="[identificationGroup.expression]" [copyEnabled]="false" [reorderEnabled]="false" [isAllowed]="isAllowed">
  </app-expression-node-renderer> 
  </div>
  <app-move-expressions-confirm (accept)="onMoveExpressionsConfirm()"></app-move-expressions-confirm>

  <a #activateMoveExpressionConfirm id="moveExpressionsToOperatorModal"></a>
</div>
