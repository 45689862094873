import { FunctionBase } from 'app/modules/shared/components/function/function-base';
import { FunctionType } from 'app/modules/shared/components/function/functions';
import { BaseProperty, PropertyType } from 'app/modules/shared/model/properties/base-property';
import { InputProperty } from 'app/modules/shared/model/properties/input-property';

export class FunctionWrite extends FunctionBase {

  constructor(enabled: boolean, properties: Array<BaseProperty>) {
    super(enabled, properties);
    this.name = 'Write';
    this.propertyType = PropertyType.Target;
    this.type = FunctionType.Write;
  }

  addProperty(propertyName: string): void {
    const outputProperty = new InputProperty();
    outputProperty.name = propertyName;
    outputProperty.setServiceExecutionSequenceModelSilent(this.serviceExecutionSequence);
    this.properties.push(outputProperty);
  }

  syncFromModel(): void {
    this.properties.forEach(property => {
      property.setServiceExecutionSequenceModelSilent(this.serviceExecutionSequence);
    });
  }
}
