import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild {

  constructor( private router: Router) {}

  canActivate(): boolean {
    // Check if user has roles in the token
    const roles =   localStorage.getItem("currentUserRole"); 
    const token = localStorage.getItem("token")  
    if (roles === "null" && token === "null") {
      // If no roles, redirect to authorization failed route
      this.router.navigate(['/authorization-failed']);
      return false;
    }
    return true;
  }

  canActivateChild(): boolean  {
   return this.canActivate();
  }

}
