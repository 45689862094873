<app-message-handler></app-message-handler>
<sdds-theme name="scania"></sdds-theme>

<div *ngIf="loginDisplay" class="flex flex-col h-screen">
  <div>
    <app-header-inline-menu  [username]="username"  [userRole]="userRole"></app-header-inline-menu>
  </div>

  <div class="flex items-center bg-orange-100 border text-orange-700 px-4 py-3 mt-64" role="alert">
    <mat-icon>warning</mat-icon>
    <p class="ml-10">Starting from <b>2023-11-17</b>, only <b>released</b> specification versions will be used in production </p>
    <a class="visited:text-orange-700" href="https://teams.microsoft.com/l/message/19:d8099acb17524e28b5b60c2e40b6247e@thread.tacv2/1699014592020?tenantId=3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac&groupId=c041a2d0-6a69-4612-9731-9494e2e88a08&parentMessageId=1699014592020&teamName=SESAMM7%20Project%20Coordination&channelName=Onboarding%20Secure%20Update%20ECU%C2%B4s&createdTime=1699014592020">
      <mat-icon>open_in_new</mat-icon>
    </a>
  </div>

  <div class="grow h-5/6 overflow-y-auto mt-2">
    <app-main></app-main>
  </div>
  <div class="bg-black h-32 mt-2">
    <div class="flex justify-center">
      <span >
        <a class="text-white hover:text-white visited:text-white" href="https://scaniaazureservices.sharepoint.com/teams/SESAMM7projektkoordinering/_layouts/15/Doc.aspx?sourcedoc={3fb9a886-375e-4b97-939c-85021a5a4dd1}&action=edit&wd=target%28Onboarding%20Secure%20Update%20ECU%C2%B4s.one%7C01390e36-8409-4690-a6cd-b75a9cd43724%2FDCode%20and%20OAS_DCode%20and%20OAS%7Ca5b2f1bb-2cd6-4fee-bf20-393447e5b37d%2F%29&wdorigin=703">
          User Guide<mat-icon class="scale-50">open_in_new</mat-icon>
        </a>
      </span>
      <span>
        <a class="text-white hover:text-white visited:text-white ml-24" href="mailto:dcode.helpdesk@scania.com">
          Support<mat-icon class="scale-50">open_in_new</mat-icon>
        </a>
      </span>
    </div>
  </div>
</div>
