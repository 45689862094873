<sdds-modal size="md" selector="#buildLabelModal">
    <h5 slot="sdds-modal-headline">Add build label</h5>
    <div slot="sdds-modal-body">
        <div [ngClass]="{'loading': loading}">
            <div class="sdds-banner">
                <div class="sdds-banner-body">
                    When creating a build label, all new specifications/specification versions that have both the <strong>Main</strong> label and are marked for <strong>Release</strong> will get this label. A build label within DCODE will not affect the
                    build label for the production SCOMMDb build.
                </div>
            </div>
            <div class="sdds-on-white-bg my-10">
                <sdds-textfield type="text" size="md" ngDefaultControl [(ngModel)]="buildLabel" placeholder="Enter your prefered build name" label-inside="Label name"></sdds-textfield>
            </div>

            <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg my-10">
                <span class="sdds-dropdown-label-outside">Existing labels</span>
                <select id="allLabels" #select multiple style="height: 150px;">
                  <option *ngFor="let item of labels" [ngValue]="item"
                    [disabled]="true" class="cursor-default">{{item.name}}</option>
                </select>
            </div>

        </div>
    </div>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-primary sdds-btn-md mr-10" (click)="apply()" [disabled]="!buildLabel">Create Label</button>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md">Cancel</button>
</sdds-modal>
