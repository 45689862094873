import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { CategoryItem } from "app/app-model/category-item";
import { CategoryName } from 'app/app-model/enums';
import { IvdOverrideModel } from "app/app-model/ivd/ivd.model";
import { IvdOverrideService } from "app/app-services/ivd-override-service";
import { SpecificationService } from "app/app-services/specification-service";
import { PropertyType } from 'app/modules/shared/model/properties/base-property';
import { ServiceExecutionSequence } from "app/modules/shared/model/service-execution/service-execution-sequence";

export class IvdCategoryItem extends CategoryItem {

    propertyType: PropertyType = PropertyType.Both;

    private _model: IvdOverrideModel;
    public get model(): IvdOverrideModel {
        return this._model;
    }
    public set model(v: IvdOverrideModel) {
        this._model = v;
        this.syncFromModel();
    }

    private _serviceExecutionSequence: ServiceExecutionSequence;
    public get serviceExecutionSequence(): ServiceExecutionSequence {
        return this._serviceExecutionSequence;
    }
    public set serviceExecutionSequence(v: ServiceExecutionSequence) {
        this._serviceExecutionSequence = v;
    }

    private _ivdOverrideService: IvdOverrideService;
    public get ivdOverrideService(): IvdOverrideService {
        return this._ivdOverrideService;
    }
    public set ivdOverrideService(v: IvdOverrideService) {
        this._ivdOverrideService = v;
    }

    constructor(private specificationService: SpecificationService,
        private locationService: Location,
        private router: Router,
        private activatedRoute: ActivatedRoute,) {
        super();
        this.serviceExecutionSequence = new ServiceExecutionSequence()
        this.categoryType = CategoryName.IvdOverride;
    }

    notifyIvdOverrideChanged() {
        this.ivdOverrideService.updateItem(this.specificationService.currentSpecificationVersion.id, this.model)
            .subscribe(updatedIvdOverride => {
                this.model = updatedIvdOverride;
                const newUrl = this.router.createUrlTree([], {
                    relativeTo: this.activatedRoute,
                    queryParams: { itemId: this.model.name }
                }).toString();
                this.locationService.go(newUrl);
            });
    }

    private syncFromModel() {
        this.name = this.model.name;

        if (!this.isPreview && this.model.sequence) {
            this.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
            this.serviceExecutionSequence.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
            this.serviceExecutionSequence.setModel(this.model.sequence).subscribe(_ => {
                this.subscribeServiceExecutionModifiedEvents();
                this.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
            });
        }
    }

    private subscribeServiceExecutionModifiedEvents() {
        if (!this.hasModificationSubscriptions) {
            this._serviceExecutionSequence.modified.subscribe({
                next: () => this.notifyIvdOverrideChanged()
            });

            this.hasModificationSubscriptions = true;
        }
    }
}