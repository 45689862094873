import { Component, ViewChild, Input } from '@angular/core';
import { DcodeGraph } from '../model/graph/dcode-graph';
import * as mermaid from 'mermaid';
import { Router } from '../../../../../node_modules/@angular/router';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css']
})
export class GraphComponent {

  @ViewChild('graphContainer', { static: true }) input;
  _leftToRight = false;

  private _dcodeGraph: DcodeGraph;

  constructor(private router: Router) {

  }

  @Input()
  set dcodeGraph(value: DcodeGraph) {
    this._dcodeGraph = value;
    this.generateGraph();
  }

  @Input()
  set leftToRight(value: boolean) {
    this._leftToRight = value;
    this.generateGraph();
  }

  generateGraph() {
    if (!this._dcodeGraph) {
      return;
    }

    this.mermaid();
  }

  mermaid() {
    if (!this.input || !this._dcodeGraph) {
      return;
    }

    mermaid.default.initialize({
      startOnLoad: false,
      flowchart: {
        htmlLabels: true,
        curve: 'basis'
      }
    });

    const element = this.input.nativeElement;
    const insertSvg = (svgCode) => {
      element.innerHTML = svgCode;
    };

    let graphDefinition = this._leftToRight ? 'graph LR' : 'graph TB';

    this._dcodeGraph.nodes.forEach(x => {
      graphDefinition += '\n' + x.odxId + '(' + x.name + ')';
    });

    this._dcodeGraph.edges.forEach(x => {
      const id1 = this._dcodeGraph.nodes.find(n => n.name === x.source.name).odxId;
      const id2 = this._dcodeGraph.nodes.find(n => n.name === x.target.name).odxId;

      let links = '';

      x.links.forEach(l => {
        links += '<a style=\'background:white;\' onclick=\'event.preventDefault();\' href=\'#\' linkAttr=\'' + l.link + '\'>' + l.name + '</a><br/><br/>';
      });

      if (links !== '') {
        graphDefinition += '\n' + id1 + ' -- "' + links + '" --> ' + id2;
      } else {
        graphDefinition += '\n' + id1 + '--> ' + id2;
      }
    });

    const nodesWithEdges = [];

    this._dcodeGraph.edges.forEach(e => {
      if (!nodesWithEdges.find(x => x === e.source.odxId)) {
        nodesWithEdges.push(e.source.odxId);
      }

      if (!nodesWithEdges.find(x => x === e.target.odxId)) {
        nodesWithEdges.push(e.target.odxId);
      }
    });

    const nodesWithoutEdges = this._dcodeGraph.nodes.filter(x => !nodesWithEdges.find(y => y === x.odxId));
    graphDefinition += '\n';
    graphDefinition += 'classDef linkLessNode opacity: 0.5\n';
    nodesWithoutEdges.forEach(x => {
      graphDefinition += 'class ' + x.odxId + ' linkLessNode\n';
    });
    graphDefinition += 'classDef nodeType1 fill:#dff0d8,stroke:#3c763d,stroke-width:2px;\n';
    const startNodes = this._dcodeGraph.nodes.filter(x => x.type === 1);
    startNodes.forEach(x => {
      graphDefinition += 'class ' + x.odxId + ' nodeType1\n';
    });


    mermaid.default.mermaidAPI.render('graph' + Date.now() + Math.floor(Math.random() * 1000), graphDefinition);
  }

  containerClicked(event) {
    if (event && event.path && event.path[0].nodeName === 'A') {
      const aElement = event.path[0];
      const link = aElement.getAttribute('linkAttr');

      this.router.navigateByUrl(link);
    }
  }
}
