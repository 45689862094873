<div *ngIf="specificationVersion" class="sdds-row specification-version-details-wrapper">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <h4>Details</h4>
    </div>

    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <div class="sdds-row">
            <div
                class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-6">
                <p>
                    <b>Specification name</b><br>{{specification.name}}
                </p>
                <p>
                    <b>Specification version</b><br>{{specificationVersion.name}}
                </p>
                <p *ngIf="specificationVersion.releaseStatus === 1 && specificationVersion.releaseDate">
                    <b>Release date</b><br>{{specificationVersion.releaseDate | date: "yyyy-MM-dd HH:mm"}}
                </p>
                <p *ngIf="specificationVersion.specification.code">
                    <b>Specification GUID</b><br>{{specificationVersion.specification.code}}
                </p>
                <p
                    *ngIf="specificationVersion.specificationType !== legacyVersionEnum.Legacy && specificationVersion.specification.translationId">
                    <b>ODX Translation ID</b><br>{{specificationVersion.specification.translationId}}
                </p>
                <p>
                    <b>Specification Version Type</b><br>{{legacyVersionEnum[specificationVersion.specificationType]}}
                </p>
                <p>
                    <b>Specification protocol</b><br>{{diagnosticProtocol}}
                </p>
                <p>
                    <b>Server execution mode</b><br>{{serverExecutionMode}}
                </p>
                <p>
                    <b>Server identifications</b><br>
                    <span *ngFor="let l of serverIdentificationsPreview; trackBy: trackByServerIdentificationId">
                        <a [routerLink]="['/specification', specificationVersion.specification.id, specificationVersion.id, 'servers']"
                            *ngIf="l.diagnosticFamily && l.generation" [queryParams]="{itemId:l.id}">
                            {{l.diagnosticFamily}} {{l.generation}}
                            <mat-icon fontSet="material-icons-outlined" class="md-18">arrow_forward</mat-icon>
                        </a>
                        <a [routerLink]="['/specification', specificationVersion.specification.id, specificationVersion.id, 'servers']"
                            *ngIf="!l.diagnosticFamily || !l.generation" [queryParams]="{itemId:l.id}">
                            Unknown Server identification
                            <mat-icon fontSet="material-icons-outlined" class="md-18">arrow_forward</mat-icon>
                        </a>
                        <br>
                    </span>
                </p>
                <p>
                    <span>
                        <b>Rbacc File name</b><br>
                        <span *ngIf="rbacFile"> {{rbacFile.name}} </span>
                        <a *ngIf="rbacFile" (click) = "rbaccDetails(rbacFile.rbacGuid)"
                            matTooltip="Go to Rbacc File">
                            <mat-icon fontSet="material-icons-outlined" class="md-18">arrow_forward</mat-icon>
                        </a>
                        <span *ngIf="!rbacFile">None</span>
                    </span>
                </p>
            </div>

            <div
                class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-6">
                <p style="margin-left: -2px;">
                    <b>Branch labels</b><br>
                    <span *ngIf="currentBranchLabels.length !== 0">
                        <span *ngFor="let l of currentBranchLabels">
                            {{l.name}}
                        </span>
                    </span>
                    <span *ngIf="currentBranchLabels.length === 0">None</span>
                </p>
                <p style="margin-left: -17px;">
                    <sdds-accordion>
                        <sdds-accordion-item header="Build labels" affix="suffix" matIconSuffix="16">
                            <span *ngIf="currentBuildLabels.length !== 0">
                                <small *ngFor="let label of currentBuildLabels; let isLast=last">
                                    {{label.name}}{{isLast ? '' : ','}}
                                </small>
                            </span>
                            <span *ngIf="currentBuildLabels.length === 0">None</span>
                        </sdds-accordion-item>
                    </sdds-accordion>
                </p>
                <p>
                    <b>Include in Scomm</b><br>{{specification.includeInScomm ? 'Yes' : 'No'}}
                </p>

                <p [ngClass]="{'loading':loadingOdxFile}">
                    <b>ODX file</b><br>
                    <span *ngIf="!loadingOdxFile && odxFilePreview">
                        <span *ngIf="odxFilePreview">{{ odxFilePreview.fileName }}</span><br>
                        <button id="odxFileButton" class="sdds-btn sdds-btn-secondary sdds-btn-sm" type="button"
                            (click)="downloadOdx();">Download</button>
                    </span>
                    <span *ngIf="!odxFilePreview && !loadingOdxFile">None</span>
                </p>
                <p>
                    <b>Modifications</b> <br>
                    Changes for version {{specificationVersion.name}}<br>
                    <a [routerLink]="['./modification-table', specificationVersion.id]">
                        <mat-icon fontSet="material-icons-outlined" class="md-18">arrow_forward</mat-icon>
                    </a>
                </p>
                <p>
                    <span>
                        <b>DTC Record Number</b>
                        <app-editable-textbox [text]="dtcExtended" [isEditable]="!currentSpecificationVersionIsLegacy"
                            (textEditedEventEmitter)="updateDtc($event)"
                            [isAllowed]="canCreateDeleteSpecification()"></app-editable-textbox>
                        <!-- <p *ngIf="currentSpecificationVersionIsLegacy" class="w-2/3">{{dtcExtended}} </p> -->
                    </span>
                </p>
                <p>
                    <span class="w-2/3">
                        <b>Handle Negative Response Code 21</b>
                        <sdds-dropdown *ngIf="!currentSpecificationVersionIsLegacy && canCreateDeleteSpecification()"
                            size="small" placeholder="{{handleNegativeRes}}"
                            (selectOption)="updateHandleNegativeResponse($event)">
                            <sdds-dropdown-option value="true" class="hydrated">True</sdds-dropdown-option>
                            <sdds-dropdown-option value="false" class="hydrated">False</sdds-dropdown-option>
                        </sdds-dropdown>

                        <p *ngIf="currentSpecificationVersionIsLegacy||!canCreateDeleteSpecification()" class="w-2/3">
                            {{handleNegativeRes}} </p>
                    </span>
                </p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="specificationVersion" class="sdds-row specification-version-statistics-wrapper">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <h4>Statistics</h4>
    </div>

    <div *ngIf="loadingLabels || loadingOdxFile"
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <div [ngClass]="{'loading': dataCategoryStatistics.length <= 0}">
        </div>
    </div>

    <div *ngIf="!loadingLabels && !loadingOdxFile"
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th>Data category</th>
                    <th>No. of items</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let statisticsItem of dataCategoryStatistics">
                    <td>
                        <a
                            [routerLink]="['/specification', specificationVersion.specification.id, specificationVersion.id, getDataCategoryId(statisticsItem.categoryName)]">
                            {{statisticsItem.categoryName}}
                            <mat-icon fontSet="material-icons-outlined" class="md-18">arrow_forward</mat-icon>
                        </a>
                    </td>
                    <td>{{statisticsItem.count}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="specificationVersion" class="sdds-row specification-version-statistics-wrapper">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <h4>Comments</h4>
        <div *ngFor="let specificationVersion of getSpecificationVersionsReversed()">
            <div *ngIf="specificationVersion.comment"
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
                <b>V.{{specificationVersion.name}}: </b> {{specificationVersion.comment}} -
                <span *ngIf="specificationVersion.commentByUser"
                    [title]="writtenByText">{{specificationVersion.commentByUser }} </span>
            </div>
        </div>
    </div>
</div>