<div class="sdds-card mb-12" *ngFor="let serviceExecution of availableServiceExecutions">
    <div class="sdds-card-header headerPadding" *ngIf="serviceExecution.diagnosticService">
        <h4 class="sdds-card-headline">
            <div class="flex justify-items-center">
                <div class="flex-none w-auto">
                    <button class="sdds-btn sdds-btn-ghost sdds-btn-sm" (click)="toggleDisplay()">
                        <mat-icon fontSet="material-icons-outlined" class="md-16">zoom_out_map</mat-icon>
                    </button>
                </div>

                <div class="shrink w-4/5 break-all mt-8">
                    {{serviceExecution.diagnosticService.name}}
                </div>

                <div class="flex-none w-auto">
                    <button class="sdds-btn sdds-btn-ghost sdds-btn-sm"
                        (click)="nagivateToDiagnosticService(serviceExecution.diagnosticService.name)"
                        matTooltip="Navigate to diagnostic service definition">
                        <mat-icon fontSet="material-icons-outlined" class="md-18">arrow_forward</mat-icon>
                    </button>
                    <button class="sdds-btn sdds-btn-ghost sdds-btn-sm" *ngIf="!isReadOnly"
                        (click)="openDialog(serviceExecution)" matTooltip="Disconnect diagnostic service"
                        [disabled]="!canEditCategoryItems()">
                        <mat-icon fontSet="material-icons-outlined" class="md-18">delete_forever</mat-icon>
                    </button>

                    <button class="sdds-btn sdds-btn-ghost sdds-btn-sm" *ngIf="!isReadOnly"
                        (click)="serviceExecutionSequence.moveServiceExecutionUp(serviceExecution)" matTooltip="Move up"
                        [disabled]="!canEditCategoryItems()">
                        <mat-icon fontSet="material-icons-outlined" class="md-18">north</mat-icon>
                    </button>

                    <button class="sdds-btn sdds-btn-ghost sdds-btn-sm" *ngIf="!isReadOnly"
                        (click)="serviceExecutionSequence.moveServiceExecutionDown(serviceExecution)"
                        matTooltip="Move down" [disabled]="!canEditCategoryItems()">
                        <mat-icon fontSet="material-icons-outlined" class="md-18">south</mat-icon>
                    </button>
                </div>
            </div>
        </h4>
    </div>

    <div class="sdds-card-body mt-16" *ngIf="!isShow && serviceExecution.diagnosticService"
        [id]="serviceExecution.diagnosticService.name">
        <div class="sdds-row">
            <div *ngIf="!hasRequestPropertyParameters(serviceExecution) && !hasResponsePropertyParameters(serviceExecution)"
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div class="sdds-banner sdds-banner-info">
                    <div class="sdds-banner-body">
                        No value parameters available in diagnostic service
                    </div>
                </div>
            </div>

            <div *ngIf="hasRequestPropertyParameters(serviceExecution)"
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <h5>Request Parameters</h5>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Data Type</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let propertyParam of serviceExecution.availableRequestPropertyParameters">
                            <td>{{propertyParam.parameter.model.name}}</td>

                            <td *ngIf="propertyParam.parameter.model.scaling">
                                {{propertyParam.parameter.getPhysicalDataType()}}</td>

                            <td
                                *ngIf="!hasPropertyOwner(propertyParam) && propertyParam.parameter.hasInternalToPhysicalComputationalMethod()">
                                <div [ngClass]="{'is-invalid': !propertyParam.isValidValue}">
                                    <select [(ngModel)]="propertyParam.assignedValue.data"
                                        (change)="onParameterValueSelected(propertyParam, serviceExecution)"
                                        [disabled]="assignValueToParameterDisabled(propertyParam) || isReadOnly === true">
                                        <option disabled active [disabled]="getUserRole()"></option>
                                        <option
                                            *ngFor="let value of propertyParam.parameter.getInternalToPhysicalComputationalMethodValues()"
                                            [ngValue]="value">{{value}}
                                        </option>
                                    </select>
                                </div>
                            </td>

                            <td
                                *ngIf="!hasPropertyOwner(propertyParam) && !propertyParam.parameter.hasInternalToPhysicalComputationalMethod()">
                                <div>
                                    <mat-form-field>
                                        <mat-label>Parameter Value</mat-label>
                                        <input type="text" matInput placeholder="Type a value"
                                            [(ngModel)]="propertyParam.assignedValue.data"
                                            (change)="onParameterValueSelected(propertyParam, serviceExecution)"
                                            [disabled]="assignValueToParameterDisabled(propertyParam) || isReadOnly === true||getUserRole()">
                                        <mat-error *ngIf="!propertyParam.isValidValue">
                                            Invalid parameter value
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </td>

                            <td *ngIf="hasPropertyOwner(propertyParam)">
                                <b *ngIf="hasPropertyOwner(propertyParam)">From Property: </b>
                                <b *ngIf="hasPropertyOwner(propertyParam)">
                                    {{getPropertyOwnersFormated(propertyParam)}}
                                </b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="hasResponsePropertyParameters(serviceExecution)"
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <h5>Response Parameters</h5>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Data Type</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let propertyParam of serviceExecution.availableResponsePropertyParameters">
                            <td>{{propertyParam.parameter.model.name}}</td>

                            <td *ngIf="propertyParam.parameter.model.scaling">
                                {{propertyParam.parameter.getPhysicalDataType()}}</td>

                            <td>
                                <b *ngIf="hasPropertyOwner(propertyParam)">To Property(s): </b>
                                <b *ngIf="hasPropertyOwner(propertyParam)">
                                    {{getPropertyOwnersFormated(propertyParam)}}
                                </b>
                                <div *ngIf="!hasPropertyOwner(propertyParam)">Discarded</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
