<div *ngIf="loading" class="loader-container">
    <div class="loader"></div>
</div>


<!-- <div class="sdds-row" [ngClass]="{'loading':loading}"> -->
    <div class="sdds-row" >
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <h4>Versions <span *ngIf="specificationName">of {{specificationName}}</span></h4>
    </div>

    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <app-toolbar addDataTarget="#createNewVersionModal" [addButtonText]="'Add version'"
            upgradeDataTarget="#upgradeVersionModal" [upgradeButtonText]="'Upgrade version'"
            [upgradeVersion]="versionIsLegacy" [dataTargetEnabledRemove]="false"
            [createVersion]="true"  [createButtonId]="'createNewVersionModal'"
            [upgradeButtonId]="'upgradeVersionModal'" [filterEnabled]="true" (filterTextEventEmitter)="filter($event);" [isAllowed]="canEditCategoryItems()">
        </app-toolbar>
    </div>

    <div *ngIf="versionTree && versionTree.isEmpty"
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <span>The are no specification versions. Add a new specification version to proceed.</span>
    </div>

    <div *ngIf="versionTree && !versionTree.isEmpty"
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <app-tree [tree]="versionTree"></app-tree>
    </div>
</div>

<app-create-new-version [anyInWorkVersions]="anyInWorkVersions" [versions]="versions" [specification]="specification"
    [basedOnVersion]="selectedSpecificationVersion" (versionCreatedEventEmitter)="newVersionCreated($event);">
</app-create-new-version>

<app-upgrade-version-modal [anyInWorkVersions]="anyInWorkVersions" [basedOnVersion]="selectedSpecificationVersion"
    (versionCreatedEventEmitter)="newVersionCreated($event);"></app-upgrade-version-modal>

<a id="areYouSureModal" appOpenModal [open]="openModalEventEmitter" style="display: none"></a>

<app-confirm-dialogue [message]="doYouWantToDeleteMessage" (yesEventEmitter)="deleteStoredSpecificationVersion();">
</app-confirm-dialogue>

<app-version-release [showEventEmitter]="showReleaseEventEmitter" (modalClosing)="onAppReleaseClose()">
</app-version-release>

<button id="versionReleaseModal" #versionReleaseModalRef style="display: none;"></button>