import { Component, Input, OnInit } from '@angular/core';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';
import { IdentificationExpressionService } from 'app/app-services/identification-expression.service';

@Component({
  selector: 'app-special-comparison',
  templateUrl: './special-comparison.component.html',
  styleUrls: ['./special-comparison.component.css']
})
export class SpecialComparisonComponent {

  @Input()
  isReadOnly: boolean;

  @Input()
  reorderEnabled = true;

  @Input()
  copyEnabled = true;

  @Input()
  isAllowed :boolean;

  private _expressionModel: ServeridentificationExpressionModel;
  public get expressionModel(): ServeridentificationExpressionModel {
    return this._expressionModel;
  }

  @Input()
  public set expressionModel(v: ServeridentificationExpressionModel) {
    this._expressionModel = v;
  }

  public get specialValue(): string {
    return this._expressionModel.compareValue.data;
  }
  public set specialValue(v: string) {
    this._expressionModel.compareValue.data = v;
  }

  constructor(private identificationExpressionService: IdentificationExpressionService) { }

  notifyServerIdentificationChanged() {
    this.identificationExpressionService.modified.next(this.expressionModel);
  }

}
