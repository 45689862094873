import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-remove-draft-confirm',
  templateUrl: './remove-draft-confirm.component.html',
  styleUrls: ['./remove-draft-confirm.component.css']
})
export class RemoveDraftConfirmComponent {

  @Output()
  accept: EventEmitter<any> = new EventEmitter();

  apply() {
    this.accept.emit();
  }

  cancel() {

  }
}
