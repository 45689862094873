import { Injectable } from '@angular/core';
import { Specification } from 'app/app-model/specification';
import { StructureEcuFamily } from 'app/app-model/structure/structure-ecu-family';
import { StructureEcuGeneration } from 'app/app-model/structure/structure-ecu-generation';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiProxyService } from '../modules/shared/services/api-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class StructureService {

  constructor(private apiProxy: ApiProxyService) {
  }

  public getStructure(): Observable<StructureEcuFamily[]> {

    // Make the HTTP request:
    return this.apiProxy.get<StructureEcuFamily[]>(environment.apiUrl + 'SpecificationStructure').pipe(map(s => {
      const families = s['families'];

      const dp = s['disconnectedSpecifications'];

      if (dp != null && dp.length > 0) {
        const uf = new StructureEcuFamily();
        uf.name = 'Missing a server';

        const ug = new StructureEcuGeneration();
        ug.name = 'Missing a server';

        uf.generations.push(ug);

        dp.forEach(element => {
          ug.specifications.push(element);
        });

        families.push(uf);
      }

      return families;
    }));
  }

  public getTemplates(): Observable<Specification[]> {
    return this.apiProxy.get<Specification[]>(`${environment.apiUrl}specificationstructure/templates`);
  }
}
