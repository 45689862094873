import { Injectable } from '@angular/core';
import { SpecificationVersion } from '../app-model/specification-version';



@Injectable({
  providedIn: 'root'
})
export class SpecificationStatusService {

  notAllowedInWorkText = 'Operation is not allowed when specification version is in work status.';
  notAllowedInReleaseText = 'Operation is not allowed when specification version is in release status.';

  constructor() { }

  isInWork(specificationVersion: SpecificationVersion): boolean {
    if (!specificationVersion) {
      return false;
    }

    return specificationVersion.releaseStatus === 0;
  }

  isInRelease(specificationVersion: SpecificationVersion): boolean {
    if (!specificationVersion) {
      return true;
    }

    return specificationVersion.releaseStatus === 1;
  }

}
