<app-dialog-base modalId="createNewVersionModal" title="Create new version" [itemTemplate]="createNewVersionTemplate" [footerTemplate]="createNewVersionFooter"></app-dialog-base>

<ng-template #createNewVersionTemplate>
    <ng-container *ngIf="versions">
        <div class="sdds-banner">
            <div class="sdds-banner-body">
                <ng-container *ngIf="!anyInWorkVersions">
                    <p *ngIf="basedOnVersion">The new version will be based on version {{basedOnVersion.name}}.</p>
                    <p>Click on the create button to create the new version.</p>
                </ng-container>

                <ng-container *ngIf="anyInWorkVersions">
                    There are currently in work versions of this specification. There can only be one in work version at a time, hence a new version cannot be created.
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #createNewVersionFooter>
    <ng-container *ngIf="!anyInWorkVersions">
        <button slot="sdds-modal-actions"   data-dismiss-modal  class="sdds-btn sdds-btn-primary sdds-btn-md mr-12" (click)="apply()" [disabled]="loading" [close]="modalClosing">Create</button>
        <button slot="sdds-modal-actions"   data-dismiss-modal  class="sdds-btn sdds-btn-secondary sdds-btn-md" (click)="cancel()" [disabled]="loading">Cancel</button>
    </ng-container>

    <ng-container *ngIf="anyInWorkVersions">
        <button slot="sdds-modal-actions"   data-dismiss-modal  class="sdds-btn sdds-btn-primary sdds-btn-md" (click)="cancel()" [disabled]="loading">Ok</button>
    </ng-container>

    <button slot="sdds-modal-actions"   data-dismiss-modal  class="sdds-btn sdds-btn-secondary sdds-btn-md hide-element" style="display: none;" appCloseModal [close]="modalClosing"></button>
</ng-template>