import { Pipe, PipeTransform } from '@angular/core';
import { GenerationModel } from 'app/app-model/server-identification/diagnostic-family.model';

@Pipe({
  name: 'orderedGenerations'
})
export class OrderedGenerationsPipe implements PipeTransform {

  transform(generations: Array<GenerationModel>, args?: string): any {
    generations.sort((generationA: GenerationModel, generationB: GenerationModel) => {
      if (generationA.id < generationB.id) {
        return 1;
      } else if (generationA.id > generationB.id) {
        return -1;
      } else {
        return 0;
      }
    });

    return generations;
  }

}
