import { MatSnackBarRef } from '@angular/material/snack-bar';
import { OdxFile } from 'app/app-model/odx-import/odx-file';
import { OdxLayer } from 'app/app-model/odx-import/odx-import';
import { OdxImportIssue } from 'app/app-model/odx-import/odx-import-issue';
import { IncomingOdxFile, OdxUpdate } from 'app/app-model/odx-import/odx-update';
import { SpecificationStatusHubService } from 'app/app-services/hubs/specification-status-hub.service';
import { OdxService } from 'app/app-services/odx-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { of, Subject, zip } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { MessageTemplateComponent } from '../message-handler/message-templates/message-templates.component';

export class OdxImportViewModel {
  odxLayer: OdxLayer;
  odxFile: OdxFile;
  loading: boolean;
  statusMessageToken: MatSnackBarRef<MessageTemplateComponent>;
  currentPage: number;
  messageService: MessageService;
  odxService: OdxService;
  specificationService: SpecificationService;
  specificationStatusHubService: SpecificationStatusHubService;
  pageSize = 4;
  pages: Array<number>;
  pageItems: OdxImportIssue[];
  importDone: Subject<OdxFile> = new Subject<OdxFile>();
  importDoneOdxIssues: Subject<OdxImportIssue[]> = new Subject<OdxImportIssue[]>();
  odxUpdate: OdxUpdate;

  incomingOdxFile: IncomingOdxFile;

  importDefaultOdxLayerToSpecificationVersion() {
    this.statusMessageToken = this.messageService.dispatchInfoMessage('Uploading Odx file to specification version...', true);
    this.loading = true;
    const versionId = this.specificationService.currentSpecificationVersion.id;

    const importOdxFileObservable = this.odxService.createOdxFile(this.odxFile)
      .pipe(
        tap(fileId => { this.odxFile.id = fileId; }),
        switchMap(odxFileId => {
          return zip(of(odxFileId), this.odxService.getOdxFileLayers(this.odxFile.id));
        }),
        switchMap(([odxFileId, odxLayers]) => {
          return zip(of(odxLayers), this.odxService.getOdxFileLayer(odxFileId, odxLayers[0].shortName));
        }),
        switchMap(([odxLayerInfo, odxLayer]) => {
          return this.specificationService.updateSpecificationVersionWithOdxImport(versionId, odxLayer);
        })
      );

    importOdxFileObservable.subscribe(_ => {
      this.loading = false;
      this.messageService.dispatchSuccessMessage('Odx file successfully uploaded.');
      location.reload();
    }, err => {
      this.loading = false;
      this.messageService.dispatchErrorMessageFromApi(err);
    });
  }

  prepareLayerIssues() {
    this.currentPage = 1;
    this.pages = new Array<number>();

    if (this.odxLayer && this.odxLayer.importIssues) {
      let pageCount: number = Math.floor(this.odxLayer.importIssues.length / this.pageSize);
      if (this.odxLayer.importIssues.length % this.pageSize !== 0) {
        pageCount++;
      }
      for (let i = 1; i < pageCount + 1; i++) {
        this.pages.push(i);
      }
    }
    this.refreshLayerIssues();
  }

  refreshLayerIssues() {
    if (!this.odxLayer || !this.odxLayer.importIssues) {
      this.pageItems = new Array<OdxImportIssue>();
    } else {
      this.pageItems = this.odxLayer.importIssues.slice((this.currentPage - 1) * this.pageSize, (this.currentPage) * this.pageSize);
    }
  }

  setIssuePage(page: number) {
    this.currentPage = page;
    this.refreshLayerIssues();
  }

  setOdxFile(file: OdxFile) {
    this.odxFile = file;
    this.incomingOdxFile = { fileName: file.fileName, size: file.size, data: file.data };
    this.odxUpdate = {
      incomingOdxFile: this.incomingOdxFile,
      specificationVersionId: this.specificationService.currentSpecificationVersion.id
    };
  }

  reset() {
    this.prepareLayerIssues();
  }

  closeLatestStatusMessage() {
    if (this.statusMessageToken) {
      this.statusMessageToken.dismiss();
    }
  }
}
