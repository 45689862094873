import { Location } from '@angular/common';
import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { CategoryName } from 'app/app-model/enums';
import { IoService } from 'app/app-services/io.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { FunctionBase } from 'app/modules/shared/components/function/function-base';
import { FunctionControl } from 'app/modules/shared/components/function/function-control';
import { FunctionRead } from 'app/modules/shared/components/function/function-read';
import { FunctionReset } from 'app/modules/shared/components/function/function-reset';
import { FunctionItem } from 'app/modules/shared/components/function/function.item';
import { FunctionType } from 'app/modules/shared/components/function/functions';

@Directive()
export class IoCategoryItemDirective extends FunctionItem implements OnDestroy {

  @Input()
  isLoading = true;

  @Output() ioModelEditingEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  ioService: IoService;
  hasSyncDoneSubscriptions = false;
  oldName = '';
  serviceName = '';

  constructor(private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
    super.categoryType = CategoryName.Io;
  }

  ngOnDestroy(): void {
    super.unsubscribe();
  }


  public setIsSyncingFromMasterState() {
    this.isSyncingFromMaster = true;
    this.isSyncControlDone = false;
    this.isSyncReadDone = false;
    this.isSyncResetDone = false;
  }

  public createFunctions() {
    if (!this.functions || this.functions.length === 0) {

      const readFunction = new FunctionRead(false, []);
      readFunction.addProperty(this.getPropertyNameForFunctionType(FunctionType.Read));
      readFunction.serviceExecutionSequence.modified.subscribe(val => {
        this.notifyItemChanged();
      });

      const controlFunction = new FunctionControl(false, []);
      controlFunction.addProperty(this.getPropertyNameForFunctionType(FunctionType.Control));
      controlFunction.serviceExecutionSequence.modified.subscribe(val => {
        this.notifyItemChanged();
      });

      const resetFunction = new FunctionReset(false, []);
      resetFunction.addProperty(this.getPropertyNameForFunctionType(FunctionType.Reset));
      resetFunction.serviceExecutionSequence.modified.subscribe(val => {
        this.notifyItemChanged();
      });

      this.functions = [readFunction, controlFunction, resetFunction];
    }
  }

  public createProperties(func: FunctionBase) {
    func.properties = [];
    func.addProperty(this.getPropertyNameForFunctionType(func.type));
  }

  public notifyOnEditingEnabled() {
    this.ioModelEditingEventEmitter.emit();
  }

  public setName(name: string) {
    if (!this.model) {
      return;
    }

    this.oldName = this.model.name;
    this.name = name;
    this.model.name = name;
    this.notifyItemChanged();
  }

  public notifyItemChanged() {
    if (!this.isSyncingFromMaster) {
      console.log('IO ' + this.name + ' modified...Saving changes');
      this.ioService.updateItem(this.specificationService.currentSpecificationVersion.id, this.model, this.oldName)
        .subscribe(updatedIo => {
          this.model = updatedIo;

          const newUrl = this.router.createUrlTree([], {
            relativeTo: this.activatedRoute,
            queryParams: { itemId: this.model.name }
          }).toString();
          this.locationService.go(newUrl);
          this.getServiceName();
          console.log('IO' + this.name + ' successfully updated');
        }, err => {
        });
    }
  }

  public getServiceName() {
    if (this.model.readSequence &&
      this.model.readSequence.executions &&
      this.model.readSequence.executions.length > 0 &&
      this.model.readSequence.executions[0]) {
      this.serviceName = this.model.readSequence.executions[0].service;
      this.fetchEcuIdentifierInUse(this.specificationVersionId, this.serviceName);
    }

 else   if (this.model.controlSequence &&
      this.model.controlSequence.executions &&
      this.model.controlSequence.executions.length > 0 &&
      this.model.controlSequence.executions[0]) {
      this.serviceName = this.model.controlSequence.executions[0].service;
      this.fetchEcuIdentifierInUse(this.specificationVersionId, this.serviceName);
    }

  else  if (this.model.resetSequence &&
      this.model.resetSequence.executions &&
      this.model.resetSequence.executions.length > 0 &&
      this.model.resetSequence.executions[0]) {
      this.serviceName = this.model.resetSequence.executions[0].service;
      this.fetchEcuIdentifierInUse(this.specificationVersionId, this.serviceName)
    }
    else
    {
      this.fetchEcuIdentifierInUse(this.specificationVersionId);
    }

  }

  public getPropertyNameForFunctionType(functionType: FunctionType): string {
    switch (functionType) {
      case FunctionType.Control:
        return 'ControlIO Value';
      case FunctionType.Read:
        return 'ReadIO Value';
      case FunctionType.Reset:
        return 'ResetIO Value';
      default:
        return this.defaultPropertyName;
    }
  }

  protected syncFromModel() {
    this.setIsSyncingFromMasterState();
    this.id = this.model.id;
    this.name = this.model.name;
    this.oldName = this.name;
    this.isLoading = true;
    this.createFunctions();
    if (this.specificationService.currentSpecification) {
      this.syncSequences(this.specificationService.currentSpecificationVersion.id).subscribe(_ => {
        this.updateSyncingFromMasterState();
        this.selectDefaultFunction();
        super.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
        this.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
        this.getServiceName();
      });
    }
  }

  protected updateSyncingFromMasterState() {
    this.isSyncingFromMaster = false;
    this.isLoading = false;
  }

  protected createServiceExecutionSequenceIfNeeded(functionType: FunctionType, func: FunctionBase) {
    switch (functionType) {
      case FunctionType.Read:
        if (this.model.readSequence) {
          break;
        }
        this.model.readSequence = new ServiceExecutionSequenceModel();
        func.serviceExecutionSequence.model = this.model.readSequence;
        this.isSyncReadDone = true;
        break;
      case FunctionType.Control:
        if (this.model.controlSequence) {
          break;
        }
        this.model.controlSequence = new ServiceExecutionSequenceModel();
        func.serviceExecutionSequence.model = this.model.controlSequence;
        this.isSyncControlDone = true;
        break;
      case FunctionType.Reset:
        if (this.model.resetSequence) {
          break;
        }
        this.model.resetSequence = new ServiceExecutionSequenceModel();
        func.serviceExecutionSequence.model = this.model.resetSequence;
        this.isSyncResetDone = true;
        break;
      default:
        break;
    }
  }

  protected subscribeDefaultServcieExecutionSequenceEvents() {
    if (!this.hasSyncDoneSubscriptions) {
      this._serviceExecutionSequence.syncDone.subscribe(args => {
        this.subscribeServiceExecutionModifiedEvents();
        this.isSyncingFromMaster = false;
      });
      this.hasSyncDoneSubscriptions = true;
    }
  }
}
