<div class="form-group">
  <b>Functions</b>
</div>

<div class="sdds-checkbox-item" *ngIf="canSetRead">
  <input class="sdds-form-input" type="checkbox" id="readFunction" value="Read" [(ngModel)]="readFlag"
    [disabled]="isReadOnly" (change)="readFlagChanged.next(readFlag)" matTooltip="Enable or disable Read function"
    [disabled]="!canEditCategoryItems()">
  <label class="sdds-form-label">Read </label>
</div>

<div class="sdds-checkbox-item" *ngIf="canSetControl">
  <input class="sdds-form-input" type="checkbox" id="controlFunction" value="Control" [(ngModel)]="controlFlag"
    [disabled]="isReadOnly" (change)="controlFlagChanged.next(controlFlag)"
    matTooltip="Enable or disable Control function" [disabled]="!canEditCategoryItems()">
  <label class="sdds-form-label">Control</label>
</div>

<div class="sdds-checkbox-item" *ngIf="canSetReset">
  <input class="sdds-form-input" type="checkbox" id="resetFunction" value="Reset" [(ngModel)]="resetFlag"
    [disabled]="isReadOnly" (change)="resetFlagChanged.next(resetFlag)" matTooltip="Enable or disable Reset function"
    [disabled]="!canEditCategoryItems()">
  <label class="sdds-form-label"> Reset </label>
</div>

<div class="sdds-checkbox-item" *ngIf="canSetWrite">
  <input class="sdds-form-input" type="checkbox" id="writeFunction" value="Write" [(ngModel)]="writeFlag"
    [disabled]="isReadOnly" (change)="writeFlagChanged.next(writeFlag)" matTooltip="Enable or disable write function"
    [disabled]="!canEditCategoryItems()">
  <label class="sdds-form-label"> Write </label>
</div>