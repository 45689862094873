/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ScommFileType, SharedFile } from 'app/app-model/legacy-file';
import { SharedFilesEventsService } from 'app/app-services/shared-files-events.service';
import { SharedFilesService } from 'app/app-services/shared-files.service';
import { SpecificationLegacyFiles } from 'app/app-services/specification-legacy-files.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { ToolbarComponent } from 'app/modules/shared/components/toolbar/toolbar.component';
import { SharedFileTreeNode } from 'app/modules/shared/components/tree/model/shared-file-tree.node';
import { Tree } from 'app/modules/shared/components/tree/model/tree';
import { TreeService } from 'app/modules/shared/components/tree/service/tree.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

// eslint-disable-next-line no-shadow
export enum SharedFilesFilterOptions {
  All,
  Other,
  Keyfile
}

@Component({
  selector: 'app-shared-files-assigner',
  templateUrl: './shared-files-assigner.component.html',
  styleUrls: ['./shared-files-assigner.component.css']
})
export class SharedFilesAssignerComponent implements OnInit, OnDestroy {
  @ViewChild('toolbar', { static: true }) toolbar: ToolbarComponent;

  @Input()
  filterOptions = SharedFilesFilterOptions.All;

  fileTree: Tree;
  filesToAssignTree: Tree;
  loading: boolean;
  hasFileSelected: boolean;
  hasFileToAssignSelected: boolean;
  selectedSharedFileSpecification: SharedFile;
  selectedAssignedSharedFileSpecification: SharedFile;
  fileTreeService = new TreeService();
  assignedFileTreeService = new TreeService();
  selectedTreeNodeSubscription: Subscription;
  sharedFileRefsSubscription: Subscription;
  sharedFileSpecsSubscription: Subscription;
  selectedSpecificationSubscription: Subscription;

  constructor(private sharedFilesService: SharedFilesService,
    private sharedFilesEventsService: SharedFilesEventsService,
    private specificationLegacyFiles: SpecificationLegacyFiles,
    private specificationService: SpecificationService) { }

  ngOnInit() {
    this.sharedFilesEventsService.referencedSharedFilesRemoved.subscribe({
      next: () => {
        this.updateAvailableFiles();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sharedFileRefsSubscription) {
      this.sharedFileRefsSubscription.unsubscribe();
    }

    if (this.sharedFileSpecsSubscription) {
      this.sharedFileSpecsSubscription.unsubscribe();
    }

    if (this.selectedSpecificationSubscription) {
      this.selectedSpecificationSubscription.unsubscribe();
    }
  }

  get assignTreeDoesNotContainFile() {
    return !this.canAssignAnotherFile();
  }
  canAssignAnotherFile() {
    let hasBinFile = false;

    if (this.filesToAssignTree.rootNodes) {
      this.filesToAssignTree.rootNodes.forEach(file => {
        if (file.name.toLowerCase().endsWith('bin')) {
          hasBinFile = true;
        }
      });

      if (!hasBinFile) {
        return true;
      }
      if (this.filesToAssignTree.rootNodes.length > 0 && hasBinFile) {
        return false;
      }
    }
    return true;
  }

  assignSelectedFile() {
    this.createFileSpecificationToAssignTreeNode(this.selectedSharedFileSpecification);
    this.removeNodeFromFilesTree(this.selectedSharedFileSpecification);
    this.selectedSharedFileSpecification = null;
  }

  unAssignSelectedFile() {
    this.removeNodeFromFilesToAssignTree(this.selectedAssignedSharedFileSpecification);
    this.createFileSpecificationTreeNode(this.selectedAssignedSharedFileSpecification);
    this.selectedAssignedSharedFileSpecification = null;
    this.sortTree(this.fileTree);
  }

  removeNodeFromFilesTree(fileSpecification: SharedFile) {
    // Remove node from the available file specifications tree.
    const indexOfNodeToRemove = this.fileTree.rootNodes.findIndex(node => (node as SharedFileTreeNode).specificationFile === fileSpecification);
    this.fileTree.rootNodes.splice(indexOfNodeToRemove, 1);
  }

  removeNodeFromFilesToAssignTree(fileSpecification: SharedFile) {
    // Remove node from the available file specifications tree.
    const indexOfNodeToRemove = this.filesToAssignTree.rootNodes.findIndex(node => (node as SharedFileTreeNode).specificationFile === fileSpecification);
    this.filesToAssignTree.rootNodes.splice(indexOfNodeToRemove, 1);
  }

  public updateAvailableFiles() {
    this.loading = true;
    this.sharedFileSpecsSubscription = this.sharedFilesService.getSharedFileSpecifications().pipe(tap(files => {
      this.buildFileTree(files);
      this.sortTree(this.fileTree);
      this.updateAssignedFiles();
    })).subscribe();
  }
  sortTree(fileTree: Tree): any {
    fileTree.rootNodes.sort((a, b) => a.name.localeCompare(b.name));
  }

  updateAssignedFiles() {
    const specificationVersion = this.specificationService.currentSpecificationVersion;
    if (specificationVersion) {
      this.sharedFileRefsSubscription = this.specificationLegacyFiles.getSpecificationScommFilesWithSharedFileRefs(specificationVersion.id).subscribe({
        next: (assignedFiles) => {
          if (assignedFiles) {
            assignedFiles.forEach(assignedFile => {
              const sharedFileNode = this.fileTree.rootNodes.find(node => (node as SharedFileTreeNode).specificationFile.id === assignedFile.sharedFileId);

              if (sharedFileNode) {
                this.fileTree.rootNodes.splice(this.fileTree.rootNodes.indexOf(sharedFileNode), 1);
              }
            });
          }
        }
      });
    }
  }

  buildFileTree(files: SharedFile[]) {
    this.fileTree = new Tree(this.fileTreeService);
    this.filesToAssignTree = new Tree(this.assignedFileTreeService);

    let filesToInclude = files;

    switch (this.filterOptions) {
      case SharedFilesFilterOptions.Keyfile:
        filesToInclude = files.filter(file => file.fileType === ScommFileType.KeyFile).sort((fileA, fileB) => fileA.name.localeCompare(fileB.name));
        break;
      case SharedFilesFilterOptions.Other:
        filesToInclude = files.filter(file => file.fileType === ScommFileType.Other).sort((fileA, fileB) => fileA.name.localeCompare(fileB.name));
        break;
    }

    const treeNodes = filesToInclude.map(x =>
      new SharedFileTreeNode(x, this.fileTreeService, this.specificationService, this.sharedFilesService,
        (specificationIds => { }), false));
    treeNodes.forEach(x => {
      this.fileTree.addRootNode(x);
    });

    this.updateSelectedTreeNodeEventHandler();

    this.filesToAssignTree.selectedTreeNodeEventEmitter.subscribe((t: any) => {
      if (this.selectedAssignedSharedFileSpecification !== t.specificationFile) {
        this.selectedAssignedSharedFileSpecification = t.specificationFile;
        this.hasFileToAssignSelected = true;
      }
    }, error => {
      console.log('error: ', error);
    });
  }

  filter(text: string): void {
    this.clearAvailableFileSelections();
    if (this.fileTree) {
      this.fileTree.showTreeNodesThatContainsText(text);
    }
  }

  clearFilter() {
    this.toolbar.filterText = '';
  }

  public getSelectedFilesFromTree(): SharedFile[] {
    const filesToAssign: SharedFile[] = [];

    let i = 0;
    this.filesToAssignTree.rootNodes.forEach(node => {
      filesToAssign.push((node as SharedFileTreeNode).specificationFile);
      i++;
    });

    return filesToAssign;
  }

  public clearSelectedFilesTree() {
    this.filesToAssignTree.rootNodes = [];
  }

  public clearAvailableFileSelections() {
    this.fileTree.clearSelection();
    this.hasFileSelected = false;
    this.selectedSharedFileSpecification = undefined;
  }

  public hasMultipleKeyFilesAssigned() {

    let hasMultipleKeyFiles = false;

    if (this.filesToAssignTree) {
      this.filesToAssignTree.rootNodes.forEach(node => {
        if (node.name.includes('bin' || 'BIN')) {
          hasMultipleKeyFiles = true;
        }
      });
    }

    return hasMultipleKeyFiles;
  }

  public hasFilesToApply() {
    return this.filesToAssignTree.rootNodes.length > 0;
  }

  private updateSelectedTreeNodeEventHandler() {
    if (this.selectedTreeNodeSubscription) {
      this.selectedTreeNodeSubscription.unsubscribe();
    }
    this.selectedTreeNodeSubscription = this.fileTree.selectedTreeNodeEventEmitter.subscribe((t: any) => {
      console.log('Treenode selected');
      if (t !== null && this.selectedSharedFileSpecification !== t.specificationFile) {
        this.selectedSharedFileSpecification = t.specificationFile;
        this.hasFileSelected = true;
      }
    }, e => {
    });
  }

  private createFileSpecificationTreeNode(fileSpecification: SharedFile) {
    const treeNode = new SharedFileTreeNode(fileSpecification, this.fileTreeService, this.specificationService, this.sharedFilesService,
      (specificationIds => { }), false);
    this.fileTree.addRootNode(treeNode);
  }

  private createFileSpecificationToAssignTreeNode(fileSpecification: SharedFile) {
    const treeNode = new SharedFileTreeNode(fileSpecification, this.assignedFileTreeService, this.specificationService, this.sharedFilesService,
      (specificationIds => { }), false);
    this.filesToAssignTree.addRootNode(treeNode);
  }
}
