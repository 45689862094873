import { EventEmitter, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerExecutionModeType, SpecificationType } from 'app/app-model/enums';
import { NewSpecification } from 'app/app-model/newSpecification';
import { OdxImport, OdxLayer } from 'app/app-model/odx-import/odx-import';
import { NewSpecificationInfo } from 'app/app-model/specification-info';
import { SpecificationServerFileInfo } from 'app/app-model/specification-serverfile-info.model';
import { SpecificationStatisticItem } from 'app/app-model/specification-statistic-item.model';
import { SpecificationUser } from 'app/app-model/specificationUser';
import { StructureSpecification } from 'app/app-model/structure/structure-specification';
import { ValidationIssue } from 'app/app-model/validation/validation-issue';
import { SpecificationLegacyFiles } from 'app/app-services/specification-legacy-files.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { Rbac } from 'app/modules/shared/model/rbac';
import { User } from 'app/modules/shared/model/user';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Observer, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Label } from '../app-model/label/label';
import { ScommFile } from '../app-model/legacy-file';
import { Specification } from '../app-model/specification';
import { SpecificationVersion } from '../app-model/specification-version';
import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { RbacService } from './rbac-file-service';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SpecificationService implements OnInit, OnDestroy {
  isRbacConnected: boolean = true;
  rbacFile: Rbac;
  currentUser: User;
  userSubscription: Subscription;
  collaborators: SpecificationUser[];
  currentSpecification: Specification;
  currentSpecificationVersion: SpecificationVersion;
  selectedSpecificationEventEmitter: BehaviorSubject<{ specification: Specification; specificationVersion: SpecificationVersion }>
    = new BehaviorSubject({ specification: null, specificationVersion: null });
  specificationUpdatedEventEmitter: EventEmitter<Specification> = new EventEmitter<Specification>();
  releaseCheckDoneEventEmitter: EventEmitter<any> = new EventEmitter();

  private _curentUserIsCollaborator: boolean;

  constructor(private apiProxy: ApiProxyService, private authService: AuthService, private rbacService: RbacService,
    private router: Router, private userService: UserService,private http: HttpClient,
    private legacyFilesService: SpecificationLegacyFiles) {
    console.log('Getting user..');

    this.userSubscription = this.getCurrentUser().subscribe(user => {
      this.currentUser = user;
      console.log(`User ${user.name} is logged in`);
    });
  }

  get currentUserIsCollaborator(): boolean {
    return this._curentUserIsCollaborator;
  }

  get currentUserIsSpecificationOwner(): boolean {
    if (!this.currentSpecification.owner) {
      return false;
    }
    if (this.currentSpecification.owner.userName === this.currentUser.userName) {
      return true;
    }
  }

  public get isLegacyVersion(): boolean {
    return this.currentSpecificationVersion.specificationType === SpecificationType.Legacy;
  }

  public get isTemplateSpecification(): boolean {
    return this.currentSpecification.isTemplate;
  }

  public get isUpgradedVersion(): boolean {
    return this.currentSpecificationVersion.specificationType === SpecificationType.Upgraded;
  }

  public get isNativeVersion(): boolean {
    return this.currentSpecificationVersion.specificationType === SpecificationType.Native;
  }

  public get isOdxBasedVersion(): boolean {
    return this.currentSpecificationVersion.specificationType === SpecificationType.OdxBased;
  }

  public get isCurrentSpecificationVersionReleased(): boolean {
    return this.currentSpecificationVersion.releaseStatus == 1;
  }

  notifyReleaseCheckDone() {
    this.releaseCheckDoneEventEmitter.emit();
  }

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit() {
    this.userSubscription = this.checkCurrentUserIsCollaborator().subscribe((value) => this._curentUserIsCollaborator = value);
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public getDtc(specificationId: number, specificationVersionId: number): Observable<string> {
    return this.apiProxy.get<string>(`${environment.apiUrl}specifications/dtcextendeddatarecordnumber/${specificationId}/${specificationVersionId}`);
  }

  public updateDtc(specificationId: number, newDtc: string): Observable<string> {
    return this.apiProxy.put<string>(`${environment.apiUrl}specifications/dtcextendeddatarecordnumber/${specificationId}/${newDtc}`, undefined);
  }

  public getHandleNegativeResponse(specificationId: number, specificationVersionId: number): Observable<boolean> {
    return this.apiProxy.get<boolean>(`${environment.apiUrl}specifications/handlenegativeresponsecode21/${specificationId}/${specificationVersionId}`);
  }

  public updateHandleNegativeResponse(specificationId: number, value: boolean): Observable<boolean> {
    return this.apiProxy.put<boolean>(`${environment.apiUrl}specifications/handlenegativeresponsecode21/${specificationId}/${value}`, undefined);
  }

  public createSpecificationVersion(specificationVersion: SpecificationVersion): Observable<SpecificationVersion> {
    return this.apiProxy.post<SpecificationVersion>(`${environment.apiUrl}versions/`, specificationVersion);
  }

  public deleteSpecificationVersion(specificationVersion: SpecificationVersion): Observable<SpecificationVersion> {
    return this.apiProxy.delete<SpecificationVersion>(`${environment.apiUrl}versions/${specificationVersion.id}`);
  }

  public deleteSpecification(specification: StructureSpecification): Observable<StructureSpecification> {
    return this.apiProxy.delete<StructureSpecification>(`${environment.apiUrl}specifications/${specification.id}`);
  }

  public updateSpecificationVersion(id: number, specificationVersion: SpecificationVersion): Observable<SpecificationVersion> {
    return this.apiProxy.put<SpecificationVersion>(`${environment.apiUrl}versions/${id}`, specificationVersion);
  }

  public releaseSpecificationVersion(id: number, specificationVersion: SpecificationVersion): Observable<SpecificationVersion> {
    return this.apiProxy.put<SpecificationVersion>(`${environment.apiUrl}versions/${id}/releaseWithUpdatedComment`, specificationVersion);
  }

  public updateSpecificationVersionWithOdxImport(id: number, odxImport: OdxLayer): Observable<OdxLayer> {
    return this.apiProxy.put<OdxLayer>(`${environment.apiUrl}versions/${id}/layerstask`, odxImport);
  }

  public getCurrentUser(): Observable<User> {
    return this.userService.getCurrentUser();
  }

  public getSpecification(id: number): Observable<Specification> {
    return this.apiProxy.get<Specification>(environment.apiUrl + 'specifications/' + id).pipe(tap(p => {
      p.specificationVersions.forEach(pv => {
        if (pv.labels == null) {
          pv.labels = [];
        }
      });
    }));
  
  
    // return this.apiProxy.get<Specification>(environment.apiUrl + 'specifications/' + id).pipe(tap(p => {
    //   p.specificationVersions.forEach(pv => {
    //     if (pv.labels == null) {
    //       pv.labels = [];
    //     }
    //   });
    // }));
  }

  public getCurrentSpecification(id: number): Observable<Specification> {
    if (this.currentSpecification === undefined) {
      return this.getSpecification(id).pipe(tap(specification => {
        this.currentSpecification = specification;
      }));
    } else {
      return of(this.currentSpecification);
    }
  }

  public getCurrentSpecificationVersion(id: number): Observable<SpecificationVersion> {
    if (this.currentSpecificationVersion === undefined) {
      return this.getSpecificationVersion(id).pipe(tap(specificationVersion => {
        this.currentSpecificationVersion = specificationVersion;
      }));
    } else {
      return of(this.currentSpecificationVersion);
    }
  }

  public openlatestVersionOfCurrentSpecification(): SpecificationVersion {
    if (this.currentSpecification) {
      this.currentSpecificationVersion = this.currentSpecification.specificationVersions[this.currentSpecification.specificationVersions.length - 1];
      this.selectedSpecificationEventEmitter.next({ specification: this.currentSpecification, specificationVersion: this.currentSpecificationVersion });
      return this.currentSpecificationVersion;
    } else {
      return undefined;
    }
  }

  public getAlltemplates(): Observable<Specification[]> {
    return this.apiProxy.get<Specification[]>(`${environment.apiUrl}specificationstructure/templates`);
  }

  public getSpecifications(): Observable<Specification[]> {
    return this.apiProxy.get<Specification[]>(`${environment.apiUrl}specifications`);
  }

  public getSpecificationsPreview(): Observable<Specification[]> {
    return this.apiProxy.get<Specification[]>(environment.apiUrl + 'specifications/getspecificationspreview');
  }

  public getSpecificationVersion(versionId: number): Observable<SpecificationVersion> {
    return this.apiProxy.get<SpecificationVersion>(`${environment.apiUrl}versions/${versionId}`);
  }

  public getSpecificationVersionOdxImport(id: number): Observable<OdxImport> {
    return this.apiProxy.get<OdxImport>(`${environment.apiUrl}versions/${id}/odximport`);
  }

  public getStatistics(id: number): Observable<SpecificationStatisticItem[]> {
    return this.apiProxy.get<SpecificationStatisticItem[]>(`${environment.apiUrl}versions/${id}/statistics`);
  }

  public getServerFileInfo(id: number): Observable<SpecificationServerFileInfo> {
    return this.apiProxy.get<SpecificationServerFileInfo>(`${environment.apiUrl}versions/${id}/serverFileInfo`);
  }

  public updateSpecification(specification: Specification): Observable<Specification> {
    return this.apiProxy.put<Specification>(`${environment.apiUrl}specifications/${specification.id}`, specification);
  }

  public createSpecificationCollaborator(specification: Specification, user: SpecificationUser): Observable<SpecificationUser> {
    return this.apiProxy.post<SpecificationUser>(`${environment.apiUrl}specifications/${specification.id}/collaborators`, user);
  }

  public deleteSpecificationCollaborator(specificationId: number, userId: string): Observable<Specification> {
    return this.apiProxy.delete<Specification>(`${environment.apiUrl}specifications/${specificationId}/collaborators/${userId}`);
  }

  public getSpecificationCollaborators(specification: Specification): Observable<SpecificationUser[]> {
    return this.apiProxy.get<SpecificationUser[]>(`${environment.apiUrl}specifications/${specification.id}/collaborators/`);
  }

  public getUsers(): Observable<User[]> {
    return this.apiProxy.get<User[]>(environment.apiUrl + 'users');
  }

  public createSpecification(specification: NewSpecification, legacyServerFile: ScommFile): Observable<Specification> {
    return new Observable((observer: Observer<Specification>) => {
      this.apiProxy.post<NewSpecification, Specification>(environment.apiUrl + 'specifications', specification).subscribe({

        next: (result) => {

          /**
           * When legacy specifications are created. The specifications needs to be created first, then assign the server xml file.
           */

          if (legacyServerFile && legacyServerFile !== null) {
            legacyServerFile.specificationVersionId = result.specificationVersions[0].id;
            this.legacyFilesService.saveSpecificationScommFile(legacyServerFile).subscribe({
              next: (args) => {
                observer.next(result);
                observer.complete();
              }
            });
          } else {
            observer.next(result);
            observer.complete();
          }
        },

        error: (error) => console.log('error', error)
      });
    });
  }

  public getIssues(id: number): Observable<ValidationIssue[]> {
    return this.apiProxy.get<ValidationIssue[]>(`${environment.apiUrl}versions/${id}/issues`);
  }

  public hasOdxUpgradeSupport(specificationVersionId: number): Observable<boolean> {
    return this.apiProxy.get<boolean>(`${environment.apiUrl}versions/${specificationVersionId}/odxUpgradeSupport`);
  }

  public checkRbacFileConnected() {
    if (!this.isTemplateSpecification && (this.isNativeVersion || this.isOdxBasedVersion)) {
      if (this.currentSpecification.serverExecutionMode === ServerExecutionModeType.Application) {
        this.rbacService.getRbacVersionGuid(this.currentSpecificationVersion.code).subscribe(data => {
          if (data === undefined) {
            this.isRbacConnected = false;
          }
          else {
            this.isRbacConnected = true;
          }
        })
      }
      else {
        this.isRbacConnected = true;
      }
    }
  }


  public canReleaseSpecificationVersion(id: number): Observable<boolean> {
    this.checkRbacFileConnected();
    return this.getIssues(id).pipe(map(issues => {
      let hasNoIssues = true;
      issues.forEach(issue => {
        if (issue.issue.severity === 1) {
          hasNoIssues = false;
        }
      });
      return hasNoIssues;
    },
      err => {
        console.log(err);
      }
    ));

  }

  public setCurrentSpecification(specification: Specification, specificationVersion: SpecificationVersion) {
    if (this.currentSpecification === specification) {
      return;
    }

    this.currentSpecification = specification;
    this.currentSpecificationVersion = specificationVersion;
    this.selectedSpecificationEventEmitter.next({ specification: this.currentSpecification, specificationVersion: this.currentSpecificationVersion });
  }

  public sendSpecificationUpdatedEvent() {
    this.specificationUpdatedEventEmitter.emit();
  }

  public updateSpecificationVersionLabels(specificationVersion: SpecificationVersion, labels: Label[]): Observable<Label[]> {
    return this.apiProxy.put<Label[]>(`${environment.apiUrl}versions/${specificationVersion.id}/labels/`, labels);
  }

  public addSpecificationVersionLabel(specificationVersion: SpecificationVersion, label: Label): Observable<Label> {
    return this.apiProxy.put<Label>(`${environment.apiUrl}versions/${specificationVersion.id}/labels/${label.id}`, label);
  }

  public deleteSpecificationVersionLabel(specificationVersion: SpecificationVersion, label: Label): Observable<Label> {
    return this.apiProxy.delete<Label>(`${environment.apiUrl}versions/${specificationVersion.id}/labels/${label.id}`);
  }

  public deleteSpecificationVersionSharedFile(specificationVersion: SpecificationVersion, versionSharedFileId): Observable<ScommFile> {
    return this.apiProxy.delete<ScommFile>(`${environment.apiUrl}versions/${specificationVersion.id}/sharedFiles/${versionSharedFileId}`);
  }

  public createClonedSpecificationBasedOnVersion(newspecificationVersionInfo: NewSpecificationInfo): Observable<Specification> {
    return this.apiProxy.post<NewSpecificationInfo, Specification>(`${environment.apiUrl}specifications/clone`, newspecificationVersionInfo);
  }

  private checkCurrentUserIsCollaborator(): Observable<boolean> {
    return this.getSpecificationCollaborators(this.currentSpecification)
      .pipe(map((data: SpecificationUser[]) => {
        this.collaborators = data;
        for (const userCollaborator of this.collaborators) {
          if (userCollaborator.userName === this.currentUser.userName) {
            return true;
          }
        }

        return false;
      }));
  }

  public reset(): void {
    this.currentSpecification = undefined;
    this.currentSpecificationVersion = undefined;
  }
}
