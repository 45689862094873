import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { CategoryItem } from 'app/app-model/category-item';
import { CategoryName } from 'app/app-model/enums';
import { CategoryItemsService } from 'app/app-services/category-items-service';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { SpecificationService } from 'app/app-services/specification-service';
import {
  ServerIdentificationCategoryItemComponent,
} from 'app/data-categories/server-identification/server-identification-category-item';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { CommonListElement } from './common-list-element';
import { CommonListElementSettings } from './common-list-element-settings';
import { Router } from '@angular/router';


@Component({
  selector: 'app-common-list',
  templateUrl: './common-list.component.html',
  styleUrls: ['./common-list.component.css']
})
export class CommonListComponent implements OnInit, OnDestroy, DoCheck, OnChanges {
  isLoading: boolean = false;
  @Output()
  selectedItemEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  orderChangedEventEmitter: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output()
  elementDropped: EventEmitter<any> = new EventEmitter();

  @Output()
  isDraggingEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  data: any;

  @Input()
  refreshEventEmitter: EventEmitter<void>;

  @Input()
  isReadOnly: boolean;

  @Input()
  isState: boolean;

  @Input()
  bubbleMode = false;

  @Input()
  isDragList = false;

  @Input()
  isDraggable = false;

  @Input()
  isSelectable = true;

  @Input()
  isAllowed: boolean;

  internalSelectedItem: any;
  internalList: CommonListElement<any>[];
  isDragging = false;
  copyEnabled: boolean;
  versionIsReleased: boolean;

  private _list: any[];
  private _selectedItem: any;
  private _allowDrop = false;
  private _commonListElementSettingsInitiator: (any, settings: CommonListElementSettings) => void;
  private selectedItemSubject = new Subject<void>();
  private selectedItemObservable = this.selectedItemSubject.pipe(debounceTime(50));
  private selectedItemObservableSubscription: Subscription;
  private iterableDiffer: IterableDiffer<any>;

  get commonListElementSettingsInitiator(): (any, settings: CommonListElementSettings) => void {
    return this._commonListElementSettingsInitiator;
  }

  @Input()
  set commonListElementSettingsInitiator(value: (any, settings: CommonListElementSettings) => void) {
    this._commonListElementSettingsInitiator = value;
  }

  get selectedItem() {
    return this._selectedItem;
  }

  @Input()
  set selectedItem(item) {
    this._selectedItem = item;
    if (item != null || item != undefined) {

      if (!item) {
        this.internalSelectedItem = null;
        return;
      }

      if (!this.internalList) {
        return;
      }

      this.internalSelectedItem = this.internalList.find((internalItem: CommonListElement<any>) => this.shouldSelectItem(internalItem, item));
    }
  }

  constructor(private iterableDiffers: IterableDiffers, private categoryItemsService: CategoryItemsService,
    private dataCategoriesService: DataCategoriesService,
    private specificationService: SpecificationService,
    private router: Router) {
    this.iterableDiffer = this.iterableDiffers.find([]).create(null);
  }

  shouldSelectItem(internalItem: CommonListElement<any>, itemToSelect: CategoryItem): boolean {
    /**
     * This method is checking which items from the list that should be selected.
     *
     * Currently, we have to handle 3 scenarios. For the operational data we have to check against the resource id.
     */
    if (itemToSelect.categoryType === CategoryName.OperationalDataVariable) {
      return itemToSelect.resourceId === internalItem.element.resourceId;
    }

    return itemToSelect.name ? (internalItem.element.name === itemToSelect.name) : internalItem.element.id === itemToSelect.id
  }

  ngOnInit() {
    if (this.refreshEventEmitter) {
      this.refreshEventEmitter.subscribe(x => this.refreshList());
    }

    this.selectedItemObservableSubscription = this.selectedItemObservable.subscribe(x => {
      this.selectedItemEventEmitter.emit(x);
    });

    this.specificationService.selectedSpecificationEventEmitter.subscribe(result => {
      if (result.specificationVersion) {
        this.setIsCopyEnabled(result.specificationVersion.id);
      }
    });
  }

  ngOnDestroy() {
    if (this.selectedItemObservableSubscription) {
      this.selectedItemObservableSubscription.unsubscribe();
    }
  }

  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.list);
    if (changes) {
      this.list = this.list;
    }
  }

  refreshList() {
    this.list = this.list;
  }

  get list() {
    return this._list;
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.hasOwnProperty('selectedItem')) {
      const selectedItemChange = changes['selectedItem'];
      this.isLoading = false;
    }


    if (changes.hasOwnProperty('list')) {
      const listChange = changes['list'];
      this.isLoading = false;
    }
  }


  @Input()
  set list(value: any[]) {
    this.isLoading = (this.router.url.includes("/rbac-files/") || this.router.url.includes("/diagnostic-families/") ||
      this.router.url.includes("/identification-groups/")) ? false : true;
    this._list = value;
    this.internalList = value ? value.map(v => {
      const settings = new CommonListElementSettings();
      if (this.commonListElementSettingsInitiator) {
        this.commonListElementSettingsInitiator(v, settings);
      }

      return new CommonListElement(v, settings);
    }) : [];

    this.internalSelectedItem = this.internalList.find((i: CommonListElement<any>) => i.element === this.selectedItem);
    if (this.list && this.selectedItem === undefined && this.internalSelectedItem === undefined) {
      this.isLoading = false;
    }
    if (this.selectedItem) {
      if ((this.selectedItem.sourceService != undefined && this.internalSelectedItem === undefined)
        || this.selectedItem.sourceService != undefined && this.internalSelectedItem != undefined) {
        this.isLoading = false;
      }
    }
    if (this.list.length == 0) {
      this.isLoading = false;
    }

    if (this.list.length > 0) {
      this.isLoading = false;
    }
  }

  selectItem(item: any) {
    if (this.isSelectable === false) {
      return;
    }

    this.internalSelectedItem = item;
    this.selectedItem = item.element;
    this.selectedItemSubject.next(item.element);
  }

  drag(event: DragEvent, element: any) {
    this.isDragging = true;

    const index = this.internalList.indexOf(element);
    event.dataTransfer.setData('data', index.toString());
    event.dataTransfer.effectAllowed = 'move';
    this.isDraggingEventEmitter.emit(true);
  }

  dragEnd(event: DragEvent) {
    this.isDraggingEventEmitter.emit(false);
  }

  drop(event: CdkDragDrop<any>) {
    this.isDragging = false;
    this.isDraggingEventEmitter.emit(false);

    if (event.currentIndex === event.previousIndex) {
      return;
    }

    const indexOfMovedItem = this.internalList.indexOf(event.item.data);
    const movedItem = (this.internalList as unknown as CommonListElement<any>)[indexOfMovedItem];

    if (!movedItem.allowDrop) {
      return;
    }

    moveItemInArray(this.internalList, event.previousIndex, event.currentIndex);
    this.internalSelectedItem = movedItem;
    this.orderChangedEventEmitter.emit(this.internalList.map(item => item.element));
    this.elementDropped.emit(this.internalSelectedItem.element);
  }

  isDropAllowed(event: DragEvent, element: any) {
    event.preventDefault();

    return this.isDropAllowed;
  }

  keydown(event: KeyboardEvent) {
    const key = event.key;

    if (this.internalSelectedItem == null) {
      return;
    }

    const indexOfSelectedItem = this.internalList.indexOf(this.internalSelectedItem);

    if (key === 'ArrowUp') {
      event.preventDefault();
      event.stopPropagation();

      if (indexOfSelectedItem === 0) {
        return;
      }

      this.selectItem(this.internalList[indexOfSelectedItem - 1]);
    } else if (key === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();

      if (indexOfSelectedItem === this.internalList.length - 1) {
        return;
      }

      this.selectItem(this.internalList[indexOfSelectedItem + 1]);
    }
  }

  isDragDisabled(item: any) {

    if (!item.element.specificationVersion) {
      return false;
    }

    return item.element.specificationVersion.releaseStatus === 1;
  }

  trackItemById(index: number, item: CommonListElement<any>) {
    return item.element.id;
  }

  copyItem(element: CategoryItem) {
    this.dataCategoriesService.copyItem(element);
    this.refreshList();
  }

  listContainsServerIdentificationItems() {
    return this.internalList.some(listItem => listItem.element instanceof ServerIdentificationCategoryItemComponent);
  }

  private setIsCopyEnabled(specificationVersionId: number) {
    if (this.listContainsServerIdentificationItems()) {
      this.copyEnabled = false;
      return;
    }
    this.categoryItemsService.versionIsReleasedStatus(specificationVersionId).pipe(tap(isReleased => {
      this.copyEnabled = !isReleased;
    })).subscribe();
  }
}
