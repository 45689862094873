import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';
import { EcuIdentifierService } from 'app/app-services/ecu-identification.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { EcuIdentifierCategoryItem } from 'app/data-categories/identification-property/ecu-identification-category-item';
import { Observable, Observer } from 'rxjs';

import { MessageService } from '../../modules/shared/services/message-service.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-identifier-selector',
  templateUrl: './identifier-selector.component.html',
  styleUrls: ['./identifier-selector.component.css']
})
export class IdentifierSelectorComponent implements OnInit {

  @Output()
  notifyEcuIdentifierSelected: EventEmitter<void> = new EventEmitter();

  @Output()
  notifyCollapse: EventEmitter<any> = new EventEmitter();

  @Input()
  expressionModel: ServeridentificationExpressionModel;

  @Input()
  isReadOnly: boolean;

  @Input()
  isAllowed: boolean;

  ecuIdentifierCategoryItems: EcuIdentifierCategoryItem[] = [];
  selectedIdentifier: EcuIdentifierCategoryItem;
  availableParameters: string[] = [];
  selectedParam: string;
  private _withProperty = true;

  constructor(private ecuIdentifierService: EcuIdentifierService,
    private specificationService: SpecificationService, private messageService: MessageService) { }

  public get withProperty(): boolean {
    return this._withProperty;
  }
  public set withProperty(v: boolean) {
    this._withProperty = v;

    if (!v && this.hasEcuIdentifierSelected) {
      this.expressionModel.ecuIdentifierPropertyToCompareWith.propertyName = null;
      this.notifyEcuIdentifierSelected.next();
    }
  }

  public get hasEcuIdentifierSelected(): boolean {
    return this.ecuIdentifier !== '';
  }

  public get ecuIdentifierId(): number {
    if (!this.expressionModel.ecuIdentifierPropertyToCompareWith) {
      return -1;
    } else {
      return this.expressionModel.ecuIdentifierPropertyToCompareWith.ecuIdentifierId;
    }
  }

  public set ecuIdentifierId(v: number) {
    if (!this.expressionModel.ecuIdentifierPropertyToCompareWith) {
      this.expressionModel.ecuIdentifierPropertyToCompareWith = { ecuIdentifierId: v, ecuIdentifier: '', propertyName: '' };
    } else {
      this.expressionModel.ecuIdentifierPropertyToCompareWith.ecuIdentifierId = v;
    }
  }

  public get ecuIdentifier(): string {
    if (!this.expressionModel.ecuIdentifierPropertyToCompareWith) {
      return '';
    } else {
      return this.expressionModel.ecuIdentifierPropertyToCompareWith.ecuIdentifier;
    }
  }

  public set ecuIdentifier(v: string) {
    if (!this.expressionModel.ecuIdentifierPropertyToCompareWith) {
      this.expressionModel.ecuIdentifierPropertyToCompareWith = { ecuIdentifierId: 0, ecuIdentifier: v, propertyName: '' };
    } else {
      this.expressionModel.ecuIdentifierPropertyToCompareWith.ecuIdentifier = v;
    }
  }

  public get propertyName(): string {
    if (!this.expressionModel.ecuIdentifierPropertyToCompareWith) {
      return undefined;
    } else {
      return this.expressionModel.ecuIdentifierPropertyToCompareWith.propertyName;
    }
  }

  public set propertyName(v: string) {
    if (this.expressionModel.ecuIdentifierPropertyToCompareWith) {
      this.expressionModel.ecuIdentifierPropertyToCompareWith.propertyName = v;
    }
  }

  ngOnInit() {
    this.ecuIdentifierService.getItemsPreview(this.specificationService.currentSpecificationVersion.id).pipe(tap(items => {
      this.ecuIdentifierCategoryItems = items;
    }, err => {
      this.messageService.dispatchErrorMessageFromApi(err);
    })).subscribe();

    if (this.hasEcuIdentifierSelected) {
      this.getAvailableParametersForCurrentIdentifer().pipe(tap(arg => {
        if (this.propertyName) {
          this._withProperty = true;
        } else {
          this._withProperty = false;
        }
      })).subscribe();
    }
  }

  onIdentifierSelected() {
    if (this._withProperty) {
      this.getAvailableParametersForCurrentIdentifer().subscribe(arg => {
        // this.selectDefaultAvailableParameterOrDefault();
      });
    } else {
      this.notifyEcuIdentifierSelected.next();
    }
  }

  onIdentifierParamSelected() {
    this.notifyEcuIdentifierSelected.next();
  }

  private getAvailableParametersForCurrentIdentifer(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      // TODO: ecuIdentifier ID should be a name
      this.ecuIdentifierService.getItem(this.specificationService.currentSpecificationVersion.id, this.ecuIdentifier).subscribe({
        next: (item) => {
          if (item.isSyncingFromMaster) {
            item.syncDone.subscribe({
              next: (args) => {
                this.availableParameters = item.properties.map(prop => prop.name);
                this.propertyName = null;
                observer.next(this.availableParameters);
                observer.complete();
              }
            });
          } else {
            this.availableParameters = item.properties.map(prop => prop.name);
            observer.next(this.availableParameters);
            observer.complete();
          }
        },
        error: (err) => {
          this.messageService.dispatchErrorMessageFromApi(err);
          observer.complete();
        }
      });
    });
  }

  private selectDefaultAvailableParameterOrDefault() {
    if (this.availableParameters && this.availableParameters.length === 1) {
      this.propertyName = this.availableParameters[0];
      this.onIdentifierParamSelected();
    }
  }
}
