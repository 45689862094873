import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule } from 'angular-oauth2-oidc';
import {
  RemoveDiagnosticFamiliesConfirmComponent,
} from 'app/diagnostic-families/remove-diagnostics-family-confirm/remove-diagnostics-family-confirm.component';
import {
  RemoveDiagnosticFamilyGenerationConfirmComponent,
} from 'app/diagnostic-families/remove-diagnostics-family-generation-confirm/remove-diagnostics-family-generation-confirm.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { AdminDataTableComponent } from './admin-user-management/admin-data-table.component';
import { TaskStatusComponent } from './app-model/task-status/task-status.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  ComputationalMethodComponent,
} from './data-categories/diagnostic-services/computational-method/computational-method.component';
import { DiagnosticServicesComponent } from './data-categories/diagnostic-services/diagnostic-services.component';
import {
  EditableComputationalMethodComponent,
} from './data-categories/diagnostic-services/editable-computational-method/editable-computational-method.component';
import {
  ComputationalMethodViewComponent,
} from './data-categories/diagnostic-services/editable-parameter/computational-method/computational-method.component';
import {
  EditableParameterComponent,
} from './data-categories/diagnostic-services/editable-parameter/editable-parameter.component';
import {
  InternalValueComponent,
} from './data-categories/diagnostic-services/editable-parameter/internal-value/internal-value.component';
import {
  ParameterInfoComponent,
} from './data-categories/diagnostic-services/editable-parameter/parameter-info/parameter-info.component';
import {
  PhysicalValueComponent,
} from './data-categories/diagnostic-services/editable-parameter/physical-value/physical-value.component';
import {
  PositionLengthComponent,
} from './data-categories/diagnostic-services/editable-parameter/position-length/position-length.component';
import { ParameterComponent } from './data-categories/diagnostic-services/parameter/parameter.component';
import { EditableRangeComponent } from './data-categories/diagnostic-services/range/editable-range/editable-range.component';
import { RangeComponent } from './data-categories/diagnostic-services/range/range.component';
import {
  EditableValueConstraintComponent,
} from './data-categories/diagnostic-services/value-constraint/editable-value-constraint/editable-value-constraint.component';
import { ValueConstraintComponent } from './data-categories/diagnostic-services/value-constraint/value-constraint.component';
import { EcuResetComponent } from './data-categories/ecu-reset/ecu-reset.component';
import { FingerprintComponent } from './data-categories/fingerprint/fingerprint.component';
import { FreezeFramesComponent } from './data-categories/freeze-frames/freeze-frames.component';
import {
  HarmonizedFreezeFramesComponent,
} from './data-categories/harmonized-freeze-frames/harmonized-freeze-frames.component';
import { IdCodeFieldComponent } from './data-categories/identification-property/id-code-field/id-code-field.component';
import {
  IdentificationPropertyComponent,
} from './data-categories/identification-property/identification-property.component';
import { IoComponent } from './data-categories/io/io.component';
import { IolistComponent } from './data-categories/iolist/iolist.component';
import { DeleteDialogComponent } from './data-categories/legacy-files/delete-dialog/delete-dialog.component';
import {
  EditorShortcutsDescComponent,
  LegacyFileToolbarComponent,
  ValidationMessageComponent,
} from './data-categories/legacy-files/legacy-file-toolbar/legacy-file-toolbar.component';
import { LegacyFilesComponent } from './data-categories/legacy-files/legacy-files.component';
import {
  LegacyServerKeyfileViewerComponent,
} from './data-categories/legacy-files/legacy-server-keyfile-viewer/legacy-server-keyfile-viewer.component';
import { OtherFilesViewerComponent } from './data-categories/legacy-files/other-files-viewer/other-files-viewer.component';
import { BreakpointsComponent } from './data-categories/operational-data/breakpoints/breakpoints.component';
import {
  AxisDefinitionComponent,
} from './data-categories/operational-data/definition/axis-definition/axis-definition.component';
import {
  OperationalDataDefinitionComponent,
} from './data-categories/operational-data/definition/operational-data-definition/operational-data-definition.component';
import { OperationalDataComponent } from './data-categories/operational-data/operational-data.component';
import { PreconditionComponent } from './data-categories/precondition/precondition.component';
import { SecurityAccessComponent } from './data-categories/security-access/security-access.component';
import {
  CanAddressComparisonComponent,
} from './data-categories/server-identification/can-address-comparison/can-address-comparison.component';
import {
  CreateExpressionNodeComponent,
} from './data-categories/server-identification/create-expression-node/create-expression-node.component';
import {
  DoesNotExistComparisonComponent,
} from './data-categories/server-identification/does-not-exist-comparison/does-not-exist-comparison.component';
import {
  ExecutionModeComparisonComponent,
} from './data-categories/server-identification/execution-mode-comparison/execution-mode-comparison.component';
import {
  ExpressionNodeRendererComponent,
} from './data-categories/server-identification/expression-node-renderer/expression-node-renderer.component';
import {
  ExpressionViewToolbarComponent,
} from './data-categories/server-identification/expression-view-toolbar/expression-view-toolbar.component';
import {
  IntervalComparisonExpressionItemComponent,
} from './data-categories/server-identification/interval-comparison-expression-item/interval-comparison-expression-item.component';
import {
  OperatorComparisonComponent,
} from './data-categories/server-identification/operator-comparison/operator-comparison.component';
import { ServerIdentificationComponent } from './data-categories/server-identification/server-identification.component';
import {
  SpecialComparisonComponent,
} from './data-categories/server-identification/special-comparison/special-comparison.component';
import {
  SubstringComparisonRangeComponent,
} from './data-categories/server-identification/substring-comparison-range/substring-comparison-range.component';
import {
  ValueComparisonComponent,
} from './data-categories/server-identification/value-comparison/value-comparison.component';
import { SessionsComponent } from './data-categories/sessions/sessions.component';
import { FileBrowserComponent } from './data-categories/shared-files/file-browser/file-browser.component';
import {
  FileReferenceBrowserComponent,
} from './data-categories/shared-files/file-reference-browser/file-reference-browser.component';
import {
  FileVersionBrowserComponent,
} from './data-categories/shared-files/file-version-browser/file-version-browser.component';
import {
  FileVersionDetailsComponent,
} from './data-categories/shared-files/file-version-details/file-version-details.component';
import {
  SelectSharedFilesComponent,
} from './data-categories/shared-files/select-shared-files/select-shared-files.component';
import {
  SharedFilesAssignerComponent,
} from './data-categories/shared-files/shared-files-assigner/shared-files-assigner.component';
import { SharedFilesComponent } from './data-categories/shared-files/shared-files.component';
import { StateChartComponent } from './data-categories/state-chart/state-chart.component';
import { TeststepsComponent } from './data-categories/teststeps/teststeps.component';
import { ValidationIssueComponent } from './data-categories/validation-issue/validation-issue.component';
import { ValuesComponent } from './data-categories/values/values.component';
import { XmlCodeViewerComponent } from './data-categories/xml-code-viewer/xml-code-viewer.component';
import { DiagnosticFamiliesComponent } from './diagnostic-families/diagnostic-families.component';
import { GenerationComponent } from './diagnostic-families/generation/generation.component';
import { ActionConfirmComponent } from './dialogs/action-confirm/action-confirm.component';
import { CreateItemComponent } from './dialogs/create-item/create-item.component';
import { CreateNewSpecificationComponent } from './dialogs/create-new-specification/create-new-specification.component';
import { CreateNewVersionComponent } from './dialogs/create-new-version/create-new-version.component';
import { CreateSharedFileVersionComponent } from './dialogs/create-shared-file-version/create-shared-file-version.component';
import { CreateSharedFileComponent } from './dialogs/create-shared-file/create-shared-file.component';
import {
  IdentificationGroupSelectorComponent,
} from './dialogs/identification-group-selector/identification-group-selector.component';
import { IdentifierSelectorComponent } from './dialogs/identifier-selector/identifier-selector.component';
import { MoveExpressionsConfirmComponent } from './dialogs/move-expressions-confirm/move-expressions-confirm.component';
import { RbacFileSelectorComponent } from './dialogs/rbac-file-selector/rbac-file-selector.component';
import { RemoveDraftConfirmComponent } from './dialogs/remove-draft-confirm/remove-draft-confirm.component';
import { RemoveParameterConfirmComponent } from './dialogs/remove-parameter-confirm/remove-parameter-confirm.component';
import { UpgradeVersionModalComponent } from './dialogs/upgrade-version-modal/upgrade-version-modal.component';
import { OpenSpecDirective } from './directives/open-spec.directive';
import { FamilyGenerationComponent } from './family-generation/family-generation.component';
import {
  IdentificationGroupExpressionComponent,
} from './identification-group-expression/identification-group-expression.component';
import {
  ConnectedServerIdentificationsComponent,
} from './identification-groups/connected-server-identifications/connected-server-identifications.component';
import { IdentificationGroupsComponent } from './identification-groups/identification-groups.component';
import { AddKeysetItemComponent } from './keyset/add-keyset-item/add-keyset-item.component';
import { AddKeysetComponent } from './keyset/add-keyset/add-keyset.component';
import { KeysetComponent } from './keyset/keyset.component';
import { MainComponent } from './main/main.component';
import { ManageTemplatesComponent } from './manage-templates/manage-templates.component';
import { ModificationInfoTableComponent } from './modification-info-table/modification-info-table.component';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { ReleaseModule } from './modules/release/release.module';
import {
  AssignedServicesViewerComponent,
} from './modules/shared/components/assigned-services-viewer/assigned-services-viewer.component';
import {
  FunctionDashboardComponent,
} from './modules/shared/components/function/function-dashboard/function-dashboard.component';
import {
  FunctionDefinitionComponent,
} from './modules/shared/components/function/function-definition/function-definition.component';
import {
  MessageTemplateComponent,
} from './modules/shared/components/message-handler/message-templates/message-templates.component';
import { PropertyTableComponent } from './modules/shared/components/property-table/property-table.component';
import { SharedModule } from './modules/shared/shared.module';
import { ContentNavbarComponent } from './navigation/content-navbar/content-navbar.component';
import { HeaderInlineMenuComponent } from './navigation/header-inline-menu/header-inline-menu.component';
import { ReleaseBrowserNavbarComponent } from './navigation/release-browser-navbar/release-browser-navbar.component';
import { ImportComponent } from './navigation/specification-navbar/import/import.component';
import { VersionReleaseComponent } from './navigation/specification-navbar/release/release.component';
import { SpecificationNavbarComponent } from './navigation/specification-navbar/specification-navbar.component';
import { OrderedGenerationsPipe } from './pipes/ordered-generations.pipe';
import { ServiceExecutionOfTypePipe } from './pipes/service-execution-of-type-filter.pipe';
import { RbacDetailComponent } from './rbac-detail/rbac-detail.component';
import { RbacViewComponent } from './rbac-view/rbac-view.component';
import { ResourcesModule } from './resources/resources.module';
import { SpecificationBrowserComponent } from './specification-browser/specification-browser.component';
import { SpecificationHomeComponent } from './specification-home/specification-home.component';
import { SpecificationInfobarComponent } from './specification-infobar/specification-infobar.component';
import {
  CategoryItemsDiagnosticServicesComponent,
} from './specification-structure/category-items-diagnostic-services/category-items-diagnostic-services.component';
import { CategoryItemsComponent } from './specification-structure/category-items/category-items.component';
import { RemoveItemConfirmComponent } from './specification-structure/remove-item-confirm/remove-item-confirm.component';
import {
  SpecificationVersionDetailsComponent,
} from './specification-version-details/specification-version-details.component';
import { environment } from '../environments/environment';
import { SpecificationVersionsComponent } from './specification-versions/specification-versions.component';
import { SpecificationComponent } from './specification/specification.component';
import { StartPageComponent } from './start-page/start-page.component';
import { RbacLogComponent } from './rbac-log/rbac-log.component';
import { NewRbacComponent } from './rbac/new-rbac/new-rbac.component';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

export function loggerCallback(logLevel: LogLevel, message: string) {
  switch (logLevel) {
    case LogLevel.Error:
      console.error(message);
      return;
    case LogLevel.Info:
      console.info(message);
      return;
    case LogLevel.Verbose:
      console.debug(message);
      return;
    case LogLevel.Warning:
      console.warn(message);
      return;
    default:
      console.log(message);
      return;
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureAD.msalConfig.auth.clientId,
      authority: environment.azureAD.msalConfig.auth.authority,
      redirectUri: '/content/specifications',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: true,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  environment.apiConfig.forEach((a) =>
    protectedResourceMap.set(a.uri, [...(a.apiScopes ? a.apiScopes.filter((value, index, array) => array.indexOf(value) === index).filter((a) => a.length > 0): []),...(a.graphScopes? a.graphScopes.filter((value, index, array) => array.indexOf(value) === index).filter((a) => a.length > 0): []),]),
  );
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  console.log(
    'api scopes: ',
    environment.apiConfig
      .map((a) => a.apiScopes?.join(','))
      .filter((value, index, array) => array.indexOf(value) === index)
      .filter((a) => a.length > 0),
  );
  console.log(
    'graph scopes: ',
    environment.apiConfig
      .map((a) => a.graphScopes?.join(','))
      .filter((value, index, array) => array.indexOf(value) === index)
      .filter((a) => a.length > 0),
  );

  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        ...environment.apiConfig
          .map((a) => a.apiScopes?.join(','))
          .filter((value, index, array) => array.indexOf(value) === index)
          .filter((a) => a.length > 0),
      ],

      extraScopesToConsent: [
        ...environment.apiConfig
          .map((a) => a.apiScopes?.join(','))
          .filter((value, index, array) => array.indexOf(value) === index)
          .filter((a) => a.length > 0),
      ],
    },
    loginFailedRoute: '/authorization-failed',
  };
}

import { IvdOverrideComponent } from './data-categories/ivd-override/ivd-override.component';
import { RoleGuard } from './modules/authentication/RoleGuard';
import { ImportCategoryItemPackageDialogComponent } from './dialogs/import-category-item-package-dialog/import-category-item-package-dialog.component';
import { VsocMonitoringComponent } from './data-categories/vsoc-monitoring/vsoc-monitoring-component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    CategoryItemsComponent,
    RemoveParameterConfirmComponent,
    IoComponent,
    IolistComponent,
    FunctionDefinitionComponent,
    PropertyTableComponent,
    AssignedServicesViewerComponent,
    SpecificationNavbarComponent,
    ImportComponent,
    VersionReleaseComponent,
    CreateNewVersionComponent,
    SpecificationComponent,
    CreateItemComponent,
    RemoveItemConfirmComponent,
    RemoveDiagnosticFamiliesConfirmComponent,
    RemoveDiagnosticFamilyGenerationConfirmComponent,
    SpecificationVersionsComponent,
    SpecificationVersionDetailsComponent,
    FunctionDashboardComponent,
    CreateNewSpecificationComponent,
    SpecificationHomeComponent,
    ServiceExecutionOfTypePipe,
    OperationalDataComponent,
    OperationalDataDefinitionComponent,
    AxisDefinitionComponent,
    BreakpointsComponent,
    IdentificationPropertyComponent,
    ServerIdentificationComponent,
    OperatorComparisonComponent,
    ValueComparisonComponent,
    IdentifierSelectorComponent,
    DiagnosticServicesComponent,
    RangeComponent,
    ParameterComponent,
    ValueConstraintComponent,
    ExpressionViewToolbarComponent,
    ValidationIssueComponent,
    SessionsComponent,
    EcuResetComponent,
    FingerprintComponent,
    ValuesComponent,
    ReleaseBrowserNavbarComponent,
    IdentificationGroupSelectorComponent,
    IdentificationGroupsComponent,
    SpecificationBrowserComponent,
    IdentificationGroupExpressionComponent,
    LegacyFilesComponent,
    SharedFilesComponent,
    FileBrowserComponent,
    FileVersionBrowserComponent,
    FileVersionDetailsComponent,
    CreateSharedFileComponent,
    ContentNavbarComponent,
    CreateSharedFileVersionComponent,
    FamilyGenerationComponent,
    SelectSharedFilesComponent,
    SharedFilesAssignerComponent,
    CategoryItemsDiagnosticServicesComponent,
    SecurityAccessComponent,
    KeysetComponent,
    AddKeysetComponent,
    AddKeysetItemComponent,
    StateChartComponent,
    ConnectedServerIdentificationsComponent,
    IdCodeFieldComponent,
    CanAddressComparisonComponent,
    DoesNotExistComparisonComponent,
    SpecialComparisonComponent,
    SubstringComparisonRangeComponent,
    PreconditionComponent,
    FileReferenceBrowserComponent,
    CreateExpressionNodeComponent,
    ExpressionNodeRendererComponent,
    MoveExpressionsConfirmComponent,
    RemoveItemConfirmComponent,
    EditableParameterComponent,
    EditableComputationalMethodComponent,
    EditableRangeComponent,
    EditableValueConstraintComponent,
    DiagnosticFamiliesComponent,
    GenerationComponent,
    TaskStatusComponent,
    DoesNotExistComparisonComponent,
    IntervalComparisonExpressionItemComponent,
    ExecutionModeComparisonComponent,
    OpenSpecDirective,
    SpecificationInfobarComponent,
    StartPageComponent,
    ParameterInfoComponent,
    PhysicalValueComponent,
    InternalValueComponent,
    PositionLengthComponent,
    ComputationalMethodViewComponent,
    ComputationalMethodComponent,
    OrderedGenerationsPipe,
    RemoveDraftConfirmComponent,
    LegacyFileToolbarComponent,
    LegacyServerKeyfileViewerComponent,
    OtherFilesViewerComponent,
    EditorShortcutsDescComponent,
    ValidationMessageComponent,
    ActionConfirmComponent,
    UpgradeVersionModalComponent,
    AdminDataTableComponent,
    HeaderInlineMenuComponent,
    ModificationInfoTableComponent,
    IolistComponent,
    TeststepsComponent,
    FreezeFramesComponent,
    HarmonizedFreezeFramesComponent,
    XmlCodeViewerComponent,
    DeleteDialogComponent,
    RbacDetailComponent,
    RbacFileSelectorComponent,
    RbacViewComponent,
    RbacLogComponent,
    NewRbacComponent,
    IvdOverrideComponent,
    ImportCategoryItemPackageDialogComponent,
    VsocMonitoringComponent,
    // MessageTemplateComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MsalModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    AuthenticationModule,
    ReleaseModule,
    AppRoutingModule,
    ResourcesModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatBadgeModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatIconModule,
    CommonModule,
    CdkStepperModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    MatDialogModule,
    MatStepperModule,
    MatRadioModule,
    MatSelectModule,
    Ng2SearchPipeModule,
    MatProgressSpinnerModule,
    ManageTemplatesComponent,
    OAuthModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    RoleGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: [
    EditorShortcutsDescComponent,
    ValidationMessageComponent,
    MessageTemplateComponent,
    DeleteDialogComponent
  ]
})
export class AppModule {
}
