<div class="sdds-row">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
        <div *ngIf="openModeEnabled">
            <h4>{{getFileName}}</h4>

            <app-legacy-file-toolbar #toolbarRef> </app-legacy-file-toolbar>

            <div id="legacyFileToolbarServiceId" style="width:30%; margin-top: 10px;"
                *ngIf="legacyFileToolbarServiceRef.viewDraftsActive && !legacyFileToolbarServiceRef.mergeMode  && !legacyFileToolbarServiceRef.diffMode">
                <label class="control-label">Available drafts:</label>
                <select #availableDrafts class="form-control"
                    [(ngModel)]="legacyModificationServiceRef.selectedDraftFile"
                    (change)="legacyModificationServiceRef.selectDraftFile(legacyModificationServiceRef.selectedDraftFile)">
                    <option *ngFor="let file of legacyModificationServiceRef.fileDrafts" [ngValue]="file">
                        {{file.draft.user.name}}
                    </option>
                </select>
            </div>

            <div class="mt-10">
                <div class="editing" *ngIf="legacyModificationServiceRef.showEditingDraftFileTextEnabled">
                    <label *ngIf="legacyModificationServiceRef.isCurrentUserDraftSelected">The Draft File for
                        "{{legacyModificationServiceRef.selectedDraftFileUser}}" is in Edit mode<span> .
                        </span><span>. </span><span> . </span></label>
                    <label
                        *ngIf="!legacyModificationServiceRef.isCurrentUserDraftSelected && !legacyFileToolbarServiceRef.diffMode">The
                        Draft File for
                        "{{legacyModificationServiceRef.selectedDraftFileUser}}" is in Display mode</label>
                </div>
                <div *ngIf="showDisplayingMasterFileText">
                    <label>The Master File is in Display mode.</label>
                </div>
                <div class="clear-both"></div>
                <div>
                    <app-file-preview #fileEditor
                        [activeCodeEditorContent]="legacyModificationServiceRef.currentSingleFileContent"
                        [xmlFileName]="legacyServerFileName">
                    </app-file-preview>
                </div>
            </div>
        </div>

        <div *ngIf="!openModeEnabled">
            <h1>SCOMM Files</h1>
            <div class="sdds-banner">
                <div class="sdds-banner-body">
                    This section contains a list of Scomm files related to this specification. Supported files are XML
                    files containing ECU Id, Server Identification and Binary security access files.
                </div>
            </div>

            <div class="mt-10">
                <app-legacy-server-keyfile-viewer [currentSpecification]="currentSpecification"
                    [isEditingAllowed]="isEditingAllowed" [hasLoadedFiles]="legacyModificationServiceRef.hasLoadedFiles"
                    [legacyServerFile]="legacyModificationServiceRef.currentLegacyServerFile"
                    [keyFile]="legacyModificationServiceRef.getKeyFile" [currentUser]="currentUser"
                    (legacyXmlFileUploaded)="legacyModificationServiceRef.whenLegacyFileUploaded($event)"
                    (legacyFileCreated)="whenLegacyFileCreated($event)"
                    (legacyXmlFileRemoved)="legacyModificationServiceRef.whenLegacyFileRemoved()"
                    (openLegacyFileEv)="whenOpenLegacyServerFile()"
                    (assignOtherSharedFiles)="legacyModificationServiceRef.updateAvailableScommFiles(specVersionId)"
                    (unlinkSharedFile)="legacyModificationServiceRef.whenUnlinkSharedFile($event)"
                    [hasOtherFiles]="legacyModificationServiceRef.getOtherFiles.length>0">
                </app-legacy-server-keyfile-viewer>
            </div>

            <div class="my-20">
                <app-other-files-viewer [otherFiles]="legacyModificationServiceRef.getOtherFiles"
                    (unlikSelectedFile)="legacyModificationServiceRef.whenUnlinkSharedFile($event)"
                    [isReadOnly]="!isEditingAllowed">
                </app-other-files-viewer>
            </div>
        </div>
    </div>
</div>