import { Component, ElementRef, EventEmitter, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { SpecificationVersion } from '../../../app-model/specification-version';


@Component({
  selector: 'app-file-reference-browser',
  templateUrl: './file-reference-browser.component.html',
  styleUrls: ['./file-reference-browser.component.css']
})
export class FileReferenceBrowserComponent {

  @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef;

  public modalClosing: EventEmitter<void> = new EventEmitter<void>();
  private _referencedDcodeSpecifications: SpecificationVersion[];

  constructor(private router: Router) { }

  public get dcodeReferenceSpecifications(): SpecificationVersion[] {
    if (this._referencedDcodeSpecifications) {
      return this._referencedDcodeSpecifications.sort((firstItem, secondItem) =>
        firstItem.specification.name.localeCompare(secondItem.specification.name));
    } else {
      return this._referencedDcodeSpecifications;
    }
  }

  @Input()
  public set dcodeReferenceSpecifications(v: SpecificationVersion[]) {
    this._referencedDcodeSpecifications = v;
  }

  close() {
    this.modalClosing.emit();
  }

  navigateToSpec(specificationVersion: SpecificationVersion) {
    this.cancelButton.nativeElement.click();
    this.router.navigate(['/specification', specificationVersion.specification.id, specificationVersion.id, 'files']);
  }

}
