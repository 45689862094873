import { Injectable } from '@angular/core';
import { ComputationalMethodType } from 'app/app-model/diagnostic-service/computational.method.model';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticServiceValidator {
    public isValidComputationalMethod(computationalMethodType: ComputationalMethodType,
        internalDataType: OdxDataType,
        physicalDataType: OdxDataType,
        isInternalToPhysical: boolean) {
        if (computationalMethodType === ComputationalMethodType.TextTable) {
            return this.hasValidTextTableDataTypes(physicalDataType);
        } else if (computationalMethodType === ComputationalMethodType.LinearFormulaData) {
            return this.hasValidLinearFormulaDataTypes(internalDataType, physicalDataType, isInternalToPhysical);
        }
    }

    public hasValidTextTableDataTypes(physicalDataType: OdxDataType): boolean {
        return physicalDataType === OdxDataType.AsciiString;
    }

    public hasValidLinearFormulaDataTypes(internalDataType: OdxDataType, physicalDataType: OdxDataType, isInternalToPhysical: boolean): boolean {
        return isInternalToPhysical ? this.isNumericDataType(internalDataType) : this.isNumericDataType(physicalDataType);
    }

    public isNumericDataType(dataType: OdxDataType): boolean {
        return dataType === OdxDataType.Float32 ||
            dataType === OdxDataType.Float64 ||
            dataType === OdxDataType.Int32 ||
            dataType === OdxDataType.UInt32;
    }
}