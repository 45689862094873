import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'app/app-services/user.service';

import { MessageService } from '../../../../shared/services/message-service.service';
import { View } from '../../../model/view';
import { ViewService } from '../../../services/view.service';


@Component({
  selector: 'app-add-view',
  templateUrl: './add-view.component.html',
  styleUrls: ['./add-view.component.css']
})
export class AddViewComponent implements OnInit {
  @Output()
  viewAddedEventEmitter = new EventEmitter<View>();

  @Input()
  initEventEmitter: EventEmitter<void>;

  modalClosing: EventEmitter<void> = new EventEmitter<void>();
  applying = false;
  name: string;
  loading = false;

  constructor(private viewService: ViewService, private messageService: MessageService, private userService: UserService) { }

  ngOnInit() {
    this.initEventEmitter.subscribe(() => {
      this.name = null;
    });
  }

  addView(name: string) {

    this.applying = true;
    const viewToAdd = new View();
    viewToAdd.name = name;

    this.userService.getCurrentUser().subscribe(activeUser => {
      viewToAdd.createdBy = activeUser.firstName + ' ' + activeUser.lastName;

      if (this.applying) {
        this.viewService.addView(viewToAdd).subscribe(addedView => {
          this.viewAddedEventEmitter.emit(addedView);
          this.modalClosing.emit();
          this.applying = false;
        },
          error => {
            this.applying = false;
            this.messageService.dispatchErrorMessageFromApi(error);
          });
      }
    });
  }
}
