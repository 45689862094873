/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModificationService } from 'app/app-services/modification.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { BaseProperty, PropertyType } from 'app/modules/shared/model/properties/base-property';
import { InputProperty } from 'app/modules/shared/model/properties/input-property';
import {
  ServiceExecutionDirective,
  ServiceExecutionType,
} from 'app/modules/shared/model/service-execution/service-execution';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { PropertyParameterDirective } from 'app/modules/shared/model/service/parameters/property.parameter';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-property-table',
  templateUrl: './property-table.component.html',
  styleUrls: ['./property-table.component.css']
})
export class PropertyTableComponent implements OnInit, OnDestroy {

  @Input()
  properties: Array<BaseProperty>;

  @Input()
  propertyType: PropertyType;

  @Input()
  serviceExecutionSequence: ServiceExecutionSequence;

  @Input()
  isReadOnly: boolean;

  @Input()
  unlinkEnabled = true;

  serviceExecutionRemovedSubscription: Subscription;
  OdxDataType = OdxDataType;
  PropertyType = PropertyType;
  isShow = true;

  private minimumNumberOfProeprtiesToShow = 10;
  private propertiesDisplayBlockSize = 10;
  private numberOfShowingProperties: number;

  constructor(private modificationService: ModificationService, 
    private authService:AuthService
    ) { }

  ngOnInit() {
    this.serviceExecutionRemovedSubscription = this.modificationService.serviceExecutionRemoved.subscribe((serviceExecution: ServiceExecutionDirective) => {
      this.properties.forEach(property => {
        if (serviceExecution.hasPropertyParameter(property.selectedPropertyParameter)) {
          // property.selectedPropertyParameter = null;
          property.setSelectedPropertyParameterSilent(undefined);
        }
      });
    });

    this.numberOfShowingProperties = this.properties.length > this.propertiesDisplayBlockSize ? this.propertiesDisplayBlockSize : this.properties.length;
  }

  ngOnDestroy() {
    if (this.serviceExecutionRemovedSubscription) {
      this.serviceExecutionRemovedSubscription.unsubscribe();
    }
  }

  get propertiesToShow(): BaseProperty[] {
    return this.properties.slice(0, this.numberOfShowingProperties);
  }

  get canShowMoreProperties(): boolean {
    return this.properties.length > this.numberOfShowingProperties;
  }

  get canShowLessProperties(): boolean {
    return this.numberOfShowingProperties > this.minimumNumberOfProeprtiesToShow;
  }

  get propertiesLeftToShow(): number {
    return this.properties.length - this.numberOfShowingProperties;
  }

  showMoreProperties(): void {
    if ((this.numberOfShowingProperties + this.propertiesDisplayBlockSize) < this.properties.length) {
      this.numberOfShowingProperties += this.propertiesDisplayBlockSize;
      return;
    }

    this.numberOfShowingProperties = this.properties.length; // Show all remaining properties
  }

  showLessProperties(): void {
    if ((this.numberOfShowingProperties - this.propertiesDisplayBlockSize) > this.minimumNumberOfProeprtiesToShow) {
      this.numberOfShowingProperties -= this.propertiesDisplayBlockSize;
      return;
    }

    this.numberOfShowingProperties = this.minimumNumberOfProeprtiesToShow;
  }

  /** Gets all service executions that contains data of a matching property type
   *  e.g. for Source properties should only Service executions with responses be available
   */
  getMatchingServiceExecutionsForPropertyType(): ServiceExecutionDirective[] {
    if (!this.serviceExecutionSequence.serviceExecutions) {
      return [];
    }

    return this.serviceExecutionSequence.serviceExecutions.filter(serviceExecution => {
      switch (this.propertyType) {
        case PropertyType.Source:
          return serviceExecution.getServiceExecutionTypes().some(x => x === ServiceExecutionType.Response) &&
            (serviceExecution.availableResponsePropertyParameters && serviceExecution.availableResponsePropertyParameters.length > 0);
        case PropertyType.Target:
          return serviceExecution.getServiceExecutionTypes().some(x => x === ServiceExecutionType.Request) &&
            (serviceExecution.availableRequestPropertyParameters && serviceExecution.availableRequestPropertyParameters.length > 0);
        default:
          return false;
      }
    });
  }

  isInputProperty(property: BaseProperty): boolean {
    return property instanceof InputProperty;
  }

  parameterSelected(parameter: PropertyParameterDirective): boolean {
    if (parameter.usedByProperties.length > 0) {
      return true;
    }
    return false;
  }

  getRelevantPropertyParameters(property: BaseProperty, serviceExecution: ServiceExecutionDirective): PropertyParameterDirective[] {
    if (this.isInputProperty(property)) {
      return serviceExecution.availableRequestPropertyParameters;
    } else {
      return serviceExecution.availableResponsePropertyParameters;
    }
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  trackByParamName(index: number, item: PropertyParameterDirective): string {
    return item.parameter.model.name;
  }

  trackByPropertyId(index: number, item: BaseProperty): string {
    return item.name;
  }

  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
