// import { Byte } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import shajs from 'sha.js';

@Injectable({
  providedIn: 'root'
})
export class HashGenService {

  constructor() { }

  to256Hash(value: string): string {
    let hashString: string;
    if (value !== null) {
      hashString = shajs('sha256').update({ value }).digest('hex');
    }
    return hashString;
  }

  public byteToHash(b: number[]): number[] {
    const sha256 = shajs('sha256').update(b).digest();
    return Array.from(sha256);
  }

  public to256HashFromFile(file: File): Promise<number[]> {
    return new Promise((resolve, rejects) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binString = window.atob(reader.result.toString().split(',')[1]);
        const len1 = binString.length;
        const bytes = new Uint8Array(len1);
        for (let i = 0; i < len1; i++) {
          bytes[i] = binString.charCodeAt(i);
        }
        const sha256 = shajs('sha256').update(bytes).digest();
        resolve(Array.from(sha256));
      };
      reader.readAsDataURL(file);
    });
  }

}
