import { SequenceOutputDataModel } from 'app/app-model/diagnostic-service/sequence-output-data.model';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { OperationalDataAxisType, OperationalDataVariableType } from 'app/app-model/operational-data/operational-data-enums';

export class OperationalDataModel {
  resourceId: string;
  name: string;
  description = '';
  type: OperationalDataVariableType;
  cols: number;
  rows: number;
  sequence: ServiceExecutionSequenceModel = new ServiceExecutionSequenceModel();
  xAxis: OperationalDataAxisModel;
  yAxis: OperationalDataAxisModel;
  periodLength: number;
  periodUnit: number;
  unit: string;
}

export class OperationalDataAxisModel {
  type: OperationalDataAxisType;
  breakpoints: OperationalDataAxisBreakpointModel[];
  unit: string;
}

export class OperationalDataAxisBreakpointModel {
  value: number;
  description: string;
}

export class MatrixValue {
  id: number;
  row: number;
  col: number;
  output: SequenceOutputDataModel;
}

export class DefaultValue {
  output: SequenceOutputDataModel;
}

export class OperationalDataFile {
  data: string;
}
