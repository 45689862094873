/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { ParameterDataModel } from 'app/app-model/diagnostic-service/parameter-data.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { EcuIdentifierService } from 'app/app-services/ecu-identification.service';
import { NavigationService } from 'app/app-services/navigation.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';
import { MessageType } from 'app/modules/shared/model/service/message-type';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import {
  CategoryItemsDiagnosticServicesComponent,
} from 'app/specification-structure/category-items-diagnostic-services/category-items-diagnostic-services.component';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { BehaviorSubject, Observable } from 'rxjs';

import { DiagnosticServiceViewType } from '../../app-model/enums';
import { Transition } from '../../app-model/state-chart/state-chart.model';
import { DiagnosticServiceDataCategoryModel } from '../../data-categories/diagnostic-service-data-category-model';
import { CategoryComponentBaseComponent } from '../../modules/shared/model/category-component-base';
import { DcodeGraph } from '../../modules/shared/model/graph/dcode-graph';
import { Edge } from '../../modules/shared/model/graph/edge';
import { Node } from '../../modules/shared/model/graph/node';
import { MessageService } from '../../modules/shared/services/message-service.service';

// eslint-disable-next-line no-shadow
enum DiagnosticServiceSubcategory {
  Parameters,
  Preconditions,
  Transitions
}

@Component({
  selector: 'app-diagnostic-services',
  templateUrl: './diagnostic-services.component.html',
  styleUrls: ['./diagnostic-services.component.css']
})
export class DiagnosticServicesComponent extends CategoryComponentBaseComponent<DiagnosticServiceCategoryItem> implements OnInit {

  @ViewChild('activateRemoveParameterConfirm', { static: true })
  activateRemoveParameterConfirm: ElementRef;

  @ViewChild('categoryItemsViewRef', { static: true })
  categoryItemsViewRef: CategoryItemsDiagnosticServicesComponent;

  public RequestMessageName = 'request';
  public ResponseMessageName = 'response';

  updateStructure: BehaviorSubject<any> = new BehaviorSubject(null);
  viewId = 1;
  transitionGraphs: Array<DcodeGraph> = [];
  removeParameterActionToPerform: () => any;

  private _selectedParameter: ParameterData;
  private _selectedDiagnosticServiceSubcategory = DiagnosticServiceSubcategory.Parameters;
  private _diagnosticServiceItem: DiagnosticServiceCategoryItem;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private router: Router,
    protected specificationService: SpecificationService,
    protected categoryService: DataCategoriesService,
    protected messageService: MessageService,
    private specificationStatusService: SpecificationStatusService,
    private diagnosticServiceProvider: DiagnosticServiceProvider,
    private ecuIdentifierService: EcuIdentifierService,
    protected navigationService: NavigationService,
    private authService: AuthService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.Services, navigationService);
  }

  public get diagnosticServiceItem(): DiagnosticServiceCategoryItem {
    return (this.diagnosticServiceDataCategory.selectedItem as DiagnosticServiceCategoryItem);
  }

  get isReadOnly() {
    return !this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion) &&
      (!this.specificationService.isLegacyVersion || !this.specificationService.isUpgradedVersion);
  }

  get diagnosticServiceDataCategory() {
    return this.category as DiagnosticServiceDataCategoryModel;
  }

  get selectedDiagnosticServiceSubcategory() {
    return this._selectedDiagnosticServiceSubcategory;
  }

  set selectedDiagnosticServiceSubcategory(value) {
    this._selectedDiagnosticServiceSubcategory = value;
  }

  get parametersViewSet() {
    return this.selectedDiagnosticServiceSubcategory === DiagnosticServiceSubcategory.Parameters;
  }

  get preconditionsViewSet() {
    return this.selectedDiagnosticServiceSubcategory === DiagnosticServiceSubcategory.Preconditions;
  }

  get transitionsViewSet() {
    return this.selectedDiagnosticServiceSubcategory === DiagnosticServiceSubcategory.Transitions;
  }

  get currentItemId() {
    return this.diagnosticServiceDataCategory.selectedItem.id;
  }

  get currentItemName() {
    return this.diagnosticServiceDataCategory.selectedItem.name;
  }

  get requestParameters() {
    if (this.diagnosticServiceDataCategory.selectedItem === undefined || this.diagnosticServiceDataCategory.selectedItem === null) {
      return [];
    } else {
      return (this.diagnosticServiceDataCategory.selectedItem as DiagnosticServiceCategoryItem).allRequestParameters;
    }
  }

  get requestValueParameters() {
    return this.requestParameters.filter(x => x.type === 'Value');
  }

  get responseParameters() {
    if (this.diagnosticServiceDataCategory.selectedItem) {
      return (this.diagnosticServiceDataCategory.selectedItem as DiagnosticServiceCategoryItem).allResponseParameters;
    } else {
      return [];
    }
  }

  get isOdxBasedSpecification(): boolean {
    return this.specificationService.currentSpecificationVersion.specificationType === 1;
  }

  public getParameterItemHeader(item: any) {
    if (item.hexValue && item.name && item.hexValue !== item.name) {
      return `(${item.hexValue}) ${item.name}`;
    } else if (item.hexValue && !item.name) {
      return item.hexValue;
    } else {
      return item.name;
    }
  }

  public get selectedParameter(): ParameterData {
    return this._selectedParameter;
  }

  public set selectedParameter(v: ParameterData) {
    this._selectedParameter = v;
  }

  public get parmeterCount(): string {
    const requestParamsCount = this.requestParameters ? this.requestParameters.length : 0;
    const responseParamsCount = this.responseParameters ? this.responseParameters.length : 0;

    return (requestParamsCount + responseParamsCount) + '';
  }

  public get chartPreconditions() {
    return this.diagnosticServiceDataCategory.selectedItem.chartPreconditions;
  }

  public get transitions() {
    return this.diagnosticServiceDataCategory.selectedItem.transitions;
  }

  public get isLegacyVersion() {
    return this.specificationService.isLegacyVersion;
  }

  public get isUpgradedVersion() {
    return this.specificationService.isUpgradedVersion;
  }

  setParametersView() {
    this.selectedDiagnosticServiceSubcategory = DiagnosticServiceSubcategory.Parameters;
    this.router.navigate(['/specification', this.specification.id, this.specificationVersion.id, this.category.id],
      { queryParams: { viewId: +this.viewId, itemId: this.currentItemName, subView: +this.selectedDiagnosticServiceSubcategory } });
  }

  setPreconditionsView() {
    this.selectedDiagnosticServiceSubcategory = DiagnosticServiceSubcategory.Preconditions;
    this.router.navigate(['/specification', this.specification.id, this.specificationVersion.id, this.category.id],
      { queryParams: { viewId: +this.viewId, itemId: this.currentItemName, subView: +this.selectedDiagnosticServiceSubcategory } });
  }

  setTransitionsView() {
    this.selectedDiagnosticServiceSubcategory = DiagnosticServiceSubcategory.Transitions;
    this.router.navigate(['/specification', this.specification.id, this.specificationVersion.id, this.category.id],
      { queryParams: { viewId: +this.viewId, itemId: this.currentItemName, subView: +this.selectedDiagnosticServiceSubcategory } });
  }

  ngOnInit() {
    this.listenOnItemChanged();
    this.listenOnParametersChanged();
  }

  setSelectedParameter(paramIdentifier: string, messageType: string): void {
    const allParameters = this.requestParameters.concat(this.responseParameters);
    // if(paramIdentifier == null){
    //   this.selectedParameter = allParameters[0];
    //   return;
    // }
    if (!messageType && (this.hasDiagnosticServicesFromDcodeDb())) {
      this.selectedParameter = allParameters.find(p => p.model.id === +paramIdentifier);
    } else {
      if (messageType === this.RequestMessageName) {
        this.selectedParameter = this.requestParameters.find(param => param.name === paramIdentifier);
      } else if (messageType === this.ResponseMessageName) {
        this.selectedParameter = this.responseParameters.find(param => param.name === paramIdentifier);
      }
    }
  }

  createRequestParameter() {
    const categoryItem = (this.diagnosticServiceDataCategory.selectedItem as DiagnosticServiceCategoryItem);
    const createdParameter = categoryItem.createRequestParameter();

    this.saveCreatedParameter(createdParameter).subscribe(updatedParameter => {
      const currentDiagnosticService = (this.diagnosticServiceDataCategory.selectedItem as DiagnosticServiceCategoryItem);
      currentDiagnosticService.addParameter(updatedParameter, MessageType.Request);
      this.diagnosticServiceProvider.setPreviewFlag(currentDiagnosticService.name, true);
      this.navigationService.navigateToDiagnosticServiceParam(this.viewId, currentDiagnosticService.name, updatedParameter.id, 0);
    });
  }

  createResponseParameter() {
    const categoryItem = (this.diagnosticServiceDataCategory.selectedItem as DiagnosticServiceCategoryItem);
    const createdParameter = categoryItem.createResponseParameter();

    this.saveCreatedParameter(createdParameter).subscribe(updatedParameter => {
      const currentDiagnosticService = (this.diagnosticServiceDataCategory.selectedItem as DiagnosticServiceCategoryItem);
      currentDiagnosticService.addParameter(updatedParameter, MessageType.Response);
      this.diagnosticServiceProvider.setPreviewFlag(currentDiagnosticService.name, true);
      this.diagnosticServiceProvider.sortServiceParametersByBitAndBytePostion(currentDiagnosticService.name, updatedParameter.messageId);

      // modify ecuidentifiers that are connected to diagnostic services using the parameter
      this.ecuIdentifierService.addOutputToConnectedEcuIdentifiers(currentDiagnosticService, updatedParameter, this.specificationService.currentSpecificationVersion.id).subscribe(result => { });
      this.navigationService.navigateToDiagnosticServiceParam(this.viewId, currentDiagnosticService.name, updatedParameter.id, 0);
    });
  }

  removeSelectedParameter() {
    this.removeParameterActionToPerform = this.removeSelectedParameterAction;
    this.activateRemoveParameterConfirm.nativeElement.click();
  }

  removeSelectedParameterAction() {
    /**
     * In order to remove the selected parameter, we need to find the message it belongs to and its type.
     */
    const categoryItem = (this.diagnosticServiceDataCategory.selectedItem as DiagnosticServiceCategoryItem);
    const messageType = categoryItem.getMessageById(this.selectedParameter.model.messageId);

    this.removeParameter(this.selectedParameter, messageType.messageType);
  }

  removeParameter(parameterToRemove: ParameterData, messageType: MessageType) {
    const categoryItem = (this.diagnosticServiceDataCategory.selectedItem as DiagnosticServiceCategoryItem);

    this.saveRemovedParmeter(parameterToRemove).subscribe(removedParam => {
      const parameterToSelectNext = categoryItem.removeParameter(removedParam, messageType);

      const currentDiagnosticService = (this.diagnosticServiceDataCategory.selectedItem as DiagnosticServiceCategoryItem);
      this.diagnosticServiceProvider.setPreviewFlag(currentDiagnosticService.name, true);

      if (parameterToSelectNext) {
        this.navigationService.navigateToDiagnosticServiceParam(this.viewId, currentDiagnosticService.name, parameterToSelectNext.model.id, 0);
      } else {
        this.navigationService.navigateToDiagnosticService(currentDiagnosticService.name);
      }
    });
  }

  onRemoveParameterConfirm() {
    if (this.removeParameterActionToPerform) {
      this.removeParameterActionToPerform();
    }
  }

  saveCreatedParameter(model: ParameterDataModel): Observable<ParameterDataModel> {
    return this.diagnosticServiceProvider.saveParameter(model, this.diagnosticServiceItem.name);
  }

  saveRemovedParmeter(parameterData: ParameterData): Observable<ParameterDataModel> {
    return this.diagnosticServiceProvider.removeParameter(parameterData.model.id);
  }

  onParameterChanged(changedParameter: any) {
    if (changedParameter instanceof ParameterData) {
      if (changedParameter.model) {
        this.diagnosticServiceProvider.updateParameter(changedParameter.model, this.diagnosticServiceItem.name).subscribe(updatedParamModel => {
          changedParameter.model = updatedParamModel;
        });
      }
    }
  }

  notifyServiceChanged() {
    this.diagnosticServiceProvider.updateDiagnosticService(this.diagnosticServiceDataCategory.selectedItem.model).subscribe(updatedServiceModel => {
      //this.categoryItemsViewRef.onCurrentItemNameChanged(updatedServiceModel.name);
      this.navigationService.navigateToDiagnosticService(updatedServiceModel.name);
    });
  }

  editingNameDone(name: string) {
    if (name.length > 0 && this.diagnosticServiceProvider.nameIsAvailable(name)) {
      this.diagnosticServiceDataCategory.selectedItem.model.name = name;
      this.notifyServiceChanged();
    } else {
      this.messageService.dispatchWarningMessage(`Invalid name ${name}. \n Check that the name is not empty or is already used by another diagnostic service.`);
    }
  }

  clear() {
    this.selectedParameter = null;
  }

  navigateToParameter(parameter: ParameterData, messageType: string) {
    /**
     * Navigation strategy to diagnostic service parameters is decided by the specification type.
     * OdxBased specifications doesn't have an Id and need to be navigated by Name.
     */
    const parameterId = this.hasDiagnosticServicesFromDcodeDb() ? parameter.model.id : parameter.name;

    this.router.navigate(['/specification', this.specification.id, this.specificationVersion.id, this.category.id], {
      queryParams: {
        viewId: this.viewId,
        itemId: this.diagnosticServiceDataCategory.selectedItem.name,
        parameterId,
        messageType: this.hasDiagnosticServicesFromDcodeDb() ? '' : messageType,
        subView: +this.selectedDiagnosticServiceSubcategory
      }
    });
  }

  hasDiagnosticServicesFromDcodeDb(): boolean {
    return this.specificationService.isLegacyVersion || this.specificationService.isUpgradedVersion || this.specificationService.isNativeVersion;
  }

  createGraph(transition: Transition): DcodeGraph {
    if (!transition) {
      return;
    }

    const graph = new DcodeGraph();
    const source = new Node(transition.sourceState.odxId, transition.sourceState.shortName);
    const target = new Node(transition.targetState.odxId, transition.targetState.shortName);
    graph.addNode(source);
    graph.addNode(target);
    const edge = new Edge(source, target);
    graph.addEdge(edge);
    return graph;
  }

  serviceTabChanged(tabEvent: MatTabChangeEvent) {
    switch (tabEvent.index) {
      case 0:
        this.setParametersView();
        break;
      case 1:
        this.setPreconditionsView();
        break;
      case 2:
        this.setTransitionsView();
        break;
    }
  }

  private listenOnItemChanged() {
    this.componentSubscriptions.push(this.itemChanged.subscribe(item => {
      if (!this.diagnosticServiceDataCategory) {
        return;
      }

      this.diagnosticServiceDataCategory.selectedItem = item;

      if (this.diagnosticServiceDataCategory.selectedItem != null &&
        this.diagnosticServiceDataCategory.selectedItem.transitions != null &&
        this.diagnosticServiceDataCategory.selectedItem.transitions.length > 0) {
        this.transitionGraphs = [];
        this.diagnosticServiceDataCategory.selectedItem.transitions.forEach(x => {
          this.transitionGraphs.push(this.createGraph(x));
        });
      }
    }));
  }

  private listenOnParametersChanged() {
    this.componentSubscriptions.push(this.paramsChanged.subscribe(params => {
      if (params === null) {
        return;
      }

      const parameterIdOrName = params.get('parameterId') as string;
      const messageType = params.get('messageType') as string;

      if (params.get('viewId') != null) {
        this.viewId = +params.get('viewId');
      }
      this.selectedDiagnosticServiceSubcategory = +params.get('subView');

      if (this.viewId === null) {
        // Default to protocol services view
        this.viewId = +DiagnosticServiceViewType.ProtocolServices;
      }

      this.diagnosticServiceDataCategory.getDiagnosticView(this.specificationVersion.id, this.viewId).subscribe(view => {
        this.updateStructure.next({ item: this.category.activeItem, viewId: +this.viewId });
        this.setSelectedParameter(parameterIdOrName, messageType);
      }, error => {
        this.messageService.dispatchErrorMessageFromApi(error);
      });
    }));
  }

  canEditDiagnosticService(): boolean {
    return this.authService.canEditDiagnosticService();
  }
}
