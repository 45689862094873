import { SpecificationVersion } from '../../../app-model/specification-version';
import { Label } from '../../../app-model/label/label';
import { Specification } from '../../../app-model/specification';

export class View {
    id: number;
    name: string;
    createdBy: string;
    specificationVersions: SpecificationVersion[];
    basedOnLabel: Label;

    cloneView(): View {
        const clonedView = new View();
        clonedView.updateFrom(this);

        clonedView.specificationVersions = [];
        if (this.specificationVersions) {
            this.specificationVersions.forEach(pv => {
                const clonedSpecificationVersion = new SpecificationVersion();
              const clonedSpecification = new Specification();

                clonedSpecificationVersion.specification = clonedSpecification;

                clonedSpecificationVersion.id = pv.id;
                clonedSpecificationVersion.name = pv.name;
                clonedSpecification.name = pv.specification.name;

                clonedView.specificationVersions.push(clonedSpecificationVersion);
            });
        }

        return clonedView;
    }

    updateFrom(view: View) {
        this.id = view.id;
        this.name = view.name;
        this.createdBy = view.createdBy;
        this.specificationVersions = view.specificationVersions;
        this.basedOnLabel = view.basedOnLabel;
    }
}
