import { EventEmitter, Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { environment } from 'environments/environment';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { User } from '../../modules/shared/model/user';

@Injectable({
  providedIn: 'root'
})
export class SpecificationVersionHubService {

  activeUsersEventEmitter: EventEmitter<User[]> = new EventEmitter<User[]>();
  specificationVersionChanged: Subject<void> = new Subject();
  activeUsers: User[] = [];
  connected = false;
  user: User;

  private _connection: HubConnection;

  constructor(private authService: AuthService) {
    // this.init();
  }

  init() {
    const hubUrl = `${environment.hubUrl}specificationVersion`;

    this._connection = new HubConnectionBuilder()
      .withUrl(hubUrl, { transport: HttpTransportType.WebSockets })
      .withAutomaticReconnect()
      .build();

    this._connection.on('activeUsersUpdate', (users: User[]) => {
      this.activeUsers = users;
      this.activeUsersEventEmitter.emit(users);
      console.log(`${hubUrl}: activeUsersUpdate`);
    });

    this._connection.on('specificationVersionChanged', () => {
      this.specificationVersionChanged.next();
      console.log(`${hubUrl}: specificationVersionChanged`);
    });

    this._connection.onclose(() => {
      this.connected = false;
    });

    return this._connection.start()
      .then(() => {
        this.connected = true;
      })
      .catch(error => console.log(`Error starting connection to hub at ${hubUrl}`));
  }

  initHub(): Observable<any> {
    const hubUrl = `${environment.hubUrl}specificationVersion`;

    this._connection = new HubConnectionBuilder()
      .withUrl(hubUrl, {
        accessTokenFactory: () => localStorage.getItem("token")
      })
      .withAutomaticReconnect()
      .build();

    this._connection.on('activeUsersUpdate', (users: User[]) => {
      this.activeUsers = users;
      this.activeUsersEventEmitter.emit(users);
      console.log(`${hubUrl}: activeUsersUpdate`);
    });

    this._connection.on('specificationVersionChanged', () => {
      this.specificationVersionChanged.next();
      console.log(`${hubUrl}: specificationVersionChanged`);
    });

    this._connection.onclose(() => {
      this.connected = false;
    });

    console.log('Connecting to hub');
    return from(this._connection.start()).pipe(tap(() => {
      this.connected = true;
      console.log('Connected to hub');
    }),
      catchError(err => of(console.log(`Error starting connection to hub at ${hubUrl}`))));
  }

  get activeUsersAsString() {
    return this.activeUsers
      .filter(x => x)
      .map(u => u.firstName + ' ' + u.lastName)
      .join(', ');
  }

  get numberOfActiveUsers() {
    return this.activeUsers.length;
  }

  join(specificationVersionId: number) {
    this._connection.invoke('joinSpecificationVersionGroup', specificationVersionId.toString(), this.user)
      .catch(error => console.log(`Hub error: join hub ${error}`));
  }

  leave(specificationVersionId: number) {
    this._connection.invoke('leaveSpecificationVersionGroup', specificationVersionId.toString(), this.user)
      .catch(error => console.log(`Hub error: leave hub ${error}`));
  }

}
