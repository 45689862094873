<div *ngIf="loading" class="loader-container">
    <div class="loader"></div>
</div>
<div>
    <div class="sdds-banner sdds-banner-info">
        <div class="sdds-banner-body">
            Select the group that this identification belongs to. Make sure to order the identification within the group.
        </div>
    </div>
    <br />
    <div class="sdds-row">
        <div class="sdds-col-max-2 sdds-col-xxlg-2 sdds-col-xlg-2 sdds-col-lg-2 sdds-col-md-4 sdds-col-sm-6 sdds-col-xs-6 sdds-no-padding">
            <div class="sdds-dropdown sdds-dropdown-small">
                <select [disabled]="isReadOnly || !canEditCategoryItems()" name="identificationGroupInput" id="identificationGroupInput" [ngModel]="selectedIdentificationGroup" (ngModelChange)="identificationGroupChanged($event);">
                  <option *ngIf="!selectedIdentificationGroup" [ngValue]="null"></option>
                  <ng-container *ngIf="selectableIdentificationGroups">
                    <option *ngFor="let i of selectableIdentificationGroups" [ngValue]="i">{{i.name}}</option>
                  </ng-container>
                </select>
            </div>
        </div>
        <div class="sdds-col-max-10 sdds-col-xxlg-10 sdds-col-xlg-10 sdds-col-lg-10 sdds-col-md-8 sdds-col-sm-6 sdds-col-xs-6">
            <a [routerLink]="groupLink" style="margin-top: 10px; height:26px; display: inline-block; padding-top: 2px;" matTooltip="Go to identification group">
                <img src="font/iconsrc/link.png" style="width:20px;padding:0;" />
            </a>
        </div>
    </div>
    <br/>

    <div class="sdds-detail-02" *ngIf="selectedIdentificationGroup">
        Expression: {{ selectedIdentificationGroup.formattedExpression ? selectedIdentificationGroup.formattedExpression : '-' }}
    </div>
    <br />
    <div class="sdds-divider-light"></div>
    <br />
</div>