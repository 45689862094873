import { Label } from '../../../../app-model/label/label';
import { View } from '../view';

export class ExportCriteriaItem {
  id: number;
  name: string;
  isView: boolean;
  isLabel: boolean;

  constructor() {
    this.isLabel = false;
    this.isView = false;
  }

  static createForLabel(label: Label) {
    const exportItem = new this();
    exportItem.id = label.id;
    exportItem.name = label.name;
    exportItem.isLabel = true;

    return exportItem;
  }

  static createForView(view: View) {
    const exportItem = new this();
    exportItem.id = view.id;
    exportItem.name = view.name;
    exportItem.isView = true;

    return exportItem;
  }
}
