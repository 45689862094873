<div class="sdds-row mt-16 mb-16">
    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <h3>Read Id Status</h3>
    </div>

    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <button class="sdds-btn sdds-btn-secondary sdds-btn-sm mb-16" id="readIdStatusModal" [disabled]="isAddButtonDisabled||!canEditCategoryItems()">
            <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon> Add
        </button>
    </div>

    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding" *ngIf="readIdServiceExecutionSequence">
        <app-service-selector (diagnosticServicesAdded)="onDiagnosticServicesAdded($event)" [modalName]="'readIdStatusModal'" [currentServiceExecutions]="readIdServiceExecutionSequence.serviceExecutions"></app-service-selector>

        <div class="sdds-card" *ngIf="readIdServiceExecutionSequence.model && readIdServiceExecutionSequence.model.executions.length > 0">
            <div class="sdds-card-header">
                <h4 class="sdds-card-headline">
                    <span>{{readIdServiceExecutionSequence.model.executions[0].service}}</span>

                    <span class="service-actions float-right">
                        <button class="sdds-btn sdds-btn-ghost sdds-btn-sm"
                            (click)="nagivateToDiagnosticService(readIdServiceExecutionSequence.model.executions[0].service)">
                            <mat-icon fontSet="material-icons-outlined" class="md-18">arrow_forward</mat-icon>
                        </button>

                        <button class="sdds-btn sdds-btn-ghost sdds-btn-sm" *ngIf="!isReadOnly"
                            (click)="removeServiceExecution(readIdServiceExecutionSequence.model.executions[0])" [disabled]="!canEditCategoryItems()">
                            <mat-icon fontSet="material-icons-outlined" class="md-18">delete_forever</mat-icon>
                        </button>
                    </span>
                </h4>
            </div>
        </div>
    </div>

    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding" *ngIf="!readIdServiceExecutionSequence.model || readIdServiceExecutionSequence.model.executions.length <= 0">
        <div class="sdds-banner sdds-banner-info">
            <div class="sdds-banner-body">
                No services to execute where found. Add a new service to execute by selecting the <i>Add</i> option above.
            </div>
        </div>
    </div>
</div>