import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DiagnosticProtocolType, ServerExecutionModeType, SpecificationType } from 'app/app-model/enums';
import { ScommFile } from 'app/app-model/legacy-file';
import { NewSpecification } from 'app/app-model/newSpecification';
import { NewSpecificationInfo } from 'app/app-model/specification-info';
import { SpecificationVersion } from 'app/app-model/specification-version';
import { CategoryItemsService } from 'app/app-services/category-items-service';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { ScommFileImporterService } from 'app/app-services/scomm-file-importer.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { StructureService } from 'app/app-services/structure-service';
import { Input } from 'postcss';
import { iif, of } from 'rxjs';

import { mergeMap, tap } from 'rxjs/operators';
import { Specification } from '../../app-model/specification';
import { SpecificationLegacyFiles } from '../../app-services/specification-legacy-files.service';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { DialogBase } from '../dialog-base';
import { SpecificationVersionInfo } from 'app/app-model/specification-version-info';

@Component({
  selector: 'app-create-new-specification',
  templateUrl: './create-new-specification.component.html',
  styleUrls: ['./create-new-specification.component.css', '../../styles/popup.css']
})
export class CreateNewSpecificationComponent extends DialogBase implements OnInit {

  @Output()
  newSpecificationCreated: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('includeInScommYesOpt') includeInScommYesOpt: ElementRef;
  @ViewChild('includeInScommNoOpt') includeInScommNoOpt: ElementRef;

  modalClosing: EventEmitter<void> = new EventEmitter<void>();
  specificationName: string;
  specifications: Specification[];
  selectedDiagnosticProtocol: string;
  selectedServerExecutionMode: string;
  loading: boolean;
  legacyModeEnabled: boolean;
  hasUploadedLegacyServerFile: boolean;
  fileValidationMessage: string;
  legacyServerFile: ScommFile;
  selectedOption: string;
  includeInScomm: boolean;
  selectedSpecification: Specification;
  // selectedVersion: SpecificationVersion;
  templates: Specification[];
  versionsOfSelectedSpecification: SpecificationVersion[];
  selectedSpecificationVersions: SpecificationVersion[] = [];
  isMultiSelectEnabled = false;

  constructor(private scommFileImporterService: ScommFileImporterService,
    private messageService: MessageService,
    private legacyFilesService: SpecificationLegacyFiles,
    private categoryItemsService: CategoryItemsService,
    private specificationService: SpecificationService,
    private dataCategoriesService: DataCategoriesService,
    private router: Router,
    private sructureService: StructureService) {
    super();
  }

  ngOnInit() {
    this.setDefaultValues();
    this.specificationService.getSpecifications().subscribe(specs => {
      this.specifications = this.sortByName(specs);
    });
    this.sructureService.getTemplates().subscribe(templates => {
      this.templates = templates;
    });
  }

  public isNumeric(value) {
    // Use the unary plus operator (+) to convert the value to a number
    // and check if it's a finite number
    return !isNaN(value) && !isNaN(parseFloat(value));
  }

  get availableDiagnosticProtocols(): string[] {
    return Object.keys(DiagnosticProtocolType).filter(d => !this.isNumeric(d));
  }

  get availableServerExecutionModes(): string[] {
    return Object.keys(ServerExecutionModeType).filter(d => !this.isNumeric(d));
  }

  public setIncludeInScommFlag(e: boolean) {
    this.includeInScomm = e;
  }

  public setSelectedOption(e: Input) {
    if (this.selectedSpecification) {
      console.log(this.selectedSpecification.name);
      console.log(this.selectedSpecification.specificationVersions);
    }
    this.setDefaultValues();
    this.selectedOption = e.id;
  }

  public isOptionOne(): boolean {
    return this.selectedOption === "createNewSpecification";
  }

  public isOptionTwo(): boolean {
    return this.selectedOption === "cloneFromSpecification";
  }

  public isOptionThree(): boolean {
    return this.selectedOption === "cloneFromTemplate";
  }

  setDefaultValues() {
    this.selectedDiagnosticProtocol = DiagnosticProtocolType[DiagnosticProtocolType.Uds];
    this.selectedServerExecutionMode = ServerExecutionModeType[ServerExecutionModeType.Application];
    this.specificationName = '';
    this.loading = false;
    this.hasUploadedLegacyServerFile = false;
    this.fileValidationMessage = '';
    this.includeInScomm = undefined;
    this.selectedOption = "createNewSpecification";
    this.legacyServerFile = undefined;
    this.legacyModeEnabled = false;
    this.selectedSpecification = undefined;
    this.selectedSpecificationVersions = [];

    if (this.includeInScommYesOpt && this.includeInScommNoOpt) {
      this.includeInScommYesOpt.nativeElement.checked = false;
      this.includeInScommNoOpt.nativeElement.checked = false;
    }
  }

  get canApply() {
    if (this.includeInScomm === undefined || this.specificationName == '') {
      return false;
    }

    if ((this.isOptionTwo() || this.isOptionThree()) && !this.selectedSpecificationVersions) {
      return false;
    }

    return true;
  }

  xmlFileSelected(e: Event) {
    this.loading = true;
    const target: HTMLInputElement = e.target as HTMLInputElement;
    if (target.files.length === 1) {
      console.log('Validating file ' + target.files[0].name);

      this.legacyServerFile = new ScommFile();
      this.legacyServerFile.name = target.files[0].name;
      this.legacyServerFile.data = '';

      this.scommFileImporterService.getFileType(target.files[0]).pipe(
        mergeMap(fileType =>
          iif(() => fileType !== null,
            this.scommFileImporterService.getBase64(target.files[0]).pipe(tap(data => {
              this.legacyServerFile.fileType = fileType;
              this.legacyServerFile.data = data;
              this.validateFileToUpload(this.legacyServerFile);
            })),
            of(() => {
              this.messageService.dispatchErrorMessage('The file ' + this.legacyServerFile.name +
                ' is not supported by dcode. Only files with Ecu/Server/Security Access files are supported');
            })))).subscribe();
    }
  }

  validateFileToUpload(file: ScommFile) {
    this.legacyFilesService.validateScommFile(file).pipe(tap(validationResult => {
      if (validationResult.isValid) {
        this.fileValidationMessage = 'Selected file: ' + file.name;
        this.hasUploadedLegacyServerFile = true;
      } else {
        this.hasUploadedLegacyServerFile = true;
        this.fileValidationMessage = `Selected file: ${file.name} has validation errors:\r\n${validationResult.validationMessage}\r\n You can still create the specification if you want to proceed.`
          + ' ' + 'RelationalValidationMessage:' + '\n' + validationResult.relationalValidationMessage;
      }
    })).subscribe();
  }

  apply() {
    if (this.isOptionOne())
      this.createNewSpecification();
    else if (this.isOptionTwo())
      this.createClonedSpecification();
    else if (this.isOptionThree())
      this.createSpecificationFromTemplate();
  }

  cancel() {
    this.close();
    this.setDefaultValues();
  }

  close() {
    this.modalClosing.emit();
    this.setDefaultValues();
  }

  private createNewSpecification() {
    this.categoryItemsService.resetCategories();
    const newSpecification = new NewSpecification();
    newSpecification.name = this.specificationName;
    newSpecification.specificationType = this.legacyModeEnabled ? SpecificationType.Legacy : SpecificationType.OdxBased;
    newSpecification.diagnosticProtocol = DiagnosticProtocolType[this.selectedDiagnosticProtocol] as DiagnosticProtocolType;
    newSpecification.serverExecutionMode = ServerExecutionModeType[this.selectedServerExecutionMode] as ServerExecutionModeType;
    newSpecification.includeInScomm = this.includeInScomm;
    this.loading = true;

    this.specificationService.createSpecification(newSpecification, this.legacyServerFile).pipe(tap(spec => {
      this.loading = false;
      this.close();
      this.dataCategoriesService.resetCategories(true);
      this.categoryItemsService.resetCategories();
      this.router.navigate(['specification', spec.id, spec.specificationVersions[0].id]);
      this.newSpecificationCreated.emit();
    },
      error => {
        this.messageService.dispatchErrorMessageFromApi(error);
        this.loading = false;
      })).subscribe();
  }

  private createSpecificationFromTemplate() {
    this.categoryItemsService.resetCategories();
    const newSpecification = new NewSpecification();
    newSpecification.name = this.specificationName;
    newSpecification.specificationType = SpecificationType.Native;
    newSpecification.diagnosticProtocol = DiagnosticProtocolType[this.selectedDiagnosticProtocol] as DiagnosticProtocolType;
    newSpecification.serverExecutionMode = ServerExecutionModeType[this.selectedServerExecutionMode] as ServerExecutionModeType;
    newSpecification.includeInScomm = this.includeInScomm;
    this.loading = true;

    this.specificationService.createSpecification(newSpecification, undefined).pipe(tap(spec => {
      this.loading = false;
      this.close();
      this.dataCategoriesService.resetCategories(true);
      this.categoryItemsService.resetCategories();
      this.router.navigate(['specification', spec.id, spec.specificationVersions[0].id]);
      this.newSpecificationCreated.emit();
    },
      error => {
        this.messageService.dispatchErrorMessageFromApi(error);
        this.loading = false;
      })).subscribe();
  }

  private getLatestVersionFromSelectedSpecification(): SpecificationVersion {
    return this.selectedSpecification.specificationVersions
      .sort((firstVersion, otherVersion) => (firstVersion.name > otherVersion.name ? -1 : 1))[0];
  }

  private createClonedSpecification() {
    this.categoryItemsService.resetCategories();
    const specificationInfo = new NewSpecificationInfo();
    specificationInfo.name = this.specificationName;
    specificationInfo.specificationVersionTocloneFromId = this.selectedSpecificationVersions[0].id;
    specificationInfo.includeInScomm = this.includeInScomm;
    this.specificationService.createClonedSpecificationBasedOnVersion(specificationInfo).pipe(tap(spec => {
      this.loading = false;
      this.close();
      this.dataCategoriesService.resetCategories(true);
      this.categoryItemsService.resetCategories();
      this.router.navigate(['specification', spec.id, spec.specificationVersions[0].id]);
      this.newSpecificationCreated.emit();
    },
      error => {
        this.messageService.dispatchErrorMessageFromApi(error);
        this.loading = false;
      })).subscribe();
  }

  public sortByName(obj: any): any {
    obj.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return obj;
  }

  public sortByVersions(obj: any): any {
    return obj.sort((a, b) => (Number(a.name) < Number(b.name)) ? -1 : 1);
  }

  public getVersionsOfSelectedSpecifications(): any {
    if (this.selectedSpecification) {
      return this.sortByVersions(this.selectedSpecification.specificationVersions)
    }
  }
}
