<app-category-header [text]="'State Charts'"></app-category-header>

<div class="sdds-row">
    <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16"
        [class.loading]="loading">
        <app-toolbar [filterEnabled]="true" (filterTextEventEmitter)="filterText = $event"></app-toolbar>

        <div class="content-list-container">
            
            <app-common-list [list]="charts | nameFilter: filterText:'shortName'"
                [itemTemplate]="stateChartItemTemplate" (selectedItemEventEmitter)="onSelect($event)"
                [selectedItem]="selectedChart"></app-common-list>
        </div>

        <ng-template #stateChartItemTemplate let-item>
            <div>{{item.longName}}</div>
        </ng-template>
    </div>
    <div *ngIf="selectedChart"
        class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <!-- <h1>{{selectedChart.longName}}<small>{{chartTypeToText(selectedChart.type)}}</small></h1> -->
        <h2>{{selectedChart.longName}}</h2>
        <p>{{selectedChart.desc}}</p>
        <div>
            <app-graph [dcodeGraph]="dcodeGraph"></app-graph>
        </div>
        <h3>States</h3>
        <table class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let state of selectedChart.states" [ngClass]="{'success' : state.start}">
                    <td nowrap>{{state.longName}}</td>
                    <td>{{state.desc}}</td>
                </tr>
            </tbody>
        </table>
        <br />
        <h3>Transitions</h3>

        <div class="sdds-banner">
            <div class="sdds-banner-body">
                If any of the services is successfully executed, the state of the ECU changes from the specified Source
                to the specified Target state.
            </div>
        </div>
        <br />
        <table class="table">
            <thead>
                <tr>
                    <th>Source State</th>
                    <th>Target State</th>
                    <th>Transition Services</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let transition of selectedChart.transitions">
                    <td>{{transition.sourceState.shortName}}</td>
                    <td *ngIf="transition.targetState">{{transition.targetState.shortName}}</td>
                    <td *ngIf="!transition.targetState">-</td>
                    <td>
                        <ng-container *ngFor="let service of transition.services">
                            <div class="underline">
                                <a *ngIf="specificationVersion"
                                    [routerLink]="['/specification', specificationVersion.specification.id, specificationVersion.id, 'services']"
                                    [queryParams]="{itemId: service.name}">
                                    {{service.name}}
                                </a>
                            </div>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>