<div *ngIf="loading" class="loader-container">
    <div class="loader"></div>
</div>
<!-- <div class="sdds-row" [class.loading]="loading"> -->
    
<div class="sdds-row" >
    <div class="sdds-col-max sdds-col-xxlg sdds-col-xlg sdds-col-lg sdds-col-md sdds-col-sm sdds-col-xs" *ngIf="exportPreview && exportPreview.specificationVersions.length === 0 ">
        <div class="sdds-banner">
            <div class="sdds-banner-body">
                Nothing is included.
            </div>
        </div>
    </div>
    <div class="sdds-col-max sdds-col-xxlg sdds-col-xlg sdds-col-lg sdds-col-md sdds-col-sm sdds-col-xs" *ngIf="!exportPreview">
        <div class="sdds-banner">
            <div class="sdds-banner-body">
                Select a criteria to see included content.
            </div>
        </div>
    </div>
    <ng-container *ngIf="exportPreview && exportPreview.specificationVersions.length > 0">
        <div class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-6">
            <h5>Specification Versions</h5>
            <div class="w-1/3">
                <app-toolbar [filterEnabled]="true" (filterTextEventEmitter)="filter($event)"></app-toolbar>
            </div>
            <div style="overflow-y: auto;max-height:60vh; margin-top: 5px;">
                <app-common-list [isSelectable]="false" [list]="exportPreview.specificationVersions | specificationNameFilter: filterText" [itemTemplate]="exportPreviewItemTemplate"></app-common-list>
            </div>
        </div>
        <div class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-6">
            <h5>Server Identifications</h5>
            <div class="sdds-banner">
                <div class="sdds-banner-body">
                    <span>
            Identification groups order can be changed from the <b class="underline"><a
              href="/content/identification-groups">Identification Group </a></b>
          </span>
                </div>
            </div>
            <div class="w-1/2 mt-10">
                <app-toolbar [filterEnabled]="true" (filterTextEventEmitter)="filterIdentifications($event);"></app-toolbar>
            </div>
            <div style="overflow-y: auto;max-height:60vh; margin-top: 5px;">
                <app-tree [tree]="identificationTree"></app-tree>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #exportPreviewItemTemplate let-item>
    <table>
        <tr>
            <td><b>Specification:</b></td>
            <td>{{item.specification.name}}</td>
            <td>
                <a [routerLink]="['/specification', item.specification.id, item.id]">
                    <img src="font/iconsrc/link.png" style="width: 20px; margin-left: 5px;" />
                </a>
            </td>
        </tr>
        <tr>
            <td><b>Version:</b> {{item.name}}</td>
        </tr>
    </table>
</ng-template>