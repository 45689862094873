<div class="sdds-card ml-4" *ngIf="parameter.scalingData">
    <div class="sdds-card-header">
        <h5 class="sdds-card-headline">Internal Value</h5>
    </div>

    <div class="sdds-card-body mt-16">
        <div class="sdds-row mb-16">
            <div
                class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                <label for="internalDataTypeSelector">Data Type</label>
            </div>

            <div
                class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-dropdown sdds-dropdown-small sdds-on-white-bg">
                <select #internalDatatypeSelect name="internalDataTypeSelector" [(ngModel)]="internalValueDataType"
                    [disabled]="isOdxBasedSpecification || !isAllowed">
                    <option *ngIf="!internalValueDataType" disabled active></option>
                    <option *ngFor="let dataType of parameterService.getInternalValueDataTypes()">{{dataType}}</option>
                </select>
            </div>
        </div>

        <div class="sdds-row mb-16">
            <div
                class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                <label for="internalValEncodingSelector">Encoding</label>
            </div>

            <div
                class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-dropdown sdds-dropdown-small sdds-on-white-bg">
                <select name="internalValEncodingSelector" [(ngModel)]="internalValueEncoding"
                    (change)="parameterChanged.next()" [disabled]="isOdxBasedSpecification || !isAllowed">
                    <option *ngIf="!internalValueEncoding" disabled active></option>
                    <option *ngFor="let encoding of parameterService.getEncodings()">{{encoding}}</option>
                </select>
            </div>
        </div>

        <div class="sdds-row" *ngIf="isValueParameter">
            <div
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div class="sdds-checkbox-item">
                    <input class="sdds-form-input" type="checkbox" id="cbxHasInternalValConstraint"
                        [(ngModel)]="hasInternalValueConstraint" (change)="parameterChanged.next()"
                        [disabled]="isOdxBasedSpecification || !isAllowed">
                    <label class="sdds-form-label" for="cbxHasInternalValConstraint">Has internal value
                        constraint</label>
                </div>
            </div>
        </div>

        <app-editable-value-constraint *ngIf="hasInternalValueConstraint"
            [valueConstraint]="parameter.scalingData.internalConstraintData" [dataType]="internalValueDataTypeEnum"
            (valueConstraintChange)="parameterChanged.next($event)" [isOdxBasedSpecification]="isOdxBasedSpecification"
            [isAllowed]="isAllowed">
        </app-editable-value-constraint>
    </div>
</div>
