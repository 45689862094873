import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';
import { DiagnosticServiceCategoryItemGroup } from 'app/modules/shared/model/service/diagnostic-service-category-item-group';
import { DiagnosticServiceView } from 'app/modules/shared/model/service/diagnostic-service-view';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { DiagnosticServiceViewType } from '../app-model/enums';
import { DataCategoryModel } from '../data-categories/data-category-model';

@Injectable()
export class DiagnosticServiceDataCategoryModel extends DataCategoryModel {

  currentView: DiagnosticServiceView;
  loadingView: boolean;
  selectedGroupItemEventEmitter: EventEmitter<DiagnosticServiceCategoryItemGroup> = new EventEmitter();
  private _selectedItem: DiagnosticServiceCategoryItem;
  private _selectedGroupItem: DiagnosticServiceCategoryItemGroup;

  constructor(router: Router, activatedRoute: ActivatedRoute) {
    super(router, activatedRoute);
  }

  getDiagnosticView(specificationVersionId: number, viewType: DiagnosticServiceViewType) {
    this.loadingView = true;
    return this.categoryItemsService.getDiagnosticView(specificationVersionId, viewType).pipe(tap(view => {
      this.currentView = view; this.loadingView = false;
    }), catchError(e => {
      this.loadingView = false;
      return throwError(e);
    }));
  }

  set selectedItem(value) {
    this._selectedItem = value;

    if (value) {
      value.syncFromModel();
    }
  }

  get selectedItem() {
    return this._selectedItem;
  }

  set selectedGroupItem(groupItem) {
    this._selectedGroupItem = groupItem;
    this.selectedGroupItemEventEmitter.emit(groupItem);
  }

  get selectedGroupItem() {
    return this._selectedGroupItem;
  }
}
