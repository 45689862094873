import { Component, OnInit } from '@angular/core';
import { KeysetService } from '../app-services/keyset.service';
import { MessageService } from '../modules/shared/services/message-service.service';
import { Keyset } from '../app-model/key/keyset';
import { KeysetItem } from '../app-model/key/keyset-item';
import { DiagnosticFamilyService } from '../app-services/diagnostic-family.service';
import { FamilyGenerationPair } from '../app-model/family-generation-pair';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-keyset',
  templateUrl: './keyset.component.html',
  styleUrls: ['./keyset.component.css']
})
export class KeysetComponent implements OnInit {

  loading = false;
  keysets: Keyset[] = [];
  selectedKeyset: Keyset;
  selectedKeysetItem: KeysetItem;
  familyGenerationPairs = [];
  keysetFilter: string;
  constructor(private keysetService: KeysetService, private messageService: MessageService, private diagnosticFamilyService: DiagnosticFamilyService,
    private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.loading = true;

    this.keysetService.getKeysets().subscribe(x => {
      this.keysets = x;
      this.loading = false;

      this.activatedRoute.paramMap.subscribe(map => {
        const id = +map.get('id');

        if (id) {
          this.selectedKeyset = this.keysets.find(k => k.id === id);
        }
      });
    }, error => {
      this.loading = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });

    this.diagnosticFamilyService.getFamilies();
  }

  keysetSelected(keyset: Keyset) {
    this.router.navigate(['content/keyset/', keyset.id]);
    this.selectedKeysetItem = null;
  }

  keysetItemSelected(keysetItem: KeysetItem) {
    this.selectedKeysetItem = keysetItem;
  }

  removeSelectedKeysetItem() {
    if (!this.selectedKeyset || !this.selectedKeysetItem) {
      return;
    }

    this.keysetService.deleteKeysetItem(this.selectedKeyset.id, this.selectedKeysetItem.id).subscribe(x => {
      const index = this.selectedKeyset.items.indexOf(this.selectedKeysetItem);
      this.selectedKeyset.items.splice(index, 1);
      this.selectedKeysetItem = index - 1 > 0 ? this.selectedKeyset.items[index - 1] : null;
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  removeSelectedKeyset() {
    if (!this.selectedKeyset) {
      return;
    }

    this.keysetService.deleteKeyset(this.selectedKeyset.id).subscribe(x => {
      const index = this.keysets.indexOf(this.selectedKeyset);
      this.keysets.splice(this.keysets.indexOf(this.selectedKeyset), 1);
      this.keysetSelected(index - 1 > 0 ? this.keysets[index - 1] : null);
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  keysetAdded(keyset: Keyset) {
    this.keysets = this.keysets.concat(keyset);
    this.keysetSelected(keyset);
  }

  keysetItemAdded(keysetItem: KeysetItem) {
    this.selectedKeyset.items = this.selectedKeyset.items.concat(keysetItem);
    this.selectedKeysetItem = keysetItem;
  }

  keysetNameChanged(newName: string) {
    const oldName = this.selectedKeyset.name;

    this.selectedKeyset.name = newName;

    this.keysetService.updateKeyset(this.selectedKeyset).subscribe(x => {

    }, error => {
      this.selectedKeyset.name = oldName;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  familyGenerationsUpdated(familyGenerationPairs: FamilyGenerationPair[]) {
    const oldFamilyGenerationPairs = this.selectedKeyset.familyGenerationPairs;
    this.selectedKeyset.familyGenerationPairs = familyGenerationPairs;
    this.keysetService.updateKeyset(this.selectedKeyset).subscribe(data => {
      this.selectedKeyset.familyGenerationPairs = data.familyGenerationPairs;
    }, error => {
      this.selectedKeyset.familyGenerationPairs = oldFamilyGenerationPairs;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }
}
