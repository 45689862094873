<div class="sdds-row">
    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
        <h1>Export Label</h1>

        <div class="float-right">
            <button type="button" class="sdds-btn sdds-btn-secondary sdds-btn-sm mr-10" id="buildLabelModal" [disabled]="!canCreateExportLabel()">
          Add New Build Label
        </button>
            <button type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm" [class.loading]="exporting" (click)="performExport();" [disabled]="!canExport||!canExportByLabel()">Export Label</button>
        </div>

        <div class="clear-right"></div>

        <div class="sdds-dropdown sdds-dropdown-small w-1/5 ml-">
            <span class="sdds-dropdown-label-outside">Browse criteria</span>
            <select name="itemInput" id="itemInput" [(ngModel)]="selectedItem" (ngModelChange)="selectItem($event);">
                  <optgroup label="Label">
                    <option *ngFor="let item of labelItems" [ngValue]="item">
                      {{item.name}}
                    </option>
                  </optgroup>
            </select>
        </div>
    </div>
</div>

<div class="my-20">
    <app-global-export-preview [item]="selectedItem"></app-global-export-preview>
</div>

<app-build [labels]="labelItems" (labelsUpdated)="onLabelsUpdate($event)"></app-build>