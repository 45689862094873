import { Label } from 'app/app-model/label/label';
import { SpecificationVersion } from 'app/app-model/specification-version';
import { SpecificationService } from 'app/app-services/specification-service';
import { MessageService } from 'app/modules/shared/services/message-service.service';

import { TreeService } from '../service/tree.service';
import { TreeNode } from './tree-node';
import { SpecificationType } from 'app/app-model/enums';

export class SpecificationVersionTreeNode extends TreeNode {
  loading: boolean;
  specificationVersion: SpecificationVersion;
  openAction: (p: SpecificationVersion) => void;
  deleteAction: (p: SpecificationVersion) => void;
  releaseAction: (p: SpecificationVersion) => void;
  specificationService: SpecificationService;
  messageService: MessageService;

  private _canOpen: () => boolean;
  private _canDelete: () => boolean;


  constructor(specificationVersion: SpecificationVersion,
    openAction: (p: SpecificationVersion) => void,
    deleteAction: (p: SpecificationVersion) => void,
    releaseAction: (p: SpecificationVersion) => void,
    canOpen: () => boolean,
    canDelete: () => boolean,
    treeService: TreeService,
    specificationService: SpecificationService,
    messageService: MessageService) {
    super(specificationVersion.name, treeService);
    this.specificationVersion = specificationVersion;
    this.openAction = openAction;
    this.deleteAction = deleteAction;
    this.releaseAction = releaseAction;
    this._canOpen = canOpen;
    this._canDelete = canDelete;
    this.specificationService = specificationService;
    this.messageService = messageService;
  }

  performOpenAction(): void {
    if (this.canOpen) {
      this.openAction(this.specificationVersion);
    }
  }

  performDeleteAction(): void {
    if (this.canDelete()) {
      this.deleteAction(this.specificationVersion);
    }
  }

  performReleaseAction(): void {
    this.releaseAction(this.specificationVersion);
  }

  get icon(): string {
    return 'article';
  }

  get canOpen(): boolean {
    return this._canOpen && this._canOpen();
  }

  // get canDelete(): boolean {
  //   return this._canDelete && this._canDelete();
  // }

  get isInWorkStatus(): boolean {
    return this.specificationVersion.releaseStatus === 0;
  }

  get isInReleaseStatus(): boolean {
    return this.specificationVersion.releaseStatus === 1;
  }

  get isLegacyVersion(): boolean {
    return this.specificationVersion.specificationType === SpecificationType.Legacy;
  }

  get labels(): Label[] {
    return this.specificationVersion.labels;
  }

  get isSpecificationVersionTreeNode(): boolean {
    return true;
  }

  get isOpen(): boolean {
    return this.specificationService.currentSpecificationVersion && this.specificationService.currentSpecificationVersion.id === this.specificationVersion.id;
  }

  canDelete(): boolean {
    return this.deleteAction && this.isInWorkStatus && this._canDelete();
  }

  containsText(text: string): boolean {

    let partOfLabels = false;

    if (this.labels) {
      partOfLabels = this.labels.some(l => this.areTextsEqual(l.name, text));
    }

    return super.containsText(text) || partOfLabels;
  }

  onReleaseModalClose() {
    if (this.loading) {
      this.loading = false;
    }
  }
}
