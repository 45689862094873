import * as CodeMirror from 'codemirror';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpecificationService } from 'app/app-services/specification-service';
import { LegacyContentModel } from 'app/app-model/legacyContent.model';
import * as vkbeautify from 'vkbeautify';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { outputAst } from '@angular/compiler';

@Component({
  selector: 'app-xml-code-viewer',
  templateUrl: './xml-code-viewer.component.html',
  styleUrls: ['./xml-code-viewer.component.css']
})
export class XmlCodeViewerComponent implements OnDestroy, AfterViewInit {

  @Input() titleName: string;
  @Input() bannerText: string;
  @Input() readModeOnly: boolean;
  @Input() xmlString: string;
  @Output() modifiedData = new EventEmitter<any>();
  @Output() delete = new EventEmitter();
  @ViewChild('codeEditor') codeEditorElemRef: ElementRef;

  private _codeEditor: CodeMirror.Editor;
  specificationEventSubscription: Subscription;
  legacyContentModel: LegacyContentModel;
  contentChanged = false;

  constructor(private specificationService: SpecificationService,
    private authService: AuthService) { }

  ngOnDestroy(): void {
    if (this.specificationEventSubscription) {
      this.specificationEventSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.specificationEventSubscription = this.specificationService.selectedSpecificationEventEmitter.subscribe(spec => {
      if (spec.specification) {
        this.initCodeEditor();
        this.loadXmlContent();
        this.initCodeEditorEvents();
      }
    });
  }

  saveChanges() {
    this.modifiedData.emit(this.getEncodedStringFromXml(this._codeEditor.getValue()));
  }

  deleteDocument(): void {
    this.delete.emit();
  }

  canSaveChanges(): boolean {
    return this.specificationService.currentSpecificationVersion.releaseStatus === 0;
  }

  loadXmlContent() {
    const prettyXml = vkbeautify.xml(atob(this.xmlString));
    this._codeEditor.setValue(prettyXml);
    setTimeout(() => {
      this._codeEditor.refresh();
    }, 5);
  }

  getXmlDisplayString(encodedString: string): string {
    return atob(encodedString);
  }

  getEncodedStringFromXml(xmlString: string): string {
    return btoa(xmlString)
  }

  private initCodeEditor(): void {
    this.contentChanged = false;

    if (this._codeEditor) {
      return;
    }

    const element = this.codeEditorElemRef.nativeElement;
    const editorOptions: CodeMirror.EditorConfiguration = {
      lineNumbers: true,
      readOnly: this.readModeOnly,
      styleActiveLine: true,
      theme: 'ttcn',
      mode: 'application/xml',
    };
    this._codeEditor = CodeMirror(element, editorOptions);
  }

  private initCodeEditorEvents(): void {
    this._codeEditor.on('change', () => {
      this.contentChanged = true;
    });
  }

  canEditCategoryItems(): boolean {
    return this.authService.canEditCategoryItems();
  }
}
