import { Component, OnInit } from '@angular/core';
import { CategoryComponentBaseComponent } from 'app/modules/shared/model/category-component-base';
import { IvdCategoryItem } from './ivd-category-item';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { ActivatedRoute } from '@angular/router';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { NavigationService } from 'app/app-services/navigation.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-ivd-override',
  templateUrl: './ivd-override.component.html',
  styleUrls: ['./ivd-override.component.css']
})
export class IvdOverrideComponent extends CategoryComponentBaseComponent<IvdCategoryItem> implements OnInit {

  ivdOverrideItem: IvdCategoryItem

  constructor(activatedRoute: ActivatedRoute,
    private specificationStatusService: SpecificationStatusService,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    messageService: MessageService,
    protected navigationService: NavigationService,
    private authService: AuthService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.IvdOverrides, navigationService);
  }

  ngOnInit(): void {
    this.itemChanged.subscribe(item => {
      this.ivdOverrideItem = item;
    });
  }

  get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  canEditCategoryItems(): boolean {
    return this.authService.canEditCategoryItems();
  }
}
