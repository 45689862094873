<ng-container *ngIf="computationalMethod">
    <div class="sdds-radio-button-group">
        <div class="sdds-radio-item">
            <input class="sdds-form-input" type="radio" name="textTableOption"
                [readonly]="isOdxBasedSpecification || !isAllowed" [id]="'optionsRadios1_'+isInternalToPhysical"
                [disabled]="!hasValidTextTableDataTypes || isOdxBasedSpecification || !isAllowed"
                [checked]="isTextTable" (change)="enableTextTable()" value="option1">
            <label class="sdds-form-label" [for]="'optionsRadios1_'+isInternalToPhysical">
                Text table (Requires&#32;<b>AsciiString</b>&#32;in physical data type)
            </label>
        </div>
    </div>

    <div class="sdds-radio-button-group mb-16">
        <div class="sdds-radio-item">
            <input class="sdds-form-input" type="radio" name="linearFormulaOption"
                [readonly]="isOdxBasedSpecification || !isAllowed" [id]="'optionsRadios2_'+isInternalToPhysical"
                [disabled]="!hasValidLinearFormulaDataTypes || isOdxBasedSpecification || !isAllowed"
                [checked]="isLinearFormulaData" (change)="enableLinearFormula()" value="option2">
            <label class="sdds-form-label" [for]="'optionsRadios2_'+isInternalToPhysical">
                Linear formula (Requires numerical data type)
            </label>
        </div>
    </div>


    <div class="sdds-row">
        <div
            class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
            <div *ngIf="isTextTable" [ngStyle]="{'pointer-events': hasValidTextTableDataTypes ? 'all' : 'none'}"
                class="sdds-row">

                <div *ngIf="!isValidComputationalMethod()"
                    class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <div class="sdds-banner sdds-banner-error">
                        <div class="sdds-banner-body">
                            Unsupported physical data type. Physical data type must be <b>AsciiString</b> in a text
                            table. Changes will not be saved.
                        </div>
                    </div>
                </div>

                <div
                    class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-on-white-bg mb-16">
                    <label for="defIntervaltext">Default interval text</label>
                    <div class="w-6/12">
                        <input name="defIntervaltext" class="sdds-textfield-input-md" type="text"
                            [(ngModel)]="computationalMethod.defaultInterval" (change)="onChange()"
                            [readonly]="isOdxBasedSpecification || !isAllowed">
                    </div>
                </div>

                <div
                    class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
                    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" (click)="addNewInterval()"
                        [disabled]="isOdxBasedSpecification || !isAllowed">
                        Add new interval <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon>
                    </button>
                </div>

                <div *ngIf="!sortedTextTableIntervals || sortedTextTableIntervals.length <= 0"
                    class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
                    <div class="sdds-banner sdds-banner-info">
                        <div class="sdds-banner-body">
                            Text table is empty. To add a new interval, please click on the <b>Add new interval</b>
                            option above
                        </div>
                    </div>
                </div>

                <div *ngIf="sortedTextTableIntervals && sortedTextTableIntervals.length > 0"
                    class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
                    <table class="table">
                        <thead>
                            <th scope="col">Interval Name</th>
                            <th scope="col">Range</th>
                            <th scope="col">Inverse Value</th>
                            <th scope="col">Inverse Value To Use</th>
                            <th scope="col"></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of sortedTextTableIntervals">
                                <td>
                                    <input type="text" [(ngModel)]="item.text" (change)="onChange()"
                                        [readOnly]="isOdxBasedSpecification || !isAllowed">
                                </td>
                                <td>
                                    <app-editable-range [range]="item.range"
                                        [isOdxBasedSpecification]="isOdxBasedSpecification"
                                        (rangeChange)="onChange($event)" [isAllowed]="isAllowed"></app-editable-range>
                                </td>
                                <td>
                                    <ng-container *ngIf="item.inverseInternalValue">
                                        <input type="text" placeholder="NotSet"
                                            [(ngModel)]="item.inverseInternalValue.data" readonly>
                                    </ng-container>
                                    <ng-container *ngIf="!item.inverseInternalValue">
                                        <input type="text" placeholder="-" readonly>
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngIf="item.inverseInternalValue">
                                        <input type="text" placeholder="NotSet"
                                            [(ngModel)]="item.inverseInternalValue.data" readonly>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="!item.inverseInternalValue && item.lowerType !== DataLimitType.Open">
                                        <input type="text" placeholder="NotSet" [(ngModel)]="item.range.lowerValue.data"
                                            readonly>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="!item.inverseInternalValue && item.lowerType === DataLimitType.Open">
                                        <input type="text" placeholder="-" readonly>
                                    </ng-container>
                                </td>
                                <td>
                                    <button class="sdds-btn sdds-btn-ghost sdds-btn-sm" *ngIf="!isOdxBasedSpecification"
                                        (click)="removeTextTableInterval(item)" [disabled]="!isAllowed">
                                        <mat-icon fontSet="material-icons-outlined"
                                            class="md-18">delete_forever</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div *ngIf="isLinearFormulaData" [ngStyle]="{'pointer-events': hasValidLinearFormulaDataTypes ? 'all' : 'none'}"
            class="sdds-row">
            <div *ngIf="!isValidComputationalMethod()"
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div class="sdds-banner sdds-banner-error">
                    <div class="sdds-banner-body">
                        <ng-container *ngIf="isInternalToPhysical">
                            The current internal data type <b>{{OdxDataType[internalDataType]}}</b> is not supported in
                            a linear formula. Please choose a <b>numerical</b> data type. Your changes will <b>not</b>
                            be saved
                        </ng-container>

                        <ng-container *ngIf="!isInternalToPhysical">
                            The current physical data type <b>{{OdxDataType[physicalDataType]}}</b> is not supported in
                            a linear formula. Please choose a <b>numerical</b> data type. Your changes will <b>not</b>
                            be saved
                        </ng-container>
                    </div>
                </div>
            </div>

            <div
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">

                <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg" style="width: 140px">
                    <span class="sdds-dropdown-label-outside"> <b>Formula type</b> </span>
                    <select [(ngModel)]="selectedLinearFormulaType" name="linearFormulaType" (change)="onChange()"
                        [disabled]="isReadOnly || !isAllowed">
                        <option *ngFor="let formulaType of availableLinearFormulaTypes"
                            [ngValue]="LinearFormulaType[formulaType]">{{formulaType}}</option>
                    </select>
                </div>

                <div *ngIf="hasFixedFormulaType" class="mt-16">
                    <form role="form">
                        <fieldset [disabled]="!hasNumericalDataType">
                            <span>
                                <label for="numeratorConstant">Numerator constant:</label>
                                <input class="w-52 ml-16" type="number" id="numeratorConstant"
                                    [(ngModel)]="numeratorConstant" [ngModelOptions]="{standalone: true}"
                                    placeholder="c" (change)="onChange()"
                                    [readonly]="isOdxBasedSpecification || !isAllowed">
                            </span>

                            <span class="ml-10">
                                <label for="numeratorCoefficient">Numerator coefficient:</label>
                                <input class="w-52 ml-10" type="number" id="numeratorCoefficient"
                                    [(ngModel)]="numeratorCoefficient" [ngModelOptions]="{standalone: true}" min="1"
                                    placeholder="nc" (change)="onChange()"
                                    [readonly]="isOdxBasedSpecification || !isAllowed">
                            </span>

                            <span class="ml-10">
                                <label for="denominatorConstant">Denominator constant:</label>
                                <input class="w-52 ml-10" type="number" id="denominatorConstant"
                                    [(ngModel)]="denominatorConstant" [ngModelOptions]="{standalone: true}" min="1"
                                    placeholder="dc" (change)="onChange()"
                                    [readonly]="isOdxBasedSpecification || !isAllowed">
                            </span>
                        </fieldset>
                    </form>

                    <div class="mt-10">
                        <span class="sdds-dropdown-label-outside"> <b>Preview</b> </span>
                        <i>{{numeratorConstant}} + X * {{numeratorCoefficient}} / {{denominatorConstant}}</i>
                    </div>
                </div>

                <div *ngIf="hasTextFormulaType" class="mt-16">
                    <mat-form-field>
                        <mat-label>Linear Formula</mat-label>
                        <input matInput type="text" [(ngModel)]="formulaAsStr" (change)="onChange()"
                            [readonly]="!isAllowed">
                        <button class="sdds-btn sdds-btn-ghost sdds-btn-sm" *ngIf="formulaAsStr" matSuffix
                            mat-icon-button aria-label="Clear" (click)="clearTextFormula()" [disabled]="!isAllowed">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</ng-container>
