import { RangeModel, DataLimitType } from 'app/app-model/diagnostic-service/range.model';
import { TypedValueData, OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';

export class RangeData {
    private _model: RangeModel;
    private _lowerValue: TypedValueData;
    private _upperValue: TypedValueData;

    constructor(range: RangeModel) {
        this.model = range;
    }

    public get model(): RangeModel {
        return this._model;
    }
    public set model(v: RangeModel) {
        this._model = v;
    }

    changeDataType(dataType: OdxDataType) {
        this.model.lowerValue.dataType = dataType;
        this.model.upperValue.dataType = dataType;
    }

    setRangeDefaultValue(value: TypedValueData) {
        this.model.lowerValue = value;
        this.model.upperValue = value;
    }
}