<div class="sdds-row mt-16 mb-16">
  <div class="sdds-col-max-12  sdds-no-padding">
    <h3>Postrequisites</h3>
  </div>

  <div class="sdds-col-max-12 sdds-no-padding mb-10">
    <span class="sdds-checkbox-item" style="display: initial;">
      <input class="sdds-form-input" type="checkbox" [disabled]="isReadOnly||!canEditCategoryItems()"
        name="ecuResetCommand" id="ecuResetCommand" [checked]="ecuResetEnabled" (change)="onActivateEcuReset()">
      <label class="sdds-form-label" style="padding-left: 0px !important;" for="ecuResetCommand">
        Ecu Reset
      </label>
    </span>
  </div>

  <div class="flex" *ngIf="ecuResetEnabled">
    <div class="flex-none">
      <mat-form-field appearance="fill">
        <mat-label>Ecu Reset</mat-label>
        <input type="text" matInput matNativeControl placeholder="Select an Ecu Reset" [formControl]="ecuResetControl"
          [matAutocomplete]="ecuResetAutoComplete" [(ngModel)]="selectedEcuReset"
          [ngStyle]="{'color':postrequisitesUpdate? 'green' : '' }" />
        <mat-autocomplete #ecuResetAutoComplete="matAutocomplete">
          <mat-option *ngFor="let ecuReset of filteredEcuResets | async" [value]="ecuReset">
            <p class="break-words text-5xl">{{ ecuReset }}</p>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="flex-initial">
      <button class="sdds-btn sdds-btn-secondary sdds-btn-md ml-10 sdds-no-padding" id="confirmPostRequisiteBtn"
        (click)="confirmEnteredEcuReset()" [disabled]="!canConfirmEcuReset||!canEditCategoryItems()">
        Update
      </button>
    </div>
  </div>

  <div class="sdds-col-max-12 sdds-no-padding">
    <label class="text-blue-600" *ngIf="canConfirmEcuReset">Press on update to save changes</label>
  </div>
</div>