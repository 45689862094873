/* eslint-disable @typescript-eslint/naming-convention */
import { Directive, Input } from '@angular/core';
import { ServiceExecutionModel } from 'app/app-model/diagnostic-service/service-execution.model';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { PropertyParameterDirective } from 'app/modules/shared/model/service/parameters/property.parameter';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

// eslint-disable-next-line no-shadow
export enum ServiceExecutionType {
  Request,
  Response,
  Undefined
}

@Directive()
export class ServiceExecutionDirective {

  @Input()
  isCollapsed: boolean;

  specificationVersionId: number;
  diagnosticServiceProvider: DiagnosticServiceProvider;
  syncDone: BehaviorSubject<boolean> = new BehaviorSubject(false);
  modified: Subject<void> = new Subject();

  /** A list of both request and response parameters */
  availableParameters: ParameterData[];

  /** A list of both request and response parameters that can be assigned to a property.*/
  availablePropertyParameters: PropertyParameterDirective[];

  /** A list of request parameters that can be assigned to a property */
  availableRequestPropertyParameters: PropertyParameterDirective[];

  /** A list of response parameters that can be assigned to a property */
  availableResponsePropertyParameters: PropertyParameterDirective[];

  private _serviceExecutionModel: ServiceExecutionModel = new ServiceExecutionModel();

  public get model(): ServiceExecutionModel {
    return this._serviceExecutionModel;
  }
  public set model(v: ServiceExecutionModel) {
    this._serviceExecutionModel = v;
    this.syncFromModel();
  }

  private _diagnosticService: DiagnosticServiceCategoryItem;
  public get diagnosticService(): DiagnosticServiceCategoryItem {
    return this._diagnosticService;
  }
  public set diagnosticService(v: DiagnosticServiceCategoryItem) {
    if (!v) {
      const i = 0;
    }

    this._diagnosticService = v;
  }

  setModel(model: ServiceExecutionModel): Observable<any> {
    this._serviceExecutionModel = model;

    if (model.command) {
      return of(true);
    }
    if (!this.diagnosticServiceProvider) {
      return of(false);
    } else {
      return this.diagnosticServiceProvider.getDiagnosticServiceByName(this.specificationVersionId, this._serviceExecutionModel.service)
        .pipe(
          tap(diagnosticService => {
            if (diagnosticService) {
              this.diagnosticService = diagnosticService;
              this.updateAvailableParameters();
            }
          }));
    }
  }

  syncFromModel() {
    if (!this.diagnosticServiceProvider) {
      return;
    }

    this.syncDiagnosticServiceFromModel();
  }

  syncDiagnosticServiceFromModel() {
    this.diagnosticServiceProvider.getDiagnosticServiceByName(this.specificationVersionId, this._serviceExecutionModel.service).subscribe(diagnosticService => {
      if (diagnosticService) {
        this.diagnosticService = diagnosticService;
        this.updateAvailableParameters();
      }
      this.syncDone.next(true);
    });
  }

  updateAvailableParameters() {
    this.availableParameters = this.diagnosticService.filteredRequestValueParameters.concat(this.diagnosticService.filteredResponseValueParameters);

    this.availableRequestPropertyParameters = this.diagnosticService.filteredRequestValueParameters.map<PropertyParameterDirective>(param => {
      const requestParameter = new PropertyParameterDirective(param);
      const requestParameterInModel = this.model.requestParameters.find(rp => rp.path[0].step === param.name);

      /**Assigned values must be synchronized in order to be shown correctly in the GUI */
      if (requestParameterInModel && requestParameterInModel.value) {
        requestParameter.assignedValue.dataType = requestParameterInModel.value.dataType;
        requestParameter.assignedValue.data = requestParameterInModel.value.data;
        requestParameter.validateParameterValue();
      }

      return requestParameter;
    });

    this.availableResponsePropertyParameters = this.diagnosticService.filteredResponseValueParameters.map<PropertyParameterDirective>(param =>
      new PropertyParameterDirective(param)
    );

    this.availablePropertyParameters = this.availableRequestPropertyParameters.concat(this.availableResponsePropertyParameters);
  }

  /** Checks if the specified property parameter belongs this service execution */
  hasPropertyParameter(propertyParameter: PropertyParameterDirective): boolean {
    if (!propertyParameter) {
      return false;
    }

    if (!this.availableParameters) {
      return false;
    }

    return this.availableParameters.find(param =>
      param.model.name === propertyParameter.parameter.model.name &&
      param.sourceService === propertyParameter.parameter.sourceService) != null;
  }

  hasParameterWithId(id: number): boolean {
    return this.availableParameters.find(param => param.model.id === id) != null;
  }

  getServiceExecutionTypes(): ServiceExecutionType[] {
    const types = [];

    if (this.diagnosticService && this.diagnosticService.filteredRequestValueParameters.length > 0) {
      types.push(ServiceExecutionType.Request);
    }

    if (this.diagnosticService && this.diagnosticService.filteredResponseValueParameters.length > 0) {
      types.push(ServiceExecutionType.Response);
    }

    if (types.length === 0) {
      types.push(ServiceExecutionType.Undefined);
    }

    return types;
  }

  notifyServiceExecutionChanged() {
    this.modified.next();
  }
}
