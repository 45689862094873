import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { ValuesService } from 'app/app-services/values.service';
import { ValueCategoryItemDirective } from 'app/data-categories/values/value-category-item';
import { FunctionType } from 'app/modules/shared/components/function/functions';
import { Category } from 'app/specification-structure/category-items/category.enum';

import { CategoryComponentBaseComponent } from '../../modules/shared/model/category-component-base';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { NavigationService } from 'app/app-services/navigation.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-values',
  templateUrl: './values.component.html',
  styleUrls: ['./values.component.css']
})
export class ValuesComponent extends CategoryComponentBaseComponent<ValueCategoryItemDirective> implements OnInit {

  valueItem: ValueCategoryItemDirective;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FunctionType = FunctionType;

  constructor(activatedRoute: ActivatedRoute,
    private specificationStatusService: SpecificationStatusService,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    navigationService: NavigationService,
    messageService: MessageService, private valuesService: ValuesService,private authService:AuthService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.Values, navigationService);
  }

  get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  ngOnInit() {
    this.componentSubscriptions.push(this.itemChanged.subscribe(item => {
      this.valueItem = item;
    }));
  }

  enableFunction(f: FunctionType, isEnabled: boolean) {
    this.valueItem.enableFunction(f, isEnabled, true);
    this.valueItem.notifyItemChanged();
  }

  editingNameDone(name: string) {
    if (name.trim().length > 0) {
      this.valueItem.setName(name.trim());
    } else {
      this.messageService.dispatchErrorMessage('Value name can not be empty!');
    }
  }

  onResetChanged() {
    this.valueItem.notifyItemChanged();
  }

  updateDescription(){
    this.valueItem.model.description = this.valueItem.model.description.trim();
    this.valueItem.notifyItemChanged();
  }

  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
