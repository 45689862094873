import { MessageData } from 'app/modules/shared/model/service/message-data';
import {ChartPrecondition} from 'app/modules/shared/model/service/state-chart';
import { Transition } from '../state-chart/state-chart.model';

export class DiagnosticServiceModel {
    id: number;
    name: string;
    index: number;
    specificationContentId: number;
    specificationVersionId: number;
    messages: MessageData[];
    specificationContent: any;
    chartPreconditions: ChartPrecondition[];
    transitions: Transition[];
    isTemplateService: boolean;
}
