/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export class LengthModel {
    id: number;
    kind: LengthKind;
    bitLength: number;
    minByteLength: number;
    maxByteLength: number;
    hasMaxByteLength: boolean;
    termination: TerminationType;
}

export enum LengthKind {
    Standard,
    MinMax
}

export enum TerminationType {
    ValueZero,
    ValueFf,
    ValueZeroOrFf,
    EndOfMessage
}
