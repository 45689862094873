import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-id-code-field',
  templateUrl: './id-code-field.component.html',
  styleUrls: ['./id-code-field.component.css']
})

export class IdCodeFieldComponent implements OnInit, OnDestroy {

  @Input()
  isEditingAllowed: boolean;

  @Input()
  public isCanAddressVal: boolean;

  @Input()
  public darkMode = false;

  @Input()
  isAllowed: boolean;

  @Output()
  hexCodeChanged: EventEmitter<number> = new EventEmitter();
  hexCodeError: EventEmitter<number> = new EventEmitter();

  isValidHexCode: boolean;
  changed = false;
  hexCodeInputChanged = new Subject<string>();
  private hexCodeInputChangedSubscription: Subscription;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private KeyboardEnter = 'Enter';
  private _hexCode: number;
  private _hexCount: number;
  private _codeField: string;

  constructor() {
    this.listenForHexCodeInputChanges();
  }

  @Input()
  public set hexCode(rawHexCode: number) {
    if (rawHexCode) {
      this._hexCode = rawHexCode;
      this.updateHexCodeFormat();
    }
  }

  public get hexCode(): number {
    return this._hexCode;
  }

  get hexCodeAsString(): string {
    return `0x${this.hexCode.toString(16).replace('0x', '').padStart(this.hexCount, '0').toUpperCase()}`;
  }

  public get hexCount(): number {
    return this._hexCount;
  }

  @Input()
  public set hexCount(v: number) {
    this._hexCount = v;
    this.updateHexCodeFormat();
  }

  public get codeField(): string {
    return this._codeField ? this._codeField : '';
  }
  public isNumeric(value) {
    // Use the unary plus operator (+) to convert the value to a number
    // and check if it's a finite number
    return !isNaN(value) && !isNaN(parseFloat(value));
  }
  @Input()
  public set codeField(v: string) {
    if (this.isNumeric(v)) {
      this._hexCode = parseInt(v, 16);

      if (this.hexCode === 0) {
        this.isValidHexCode = false;
      } else {
        this._codeField = this.hexCodeAsString;
        this.isValidHexCode = true;
      }
    } else {
      this.isValidHexCode = false;
    }
  }

  get suggestedHexStringFormat(): string {
    return `0x${''.padStart(this.hexCount, 'X')}`;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === this.KeyboardEnter) {
      const target = event.target as any;
      if (target.value) {
        this.hexCodeInputChanged.next(target.value);
      }
    }
  }

  ngOnInit() {
    if (!this.hexCount) {
      this.hexCount = 1;
    }
  }

  ngOnDestroy(): void {
    if (this.hexCodeInputChangedSubscription) {
      this.hexCodeInputChangedSubscription.unsubscribe();
    }
  }

  updateHexCodeFormat() {
    if (this.hexCode) {
      this.codeField = this.hexCodeAsString;
    }
  }

  setContentChanged() {
    this.changed = true;
  }

  private listenForHexCodeInputChanges() {
    this.hexCodeInputChangedSubscription = this.hexCodeInputChanged.pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(value => {
        this.codeField = value;
        this.notifyChanges();
      });
  }

  private notifyChanges() {
    if (this.isValidHexCode && this.changed) {
      this.hexCodeChanged.next(this.hexCode);
      this.changed = false;
    }
  }
}
