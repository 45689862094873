import { Injectable } from '@angular/core';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { ApiProxyService } from 'app/modules/shared/services/api-proxy.service';
import { environment } from 'environments/environment';
import { Observable, Observer, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { SecurityAccessModel } from '../app-model/security-access/security-access.model';
import { SecurityAccessCategoryItemDirective } from '../data-categories/security-access/security-access-category-item';
import { MessageService } from '../modules/shared/services/message-service.service';
import { CategoryServiceBase } from './category-base.service';
import { SpecificationService } from './specification-service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SecurityAccessService extends CategoryServiceBase<SecurityAccessModel, SecurityAccessCategoryItemDirective> {

  securityAccessItemsChanged: Subject<void> = new Subject();

  constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
    private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected apiProxy: ApiProxyService,
    private messageService: MessageService) {
    super(apiProxy);
    this.apiCategoryName = 'modes';
  }

  getItemsPreview(specificationVersionId: number): Observable<SecurityAccessCategoryItemDirective[]> {
    this.isPending = true;

    if (this.hasCachedItems) {
      return new Observable((observer: Observer<SecurityAccessCategoryItemDirective[]>) => {
        observer.next(this.items);
        observer.complete();
      });
    } else {
      return this.apiProxy.get<SecurityAccessModel[]>(`${environment.apiUrl}versions/${specificationVersionId}/modes`).pipe(map(securityModes => {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < securityModes.length; i++) {
          const securityAccess = new SecurityAccessCategoryItemDirective(this.specificationService, this.locationService, this.router, this.activatedRoute);
          securityAccess.securityAccessService = this;
          securityAccess.diagnosticServiceProvider = this.diagnosticServiceProvider;
          securityAccess.model = securityModes[i];
          if (!this.items.find(item => item.name === securityAccess.name)) {
            this.items.push(securityAccess);
          }
        }

        this.hasCachedItems = true;
        this.isPending = false;
        this.getItemsPreviewDone.next(this.isPending);

        return this.items;
      }));
    }
  }

  getCompleteItems(specificationVersionId: number): Observable<SecurityAccessCategoryItemDirective[]> {
    throw new Error('Method not implemented.');
  }

  createItem(specificationVersionId: number, itemName?: string): Observable<SecurityAccessCategoryItemDirective> {
    if (this.items.find(item => item.name === itemName)) {
      this.messageService.dispatchErrorMessage('A security access item with name ' + itemName + ' already exists');
      throw new Error('A security access item with name ' + itemName + ' already exists');
    }

    const itemToCreate = new SecurityAccessModel();
    itemToCreate.name = itemName;
    itemToCreate.requestSeedSequence = new ServiceExecutionSequenceModel();
    itemToCreate.sendKeySequence = new ServiceExecutionSequenceModel();

    return this.apiProxy.post<SecurityAccessModel>(`${environment.apiUrl}versions/${specificationVersionId}/modes/`, itemToCreate).pipe(map(item => {
      const securityAccessCategoryItem = new SecurityAccessCategoryItemDirective(this.specificationService, this.locationService, this.router, this.activatedRoute);
      securityAccessCategoryItem.securityAccessService = this;
      securityAccessCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      securityAccessCategoryItem.isPreview = false;
      securityAccessCategoryItem.model = item;

      this.items.push(securityAccessCategoryItem);
      this.securityAccessItemsChanged.next();
      return securityAccessCategoryItem;
    }));
  }

  getCategoryItemModel(specificationVersionId: number, name: string): Observable<SecurityAccessCategoryItemDirective> {
    return this.apiProxy.get<SecurityAccessCategoryItemDirective>(`${environment.apiUrl}versions/${specificationVersionId}/modes/${name}/`).pipe(map(item => {
      const securityAccessCategoryItem = new SecurityAccessCategoryItemDirective(this.specificationService, this.locationService, this.router, this.activatedRoute);
      securityAccessCategoryItem.securityAccessService = this;
      securityAccessCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      securityAccessCategoryItem.isPreview = false;
      securityAccessCategoryItem.model = item;

      this.items.push(securityAccessCategoryItem);
      this.securityAccessItemsChanged.next();
      return securityAccessCategoryItem;
    }));
  }

  deleteItem(specificationVersionId: number, itemName?: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.apiProxy.delete(`${environment.apiUrl}versions/${specificationVersionId}/modes/${itemName}`).subscribe(result => {
        observer.next(result);
        this.securityAccessItemsChanged.next();
        observer.complete();
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public updateItem(specificationVersionId: number, SecurityAccessDataModel: any, itemName?: string): Observable<SecurityAccessModel> {
    return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/modes/${itemName}/`, SecurityAccessDataModel);
  }

  public getModeItem(specificationVersionId: number, itemName: string): Observable<SecurityAccessCategoryItemDirective> {
    return this.apiProxy.get<SecurityAccessCategoryItemDirective>(`${environment.apiUrl}versions/${specificationVersionId}/modes/${itemName}/`);
  }
}
