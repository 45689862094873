import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SpecificationType } from 'app/app-model/enums';
import { LegacyConversionService } from 'app/app-services/legacy-conversion.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { UserService } from 'app/app-services/user.service';
import {
  ServerIdentificationCategoryItemComponent,
} from 'app/data-categories/server-identification/server-identification-category-item';
import { User } from 'app/modules/shared/model/user';
import { Subscription } from 'rxjs';

import { Specification } from '../../../app-model/specification';
import { SpecificationVersion } from '../../../app-model/specification-version';
import { IdentificationGroupService } from '../../../app-services/identification-group.service';
import { ServerIdentificationService } from '../../../app-services/server-identification.service';
import { ObservableExecutor, ObservableTask } from '../../../modules/shared/model/observable-executor';
import { MessageService } from '../../../modules/shared/services/message-service.service';
import { IdentificationGroupToOrderComponent } from './identification-group-order/identification-group-order.component';

@Component({
  selector: 'app-version-release',
  templateUrl: './release.component.html',
  styleUrls: ['./release.component.css']
})

export class VersionReleaseComponent implements OnDestroy {

  @Output()
  public modalClosing: EventEmitter<void> = new EventEmitter<void>();

  public loading: boolean;
  serverIdentifications: ServerIdentificationCategoryItemComponent[];
  currentSpecificationVersionName: string;
  currentUser: User;

  private releaseStatus = 0;
  private _currentSpecificationVersion: SpecificationVersion;
  private _currentSpecification: Specification;
  private subscription: Subscription;
  private groupsToOrder: IdentificationGroupToOrderComponent[] = [];
  private _showEventEmitter: EventEmitter<void>;
  public get showEventEmitter(): EventEmitter<void> {
    return this._showEventEmitter;
  }

  @Input()
  public set showEventEmitter(v: EventEmitter<void>) {
    this._showEventEmitter = v;

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = v.subscribe(() => {

      this.loading = true;

      const getSpecificationTask = new ObservableTask<any>(this.specificationService
        .getSpecification(this.specificationService.currentSpecification.id), (specification: Specification) => {
          this.currentSpecification = specification;
          this.currentSpecificationVersion = specification.specificationVersions
            .find(pv => pv.id === this.specificationService.currentSpecificationVersion.id);
          this.currentSpecificationVersionName = this.currentSpecificationVersion.name;

          this.releaseStatus = this.currentSpecificationVersion.releaseStatus;
        });

      const specificationExecutor = new ObservableExecutor<any>([getSpecificationTask]);

      specificationExecutor.onSuccessEventEmitter.subscribe(() => {
        this.loading = false;
      });

      specificationExecutor.onErrorEventEmitter.subscribe((error) => {
        this.messageService.dispatchErrorMessage(error);
        this.loading = false;
      });

      specificationExecutor.start();
    });
  }

  constructor(private specificationService: SpecificationService,
    private messageService: MessageService,
    private identificationGroupService: IdentificationGroupService,
    private serverIdentificationService: ServerIdentificationService,
    private legacyConversionService: LegacyConversionService,
    private router: Router,
    private userService: UserService) {
    this.getCurrentUser();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  navigateToIdentificationGroup() {
    this.close();
    this.router.navigate(
      [
        '/content/identification-groups',
      ]
    );
  }

  get currentSpecificationVersion(): SpecificationVersion {
    return this._currentSpecificationVersion;
  }

  set currentSpecificationVersion(specificationVersion: SpecificationVersion) {
    this._currentSpecificationVersion = specificationVersion;
  }

  get currentSpecification(): Specification {
    return this._currentSpecification;
  }

  set currentSpecification(specification: Specification) {
    this._currentSpecification = specification;
  }

  get currentSpecificationVersionReleased(): boolean {
    return this.releaseStatus === 1;
  }

  specificationVersionFromSpecification(specificationVersionId: number): SpecificationVersion {
    return this.currentSpecification.specificationVersions.find(p => p.id === specificationVersionId);
  }

  close() {
    this.groupsToOrder = [];
    this.modalClosing.emit();
    this.loading = false;
  }

  release() {
    if (this.currentSpecificationVersionReleased) {
      this.close();
      return;
    }
    this.applyRelease();
  }

  getCurrentUser(): void {
    this.userService.getCurrentUser().subscribe(user => {
      this.currentUser = user;
    });
  }

  applyRelease() {
    const observableTasks: ObservableTask<any>[] = [];

    if (this.currentSpecificationVersion.comment) {
      this.currentSpecificationVersion.commentByUser = this.currentUser.userName;
    }

    const updateSpecificationVersionObservable =
      this.specificationService.releaseSpecificationVersion(this.currentSpecificationVersion.id, this.currentSpecificationVersion);

    const observableTask = new ObservableTask(updateSpecificationVersionObservable, (updatedSpecificationVersion: SpecificationVersion) => {
      this.specificationService.currentSpecificationVersion.releaseStatus = updatedSpecificationVersion.releaseStatus;
    });

    observableTasks.push(observableTask);

    const observableExecutor = new ObservableExecutor<any>(observableTasks);

    observableExecutor.onSuccessEventEmitter.subscribe(() => {
      this.loading = false;
        this.specificationService.sendSpecificationUpdatedEvent();
      this.close();
    });

    observableExecutor.onErrorEventEmitter.subscribe((e) => {
      this.loading = false;
      this.messageService.dispatchErrorMessage(e);
    });

    this.loading = true;
    observableExecutor.start();
  }
}
