<app-dialog-base applyCaption="Yes" title="Move expressions to operator" modalId="moveExpressionsToOperatorModal"
  [itemTemplate]="moveExpressionsToOperatorTemplate" (modalApply)="apply()" (modalCancel)="cancel()"></app-dialog-base>

<ng-template #moveExpressionsToOperatorTemplate>
  <div>
    <p class="alert alert-info">Only one expression node is allowed at this level. For more expression nodes, a logic
      operator(And/Or) is needed.
      <br />
      Do you want to move the existing expression node and the new one to a logic operator?</p>
  </div>
</ng-template>