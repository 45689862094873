import {
  ServiceExecutionResponseValue,
} from 'app/app-model/diagnostic-service/sequence-execution-response-parameter-data.model';
import { BaseProperty } from 'app/modules/shared/model/properties/base-property';

export class OutputProperty extends BaseProperty {

  linkAssignedParameter() {
    if (!this.serviceExecutionSequence) {
      return;
    }

    if (this.previousSelectedProperty) {
      this.unlinkPreviousSelectedProperty();
    }

    const parameterToAssign = this.findResponseParameter(this.selectedPropertyParameter.parameter.name);
    parameterToAssign.target.variable = this.name;

    this.selectedPropertyParameter.usedByProperties = [this.name];
    this.selectedPropertyParameter.owner = this;
  }

  unlinkSilent(): void {
    throw new Error('Method not implemented.');
  }

  unlink(): void {
    if (!this.serviceExecutionSequence) {
      return;
    }
    if (!this.selectedPropertyParameter) {
      return;
    }

    const matchingResponseParameter = this.findResponseParameter(this.selectedPropertyParameter.parameter.name);

    if (matchingResponseParameter) {
      matchingResponseParameter.target.variable = undefined;
      this.removeThisPropertyNameFromAssignedParameter();
      this.selectedPropertyParameter = null;
    }
  }

  reset() {
    if (!this.serviceExecutionSequence) {
      return;
    }
    if (!this.selectedPropertyParameter) {
      return;
    }

    const matchingResponseParameter = this.findResponseParameter(this.selectedPropertyParameter.parameter.name);

    if (matchingResponseParameter) {
      matchingResponseParameter.target.variable = undefined;
      this.removeThisPropertyNameFromAssignedParameter();
    }
  }

  syncFromModel(): void {
    const matchingParamIdInResponseParam = this.findResponseParameterWithVariableName(this.name);

    if (matchingParamIdInResponseParam && matchingParamIdInResponseParam.target.variable) {
      this.selectedPropertyParameter = this.serviceExecutionSequence.getPropertyParameterByName(matchingParamIdInResponseParam.path[0].step);
    }
  }

  syncFromModelSilent(): void {
    const matchingParamIdInResponseParam = this.findResponseParameterWithVariableName(this.name);

    if (!matchingParamIdInResponseParam) {
      return;
    }

    this.setSelectedPropertyParameterSilent(this.serviceExecutionSequence.getPropertyParameterByName(matchingParamIdInResponseParam.path[0].step));

    if (this.selectedPropertyParameter) {
      this.selectedPropertyParameter.usedByProperties = [this.name];
    }
  }

  unlinkPreviousSelectedProperty() {
    const previousValue = this.findResponseParameter(this.previousSelectedProperty.parameter.name);
    previousValue.target.variable = undefined;

    const propToRemoveIndex = this.previousSelectedProperty.usedByProperties.findIndex(prop => prop === this.name);

    if (propToRemoveIndex > -1) {
      this.previousSelectedProperty.usedByProperties.splice(propToRemoveIndex, 1);
    }
  }

  private findResponseParameterWithVariableName(variableName: string) {
    let matchingResponseParam: ServiceExecutionResponseValue;

    this.serviceExecutionSequence.model.executions.forEach(service => {
      const foundParam = service.responseParameters.find(responseParam => this.hasRepsonseParamWithVariable(responseParam, variableName));

      if (foundParam) {
        matchingResponseParam = foundParam;
        return;
      }
    });

    return matchingResponseParam;
  }

  private findResponseParameter(paramName: string): ServiceExecutionResponseValue {
    let matchingResponseParam: ServiceExecutionResponseValue;

    this.serviceExecutionSequence.model.executions.forEach(service => {
      const foundParam = service.responseParameters.find(responseParam => this.hasRepsonseParamWithStep(responseParam, paramName));

      if (foundParam) {
        matchingResponseParam = foundParam;
        return;
      }
    });

    return matchingResponseParam;
  }

  private hasRepsonseParamWithStep(parameter: ServiceExecutionResponseValue, step: string) {
    return parameter && parameter.path && parameter.path.length > 0 && (step === parameter.path[0].step);
  }

  private hasRepsonseParamWithVariable(parameter: ServiceExecutionResponseValue, variableName: string): unknown {
    return parameter && parameter.target && (parameter.target.variable === variableName);
  }
}
