<ng-container *ngIf="valueConstraint">
    <div class="sdds-row mt-12 mb-16">
        <div
            class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
            <h6>Valid range</h6>
        </div>

        <app-editable-range [range]="valueConstraint.validRangeData.model" (rangeChange)="onChanged($event)"
            [isAllowed]="isAllowed">
        </app-editable-range>
    </div>

    <div class="sdds-row">
        <div
            class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
            <h6>Constraint items</h6>
        </div>

        <div
            class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
            <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" (click)="addNewValueConstraintItem()"
                [disabled]="isOdxBasedSpecification || !isAllowed">
                Add new interval <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon>
            </button>
        </div>

        <div
            class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
            <table class="table">
                <thead>
                    <th>Type</th>
                    <th>Range</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of valueConstraint.valueConstraintItems">
                        <td>
                            <select name="valConstraintType" [(ngModel)]="item.type" (change)="onChanged()"
                                [disabled]="isOdxBasedSpecification || !isAllowed">
                                <option *ngIf="!item.type" disabled active></option>
                                <option *ngFor="let dataType of getValueConstraintDataTypes()">{{dataType}}</option>
                            </select>
                        </td>
                        <td>
                            <app-editable-range [range]="item.range.model" (rangeChange)="onChanged($event)"
                                [isOdxBasedSpecification]="isOdxBasedSpecification"
                                [isAllowed]="isAllowed"></app-editable-range>
                        </td>
                        <td>
                            <button class="sdds-btn sdds-btn-ghost sdds-btn-sm" *ngIf="!isOdxBasedSpecification"
                                (click)="removeConstraintItem(item)" [disabled]="!isAllowed">
                                <mat-icon fontSet="material-icons-outlined" class="md-18">delete_forever</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>