/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LegacyFileModificationService } from 'app/app-services/legacy-file-modification.service';
import { LegacyFileToolbarService } from 'app/app-services/legacy-file-toolbar-service.service';
import { UserService } from 'app/app-services/user.service';
import {
  SharedFilesFilterOptions,
} from 'app/data-categories/shared-files/shared-files-assigner/shared-files-assigner.component';
import { FilePreviewComponent } from 'app/modules/shared/components/file-preview/file-preview.component';
import { User } from 'app/modules/shared/model/user';
import { Subscription } from 'rxjs';

import { ScommFile, ScommFileType, SharedFileType } from '../../app-model/legacy-file';
import { Specification } from '../../app-model/specification';
import { SpecificationService } from '../../app-services/specification-service';
import { SpecificationStatusService } from '../../app-services/specification-status.service';
import { LegacyFileToolbarComponent } from './legacy-file-toolbar/legacy-file-toolbar.component';

@Component({
  selector: 'app-legacy-files',
  templateUrl: './legacy-files.component.html',
  styleUrls: ['./legacy-files.component.css'],
})
export class LegacyFilesComponent implements OnInit, OnDestroy {

  @ViewChild('fileEditor') fileEditor: FilePreviewComponent;
  @ViewChild('toolbarRef') toolBarRef: LegacyFileToolbarComponent;

  specificationVersionSubscription: Subscription;
  legacyServerFileSubscription: Subscription;
  specificationServiceSubscripption: Subscription;
  activatedRouteSubscription: Subscription;
  selectXmlSharedFilesModal = 'selectXmlSharedFilesModal';
  loading: boolean;
  openModeEnabled: boolean;
  fileName: string;
  ScommFileType = ScommFileType;
  SharedFileType = SharedFileType;
  SharedFilesFilterOptions = SharedFilesFilterOptions;
  currentSpecification: Specification;
  currentUser: User;
  hasOtherLinkedFiles: boolean;

  private _leftContent: string;
  private _rightContent: string;
  private _specId: number;
  private _specVersionId: number;

  constructor(
    private specificationStatusService: SpecificationStatusService,
    private activatedRoute: ActivatedRoute,
    private specificationService: SpecificationService,
    private userService: UserService,
    private legacyFileModificationService: LegacyFileModificationService,
    private legacyFileToolbarService: LegacyFileToolbarService) {
  }

  get legacyModificationServiceRef(): LegacyFileModificationService {
    return this.legacyFileModificationService;
  }

  get legacyFileToolbarServiceRef(): LegacyFileToolbarService {
    return this.legacyFileToolbarService;
  }

  public get leftContent(): string {
    return this._leftContent;
  }
  public set leftContent(v: string) {
    this._leftContent = v;
  }

  public get rightContent(): string {
    return this._rightContent;
  }
  public set rightContent(v: string) {
    this._rightContent = v;
  }

  public get specId(): number {
    return this._specId;
  }
  public set specId(v: number) {
    this._specId = v;
  }

  public get specVersionId(): number {
    return this._specVersionId;
  }
  public set specVersionId(v: number) {
    this._specVersionId = v;
  }

  get getFileName(): string {
    if (this.fileName) {
      return this.fileName;
    } else if (this.currentSpecification.name) {
      return this.currentSpecification.name;
    }
  }

  get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get isUdsProtocol(): boolean {
    return this.currentSpecification.diagnosticProtocol === 1;
  }

  get showDisplayingMasterFileText() {
    return !this.legacyFileToolbarService.mergeMode &&
      !this.legacyFileToolbarService.diffMode &&
      !this.legacyFileToolbarService.viewDraftsActive;
  }

  ngOnInit() {
    if (this.activatedRoute.parent) {
      this.activatedRouteSubscription = this.activatedRoute.parent.params.subscribe(params => {
        this.specId = +params['specId'];
        this.specVersionId = +params['specVersionId'];
        this.legacyFileModificationService.hasLoadedFiles = false;
        this.specificationServiceSubscripption = this.specificationService.getCurrentSpecification(this.specId).subscribe({
          next: (specification) => {
            this.currentSpecification = specification;
            if (specification.specificationVersions) {
              this.specificationVersionSubscription =
                this.specificationService.getCurrentSpecificationVersion(this.specVersionId).subscribe({
                  next: (version) => {
                    this.legacyFileModificationService.subscribeToToolbarEvents();
                    this.legacyFileModificationService.updateAvailableScommFiles(this.specVersionId);
                  }
                });
            }
          }
        });

        this.setLegacyFileModificationServiceFields();
        this.setLegacyFileModificationServiceAccessors();
        this.hasOtherLinkedFiles = this.legacyModificationServiceRef.getOtherFiles.length > 0;
      });
    }
  }

  ngOnDestroy() {
    if (this.specificationVersionSubscription) {
      this.specificationVersionSubscription.unsubscribe();
    }

    if (this.specificationServiceSubscripption) {
      this.specificationServiceSubscripption.unsubscribe();
    }

    if (this.activatedRouteSubscription) {
      this.activatedRouteSubscription.unsubscribe();
    }

    this.legacyFileToolbarService.reset();
    this.legacyFileModificationService.reset();
  }

  setLegacyFileModificationServiceFields() {
    this.userService.getCurrentUser().subscribe(user => {
      this.legacyFileModificationService.currentUser = user;
      this.currentUser = user;
    });
  }

  get legacyServerFileName(): string {
    return this.legacyFileModificationService.currentLegacyServerFile ? this.legacyFileModificationService.currentLegacyServerFile.name : '';
  }

  setLegacyFileModificationServiceAccessors() {
    this.legacyFileModificationService.currentFileNameUpdater =
      (name) => this.updateCurrentFileName(name);

    this.legacyFileModificationService.openModeUpdater =
      (enabled) => this.updateOpenServerFileMode(enabled);

    this.legacyFileModificationService.fileEditorAccessor =
      () => this.fileEditor;
  }


  getCurrentUser(): User {
    this.userService.getCurrentUser().subscribe(user => {
      this.currentUser = user;
    });
    return this.currentUser;
  }

  updateOpenServerFileMode(enabled: boolean) {
    this.openModeEnabled = enabled;
  }

  updateCurrentFileName(name: string) {
    this.fileName = name;
  }

  whenOpenLegacyServerFile() {
    this.openModeEnabled = true;

    setTimeout(() => {
      this.legacyFileModificationService.viewMasterFile();
    }, 200);

  }

  whenLegacyFileCreated(scommFile: ScommFile) {
    this.legacyFileModificationService.updateAvailableScommFiles(this.specVersionId);
    this.fileName = scommFile.name;
    this.openModeEnabled = true;

    setTimeout(() => {
      if (this.fileEditor) {
        this.fileEditor.activeCodeEditorContent = scommFile.data;
      }
    }, 300);
  }
}
