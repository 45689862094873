<div class="break-words">
  <b *ngIf="dismissable" class="close absolute right-16 cursor-pointer" (click)="onDismiss()">
    <span aria-hidden='true'>×</span>
  </b>
  <table>
    <tr>
      <td>
        <div [ngSwitch]="messageType">
          <img src="../../../../../../font/iconsrc/warning_msg.png" *ngSwitchCase="MessageTypeEnum.Warning" />
          <img src="../../../../../../font/iconsrc/info_msg.png" *ngSwitchCase="MessageTypeEnum.Info" />
          <img src="../../../../../../font/iconsrc/info_msg.png" *ngSwitchCase="MessageTypeEnum.PerformingAction" />
          <img src="../../../../../../font/iconsrc/error_msg.png" *ngSwitchCase="MessageTypeEnum.Error" />
          <img src="../../../../../../font/iconsrc/success_msg.png" *ngSwitchCase="MessageTypeEnum.Success" />
        </div>
      </td>
      <td><b class="ml-10 mt-5">{{label}}</b></td>
    </tr>
  </table>

  <div class="mt-10" *ngIf=" message">
    <b>Message</b>
    <p [innerHTML]="message"></p>
  </div>

  <div *ngIf="details" style="overflow-y: auto; max-height: 200px;">
    <b><a (click)="toggleDetails()"
        class="cursor-pointer">{{showDetailsExpanded ? '&lt; Hide details': '&gt; Show details'}}</a></b>
    <p *ngIf="showDetailsExpanded" class="mt-10" [innerHTML]="details"></p>
  </div>
</div>
