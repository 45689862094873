import { Component, Input, OnInit } from '@angular/core';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';
import { IdentificationExpressionService } from 'app/app-services/identification-expression.service';

@Component({
  selector: 'app-execution-mode-comparison',
  templateUrl: './execution-mode-comparison.component.html',
  styleUrls: ['./execution-mode-comparison.component.css']
})
export class ExecutionModeComparisonComponent {
  @Input()
  isReadOnly: boolean;

  @Input()
  reorderEnabled = true;

  @Input()
  copyEnabled = true;

  @Input()
  isAllowed :boolean;

  @Input()
  expressionModel: ServeridentificationExpressionModel;

  constructor(private identificationExpressionService: IdentificationExpressionService) { }

  notifyServerIdentificationChanged() {
    this.identificationExpressionService.modified.next(this.expressionModel);
  }
}
