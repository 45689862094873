import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SpecificationVersion } from 'app/app-model/specification-version';
import { UserService } from 'app/app-services/user.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';

import { Label } from '../../../../app-model/label/label';
import { LabelType } from '../../../../app-model/label/label-type';
import { LabelService } from '../../../../app-services/label-service';
import { MessageService } from '../../../shared/services/message-service.service';
import { ExportCriteriaItem } from '../../model/export/export-criteria-item';
import { ExportCriteria } from '../../model/export/export-critieria';
import { View } from '../../model/view';
import { ExportService } from '../../services/export.service';
import { ViewService } from '../../services/view.service';
import {
  AddSpecificSpecificationVersionComponent,
} from './add-specific-specification-version/add-specific-specification-version.component';
import { SpecificSpecificationVersion } from './model/specific-specification-version';


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
  @ViewChild('addVersionsDiag', { static: true }) addVersionDiag: AddSpecificSpecificationVersionComponent;

  @Input()
  canRemove = true;

  @Input()
  canAdd = true;

  @Input()
  dataTarget = '#createItemModal';
  
  views: View[];
  labels: Label[];
  selectableViews: View[];
  selectedView: View;

  specificSpecificationVersions: SpecificSpecificationVersion[];
  addSpecificSpecificationVersionEventEmitter = new EventEmitter<void>();
  exportCriteriaItem = new ExportCriteriaItem();
  addViewEventEmitter = new EventEmitter<void>();
  loadingViews = false;
  loading = false;
  _selectedLabel: Label;
  viewFilterText: boolean;
  exporting: boolean;
  items: ExportCriteriaItem[];


  private _exportData: number[];
  public get exportData(): number[] {
    return this._exportData;
  }
  public set exportData(v: number[]) {
    this._exportData = v;
  }
  get doYouWantToDeleteMessage() {
     return this.selectedView
      ?'Do you want to delete package ' +
       this.selectedView.name +  '?': '';
 }

  constructor(private viewService: ViewService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private labelService: LabelService,
    private router: Router,
    private exportService: ExportService,
    private userService: UserService,
    private authService:AuthService) { }

  get selectedLabel() {
    return this._selectedLabel;
  }

  set selectedLabel(value: Label) {
    this._selectedLabel = value;
  }

  onAdd() {
    this.addSpecificSpecificationVersionEventEmitter.emit();
  }

  updateLabel(label: Label) {
    const clonedView = this.selectedView.cloneView();
    clonedView.basedOnLabel = label ? label : null;

    this.viewService.updateView(clonedView).subscribe(() => {
      this.selectedLabel = label;
      this.selectedView.basedOnLabel = label;
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  ngOnInit() {
    this.loadingViews = true;

    this.viewService.getViews().subscribe(views => {
      this.views = views;
      this.loadingViews = false;
      this.loading = true;

      this.labelService.getLabels(LabelType.Branch).subscribe(labels => {
        this.whenLabelsLoaded(labels);
      }, error => {
        this.loading = false;
        this.messageService.dispatchErrorMessageFromApi(error);
      });
    }, error => {
      this.loadingViews = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  removeSelectedSpecificSpecificationVersion(specificationToRemove: SpecificSpecificationVersion): void {
    if (!specificationToRemove) {
      return;
    }

    this.viewService.deleteSpecificationVersionFromView(this.selectedView, specificationToRemove.specificationVersion).subscribe(removedSpecificationVersion => {
      this.specificSpecificationVersions.splice(this.specificSpecificationVersions.indexOf(specificationToRemove), 1);
      this.selectedView.specificationVersions.splice(this.selectedView.specificationVersions.indexOf(removedSpecificationVersion), 1);
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  specificationVersionsAdded(specificationVersions: SpecificationVersion[]) {
    specificationVersions.forEach(specificationVersion => {
      this.viewService.addSpecificationVersionToView(this.selectedView, specificationVersion).subscribe(addedSpecificationVersion => {
        const specificSpecificationVersionItem = new SpecificSpecificationVersion(addedSpecificationVersion);
        this.specificSpecificationVersions = this.specificSpecificationVersions.concat([specificSpecificationVersionItem]);
        this.selectedView.specificationVersions = this.selectedView.specificationVersions.concat([specificSpecificationVersionItem.specificationVersion]);
        this.onAdd();
      }, error => {
        this.messageService.dispatchErrorMessageFromApi(error);
      });
    });
  }

  removeSelectedView(): void {
    if (!this.selectedView) {
      return;
    }
    this.viewService.deleteView(this.selectedView).subscribe(deletedView => {
      const orginalView = this.views.find(v => v.id === deletedView.id);
      this.views.splice(this.views.indexOf(orginalView), 1);
      this.selectedView = undefined;
      this.specificSpecificationVersions = [];
      this.selectedLabel = undefined;
      this.router.navigate(['/release/view']);
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  performExport() {
    let exportObservable = null;
    let exportName = '';
    exportObservable = this.exportService.createGlobalExport(ExportCriteria.createForView(this.selectedView.id));
    exportName = 'export_view_' + this.selectedView.name;
    this.subscribeAndHandleResponse(exportObservable, exportName);
  }

  inspectPackage() {
    this.exportCriteriaItem = ExportCriteriaItem.createForView(this.selectedView);
  }

  subscribeAndHandleResponse(exportObservable: Observable<string>, exportName: string) {
    if (!exportObservable) {
      return;
    }

    this.exporting = true;
    this.loading = true;
    exportObservable.subscribe(data => {
      FileSaver.saveAs(new Blob([this.convertToByteArray(data)]), exportName + '.zip');
      this.getItems();
      this.exporting = false;
      this.loading = false;
    }, error => {
      this.exporting = false;
      this.loading = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  getItems(done: () => void = null) {
    this.loading = true;
    this.labelService.getLabels(LabelType.Branch).subscribe(branchLabels => {
      this.whenBranchLabelsLoaded(branchLabels, done);
    }, error => {
      this.loading = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  convertToByteArray(data: string): Uint8Array {
    const raw = atob(data);
    const array = new Uint8Array(new ArrayBuffer(raw.length));
    for (let i = 0; i < raw.length; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  viewAdded(view: View) {
    this.views = [view].concat(this.views);
    this.selectView(view);
  }

  viewNameChanged(name: string) {
    const viewToUpdate = this.selectedView.cloneView();
    viewToUpdate.name = name;

    this.viewService.updateView(viewToUpdate).subscribe(() => {
      this.selectedView.name = name;
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });

  }
  selectView(view: View) {
    this.router.navigate(['/release/view', view.id]);
  }

  versionSelectorModalClosing(): void {
    console.log('modal closed');
  }

  private whenBranchLabelsLoaded(branchLabels: Label[], done: () => void) {
    this.items = branchLabels.map(x => ExportCriteriaItem.createForLabel(x));

    this.labelService.getLabels(LabelType.Build).subscribe(buildLabels => {
      this.items = this.items.concat(buildLabels.map(x => ExportCriteriaItem.createForLabel(x)));

      this.viewService.getViews().subscribe(views => {
        this.whenViewsLoaded(views, done);
      }, error => {
        this.loading = false;
        this.messageService.dispatchErrorMessageFromApi(error);
      });
    }, error => {
      this.loading = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  private whenViewsLoaded(views: View[], done: () => void) {
    this.items = this.items.concat(views.map(x => ExportCriteriaItem.createForView(x)));

    if (done) {
      done();
    }
  }

  private whenLabelsLoaded(labels: Label[]) {
    this.labels = labels;

    this.labelService.getLabels(LabelType.Build).subscribe(buildLabels => {
      this.labels = this.labels.concat(buildLabels);
      this.loading = false;

      this.activatedRoute.paramMap.subscribe(map => {
        this.whenRouteActivated(map);
      });
    }, error => {
      this.loading = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  private whenRouteActivated(map: ParamMap) {
    const viewId = +map.get('viewId');
    if (viewId) {
      const viewToSelect = this.views.find(v => v.id === viewId);
      if (viewToSelect) {
        this.selectableViews = this.views.filter(v => v.id !== viewId);
        this.selectedView = viewToSelect;

        if (this.selectedView.basedOnLabel) {
          this.selectedLabel = this.labels.find(l => l.id === this.selectedView.basedOnLabel.id);

        } else {
          this.selectedLabel = null;
        }
        this.specificSpecificationVersions = this.selectedView.specificationVersions
          .map(pv => new SpecificSpecificationVersion(pv))
          .sort((a, b) => {
            if (a.specificationVersion.specification.name < b.specificationVersion.specification.name) {
              return -1;
            }
            if (a.specificationVersion.specification.name > b.specificationVersion.specification.name) {
              return 1;
            }
            return 0;
          });
      }
    }
  }

  canEditExportPackage() {
    return this.authService.canEditExportPackage();
  }
}
