import { BaseProperty, PropertyType } from 'app/modules/shared/model/properties/base-property';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { Subject } from 'rxjs';

import { FunctionType } from './functions';

export abstract class FunctionBase {
  name: string;
  enabled: boolean;
  type: FunctionType;
  properties: Array<BaseProperty> = new Array<BaseProperty>();
  propertyType: PropertyType;
  modified: Subject<void> = new Subject();

  private _serviceExecutionSequence: ServiceExecutionSequence = new ServiceExecutionSequence();
  public get serviceExecutionSequence(): ServiceExecutionSequence {
    return this._serviceExecutionSequence;
  }

  public set serviceExecutionSequence(v: ServiceExecutionSequence) {
    this._serviceExecutionSequence = v;
    this.syncFromModel();
  }

  constructor(enabled: boolean, properties: Array<BaseProperty>) {
    this.enabled = enabled;
    this.properties = properties;
  }

  notifyFunctionModified() {
    this.modified.next();
  }

  resetProperties() {
    this.properties = [];
  }

  resetServiceExecutionSequence() {
    this.serviceExecutionSequence.serviceExecutions = [];
  }

  abstract addProperty(propertyName: string): void;

  abstract syncFromModel(): void;
}
