import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Label } from '../../../app-model/label/label';
import { ApiProxyService } from '../../shared/services/api-proxy.service';
import { BuildParameters } from '../model/export/build-parameters';

@Injectable()
export class BuildService {
  constructor(private apiProxy: ApiProxyService) {
  }

  createBuild(buildParameters: BuildParameters): Observable<Label> {
    return this.apiProxy.post(environment.apiUrl + 'build', buildParameters);
  }
}
