<sdds-modal size="sm" selector="#addView">
  <h5 slot="sdds-modal-headline">Add new package</h5>
  <div slot="sdds-modal-body">
    <div class="w-1/2">
      <sdds-textfield
        autofocus
        ngDefaultControl
        [(ngModel)]="name"
        name="viewNameInput"
        type="text"
        placeholder="Type package name"
        label-inside="Package name"
        [state]="!name ? 'error': 'default'"
      ></sdds-textfield>
    </div>
  </div>
  <button
    slot="sdds-modal-actions"
    data-dismiss-modal
    class="sdds-btn sdds-btn-secondary sdds-btn-md"
  >
    Cancel
  </button>
  <button
    slot="sdds-modal-actions"
    data-dismiss-modal
    class="sdds-btn sdds-btn-primary sdds-btn-md ml-10"
    [disabled]="applying"
    (click)="addView(name)"
    [disabled]="!name"
  >
    Ok
  </button>
</sdds-modal>
