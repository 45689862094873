import { EventEmitter } from '@angular/core';
import { SharedFile } from 'app/app-model/legacy-file';

import { TreeService } from '../service/tree.service';
import { SharedFileTreeNode } from './shared-file-tree.node';
import { SharedFileVersionTreeNode } from './shared-file-version-tree-node';
import { SpecificationVersionTreeNode } from './specification-version-tree-node';
import { StructureSpecificationTreeNode } from './structure-specification-tree-node';

export class TreeNode {
  selectTreeNodeEventEmitter: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  expandedEventEmitter: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  visibleEventEmitter: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  isSharedFileTreeNode: boolean;
  specificationFile: SharedFile;
  isDiagnosticServiceTreeNode: boolean;
  name: string;
  children: TreeNode[];
  isSelected: boolean;
  isEditable: boolean;
  editAction: (arg0: TreeNode, string) => void;
  filterMatch: boolean;

  private _isVisible: boolean;
  private _isExpanded: boolean;
  private _isStructureSpecificationTreeNode: boolean;
  private _isInWorkStatus: boolean;
  private _isSpecificationVersionTreeNode: boolean;
  private _isSharedFileSpecificationVersionTreeNode: boolean;

  constructor(name: string, private _treeService: TreeService) {
    this.name = name;
    this.children = [];
    this.isVisible = true;
    this.isExpanded = false;
    this.isEditable = false;
    this.filterMatch = false;
    this.isSelected = false;
  }

  public get isInWorkStatus(): boolean {
    return this._isInWorkStatus;
  }
  public set isInWorkStatus(v: boolean) {
    this._isInWorkStatus = v;
  }

  public get isStructureSpecificationTreeNode(): boolean {
    return this._isStructureSpecificationTreeNode;
  }
  public set isStructureSpecificationTreeNode(v: boolean) {
    this._isStructureSpecificationTreeNode = v;
  }

  public get isSpecificationVersionTreeNode(): boolean {
    return this._isSpecificationVersionTreeNode;
  }
  public set isSpecificationVersionTreeNode(v: boolean) {
    this._isSpecificationVersionTreeNode = v;
  }

  public get isSharedFileSpecificationVersionTreeNode(): boolean {
    return this._isSharedFileSpecificationVersionTreeNode;
  }
  public set isSharedFileSpecificationVersionTreeNode(v: boolean) {
    this._isSharedFileSpecificationVersionTreeNode = v;
  }

  get icon(): string {
    return 'folder';
  }

  get isExpanded(): boolean {
    return this._isExpanded;
  }

  set isExpanded(isExpanded: boolean) {
    this._isExpanded = isExpanded;
    this.expandedEventEmitter.emit(this);
  }

  get specificationVersionTreeNode() {
    return this as unknown as SpecificationVersionTreeNode;
  }

  get specificationTreeNode() {
    return this as unknown as StructureSpecificationTreeNode;
  }

  get sharedFileTreeNode() {
    return this as unknown as SharedFileTreeNode;
  }

  get sharedFileVersiontreeNode() {
    return this as unknown as SharedFileVersionTreeNode;
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  set isVisible(visible: boolean) {
    this._isVisible = visible;
    this.visibleEventEmitter.emit(this);
  }

  addChild(treeNode: TreeNode) {
    this.children.push(treeNode);
    treeNode.expandedEventEmitter.subscribe((t: TreeNode) => {
      if (t.isExpanded) {
        this.isExpanded = true;
      }
    });

    treeNode.visibleEventEmitter.subscribe((t: TreeNode) => {
      if (t.isVisible) {
        this.isVisible = true;
      }
    });
  }

  selectTreeNode(notifySelection = true) {
    if (this.isSelected) {
      return;
    }

    this._treeService.selectTreeNode(this, notifySelection);
  }

  get isTreeNode(): boolean {
    return true;
  }

  editingDone(newName: string) {
    if (this.name !== newName && this.editAction) {
      this.editAction(this, newName);
    }
  }

  containsText(text: string): boolean {
    if(this.isStructureSpecificationTreeNode) {
      return this.areTextsEqual(this.name, text) ||
             this.areTextsEqual(this.specificationTreeNode.specification.code, text) ||
             this.areTextsEqual(this.specificationTreeNode.specification.translationId + '', text);
    }

    return this.areTextsEqual(this.name, text);
  }

  navigateUp() {
    this._treeService.navigateUpFromTreeNode(this);
  }

  navigateDown() {
    this._treeService.navigateDownFromTreeNode(this);
  }

  canDelete(): boolean {
    return false;
  }

  protected areTextsEqual(textOne: string, textTwo: string): boolean {
    return textOne.toLowerCase().indexOf(textTwo.toLowerCase()) > -1;
  }
}
