import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-dialogue',
  templateUrl: './confirm-dialogue.component.html',
  styleUrls: ['./confirm-dialogue.component.css']
})
export class ConfirmDialogueComponent {

  @Input()
  message: string;

  @Output()
  yesEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  noEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  yes(): void {
    this.yesEventEmitter.emit();
  }

  no(): void {
    this.noEventEmitter.emit();
  }
}
