import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'specificationNameFilter' })
export class SpecificationNameFilterPipe implements PipeTransform {
  transform(value: any[], filterText: string): any[] {
    if (!value || value.length <= 0) {
      return [];
    }
    if (!filterText || filterText === '') {
      return value;
    }

    const filteredItems = value.filter(item => item.specification.name && item.specification.name.toLowerCase().includes(filterText.toLowerCase()));

    return filteredItems;
  }
}
