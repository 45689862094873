<app-category-header [text]="'Harmonized freeze frames'"></app-category-header>
<div class="mb-16">
  <label class="sdds-btn sdds-btn-primary sdds-btn-sm ml-14">
      Import from zipped container
       <input #importZipBtn class="hidden" id="fileAttach" type="file" [disabled]="!isImportFileAllowed" accept=".zip"
          (change)="importZipFile($event)" >
  </label>
</div>
<div class="sdds-row">
  <div
    class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
    <!-- [ngClass]="{'loading': !category}"> -->
    <app-category-items *ngIf="category" [specification]="specification" [specificationVersion]="specificationVersion"
      [category]="category" [showHexCode]="true" [isAllowed]="canEditCategoryItems()">
    </app-category-items>
  </div>

  <div *ngIf="freezeFrameItem"> <!-- [ngClass]="{'loading': isLoading}" -->
    class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
    <div class="m-10">
      <app-editable-textbox [overrideFont]="true" [text]="freezeFrameItem.name"
        (textEditedEventEmitter)="editingNameDone($event)" enclosingElement="h1" [editingNotAllowed]="!isEditingAllowed"
        [editingNotAllowedReason]="editingNotAllowedReason" enclosingElement="h2" [isAllowed]="canEditCategoryItems()">
      </app-editable-textbox>
    </div>

    <div class="m-10">
      <sdds-textfield size="md" type="text" ngDefaultControl [(ngModel)]="freezeFrameItem.model.description"
        (customChange)="onChange()" [disabled]="!isEditingAllowed||!canEditCategoryItems()"
        placeholder="Enter description">
        <span slot="sdds-label"><b>Description</b></span>
      </sdds-textfield>
    </div>

    <div class="m-10 w-1/4">
      <sdds-textfield size="md" type="text" ngDefaultControl [(ngModel)]="freezeFrameItem.model.did"
        (customChange)="onChange()" [disabled]="!isEditingAllowed||!canEditCategoryItems()" placeholder="Enter DID">
        <span slot="sdds-label"><b>Data Identifier</b></span>
      </sdds-textfield>
    </div>

    <h4 class="ml-10 mt-20">General</h4>
    <div class="flex flex-wrap">
      <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg m-10 w-1/4">
        <span class="sdds-dropdown-label-outside"> <b>Freeze Frame Type </b> </span>
        <select [(ngModel)]="freezeFrameItem.model.freezeFrameType" name="harmonizedFFTypeName" (change)="onChange()"
          [disabled]="isReadOnly||!canEditCategoryItems()">
          <option *ngFor="let type of availableFreezeFrameTypes" [ngValue]="freezeFrameType[type]">{{type}}
            {{sourceDescription}}
          </option>
        </select>
      </div>

      <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg m-10 w-1/4">
        <span class="sdds-dropdown-label-outside"> <b>Source</b> </span>
        <select [(ngModel)]="freezeFrameItem.model.freezeFrameSource" name="harmonizedFFSourceName"
          (change)="onChange()" [disabled]="isReadOnly||!canEditCategoryItems()">
          <option *ngFor="let freezeFrameSourceType of availableFreezeFrameSource"
            [ngValue]="freezeFrameSource[freezeFrameSourceType]">{{freezeFrameSourceType}}</option>
        </select>
      </div>

      <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg m-10 w-1/4">
        <span class="sdds-dropdown-label-outside"> <b>DataIndication </b> </span>
        <select [(ngModel)]="freezeFrameItem.model.freezeFrameDataIndication" name="harmonizedFFDataIndicationName"
          (change)="onChange()" [disabled]="isReadOnly||!canEditCategoryItems()">
          <option *ngFor="let dataIndic of availableFreezeFrameDataIndication"
            [ngValue]="freezeFrameDataIndication[dataIndic]">{{dataIndic}}</option>
        </select>
      </div>

      <div class="m-10 w-1/4">
        <sdds-textfield size="md" type="text" ngDefaultControl [(ngModel)]="freezeFrameItem.model.unit"
          (customChange)="onChange()" [disabled]="!isEditingAllowed||!canEditCategoryItems()" placeholder="Unit">
          <span slot="sdds-label"><b>Unit</b></span>
        </sdds-textfield>
      </div>
    </div>
    <br />
    <h4 class="ml-10 mt-20">Scaling & Computational Method</h4>
    <div class="flex flex-wrap">
      <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg m-10 w-1/4">
        <span class="sdds-dropdown-label-outside"> <b>User Data Type </b> </span>
        <select [(ngModel)]="freezeFrameItem.model.userDataType" name="harmonizedFFUserDataType" (change)="onChange()"
          [disabled]="isReadOnly||!canEditCategoryItems()">
          <option *ngFor="let userType of availableFreezeFrameUserDataTypes" [ngValue]="userDataType[userType]">
            {{userType }}</option>
        </select>
      </div>

      <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg m-10 w-1/4">
        <span class="sdds-dropdown-label-outside"> <b>Scaling Data Type </b> </span>
        <select [(ngModel)]="freezeFrameItem.model.scalingDataType" name="harmonizedFFScalingDataType"
          (change)="onChange()" [disabled]="isReadOnly||!canEditCategoryItems()">
          <option *ngFor="let scalingType of availableFreezeFrameScalingDataType"
            [ngValue]="scalingDataType[scalingType]">{{scalingType }}</option>
        </select>
      </div>
    </div>

    <div class="mt-16">
      <span class="sdds-dropdown-label-outside ml-10"> <b>Method </b> </span>
      <mat-radio-group aria-label="Method Type" [(ngModel)]="selectedComputationalMethodType"
        [disabled]="isReadOnly||!canEditCategoryItems()" (change)="selectedComputationalMethodChanged($event)">
        <mat-radio-button *ngFor="let computationalMethodType of availableFreezeFrameComputationalMethodTypes"
          [value]="computationalMethodType">{{computationalMethodType}}</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- <div *ngIf="isEnumerationEnabled" class="mt-10">
          <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg m-10 w-1/4">
            <span class="sdds-dropdown-label-outside"> <b>Enumeration Type </b> </span>
            <select [(ngModel)]="freezeFrameItem.model.freezeFrameEnumType" name="freezeframeEnumType"
                (change)="onChange()" [disabled]="isReadOnly">
              <option *ngFor="let freezeFrameEnumType of availableFreezeFrameEnumType"
                  [ngValue]="freezeFrameEnumType[freezeFrameEnumType]">{{freezeFrameEnumType}}</option>
            </select>
          </div>
        </div> -->

    <div *ngIf="isEnumerationEnabled" class="ml-10 mt-10 w-1/2">
      <app-freezeframe-enum [freezeFrameModel]="freezeFrameItem.model" [isReadOnly]="isReadOnly"
        (changed)="onChange()"></app-freezeframe-enum>
    </div>

    <div *ngIf="isLinearFormulaEnabled" class="ml-10 mt-10 w-1/4">
      <sdds-textfield size="md" type="text" ngDefaultControl [(ngModel)]="freezeFrameItem.model.formulaForReading"
        (customChange)="onChange()" [disabled]="!isEditingAllowed||!canEditCategoryItems()"
        placeholder="Formula for Reading">
        <span slot="sdds-label"><b>Formula for Reading</b></span>
      </sdds-textfield>
    </div>

    <br />
    <h4 class="ml-10 mt-20">Position & Length</h4>
    <div class="sdds-checkbox-item">
      <input type="checkbox" class="sdds-form-input" [(ngModel)]="bitPosBitLengthChecked" id="bitPosBitLengthChecked"
        [disabled]="!canEditCategoryItems()">
      <label class="sdds-form-label ml-1">Show Bit position and Bit Length</label>
    </div>
    <div class="flex flex-wrap ml-10 mt-16 pb-16">
          <div>
            <label for="bytePosition" class="sdds-dropdown-label-outside"><b>Byte position</b></label>
            <div class="sdds-on-white-bg">
                <input name="bytePosition" class="sdds-textfield-input-md" type="number" [(ngModel)]="freezeFrameItem.model.bytePosition" (change)="onChange()">
            </div>
          </div>
          <div class="ml-10">
            <label for="bitLength" class="sdds-dropdown-label-outside"><b>Byte length</b></label>
            <div class="sdds-on-white-bg">
              <input name="bitLength" class="sdds-textfield-input-md" type="number"
              [(ngModel)]="freezeFrameItem.model.byteLength" [readonly]="!canEditCategoryItems()"  (change)="onChange()" min="1">
            </div>
          </div>
          <div class="ml-10" *ngIf="bitPosBitLengthChecked">
            <label for="bitPosition" class="sdds-dropdown-label-outside"><b>Bit position</b></label>
            <div class="sdds-on-white-bg">
              <input name="bitPosition" class="sdds-textfield-input-md" type="number"
                [(ngModel)]="freezeFrameItem.model.bitPosition" [readonly]="!canEditCategoryItems()" (change)="onChange()">
            </div>
          </div>
          <div class="ml-10" *ngIf="bitPosBitLengthChecked">
            <label for="bitLength" class="sdds-dropdown-label-outside"><b>Bit length</b></label>
            <div class="sdds-on-white-bg">
              <input name="bitLength" class="sdds-textfield-input-md" type="number"
                [(ngModel)]="freezeFrameItem.model.bitLength" [readonly]="!canEditCategoryItems()" (change)="onChange()" min="1">
            </div>
          </div>
      <div *ngIf="isCommonRequiredFreezeframe">
        <label for="bytePosition" class="sdds-dropdown-label-outside"><b>Byte position</b></label>
        <div class="sdds-on-white-bg">
          <input name="bytePosition" class="sdds-textfield-input-md" type="number"
            [(ngModel)]="freezeFrameItem.model.bytePosition" [readonly]="!canEditCategoryItems()" (change)="onChange()">
        </div>
      </div>
    </div>
  </div>
</div>
