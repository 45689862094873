<div class="sdds-row icon-bar my-14">
  <div class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-6">
    <div class="sdds-row">
    <div class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-5 sdds-col-sm-5 sdds-col-xs-5">
      <label class="sdds-headline-07">Editor options</label>
      <div>
        <button class="btn btn-link btn-xs toolbar-btn" matTooltip="Back to SCOMM files" matTooltipPosition="below"
          (click)="legacyToolbarServiceRef.triggerBackToScommFiles()"
          *ngIf="!legacyToolbarServiceRef.fullScreenActive"><mat-icon svgIcon="arrow-left"></mat-icon></button>
        <button class="btn btn-link btn-xs toolbar-btn-sm" *ngIf="!legacyToolbarServiceRef.fullScreenActive"
          matTooltip="Enter fullscreen" matTooltipPosition="below"
          (click)="legacyToolbarServiceRef.triggerFullScreen(!legacyToolbarServiceRef.fullScreenActive)">
         <mat-icon svgIcon="arrows-fullscreen"></mat-icon>
        </button>

        <button class="btn btn-link btn-xs toolbar-btn-sm" *ngIf="legacyToolbarServiceRef.fullScreenActive"
          matTooltip="Exit fullscreen" matTooltipPosition="below"
          (click)="legacyToolbarServiceRef.triggerFullScreen(!legacyToolbarServiceRef.fullScreenActive)">
         <mat-icon svgIcon="arrows-angle-contract"></mat-icon>
        </button>

        <span [matTooltip]="deleteFileButtonTooltip">
          <button *ngIf="legacyToolbarServiceRef.userCanDeleteDraft"
          class="btn btn-link btn-xs toolbar-btn-sm" matTooltipPosition="below"
            [disabled]="!legacyToolbarServiceRef.isCurrentUserDraftSelected ||!canEditLegacyFile() " matTooltipPosition="below"
            class="btn btn-link btn-xs toolbar-btn" data-toggle="modal" data-target="#removeDraftConfirmModal">
            <mat-icon svgIcon="x-circle"></mat-icon>
          </button>
        </span>

        <span [matTooltip]="showCreateDraftButtonTooltip">
          <button class="btn btn-link btn-xs toolbar-btn-sm" matTooltipPosition="below"
            *ngIf="legacyToolbarServiceRef.userCanCreateDraft"
            [disabled]="!canEditLegacyFile()|| !selectedSpecificationIsInWork"
            (click)="legacyToolbarServiceRef.triggerCreateDraft()">
            <mat-icon svgIcon="terminal"></mat-icon>
          </button>
        </span>

        <span [matTooltip]="showDraftButtonTooltip">
          <button class="btn btn-link btn-xs toolbar-btn-sm" matTooltipPosition="below"
            *ngIf="legacyToolbarServiceRef.userCanViewDrafts"
            [disabled]="!canEditLegacyFile() || !legacyToolbarServiceRef.draftFilesExists"
            (click)="legacyToolbarServiceRef.triggerViewDrafts()">
            <mat-icon svgIcon="inboxes"></mat-icon>
          </button>
        </span>
        <button class="btn btn-link btn-xs toolbar-btn-sm" matTooltip="View master file" matTooltipPosition="below"
          *ngIf="legacyToolbarServiceRef.userCanViewMasterFile"
          (click)="legacyToolbarServiceRef.triggerViewMasterFiles()">
          <mat-icon svgIcon="inbox"></mat-icon>
       </button> 
    
        <span [matTooltip]="saveFileButtonTooltip">
         <button class="btn btn-link btn-xs toolbar-btn" matTooltipPosition="below"
           *ngIf="legacyToolbarServiceRef.userCanSaveDraft"
            [disabled]="!legacyToolbarServiceRef.isCurrentUserDraftSelected||!canEditLegacyFile()"
            (click)="legacyToolbarServiceRef.triggerSaveDraft()">
            <mat-icon svgIcon="cloud-upload"></mat-icon>
          </button> 
        </span>
        <button class="btn btn-link btn-xs toolbar-btn-sm" (click)="showEditorShortcuts()"
          matTooltip="Show editor shortcuts" matTooltipPosition="below"><mat-icon svgIcon="info"></mat-icon></button>
      </div>
    </div>
    <div class="sdds-col-max-2 sdds-col-xxlg-2 sdds-col-xlg-2 sdds-col-lg-2 sdds-col-md-2 sdds-col-sm-2 sdds-col-xs-2" >
      <div class="section-toolbar" *ngIf="legacyToolbarServiceRef.viewDraftsActive && !legacyToolbarServiceRef.diffMode">
        <label class="sdds-headline-07" *ngIf="legacyToolbarServiceRef.viewDraftsActive">Validation</label>
        <div>
          <button class="btn btn-link btn-xs toolbar-btn-sm" style="box-shadow: none;"
            *ngIf="legacyToolbarServiceRef.isValidDraft" matTooltip="Server file is valid!" matTooltipPosition="below">
            <mat-icon  style="color:#44a878;"svgIcon="check-circle"></mat-icon>
          </button>

          <button *ngIf="!legacyToolbarServiceRef.isValidDraft" class="btn btn-link btn-xs toolbar-btn-sm"
            (click)="showValidationMessage()"
            matTooltip="Server file has validation errors, click for details" matTooltipPosition="below">
            <mat-icon style="color:rgb(240, 125, 42)" svgIcon="alert-circle-fill"></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-5 sdds-col-sm-5 sdds-col-xs-5"></div>
  </div>
  </div>
  <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3"></div>


  <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3" class="section-toolbar">
    <label class="sdds-headline-07">Merge/diff</label>
    <div>
      <span [matTooltip]="mergeFileButtonTooltip">
        <button *ngIf="!legacyToolbarServiceRef.mergeMode && !legacyToolbarServiceRef.diffMode"
          [disabled]="!canEditLegacyFile() || !legacyToolbarServiceRef.userHasDraftFile"
          class="btn btn-link btn-xs toolbar-btn-sm" matTooltipPosition="below"
          (click)="legacyToolbarServiceRef.triggerStartMerge()">
          <mat-icon svgIcon="arrow-left-right"></mat-icon>
        </button>
      </span>

      <span *ngIf="!legacyToolbarServiceRef.mergeMode && !legacyToolbarServiceRef.diffMode">
        <button class="btn btn-link btn-xs toolbar-btn" matTooltip="Diff files" matTooltipPosition="below"
          (click)="legacyToolbarServiceRef.triggerShowDiff()" [disabled]="!canEditLegacyFile()" >
          <mat-icon svgIcon="document-diff"></mat-icon>
        </button>
      </span>

      <span [ngClass]="{'hidden': !legacyToolbarServiceRef.mergeMode}">
        <span [matTooltip]="executeFileButtonTooltip">
          <button class="btn btn-link btn-xs toolbar-btn" matTooltipPosition="below" 
            id="actionConfirmModal" [disabled]="!legacyToolbarServiceRef.isValidDraft">
            <mat-icon svgIcon="gear"></mat-icon>
          </button>
        </span>
      </span>

      <span *ngIf="legacyToolbarServiceRef.mergeMode || legacyToolbarServiceRef.diffMode">
        <button class="btn btn-link btn-xs toolbar-btn" matTooltip="Close diff/merge view" matTooltipPosition="below"
          (click)="legacyToolbarServiceRef.triggerCloseDiffMerge()">
         <mat-icon svgIcon="box-arrow-right"></mat-icon>
        </button>
      </span>
    </div>
  </div>
</div>

<app-remove-draft-confirm (accept)="legacyToolbarServiceRef.triggerDeleteDraft()"></app-remove-draft-confirm>
<app-action-confirm (accept)="legacyToolbarServiceRef.triggerExecuteMerge()" confirmTitle="Execute merge"
  confirmDesc="Are you sure to merge the files?"></app-action-confirm>
