<div class="sdds-card cardStyle">
  <div class="sdds-card-header cardHeaderStyle">
    <div class="flex">
      <div class="flex-none">
        <div class="sdds-dropdown">
          <select name="operatorType" class="operator-selector"
                    [(ngModel)]="expressionModel.nodeType"
                    (change)="onOperatorTypeChange()"
                    [disabled]="isReadOnly || hasAnyIntervalNodeChilds()||!isAllowed">
              <option active [ngValue]="ServerIdentificationExpressionNodeType.AndOperator">And</option>
              <option [ngValue]="ServerIdentificationExpressionNodeType.OrOperator">Or</option>
          </select>
        </div>
      </div>
      <div class="grow"></div>
      <div class="flex-none">
        <app-expression-view-toolbar
          [expressionModel]="expressionModel"
          [iconColor]="'white'"
          [copyEnabled]="false"
          [isReadOnly]="isReadOnly">
        </app-expression-view-toolbar>
      </div>
    </div>
  </div>

  <div class="sdds-card-body">
      <app-create-expression-node [isReadOnly]="isReadOnly" [legacyModeEnabled]="legacyModeEnabled"
      (createLegacyValueEquals)="addValueEqualsComparisonNode()"
      (createLegacyValueStartsWith)="addValueStartsWithComparisonNode()"
      (createLegacyValueContains)="addValueContainsComparisonNode()"
      (createValueStartsWith)="addValueStartsWithComparisonNode()"
      (createValueContains)="addValueContainsComparisonNode()"
      (createCanAddressComparison)="addCanAddressComparisonNode()"
      (createExecutingModeComparison)="addExecutingModeComparisonNode()"
      (createSpecialComparison)="addSpecialComparisonNode()"
      (createDoesNotExistComparison)="addDoesNotExistComparisonNode()" (createOperator)="addOperator()"
      (createValueComparisonNodeEqualTo)="addValueComparisonNodeEqualTo()"
      (createValueComparisonNodeInterval)="addValueComparisonNodeInterval()"
      [isAllowed]="isAllowed">
    </app-create-expression-node>

    <br /><br/>

    <app-expression-node-renderer [legacyModeEnabled]="legacyModeEnabled" [isReadOnly]="isReadOnly"
      [expressions]="expressionModel.expressions" [isAllowed]="isAllowed"></app-expression-node-renderer>
  </div>
</div>
