import { Directive, Input, TemplateRef, ViewContainerRef, ElementRef, AfterViewChecked, Renderer2 } from '@angular/core';
import { OnDestroy } from '@angular/core';

@Directive({
    selector: '[appReplaceElement]'
})
export class ReplaceElementDirective implements OnDestroy {

    createdReplacementElement: any;
    parent: any;

    constructor(
        private templateRef: TemplateRef<any>,
        private vcf: ViewContainerRef,
        private renderer: Renderer2
    ) { }

    ngOnDestroy(): void {
        if (this.parent && this.createdReplacementElement) {
            this.parent.removeChild(this.createdReplacementElement);
        }
    }

    @Input('appReplaceElement')
    set replacementTag(replacementElement: string) {
        const parentNode = this.templateRef.elementRef.nativeElement.parentNode;

        this.parent = parentNode;

        const createdReplacementElement = this.renderer.createElement(replacementElement);

        this.renderer.appendChild(parentNode, createdReplacementElement);
        this.renderer.appendChild(createdReplacementElement, this.templateRef.elementRef.nativeElement);

        this.vcf.createEmbeddedView(this.templateRef);

        this.createdReplacementElement = createdReplacementElement;
    }
}
