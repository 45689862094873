import { Output, EventEmitter, Injectable } from '@angular/core';
import { ServiceExecutionDirective } from 'app/modules/shared/model/service-execution/service-execution';

@Injectable({
    providedIn: 'root'
})

export class ModificationService {


    @Output()
    serviceExecutionRemoved: EventEmitter<ServiceExecutionDirective> = new EventEmitter();
}
