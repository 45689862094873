export class ExportCriteria {
    labelId?: number;
    viewId?: number;

    public static createForLabel(labelId: number) {
        return this.create(labelId, null);
    }

    public static createForView(viewId: number) {
        return this.create(null, viewId);
    }

    private static create(labelId?: number, viewId?: number) {
        const exportParameters = new ExportCriteria();

        exportParameters.labelId = labelId;
        exportParameters.viewId = viewId;

        return exportParameters;
    }
}