<div class="sdds-row">
  <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3" [class.loading]="loadingViews">
    <app-file-browser (selectedSharedFileEvent)="setSelectedSharedFile($event)" [isAllowed]="canEditSharedFiles()"></app-file-browser>
  </div>
  <div class="sdds-col-max-1 sdds-col-xxlg-1 sdds-col-xlg-1 sdds-col-lg-1 sdds-col-md-1 sdds-col-sm-1 sdds-col-xs-1"></div>
  <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3">
    <app-file-version-browser [isAllowed]="canEditSharedFiles()"></app-file-version-browser>
  </div>
  <div class="sdds-col-max-1 sdds-col-xxlg-1 sdds-col-xlg-1 sdds-col-lg-1 sdds-col-md-1 sdds-col-sm-1 sdds-col-xs-1"></div>

  <div class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4">
    <app-file-version-details  [isAllowed]="canEditSharedFiles()"></app-file-version-details>
  </div>

</div>
