import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { SessionsModel } from 'app/app-model/sessions/sessions.model';
import { CategoryServiceBase } from 'app/app-services/category-base.service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { SessionsCategoryItem } from 'app/data-categories/sessions/sessions-category-item';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { SpecificationService } from './specification-service';

@Injectable({
  providedIn: 'root'
})
export class SessionsService extends CategoryServiceBase<SessionsModel, SessionsCategoryItem> {

  constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
    private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected apiProxy: ApiProxyService) {
    super(apiProxy);
    this.apiCategoryName = 'sessions';
  }

  getItemsPreview(specificationVersionId: number): Observable<SessionsCategoryItem[]> {
    this.isPending = true;
    if (this.hasCachedItems) {
      this.isPending = false;
      return of(this.items);
    } else {
      return this.apiProxy.get<SessionsModel[]>(`${environment.apiUrl}versions/${specificationVersionId}/sessions`).pipe(map(sessions => {
        this.items = new Array<SessionsCategoryItem>();
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < sessions.length; i++) {
          const session = new SessionsCategoryItem(this.specificationService, this.locationService, this.router, this.activatedRoute);
          session.sessionsService = this;
          session.diagnosticServiceProvider = this.diagnosticServiceProvider;
          session.model = sessions[i];

          this.items.push(session);
        }

        this.hasCachedItems = true;
        this.isPending = false;
        this.getItemsPreviewDone.next(this.isPending);

        return this.items;
      }));
    }
  }

  createItem(specificationVersionId: number, itemName?: string): Observable<SessionsCategoryItem> {
    const itemToCreate = new SessionsModel();
    itemToCreate.name = itemName;
    itemToCreate.setSequence = new ServiceExecutionSequenceModel();
    return this.apiProxy.post<SessionsModel>(`${environment.apiUrl}versions/${specificationVersionId}/sessions/`, itemToCreate).pipe(map(item => {
      const sessionCategoryItem = new SessionsCategoryItem(this.specificationService, this.locationService, this.router, this.activatedRoute);
      sessionCategoryItem.sessionsService = this;
      sessionCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      sessionCategoryItem.isPreview = false;
      sessionCategoryItem.model = item;

      this.items.push(sessionCategoryItem);

      return sessionCategoryItem;
    }));
  }

  getCategoryItemModel(specificationVersionId: number, name: string): Observable<SessionsCategoryItem> {
    return this.apiProxy.get<SessionsModel>(`${environment.apiUrl}versions/${specificationVersionId}/sessions/${name}/`).pipe(map(item => {
      const sessionCategoryItem = new SessionsCategoryItem(this.specificationService, this.locationService, this.router, this.activatedRoute);
      sessionCategoryItem.sessionsService = this;
      sessionCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      sessionCategoryItem.isPreview = false;
      sessionCategoryItem.model = item;

      this.items.push(sessionCategoryItem);
      return sessionCategoryItem;
    }));
  }

  getCompleteItems(specificationVersionId: number): Observable<SessionsCategoryItem[]> {
    throw new Error('Method not implemented.');
  }

  deleteItem(specificationVersionId: number, itemName?: string): Observable<any> {
    return this.apiProxy.delete(`${environment.apiUrl}versions/${specificationVersionId}/sessions/${itemName}`)
      .pipe(tap(deletedItem => this.removeCachedItem(deletedItem.name)));
  }

  updateItem(specificationVersionId: number, sessionsDataModel: any, itemName?: string): Observable<SessionsModel> {
    return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/sessions/${itemName}/`, sessionsDataModel);
  }

  public getSessionItem(specificationVersionId: number, itemName: string): Observable<SessionsCategoryItem> {
    return this.apiProxy.get<SessionsCategoryItem>(`${environment.apiUrl}versions/${specificationVersionId}/sessions/${itemName}/`);
  }
}
