import { Component, Input } from '@angular/core';
import { TreeNode } from '../../model/tree-node';

@Component({
  selector: 'app-node-details',
  templateUrl: './node-details.component.html',
  styleUrls: ['./node-details.component.css']
})
export class NodeDetailsComponent  {
  @Input()
  treeNode: TreeNode;
  @Input()
  diagnosticNo:string;
}
