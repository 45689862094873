/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ComputationalMethodType } from 'app/app-model/diagnostic-service/computational.method.model';
import { LengthKind, TerminationType } from 'app/app-model/diagnostic-service/length.model';
import { DiagnosticServiceValidator } from 'app/app-services/diagnostic-service-validator';
import { EcuIdentifierService } from 'app/app-services/ecu-identification.service';
import { ParameterService } from 'app/app-services/parameter-service';
import { ServiceModificationService } from 'app/app-services/service-modification.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { ParameterType } from 'app/modules/shared/model/service/parameters/parameter-type';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-editable-parameter',
  templateUrl: './editable-parameter.component.html',
  styleUrls: ['./editable-parameter.component.css']
})
export class EditableParameterComponent implements OnInit, OnDestroy {

  @Input()
  parameter: ParameterData;

  @Input()
  diagnosticService: DiagnosticServiceCategoryItem;

  @Input()
  isAllowed: boolean;

  @Output()
  parameterChange: EventEmitter<ParameterData> = new EventEmitter();

  OdxDataType: OdxDataType;
  ParameterType: ParameterType;
  msgSaveParameterError = '';

  private serviceModificationSubscription: Subscription;

  constructor(private diagnosticServiceValidator: DiagnosticServiceValidator,
    private messageService: MessageService,
    private serviceModificationService: ServiceModificationService,
    private ecuIdentifierService: EcuIdentifierService,
    private specificationService: SpecificationService,
    private parameterService: ParameterService
  ) { }

  public get physicalValueDataType(): string {
    return this.parameter.getPhysicalDataType();
  }

  public set physicalValueDataType(valueType: string) {
    this.parameter.model.scaling.physicalDataType = OdxDataType[valueType];
    setTimeout(() => {
      this.onPhysicalDataTyeChanged();
    }, 500);
  }

  public get lengthTermination(): string {
    return TerminationType[this.parameter.model.length.termination];
  }

  public set lengthTermination(termination: string) {
    this.parameter.model.length.termination = TerminationType[termination];
  }

  public get internalValueDataTypeEnum(): OdxDataType {
    return this.parameter.scalingData.model.internalDataType;
  }

  public get physicalValueDataTypeEnum(): OdxDataType {
    return this.parameter.scalingData.model.physicalDataType;
  }

  public get hasInternalValueConstraint(): boolean {
    return this.parameter.scalingData?.internalConstraintData !== undefined;
  }

  public get hasPhysicalValueConstraint(): boolean {
    return this.parameter.scalingData?.physicalConstraintData !== undefined;
  }

  get hasMinMaxLength(): boolean {
    return this.parameter.model.length.kind === LengthKind.MinMax;
  }

  set hasMinMaxLength(enabled: boolean) {
    if (enabled) {
      this.parameter.resetLength();
      this.parameter.model.length.bitLength = 0;
      this.parameter.model.length.kind = LengthKind.MinMax;
    } else {
      this.parameter.resetLength();
      this.parameter.model.length.bitLength = 1;
      this.parameter.model.length.kind = LengthKind.Standard;
    }
  }

  get hasMaxByteLength(): boolean {
    return this.parameter.model.length.hasMaxByteLength;
  }

  set hasMaxByteLength(hasMaxByteLength: boolean) {
    this.parameter.model.length.hasMaxByteLength = hasMaxByteLength;
  }

  get isValueParameter(): boolean {
    return this.parameter.model.type === ParameterType.Value;
  }

  get isConstantParameter(): boolean {
    return this.parameter.model.type === ParameterType.Constant;
  }

  get isReservedParameter(): boolean {
    return this.parameter.model.type === ParameterType.Reserved;
  }

  get termination(): string {
    if (!this.parameter.model.length.termination) {
      return '';
    }

    return TerminationType[this.parameter.model.length.termination];
  }

  get hasInternalComputationalMethod(): boolean {
    return this.parameter.scalingData &&
      this.parameter.scalingData.model.internalToPhysicalComputationalMethod !== undefined &&
      this.parameter.scalingData.model.internalToPhysicalComputationalMethod !== null;
  }
  set hasInternalComputationalMethod(enabled: boolean) {
    if (enabled) {
      this.parameter.scalingData.enableInternalToPhysicalComputationalMethod(this.internalValueDataTypeEnum);
    } else {
      this.parameter.scalingData.disableInternalToPhysicalComputationalMethod();
    }
    this.notifyParameterChanged();
  }

  get hasPhysicalComputationalMethod(): boolean {
    return this.parameter.scalingData &&
      this.parameter.scalingData.model.physicalToInternalComputationalMethod !== undefined &&
      this.parameter.scalingData.model.physicalToInternalComputationalMethod !== null;
  }
  set hasPhysicalComputationalMethod(enabled: boolean) {
    if (enabled) {
      this.parameter.scalingData.enablePhysicalToInternalComputationalMethod(this.physicalValueDataTypeEnum);
    } else {
      this.parameter.scalingData.disablePhysicalToInternalComputationalMethod();
    }
    this.notifyParameterChanged();
  }
  get isOdxBasedSpecification(): boolean {
    return this.specificationService.currentSpecificationVersion.specificationType === 1;
  }

  ngOnInit() {
    this.serviceModificationSubscription = this.serviceModificationService.requestChange.subscribe(change => {
      this.parameter.model.scaling.physicalDataType = change.valueToChange;
    });
  }

  ngOnDestroy(): void {
    if (this.serviceModificationSubscription) {
      this.serviceModificationSubscription.unsubscribe();
    }
  }

  onParameterTypeChanged() {
    switch (ParameterType[this.parameter.type]) {
      case ParameterType.Constant:
        this.parameter.initToConstant();
        break;
      case ParameterType.Value:
        this.parameter.initToValue();
        break;
      case ParameterType.Reserved:
        this.parameter.initToReserved();
        break;
      default:
        break;
    }

    this.notifyParameterChanged();
  }

  onPhysicalDataTyeChanged() {
    this.notifyParameterChanged();
  }

  onInternalDataTypeChanged() {
    this.notifyParameterChanged();
  }

  notifyParameterChanged(event?: any) {
    this.msgSaveParameterError = '';
    if (!event) {
      if (this.hasEnabledComputationalMethods()) {
        if (this.hasValidComputationalMethods() && this.isValidParameter()) {
          this.ecuIdentifierService.updateParameterNameToConnectedProperties(
            this.diagnosticService, this.parameter.model,
            this.specificationService.currentSpecificationVersion.id)
            .subscribe(err => this.msgSaveParameterError = err);
          this.parameterChange.next(this.parameter);
        }
      } else if (this.isValidParameter()) {
        this.ecuIdentifierService.updateParameterNameToConnectedProperties(this.diagnosticService,
          this.parameter.model,
          this.specificationService.currentSpecificationVersion.id)
          .subscribe();
        this.parameterChange.next(this.parameter);
      }
    }
  }

  setDefaultInternalvalueConstraint() {
    const constraintData = this.parameterService.createNewValueConstraint(this.internalValueDataTypeEnum);
    this.parameter.scalingData.internalConstraintData = constraintData;
  }

  hasTextTable(): boolean {
    return this.parameter.scalingData.model.internalToPhysicalComputationalMethod &&
      this.parameter.scalingData.model.internalToPhysicalComputationalMethod.methodType === ComputationalMethodType.TextTable;
  }

  onHasInternalValConstraitnChecked(item: ElementRef) {
    if (item.nativeElement.checked) {

    }
  }

  isValidParameter(): boolean {
    switch (this.parameter.model.type) {
      case ParameterType.Constant:
        if (this.parameter.hasNumericalPhysicalDataType()) {
          // eslint-disable-next-line radix
          const value = Number.parseInt(this.parameter.rawValue, undefined);
          const maxLength = this.getMaxLength();
          const minLength = this.getMinLength();
          return value < maxLength && value >= minLength;
        }
        return true;
      default:
        return this.parameter.name !== '';;
    }
  }

  public hasValidComputationalMethods(): boolean {
    const internalCompMethod = this.parameter.model.scaling.internalToPhysicalComputationalMethod;
    const physCompMethod = this.parameter.model.scaling.physicalToInternalComputationalMethod;

    if (this.hasInternalComputationalMethod && !this.hasPhysicalComputationalMethod) {
      return this.diagnosticServiceValidator
        .isValidComputationalMethod(internalCompMethod.methodType, this.internalValueDataTypeEnum, this.physicalValueDataTypeEnum, true);
    } else if (!this.hasInternalComputationalMethod && this.hasPhysicalComputationalMethod) {
      return this.diagnosticServiceValidator
        .isValidComputationalMethod(physCompMethod.methodType, this.internalValueDataTypeEnum, this.physicalValueDataTypeEnum, false);
    } else if (this.hasInternalComputationalMethod && this.hasPhysicalComputationalMethod) {
      return this.diagnosticServiceValidator
        .isValidComputationalMethod(internalCompMethod.methodType, this.internalValueDataTypeEnum, this.physicalValueDataTypeEnum, true) &&
        this.diagnosticServiceValidator.isValidComputationalMethod(
          physCompMethod.methodType,
          this.internalValueDataTypeEnum,
          this.physicalValueDataTypeEnum, false);
    }

    return true;
  }

  private hasEnabledComputationalMethods() {
    return this.hasInternalComputationalMethod || this.hasPhysicalComputationalMethod;
  }

  private getMaxLength(): number {
    let maxLength;
    if (this.hasMinMaxLength) {
      if (this.hasMaxByteLength) {
        maxLength = Math.pow(2, this.parameter.model.length.maxByteLength * 8);
      } else {
        maxLength = 1114111;
      }
    } else {
      maxLength = Math.pow(2, this.parameter.model.length.bitLength);
    }
    return maxLength;
  }

  private getMinLength(): number {
    let minLength = 0;
    if (this.hasMinMaxLength) {
      minLength = Math.pow(2, this.parameter.model.length.minByteLength * 8);
    }
    return minLength;
  }
}
