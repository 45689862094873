<div *ngIf="editMode">
  <div #codeEditor ></div>
</div>

<div *ngIf="mergeMode">
  <div class="alert alert-info" style="max-width: 81%;">
    In this view, you can compare and merge your draft with the master version of the legacy server file. Your
    draft is also editable in this view if you need to do additional changes before merging.
  </div>
  <div class="sdds-row">
    <div style="text-align: center;" class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-6"><b>MASTER FILE</b>
      <label *ngIf="mergeMode && modifiedBy"> &nbsp; &nbsp; Modified by:
        &nbsp;{{modifiedBy}} &nbsp;{{modifiedByDate | date: 'yyyy/MM/dd hh:mm:ss'}}</label>
      <label *ngIf="mergeMode && !modifiedBy "> &nbsp; &nbsp; Created at:
        &nbsp;{{createdByDate | date: 'yyyy/MM/dd hh:mm:ss'}}</label>

    </div>
    <div style="text-align: center;" class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-6"><b>YOUR DRAFT</b></div>
  </div>
  <div class="sdds-row">
    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
    <div #mergeEditor class="merge-editor" style="height: 60vh;"></div>
  </div>
  </div>
</div>

<div *ngIf="diffMode">
  <div class="alert alert-info" style="max-width: 81%;">
    In this view, you can compare between all available drafts of the current specification version and master legacy files from all specification versions.
  </div>
  <div class="sdds-row">
    <div class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-6">
      <div style="min-width: 25%;">
        <app-scomm-file-selector (fileSelected)="setLeftContent($event)"></app-scomm-file-selector>
      </div>
    </div>
    <div class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-6">
      <div style="min-width: 25%;">
        <app-scomm-file-selector (fileSelected)="setRightContent($event)"></app-scomm-file-selector>
      </div>
    </div>
  </div>
  <div class="sdds-row">
    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
    <div #mergeEditor class="merge-editor" style="height: 60vh;"></div>
  </div>
  </div>
</div>
