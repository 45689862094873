<app-category-header [text]="'Identifications'"></app-category-header>

<div class="sdds-row">
    <div
        class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <app-category-items *ngIf="category" [specification]="specification"
            [specificationVersion]="specificationVersion" [showHexcode]="true" [category]="category"
            [isAllowed]="canEditCategoryItems()">
        </app-category-items>
    </div>

    <div
        class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <ng-container *ngIf="ecuIdentifierItem">
            <app-editable-textbox [overrideFont]="true" [text]="ecuIdentifierItem.shortName"
                (textEditedEventEmitter)="editingNameDone($event)" enclosingElement="h1"
                [editingNotAllowed]="!isEditingAllowed" [editingNotAllowedReason]="editingNotAllowedReason"
                enclosingElement="h2" [isAllowed]="canEditCategoryItems()"></app-editable-textbox>

            <div class="mb-16">
                <h3>Id (Hex)</h3>
                <app-id-code-field [hexCount]="hexCount" [hexCode]="ecuIdentifierItem.ecuIdentifierHexCode"
                    [darkMode]="true" [isEditingAllowed]="isEditingAllowed" (hexCodeChanged)="onHexCodeChanged($event)"
                    [isAllowed]="canEditCategoryItems()">
                </app-id-code-field>
            </div>

            <app-property-table [properties]="ecuIdentifierItem.properties"
                [serviceExecutionSequence]="ecuIdentifierItem.serviceExecutionSequence"
                [propertyType]="ecuIdentifierItem.propertyType" [isReadOnly]="!isEditingAllowed"
                [unlinkEnabled]="false"></app-property-table>

            <app-assigned-services-viewer [serviceExecutionSequence]="ecuIdentifierItem.serviceExecutionSequence"
                [propertyType]="ecuIdentifierItem.propertyType" [isReadOnly]="!isEditingAllowed"
                [hideAddButtonWithOneService]="true">
            </app-assigned-services-viewer>
        </ng-container>
    </div>
</div>

<ng-template #categoryItemTemplate let-item>
    <p class="w-9/12">{{(this.ecuIdentifierItem.model.code)+item.name}}</p>
</ng-template>