<div class="my-5" *ngIf="!addSpecVersionsExpanded">
  <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" (click)="enableSpecVersionAssigner()" [disabled]="!isAllowed">Add version</button>
</div>

<div class="sdds-card float-left py-10" *ngIf="addSpecVersionsExpanded">
  <div class="sdds-card-body">
    <div>
      <app-specification-version-selector #specificationVersionSelector
        header="Select one or more specification versions to be part of this custom build." multiSelect="true"
        [selectedSpecificationVersions]="selectedSpecificationVersions">
      </app-specification-version-selector>

      <div class="float-right">
        <button type="button" class="sdds-btn sdds-btn-secondary sdds-btn-sm" (click)="close();">Close</button>
        <button type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm ml-10" (click)="okAdd();" [disabled]="!hasSelectedSpecificationVersions">Add</button>
      </div>
    </div>
  </div>
</div>
