import { Injectable } from '@angular/core';
import { ServerIdentificationModel } from 'app/app-model/server-identification/server-identification.model';
import { environment } from 'environments/environment';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FamilyGenerationPair } from '../app-model/family-generation-pair';
import { DiagnosticFamilyModel, GenerationModel } from '../app-model/server-identification/diagnostic-family.model';
import { ApiProxyService } from '../modules/shared/services/api-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticFamilyService {

  _families: Array<DiagnosticFamilyModel> = [];
  _generations: Array<GenerationModel> = []
  _generationsByFamily: { [family: string]: GenerationModel[] } = {};
  _familyGenerationPairs: Array<FamilyGenerationPair> = [];

  constructor(private apiProxy: ApiProxyService) {

  }


  public loadFamilyAndgenerations() {
    if(!this._families) {
      this.getFamilies().subscribe(families => this._families = families);
    }
  }

  public createDiagnosticFamily(diagnosticFamily: DiagnosticFamilyModel): Observable<DiagnosticFamilyModel> {
    return this.apiProxy.post<DiagnosticFamilyModel>(environment.apiUrl + 'families/dcode', diagnosticFamily);
  }

  public updateDiagnosticFamily(diagnosticFamily: DiagnosticFamilyModel): Observable<DiagnosticFamilyModel> {
    return this.apiProxy
      .put<DiagnosticFamilyModel>(environment.apiUrl + 'families/', diagnosticFamily);
  }

  public deleteDiagnosticFamily(diagnosticFamilyId: number): Observable<DiagnosticFamilyModel> {
    return this.apiProxy
      .delete<DiagnosticFamilyModel>(environment.apiUrl + 'families/' + diagnosticFamilyId);
  }

  public createDiagnosticFamilyGeneration(diagnosticFamilyId: number, diagnosticFamilyGeneration: DiagnosticFamilyModel): Observable<DiagnosticFamilyModel> {
    return this.apiProxy
      .post<DiagnosticFamilyModel>(environment.apiUrl + 'families/' + diagnosticFamilyId + '/generations/dcode', diagnosticFamilyGeneration);
  }

  public updateDiagnosticFamilyGeneration(diagnosticFamilyId: number, diagnosticFamilyGeneration: DiagnosticFamilyModel): Observable<DiagnosticFamilyModel> {
    return this.apiProxy
      .put<DiagnosticFamilyModel>(environment.apiUrl + 'families/' + diagnosticFamilyId + '/generations', diagnosticFamilyGeneration);
  }

  public deleteDiagnosticFamilyGeneration(diagnosticFamilyId: number, generationId: number): Observable<GenerationModel> {
    return this.apiProxy
      .delete<GenerationModel>(environment.apiUrl + 'families/' + diagnosticFamilyId + '/generations/' + generationId);
  }

  getFamilies(): Observable<DiagnosticFamilyModel[]> {
    return this.apiProxy.get<DiagnosticFamilyModel[]>(environment.apiUrl + 'families');
  }

  getAllgenerations(): Observable<GenerationModel[]> {
    return this.apiProxy.get<GenerationModel[]>(environment.apiUrl + 'generation/getallgenerations');
  }

  getGenerations(familyName: string): Observable<GenerationModel[]> {
    return this.apiProxy.get<GenerationModel[]>(environment.apiUrl + 'families/' + familyName + '/generations');
  }

  getGenerationById(generationId:number):Observable<GenerationModel>{
  return this.apiProxy.get<GenerationModel>(`${environment.apiUrl}generation/${generationId}`);
  }

  getGenerationForReleasedSpecification(specificationId: number): Observable<GenerationModel[]> {
    if (!specificationId) {
      return of([]);
    }

    return this.apiProxy
      .get<ServerIdentificationModel[]>(environment.apiUrl + 'servers?specificationVersionId=' + specificationId)
      .pipe(map(identification => {
        const generationModels = Array<GenerationModel>();
        identification.forEach(generationData => {
          const generation = new GenerationModel();
          generation.id = generationData.generationId;
          generation.name = generationData.generation;
          generationModels.push(generation);
        });

        return generationModels;

      }));
  }
}
