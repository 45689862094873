import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DiagnosticProtocolType, SpecificationType } from 'app/app-model/enums';
import {
  ServerIdentificationCompareOperatorType,
  ServerIdentificationExpressionNodeType,
} from 'app/app-model/server-identification/server-identification.enums';
import {
  EcuIdentifierPropertyModel,
  ServeridentificationExpressionModel,
  TypedRangeModel,
} from 'app/app-model/server-identification/server-identification.model';
import { OdxDataType, TypedValueData } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { Category } from 'app/specification-structure/category-items/category.enum';

import { DataCategoriesService } from './data-categories-service';
import { IdentificationExpressionService } from './identification-expression.service';
import { SpecificationService } from './specification-service';

@Injectable({
  providedIn: 'root'
})
export class ExpressionNodeHelperService {

  identificationExpressionService: IdentificationExpressionService;

  constructor(private specificationService: SpecificationService, private dataCategoryService: DataCategoriesService, private router: Router) { }


  assignValueEqualsComparisonNode(expressionModel: ServeridentificationExpressionModel) {
    if (!expressionModel) {
      return;
    }

    const expressionNode = this.createExpressionNode(this.nodeType,
      { data: '0', dataType: OdxDataType.AsciiString },
      ServerIdentificationCompareOperatorType.EqualTo,
      expressionModel.expressions.length);

    expressionModel.expressions.push(expressionNode);
  }

  assignValueStartsWithComparisonNode(expressionModel: ServeridentificationExpressionModel) {
    if (!expressionModel) {
      return;
    }

    const expression = this.createExpressionNode(this.nodeType,
      { data: '0', dataType: OdxDataType.AsciiString },
      ServerIdentificationCompareOperatorType.StartsWith,
      expressionModel.expressions.length);

    expressionModel.expressions.push(expression);
  }

  assignValueContainsComparisonNode(expressionModel: ServeridentificationExpressionModel) {
    if (!expressionModel) {
      return;
    }

    const expression = this.createExpressionNode(this.nodeType,
      { data: '0', dataType: OdxDataType.AsciiString },
      ServerIdentificationCompareOperatorType.Contains,
      expressionModel.expressions.length);

    expressionModel.expressions.push(expression);
  }


  assignCanAddressComparisonNode(expressionModel: ServeridentificationExpressionModel) {
    if (!expressionModel) {
      return;
    }

    const expression = this.createExpressionNode(ServerIdentificationExpressionNodeType.CanAddressCompareOperand,
      { data: '0', dataType: OdxDataType.AsciiString },
      ServerIdentificationCompareOperatorType.EqualTo,
      expressionModel.expressions.length);

    expressionModel.expressions.push(expression);
  }

  assignExecutingModeComparisonNode(expressionModel: ServeridentificationExpressionModel): any {
    if (!expressionModel) {
      return;
    }

    const expression = this.createExpressionNode(ServerIdentificationExpressionNodeType.ExecutionModeCompareOperand,
      { data: '', dataType: OdxDataType.AsciiString },
      ServerIdentificationCompareOperatorType.EqualTo,
      expressionModel.expressions.length);

    expressionModel.expressions.push(expression);
  }

  assignDoesNotExistComparisonNode(expressionModel: ServeridentificationExpressionModel): any {
    if (!expressionModel) {
      return;
    }

    const expression = this.createExpressionNode(ServerIdentificationExpressionNodeType.DoesNoExistCompareOperator,
      { data: 'false', dataType: OdxDataType.AsciiString },
      ServerIdentificationCompareOperatorType.EqualTo,
      expressionModel.expressions.length);

    expressionModel.legacyIdentifierCode = 0;
    expressionModel.expressions.push(expression);
  }

  assignSpecialComparisonNode(expressionModel: ServeridentificationExpressionModel) {
    if (!expressionModel) {
      return;
    }

    const expression = this.createExpressionNode(ServerIdentificationExpressionNodeType.SpecialCompareOperator,
      { data: '', dataType: OdxDataType.AsciiString },
      ServerIdentificationCompareOperatorType.EqualTo,
      expressionModel.expressions.length);

    expressionModel.expressions.push(expression);
  }

  assignOperator(expressionModel: ServeridentificationExpressionModel, notifyOnChange = true) {
    if (!expressionModel) {
      return;
    }

    const expression = this.createExpressionNode(ServerIdentificationExpressionNodeType.AndOperator, undefined, undefined, expressionModel.expressions.length);
    expressionModel.expressions.push(expression);

    if (notifyOnChange) {
      this.notifyOperatorContentChanged(expressionModel);
    }

    return expression;
  }

  assignValueComparisonExpressionNode(expressionModel: ServeridentificationExpressionModel, compareOperator: ServerIdentificationCompareOperatorType) {
    if (!expressionModel) {
      return;
    }

    const expression = this.createExpressionNode(this.nodeType,
      { data: '0', dataType: OdxDataType.UInt32 },
      compareOperator,
      expressionModel.expressions.length);

    expressionModel.expressions.push(expression);
  }

  assignOperatorWithInterval(expressionModel: ServeridentificationExpressionModel, notifyOnChange = true) {
    if (!expressionModel) {
      return;
    }

    const expression = this.createExpressionNode(ServerIdentificationExpressionNodeType.AndOperator, undefined, undefined, expressionModel.expressions.length);
    const leftExpressionNode = this.createExpressionNode(
      this.nodeType,
      { data: '0', dataType: OdxDataType.AsciiString },
      ServerIdentificationCompareOperatorType.GreaterThan,
      0);
    const rightExpressionNode = this.createExpressionNode(
      this.nodeType,
      { data: '0', dataType: OdxDataType.AsciiString },
      ServerIdentificationCompareOperatorType.LessThan,
      1);

    expression.expressions.push(leftExpressionNode, rightExpressionNode);
    expressionModel.expressions.push(expression);

    if (notifyOnChange) {
      this.notifyOperatorContentChanged(expressionModel);
    }

    return expression;
  }

  hasNonOperatorExpressionNodeType(expressionModel: ServeridentificationExpressionModel): boolean {
    if (expressionModel) {
      return expressionModel.expressions.every(exp =>
        exp.nodeType !== ServerIdentificationExpressionNodeType.AndOperator);
    } else {
      return true;
    }
  }

  isOperator(expressionModel: ServeridentificationExpressionModel): boolean {
    return expressionModel &&
      (expressionModel.nodeType === ServerIdentificationExpressionNodeType.AndOperator ||
        expressionModel.nodeType === ServerIdentificationExpressionNodeType.OrOperator);
  }

  hasMoreThanOneChild(expressionModel: ServeridentificationExpressionModel): boolean {
    return expressionModel ? expressionModel.expressions.length > 0 : false;
  }

  cloneExpression(expressionToCopy: ServeridentificationExpressionModel): ServeridentificationExpressionModel {
    return {
      id: expressionToCopy.id,
      index: expressionToCopy.index,
      nodeType: expressionToCopy.nodeType,
      expressions: expressionToCopy.expressions,
      compareValue: expressionToCopy.compareValue,
      compareCanAddress: expressionToCopy.compareCanAddress,
      legacyIdentifierCode: expressionToCopy.legacyIdentifierCode,
      compareOperator: expressionToCopy.compareOperator,
      ecuIdentifierPropertyToCompareWith: expressionToCopy.ecuIdentifierPropertyToCompareWith,
      compareRange: expressionToCopy.compareRange,
      subStringStart: expressionToCopy.subStringStart,
      subStringLength: expressionToCopy.subStringLength,
      searchLength: expressionToCopy.searchLength
    };
  }

  moveExpressionsToNewOperator(modelWithExpressionsToMove: ServeridentificationExpressionModel): ServeridentificationExpressionModel {
    let currentExpressions = new Array<ServeridentificationExpressionModel>();
    currentExpressions = currentExpressions.concat(modelWithExpressionsToMove.expressions);
    modelWithExpressionsToMove.expressions.splice(0, modelWithExpressionsToMove.expressions.length);

    const operatorNode = this.assignOperator(modelWithExpressionsToMove, false);

    currentExpressions.forEach(exp => {
      operatorNode.expressions.push(exp);
    });

    this.notifyOperatorContentChanged(operatorNode);

    return operatorNode;
  }

  deleteExpression(expressionModel: ServeridentificationExpressionModel, expression: ServeridentificationExpressionModel) {
    if (!expressionModel) {
      return;
    }

    const expressionIndex = this.getExpressionIndex(expressionModel, expression);

    if (expressionIndex > -1) {
      expressionModel.expressions.splice(expressionIndex, 1);
      this.refreshExpressionsIndex(expressionModel);
      this.notifyOperatorContentChanged(expression);
    }
  }

  copyExpression(expressionModel: ServeridentificationExpressionModel, expressionToCopy: ServeridentificationExpressionModel) {
    if (!expressionModel) {
      return;
    }

    const expressionIndex = this.getExpressionIndex(expressionModel, expressionToCopy);

    if (expressionIndex > -1) {
      expressionModel.expressions.push({
        compareOperator: expressionToCopy.compareOperator,
        compareRange: this.cloneCompareRange(expressionToCopy),
        compareValue: this.cloneCompareValue(expressionToCopy),
        compareCanAddress: expressionToCopy.compareCanAddress,
        ecuIdentifierPropertyToCompareWith: this.cloneEcuIdentiferProperty(expressionToCopy),
        expressions: [],
        nodeType: expressionToCopy.nodeType,
        legacyIdentifierCode: expressionToCopy.legacyIdentifierCode,
        index: expressionModel.expressions.length,
        subStringStart: expressionToCopy.subStringStart,
        subStringLength: expressionToCopy.subStringLength,
        id: 0,
        searchLength: expressionToCopy.searchLength
      });

      this.notifyOperatorContentChanged(expressionModel.expressions[expressionModel.expressions.length - 1]);
    }
  }

  moveUpExpression(expressionModel: ServeridentificationExpressionModel, expressionToMove: ServeridentificationExpressionModel) {
    if (!expressionModel) {
      return;
    }
    const expressionIndex = this.getExpressionIndex(expressionModel, expressionToMove);

    if (expressionIndex > -1) {
      if (expressionIndex > 0 && expressionModel.expressions.length > 1) {
        const targetIndex = expressionIndex - 1;
        const previousItem = expressionModel.expressions.splice(targetIndex, 1, expressionModel.expressions[expressionIndex]);
        expressionModel.expressions[expressionIndex] = previousItem[0];
        expressionModel.expressions[expressionIndex].index = expressionIndex;
        expressionModel.expressions[targetIndex].index = targetIndex;

        this.notifyOperatorContentChanged(expressionToMove);
      }
    }
  }

  moveDownExpression(expressionModel: ServeridentificationExpressionModel, expressionToMove: ServeridentificationExpressionModel) {
    if (!expressionModel) {
      return;
    }
    const expressionIndex = this.getExpressionIndex(expressionModel, expressionToMove);

    if (expressionIndex > -1) {
      if (expressionIndex < expressionModel.expressions.length - 1 && expressionModel.expressions.length > 1) {
        const targetIndex = expressionIndex + 1;
        const previousItem = expressionModel.expressions.splice(targetIndex, 1, expressionModel.expressions[expressionIndex]);
        expressionModel.expressions[expressionIndex] = previousItem[0];
        expressionModel.expressions[expressionIndex].index = expressionIndex;
        expressionModel.expressions[targetIndex].index = targetIndex;

        this.notifyOperatorContentChanged(expressionToMove);
      }
    }
  }

  getNextAvailableOperator(expressionModel: ServeridentificationExpressionModel): ServeridentificationExpressionModel {
    return expressionModel.expressions.find(exp => exp.nodeType === ServerIdentificationExpressionNodeType.AndOperator || exp.nodeType === ServerIdentificationExpressionNodeType.OrOperator);
  }

  createExpressionNode(nodeType: ServerIdentificationExpressionNodeType, comparevalue: TypedValueData,
    compareOperator: ServerIdentificationCompareOperatorType, nodeIndex: number): ServeridentificationExpressionModel {
    const expression = new ServeridentificationExpressionModel();
    expression.nodeType = nodeType;
    expression.compareValue = comparevalue;
    if (compareOperator != null) {
      expression.compareOperator = compareOperator;
    }
    expression.index = nodeIndex;
    expression.legacyIdentifierCode = 0;

    return expression;
  }

  notifyOperatorContentChanged(changedObj: any) {
    console.log('Operator Modified!');
    this.identificationExpressionService.modified.next(changedObj);
  }

  private getExpressionIndex(expressionModel: ServeridentificationExpressionModel, expressionToFind: ServeridentificationExpressionModel): number {
    if (!expressionModel) {
      return;
    }

    return expressionModel.expressions.findIndex(exp => exp === expressionToFind);
  }

  private refreshExpressionsIndex(expressionModel: ServeridentificationExpressionModel) {
    for (let i = 0; i < expressionModel.expressions.length; i++) {
      expressionModel.expressions[i].index = i;
    }
  }

  private cloneCompareRange(expression: ServeridentificationExpressionModel): TypedRangeModel {
    if (expression.compareRange) {
      return {
        maxLimitType: expression.compareRange.maxLimitType,
        minLimitType: expression.compareRange.minLimitType,
        maxValue: { data: expression.compareRange.maxValue.data, dataType: expression.compareRange.maxValue.dataType },
        minValue: { data: expression.compareRange.minValue.data, dataType: expression.compareRange.minValue.dataType }
      };
    } else {
      return null;
    }
  }

  private cloneCompareValue(expression: ServeridentificationExpressionModel): TypedValueData {
    if (expression.compareValue) {
      return {
        data: expression.compareValue.data,
        dataType: expression.compareValue.dataType,
      };
    } else {
      return null;
    }
  }

  private cloneEcuIdentiferProperty(expression: ServeridentificationExpressionModel): EcuIdentifierPropertyModel {
    if (expression.ecuIdentifierPropertyToCompareWith) {
      return {
        ecuIdentifierId: expression.ecuIdentifierPropertyToCompareWith.ecuIdentifierId,
        ecuIdentifier: expression.ecuIdentifierPropertyToCompareWith.ecuIdentifier,
        propertyName: expression.ecuIdentifierPropertyToCompareWith.propertyName
      };
    } else {
      return null;
    }
  }

  get nodeType(): ServerIdentificationExpressionNodeType {
    const identificationGroupRoute = 'identification-groups';

    if (this.router.url.includes(identificationGroupRoute)) {
      return ServerIdentificationExpressionNodeType.LegacyEcuIdentifierCompareOperand;
    }

    if (this.dataCategoryService.activeCategory && this.dataCategoryService.activeCategory.category === Category.Servers) {
      if (this.specificationService.isLegacyVersion) {
        if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
          return ServerIdentificationExpressionNodeType.LegacyEcuIdentifierCompareOperand;
        } else {
          return ServerIdentificationExpressionNodeType.EcuIdentifierCompareOperand;
        }
      } else {
        return ServerIdentificationExpressionNodeType.EcuIdentifierCompareOperand;
      }
    }
  }
}
