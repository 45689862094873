export abstract class DiagnosticRequisitesBase {

  protected filterValues(searchValue: string, valuesToFilter: string[]): string[] {
    if (valuesToFilter == null || valuesToFilter === undefined) {
      return [];
    } else {
      const filterValue = this.normalizeValue(searchValue);
      return valuesToFilter.filter(value => this.normalizeValue(value).includes(filterValue));
    }
  }

  protected normalizeValue(value: string): string {
    return value.toLocaleLowerCase().replace(/\s/g, '');
  }

  // protected abstract initRequisites(): void;
  protected abstract initFilters(): void;
  protected abstract loadCategoryItems(): void;
  protected abstract enableOrDisableInputControls(): void;
}
