import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { NavigationService } from 'app/app-services/navigation.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { DataCategoryModel } from 'app/data-categories/data-category-model';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Specification } from '../../../app-model/specification';
import { SpecificationVersion } from '../../../app-model/specification-version';
import { MessageService } from '../services/message-service.service';

@Component({
  template: ''
})
export class CategoryComponentBaseComponent<T> implements OnDestroy {
  isLoading = false;
  category: DataCategoryModel;
  specificationVersion: SpecificationVersion;
  specification: Specification;
  paramsChanged: BehaviorSubject<Params> = new BehaviorSubject<Params>(null);
  itemChanged: BehaviorSubject<T> = new BehaviorSubject(null);
  componentSubscriptions: Subscription[] = [];
  activeItemSubject = new Subject();

  constructor(
    protected specificationService: SpecificationService,
    protected activatedRoute: ActivatedRoute,
    protected categoryService: DataCategoriesService,
    protected messageService: MessageService,
    protected categoryEnum: Category,
    protected navigationService: NavigationService
  ) {
    this.activeItemSubject
      .pipe(
        switchMap(
          (input: {
            itemId: string;
            category: DataCategoryModel;
            params: ParamMap;
          }) => {
            if (!input.itemId) {
              input.category.resetActiveItem();
              return of([null, input.params]);
            }
            return input.category
              .setActiveItem(this.specificationVersion.id, input.itemId)
              .pipe(
                map(categoryItem => [categoryItem, input.params])
              );
          }
        )
      )
      .subscribe(
        (categoryItemFromRoute: any[]) => {
          const categoryItem = categoryItemFromRoute[0];
          const params = categoryItemFromRoute[1];

          if (this.category.category === Category.Services || (categoryItem && categoryEnum !== null)) {
            this.itemChanged.next(categoryItem as any);
            this.paramsChanged.next(params);
          } else {
            this.navigationService.navigateToFirstAvailableItem(this.category.category);
          }
        },
        error => {
          this.messageService.dispatchErrorMessageFromApi(error);
        }
      );

    this.setSelectedSpecificationSubscription();
  }

  setSelectedSpecificationSubscription() {
    const specificationEventEmitterSubscription = this.specificationService.selectedSpecificationEventEmitter.subscribe(
      (value: {
        specification: Specification;
        specificationVersion: SpecificationVersion;
      }) => {
        if (!value.specification || !value.specificationVersion) {
          return;
        }

        this.specification = value.specification;
        this.specificationVersion = value.specificationVersion;
        //this.isLoading = true;
        console.log('Loading category: ' + this.categoryEnum);
        const categorySubscription = this.categoryService
          .updateCategory(this.categoryEnum, this.specificationVersion)
          .subscribe(
            updatedCategory => {
              this.category = updatedCategory;
              this.categoryService.setActiveCategory(this.category);
              //this.isLoading = false;
              console.log('Category ' + this.categoryEnum + ' loaded');
              this.activatedRoute.queryParamMap.subscribe(params => {
                const itemId = params.get('itemId');
                this.activeItemSubject.next({
                  itemId: itemId,
                  category: this.category,
                  params
                });
              });
            },
            err => {
              this.messageService.dispatchErrorMessageFromApi(err);
            }
          );
        this.componentSubscriptions.push(categorySubscription);
      }
    );
    this.componentSubscriptions.push(specificationEventEmitterSubscription);
  }

  ngOnDestroy() {
    if (this.componentSubscriptions && this.componentSubscriptions.length > 0) {
      this.componentSubscriptions.forEach(subscription => {
        subscription.unsubscribe();
      });
    }
  }
}
