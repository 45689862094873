<div class="sdds-card sdds-clickable sdds-on-white-bg" [ngStyle]="{'background': isInWorkStatus(specificSpecificationVersion) ? '#ecf6f1' : 'transparent'}">
  <div class="sdds-card-body p-10">

    <div class="float-right" *ngIf="isAllowed">
      <a id="deleteBtn-spec" style="cursor: pointer;" (click)="removeSpecification.next(specificSpecificationVersion)">
       &times;</a>
    </div>
    <div>
      <table class="table-auto">
        <tr>
          <td>
            <b>Specification:</b>
          </td>
          <td>
            <span class="sdds-detail-02 ml-5">{{specificSpecificationVersion.specification.name}}</span>
          </td>
          <td>
              <a
              [routerLink]="['/specification',specificSpecificationVersion.specification.id,specificSpecificationVersion.specificationVersion.id]"><img
                src="font/iconsrc/link.png" style="width: 20px; margin-left: 5px;" /></a>
          </td>
        </tr>
        <tr>
          <td>
            <b>Version:</b>
          </td>
          <td><span class="sdds-detail-02 ml-5">{{specificSpecificationVersion.specificationVersion.name}}</span></td>
        </tr>
        <tr>
          <td>
            <b>Status: </b>
          </td>
          <td>
            <b style="margin-left: 5px;" *ngIf="isInWorkStatus(specificSpecificationVersion)" style="color:#60b269;">In
              Work</b>
            <b style="margin-left: 5px;" *ngIf="!isInWorkStatus(specificSpecificationVersion)"
              style="color: #448691;">Released</b>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
