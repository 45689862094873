import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';

import { FamilyGenerationPair } from '../app-model/family-generation-pair';
import { DiagnosticFamilyModel, GenerationModel } from '../app-model/server-identification/diagnostic-family.model';
import { DiagnosticFamilyService } from '../app-services/diagnostic-family.service';
import { MessageService } from '../modules/shared/services/message-service.service';

@Component({
  selector: 'app-family-generation',
  templateUrl: './family-generation.component.html',
  styleUrls: ['./family-generation.component.css']
})
export class FamilyGenerationComponent implements OnInit {

  @Input()
  isReadonly: boolean;

  @Input()
  isAllowed: boolean;

  @Output()
  update: EventEmitter<FamilyGenerationPair[]> = new EventEmitter<FamilyGenerationPair[]>();

  families: DiagnosticFamilyModel[];
  generations: GenerationModel[];

  selectedFamily: DiagnosticFamilyModel;
  selectedGeneration: GenerationModel;
  _familyGenerationPairs: FamilyGenerationPair[];
  loadingFamilies = false;
  loadingGenerations = false;
  showFamilyGenerationSelectorEnabled = false;

  constructor(private diagnosticFamilyService: DiagnosticFamilyService, private messageService: MessageService) { }

  ngOnInit() {
    this.getFamilies();
  }

  get familyGenerationPairs(): FamilyGenerationPair[] {
    return this._familyGenerationPairs;
  }

  @Input()
  set familyGenerationPairs(value: FamilyGenerationPair[]) {
    this._familyGenerationPairs = [].concat(value);
  }

  get canAdd() {
    return this.selectedFamily && this.selectedGeneration
      && !this.familyGenerationPairs.some(x => x.diagnosticFamily.id === this.selectedFamily.id && x.generation.id === this.selectedGeneration.id);
  }

  get canAddTooltip() {
    if (this.canAdd === false) {
      return 'This combination cannot be added either because it already exists in the list or because it is invalid.';
    }

    return '';
  }

  get familyGenerationPairsExists() {
    return this.familyGenerationPairs && this.familyGenerationPairs.length > 0;
  }

  showFamilyGenerationSelector() {
    this.showFamilyGenerationSelectorEnabled = !this.showFamilyGenerationSelectorEnabled;
  }

  getFamilies() {
    this.loadingFamilies = true;
    this.diagnosticFamilyService.getFamilies().pipe(tap(families => {
      this.families = families;
      if (families && families.length > 0) {
        this.familyChanged(families[0]);
      } else {
        this.familyChanged(null);
      }
      this.loadingFamilies = false;
    }, error => {
      this.loadingFamilies = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    })).subscribe();
  }

  getGenerations() {
    if (!this.selectedFamily) {
      return;
    }

    this.loadingGenerations = true;
    this.diagnosticFamilyService.getGenerations(this.selectedFamily.name).pipe(tap(generations => {
      this.generations = generations;
      if (generations && generations.length > 0) {
        this.generationChanged(generations[0]);
      } else {
        this.generationChanged(null);
      }
      this.loadingGenerations = false;
    }, error => {
      this.loadingGenerations = false;
      this.messageService.dispatchErrorMessageFromApi(error);
    })).subscribe();
  }

  add() {
    if (this.selectedFamily && this.selectedGeneration) {
      const familyGenerationPair = new FamilyGenerationPair();
      familyGenerationPair.diagnosticFamily = this.selectedFamily;
      familyGenerationPair.generation = this.selectedGeneration;

      this.familyGenerationPairs.push(familyGenerationPair);
      this.update.emit(this.familyGenerationPairs);
    }

    this.showFamilyGenerationSelectorEnabled = false;
  }

  remove(familyGenerationPair: FamilyGenerationPair) {
    if (!familyGenerationPair) {
      return;
    }

    this.familyGenerationPairs.splice(this.familyGenerationPairs.indexOf(familyGenerationPair), 1);
    this.update.emit(this.familyGenerationPairs);
  }

  familyChanged(family: DiagnosticFamilyModel) {
    this.selectedFamily = family;
    this.getGenerations();
  }

  generationChanged(generation: GenerationModel) {
    this.selectedGeneration = generation;
  }
}
