import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { EcuValueModel } from 'app/app-model/ecu-value.model';
import { CategoryServiceBase } from 'app/app-services/category-base.service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { ValueCategoryItemDirective } from 'app/data-categories/values/value-category-item';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { SpecificationService } from './specification-service';

@Injectable({
  providedIn: 'root'
})
export class ValuesService extends CategoryServiceBase<EcuValueModel, ValueCategoryItemDirective> {

  constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
    private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected apiProxy: ApiProxyService) {
    super(apiProxy);
    this.apiCategoryName = 'values';
  }

  getItemsPreview(specificationVersionId: number): Observable<ValueCategoryItemDirective[]> {
    this.isPending = true;

    if (this.hasCachedItems) {
      this.isPending = false;
      return of(this.items);
    }

    return this.apiProxy.get<EcuValueModel[]>(`${environment.apiUrl}versions/${specificationVersionId}/values`).pipe(map((values) => {
      this.items = new Array<ValueCategoryItemDirective>();
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < values.length; i++) {
        const ecuValueCategoryItem = new ValueCategoryItemDirective(this.specificationService, this.locationService, this.router, this.activatedRoute);
        ecuValueCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
        ecuValueCategoryItem.valuesService = this;
        ecuValueCategoryItem.model = values[i];
        ecuValueCategoryItem.isStoredInSource = true;
        this.items.push(ecuValueCategoryItem);
      }

      this.hasCachedItems = true;
      this.isPending = false;
      this.getItemsPreviewDone.next(this.isPending);

      return this.items;
    }));
  }

  getCompleteItems(specificationVersionId: number): Observable<ValueCategoryItemDirective[]> {
    throw new Error('Method not implemented.');
  }

  public createItem(specificationVersionId: number, itemName?: string): Observable<ValueCategoryItemDirective> {
    const valueModel = new EcuValueModel();
    valueModel.name = itemName;
    valueModel.readSequence = new ServiceExecutionSequenceModel();

    return this.apiProxy.post<EcuValueModel>(
      `${environment.apiUrl}versions/${specificationVersionId}/values/`, valueModel)
      .pipe(map(savedEcuValue => {
        const ecuValueCategoryItem = this.createEcuValueCategoryItem(savedEcuValue);
        this.items.push(ecuValueCategoryItem);

        return ecuValueCategoryItem;
      }));
  }

  getCategoryItemModel(specificationVersionId: number, name: string): Observable<ValueCategoryItemDirective> {
    return this.apiProxy.get<ValueCategoryItemDirective>(`${environment.apiUrl}versions/${specificationVersionId}/values/${name}/`).pipe(map(item => {
      const ecuValueCategoryItem = new ValueCategoryItemDirective(this.specificationService, this.locationService, this.router, this.activatedRoute);
      ecuValueCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      ecuValueCategoryItem.valuesService = this;
      ecuValueCategoryItem.model = item;
      ecuValueCategoryItem.isStoredInSource = true;
      this.items.push(ecuValueCategoryItem);
      return ecuValueCategoryItem;
    }));
  }

  deleteItem(specificationVersionId: number, itemName?: string): Observable<any> {
    return this.apiProxy.delete(`${environment.apiUrl}versions/${specificationVersionId}/values/${itemName}`)
      .pipe(tap(deletedItem => this.removeCachedItem(deletedItem.name)));
  }

  updateItem(specificationVersionId: number, valueDataModel: any, itemName?: string): Observable<EcuValueModel> {
    const dataModel = valueDataModel as EcuValueModel;
    const cachedItem = this.findCachedItem(dataModel.name);

    if (!this.hasValidData(dataModel)) {
      return of(dataModel);
    }

    return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/values/${itemName}/`, dataModel);
  }

  public getValueItem(specificationVersionId: number, name: string): Observable<ValueCategoryItemDirective> {
    return this.apiProxy.get<ValueCategoryItemDirective>(`${environment.apiUrl}versions/${specificationVersionId}/values/${name}/`);
  }

  private createEcuValueCategoryItem(ecuValueModel: EcuValueModel): ValueCategoryItemDirective {
    const valueItem = new ValueCategoryItemDirective(this.specificationService, this.locationService, this.router, this.activatedRoute);
    valueItem.valuesService = this;
    valueItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
    valueItem.isPreview = false;
    valueItem.model = ecuValueModel;
    valueItem.isSyncingFromMaster = false;

    return valueItem;
  }

  private hasValidData(ecuValueModel: EcuValueModel): boolean {
    return this.valueHasAnyValidSequence(ecuValueModel);
  }

  private valueHasAnyValidSequence(ecuValueModel: EcuValueModel): boolean {
    const hasValidReadSequence = !ecuValueModel.readSequence || (ecuValueModel.readSequence &&
      ecuValueModel.readSequence.executions &&
      ecuValueModel.readSequence.executions.length > 0);

    const hasValidWriteSequence = !ecuValueModel.writeSequence || (ecuValueModel.writeSequence &&
      ecuValueModel.writeSequence.executions &&
      ecuValueModel.writeSequence.executions.length > 0);

    return hasValidReadSequence && hasValidWriteSequence;
  }
}
