import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../modules/shared/model/user';

//  Data source for the AdminDataTable view. This class should
//  encapsulate all logic for fetching and manipulating the displayed data
//  (including sorting, pagination, and filtering).
export class AdminDataTableDataSource extends DataSource<User> {
  data: User[] = [];
  paginator: MatPaginator;
  sort: MatSort;

  constructor() {
    super();
  }

  setUsers(users: User[]) {
    this.data = users;
  }

  //  Connect this data source to the table. The table will only update when
  //  the returned stream emits new items.
  //  @returns A stream of the items to be rendered.
  connect(): Observable<User[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      of(this.paginator.page),
      of(this.sort.sortChange)
    ];

    return merge(...dataMutations).pipe(map(() => this.getPagedData(this.getSortedData([...this.data]))));
  }

  //    Called when the table is being destroyed. Use this function, to clean up
  //    any open connections or free any held resources that were set up during connect.
  disconnect() { }

  //   Paginate the data (client-side). If you're using server-side pagination,
  //   this would be replaced by requesting the appropriate data from the server.
  private getPagedData(data: User[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  //   Sort the data (client-side). If you're using server-side sorting,
  //   this would be replaced by requesting the appropriate data from the server.
  private getSortedData(data: User[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'firstName': return this.compare(a.firstName, b.firstName, isAsc);
        case 'lastName': return this.compare(a.lastName, b.lastName, isAsc);
        case 'userName': return this.compare(a.userName, b.userName, isAsc);
        default: return 0;
      }
    });
  }

  //  Simple sort comparator for example ID/Name columns (for client-side sorting).
  private compare(a: string, b: string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
