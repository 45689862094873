<div class="sdds-row">
  <div
    class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding toolbar-button">
    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" id="{{createItemId}}"
      [ngClass]="{'hidden':!(addButtonShow && addWithModalEnabled)}" [attr.data-target]="addDataTarget"
      [disabled]="!isAllowed" (click)="addClicked()">
      <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon> {{addText}}
    </button>

    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm"
      [ngClass]="{'hidden':!(addButtonShow && addWithoutModalEnabled)}" [disabled]="!isAllowed" (click)="addClicked()">
      <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon> {{addText}}
    </button>

    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" disabled id="{{createItemId}}"
      [ngClass]="{'hidden':!(addButtonShow && addEnabled && addNotAllowed)}" [title]="addNotAllowedReason"
      [disabled]="!isAllowed">
      <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon> {{addText}}
    </button>

    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" id="{{createItemId}}"
      [ngClass]="{'hidden':!(addButtonShow && addEnabled && !dataTargetEnabledAdd)}" (click)="addClicked()"
      [disabled]="!isAllowed">
      <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon> {{addText}}
    </button>

    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" id="{{removeItemId}}" [disabled]="!isAllowed"
      [ngClass]="{'hidden': !(removeButtonShow && removeWithModalEnabled)}" (click)="removeClicked()">
      <mat-icon fontSet="material-icons-outlined" class="md-18">remove</mat-icon> {{removeText}}
    </button>

    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" id="{{removeItemId}}"
      [ngClass]="{'hidden': !(removeButtonShow && removeWithoutModalEnabled)}" (click)="removeClicked()"
      [disabled]="!isAllowed">
      <mat-icon fontSet="material-icons-outlined" class="md-18">remove</mat-icon> {{removeText}}
    </button>

    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" disabled id="{{removeItemId}}"
      [ngClass]="{'hidden': !(removeButtonShow && (removeEnabled || removeEventEmitterEnabled) && removeNotAllowed)}"
      [title]="removeNotAllowedReason" [disabled]="!isAllowed">
      <mat-icon fontSet="material-icons-outlined" class="md-18">remove</mat-icon> {{removeText}}
    </button>

    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" id="{{removeItemId}}"
      [ngClass]="{'hidden': !(removeButtonShow && (removeEnabled || removeEventEmitterEnabled) && !dataTargetEnabledRemove)}"
      (click)="removeClicked()" [disabled]="!isAllowed">
      <mat-icon fontSet="material-icons-outlined" class="md-18">remove</mat-icon> {{removeText}}
    </button>

    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" *ngIf="collapseEnabled" (click)="collapseClicked()">
      <mat-icon fontSet="material-icons-outlined" class="md-18">compress</mat-icon> {{collapseText}}
    </button>

    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" *ngIf="expandEnabled" (click)="expandClicked()">
      <mat-icon fontSet="material-icons-outlined" class="md-18">expand</mat-icon> {{expandText}}
    </button>

    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" id="{{createButtonId}}" (click)="addClicked()"
      [ngClass]="{'hidden': !(createSpecificationOrVersionEnabled)}"
      [attr.data-target]="createSpecificationOrVersionEnabled ? addDataTarget : ''"
      [disabled]="!isAllowed">{{addButtonText}}
    </button>

    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" id="{{upgradeButtonId}}" (click)="upgradeClicked()"
      [ngClass]="{'hidden': !(upgradeVersionEnabled)}"
      [attr.data-target]="upgradeVersionEnabled ? upgradeDataTarget : ''" [disabled]="!isAllowed">{{upgradeButtonText}}
    </button>
  </div>

  <div
    class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-12"
    *ngIf="filterEnabled && filterShown">
    <input class="sdds-textfield-input-md" [(ngModel)]="filterText" type="text" placeholder="Search...">
  </div>
</div>