import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { TreeService } from '../service/tree.service';
import { Tree } from '../model/tree';
import { TreeNodeService } from 'app/app-services/tree-node.service';
import { Subscription } from 'rxjs';
import { StructureSpecificationTreeNode } from '../model/structure-specification-tree-node';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css'],
  providers: [TreeService]
})

export class TreeComponent implements OnInit, OnDestroy {

  @Input()
  tree: Tree;

  @ViewChild('removeDialogtrigger') removeDialogtrigger: ElementRef;

  structureSpecificationTreeNode: StructureSpecificationTreeNode;

  deleteSpecificationModalSubscription: Subscription;

  constructor(private treeNodeService: TreeNodeService) { }

  ngOnInit() {
    this.deleteSpecificationModalSubscription = this.treeNodeService.viewDeleteSpecificationModal.subscribe(treeNode => {
      this.structureSpecificationTreeNode = treeNode;
      this.removeDialogtrigger.nativeElement.click();
    });
  }

  ngOnDestroy(): void {
    this.deleteSpecificationModalSubscription.unsubscribe();
  }
}
