import { Edge } from './edge';
import { Node } from './node';

export class DcodeGraph {
    edges: Edge[];
    nodes: Node[];

    constructor() {
        this.nodes = [];
        this.edges = [];
    }

    addNode(node: Node) {
        this.nodes.push(node);
    }

    addEdge(edge: Edge) {
        this.edges.push(edge);
    }
}