import { Component, Input } from '@angular/core';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';

import { IdentificationExpressionItemService } from '../../../app-services/identification-expression-item.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'app/data-categories/legacy-files/delete-dialog/delete-dialog.component';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-expression-view-toolbar',
  templateUrl: './expression-view-toolbar.component.html',
  styleUrls: ['./expression-view-toolbar.component.css']
})


export class ExpressionViewToolbarComponent {
  @Input()
  reorderEnabled = true;

  @Input()
  copyEnabled = true;

  @Input()
  removeEnabled = true;

  @Input()
  expressionModel: ServeridentificationExpressionModel;

  @Input()
  isReadOnly: boolean;

  @Input()
  isAllowed: boolean;

  get doYouWantToDeleteMessage() {
    return 'Do you want to delete expression node ?'
  }
  private _iconColor = '#041e42';
  public get iconColor(): string {
    return this._iconColor;
  }
  @Input()
  public set iconColor(v: string) {
    this._iconColor = v;
  }
  openDialog(expressionModel: ServeridentificationExpressionModel) {
    let dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        fileName: "expression node",
      },
    });
    const sub = dialogRef.componentInstance.yesEventEmitter.subscribe(() => {
      this.identificationExpressionItemService.onDelete.next(expressionModel)
    })
    dialogRef.afterClosed().subscribe(result => {
      dialogRef.componentInstance.yesEventEmitter.unsubscribe();
    });
  }

  constructor(private identificationExpressionItemService: IdentificationExpressionItemService,
    public dialog: MatDialog,
    private authService:AuthService) { }
    canEditCategoryItems():boolean{
      return this.authService.canEditCategoryItems();
    }
}
