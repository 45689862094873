import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { MessageService } from '../modules/shared/services/message-service.service';
import { StructureSpecificationTreeNode } from 'app/modules/shared/components/tree/model/structure-specification-tree-node';

@Injectable({
  providedIn: 'root'
})
export class TreeNodeService {

 viewDeleteSpecificationModal: Subject<StructureSpecificationTreeNode> = new Subject<StructureSpecificationTreeNode>();


  constructor() {
  }

}
