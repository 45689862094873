<app-dialog-base title="Add Scomm shared file specification" modalId="createSharedFileModal"
  [itemTemplate]="createSharedFileTemplate" [cancelEnabled]="!loading " [applyEnabled]="!loading && fileSpecificationToCreate" (modalApply)="apply()"
  (modelCancel)="cancel()" applyCaption="Create"></app-dialog-base>


<ng-template #createSharedFileTemplate>

  <label class="sdds-btn sdds-btn-primary sdds-btn-sm">
      Browse File
    <input class="hidden" type="file" (change)="fileChanged($event)">
  </label>

  <br />
  <label *ngIf="fileSpecificationToCreate" class="my-10">Click on "Create" to create a specification for
    {{fileSpecificationToCreate.name}}</label>

  <div class="sdds-dropdown sdds-dropdown-small mt-10 w-4/12">
    <span class="sdds-dropdown-label-outside">Shared file type</span>
    <select id="fileTypeSelect" [(ngModel)]="sharedFileType">
      <option [ngValue]="SharedFileType.KeyFile">KeyFile</option>
      <option [ngValue]="SharedFileType.Other">Other</option>
    </select>
  </div>

  <div class="sdds-checkbox-item mt-10">
    <input class="sdds-form-input" type="checkbox" [(ngModel)]="alwaysInclude">
      <label class="sdds-form-label" for="cb-option-1">
        Always include
      </label>
  </div>
</ng-template>
