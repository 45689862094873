import { CategoryItem } from 'app/app-model/category-item';
import { DiagnosticServiceModel } from 'app/app-model/diagnostic-service/diagnostic.service.model';
import { LengthKind, TerminationType } from 'app/app-model/diagnostic-service/length.model';
import { ParameterDataModel } from 'app/app-model/diagnostic-service/parameter-data.model';
import { ScalingModel, ScaniaEncodingType } from 'app/app-model/diagnostic-service/parameter.scaling.model';
import { MessageType } from 'app/modules/shared/model/service/message-type';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { ParameterType } from 'app/modules/shared/model/service/parameters/parameter-type';
import { ChartPrecondition } from 'app/modules/shared/model/service/state-chart';

import { Transition } from '../../../../app-model/state-chart/state-chart.model';
import { MessageData } from './message-data';
import { OdxDataType } from './parameters/typed-value-data';

export class DiagnosticServiceCategoryItem extends CategoryItem {

  filteredResponseValueParameters: Array<ParameterData> = [];
  filteredRequestValueParameters: Array<ParameterData> = [];

  allResponseParameters: Array<ParameterData> = [];
  allRequestParameters: Array<ParameterData> = [];
  chartPreconditions: Array<ChartPrecondition> = [];
  transitions: Array<Transition> = [];

  private _model: DiagnosticServiceModel;

  public get model(): DiagnosticServiceModel {
    return this._model;
  }
  public set model(v: DiagnosticServiceModel) {
    this._model = v;
    this.syncFromModel();
  }

  constructor(diagnosticServiceModel: DiagnosticServiceModel, isPreview?: boolean) {
    super();
    this._model = diagnosticServiceModel;
    this.isPreview = isPreview;
    this.syncFromModel();
  }

  syncFromModel() {
    this.id = this._model.id;
    this.name = this._model.name;

    if (!this.isPreview) {
      this.retrieveParametersFromUnderlayingModel();
    }
  }

  retrieveParametersFromUnderlayingModel() {
    if (!this._model) {
      return;
    }
    this.filteredResponseValueParameters = this.getValueParametersFromMessagesOfType(MessageType.Response);
    this.filteredRequestValueParameters = this.getValueParametersFromMessagesOfType(MessageType.Request);
    this.allRequestParameters = this.getParametersFromMessagesOfType(MessageType.Request);
    this.allResponseParameters = this.getParametersFromMessagesOfType(MessageType.Response);
    this.chartPreconditions = this._model.chartPreconditions;
    this.transitions = this._model.transitions;
  }

  getParametersFromMessagesOfType(messageType: MessageType): ParameterData[] {
    const message = this.getMessage(messageType);

    if (!message || !message.parameters) {
      return [];
    }

    return message.parameters.map(p => {
      const param = new ParameterData();
      param.sourceService = this.name;
      param.model = p;
      return param;
    });
  }

  getValueParametersFromMessagesOfType(messageType: MessageType): ParameterData[] {
    const messages = this._model.messages.filter(message => message.messageType === messageType);

    // Parameters needs to be reinstantiated in order to get a concrete ParameterData instance
    let parameters = [];
    messages.forEach(message => {
      if (message.parameters) {
        const rawValueParameters = message.parameters.filter(parameter => parameter.type === ParameterType.Value || parameter.type === ParameterType.System);
        const valueParameters = rawValueParameters.map<ParameterData>(parameter => {
          const valueParam = new ParameterData();
          valueParam.sourceService = this.name;
          valueParam.model = parameter;
          return valueParam;
        });
        parameters = parameters.concat(valueParameters);
      }
    });

    return parameters;
  }

  getMessage(messageType: MessageType): MessageData {
    return this._model.messages.find(m => m.messageType === messageType);
  }

  getMessageById(messageId: number): MessageData {
    return this._model.messages.find(m => m.id === messageId);
  }

  createDefaultParameter(messageId: number, scalingModel: ScalingModel, paramBytePosition = 0): ParameterDataModel {
    return {
      bitPosition: 0,
      bytePosition: paramBytePosition,
      length: {
        id: 0,
        bitLength: 8,
        kind: LengthKind.Standard,
        maxByteLength: 0,
        minByteLength: 0,
        hasMaxByteLength: true,
        termination: TerminationType.EndOfMessage
      },
      name: '',
      index: 0,
      id: 0,
      messageId,
      type: ParameterType.Value,
      value: { data: '', dataType: OdxDataType.AsciiString },
      scaling: scalingModel
    };
  }

  createResponseParameter(): ParameterDataModel {
    const messageToModify = this.getMessage(MessageType.Response);
    const scalingModel = new ScalingModel();
    scalingModel.physicalDataType = OdxDataType.UInt32;
    scalingModel.unit = '-';
    scalingModel.internalDataType = OdxDataType.UInt32;
    scalingModel.encoding = ScaniaEncodingType.Default;

    if (!messageToModify.parameters) {
      messageToModify.parameters = [];
    }

    const paramBytePosition = this.calculateNextBytePosition(messageToModify);
    const parameter = this.createDefaultParameter(messageToModify.id, scalingModel, paramBytePosition);
    return parameter;
  }

  createRequestParameter(): ParameterDataModel {
    const messageToModify = this.getMessage(MessageType.Request);
    const scalingModel = new ScalingModel();
    scalingModel.physicalDataType = OdxDataType.UInt32;
    scalingModel.unit = '-';
    scalingModel.internalDataType = OdxDataType.UInt32;
    scalingModel.encoding = ScaniaEncodingType.Default;

    if (!messageToModify.parameters) {
      messageToModify.parameters = [];
    }

    const paramBytePosition = this.calculateNextBytePosition(messageToModify);
    const parameter = this.createDefaultParameter(messageToModify.id, scalingModel, paramBytePosition);

    return parameter;
  }

  calculateNextBytePosition(messageToModify: MessageData): number {
    if (!messageToModify.parameters || messageToModify.parameters.length === 0) {
      return 0;
    } else {
      const lastParameter = messageToModify.parameters[messageToModify.parameters.length - 1];
      const nextBytePos = lastParameter.bytePosition + (Math.ceil(lastParameter.length.bitLength / 8));

      return nextBytePos;
    }
  }

  addParameter(parameter: ParameterDataModel, messageType: MessageType) {
    const messageToModify = this.getMessage(messageType);
    messageToModify.parameters.push(parameter);
    this.retrieveParametersFromUnderlayingModel();
  }

  removeParameter(paramToRemove: ParameterDataModel, messageType: MessageType) {
    const message = this.getMessage(MessageType.Response);
    const parameterPool = messageType === MessageType.Request ? this.allRequestParameters : this.allResponseParameters;
    let nextParameter: ParameterData;

    if (parameterPool.length > 1) {
      const indexOfParamToRemove = parameterPool.findIndex(param => param.model.id === paramToRemove.id);
      if (indexOfParamToRemove === 0) {
        nextParameter = parameterPool[1];
      } else {
        nextParameter = parameterPool[parameterPool.findIndex(param => param.model.id === paramToRemove.id) - 1];
      }
    }

    this.removeParameterFromMessage(message, paramToRemove);

    return nextParameter;
  }

  updateParameterModel(updatedParamModel: ParameterDataModel): any {
    const messageToModify = this.model.messages.find(message => message.id === updatedParamModel.messageId);

    if (messageToModify) {
      const paramToUpdateIndex = messageToModify.parameters.findIndex(param => param.id === updatedParamModel.id);

      if (paramToUpdateIndex >= 0) {
        messageToModify.parameters[paramToUpdateIndex] = updatedParamModel;
        this.retrieveParametersFromUnderlayingModel();
      }
    }
  }

  private removeParameterFromMessage(message: MessageData, paramToRemove: ParameterDataModel) {
    const parameterToRemove = message.parameters.find(p => paramToRemove === p);
    const parameterToRemoveIndex = message.parameters.indexOf(parameterToRemove);

    message.parameters.splice(parameterToRemoveIndex, 1);
    this.retrieveParametersFromUnderlayingModel();
  }

  public requestDid(item: any): string {
    return item.allRequestParameters.length > 1 ? item.allRequestParameters[1].hexValue : " ";
  }

  public requestSid(item: any): string {
    return item.allRequestParameters.length > 1 ? item.allRequestParameters[0].hexValue : " ";
  }
}
