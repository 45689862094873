<button mat-button class="close-icon float-right -top-24" [mat-dialog-close]="true">
  <mat-icon>close</mat-icon>
</button>
<h2 mat-dialog-title class="text-9xl">Are you sure? </h2>
<div mat-dialog-content>
<p class="text-7xl mb-5">You want to delete  {{data.fileName}}?</p>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close="false">No</button>
  <button mat-button mat-dialog-close cdkFocusInitial  class="bg-blue" (click)="yes()">Yes</button>
</mat-dialog-actions>

