import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OperationalDataAxisType } from 'app/app-model/operational-data/operational-data-enums';
import { OperationalDataAxisModel } from 'app/app-model/operational-data/operational-data.model';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-axis-definition',
  templateUrl: './axis-definition.component.html',
  styleUrls: ['./axis-definition.component.css']
})
export class AxisDefinitionComponent {

  @Input()
  axisTag = '';

  @Input()
  axisModel: OperationalDataAxisModel;

  @Output()
  axisDefinitionChanged: EventEmitter<void> = new EventEmitter();

  @Input()
  disabled: boolean;

  @Input()
  isReadOnly: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  OperationalDataAxisType = OperationalDataAxisType;

  constructor(private authService:AuthService) { }

  getAxisTypes(): string[] {
    const keys = Object.keys(OperationalDataAxisType);
    return keys.slice(keys.length / 2);
  }

  onAxisDefinitionChange() {
    this.axisDefinitionChanged.next();
  }

  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
