<div *ngIf="treeNode" class="tree-node" (mouseover)="hasMouseCapture=true" (mouseout)="hasMouseCapture=false">
    <div [class.hide]="!treeNode.isVisible" [tabindex]="treeNode.isSelected ? 0 : -1"
        [ngClass]="{'active': treeNode.specificationVersionTreeNode.isOpen || (treeNode.isSelected && !treeNode.isSpecificationVersionTreeNode)}"
        (click)="expandCollapseTreeNode($event)" (keydown)="keydown($event);"
        [appFocus]="treeNode.isSelected">
        <app-node-description  [treeNode]="treeNode" (updateDataEvent)="updateData($event)"></app-node-description>
        <app-node-details *ngIf="treeNode.isExpanded" [treeNode]="treeNode" [diagnosticNo]="diagnosticNo"></app-node-details>
    </div>

    <div *ngIf="treeNode.isExpanded">
        <div *ngFor="let c of treeNode.children" class="tree-node-child">
            <app-tree-node [treeNode]="c"></app-tree-node>
        </div>
    </div>
</div>
