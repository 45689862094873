import { Pipe, PipeTransform } from '@angular/core';
import { ServiceExecutionDirective, ServiceExecutionType } from 'app/modules/shared/model/service-execution/service-execution';
import { PropertyType } from 'app/modules/shared/model/properties/base-property';

@Pipe({ name: 'serviceExecutionOfPipeType' })
export class ServiceExecutionOfTypePipe implements PipeTransform {

  transform(serviceExecutions: ServiceExecutionDirective[], propertyType: PropertyType) {
    if (!serviceExecutions) {
      return [];
    }

    return serviceExecutions.filter(serviceExecution => {
      switch (propertyType) {
        case PropertyType.Source:
          return serviceExecution.getServiceExecutionTypes().some(x => x === ServiceExecutionType.Response);
        case PropertyType.Target:
          return serviceExecution.getServiceExecutionTypes().some(x => x === ServiceExecutionType.Request);
        default:
          return false;
      }
    });
  }

}
