import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToolbarComponent } from 'app/modules/shared/components/toolbar/toolbar.component';

import { SpecificationVersion } from '../../../../../app-model/specification-version';
import { SpecificationService } from '../../../../../app-services/specification-service';
import { MessageService } from '../../../../shared/services/message-service.service';
import { View } from '../../../model/view';
import { ViewService } from '../../../services/view.service';
import { SpecificSpecificationVersion } from '../model/specific-specification-version';

@Component({
  selector: 'app-add-specific-specification-version',
  templateUrl: './add-specific-specification-version.component.html',
  styleUrls: ['./add-specific-specification-version.component.css']
})
export class AddSpecificSpecificationVersionComponent implements OnInit {


  @ViewChild('select', { static: true }) selectElement: ElementRef;
  @ViewChild('filterToolbar', { static: true }) filterToolbar: ToolbarComponent;

  @Output()
  addDoneEventEmitter: EventEmitter<SpecificSpecificationVersion> = new EventEmitter<SpecificSpecificationVersion>();

  @Input()
  currentView: View;

  @Input()
  initEventEmitter: EventEmitter<void>;

  modalClosing: EventEmitter<void> = new EventEmitter<void>();
  loading = false;
  selectedSpecificationVersions = new Array<SpecificationVersion>();
  numberOfAddedSpecificationVersions = 0;

  constructor(private specificationService: SpecificationService, private messageService: MessageService, private viewService: ViewService) { }

  clearFilters(): any {
    console.log('Clearing filters');
    if (this.filterToolbar) {
      this.filterToolbar.filterText = undefined;
    }
  }

  ngOnInit() {
    this.initEventEmitter.subscribe(() => {
    });
  }

  get canAdd() {
    return this.selectedSpecificationVersions && this.selectedSpecificationVersions.length > 0;
  }

  createNewSpecificVersionItem() {
    this.loading = true;
    this.numberOfAddedSpecificationVersions = 0;
    this.selectedSpecificationVersions.forEach(selectedSpecificationVersion => {
      this.viewService.addSpecificationVersionToView(this.currentView, selectedSpecificationVersion).subscribe(addedSpecificationVersion => {
        const specificSpecificationVersionItem = new SpecificSpecificationVersion(addedSpecificationVersion);
        this.addDoneEventEmitter.emit(specificSpecificationVersionItem);
        this.updateNumberOfAddedVersions();
      }, error => {
        this.updateNumberOfAddedVersions(false);
        this.messageService.dispatchErrorMessageFromApi(error);
      });
    });
  }

  updateNumberOfAddedVersions(closeModal: boolean = true) {
    this.numberOfAddedSpecificationVersions++;
    if (this.numberOfAddedSpecificationVersions >= this.selectedSpecificationVersions.length) {
      if (closeModal === true) {
        this.reset();
        this.modalClosing.emit();
      }
      this.loading = false;
    }
  }

  reset() {
    this.selectedSpecificationVersions.splice(0, this.selectedSpecificationVersions.length);
    // this.specVersionSelectorRef.reset();
  }
}
