import { Component, OnInit } from '@angular/core';
import { LegacyContentModel } from 'app/app-model/legacyContent.model';
import { LegacyContentService } from 'app/app-services/legacyContent.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-freeze-frames',
  templateUrl: './freeze-frames.component.html',
  styleUrls: ['./freeze-frames.component.css']
})
export class FreezeFramesComponent implements OnInit {

  legacyFreezeFrames: LegacyContentModel;
  specificationEventSubscription: Subscription;

  constructor(private legacyContentService: LegacyContentService,
    private specificationService: SpecificationService) { }

  ngOnInit(): void {
    this.specificationEventSubscription = this.specificationService.selectedSpecificationEventEmitter.subscribe(spec => {
      if (spec.specification)
        this.getLegacyFreezeFrameItems(this.specificationService.currentSpecificationVersion.id);
    });
  }

  private getLegacyFreezeFrameItems(versionId: number) {
    this.legacyContentService.getLegacyFreezeFrames(versionId).subscribe(freezeframes => {
      if (freezeframes.xmlContent) {
        this.legacyFreezeFrames = freezeframes;
      }
    });
  }
}
