import { Injectable } from '@angular/core';
import { ValidationIssue } from 'app/app-model/validation/validation-issue';
import { SpecificationService } from 'app/app-services/specification-service';
import { ApiProxyService } from 'app/modules/shared/services/api-proxy.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { SpecificationVersion } from '../app-model/specification-version';
import { ApiMessageService } from '../modules/shared/services/api-message-service';
import { ScommFileValidationResult } from 'app/app-model/legacy-file';

@Injectable({
  providedIn: 'root'
})
export class SpecificationVersionValidationService {
  specificationVersion: SpecificationVersion;
  issueChangedEventEmitter: BehaviorSubject<{ issues: Array<ValidationIssue>; warnings: number; errors: number }> =
    new BehaviorSubject({ issues: new Array<ValidationIssue>(), warnings: 0, errors: 0 });

  validationStateEventEmitter: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private changeCompleted:  Subject<void> = new Subject();

  constructor(apiMessageService: ApiMessageService, private specificationService: SpecificationService, private apiProxy: ApiProxyService) {

    this.specificationService.selectedSpecificationEventEmitter.subscribe({
      next: (data) => {
        this.specificationVersion = data.specificationVersion;
        this.updateAndNotify();
      }
    });

    apiMessageService.putMessage.subscribe({ next: (message) => message.completed.subscribe(c => this.changeCompleted.next()) });
    apiMessageService.postMessage.subscribe({ next: (message) => message.completed.subscribe(c => this.changeCompleted.next()) });
    apiMessageService.deleteMessage.subscribe({ next: (message) => message.completed.subscribe(c => this.changeCompleted.next()) });

    this.changeCompleted.pipe(debounceTime(1600)).subscribe({
      next: (x) => {
        this.updateAndNotify();
      }
    });
  }

  private updateAndNotify() {
    if (this.specificationVersion == null || !this.specificationVersion.id || this.specificationVersion.id === 0) {
      console.log(`No specification version active`);
      this.issueChangedEventEmitter.next({ issues: new Array<ValidationIssue>(), warnings: 0, errors: 0 });
    } else {
      console.log(`Loading issues for specification version ${this.specificationVersion.id}`);
      this.validationStateEventEmitter.next(true);
      this.apiProxy.get<ValidationIssue[]>(environment.apiUrl + 'versions/' + this.specificationVersion.id + '/issues').subscribe({
        next: (validationIssues) => {
          this.afterValidatingFile(validationIssues);
          this.validationStateEventEmitter.next(false);
        }
      });
    }
  }

  private afterValidatingFile(validationIssues: ValidationIssue[]) {
    const issues = Array<ValidationIssue>();
    validationIssues.map(i => issues.push(i));
    const warnings = issues.filter(x => x.issue.severity === 0).length;
    const errors = issues.filter(x => x.issue.severity === 1).length;
    if (this.specificationVersion) {
      console.log(`Found ${issues.length} issues for specification version ${this.specificationVersion.id}, warnings:${warnings} errors:${errors}`);
    }
    this.issueChangedEventEmitter.next({ issues, warnings, errors });
  }

  public validateServerFile(id: number): Observable<ScommFileValidationResult> {
    return this.apiProxy.get<ScommFileValidationResult>(environment.apiUrl+ 'versions/'+ id + '/serverFileValidation');
  }
}

