import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subject, Subscription } from 'rxjs';

import { ApplicationService } from './app-services/application.service';
import { UserService } from './app-services/user.service';
import { AuthService } from './modules/authentication/services/auth.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter, take, takeUntil } from 'rxjs/operators';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';

import { User } from './modules/shared/model/user';
import { HttpClient } from '@angular/common/http';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('fadeout', [
      transition(':leave', animate('300ms ease-out', style({
        opacity: '0'
      })))])]
})

export class AppComponent implements OnInit, OnDestroy {

  showNavbar = true;
  title = 'app';
  loaded = false;
  showBrowserWarning = true;
  // user: User;
  subscriptions: Subscription[] = [];
  private readonly rolePriority: { [key: string]: number } = {
    basic: 1,
    extended: 2,
    complete: 3,
    advanced: 4,
    admin:5
  };

 
  private readonly _destroying$ = new Subject<void>();

  loginDisplay: boolean;
  profile : any;
  currentUser: User;
  private msalSubjectSubscription: Subscription | undefined;
  private inProgressSubscription: Subscription | undefined;
  public jwtToken: string = "";
  public currentUserRoles:any = [];
  username: string;
  userRole: string;
 
  constructor(private router: Router, private appService: ApplicationService,
    private readonly authMSALService: MsalService, private readonly msalBroadcastService: MsalBroadcastService,
    protected http: HttpClient,
    private authService: AuthService,
              iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, ) {
    //oauthService.initCodeFlow();
    this.loaded = true;

    this.registerCustomIcons(iconRegistry, sanitizer);
  }

  registerCustomIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'icon-dcode-subfunction',
      sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/icon-dcode-subfunction.svg'));
    iconRegistry.addSvgIcon(
      'icon-dcode-protocol-service',
      sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/icon-dcode-protocol-service.svg'));
    iconRegistry.addSvgIcon(
      'icon-dcode-diagnostic-service',
      sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/icon-dcode-diagnostic-service.svg'));
    iconRegistry.addSvgIcon(
      'icon-dcode-identifier-type',
      sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/icon-dcode-identifier-type.svg'));
    iconRegistry.addSvgIcon(
      'icon-dcode-identifier',
      sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/icon-dcode-identifier.svg'));
    iconRegistry.addSvgIcon(
      'alarm-fill',
      sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/alarm-fill.svg'))
      .addSvgIcon(
        'alarm',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/alarm.svg'))
      .addSvgIcon(
        'alert-circle-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/alert-circle-fill.svg'))
      .addSvgIcon(
        'alert-circle',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/alert-circle.svg'))
      .addSvgIcon(
        'alert-octagon-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/alert-octagon-fill.svg'))
      .addSvgIcon(
        'alert-octagon',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/alert-octagon.svg'))
      .addSvgIcon(
        'alert-square-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/alert-square-fill.svg'))
      .addSvgIcon(
        'alert-square',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/alert-square.svg'))
      .addSvgIcon(
        'alert-triangle-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/alert-triangle-fill.svg'))
      .addSvgIcon(
        'alert-triangle',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/alert-triangle.svg'))
      .addSvgIcon(
        'archive-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/archive-fill.svg'))
      .addSvgIcon(
        'archive',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/archive.svg'))
      .addSvgIcon(
        'arrow-bar-bottom',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-bar-bottom.svg'))
      .addSvgIcon(
        'arrow-bar-left',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-bar-left.svg'))
      .addSvgIcon(
        'arrow-bar-right',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-bar-right.svg'))
      .addSvgIcon(
        'arrow-bar-up',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-bar-up.svg'))
      .addSvgIcon(
        'arrow-clockwise',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-clockwise.svg'))
      .addSvgIcon(
        'arrow-counterclockwise',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-counterclockwise.svg'))
      .addSvgIcon(
        'arrow-down-left',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-down-left.svg'))
      .addSvgIcon(
        'arrow-down-right',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-down-right.svg'))
      .addSvgIcon(
        'arrow-down-short',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-down-short.svg'))
      .addSvgIcon(
        'arrow-down',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-down.svg'))
      .addSvgIcon(
        'arrow-left-right',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-left-right.svg'))
      .addSvgIcon(
        'arrow-left-short',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-left-short.svg'))
      .addSvgIcon(
        'arrow-left',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-left.svg'))
      .addSvgIcon(
        'arrow-repeat',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-repeat.svg'))
      .addSvgIcon(
        'arrow-right-short',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-right-short.svg'))
      .addSvgIcon(
        'arrow-right',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-right.svg'))
      .addSvgIcon(
        'arrow-up-down',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-up-down.svg'))
      .addSvgIcon(
        'arrow-up-left',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-up-left.svg'))
      .addSvgIcon(
        'arrow-up-right',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-up-right.svg'))
      .addSvgIcon(
        'arrow-up-short',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-up-short.svg'))
      .addSvgIcon(
        'arrow-up',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrow-up.svg'))
      .addSvgIcon(
        'arrows-angle-contract',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrows-angle-contract.svg'))
      .addSvgIcon(
        'arrows-angle-expand',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrows-angle-expand.svg'))
      .addSvgIcon(
        'arrows-collapse',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrows-collapse.svg'))
      .addSvgIcon(
        'arrows-expand',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrows-expand.svg'))
      .addSvgIcon(
        'arrows-fullscreen',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/arrows-fullscreen.svg'))
      .addSvgIcon(
        'at',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/at.svg'))
      .addSvgIcon(
        'award',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/award.svg'))
      .addSvgIcon(
        'backspace-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/backspace-fill.svg'))
      .addSvgIcon(
        'backspace-reverse-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/backspace-reverse-fill.svg'))
      .addSvgIcon(
        'backspace-reverse',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/backspace-reverse.svg'))
      .addSvgIcon(
        'backspace',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/backspace.svg'))
      .addSvgIcon(
        'bar-chart-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/bar-chart-fill.svg'))
      .addSvgIcon(
        'bar-chart',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/bar-chart.svg'))
      .addSvgIcon(
        'battery-charging',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/battery-charging.svg'))
      .addSvgIcon(
        'battery-full',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/battery-full.svg'))
      .addSvgIcon(
        'battery',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/battery.svg'))
      .addSvgIcon(
        'bell-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/bell-fill.svg'))
      .addSvgIcon(
        'bell',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/bell.svg'))
      .addSvgIcon(
        'blockquote-left',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/blockquote-left.svg'))
      .addSvgIcon(
        'blockquote-right',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/blockquote-right.svg'))
      .addSvgIcon(
        'book-half-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/book-half-fill.svg'))
      .addSvgIcon(
        'book',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/book.svg'))
      .addSvgIcon(
        'bookmark-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/bookmark-fill.svg'))
      .addSvgIcon(
        'bookmark',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/bookmark.svg'))
      .addSvgIcon(
        'bootstrap-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/bootstrap-fill.svg'))
      .addSvgIcon(
        'bootstrap-reboot',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/bootstrap-reboot.svg'))
      .addSvgIcon(
        'bootstrap',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/bootstrap.svg'))
      .addSvgIcon(
        'box-arrow-bottom-left',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/box-arrow-bottom-left.svg'))
      .addSvgIcon(
        'box-arrow-bottom-right',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/box-arrow-bottom-right.svg'))
      .addSvgIcon(
        'box-arrow-down',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/box-arrow-down.svg'))
      .addSvgIcon(
        'box-arrow-left',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/box-arrow-left.svg'))
      .addSvgIcon(
        'box-arrow-right',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/box-arrow-right.svg'))
      .addSvgIcon(
        'box-arrow-up-left',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/box-arrow-up-left.svg'))
      .addSvgIcon(
        'box-arrow-up-right',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/box-arrow-up-right.svg'))
      .addSvgIcon(
        'box-arrow-up',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/box-arrow-up.svg'))
      .addSvgIcon(
        'braces',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/braces.svg'))
      .addSvgIcon(
        'brightness-fill-high',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/brightness-fill-high.svg'))
      .addSvgIcon(
        'brightness-fill-low',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/brightness-fill-low.svg'))
      .addSvgIcon(
        'brightness-high',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/brightness-high.svg'))
      .addSvgIcon(
        'brightness-low',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/brightness-low.svg'))
      .addSvgIcon(
        'brush',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/brush.svg'))
      .addSvgIcon(
        'bucket-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/bucket-fill.svg'))
      .addSvgIcon(
        'bucket',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/bucket.svg'))
      .addSvgIcon(
        'building',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/building.svg'))
      .addSvgIcon(
        'bullseye',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/bullseye.svg'))
      .addSvgIcon(
        'calendar-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/calendar-fill.svg'))
      .addSvgIcon(
        'calendar',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/calendar.svg'))
      .addSvgIcon(
        'camera-video-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/camera-video-fill.svg'))
      .addSvgIcon(
        'camera-video',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/camera-video.svg'))
      .addSvgIcon(
        'camera',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/camera.svg'))
      .addSvgIcon(
        'capslock-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/capslock-fill.svg'))
      .addSvgIcon(
        'capslock',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/capslock.svg'))
      .addSvgIcon(
        'chat-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/chat-fill.svg'))
      .addSvgIcon(
        'chat',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/chat.svg'))
      .addSvgIcon(
        'check-box',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/check-box.svg'))
      .addSvgIcon(
        'check-circle',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/check-circle.svg'))
      .addSvgIcon(
        'cloud-upload',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/cloud-upload.svg'))
      .addSvgIcon(
        'cloud-download',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/cloud-download.svg'))
      .addSvgIcon(
        'cloud',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/cloud.svg'))
      .addSvgIcon(
        'cloud-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/cloud-fill.svg'))
      .addSvgIcon(
        'document-diff',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/document-diff.svg'))
      .addSvgIcon(
        'eject-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/eject-fill.svg'))
      .addSvgIcon(
        'gear-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/gear-fill.svg'))
      .addSvgIcon(
        'gear',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/gear.svg'))
      .addSvgIcon(
        'person-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/person-fill.svg'))
      .addSvgIcon(
        'pencil',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/pencil.svg'))
      .addSvgIcon(
        'play-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/play-fill.svg'))
      .addSvgIcon(
        'folder',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/folder.svg'))
      .addSvgIcon(
        'document',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/document.svg'))
      .addSvgIcon(
        'documents',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/documents.svg'))
      .addSvgIcon(
        'document-text',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/document-text.svg'))
      .addSvgIcon(
        'display',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/display.svg'))
      .addSvgIcon(
        'display-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/display-fill.svg'))
      .addSvgIcon(
        'info-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/info-fill.svg'))
      .addSvgIcon(
        'info',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/info.svg'))
      .addSvgIcon(
        'inboxes',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/inboxes.svg'))
      .addSvgIcon(
        'inbox',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/inbox.svg'))
      .addSvgIcon(
        'skip-start-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/skip-start-fill.svg'))
      .addSvgIcon(
        'skip-backward-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/skip-backward-fill.svg'))
      .addSvgIcon(
        'terminal',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/terminal.svg'))
      .addSvgIcon(
        'toggle-off',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/toggle-off.svg'))
      .addSvgIcon(
        'toggle-on',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/toggle-on.svg'))
      .addSvgIcon(
        'trash',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/trash.svg'))
      .addSvgIcon(
        'trash-fill',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/trash-fill.svg'))
      .addSvgIcon(
        'x-circle',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/x-circle.svg'))
      .addSvgIcon(
        'x-close',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/x.svg'))
      .addSvgIcon(
        'plus',
        sanitizer.bypassSecurityTrustResourceUrl('../font/bootstrapIcons/plus.svg'));
  }

  ngOnInit(): void {
    this.msalSubjectSubscription = this.msalBroadcastService.msalSubject$
    .pipe(
      filter(
        (msg: EventMessage) =>
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ),
      take(1),
    )
    .subscribe((result:any) => {   
      this.jwtToken = result.payload.accessToken;
      this.jwtToken != "" || this.jwtToken!= null ? localStorage.setItem("token",this.jwtToken): localStorage.setItem("token","null") ;    
      this.authService.setUser(result.payload.idTokenClaims);
      this.username = result.payload.idTokenClaims.given_name + " " + result.payload.idTokenClaims.family_name;
      this.userRole =  this.getUserRole( result.payload.idTokenClaims.roles);
      this.userRole == null ? localStorage.setItem("currentUserRole","null") : localStorage.setItem("currentUserRole",this.userRole)  ;   
      if(this.userRole == null) this.router.navigate(['/authorization-failed']);
      if (this.authMSALService.instance.getAllAccounts().length === 0) {
        window.location.pathname = '/';
      } else {
        this.setLoginDisplay();
      }
    });

  this.inProgressSubscription = this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$),
    )
    .subscribe(() => {
      this.authMSALService;
      this.setLoginDisplay();
    });

    this.subscriptions.push(this.appService.requestFullScreen.subscribe(arg => {
      this.showNavbar = false;
    }));

    this.subscriptions.push(this.appService.requestNormalWindowMode.subscribe(arg => {
      this.showNavbar = true;
    }));

    // this.userService.getCurrentUser().subscribe(user => {
    //   this.user = user;
    // });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authMSALService.instance.getAllAccounts().length > 0;
    }

   public getUserRole(roles: string[]): string {
    this.currentUserRoles=roles;
     return this.getHighestRole(this.currentUserRoles)
   }
 
   public getHighestRole(roles: string[]): string {
     const roleMappings = {
       'admin': 'Admin',
       'advanced': 'Advanced',
       'complete': 'System Owner',
       'extended': 'Collaborator',
       'basic': 'Reader',

     };
   
     if (!roles || roles.length === 0) {
       return null;
     }
   
     let highestRole = null;
     for (const role of roles) {
        if (!highestRole || this.rolePriority[role.trim().trim().split('-').pop()] > this.rolePriority[highestRole.toLowerCase()]) {
          highestRole = role.trim().trim().split('-').pop();
      }
     }

     return roleMappings[highestRole];
   }


 
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
    this.msalSubjectSubscription.unsubscribe();
    this.inProgressSubscription.unsubscribe();
  }
  closeBrowserWarning(): void {
    this.showBrowserWarning = false;
  }
}
