import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryItem } from 'app/app-model/category-item';
import { SpecificationService } from 'app/app-services/specification-service';
import { Category } from 'app/specification-structure/category-items/category.enum';

import { DataCategoriesService } from './data-categories-service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(
    private router: Router,
    private specificationService: SpecificationService,
    private categoryService: DataCategoriesService
  ) { }

  get specificationId(): number {
    return this.specificationService.currentSpecification.id;
  }

  get specificationVersionId(): number {
    return this.specificationService.currentSpecificationVersion.id;
  }

  getCategoryName(category: Category) {
    switch (category) {
      case Category.EcuResets:
        return 'ecu-resets';
      case Category.Fingerprints:
        return 'fingerprints';
      case Category.Identification:
        return 'identifications';
      case Category.Ios:
        return 'ios';
      case Category.Iolists:
        return 'iolists';
      case Category.OperationalData:
        return 'operational-datas';
      case Category.Servers:
        return 'servers';
      case Category.Services:
        return 'services';
      case Category.Sessions:
        return 'sessions';
      case Category.Values:
        return 'values';
      case Category.LegacyFiles:
        return 'files';
      case Category.SecurityAccess:
        return 'security-access';
      case Category.FreezeFrames:
        return 'freezeframes';
      case Category.IvdOverrides:
        return 'ivd-overrides';
      case Category.VsocMonitoring:
        return 'vsoc';
    }
  }

  navigateToDiagnosticService(diagnosticServiceName: string) {
    this.navigateToCategory(Category.Services, diagnosticServiceName);
  }

  navigateToEcuIdentifier(ecuIdentifierName?: string) {
    this.navigateToCategory(Category.Identification, ecuIdentifierName);
  }

  navigateToCategory(category: Category, itemName?: string) {
    if (itemName) {
      this.router.navigate(
        [
          '/specification',
          this.specificationId,
          this.specificationVersionId,
          this.getCategoryName(category)
        ],
        { queryParams: { itemId: itemName } }
      );
    } else {
      this.router.navigate([
        '/specification',
        this.specificationId,
        this.specificationVersionId,
        this.getCategoryName(category)
      ]);
    }
  }

  navigateToCategoryItemById(category: Category, itemId?: string | number) {
    if (itemId) {
      this.router.navigate(
        [
          '/specification',
          this.specificationId,
          this.specificationVersionId,
          this.getCategoryName(category)
        ],
        { queryParams: { itemId: itemId } }
      );
    } else {
      this.router.navigate([
        '/specification',
        this.specificationId,
        this.specificationVersionId,
        this.getCategoryName(category)
      ]);
    }
  }

  navigateToCategoryItem(category: Category, categoryItem: CategoryItem) {
    const categoryItemNavigationId = this.getCategoryItemNavigationIdentifier(category, categoryItem);

    if (!categoryItemNavigationId) {
      this.navigateToCategory(category);
    }

    if (categoryItemNavigationId) {
      setTimeout(() => {
        this.navigateToCategoryItemById(category, categoryItemNavigationId);
      }, 10);
    } else {
      this.router.navigate([
        '/specification',
        this.specificationId,
        this.specificationVersionId,
        this.getCategoryName(category)
      ]);
    }
  }

  navigateToDiagnosticServiceParam(
    viewId: number,
    itemId: string,
    parameterId: number,
    subView: number
  ) {
    this.router.navigate(
      [
        '/specification',
        this.specificationId,
        this.specificationVersionId,
        'services'
      ],
      {
        queryParams: {
          viewId,
          itemId,
          parameterId,
          subView
        }
      }
    );
  }

  navigateToSpecificationHome() {
    this.router.navigate([
      '/specification',
      this.specificationId,
      this.specificationVersionId
    ]);
  }

  navigateToFirstAvailableItem(category: Category) {
    if (!this.categoryService.getCategory(category).hasItems()) {
      return;
    }

    const idOfFirstItemInCategory = this.getCategoryItemNavigationIdentifier(category, this.categoryService.getCategory(category).items[0]);
    this.navigateToCategory(category);

    setTimeout(() => {
      this.navigateToCategoryItemById(category, idOfFirstItemInCategory);
    }, 200);
  }

  getCategoryItemNavigationIdentifier(category: Category, categoryItem: CategoryItem): string {
    if (!categoryItem) {
      return '';
    }

    switch (category) {
      case Category.OperationalData:
        return categoryItem.resourceId;
      case Category.Servers:
        return `${categoryItem.id}`;
      default:
        return categoryItem.name;
    }
  }
}
