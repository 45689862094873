import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Specification } from 'app/app-model/specification';
import { SpecificationVersion } from 'app/app-model/specification-version';
import { ApplicationService } from 'app/app-services/application.service';
import { SpecificationVersionHubService } from 'app/app-services/hubs/specification-version-hub.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-specification-infobar',
  templateUrl: './specification-infobar.component.html',
  styleUrls: ['./specification-infobar.component.css']
})

export class SpecificationInfobarComponent implements OnInit, OnDestroy {
  specificationVersion: SpecificationVersion;
  subscriptions: Subscription[] = [];
  private _specification: Specification;

  constructor(
    private specificationService: SpecificationService,
    private appService: ApplicationService,
    public router: Router,
    private specificationHubService: SpecificationVersionHubService,
    private statusService: SpecificationStatusService
  ) { }

  ngOnInit() {
    this.subscriptions = [];

    this.subscriptions.push(this.specificationService.selectedSpecificationEventEmitter.subscribe(specification => {
      this.specification = specification.specification;
      this.specificationVersion = specification.specificationVersion;
    }));

    this.specificationHubService.activeUsersEventEmitter.subscribe(users => {
      console.log('from hub service: ' + JSON.stringify(users));
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  get specification(): Specification {
    return this._specification;
  }

  set specification(specification: Specification) {
    this._specification = specification;
  }

  get isCurrentSpecificationReleased(): boolean {
    return this.statusService.isInRelease(this.specificationService.currentSpecificationVersion);
  }

  clearCurrentSpecification() {
    this.specificationService.setCurrentSpecification(undefined, undefined);
    this.router.navigate(['/']);
  }

  get getNumberOfActiveUsers(): number {
    return this.specificationHubService.numberOfActiveUsers;
  }

  get getNameOfUsers(): string {
    return this.specificationHubService.activeUsersAsString;
  }

}
