import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { CategoryIdType, FreezeFrameSource, ImportSourceType } from 'app/app-model/enums';
import { FreezeFramesModel } from 'app/app-model/freeze-frames/freeze-frames.model';
import { FreezeFramesCategoryItem } from 'app/data-categories/harmonized-freeze-frames/freeze-frames-category-item';
import { ApiProxyService } from 'app/modules/shared/services/api-proxy.service';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CategoryServiceBase } from './category-base.service';
import { DiagnosticServiceProvider } from './diagnostic.service.service';
import { SpecificationService } from './specification-service';
import { PackageImportReport } from 'app/app-model/package-import-report.model';

@Injectable({
  providedIn: 'root'
})
export class FreezeframesService extends CategoryServiceBase<FreezeFramesModel, FreezeFramesCategoryItem> {
  constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
    private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected apiProxy: ApiProxyService) {
    super(apiProxy);
    this.apiCategoryName = CategoryIdType[CategoryIdType.freezeframes];
  }

  getCategoryItemModel(specificationVersionId: number, name: string): Observable<FreezeFramesCategoryItem> {
    return this.apiProxy.get<FreezeFramesModel>(`${environment.apiUrl}versions/${specificationVersionId}/freezeframes/${name}/`).pipe(map(item => {
      const freezeFrameCategoryItem = new FreezeFramesCategoryItem(this.specificationService, this.locationService, this.router, this.activatedRoute);
      freezeFrameCategoryItem.freezeFrameService = this;
      freezeFrameCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      freezeFrameCategoryItem.isPreview = false;
      freezeFrameCategoryItem.model = item;

      this.items.push(freezeFrameCategoryItem);
      return freezeFrameCategoryItem;
    }));
  }

  getItemsPreview(specificationVersionId: number): Observable<FreezeFramesCategoryItem[]> {
    this.isPending = true;
    if (this.hasCachedItems) {
      this.isPending = false;
      return of(this.items);
    } else {
      return this.apiProxy.get<FreezeFramesModel[]>(`${environment.apiUrl}versions/${specificationVersionId}/freezeframes`).pipe(map(freezeframes => {
        this.items = new Array<FreezeFramesCategoryItem>();
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < freezeframes.length; i++) {
          const freezeFrame = new FreezeFramesCategoryItem(this.specificationService, this.locationService, this.router, this.activatedRoute);
          freezeFrame.freezeFrameService = this;
          freezeFrame.diagnosticServiceProvider = this.diagnosticServiceProvider;
          freezeFrame.model = freezeframes[i];
          this.items.push(freezeFrame);
        }

        this.hasCachedItems = true;
        this.isPending = false;
        this.getItemsPreviewDone.next(this.isPending);

        return this.items;
      }));
    }
  }

  getCompleteItems(specificationVersionId: number): Observable<FreezeFramesCategoryItem[]> {
    throw new Error("Method not implemented.");
  }

  createItem(specificationVersionId: number, itemName?: string): Observable<FreezeFramesCategoryItem> {
    let itemToCreate = new FreezeFramesModel();
    itemToCreate.freezeFrameSource = FreezeFrameSource.Internal;
    itemToCreate.name = itemName;
    itemToCreate.freezeFrameSequence = new ServiceExecutionSequenceModel();

    return this.apiProxy.post<FreezeFramesModel>(`${environment.apiUrl}versions/${specificationVersionId}/freezeframes/`, itemToCreate).pipe(map(item => {
      const freezeFramesCategoryItem = new FreezeFramesCategoryItem(this.specificationService, this.locationService, this.router, this.activatedRoute);
      freezeFramesCategoryItem.freezeFrameService = this;
      freezeFramesCategoryItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
      freezeFramesCategoryItem.isPreview = false;
      freezeFramesCategoryItem.model = item;

      this.items.push(freezeFramesCategoryItem);

      return freezeFramesCategoryItem;
    }));
  }

  public deleteItem(specificationVersionId: number, itemName?: string): Observable<any> {
    return this.apiProxy.delete(`${environment.apiUrl}versions/${specificationVersionId}/freezeframes/${itemName}`)
      .pipe(tap(deletedItem => this.removeCachedItem(deletedItem.name)));
  }

  public updateItem(specificationVersionId: number, freezeFrameModel: FreezeFramesModel, itemName?: string): Observable<FreezeFramesModel> {
    return this.apiProxy.put(`${environment.apiUrl}versions/${specificationVersionId}/freezeframes/${itemName}/`, freezeFrameModel);
  }

  public getHarmonizedFreezeFrames(versionId: number): Observable<FreezeFramesModel[]> {
    return this.apiProxy.get<FreezeFramesModel[]>(environment.apiUrl + 'versions/' + versionId + '/freezeframes/')
  }

  public importFreezeFramesFromPackage(specificationVersionId: number, file: string, importSourceType: ImportSourceType): Observable<PackageImportReport<FreezeFramesModel>> {
    return this.apiProxy.postString<PackageImportReport<FreezeFramesModel>>(`${environment.apiUrl}versions/importfreezeframes/${specificationVersionId}`, file);
  }
}
