import { Component, Input } from '@angular/core';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';
import { IdentificationExpressionService } from 'app/app-services/identification-expression.service';

@Component({
  selector: 'app-can-address-comparison',
  templateUrl: './can-address-comparison.component.html',
  styleUrls: ['./can-address-comparison.component.css']
})
export class CanAddressComparisonComponent {

  @Input()
  isReadOnly: boolean;

  @Input()
  reorderEnabled = true;

  @Input()
  copyEnabled = true;

  @Input()
  isAllowed :boolean;

  @Input()
  expressionModel: ServeridentificationExpressionModel;

  canAddress: string;
  hexCount = 1;

  constructor(private identificationExpressionService: IdentificationExpressionService) { }


  get hexValSample(): string {
    if (this.hexCount === 1) {
      return 'e.g. 0x33';
    } else {
      return 'e.g. 0x0033';
    }
  }

  notifyServerIdentificationChanged(hexCode: number) {
    this.expressionModel.compareValue.data = hexCode.toString(16);
    this.identificationExpressionService.modified.next(this.expressionModel);
  }

  onHexCodeChanged(hexCode: number) {
    // If we are resetting the hex code to an old value remove 1mil from the number
    if (hexCode >= 1000000) {
      hexCode -= 1000000;
    }
    this.notifyServerIdentificationChanged(hexCode);
  }
}
