/* eslint-disable @typescript-eslint/naming-convention */
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryItem } from 'app/app-model/category-item';
import { ParameterDataModel } from 'app/app-model/diagnostic-service/parameter-data.model';
import { PathStep, StepType } from 'app/app-model/diagnostic-service/path-step.model';
import {
  ServiceExecutionResponseValue,
} from 'app/app-model/diagnostic-service/sequence-execution-response-parameter-data.model';
import { EcuIdentifierModel } from 'app/app-model/ecu-identification/ecu-identifier.model';
import { CategoryName, DiagnosticProtocolType } from 'app/app-model/enums';
import { EcuIdentifierService } from 'app/app-services/ecu-identification.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { BaseProperty, PropertyType } from 'app/modules/shared/model/properties/base-property';
import { OutputProperty } from 'app/modules/shared/model/properties/output-property';
import { ServiceExecutionDirective } from 'app/modules/shared/model/service-execution/service-execution';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { PropertyParameterDirective } from 'app/modules/shared/model/service/parameters/property.parameter';
import { BehaviorSubject, Subscription } from 'rxjs';

export class EcuIdentifierCategoryItem extends CategoryItem {

  ecuIdentifierService: EcuIdentifierService;
  propertyType: PropertyType = PropertyType.Source;
  properties: Array<BaseProperty> = new Array<BaseProperty>();
  isSyncingFromMaster = false;
  isStoredInSource = false;
  generatingProperties = false;
  syncDone: BehaviorSubject<any> = new BehaviorSubject(false);
  specificationService: SpecificationService;
  serviceExecutionModifiedSubscription: Subscription;
  oldName = '';

  private KwpByteCount = 2;
  private UdsByteCount = 4;
  private ecuIdHexRegex = new RegExp('0[xX]{1}[\\da-fA-F]{4}');
  private _model: EcuIdentifierModel;
  private _serviceExecutionSequence: ServiceExecutionSequence;
  private _ecuIdentifierHexCode: string;

  constructor(
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
    this._serviceExecutionSequence = new ServiceExecutionSequence();
    this.categoryType = CategoryName.EcuIdentifier;
  }

  public get model(): EcuIdentifierModel {
    return this._model;
  }
  public set model(v: EcuIdentifierModel) {
    this._model = v;
    this.ecuIdentifierHexCode = '0x' + v.code.toString(16).toUpperCase();
    this.syncFromModel();
  }


  public get serviceExecutionSequence(): ServiceExecutionSequence {
    return this._serviceExecutionSequence;
  }

  public set serviceExecutionSequence(v: ServiceExecutionSequence) {
    this._serviceExecutionSequence = v;
  }

  public get ecuIdentifierHexCode(): string {
    return this._ecuIdentifierHexCode;
  }
  public set ecuIdentifierHexCode(v: string) {
    this._ecuIdentifierHexCode = v;
    if (this.isValidEcuIdentifierHexCode) {
      const value = parseInt(v, 16);
      if (value !== this.model.code) {
        this.model.code = value;
        this.notifyEcuIdentifierChanged();
      }
    }
  }

  get hexCount(): number {
    if (this.specificationService && this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
      return this.KwpByteCount;
    } else {
      return this.UdsByteCount;
    }
  }

  get ecuIdentifierHexCodePadded(): string {
    return `0x${this.ecuIdentifierHexCode.replace('0x', '').padStart(this.hexCount, '0').toUpperCase()}`;
  }

  get shortName(): string {
    return this.model.name;
  }

  public get isValidEcuIdentifierHexCode(): boolean {
    this.getEcuIdHexRegex();
    return this.ecuIdHexRegex.test(this.ecuIdentifierHexCode);
  }

  public getParameterAssignedToProperty(propName: string): PropertyParameterDirective {
    if (!propName) {
      return undefined;
    }
    const matchingProp = this.properties.find(prop => prop.name === propName);

    if (matchingProp) {
      return matchingProp.selectedPropertyParameter;
    } else {
      return undefined;
    }
  }

  public onSelected() {
    this.syncFromModel();
  }

  public setName(name: string) {
    this.oldName = this.model.name;
    this.name = name;
    this.model.name = name;
    this.notifyEcuIdentifierChanged();
  }

  public notifyEcuIdentifierChanged() {
    if (!this.isSyncingFromMaster) {
      console.log('Ecu Identifier ' + this.name + ' modified...Saving changes');

      this.ecuIdentifierService.updateItem(this.specificationService.currentSpecificationVersion.id, this.model, this.oldName)
        .subscribe({
          next: (updatedOd) => {
            this.model = updatedOd;

            const newUrl = this.router.createUrlTree([], {
              relativeTo: this.activatedRoute,
              queryParams: { itemId: this.model.name }
            }).toString();
            this.locationService.go(newUrl);

            console.log('Ecu Identifier' + this.name + ' successfully updated');
          }
        });
      this.syncFromModel();
    }
  }

  public getEcuIdHexRegex() {
    if (this.specificationService) {
      if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Kwp2000) {
        this.ecuIdHexRegex = new RegExp('0[xX]{1}[\\da-fA-F]{2}$');
      } else if (this.specificationService.currentSpecification.diagnosticProtocol === DiagnosticProtocolType.Uds) {
        this.ecuIdHexRegex = new RegExp('0[xX]{1}[\\da-fA-F]{4}$');
      } else {
        this.ecuIdHexRegex = new RegExp('0[xX]{1}[\\da-fA-F]{2,4}$');

      }
    }
  }

  public CreatePropertyFromResponseValue(responseValue: ServiceExecutionResponseValue) {
    const serviceExecutions = this.serviceExecutionSequence.serviceExecutions;
    const serviceExecutiontoGenerateFrom = serviceExecutions.length === 1 ?
      serviceExecutions[0] : serviceExecutions[serviceExecutions.length - 1];
    const responsePropertyParams = serviceExecutiontoGenerateFrom.availableResponsePropertyParameters;

    const outputProperty = new OutputProperty();
    outputProperty.notifyIfPropertyChanges = false;
    outputProperty.name = responseValue.target.variable;

    if (!this.isPreview) {
      outputProperty.syncPropertyDone.subscribe({
        next: (updatedProperty) => {
          outputProperty.notifyIfPropertyChanges = true;
        }
      });
      outputProperty.setServiceExecutionSequenceModelSilent(this.serviceExecutionSequence);
      if (responsePropertyParams) {
        const propertyParameter = responsePropertyParams.find(x => x.parameter.name === outputProperty.name);
        if (propertyParameter) {
          outputProperty.setSelectedPropertyParameterSilent(propertyParameter);
        }
      }
    }

    this.properties.push(outputProperty);
  }

  public addResponseValue(
    outputParameter: ParameterDataModel,
    serviceExecutionToModify: ServiceExecutionDirective): ServiceExecutionResponseValue {
    const responseValues = serviceExecutionToModify.model.responseParameters ? serviceExecutionToModify.model.responseParameters : [];

    const pathStep = new PathStep();
    pathStep.step = outputParameter.name;
    pathStep.stepType = StepType.StepByName;

    const responseVal = new ServiceExecutionResponseValue();
    responseVal.path = [pathStep];
    responseVal.target.variable = outputParameter.name;
    responseValues.push(responseVal);

    return responseVal;
  }

  public syncFromModel() {
    this.isSyncingFromMaster = true;
    this.name = this.model.name;
    this.displayName = `(${this.ecuIdentifierHexCode}) ${this.name}`;
    this.oldName = this.name;
    this.key = this.model.name;

    if (this.model.readSequence) {
      this.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
      this.serviceExecutionSequence.maxAllowedServiceExecutions = 1;

      if (!this.isPreview) {
        this.subscribeToServiceExecutionModifications();
        this.serviceExecutionSequence.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
        this.serviceExecutionSequence.setModel(this.model.readSequence).subscribe(_ => {
          this.generatePropertiesFromResponseValues();
          this.isSyncingFromMaster = false;
          this.syncDone.next(true);
          this.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
        });
      }
    } else {
      this.isSyncingFromMaster = false;
    }
  }

  private subscribeToServiceExecutionModifications() {
    if (this.serviceExecutionModifiedSubscription === undefined) {
      this.serviceExecutionModifiedSubscription = this._serviceExecutionSequence.modified.subscribe(_ => {
        this.generatePropertiesFromResponseValues();
        this.notifyEcuIdentifierChanged();
      });
    }
  }

  private generatePropertiesFromResponseValues() {
    this.properties = [];

    if (this.serviceExecutionSequence.serviceExecutions.length <= 0) {
      return;
    }

    const serviceExecutions = this.serviceExecutionSequence.serviceExecutions;
    const serviceExecutiontoGenerateFrom = serviceExecutions.length === 1 ?
      serviceExecutions[0] : serviceExecutions[serviceExecutions.length - 1];
    const responseValuesFromModel = serviceExecutiontoGenerateFrom.model.responseParameters;

    responseValuesFromModel.forEach(responseVal => {
      if (!responseVal.target.variable) {
        responseVal.target.variable = responseVal.path[0].step;
      }

      this.CreatePropertyFromResponseValue(responseVal);
    });
  }
}
