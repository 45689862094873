<div class="sdds-row node-description-wrapper">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 node-description">
        <div class="sdds-row">
            <div *ngIf="treeNode.isDiagnosticServiceTreeNode"
                class="sdds-col-max sdds-col-xxlg sdds-col-xlg sdds-col-lg sdds-col-md sdds-col-sm sdds-col-xs">
                <span (click)="treeNode.isExpanded = !treeNode.isExpanded; $event.stopPropagation();">
                    <ng-container *ngIf="treeNode.children">
                        <ng-container *ngIf="!treeNode.isExpanded">
                            <mat-icon fontSet="material-icons-outlined" class="md-18">navigate_next</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="treeNode.isExpanded">
                            <mat-icon fontSet="material-icons-outlined" class="md-18">keyboard_arrow_down</mat-icon>
                        </ng-container>
                    </ng-container>
                </span>

                <span *ngIf="treeNode.icon">
                    <mat-icon fontSet="material-icons-outlined" class="md-18 md-dark">{{treeNode.icon}}</mat-icon>
                </span>

                <span class="editable-textbox">
                    <app-editable-textbox [isEditable]="treeNode.isEditable" [text]="treeNode.name"
                        (textEditedEventEmitter)="treeNode.editingDone($event)" [onlyPencilClickable]="true"
                        [isAllowed]="canCreateDeleteSpecification()">
                    </app-editable-textbox>
                </span>
            </div>
            <div *ngIf="!treeNode.isDiagnosticServiceTreeNode"
                class="sdds-col-max sdds-col-xxlg sdds-col-xlg sdds-col-lg sdds-col-md sdds-col-sm sdds-col-xs version-node-status">
                <span (click)="treeNode.isExpanded = !treeNode.isExpanded; $event.stopPropagation();">
                    <ng-container *ngIf="treeNode.children">
                        <ng-container *ngIf="!treeNode.isExpanded">
                            <mat-icon fontSet="material-icons-outlined" class="md-18">navigate_next</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="treeNode.isExpanded">
                            <mat-icon fontSet="material-icons-outlined" class="md-18">keyboard_arrow_down</mat-icon>
                        </ng-container>
                    </ng-container>
                </span>

                <div class="flex">
                  <span *ngIf="treeNode.icon">
                    <mat-icon fontSet="material-icons-outlined" class="md-18 md-dark">{{treeNode.icon}}</mat-icon>
                  </span>

                  <span
                      *ngIf="treeNode.isSpecificationVersionTreeNode || treeNode.isSharedFileSpecificationVersionTreeNode">
                      <div style="height:16px;" class="sdds-divider-dark-vertical"></div>
                  </span>

                  <span class="editable-textbox">
                      <app-editable-textbox [isEditable]="treeNode.isEditable" [text]="treeNode.name"
                          (textEditedEventEmitter)="treeNode.editingDone($event)" [onlyPencilClickable]="true"
                          [isAllowed]="canCreateDeleteSpecification()">
                      </app-editable-textbox>
                  </span>

                  <span
                    *ngIf="treeNode.isSharedFileSpecificationVersionTreeNode">{{sharedFileVersionTreeNode.fileVersion.file.name}}</span>

                  <span *ngIf="treeNode.isSharedFileTreeNode && treeNode.specificationFile.alwaysInclude">Always
                      include</span>

                  <ng-container *ngIf="treeNode.isSpecificationVersionTreeNode">
                      <span appInWork *ngIf="treeNode.isInWorkStatus">In Work</span>
                      <span appReleased *ngIf="specificationVersionTreeNode.isInReleaseStatus">Released</span>
                      <span appLegacy *ngIf="specificationVersionTreeNode.isLegacyVersion">Legacy</span>
                      <span appIsOpen *ngIf="specificationVersionTreeNode.isOpen">Is Open</span>
                  </ng-container>
                </div>
            </div>

            <div *ngIf="treeNode.isSpecificationVersionTreeNode"
                class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 version-node-action">
                <span *ngIf="specificationVersionTreeNode.releaseAction && treeNode.isInWorkStatus">
                        <button
                        [disabled]="!specificationVersionTreeNode.releaseAction || releaseCheck || !canReleaseVersion()"
                        [ngClass]="{'loading':(specificationVersionTreeNode.loading || releaseCheck )}"
                        class="sdds-btn sdds-btn-secondary sdds-btn-sm"
                        (click)="release(); $event.stopPropagation();">Release</button>
                </span>

                <span *ngIf="!specificationVersionTreeNode.isOpen && specificationVersionTreeNode.openAction">
                    <button [disabled]="!specificationVersionTreeNode.canOpen"
                        class="sdds-btn sdds-btn-secondary sdds-btn-sm"
                        (click)="specificationVersionTreeNode.performOpenAction();">Open</button>
                </span>

                <span *ngIf="treeNode.canDelete()">
                    <a *ngIf="!canEditSpecificationVersion()" class="cursor-not-allowed">
                        <mat-icon fontSet="material-icons-outlined"
                            class="md-18 text-gray-400">delete_forever</mat-icon>
                    </a>
                    <a (click)="specificationVersionTreeNode.performDeleteAction(); $event.stopPropagation();"
                        *ngIf="canEditSpecificationVersion()">
                        <mat-icon fontSet="material-icons-outlined" class="md-18 text-red-600">delete_forever</mat-icon>
                    </a>
                </span>
            </div>

            <div *ngIf="copyServiceEnabled" class="float-right version-action">
                <button alt="Copy Diagnostic Service" (click)="treeNode.performCopyAction(); $event.stopPropagation();"
                    class="sdds-btn sdds-btn-ghost sdds-btn-sm" [disabled]="!canEditDiagnosticService()">
                    <mat-icon fontSet="material-icons-outlined" class="md-18">content_copy</mat-icon>
                </button>
            </div>

            <div *ngIf="treeNode.isStructureSpecificationTreeNode && treeNode.canDelete()"
                class="float-right version-action">
                <button (click)="viewDeleteSpecificationModal(structureSpecificationTreeNode)"
                    class="sdds-btn sdds-btn-ghost sdds-btn-sm" title="Delete the specification"
                    [disabled]="!canCreateDeleteSpecification()">
                    <mat-icon fontSet="material-icons-outlined" class="md-18">close</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
