import { environment } from 'environments/environment';

import { Injectable, NgZone } from '../../../../node_modules/@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject } from '../../../../node_modules/rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskStatusHub {

  zone: NgZone;
  connected = false;
  setTaskEvent: BehaviorSubject<{ taskStatus: string }> = new BehaviorSubject({ taskStatus: '' });
  taskCompleteEvent: BehaviorSubject<{ taskStatus: string }> = new BehaviorSubject({ taskStatus: '' });

  private _hubConnection: HubConnection;

  constructor() {
    this.zone = new NgZone({ enableLongStackTrace: false });
    this.setupConnection();
  }

  setupConnection(): Promise<void> {
    if (this.connected) {
      return new Promise<void>(() => { });
    }

    this._hubConnection = new HubConnectionBuilder().withUrl(environment.hubUrl + 'taskStatus', { transport: HttpTransportType.WebSockets }).build();
    this._hubConnection.on('SetTask', (taskStatus: string) => {
      this.setTaskEvent.next({ taskStatus });
    });
    this._hubConnection.on('TaskComplete', (taskStatus: string) => {
      this.taskCompleteEvent.next({ taskStatus });
    });

    this._hubConnection.onclose(() => {
      this.connected = false;
    });

    return this._hubConnection
      .start()
      .then(() => {
        this.connected = true;
      })
      .catch(error => console.log('Error with Specification Status Hub. Got error ' + error));
  }
}
