<div class="sdds-row mb-16">
    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
        <h1>Issues</h1>
        <div class="sdds-banner my-10" *ngIf="issues.length === 0">
            <div class="sdds-banner-body">
                <span class="alert-link">No issues</span>
            </div>
        </div>
        <table class="table" *ngIf="issues.length> 0">
            <thead>
                <tr>
                    <th></th>
                    <th>Message</th>
                    <th>Help</th>
                    <th>Link</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let validationIssue
                    of issues; let i= index" [ngClass]="{'warning':
                    validationIssue.issue.severity === 0, 'danger':
                    validationIssue.issue.severity === 1}">
                    <td class="text-center">
                        <span class="glyphicon" [ngClass]="{'glyphicon-warning-sign':
                            validationIssue.issue.severity === 0, 'glyphicon-exclamation-sign':
                            validationIssue.issue.severity === 1}">
                        </span>
                    </td>
                    <td>{{validationIssue.issue.message}}</td>
                    <td>{{validationIssue.issue.help}}</td>
                    <td (click)="navigateToContext(validationIssue)" class="cursor-pointer text-blue-700">{{contextItemName(validationIssue)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
