import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SharedFile } from 'app/app-model/legacy-file';
import { DialogBase } from 'app/dialogs/dialog-base';

import {
  SharedFilesAssignerComponent,
  SharedFilesFilterOptions,
} from '../shared-files-assigner/shared-files-assigner.component';

@Component({
  selector: 'app-select-shared-files',
  templateUrl: './select-shared-files.component.html',
  styleUrls: ['./select-shared-files.component.css']
})
export class SelectSharedFilesComponent extends DialogBase {

  @ViewChild('sharedFilesAssigner', { static: true }) sharedFilesAssigner: SharedFilesAssignerComponent;

  @Output()
  assignSharedFilesEvent: EventEmitter<SharedFile[]> = new EventEmitter();

  @Input()
  filterOptions: SharedFilesFilterOptions = SharedFilesFilterOptions.All;

  @Input()
  componentId = '#selectSharedFilesModal';

  loading: boolean;
  sharedFilesFilterOptions = SharedFilesFilterOptions;

  constructor() {
    super();
  }

  cancel() {
    this.sharedFilesAssigner.clearFilter();
  }

  close(): void {
  }

  get canApply(): boolean {
    return this.sharedFilesAssigner.hasFileSelected;
  }

  apply() {
    if (this.sharedFilesAssigner.hasFilesToApply()) {
      this.assignSharedFilesEvent.next(this.sharedFilesAssigner.getSelectedFilesFromTree());
      this.sharedFilesAssigner.clearSelectedFilesTree();
    }
  }

  updateSharedFilesAssigner() {
    this.sharedFilesAssigner.updateAvailableFiles();
  }
}
