import { CategoryItem } from 'app/app-model/category-item';
import { LegacyContentModel } from 'app/app-model/legacyContent.model';
import { SpecificationService } from 'app/app-services/specification-service';
import { LegacyContentService } from 'app/app-services/legacyContent.service';

export class LegacyContentCategoryItem extends CategoryItem {

  private _model: LegacyContentModel;

  public get model(): LegacyContentModel {
    return this._model;
  }
  public set model(v: LegacyContentModel) {
    this._model = v;
  }

  public get testStepsContent(): string {
    return atob(this.model.xmlContent);
  }
  public set testStepsContent(content: string) {
    this.model.xmlContent= btoa(content);
  }

  constructor(private specificationService: SpecificationService,
    private legacyContentService: LegacyContentService) {
    super();
  }

  notifyContentChanged() {
    this.legacyContentService.updateUnreferencedTestSteps(this.specificationService.currentSpecificationVersion.id, this.model)
      .subscribe();
  }
}
