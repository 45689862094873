import { FamilyGenerationPair } from '../family-generation-pair';
import {
    ServeridentificationExpressionModel,
    ServerIdentificationModel,
} from '../server-identification/server-identification.model';

export class IdentificationGroup {
    id: number;
    index: number;
    name: string;
    expression: ServeridentificationExpressionModel;
    formattedExpression: string;
    serverIdentifications: ServerIdentificationModel[];
    familyGenerationPairs: FamilyGenerationPair[];
    isDefaultGroup: boolean;
}