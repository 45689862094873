<div class="sdds-card" style="background: #f2f5f8; margin-bottom:15px;">
  <div class="sdds-card-body" style="padding: 10px;">
    <div style="float:right;">
      <app-expression-view-toolbar [expressionModel]="expressionModel" [isReadOnly]="isReadOnly"
        [copyEnabled]="copyEnabled" [reorderEnabled]="reorderEnabled"></app-expression-view-toolbar>
    </div>
    <div class="sdds-row">
      <div
        class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3">
        <span class="sdds-detail-04">Diagnostic Address On CAN</span>
      </div>
      <div
        class="sdds-col-max-2 sdds-col-xxlg-2 sdds-col-xlg-2 sdds-col-lg-2 sdds-col-md-2 sdds-col-sm-2 sdds-col-xs-2">
        <span class="sdds-headline-07">Equals to</span>
      </div>
      <div
        class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-5 sdds-col-sm-5 sdds-col-xs-5">
        <app-id-code-field [hexCount]="hexCount" [hexCode]="this.expressionModel.compareValue.data"
          [isEditingAllowed]="!isReadOnly" [isCanAddressVal]="true" (hexCodeChanged)="onHexCodeChanged($event)"
          [isAllowed]="isAllowed">
        </app-id-code-field>
      </div>
      <div
        class="sdds-col-max-2 sdds-col-xxlg-2 sdds-col-xlg-2 sdds-col-lg-2 sdds-col-md-2 sdds-col-sm-2 sdds-col-xs-2">
      </div>
    </div>
  </div>
</div>