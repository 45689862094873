import { Injectable } from '@angular/core';
import { ServeridentificationExpressionModel } from 'app/app-model/server-identification/server-identification.model';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class IdentificationExpressionItemService {
    onDelete: Subject<ServeridentificationExpressionModel> = new Subject();
    onCopy: Subject<ServeridentificationExpressionModel> = new Subject();
    onMoveUp: Subject<ServeridentificationExpressionModel> = new Subject();
    onMoveDown: Subject<ServeridentificationExpressionModel> = new Subject();
}