import { Injectable } from '@angular/core';
import {
  ServeridentificationExpressionModel,
  ServerIdentificationModel,
} from 'app/app-model/server-identification/server-identification.model';
import { CategoryServiceBase } from 'app/app-services/category-base.service';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import {
  ServerIdentificationCategoryItemComponent,
} from 'app/data-categories/server-identification/server-identification-category-item';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { SpecificationService } from './specification-service';

@Injectable({
  providedIn: 'root'
})
export class ServerIdentificationService extends CategoryServiceBase<ServerIdentificationModel, ServerIdentificationCategoryItemComponent> {

  constructor(private diagnosticServiceProvider: DiagnosticServiceProvider,
    private specificationService: SpecificationService,
    protected apiProxy: ApiProxyService) {
    super(apiProxy);
  }

  getItemsPreview(specificationVersionId: number): Observable<ServerIdentificationCategoryItemComponent[]> {
    return this.apiProxy.get<ServerIdentificationModel[]>(environment.apiUrl + 'servers/new/' + specificationVersionId).pipe(map((odvs) => {
      this.items = [];

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < odvs.length; i++) {
        const serverIdentificationItem = this.createCategoryItemFromModel(odvs[i]);
        serverIdentificationItem.isPreview = false;
        this.items.push(serverIdentificationItem);
      }

      return this.items;
    }));
  }

  getCategoryItemModel(specificationVersionId: number, name: string): Observable<ServerIdentificationCategoryItemComponent> {
    return this.apiProxy.get<ServerIdentificationModel>(environment.apiUrl + 'servers/' + name).pipe(map(item => {
      const serverIdentificationItem = this.createCategoryItemFromModel(item);
      serverIdentificationItem.isPreview = false;
      this.items.push(serverIdentificationItem);
      return serverIdentificationItem;
    }));
  }

  getCompleteItems(specificationVersionId: number): Observable<ServerIdentificationCategoryItemComponent[]> {
    throw new Error('Method not implemented.');
  }

  getItem(specificationVersionId: number, itemName: string): Observable<ServerIdentificationCategoryItemComponent> {
    const cachedItem = this.findCachedItem(itemName);

    if (cachedItem && !cachedItem.isPreview) {
      return of(cachedItem);
    } else {
      return this.apiProxy.get<ServerIdentificationModel>(environment.apiUrl + 'servers/' + itemName).pipe(map(item => {
        const returnItem = cachedItem ? cachedItem : this.createCategoryItemFromModel(item);

        returnItem.isPreview = false;
        returnItem.model = item;

        return returnItem;
      }));
    }
  }

  createItem(specificationVersionId: number, itemName?: string): Observable<ServerIdentificationCategoryItemComponent> {
    const itemToCreate = this.createEmptyExpression(specificationVersionId);

    return this.apiProxy.post<ServerIdentificationModel>(environment.apiUrl + 'servers/', itemToCreate).pipe(map(item => {
      const serverIdentificationItem = new ServerIdentificationCategoryItemComponent();
      serverIdentificationItem.serverIdentificationService = this;
      serverIdentificationItem.model = item;
      serverIdentificationItem.isPreview = false;
      serverIdentificationItem.isSyncingFromMaster = false;

      this.items.push(serverIdentificationItem);

      return serverIdentificationItem;
    }));
  }

  updateItem(specificationVersionId: number, itemModel: ServerIdentificationModel): Observable<ServerIdentificationModel> {
    return this.apiProxy.put<ServerIdentificationModel>(environment.apiUrl + 'servers/' + itemModel.id, itemModel);
  }

  deleteItem(itemid: number): Observable<any> {
    return this.apiProxy.delete<ServerIdentificationModel>(environment.apiUrl + 'servers/' + itemid)
      .pipe(tap(deletedItem => this.removeCachedItemById(itemid)));
  }

  findCachedItem(itemName: string): ServerIdentificationCategoryItemComponent {
    if (!this.items || this.items.length <= 0) {
      return null;
    }

    return this.items.find(item => item.name === itemName);
  }

  private createCategoryItemFromModel(model: ServerIdentificationModel): ServerIdentificationCategoryItemComponent {
    const serverIdentificationItem = new ServerIdentificationCategoryItemComponent();
    serverIdentificationItem.serverIdentificationService = this;
    serverIdentificationItem.diagnosticServiceProvider = this.diagnosticServiceProvider;
    serverIdentificationItem.model = model;
    serverIdentificationItem.id = model.id;
    return serverIdentificationItem;
  }

  private createEmptyExpression(specificationVersionId: number): ServerIdentificationModel {
    const itemToCreate = new ServerIdentificationModel();

    itemToCreate.diagnosticFamily = '';
    itemToCreate.generation = '';
    itemToCreate.specificationVersion = {
      id: specificationVersionId, name: '',
      specificationType: this.specificationService.currentSpecificationVersion.specificationType,
      specification: null, releaseStatus: 0, labels: null
    };
    itemToCreate.className = '';
    itemToCreate.expression = new ServeridentificationExpressionModel();

    return itemToCreate;
  }

  // getServersByVersionId(specificationVersionId: number): Observable<ServerIdentificationCategoryItemComponent> {
  //   const cachedItem = this.items.find(item => item.specificationVersionId === specificationVersionId);
  //   if (!cachedItem) {
  //     return this.apiProxy.get<ServerIdentificationCategoryItemComponent>(environment.apiUrl + 'servers/new/' + specificationVersionId)
  //       .pipe(tap(item => this.items.push(item)));
  //   }
  //   return of(cachedItem);
  // }
}
