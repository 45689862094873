import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogBase } from '../dialog-base';
import { ImportSourceType, PackageImportStatus, SpecificationType } from 'app/app-model/enums';
import { SpecificationVersion } from 'app/app-model/specification-version';
import { IoService } from '../../app-services/io.service';
import { PackageImportReport, PackageImportReportTableModel } from 'app/app-model/package-import-report.model';
import { IoModel } from 'app/app-model/io/io.model';

@Component({
  selector: 'app-import-category-item-package-dialog',
  templateUrl: './import-category-item-package-dialog.component.html',
  styleUrls: ['./import-category-item-package-dialog.component.css']
})
export class ImportCategoryItemPackageDialogComponent extends DialogBase {

  @Output()
  modalApply: EventEmitter<void> = new EventEmitter<void>();

  packageStatus = PackageImportStatus;
  private selectedFileAsBase64: string;
  private selectedFile: File;
  private isUploading: boolean;
  private packageImportReport: PackageImportReport<IoModel>;
  private mappedReportItems: PackageImportReportTableModel[];

  constructor(private ioService: IoService) {
    super();
  }

  @Input()
  specificationVersion: SpecificationVersion;

  get importPackageExtension(): string {
    if (this.specificationVersion) {
      return this.specificationVersion.specificationType === SpecificationType.OdxBased ? '.csv' : '.zip';
    }
    return '';
  }

  get importPackageType(): ImportSourceType {
    if (this.specificationVersion) {
      return this.specificationVersion.specificationType === SpecificationType.OdxBased ? ImportSourceType.Csv : ImportSourceType.Zip;
    }
  }

  get importFinishedSuccessfully() {
    return this.packageImportReport.packageImportStatus == PackageImportStatus.Success;
  }

  get importFinishedWithWarnings() {
    return this.packageImportReport.packageImportStatus == PackageImportStatus.Warning;
  }

  get importFinishedWithErrors() {
    return this.packageImportReport.packageImportStatus == PackageImportStatus.Error;
  }

  setSelectedFile(event: any) {
    this.selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(this.selectedFile);
    fileReader.onload = () => {
      this.selectedFileAsBase64 = btoa(<string>fileReader.result);
    };
  }

  startUpload(): void {
    this.isUploading = true;

    this.ioService.addIosFromFile(this.specificationVersion.id, this.selectedFileAsBase64, this.importPackageType)
      .subscribe(importReport => {
        this.isUploading = false;
        this.packageImportReport = importReport;
        this.selectedFile = undefined;
        this.createTable();
      });
  }

  createTable(): void {
    if (!this.packageImportReport) { return; }
    if (!this.packageImportReport.importItems) { return; }

    this.mappedReportItems = this.packageImportReport.importItems.map(importItem => {
      const reportRow = new PackageImportReportTableModel();
      reportRow.name = importItem.item.name;
      reportRow.status = importItem.status;
      reportRow.message = importItem.messages.join('<br />');

      return reportRow;
    });
  }

  mapStatusToIconName(importStatus: PackageImportStatus) {
    switch (importStatus) {
      case PackageImportStatus.Warning:
        return 'warning';
      case PackageImportStatus.Error:
        return 'error';
      default:
        return 'done';
    }
  }

  apply(): void {
    this.close();
  }

  cancel(): void {
    this.close();
  }

  close(): void {
    this.reset();
    this.modalApply.next();
  }

  reset() {
    this.isUploading = false;
    this.selectedFile = undefined;
    this.mappedReportItems = []
    this.packageImportReport = undefined;
  }
}
