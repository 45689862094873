import { BaseProperty, PropertyType } from 'app/modules/shared/model/properties/base-property';
import { OutputProperty } from 'app/modules/shared/model/properties/output-property';

import { FunctionBase } from './function-base';
import { FunctionType } from './functions';

export class FunctionRequestSeed extends FunctionBase {

  constructor(enabled: boolean, properties: Array<BaseProperty>) {
    super(enabled, properties);
    this.name = 'Request Seed';
    this.propertyType = PropertyType.Source;
    this.type = FunctionType.RequestSeed;
  }

  addProperty(propertyName: string): void {
    const inputProperty = new OutputProperty();
    inputProperty.name = propertyName;
    inputProperty.setServiceExecutionSequenceModelSilent(this.serviceExecutionSequence);
    this.properties.push(inputProperty);
  }

  syncFromModel(): void {
    this.properties.forEach(property => {
      property.setServiceExecutionSequenceModelSilent(this.serviceExecutionSequence);
    });
  }
}
