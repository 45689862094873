import { Component } from '@angular/core';
import { OdxService } from 'app/app-services/odx-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';

import { SpecificationStatusHubService } from '../../../app-services/hubs/specification-status-hub.service';
import { MessageService } from '../../../modules/shared/services/message-service.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent {

  loading: boolean;
  status: string;
  constructor(private specificationService: SpecificationService,
    private odxService: OdxService,
    private messageService: MessageService,
    private specificationStatusService: SpecificationStatusService,
    private specificationStatusHubService: SpecificationStatusHubService) {
  }

  onOdxFileCreated(taskId: string, odxFileId: string) {

  }

  get isImportAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get importNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }
}
