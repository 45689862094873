import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ServiceExecutionModel } from 'app/app-model/diagnostic-service/service-execution.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { DiagnosticRequisitesBase } from '../../model/diagnostic-requisites-base';

@Component({
  selector: 'app-postrequisites',
  templateUrl: './postrequisites.component.html',
  styleUrls: ['./postrequisites.component.css']
})
export class PostrequisitesComponent extends DiagnosticRequisitesBase implements OnInit {

  @Input()
  isReadOnly: boolean;

  private _ecuResetServiceExecution: ServiceExecutionModel;
  public get ecuResetServiceExecution(): ServiceExecutionModel {
    return this._ecuResetServiceExecution;
  }

  @Input()
  public set ecuResetServiceExecution(v: ServiceExecutionModel) {
    this._ecuResetServiceExecution = v;

    if (this._ecuResetServiceExecution) {
      this.initRequisites();
    } else {
      this.disablePostRequisites();
    }
  }

  @Output()
  ecuResetCommandChanged: EventEmitter<string> = new EventEmitter();

  availableEcuResets: string[] = [];

  ecuResetControl = new FormControl();
  filteredEcuResets: Observable<string[]>;
  selectedEcuReset: string;
  selectedEcuResetChanged: boolean = false;

  ecuResetEnabled: boolean;

  constructor(private dataCategoriesService: DataCategoriesService,
    private specificationService: SpecificationService,
    private authService: AuthService) {
    super();
  }

  get canConfirmEcuReset(): boolean {
    return this.selectedEcuResetChanged && !this.isReadOnly;
  }

  ngOnInit(): void {
    this.loadCategoryItems();
  }

  confirmEnteredEcuReset() {
    this.ecuResetCommandChanged.emit(`${this.selectedEcuReset}`);
    this.selectedEcuResetChanged = false;
  }

  onActivateEcuReset() {
    this.ecuResetEnabled = !this.ecuResetEnabled;

    if (!this.ecuResetEnabled) {
      this.selectedEcuReset = '';
      this.ecuResetCommandChanged.emit('');
    }
  }

  protected enableOrDisableInputControls() {
    if (this.isReadOnly || !this.availableEcuResets) {
      this.ecuResetControl.disable();
    }
  }

  protected initRequisites() {
    if (this.ecuResetServiceExecution) {
      this.ecuResetEnabled = true;
      this.selectedEcuReset = this.ecuResetServiceExecution.command.arguments;
    }
  }

  protected disablePostRequisites() {
    this.ecuResetEnabled = false;
    this.selectedEcuReset = undefined;
  }

  protected loadCategoryItems() {
    const currentSpecificationversion = this.specificationService.currentSpecificationVersion;
    const ecuResetsObservable = this.dataCategoriesService.updateCategory(Category.EcuResets, currentSpecificationversion);

    ecuResetsObservable.subscribe(_ => {
      this.availableEcuResets = this.dataCategoriesService.getCategory(Category.EcuResets).items.map(item => item.name);
      this.enableOrDisableInputControls();
      this.initFilters();
      this.controlValueChanges();
    });
  }

  protected initFilters() {
    this.filteredEcuResets = this.ecuResetControl.valueChanges.pipe(
      startWith(''),
      map(value => super.filterValues(value, this.availableEcuResets))
    );
  }
  canEditCategoryItems(): boolean {
    return this.authService.canEditCategoryItems();
  }

  protected controlValueChanges() {
    this.ecuResetControl.valueChanges.subscribe(value => {
      if (this.selectedEcuReset != value) {
        this.selectedEcuReset = value;
        this.selectedEcuResetChanged = true;
      }
    });
  }
}
