import { CommonListElementSettings } from './common-list-element-settings';

export class CommonListElement<T> {
  isDragOver = false;
  element: T;
  i = 0;

  constructor(element: T, private settings: CommonListElementSettings) {
    this.element = element;
  }

  get allowDrop() {
    return this.settings.allowDrop;
  }

  get isVisible() {
    return this.settings.isVisible;
  }

  get draggable() {
    return this.settings.draggable;
  }

  get highlighted() {
    return this.settings.highlighted;
  }

  set highlighted(val: boolean) {
    this.settings.highlighted = val;
  }

  dragEnter(event: DragEvent) {
    this.isDragOver = true;
    this.i++;
  }

  dragLeave() {
    this.i--;
    if (this.i === 0) {
      this.isDragOver = false;
    }
  }
}
