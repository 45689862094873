<h1>User Details</h1>
<!-- Filter row -->
<div>
  <sdds-textfield (keyup)="applyFilter($event.target.value)" size="md" type="text" autocomplete="off"
    placeholder="Search for user ..." id="finder" class="float-left mr-20"></sdds-textfield>
  <sdds-button type="secondary" (click)="applyFilter('')" onclick="document.getElementById('finder').value=''"
    text="Clear" size="sm" class="mt-8 md:my-8"></sdds-button>
</div>

<div class="mat-elevation-z8">
  <table mat-table class="w-11/12 m-12 p-12" matSort>
    <!-- ScaniaID column-->
    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
      <td mat-cell *matCellDef="let row">{{row.userName}}</td>
    </ng-container>

    <!-- First name Column -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
      <td mat-cell *matCellDef="let row">
        <app-editable-textbox *ngIf="canEditUsers()" [overrideFont]="false" [text]="row.firstName"
          (textEditedEventEmitter)="editFirstName($event, row)" [isAllowed]="canEditUsers()"></app-editable-textbox>
        <span *ngIf="!canEditUsers()">{{row.firstName}}</span>
      </td>
    </ng-container>

    <!-- Last name Column -->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
      <td mat-cell *matCellDef="let row">
        <app-editable-textbox *ngIf="canEditUsers()" [overrideFont]="false" [text]="row.lastName"
          (textEditedEventEmitter)="editLastName($event, row)" [isAllowed]="canEditUsers()"></app-editable-textbox>
        <span *ngIf="!canEditUsers()">{{row.lastName}}</span>
      </td>
    </ng-container>

    <!-- Last login -->
    <ng-container matColumnDef="lastSeen">
      <th mat-header-cell *matHeaderCellDef>Last login</th>
      <td mat-cell *matCellDef="let row" class="text-center">{{row.lastSeen | date: "yyyy-MM-dd HH:mm"}}</td>
    </ng-container>

    <!-- The delete section -->
    <ng-container matColumnDef="removeuser">
      <th mat-header-cell *matHeaderCellDef>Remove User</th>
      <td mat-cell *matCellDef="let row" class="text-center">
        <button id="{{row.userName}}" class="sdds-btn sdds-btn-ghost sdds-btn-sm" [disabled]="!canEditUsers()">
          <mat-icon svgIcon="trash" class="md-18"></mat-icon>
        </button>
        <sdds-modal size="md" selector="#{{row.userName}}">
          <h5 slot="sdds-modal-headline">Delete User</h5>
          <p slot="sdds-modal-body">
            This action is irreversible. Are you sure you want to delete this user?
          </p>
          <button slot="sdds-modal-actions" data-dismiss-modal (click)="deleteUser(row)"
            class="sdds-btn sdds-btn-danger sdds-btn-md mr-10">Delete</button>
          <button slot="sdds-modal-actions" data-dismiss-modal
            class="sdds-btn sdds-btn-secondary sdds-btn-md">Cancel</button>
        </sdds-modal>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="25"
    [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator>
</div>