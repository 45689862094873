<app-dialog-base modalId="removeItemConfirmModal" title="Remove item" [itemTemplate]="removeItemConfirmTemplate" [footerTemplate]="removeItemConfirmFooter">
</app-dialog-base>

<ng-template #removeItemConfirmTemplate>
    <p>Are you sure to remove the item {{itemToRemoveName}} ?</p>
</ng-template>

<ng-template #removeItemConfirmFooter>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-primary sdds-btn-md mr-12" (click)="confirmRemove()">Yes</button>
    <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md">No</button>
</ng-template>