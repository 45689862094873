<div class="sdds-row" *ngIf="range">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
        <table class="table table-borderless">
            <thead>
                <th scope="col"><small>Lower Type</small></th>
                <th scope="col"><small>Lower Value</small></th>
                <th scope="col"></th>
                <th scope="col"><small>Upper Value</small></th>
                <th scope="col"><small>Upper Type</small></th>
            </thead>
            <tbody>
                <td>
                    <select name="selectLowerLimit" [(ngModel)]="range.lowerType" (change)="onLowerLimitSelect()"
                        [disabled]="isOdxBasedSpecification || !isAllowed">
                        <option disabled active></option>
                        <option [ngValue]="DataLimitType.Open"><b>]</b></option>
                        <option [ngValue]="DataLimitType.Closed"><b>[</b></option>
                        <option [ngValue]="DataLimitType.Infinite"><b>[-∞</b></option>
                    </select>
                </td>
                <td>
                    <input *ngIf="range.lowerType !== DataLimitType.Infinite" type="text" name="lowerVal"
                        [(ngModel)]="range.lowerValue.data" (change)="onChange($event)"
                        [readOnly]="isOdxBasedSpecification || !isAllowed">
                    <b *ngIf="range.lowerType === DataLimitType.Infinite">INF</b>
                </td>
                <td>
                    <b>,</b>
                </td>
                <td>
                    <input *ngIf="range.upperType !== DataLimitType.Infinite" type="text" name="upperVal"
                        [(ngModel)]="range.upperValue.data" (change)="onChange($event)"
                        [readOnly]="isOdxBasedSpecification || !isAllowed">
                    <b *ngIf="range.upperType === DataLimitType.Infinite">INF</b>
                </td>
                <td>
                    <select name="selectUpperLimit" [(ngModel)]="range.upperType" (change)="onUpperLimitSelect()"
                        [disabled]="isOdxBasedSpecification || !isAllowed">
                        <option disabled active></option>
                        <option [ngValue]="DataLimitType.Open"><b>[</b></option>
                        <option [ngValue]="DataLimitType.Closed"><b>]</b></option>
                        <option [ngValue]="DataLimitType.Infinite"><b>∞]</b></option>
                    </select>
                </td>
            </tbody>
        </table>
    </div>
</div>