import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryItem } from 'app/app-model/category-item';
import { EcuResetModel } from 'app/app-model/ecu-reset/ecu-reset.model';
import { CategoryName } from 'app/app-model/enums';
import { EcuResetService } from 'app/app-services/ecu-reset.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { PropertyType } from 'app/modules/shared/model/properties/base-property';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';

export class EcuResetCategoryItem extends CategoryItem {
  ecuResetService: EcuResetService;
  propertyType: PropertyType = PropertyType.Both;
  oldName = '';

  private _model: EcuResetModel;
  public get model(): EcuResetModel {
    return this._model;
  }
  public set model(v: EcuResetModel) {
    this._model = v;
    this.syncFromModel();
  }

  private _serviceExecutionSequence: ServiceExecutionSequence;
  public get serviceExecutionSequence(): ServiceExecutionSequence {
    return this._serviceExecutionSequence;
  }

  public set serviceExecutionSequence(v: ServiceExecutionSequence) {
    this._serviceExecutionSequence = v;
  }

  constructor(
    private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this._serviceExecutionSequence = new ServiceExecutionSequence();
    super.categoryType = CategoryName.EcuReset;
  }

  setName(name: string) {
    this.oldName = this.model.name;
    this.name = name;
    this.model.name = name;
    this.notifySessionChanged();
  }

  notifySessionChanged() {
    console.log('Ecu Reset ' + this.oldName + ' modified with the new name ' + this.name + '...Saving changes');

    this.ecuResetService.updateItem(this.specificationService.currentSpecificationVersion.id, this.model, this.oldName)
      .subscribe(updatedEcuReset => {
        this.model = updatedEcuReset;

        const newUrl = this.router.createUrlTree([], {
          relativeTo: this.activatedRoute,
          queryParams: { itemId: this.model.name }
        }).toString();
        this.locationService.go(newUrl);

        console.log('Ecu Reset' + this.name + ' successfully updated');
      });
  }

  private syncFromModel() {
    this.name = this.model.name;
    this.oldName = this.name;
    this.id = this.model.id;

    if (!this.isPreview && this.model.resetSequence) {
      this.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
      this.subscribeServiceExecutionModifiedEvents();
      this.serviceExecutionSequence.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
      this.serviceExecutionSequence.setModel(this.model.resetSequence).subscribe();
      super.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
    }
  }

  private subscribeServiceExecutionModifiedEvents() {
    if (!this.hasModificationSubscriptions) {
      this._serviceExecutionSequence.modified.subscribe(args => {
        this.notifySessionChanged();
      });
      this.hasModificationSubscriptions = true;
    }
  }
}
