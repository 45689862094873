<div *ngIf="fileVersion">
  <div style="float:right;">
    <form role="form">
      <div class="form-group">
        <button id="fileButton" class="sdds-btn sdds-btn-secondary sdds-btn-sm" type="button" (click)="download();" [disabled]="!isAllowed">Download selected file
          version</button>
      </div>
    </form>
  </div>

  <h4>Version Details</h4>
  <div>
    <p class="sdds-detail-04">File name:</p>
    <p class="sdds-detail-03">{{ fileVersion.file.name }}</p>
  </div>
  <br />
  <div>
    <p class="sdds-detail-04">Labels:</p>
    <div *ngIf="currentVersionLabels && currentVersionLabels.length !== 0">
      <small class="sdds-detail-03" *ngFor="let label of currentVersionLabels; let isLast=last">
        {{label.name}}{{isLast ? '' : ','}}
      </small>
    </div>

    <p *ngIf="!currentVersionLabels || (currentVersionLabels && currentVersionLabels.length === 0)">None</p>
  </div>

</div>
