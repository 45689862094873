<div class="sdds-row" *ngIf="specification">
    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 specification-infobar">
        <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-divider-light-border-bottom">
        </div>

        <span *ngIf="specification && specificationVersion" class="font-bold text-blue-900" [ngClass]="{'statusHeaderInWork': !isCurrentSpecificationReleased, 'statusHeaderReleased': isCurrentSpecificationReleased, 'statusHeaderActiveUsers': getNumberOfActiveUsers}">
          {{specification.name}} - VERSION {{specificationVersion.name}}
        </span>

        <span>
            <span appInWork *ngIf="!isCurrentSpecificationReleased ">In-Work</span>
        <span appReleased *ngIf="isCurrentSpecificationReleased ">Released</span>
        </span>

        <span *ngIf="getNumberOfActiveUsers>1">
            <div class="sdds-badges-text" appActiveUsers *ngIf="getNumberOfActiveUsers">
                Active users
                <sdds-tooltip placement="bottom" selector="#active-user-count" text="{{getNameOfUsers}}"></sdds-tooltip>
                <sdds-badges value="{{getNumberOfActiveUsers}}" id="active-user-count"></sdds-badges>
            </div>
        </span>
    </div>
</div>
