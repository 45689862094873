import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  @Output()
  filterTextEventEmitter: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  collapseEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  expandEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  addEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  upgradeEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  removeEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  addButtonText: string;

  @Input()
  addButtonShow = true;

  @Input()
  upgradeButtonText: string;

  @Input()
  removeButtonText: string;

  @Input()
  removeButtonShow = true;

  @Input()
  createItemId: string;

  @Input()
  removeItemId: string;

  @Input()
  createButtonId: string;

  @Input()
  upgradeButtonId: string;

  @Input()
  collapseButtonText: string;

  @Input()
  expandButtonText: string;

  @Input()
  filterEnabled: boolean;

  @Input()
  createSpecification: boolean;

  @Input()
  createVersion: boolean;

  @Input()
  upgradeVersion: boolean;

  @Input()
  addDataTargetEnabled: boolean;

  @Input()
  dataTargetEnabledAdd = true;

  @Input()
  dataTargetEnabledRemove = true;

  @Input()
  addEventEmitterEnabled: boolean;

  @Input()
  upgradeEventEmitterEnabled: boolean;

  @Input()
  addNotAllowed: boolean;

  @Input()
  addNotAllowedReason = '';

  @Input()
  removeDataTargetEnabled: boolean;

  @Input()
  removeEventEmitterEnabled: boolean;

  @Input()
  removeNotAllowed: boolean;

  @Input()
  removeNotAllowedReason = '';

  @Input()
  collapseEnabled: boolean;

  @Input()
  expandEnabled: boolean;

  @Input()
  addDataTarget: string;

  @Input()
  upgradeDataTarget: string;

  @Input()
  removeDataTarget: string;

  @Input()
  isAllowed: boolean;

  filterShown: boolean;

  private _filterText: string;
  private _textSubject: Subject<string> = new Subject<string>();

  ngOnInit() {
    this._textSubject.pipe(debounceTime(500)).subscribe(s => {
      this.filterTextEventEmitter.emit(s);
    });
    this.filterShown = true;
    if (this) {
      this.collapseEnabled = false;
    }
  }

  get filterText(): string {
    return this._filterText;
  }

  set filterText(filterText: string) {
    this._textSubject.next(filterText);
    this._filterText = filterText;
  }

  setFilterSilent(filterText: string) {
    this._filterText = filterText;
  }

  get addText() {
    return this.addButtonText || 'Add';
  }

  get upgradeText() {
    return this.upgradeButtonText || 'Upgrade';
  }

  get removeText() {
    return this.removeButtonText || 'Remove';
  }

  get collapseText() {
    return this.collapseButtonText || 'Collapse all';
  }

  get expandText() {
    return this.expandButtonText || 'Expand all';
  }

  get addEnabled() {
    return this.addDataTargetEnabled || this.addEventEmitterEnabled;
  }

  get removeEnabled() {
    return this.removeDataTargetEnabled || this.removeEventEmitterEnabled;
  }

  get createSpecificationOrVersionEnabled() {
    return this.createSpecification || this.createVersion;
  }

  get upgradeVersionEnabled() {
    return this.upgradeVersion;
  }

  get addWithModalEnabled(): boolean {
    return this.addEnabled && !this.addNotAllowed && this.dataTargetEnabledAdd && this.addDataTargetEnabled;
  }

  get addWithoutModalEnabled(): boolean {
    return this.addEnabled && !this.addNotAllowed && this.dataTargetEnabledAdd && !this.addDataTargetEnabled;
  }

  get removeWithModalEnabled(): boolean {
    return this.removeEnabled && !this.removeNotAllowed && this.dataTargetEnabledRemove && this.removeDataTargetEnabled;
  }

  get removeWithoutModalEnabled(): boolean {
    return this.removeEnabled && !this.removeNotAllowed && this.dataTargetEnabledRemove && !this.removeDataTargetEnabled;
  }

  collapseClicked() {
    this.collapseEnabled = false;
    this.expandEnabled = true;
    this.collapseEventEmitter.emit();
  }

  expandClicked() {
    this.expandEnabled = false;
    this.collapseEnabled = true;
    this.expandEventEmitter.emit();
  }

  addClicked() {
    if (this.addEventEmitterEnabled) {
      this.addEventEmitter.emit();
    }
  }

  upgradeClicked() {
    if (this.upgradeEventEmitterEnabled) {
      this.upgradeEventEmitter.emit();
    }
  }

  removeClicked() {
    if (this.removeEventEmitterEnabled) {
      this.filterText = '';
      this.removeEventEmitter.emit();
    }
  }
}
