<ng-container *ngIf="isEditable">
    <div *ngIf="isEditing" class="sdds-row">
        <div class="sdds-col-max-12 sdds-no-padding">
            <div class="sdds-row editable-textbox">
                <div class="sdds-col-max-8 sdds-on-white-bg mb-5">
                    <input class="sdds-textfield-input" style="padding: 10px" type="text" name="Item name" ngDefaultControl
                        [(ngModel)]="pendingValue" label-inside="Item name" (keydown.Enter)="processChanges()"
                        (keydown.Meta.Enter)="processChanges()" (keydown.Escape)="cancel()" [readonly]="!isAllowed" />
                </div>

                <div class="sdds-col-max-4">
                    <button (click)="processChanges()" id="editDone" class="sdds-btn sdds-btn-ghost sdds-btn-sm"
                        title="Click here or press enter to save" [disabled]="!isAllowed">
                        <mat-icon fontSet="material-icons-outlined" class="md-18">done</mat-icon>
                    </button>
                    <button 
                        (click)="cancel()" 
                        id="editCancel" 
                        class="sdds-btn sdds-btn-ghost sdds-btn-sm"
                        title="Cancel editing" 
                        [disabled]="!isAllowed"
                        matTooltip="Close editing">
                        <mat-icon fontSet="material-icons-outlined" class="md-18">close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isEditing && !editingNotAllowed" class="sdds-row">
        <div class="sdds-col-max-12 sdds-no-padding">
            <ng-container *appReplaceElement="enclosingElement">
                <div class="flex">
                  <span *ngIf="!isAllowed" class="break-all">{{text}}</span>
                  <span *ngIf="isAllowed" class="break-all">{{text}}</span>
                  <button class="sdds-btn sdds-btn-ghost sdds-btn-sm edit-node-name -mt-4 ml-4" 
                        (click)="edit()" 
                        [disabled]="!isAllowed"
                        matTooltip="Edit content">
                      <mat-icon fontSet="material-icons-outlined" class="scale-75">edit</mat-icon>
                  </button>
                </div>
            </ng-container>
        </div>
    </div>

    <div *ngIf="editingNotAllowed" class="sdds-row">
        <div class="sdds-col-max-12 sdds-no-padding">
            <ng-container *appReplaceElement="enclosingElement">
                <span id="editNotAllowed" title={{editingNotAllowedReason}}>
                    {{text}}
                    <button class="sdds-btn sdds-btn-ghost sdds-btn-sm disabled">
                        <mat-icon fontSet="material-icons-outlined" class="md-18">edit</mat-icon>
                    </button>
                </span>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isEditable">
    <ng-container *appReplaceElement="enclosingElement">
        <span class="break-all">
            {{text}}
        </span>
    </ng-container>
</ng-container>
