import { OnInit, Directive, OnDestroy } from '@angular/core';
import { CategoryItem } from 'app/app-model/category-item';
import {
  OperationalDataDefinitionSection,
  OperationalDataVariableType,
} from 'app/app-model/operational-data/operational-data-enums';
import { OperationalDataModel } from 'app/app-model/operational-data/operational-data.model';
import { CategorySaveService } from 'app/app-services/category-save.service';
import { OperationalDataService } from 'app/app-services/operational.data.service';
import { BreakpointGenerator } from 'app/data-categories/operational-data/breakpoint-generator';
import { PropertyGenerator } from 'app/data-categories/operational-data/property-generator';
import { BaseProperty, PropertyType } from 'app/modules/shared/model/properties/base-property';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoryName } from 'app/app-model/enums';
import { Subscription } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class OperationalDataCategoryItem extends CategoryItem implements OnInit, OnDestroy {

  categorySaveService: CategorySaveService;
  // specificationVersionId: number;
  propertyGenerator: PropertyGenerator = new PropertyGenerator();
  breakpointGenerator: BreakpointGenerator = new BreakpointGenerator();
  properties: Array<BaseProperty> = new Array<BaseProperty>();
  propertyType: PropertyType = PropertyType.Source;
  operationalDataService: OperationalDataService;
  hasPropertySyncNotifications = false;
  hasAmbiguousAccUnit = false;
  oldName = '';

  private sequenceUpdateSubscription: Subscription;

  private _model: OperationalDataModel = new OperationalDataModel();
  public get model(): OperationalDataModel {
    return this._model;
  }
  public set model(v: OperationalDataModel) {
    this._model = v;
    this.syncFromModel();
  }

  private _serviceExecutionSequence: ServiceExecutionSequence;
  public get serviceExecutionSequence(): ServiceExecutionSequence {
    return this._serviceExecutionSequence;
  }

  public set serviceExecutionSequence(v: ServiceExecutionSequence) {
    this._serviceExecutionSequence = v;
    this.assignServiceExecutionToAvailableProperties();
  }

  public get operationalDataVariableUnit(): string {
    return this.model.unit;
  }
  public set operationalDataVariableUnit(v: string) {
    this.model.unit = v;
  }

  constructor(
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
    this._serviceExecutionSequence = new ServiceExecutionSequence();
    super.categoryType = CategoryName.OperationalDataVariable;
    super.specificationVersionId = this.specificationVersionId;
  }

  ngOnInit(): void {
    this.categorySaveService.requestSave.subscribe(args => {
      this.notifyOperationalDataChanged();
    });
  }

  ngOnDestroy(): void {
    if (this.sequenceUpdateSubscription) {
      this.sequenceUpdateSubscription.unsubscribe();
    }
  }

  /**Checks if the specified operational data definiton section should be enabled for the current
   * Operational data variable type
   */
  public shouldBeEnabledForCurrentODType(sectionType: OperationalDataDefinitionSection): boolean {
    if (this.model && !this.model.type) {
      return false;
    }

    switch (sectionType) {
      case OperationalDataDefinitionSection.Columns:
        return !this.hasScalarOperationalDataVariableType();
      case OperationalDataDefinitionSection.Rows:
        return this.hasMatrixOperationalDataVariableType();
      case OperationalDataDefinitionSection.XAxis:
        return !this.hasScalarOperationalDataVariableType();
      case OperationalDataDefinitionSection.YAxis:
        return this.hasMatrixOperationalDataVariableType();
      case OperationalDataDefinitionSection.PeriodLength:
        return this.hasPeriodicOperationalDataVariableType();
      case OperationalDataDefinitionSection.PeriodUnit:
        return this.hasPeriodicOperationalDataVariableType();
      case OperationalDataDefinitionSection.Unit:
        return this.hasAccOperationalDataVariableType();
      default:
        return false;
    }
  }

  /**
   * Checks if the specified definition section should be shown as readonly.
   */
  public shouldBeReadOnlyForCurrentODType(sectionType: OperationalDataDefinitionSection): boolean {
    switch (sectionType) {
      case OperationalDataDefinitionSection.Columns:
        return this.hasMinMaxValueCollectionVariableType();
      case OperationalDataDefinitionSection.XAxis:
        return this.hasMinMaxValueCollectionVariableType() || this.hasAccumulatedSlindingVariableType();
      default:
        return false;
    }
  }

  public setName(name: string) {
    this.oldName = this.model.name;
    this.name = name;
    this.model.name = name;
    this.notifyOperationalDataChanged();
  }

  public onOperationalDataDefinitionChanged(arg: { wasGraphTypeChanged: boolean; requestSave: boolean }) {
    console.log(this.name + ' updated definition');

    if (arg.wasGraphTypeChanged) {
      this.hasPropertySyncNotifications = false;
    }

    this.createOperationalDataItemsFromCurrentModel(arg.wasGraphTypeChanged);

    if (arg.requestSave === true) {
      this.notifyOperationalDataChanged();
    }
  }

  public notifyOperationalDataChanged() {
    console.log('Operational Data ' + this.name + ' modified...Saving changes');

    this.operationalDataService.updateItem(this.specificationVersionId, this.model, this.resourceId)
      .subscribe(updatedOd => {
        const newUrl = this.router.createUrlTree([], {
          relativeTo: this.activatedRoute,
          queryParams: { itemId: this.model.resourceId }
        }).toString();
        this.locationService.go(newUrl);

        console.log('Operational Data' + this.name + ' successfully updated');
      });
  }

  public hasSingleAxisOperationalDataVariableType(): boolean {
    if (!this.model) {
      return false;
    }

    return this.model.type === OperationalDataVariableType.AccumulatedHistogram ||
      this.model.type === OperationalDataVariableType.AccumulatedHistogramWithDefault ||
      this.model.type === OperationalDataVariableType.MomentaryBarChart ||
      this.model.type === OperationalDataVariableType.MinMaxValueCollection ||
      this.model.type === OperationalDataVariableType.AccumulatedSlidingBarChart;
  }

  public hasMinimumValidDefinition(): boolean {
    return this.model.type != null;
  }

  public hasNoBreakpointsDefinition(): boolean {
    return !this._model.xAxis && !this.model.yAxis;
  }

  public hasMatrixOperationalDataVariableType(): boolean {
    if (!this.model) {
      return false;
    }

    return this.model.type === OperationalDataVariableType.MomentaryMatrix ||
      this.model.type === OperationalDataVariableType.AccumulatedMatrix ||
      this.model.type === OperationalDataVariableType.AccumulatedMatrixWithDefault;
  }

  private getAccumulatedValueUnit(): string {
    this.hasAmbiguousAccUnit = false;

    if (this.isPreview) {
      return '';
    }

    const ambiguousAccUnit = 'Ambiguous Acc Unit';
    let hasSameAccUnit = true;
    let referenceUnit = '';

    for (let i = 0; i < this.properties.length; i++) {
      if (i <= 0) {
        if (!this.properties[i].selectedPropertyParameter) {
          referenceUnit = '';
        } else {
          referenceUnit = this.properties[i].selectedPropertyParameter.parameter.model.scaling.unit;
          continue;
        }
      } else {
        let currentUnit = '';

        if (this.properties[i].selectedPropertyParameter) {
          currentUnit = this.properties[i].selectedPropertyParameter.parameter.model.scaling.unit;
        }

        if (referenceUnit !== currentUnit) {
          hasSameAccUnit = false;
          break;
        }
      }
    }

    this.hasAmbiguousAccUnit = !hasSameAccUnit;

    return hasSameAccUnit ? referenceUnit : ambiguousAccUnit;
  }

  private syncFromModel() {
    this.resourceId = this.model.resourceId;
    this.name = this.model.name;
    this.oldName = this.name;

    if (this.model.sequence) {
      this.serviceExecutionSequence.diagnosticServiceProvider = this.diagnosticServiceProvider;
      if (!this.isPreview) {
        this.createOperationalDataItemsFromCurrentModel(true);

        this.serviceExecutionSequence.specificationVersionId = this.specificationVersionId;
        this.sequenceUpdateSubscription = this.serviceExecutionSequence.setModel(this.model.sequence).subscribe(_ => {
          this.serviceExecutionSequence.updateAvailableParameters();
          this.subscribeServiceExecutionModifiedEvents();
          this.assignServiceExecutionToAvailableProperties();
        });
      }
    }
  }

  private createOperationalDataItemsFromCurrentModel(overwriteExistingProperties: boolean) {
    this.propertyGenerator.generatePropertiesFromDefinition(this.properties, this.model, overwriteExistingProperties);
    this.breakpointGenerator.generateBreakpoints(this.model);
    this.setAccumulatedOperationalDataVariableUnit();
    this.assignServiceExecutionToAvailableProperties();
    this.assignPropertySubscribers();
  }

  private hasScalarOperationalDataVariableType(): boolean {
    if (!this.model) {
      return false;
    }

    return this.model.type === OperationalDataVariableType.AccumulatedScalar ||
      this.model.type === OperationalDataVariableType.MomentaryScalar;
  }

  private hasMinMaxValueCollectionVariableType(): boolean {
    if (!this.model) {
      return false;
    }

    return this.model.type === OperationalDataVariableType.MinMaxValueCollection;
  }

  private hasAccumulatedSlindingVariableType(): boolean {
    if (!this.model) {
      return false;
    }

    return this.model.type === OperationalDataVariableType.AccumulatedSlidingBarChart;
  }

  private hasPeriodicOperationalDataVariableType(): boolean {
    if (!this.model) {
      return false;
    }

    return this.model.type === OperationalDataVariableType.AccumulatedSlidingBarChart;
  }

  private hasAccOperationalDataVariableType(): boolean {
    if (!this.model) {
      return false;
    }

    switch (this.model.type) {
      case OperationalDataVariableType.AccumulatedHistogram:
      case OperationalDataVariableType.AccumulatedHistogramWithDefault:
      case OperationalDataVariableType.AccumulatedMatrix:
      case OperationalDataVariableType.AccumulatedMatrixWithDefault:
      case OperationalDataVariableType.AccumulatedScalar:
      case OperationalDataVariableType.AccumulatedSlidingBarChart:
        return true;
      default:
        return false;
    }
  }

  private assignServiceExecutionToAvailableProperties() {
    this.properties.forEach(property => {
      property.setServiceExecutionSequenceModelSilent(this.serviceExecutionSequence);
    });
  }

  private assignPropertySubscribers() {
    this.properties.forEach(property => {
      if (!this.hasPropertySyncNotifications) {
        property.syncPropertyDone.subscribe(args => {
          this.setAccumulatedOperationalDataVariableUnit();
        });
      }

      property.setServiceExecutionSequenceModelSilent(this.serviceExecutionSequence);
    });

    this.hasPropertySyncNotifications = true;
  }

  private setAccumulatedOperationalDataVariableUnit() {
    this.operationalDataVariableUnit = this.getAccumulatedValueUnit();
  }

  private resetPropertySubscribers() {
    this.properties.forEach(property => {
      property.syncPropertyDone.observers = [];
    });
  }

  private subscribeServiceExecutionModifiedEvents() {
    if (!this.hasModificationSubscriptions) {
      this._serviceExecutionSequence.modified.subscribe(args => {
        this.notifyOperationalDataChanged();
      });

      this.hasModificationSubscriptions = true;
    }
  }
}
