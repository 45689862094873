import { ParameterType } from 'app/modules/shared/model/service/parameters/parameter-type';
import { TypedValueData } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { ScalingModel } from 'app/app-model/diagnostic-service/parameter.scaling.model';
import { LengthModel } from 'app/app-model/diagnostic-service/length.model';

export class ParameterDataModel {
    id: number;
    messageId: number;
    index: number;
    name: string;
    bytePosition: number;
    bitPosition: number;

    type: ParameterType;
    value: TypedValueData;
    // message: MessageData;
    scaling: ScalingModel;
    length: LengthModel;
}
