import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IoListIdCategoryItemId } from 'app/app-model/iolist/iolist.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { IoListService } from 'app/app-services/iolist.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { CategoryComponentBaseComponent } from 'app/modules/shared/model/category-component-base';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { Category } from 'app/specification-structure/category-items/category.enum';

import { IoListCategoryItem } from './iolist-category-item';
import { NavigationService } from 'app/app-services/navigation.service';

@Component({
  selector: 'app-iolist',
  templateUrl: './iolist.component.html',
  styleUrls: ['./iolist.component.css']
})
export class IolistComponent extends CategoryComponentBaseComponent<IoListCategoryItem> implements OnInit, OnDestroy {
  iolistItem: IoListCategoryItem;

  constructor(activatedRoute: ActivatedRoute,
    private specificationStatusService: SpecificationStatusService,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    messageService: MessageService, iolistService: IoListService,
    navigationService: NavigationService,private authService:AuthService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.Iolists, navigationService);
  }

  ngOnInit() {
    this.componentSubscriptions.push(this.itemChanged.subscribe(item => {
      this.iolistItem = item;
    }));
  }

  get isEditingAllowed(): boolean {
    return this.specificationStatusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  editingNameDone(name: string) {
    if (name.trim().length > 0) {
      this.iolistItem.setName(name.trim());
    } else {
      this.messageService.dispatchErrorMessage('Session name can not be empty!');
    }
  }

  iosChanged(ioRelations: IoListIdCategoryItemId[]) {
    this.iolistItem.model.listOfIoRelations = ioRelations;
    this.iolistItem.notifyIolistChanged();
  }

  ioRemoved(ioRelation: IoListIdCategoryItemId) {
    const index = this.iolistItem.model.listOfIoRelations.findIndex(io =>
      ioRelation.ioListName === io.ioListName && ioRelation.ioName === io.ioName);
    this.iolistItem.model.listOfIoRelations.splice(index, 1);
    this.iolistItem.notifyIolistChanged();
  }

  updateComment() {
    this.iolistItem.notifyIolistChanged();
  }
  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
