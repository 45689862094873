import { DiagnosticServiceGroupModel } from 'app/app-model/diagnostic-service/diagnostic-service-group.model';
import { DiagnosticServiceGroupType } from 'app/app-model/enums';

import { DiagnosticServiceCategoryItem } from './diagnostic-service';


export class DiagnosticServiceCategoryItemGroup {
    children: DiagnosticServiceCategoryItemGroup[];
    diagnosticServices: DiagnosticServiceCategoryItem[];
    model: DiagnosticServiceGroupModel;
    name: string;
    type: DiagnosticServiceGroupType;

    constructor(model: DiagnosticServiceGroupModel) {
        this.children = [];
        this.diagnosticServices = [];

        this.model = model;

        this.name = model.name;
        this.type = this.model.type;

        model.children.forEach(x => {
            this.addChild(new DiagnosticServiceCategoryItemGroup(x));
        });

        model.diagnosticServices.forEach(x => {
            this.addService(new DiagnosticServiceCategoryItem(x));
        });
    }

    get typeText() {
        switch (this.type) {
            case DiagnosticServiceGroupType.DataIdentifiers1Byte:
                return '1 byte data identifier';
            case DiagnosticServiceGroupType.DataIdentifiers2Byte:
                return '2 byte data identifier';
            case DiagnosticServiceGroupType.DataIdentifierType:
                return 'Data identifier type';
            case DiagnosticServiceGroupType.ProtocolService:
                return 'Service';
            case DiagnosticServiceGroupType.SubFunction:
                return 'Subfunction';
            default:
                return '';
        }
    }

    addChild(child: DiagnosticServiceCategoryItemGroup) {
        this.children.push(child);
    }

    addService(service: DiagnosticServiceCategoryItem) {
        this.diagnosticServices.push(service);
    }
}
