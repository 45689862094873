<div style="width: 150px;">
  <sdds-textfield
    *ngIf="startIndexEnabled"
    size="md"
    name="substringIndexStart"
    ngDefaultControl
    [(ngModel)]="expressionModel.subStringStart"
    [disabled]="isReadOnly||!isAllowed"
    (customChange)="notifySubstringComparisonChanged()"
    label-inside="From Index"
  >
  </sdds-textfield>
</div>

<div style="width: 150px;">
  <sdds-textfield
    *ngIf="lengthEnabled"
    size="md"
    name="substringLength"
    ngDefaultControl
    [(ngModel)]="expressionModel.subStringLength"
    [disabled]="isReadOnly||!isAllowed"
    (customChange)="notifySubstringComparisonChanged()"
    label-inside="String Length"
  ></sdds-textfield>
</div>
