import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Specification } from 'app/app-model/specification';
import { LegacyFileModificationService } from 'app/app-services/legacy-file-modification.service';
import { LegacyFileToolbarService } from 'app/app-services/legacy-file-toolbar-service.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-validation-message-component',
  templateUrl: 'validation-message-component.html',
  styles: []
})
export class ValidationMessageComponent {
  @Input()
  validationMessages: string[];
}

@Component({
  selector: 'app-editor-shortcut-desc-component',
  templateUrl: 'editor-shortcut-desc-component.html',
  styles: []
})

export class EditorShortcutsDescComponent {
}

@Component({
  selector: 'app-legacy-file-toolbar',
  templateUrl: './legacy-file-toolbar.component.html',
  styleUrls: ['./legacy-file-toolbar.component.css']
})
export class LegacyFileToolbarComponent implements OnInit {

  @Input()
  tooltipValue: string;
  conditionToLockToolBar: boolean;
  specification: Specification;

  private _selectedSpecificationIsInWork: boolean;

  constructor(private legacyFileToolbarService: LegacyFileToolbarService,
    private modificationService: LegacyFileModificationService,
    private _snackbar: MatSnackBar, private statusOfSpecification: SpecificationStatusService,
    private specificationService: SpecificationService,
    private authService: AuthService

  ) { }

  get legacyToolbarServiceRef(): LegacyFileToolbarService {
    return this.legacyFileToolbarService;
  }

  get modificationServiceRef(): LegacyFileModificationService {
    return this.modificationService;
  }

  public get selectedSpecificationIsInWork(): boolean {
    return this._selectedSpecificationIsInWork;
  }
  public set selectedSpecificationIsInWork(v: boolean) {
    this._selectedSpecificationIsInWork = v;
  }

  ngOnInit() {
    if (this.statusOfSpecification.isInWork(this.specificationService.currentSpecificationVersion)) {
      this.selectedSpecificationIsInWork = true;
    } else {
      this.selectedSpecificationIsInWork = false;
    }

  }

 
 
  get showCreateDraftButtonTooltip(): string {
    if (!this.canEditLegacyFile) {
      return 'You are not a System owner';
    }

    if (!this.selectedSpecificationIsInWork) {
      return 'Selected specification is released';
    }

    return 'Create draft';
  }

  get showDraftButtonTooltip(): string {
    if (!this.canEditLegacyFile) {
      return 'You are not a System owner';
    }

    if (!this.legacyToolbarServiceRef.draftFilesExists) {
      return 'No draft exists';
    }

    return 'Show all drafts';
  }

  get mergeFileButtonTooltip(): string {
    if (!this.canEditLegacyFile) {
      return 'You are not a System owner';
    }
    
    if (!this.legacyToolbarServiceRef.userHasDraftFile) {
      return 'No existing draft to merge';
    }

    return 'Merge files';
  }

  get executeFileButtonTooltip(): string {
    return !this.legacyToolbarServiceRef.isValidDraft ? 'File is invalid! You cannot Execute merge on files' : 'Execute merge on files';
  }

  get saveFileButtonTooltip(): string {
    return !this.legacyToolbarServiceRef.isCurrentUserDraftSelected ? 'Your are not draft owner' : 'Save draft';
  }

  get deleteFileButtonTooltip(): string {
    return !this.legacyToolbarServiceRef.isCurrentUserDraftSelected ? 'Your are not draft owner' : 'Delete draft';
  }
  showEditorShortcuts() {
    this._snackbar.openFromComponent(EditorShortcutsDescComponent, {
      duration: 5000
    });
  }

  showValidationMessage() {
    if (this.legacyFileToolbarService.draftValidationMessage) {
      const component = this._snackbar.openFromComponent(ValidationMessageComponent, {
        duration: 10000
      });

      component.instance.validationMessages = this.legacyFileToolbarService.draftValidationMessage.split('-');
    }
  }

  canEditLegacyFile() {
    return this.authService.canEditLegacyFile();
  }
}
