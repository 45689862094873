/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PathStep, StepType } from 'app/app-model/diagnostic-service/path-step.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { ModificationService } from 'app/app-services/modification.service';
import { NavigationService } from 'app/app-services/navigation.service';
import { SessionsService } from 'app/app-services/sessions.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { DeleteDialogComponent } from 'app/data-categories/legacy-files/delete-dialog/delete-dialog.component';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { PropertyType } from 'app/modules/shared/model/properties/base-property';
import { ServiceExecutionDirective } from 'app/modules/shared/model/service-execution/service-execution';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { PropertyParameterDirective } from 'app/modules/shared/model/service/parameters/property.parameter';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';

// import { ServicePrerequisite } from 'app/app-model/diagnostic-service/service-execution.model';
@Component({
  selector: 'app-service-parameters-table',
  templateUrl: './service-parameters-table.component.html',
  styleUrls: ['./service-parameters-table.component.css']
})
export class ServiceParametersTableComponent implements OnInit {

  @Input()
  propertyType: PropertyType;

  @Input()
  isReadOnly: boolean;

  isShow = true;
  OdxDataType = OdxDataType;
  PropertyType = PropertyType;
  serviceOptionsEnabled: boolean;
  servicePrerequisite = '';
  servicePrerequisites = [];

  private _serviceExecutionSequence: ServiceExecutionSequence;
  public get serviceExecutionSequence(): ServiceExecutionSequence {
    return this._serviceExecutionSequence;
  }
  @Input()
  public set serviceExecutionSequence(v: ServiceExecutionSequence) {
    this._serviceExecutionSequence = v;
  }

  constructor(
    private dataCategoriesService: DataCategoriesService,
    private sessionsService: SessionsService,
    private specificationService: SpecificationService,
    private modificationService: ModificationService,
    private router: Router,
    public dialog: MatDialog,
    private navigationService: NavigationService,
    private authService: AuthService) { }

  ngOnInit() {
    this.getServicePrerequisitesFormatted();
  }

  get serviceExecutionsWithService() {
    return this.serviceExecutionSequence.serviceExecutions.filter(serviceExec => serviceExec.model.service);
  }

  get availableServiceExecutions() {
    return this.serviceExecutionSequence.serviceExecutions.filter(serviceExec => !serviceExec.model.command);
  }

  getServicePrerequisitesFormatted() {
    this.sessionsService.getItemsPreview(this.specificationService.currentSpecificationVersion.id).subscribe(sessionItems => {
      sessionItems.forEach(element => {
        this.servicePrerequisite = '';

        element.model.availableSecurityAccessModes.forEach(availableSecurityAccessMode => {
          if (availableSecurityAccessMode !== '') {
            this.servicePrerequisite = element.model.name + ',' + availableSecurityAccessMode.trim();
            this.servicePrerequisites.push(this.servicePrerequisite);
          }
        });
      });
    });
  }

  getParameterOwnerTagOrDefault(propertyParam: PropertyParameterDirective): string {
    if (propertyParam.owner) {
      const ownerDescription = this.propertyType === PropertyType.Source ? 'From property:' : 'To Property:';
      return ownerDescription + ' ' + propertyParam.owner.name;
    } else {
      return 'Discarded';
    }
  }

  hasPropertyOwner(propertyParam: PropertyParameterDirective): boolean {
    return propertyParam.usedByProperties && propertyParam.usedByProperties.length > 0;
  }

  hasAssignedValue(propertyParam: PropertyParameterDirective): boolean {
    return propertyParam.assignedValue.data && propertyParam.assignedValue.data !== '';
  }

  getPropertyOwnersFormated(propertyParam: PropertyParameterDirective): string {
    let formatted = '';

    propertyParam.usedByProperties.forEach(propName => {
      formatted = formatted.concat(propName, ', ');
    });

    formatted = formatted.substring(0, formatted.length - 2);

    return formatted;
  }

  onParameterValueSelected(propertyParam: PropertyParameterDirective, serviceExecution: ServiceExecutionDirective) {
    if (propertyParam.assignedValue.data) {
      propertyParam.assignedValue.data = propertyParam.assignedValue.data.trim();
    }

    if (propertyParam.hasValidParameterValue()) {
      propertyParam.isValidValue = true;
    } else {
      propertyParam.isValidValue = false;
      return;
    }

    const requestParameterToEdit = serviceExecution.model.requestParameters
      .find(requestParam => requestParam.path[0].step === propertyParam.parameter.model.name);

    if (!requestParameterToEdit) {
      return;
    }

    const pathStep = new PathStep();
    pathStep.step = propertyParam.parameter.name;
    pathStep.stepType = StepType.StepByName;

    requestParameterToEdit.path = [pathStep];
    requestParameterToEdit.value = { dataType: propertyParam.parameter.getEnumDataType(), data: propertyParam.assignedValue.data };

    this.serviceExecutionSequence.notifySequenceChanged();
  }

  removeServiceExecution(serviceExecution: ServiceExecutionDirective) {
    this.serviceExecutionSequence.removeServiceExecution(serviceExecution);
    this.serviceExecutionSequence.notifySequenceChanged();
    this.modificationService.serviceExecutionRemoved.emit(serviceExecution);
  }

  showServcieOptions(enabled: boolean) {
    this.serviceOptionsEnabled = enabled;
  }

  prerequisitesEnabled() {
    return (this.dataCategoriesService.activeCategory.id === 'ios' ||
      this.dataCategoriesService.activeCategory.id === 'values') &&
      this.servicePrerequisites.length > 0;
  }

  assignValueToParameterDisabled(propertyParam: PropertyParameterDirective): boolean {
    return propertyParam && propertyParam.owner != null;
  }

  findPropertyParameter(parameter: ParameterData): PropertyParameterDirective {
    return this.serviceExecutionSequence.availablePropertyParameters
      .find(propertyParam => propertyParam.parameter.model.id === parameter.model.id);
  }

  hasResponsePropertyParameters(serviceExecution: ServiceExecutionDirective): boolean {
    return serviceExecution.availableResponsePropertyParameters &&
      serviceExecution.availableResponsePropertyParameters.length > 0;
  }

  hasRequestPropertyParameters(serviceExecution: ServiceExecutionDirective): boolean {
    return serviceExecution.availableRequestPropertyParameters &&
      serviceExecution.availableRequestPropertyParameters.length > 0;
  }

  nagivateToDiagnosticService(diagnosticServiceName: string): void {
    this.navigationService.navigateToDiagnosticService(diagnosticServiceName);
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  // IsPreRequisitreUsedByService(servicePrerequisite: string, serviceExecution: ServiceExecutionDirective): boolean {
  //   const prerequisiteInDatabase = serviceExecution.model.prerequisites.find(x => x.name === servicePrerequisite);
  //   if (prerequisiteInDatabase) {
  //     return true;
  //   }
  //   return false;
  // }

  // prerequisiteSelect(serviceExecution: ServiceExecutionDirective, servicePrerequisite: string, checked: boolean) {
  //   if (checked) {
  //     const prerequisite = new ServicePrerequisite();
  //     prerequisite.name = servicePrerequisite;
  //     serviceExecution.model.prerequisites.push(prerequisite);
  //   } else {
  //     const index = serviceExecution.model.prerequisites.findIndex(item => item.name === servicePrerequisite);
  //     if (index >= 0) {
  //       serviceExecution.model.prerequisites.splice(index, 1);
  //     }
  //   }
  //   this._serviceExecutionSequence.notifySequenceChanged();
  // }
  openDialog(serviceExecution: ServiceExecutionDirective) {
    let dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        fileName: serviceExecution.diagnosticService.name,
      },
    });
    const sub = dialogRef.componentInstance.yesEventEmitter.subscribe(() => {
      this.removeServiceExecution(serviceExecution)
    })
    dialogRef.afterClosed().subscribe(result => {
      dialogRef.componentInstance.yesEventEmitter.unsubscribe();
    });
  }

  canEditCategoryItems(): boolean {
    return this.authService.canEditCategoryItems();
  }

  getUserRole(): string {
    return this.authService.getUserRole();
  }
}
