import { FunctionBase } from 'app/modules/shared/components/function/function-base';
import { FunctionType } from 'app/modules/shared/components/function/functions';
import { BaseProperty, PropertyType } from 'app/modules/shared/model/properties/base-property';
import { OutputProperty } from 'app/modules/shared/model/properties/output-property';

export class FunctionRead extends FunctionBase {

  constructor(enabled: boolean, properties: Array<BaseProperty>) {
    super(enabled, properties);
    this.name = 'Read';
    this.propertyType = PropertyType.Source;
    this.type = FunctionType.Read;
  }

  addProperty(propertyName: string): void {
    const outputProperty = new OutputProperty();
    outputProperty.name = propertyName;
    outputProperty.setServiceExecutionSequenceModelSilent(this.serviceExecutionSequence);
    this.properties.push(outputProperty);
  }

  syncFromModel(): void {
    this.properties.forEach(property => {
      property.setServiceExecutionSequenceModelSilent(this.serviceExecutionSequence);
    });
  }
}
