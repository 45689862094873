/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable  */
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { OdxFile } from 'app/app-model/odx-import/odx-file';
import { OdxImportIssue } from 'app/app-model/odx-import/odx-import-issue';
import { CategoryItemsService } from 'app/app-services/category-items-service';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { OdxImportViewService } from 'app/app-services/odx-import-view.service';
import { OdxService } from 'app/app-services/odx-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { SpecificationVersionValidationService } from 'app/app-services/specification-version-validation.service';
import { ValueConversionService } from 'app/app-services/value-conversion.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { ExportService } from 'app/modules/release/services/export.service';
import { Tree } from 'app/modules/shared/components/tree/model/tree';
import { TreeNode } from 'app/modules/shared/components/tree/model/tree-node';
import { TreeService } from 'app/modules/shared/components/tree/service/tree.service';
import { User } from 'app/modules/shared/model/user';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';

import { Specification } from '../app-model/specification';
import { SpecificationVersion } from '../app-model/specification-version';
import {
  ServerIdentificationCategoryItemComponent,
} from '../data-categories/server-identification/server-identification-category-item';
import { MessageService } from '../modules/shared/services/message-service.service';
import { Category } from '../specification-structure/category-items/category.enum';
import { IvdOverrideService } from 'app/app-services/ivd-override-service';

enum CurrentViewType {
  General,
  Odx,
  Version,
  User,
  TemplateSettings,
  SpecificationSettings
}

@Component({
  selector: 'app-specification-home',
  templateUrl: './specification-home.component.html',
  styleUrls: ['./specification-home.component.css']
})

export class SpecificationHomeComponent implements OnInit, OnDestroy {
  @Output() showReleaseEventEmitter = new EventEmitter<void>();

  @Output() performExportEventEmitter = new EventEmitter<void>();

  @ViewChild('uploadOdxBtn') uploadOdxBtn: ElementRef;

  specificationVersion: SpecificationVersion;
  loadingOdxFile: boolean;
  odxFile: OdxFile;
  odxImportIssues: OdxImportIssue[];
  odxFileSize: number;
  identificationTree: Tree;
  errors = 0;
  importDoneOdxIssuesSubscription: Subscription;
  hasNoIssues: boolean;
  diagnosticProtocol: string;
  serverExecutionMode: string;
  selectedSpecificationSubscription: Subscription;
  statisticsSubscription: Subscription;
  issueChangedSubcription: Subscription;
  serverFileInfoSubscription: Subscription;
  private overviewTabLabel = 'Overview';
  private odxTabLabel = 'Odx';
  private settingsLabel = 'Settings';
  private templateSettingsTabLabel = 'Template Settings';
  private _currentView: CurrentViewType;
  private _specification: Specification;
  private numberOfUsers: User;

  constructor(
    private specificationService: SpecificationService,
    private odxService: OdxService,
    private messageService: MessageService,
    private categoryItemsService: CategoryItemsService,
    private dataCategories: DataCategoriesService,
    private validationService: SpecificationVersionValidationService,
    private odxImportService: OdxImportViewService,
    private exportService: ExportService,
    private valueConversionService: ValueConversionService,
    private statusService: SpecificationStatusService,
    private authService: AuthService,
    private specificationVersionValidationService: SpecificationVersionValidationService,
    private ivdOverrideService: IvdOverrideService
  ) { }

  ngOnInit() {
    this.currentView = CurrentViewType.Version;
    this.odxFile = undefined;
    this.odxImportIssues = undefined;
    this.specification = this.specificationService.currentSpecification;
    this.specificationVersion = this.specificationService.currentSpecificationVersion;

    this.selectedSpecificationSubscription = this.specificationService.selectedSpecificationEventEmitter.subscribe(selectedSpecification => {
      if (this.currentView !== CurrentViewType.Version) {
        this.setVersionView();
      }
      this.whenSpecificationSelected(selectedSpecification);
    });

    this.issueChangedSubcription = this.validationService.issueChangedEventEmitter.subscribe(data => {
      this.errors = data.errors;
    });

    this.importDoneOdxIssuesSubscription = this.odxImportService.model.importDoneOdxIssues.subscribe(
      issues => {
        this.odxImportIssues = issues;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.importDoneOdxIssuesSubscription) {
      this.importDoneOdxIssuesSubscription.unsubscribe();
    }
    if (this.selectedSpecificationSubscription) {
      this.selectedSpecificationSubscription.unsubscribe();
    }
    if (this.statisticsSubscription) {
      this.statisticsSubscription.unsubscribe();
    }
    if (this.serverFileInfoSubscription) {
      this.serverFileInfoSubscription.unsubscribe();
    }
    if (this.issueChangedSubcription) {
      this.issueChangedSubcription.unsubscribe();
    }
  }

  get getReleaseErrorMessage(): string {
    if (this.errors > 0) {
      return 'One or more issues, see issues tab';
    } else if (this.specificationVersion.releaseStatus === 1) {
      return 'Version already released';
    } else {
      return '';
    }
  }

  get isCurrentSpecificationReleased(): boolean {
    return this.statusService.isInRelease(this.specificationService.currentSpecificationVersion);
  }

  get canRelease(): boolean {
    return this.errors <= 0 && this.specificationVersion.releaseStatus === 0;
  }

  get specification(): Specification {
    return this._specification;
  }

  set specification(specification: Specification) {
    this._specification = specification;
  }

  get generalView() {
    return this.currentView === CurrentViewType.General;
  }

  get odxView() {
    return this.currentView === CurrentViewType.Odx;
  }

  get templateSettingsView() {
    return this.currentView === CurrentViewType.TemplateSettings;
  }

  get specificaitonSettingsViewEnabled() {
    return this.currentView === CurrentViewType.SpecificationSettings;
  }

  get versionView() {
    return this.currentView === CurrentViewType.Version;
  }

  get userView() {
    return this.currentView === CurrentViewType.User;
  }

  public get isLegacyVersion(): boolean {
    return this.specificationService.isLegacyVersion;
  }

  public get isTemplateSpecification(): boolean {
    return this.specificationService.isTemplateSpecification;
  }

  public get isNativeVersion(): boolean {
    return this.specificationVersion?.specificationType === 3;
  }

  get serverIdentificationsPreview() {
    const treeService = new TreeService();
    this.identificationTree = new Tree(treeService);

    this.dataCategories.getCategory(Category.Servers).items.forEach(item => {
      const serverIdentificationModel = (item as ServerIdentificationCategoryItemComponent).model;
      const serverIdentificationPreviewContent = `${serverIdentificationModel.diagnosticFamily
        } ${serverIdentificationModel.generation}`;
      const previewNode = new TreeNode(
        serverIdentificationPreviewContent,
        treeService
      );
      this.identificationTree.addRootNode(previewNode);
    });

    return this.identificationTree;
  }

  get currentView(): CurrentViewType {
    return this._currentView;
  }

  get isEditingAllowed(): boolean {
    return this.statusService.isInWork(this.specificationService.currentSpecificationVersion);
  }

  set currentView(currentView: CurrentViewType) {
    this._currentView = currentView;

    if (currentView === CurrentViewType.Odx && this.specificationVersion) {
      this.loadingOdxFile = true;
      this.specificationService
        .getSpecificationVersionOdxImport(this.specificationVersion.id)
        .subscribe(
          odxImport => {
            this.odxImportIssues = odxImport.importIssues;

            this.odxService.getOdxFilePreview(odxImport.odxFileId).subscribe(
              odxFilePreview => {
                this.odxFile = odxFilePreview;
                this.loadingOdxFile = false;
              },
              e => {
                this.loadingOdxFile = false;
                this.messageService.dispatchErrorMessageFromApi(e);
              }
            );
          },
          e => {
            this.odxFile = undefined;
            this.odxImportIssues = undefined;
            this.loadingOdxFile = false;
            this.messageService.dispatchErrorMessageFromApi(e);
          }
        );
    }
  }

  exportWithMain() {
    // first number in the array is the basedOnLabelId (in this case '1', for the main label)
    const exportData: number[] = [1];
    exportData.push(this.specificationVersion.id);
    this.exportService.createGlobalExportNoCritiera(exportData).subscribe(result => {
      FileSaver.saveAs(new Blob([this.valueConversionService.convertToByteArray(result)]), 'export_label_main.zip');
    }, error => {
      this.messageService.dispatchErrorMessageFromApi(error);
    });
  }

  validateServerFile() {
    this.specificationVersionValidationService.validateServerFile(this.specificationVersion.id).subscribe(data => {
      if (data.isValid) {
        this.messageService.dispatchSuccessMessage('The server file is valid.');
      } if (!data.isValid) {
        this.messageService.dispatchErrorMessage('The server file is not valid! <br/><br/>' + data.validationMessage);
      }
    }, e => {
      this.messageService.dispatchErrorMessageFromApi(e);
    });
  }

  uploadTemplateAsOdxFile(event: any) {
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile, 'UTF-8');
    fileReader.onload = () => {
      const odxFile = fileReader.result as string;
      this.odxService.updateDiagnosticSpecificationTemplateFromOdxFile(this.specification.id, odxFile).subscribe(_ => {
        this.messageService.dispatchSuccessMessage('Template successfully updated. Refreshing the page is recommended.');
      });
    };
    this.uploadOdxBtn.nativeElement.value = '';
  }

  getPathAsString(path: string[]) {
    return path ? path.sort().join('\n') : '';
  }

  getAvailableDataCategories() {
    return this.isLegacyVersion
      ? this.dataCategories
        .getDataCategories()
        .filter(dc => dc.hasLegacySupport)
      : this.dataCategories.getDataCategories();
  }

  getDataCategoryId(categoryName: string): string {
    return this.dataCategories.getCategoryByName(categoryName).id;
  }

  setGeneralView() {
    this.currentView = CurrentViewType.General;
  }

  setOdxView() {
    this.currentView = CurrentViewType.Odx;
  }

  setTemplateSettingsLabel() {
    this.currentView = CurrentViewType.TemplateSettings;
  }

  setSpecificationSettingsView() {
    this.currentView = CurrentViewType.SpecificationSettings;
  }

  setUsersView() {
    this.currentView = CurrentViewType.User;
  }

  setVersionView() {
    this.currentView = CurrentViewType.Version;
  }

  editingDone(newName: string): void {
    if (newName !== this.specification.name) {
      const oldName = this.specification.name;
      this.specification.name = newName;

      this.specificationService
        .updateSpecification(this.specification)
        .subscribe(
          p => { },
          e => {
            this.specification.name = oldName;
          }
        );
    }
  }

  showReleasePopup() {
    this.hasNoIssues = true;
    this.specificationService.canReleaseSpecificationVersion(this.specificationVersion.id).subscribe(canRelease => {
      this.hasNoIssues = canRelease;
      if (this.hasNoIssues) {
        this.showReleaseEventEmitter.emit();
      } else {
        this.messageService.dispatchErrorMessage(
          'Cannot release this version due to issues found in the specification. Please check the ISSUES tab, solve the listed issues and try again'
        );
      }
    });
  }

  performExport() {
    this.performExportEventEmitter.emit();
  }

  onImportDone(odxFile: OdxFile) {
    this.odxFile = odxFile;
  }

  onImportDoneOdxIssues(odxImportIssues: OdxImportIssue[]) {
    this.odxImportIssues = odxImportIssues;
  }

  homeTabChanged(tabEvent: MatTabChangeEvent) {
    switch (tabEvent.tab.textLabel) {
      case this.overviewTabLabel:
        this.setVersionView();
        break;
      case this.odxTabLabel:
        this.setOdxView();
        break;
      case this.settingsLabel:
        this.setSpecificationSettingsView();
        break;
      case this.templateSettingsTabLabel:
        this.setTemplateSettingsLabel();
        break;
    }
  }

  onIvdOverrideToggle() {
    this.ivdOverrideService.toggleIvdOverride(this.specificationVersion.id, this.specificationVersion.ivdOverrideEnabled)
      .subscribe(_ => {
        const ivdOverrideDataCategory = this.dataCategories.getCategory(Category.IvdOverrides);
        ivdOverrideDataCategory.enabled = this.specificationVersion.ivdOverrideEnabled;
      });
  }

  private whenSpecificationSelected(selectedSpecification: { specification: Specification, specificationVersion: SpecificationVersion }) {
    if (this.hasValidSpecificationData(selectedSpecification)) {
      this.specification = selectedSpecification.specification;
      this.specificationVersion = selectedSpecification.specificationVersion;

      this.serverFileInfoSubscription = this.specificationService
        .getServerFileInfo(selectedSpecification.specificationVersion.id)
        .subscribe(fileInfo => {
          this.diagnosticProtocol = fileInfo.diagnosticProtocol;
          this.serverExecutionMode = fileInfo.serverExecutionMode;
        });

      // Server identifications must be preloaded in order to show them in the project home general section.
      this.categoryItemsService.getPreviewItems(this.specificationVersion.id, Category.Servers).subscribe(items => {
        const category = this.dataCategories.getCategory(Category.Servers);
        category.setItems(items);
      });
    } else {
      this.specification = undefined;
      this.specificationVersion = undefined;
    }
  }

  private hasValidSpecificationData(selectedSpecification: { specification: Specification, specificationVersion: SpecificationVersion }) {
    return selectedSpecification &&
      selectedSpecification.specification &&
      selectedSpecification.specificationVersion;
  }

  private hasSpecificationVersion(selectedSpecification: { specification: Specification, specificationVersion: SpecificationVersion }) {
    return selectedSpecification && selectedSpecification.specification && selectedSpecification.specificationVersion;
  }

  canEditCategoryItems(): boolean {
    return this.authService.canEditCategoryItems();
  }

  canValidateServerFile(): boolean {
    return this.authService.canValidateServerFile();
  }
}
