<div class="sdds-banner">
  <div class="sdds-banner-body">
    RBAC is Role based access control. You can upload RBACC file here to filter the request to executed to ECU by
    user roles.
  </div>
</div>

<div *ngIf="loadingRbaccs" class="loader-container">
  <div class="loader"></div>
</div>

<div class="sdds-row mt-16">
  <!-- <div class="sdds-col-max-3" *ngIf="loadingRbaccs">
    <mat-spinner></mat-spinner>
  </div> -->
  <div class="sdds-col-max-3" *ngIf="!loadingRbaccs">
    <h2>Rbacc Files</h2>
    <!-- <app-toolbar (filterTextEventEmitter)="viewFilterText = $event" [dataTargetEnabledRemove]="true"
      [addDataTargetEnabled]="true" [createItemId]="'rbacFileSelectorModal'" addDataTarget="#addView"
      [addEventEmitterEnabled]="true" (addEventEmitter)="addViewEventEmitter.emit($event);" [isAllowed]="canEditRbacc()">
    </app-toolbar> -->
    <button class="sdds-btn sdds-btn-secondary sdds-btn-sm mb-10" (click)="addNewRbacFile()"
      [disabled]="!canEditRbacc()"> Add New
      <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon>
      <a href="rbac-files\new-rbac"></a>
    </button>

    <br>
    <!-- <app-toolbar (filterTextEventEmitter)="viewFilterText = $event"
    [addDataTargetEnabled]="true"  [addEventEmitterEnabled]="true"
     (addEventEmitter)="addViewEventEmitter.emit();" [isAllowed]="canEditRbacc()">
    </app-toolbar> -->

    <!-- <app-rbac-file-selector (applySelection)="whenRbacFileUploaded($event)"></app-rbac-file-selector> -->

    <div class="sdds-row w-max">
      <mat-form-field class="w-1/2">
        <mat-label>Search...</mat-label>
        <input matInput type="text" [(ngModel)]="searchInput" (keyup)="search()">
        <button matPrefix mat-icon-button (click)="search()">
          <mat-icon>search</mat-icon>
        </button>
        <button *ngIf="search" matSuffix mat-icon-button (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div class="float-right mx-5">
        <mat-form-field class="w-4/5">
          <mat-label>Search by...</mat-label>
          <mat-select [(ngModel)]="selectedSearch">
            <mat-option *ngFor="let type of searchTypes" [value]="type" (click)="search()">
              {{type}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <mat-radio-group [(ngModel)]="rbacType" (change)="changeFilter()">
      <mat-radio-button [value]="1">
        All Rbacs
      </mat-radio-button>
      <mat-radio-button [value]="2">
        Default Entity
      </mat-radio-button>
      <mat-radio-button [value]="3">
        Project Specific
      </mat-radio-button>
    </mat-radio-group>

    <div style="margin-top: 5px;"></div>
    <div class="content-list-container">
      <app-common-list [list]="rbacItems" [itemTemplate]="itemTemplate" (selectedItemEventEmitter)="selectView($event);"
        [selectedItem]="selectedView"></app-common-list>
    </div>

    <ng-template #itemTemplate let-item>
      <div class="sdds-row w-full">
        <div class="sdds-col-max-10">
          <div class="sdds-headline-07 float-left">{{item.name}}</div>
        </div>
        <div class="sdds-col-max-2" *ngIf="item.defaultEntity">
          <div class="flex flex-wrap justify-center">
            <div
              class="flex justify-center items-center m-1 font-bold py-1 px-2 rounded-full text-white bg-blue-950 border border-blue-950">
              <div class="font-bold leading-none max-w-full flex-initial p-2">Default</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>



  <div *ngIf="rbacIsApproved()" class="sdds-col-max-9">
    <app-rbac-view [rbacItem]="selectedView"></app-rbac-view>
    <div class="mb-16"></div>
  </div>
  <div *ngIf="addOrEditRbac()" class="sdds-col-max-9">
    <app-new-rbac [loadrbacItem]="selectedViewRbacId"></app-new-rbac>
    <div class="mb-16"></div>
  </div>
</div>