import { Location } from '@angular/common';
import { Directive, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';
import { CategoryName } from 'app/app-model/enums';
import { SpecificationService } from 'app/app-services/specification-service';
import { ValuesService } from 'app/app-services/values.service';
import { FunctionBase } from 'app/modules/shared/components/function/function-base';
import { FunctionRead } from 'app/modules/shared/components/function/function-read';
import { FunctionWrite } from 'app/modules/shared/components/function/function-write';
import { FunctionItem } from 'app/modules/shared/components/function/function.item';
import { FunctionType } from 'app/modules/shared/components/function/functions';

@Directive()
export class ValueCategoryItemDirective extends FunctionItem implements OnDestroy {

  @Input()
  isLoading = true;

  valuesService: ValuesService;
  oldName = '';
  serviceName = '';
  // specificationVersionId: number;

  constructor(private specificationService: SpecificationService,
    private locationService: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
    super.categoryType = CategoryName.EcuValue;
  }

  ngOnDestroy(): void {
    super.unsubscribe();
  }

  public notifyItemChanged() {
    if (!this.isSyncingFromMaster) {
      console.log('Ecu value' + this.name + ' modified...Saving changes');

      this.valuesService.updateItem(this.specificationService.currentSpecificationVersion.id, this.model, this.oldName)
        .subscribe(updatedEcuValue => {
          this.model = updatedEcuValue;

          const newUrl = this.router.createUrlTree([], {
            relativeTo: this.activatedRoute,
            queryParams: { itemId: this.model.name }
          }).toString();
          this.locationService.go(newUrl);
          this.getServiceName();
          console.log('Ecu value' + this.name + ' successfully updated');
        }, err => { });
    }
  }

  public getPropertyNameForFunctionType(functionType: FunctionType): string {
    switch (functionType) {
      case FunctionType.Read:
        return 'ReadValue Value';
      case FunctionType.Write:
        return 'WriteValue Value';
      default:
        return this.defaultPropertyName;
    }
  }

  public setIsSyncingFromMasterState() {
    this.isSyncingFromMaster = true;
    this.isSyncReadDone = false;
    this.isSyncWriteDone = false;
  }

  public setName(name: string) {
    this.oldName = this.model.name;
    this.name = name;
    this.model.name = name;
    this.notifyItemChanged();
  }

  public createFunctions() {
    if (!this.functions || this.functions.length === 0) {
      const readFunction = new FunctionRead(false, []);
      readFunction.addProperty(this.getPropertyNameForFunctionType(FunctionType.Read));
      this.readFunctionModifiedSubscription = readFunction.serviceExecutionSequence.modified.subscribe(val => {
        this.notifyItemChanged();
      });

      const writeFunction = new FunctionWrite(false, []);
      writeFunction.addProperty(this.getPropertyNameForFunctionType(FunctionType.Write));
      this.writeFunctionModifiedSubscritpion = writeFunction.serviceExecutionSequence.modified.subscribe(val => {
        this.notifyItemChanged();
      });

      this.functions = [readFunction, writeFunction];
    }
  }

  public createProperties(f: FunctionBase) {
    this.createFunctionProperty(f, this.getPropertyNameForFunctionType(f.type));
  }

  protected syncFromModel() {
    this.setIsSyncingFromMasterState();
    this.id = this.model.id;
    this.name = this.model.name;
    this.oldName = this.name;
    this.isLoading = true;
    this.createFunctions();
    this.syncSequences(this.specificationService.currentSpecificationVersion.id).subscribe(_ => {
      this.updateSyncingFromMasterState();
      this.selectDefaultFunction();
      super.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
      this.specificationVersionId = this.specificationService.currentSpecificationVersion.id;
      this.getServiceName();
    });
  }

  protected createServiceExecutionSequenceIfNeeded(functionType: FunctionType, func: FunctionBase) {
    switch (functionType) {
      case FunctionType.Read:
        if (this.model.readSequence) {
          return;
        }
        this.model.readSequence = new ServiceExecutionSequenceModel();
        func.serviceExecutionSequence.model = this.model.readSequence;
        this.isSyncReadDone = true;
        break;

      case FunctionType.Write:
        if (this.model.writeSequence) {
          return;
        }
        this.model.writeSequence = new ServiceExecutionSequenceModel();
        this.isSyncWriteDone = true;
        func.serviceExecutionSequence.model = this.model.writeSequence;
        break;

      default:
        return;
    }
  }

  protected updateSyncingFromMasterState() {
    this.isSyncingFromMaster = false;
    this.isLoading = false;
  }

  protected subscribeDefaultServcieExecutionSequenceEvents() {
    throw new Error('Method not implemented.');
  }

  private createFunctionProperty(func: FunctionBase, propertyName: string) {
    if (!this.functionHasPropertyWithName(func, propertyName)) {
      func.addProperty(propertyName);
    } else {
      func.syncFromModel();
    }
  }

  public getServiceName() {
    if (this.model.readSequence &&
      this.model.readSequence.executions &&
      this.model.readSequence.executions.length > 0 &&
      this.model.readSequence.executions[0]) {
      this.serviceName = this.model.readSequence.executions[0].service;
      this.fetchEcuIdentifierInUse(this.specificationVersionId, this.serviceName);
    }

    else if (this.model.writeSequence &&
      this.model.writeSequence.executions &&
      this.model.writeSequence.executions.length > 0 &&
      this.model.writeSequence.executions[0]) {
      this.serviceName = this.model.writeSequence.executions[0].service;
      this.fetchEcuIdentifierInUse(this.specificationVersionId, this.serviceName);
    }

    else {
      this.fetchEcuIdentifierInUse(this.specificationVersionId);
    }

  }
}
