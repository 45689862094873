import { Component, EventEmitter, Inject, Output } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent  {
  @Output()
  yesEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  yes() {
    this.yesEventEmitter.emit();
  }
}
