import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { User as OidcUser, UserManager, UserManagerSettings } from 'oidc-client';
import { BehaviorSubject, Observable, of } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { User } from '../../shared/model/user';

export class A implements UserManagerSettings {

}

export enum Roles {
  ADMIN = "Admin",
  SYSTEMOWNER = "System Owner",
  READER = "Reader",
  COLLABORATOR = "Collaborator",
  DCODEUSER = "DcodeUser"
}


//[task-2215-Migrate user authentication from Keycloak to AzureAD]

// export function getClientSettings(baseUrl: string): UserManagerSettings {
//   return {
//      authority: environment.identityUrl,
//     // eslint-disable-next-line @typescript-eslint/naming-convention
//     client_id: 'dcode-user',
//     // eslint-disable-next-line @typescript-eslint/naming-convention
//     redirect_uri: baseUrl + '/auth-callback',
//     // eslint-disable-next-line @typescript-eslint/naming-convention
//     post_logout_redirect_uri: baseUrl,
//     // eslint-disable-next-line @typescript-eslint/naming-convention
//     scope: 'openid profile',
//     filterProtocolClaims: true,
//     loadUserInfo: true,
//     automaticSilentRenew: true,
//     // eslint-disable-next-line @typescript-eslint/naming-convention
//     silent_redirect_uri: baseUrl + '/authentication-callback/silent-refresh.html',
//     // eslint-disable-next-line @typescript-eslint/naming-convention
//     response_type: 'code',
//   };
// }

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  stringifiedData: any;
  userSubject: BehaviorSubject<User> = new BehaviorSubject(null);
  private userManager: UserManager;
  private oidcUser: OidcUser;
  private userRoles: string[];
  jwtToken: string;

  constructor(private router: Router) {

    //[task-2215-Migrate user authentication from Keycloak to AzureAD]
    // this.userManager = new UserManager(getClientSettings(window.location.origin));

    // this.userManager.events.addUserLoaded(user => {
    //   this.setUser(user);
    // });
  }

  isLoggedIn(): boolean {
    const isLoggedIn = this.oidcUser != null && !this.oidcUser.expired;

    if (isLoggedIn) {
      this.setUser(this.oidcUser);
    }

    return isLoggedIn;
  }

  get user(): Observable<User> {
    return this.userSubject.asObservable();
  }

  get redirectUrl() {
    const redirectUrl = localStorage.getItem('redirectUrl');
    const decodedUrl = decodeURI(redirectUrl);
    return decodedUrl;
  }

  set redirectUrl(url: string) {
    localStorage.setItem('redirectUrl', url);
  }

  getClaims(): any {
    return this.oidcUser.profile;
  }


  // setAuthorizationHeaderValue(token : string): string {
  //   debugger;
  //   this.jwtToken = token;
  //   return this.jwtToken;
  // }

  // getAuthorizationHeaderValue(): string {
  //   debugger;
    
  //   if (!this.oidcUser) {
  //     return '';
  //   }

  //   //return `Bearer ${this.oidcUser.id_token}`;
  //   return `Bearer ${this.jwtToken}`;
  // }

  signInSilentCallback() {
    return this.userManager.signinSilentCallback();
  }

  startAuthentication(): Promise<void> {
    return this.userManager.signinRedirect();
  }


//[task-2215-Migrate user authentication from Keycloak to AzureAD]
//[MSAL GUARD now handles the authentication, so no need to check here seperately.]
  // completeAuthentication(): Promise<void> {
  //   return this.userManager.signinRedirectCallback().then(user => {
  //     this.setUser(user);
  //   }).then(() => {
  //     this.router.navigateByUrl(this.redirectUrl);
  //   }, () => {
  //     this.router.navigate(['/authorization-failed']);
  //   });
  // }

  public setUser(user: OidcUser) {
    this.oidcUser = user;
    // const tokenInfo = this.getDecodedAccessToken(user.access_token);
    // const aceessTokenInfo = tokenInfo.resource_access

    //roles are available in app.component.ts
    // this.userRoles = <string[]>aceessTokenInfo['dcode-client']['roles'];

    this.userSubject.next(this.createUser(user));
    this.createUser(user)
  }

  public getUser() {
      return of(this.user);
  }

  public getUserRole() {
    return localStorage.getItem("currentUserRole");
  }

  public checkUserReader() {
    return this.getUserRole() == Roles.READER;
  }

  public checkUserCollaborator() {
    return this.getUserRole() == Roles.COLLABORATOR;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  private createUser(oidcUser: any): User {
    const user = new User();

    if (oidcUser) {
      // if (oidcUser.profile) {
        user.userName = oidcUser.email;
         if (oidcUser.given_name && oidcUser.family_name) {

        if (oidcUser.givenName && oidcUser.surname) {
          user.firstName = oidcUser.given_name;
           user.lastName = oidcUser.family_name;
         
        } else {
          user.firstName = oidcUser.name;
          // this.currentUser.firstName = oidcUser.displayName;
        }
        // user.isAdmin = oidcUser.DcodeAdmin !== undefined;
        user.isAdmin = localStorage.getItem("currentUserRole") !== undefined;
      }
    }
     return user;
  }

  //Only Admin
  canEditIdentificationsGroup(): boolean {
    return (this.getUserRole() == Roles.ADMIN)
  }
  canEditDignosticFamilies(): boolean {
    return (this.getUserRole() == Roles.ADMIN)
  }
  canEditUsers(): boolean {
    return (this.getUserRole() == Roles.ADMIN)
  }
  canCreateExportLabel(): boolean {
    return (this.getUserRole() == Roles.ADMIN)
  }
  canManageTemplate(): boolean {
    return (this.getUserRole() == Roles.ADMIN)
  }
  canApproveRbacc(): boolean {
    return (this.getUserRole() == Roles.ADMIN)
  }

  //Only Admin or System Owner
  canReleaseVersion(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canCreateDeleteSpecification(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canEditSharedFiles(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canEditRbacc(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canEditSCOMMFiles(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canEditServer(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canEditDiagnosticService(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }
  canEditLegacyFile():boolean{
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER)
  }

  //Only Admin or System Owner or collaborator
  canEditCategoryItems(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER) || (this.getUserRole() == Roles.COLLABORATOR)
  }
  CanEditSpecificationVersion(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER) || (this.getUserRole() == Roles.COLLABORATOR)
  }
  canAddVersions(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER) || (this.getUserRole() == Roles.COLLABORATOR)
  }
  canValidateServerFile(): boolean {
    return (this.getUserRole() == Roles.ADMIN) || (this.getUserRole() == Roles.SYSTEMOWNER) || (this.getUserRole() == Roles.COLLABORATOR)
  }

  //Only Admin or System Owner or collaborator or reader
  canEditExportPackage(): boolean {
    return (this.getUserRole() == Roles.ADMIN || this.getUserRole() == Roles.SYSTEMOWNER || this.getUserRole() == Roles.COLLABORATOR || this.getUserRole() == Roles.READER)
  }
  canExportByLabel(): boolean {
    return (this.getUserRole() == Roles.ADMIN || this.getUserRole() == Roles.SYSTEMOWNER || this.getUserRole() == Roles.COLLABORATOR || this.getUserRole() == Roles.READER)
  }
}
