<!-- <mat-icon style="color:black">arrow_back_ios_new</mat-icon> -->
<div class="sdds-row">
    <div *ngIf="showNavbar" class="specification-browser" [ngClass]="{'sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-5 sdds-col-sm-12 sdds-col-xs-12 specification-browser-open': isProjectStructureExpanded && showNavbar, 'specification-browser-close': !isProjectStructureExpanded || !showNavbar}">
        <span >
            <a *ngIf="isProjectStructureExpanded" (click)="expandCollapse()"><mat-icon>arrow_back_ios</mat-icon></a>
            <a *ngIf="!isProjectStructureExpanded" (click)="expandCollapse()"><mat-icon>arrow_forward_ios</mat-icon></a>
        </span>

        <span class="mr-10" *ngIf="isProjectStructureExpanded">
          <app-specification-browser></app-specification-browser>
        </span>
    </div>
    <div [ngClass]="{'sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-9 sdds-col-md-7 sdds-col-sm-12 sdds-col-xs-12': isProjectStructureExpanded && showNavbar, 'sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12': !isProjectStructureExpanded || !showNavbar}">
        <ng-container *ngIf="showNavbar">
            <app-specification-infobar></app-specification-infobar>
        </ng-container>
        <ng-container *ngIf="specification && specificationVersion && (router.url.indexOf('specification/') >= 0)">
            <app-specification-navbar></app-specification-navbar>
        </ng-container>
        <router-outlet></router-outlet>
    </div>
</div>
