import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { SpecificationService } from '../../app-services/specification-service';
import { MessageService } from '../../modules/shared/services/message-service.service';
import { StateChartService } from '../../app-services/state-chart.service';
import { StateChart } from '../../app-model/state-chart/state-chart.model';
import { ServiceInfoModel } from '../../app-model/diagnostic-service/service-info.model';
import { SpecificationVersion } from '../../app-model/specification-version';
import { DcodeGraph } from '../../modules/shared/model/graph/dcode-graph';
import { Node } from '../../modules/shared/model/graph/node';
import { Edge, Link } from '../../modules/shared/model/graph/edge';

@Component({
  selector: 'app-state-chart',
  templateUrl: './state-chart.component.html',
  styleUrls: ['./state-chart.component.css']
})

export class StateChartComponent implements OnInit {
  selectedChart: StateChart;
  charts: StateChart[];
  loading = false;
  specificationVersion: SpecificationVersion;
  dcodeGraph: DcodeGraph;
  filterText: string;
  constructor(
    private messageService: MessageService,
    private specificationService: SpecificationService,
    private stateChartService: StateChartService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.specificationService.selectedSpecificationEventEmitter.subscribe(specification => {
      if (specification.specificationVersion) {
        this.specificationVersion = specification.specificationVersion;
        this.stateChartService.getAll(specification.specificationVersion.id).subscribe(data => {
          this.charts = data;
          this.charts.forEach(chart => {
            chart.id = chart.odxId;
          });

          this.loading = false;
          this.activatedRoute.queryParams.subscribe((x: Params) => {
            this.selectedChart = this.charts.find(c => c.odxId === x['itemId']);
            this.createGraph(this.selectedChart);
          });
        }, error => {
          this.loading = false;
          this.messageService.dispatchErrorMessageFromApi(error);
        });
      }
    });
  }

  onSelect(chart: StateChart) {
    this.router.navigate(['.'], { queryParams: { itemId: chart.odxId }, relativeTo: this.activatedRoute });
  }

  serviceShortNames(servcies: ServiceInfoModel[]) {
    if (!servcies) {
      return '';
    }
    return servcies.map(x => x.name).join(', ').toString();
  }

  createGraph(chart: StateChart) {
    if (!chart) {
      return;
    }

    const dcodeGraph = new DcodeGraph();

    chart.states.forEach(x => {
      dcodeGraph.addNode(new Node(x.odxId, x.shortName, x.start ? 1 : 0));
    });

    chart.transitions.forEach(x => {
      const source = dcodeGraph.nodes.find(n => n.name === x.sourceState.shortName);
      const target = dcodeGraph.nodes.find(n => n.name === x.targetState.shortName);
      const edge = new Edge(source, target);
      dcodeGraph.addEdge(edge);
    });

    chart.transitions.forEach(t => {

      const foundEdge = dcodeGraph.edges.find(e => e.source.odxId === t.sourceState.odxId && e.target.odxId === t.targetState.odxId);

      if (foundEdge) {
        t.services.forEach(s => {
          foundEdge.addLink(new Link(s.name, 'specification/' + this.specificationVersion.specification.id + '/' + this.specificationVersion.id + '/services?itemId=' + s.name));
        });
      }
    });

    this.dcodeGraph = dcodeGraph;
  }

  chartTypeToText(chartType: number) {
    if (chartType === 1) {
      return 'Security';
    }
    if (chartType === 2) {
      return 'Session';
    }

    return '';
  }
}
