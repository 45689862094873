<div class="sdds-col-max-12" style=" margin: 20px;">
    <div style="margin-top:20px; ">
        <h3>Add a New Rbacc File</h3>
    </div>
</div>
<br />
<div class="sdds-col-max-12" style="padding: 15px;">
    <form id="newRbacForm">
        <div>
            <div class="divRow">
                <div class="divCell">
                    <label class="btn btn-primary">
                        <h3 *ngIf="!isFileUploaded && selectedRbacFileName==='no file selected'"
                            class="form-horizontal">Select RBACC File</h3>
                        <h3 *ngIf="selectedRbacFileName!=='no file selected'" class="form-horizontal">Selected RBACC
                            File</h3>
                    </label>
                </div>
                <div class="divCell" style="width: 600px;">
                    <button *ngIf="selectedRbacFileName==='no file selected'"
                        class="sdds-btn sdds-btn-primary sdds-btn-sm" id="rbacFileSelectorModal">
                        select file
                    </button>
                    <div *ngIf="selectedRbacFileName!=='no file selected'">
                        <label> {{selectedRbacFileName}}</label>
                        <button type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm float-right"
                            [disabled]="(rbacToSave && rbacToSave.rbacGuid===undefined)" (click)="downloadRbacFile()">
                            Download Rbacc </button>
                    </div>

                    <app-rbac-file-selector (applySelection)="whenRbacFileUploaded($event)"></app-rbac-file-selector>
                </div>
                <label *ngIf="rbacToSave" style="padding-left: 5px;">
                    <h4> State : {{ getCurrentRbaccState()}} </h4>
                </label>
            </div>
            <br>
            <div class="divRow">
                <div class="divCell">&nbsp;</div>

                <div class="divCell float-right">
                    <div class="sdds-checkbox-item">

                        <label class="sdds-form-label ml-1">
                            <input type="checkbox" class="sdds-form-input" id="defaultEntity"
                                [checked]="defaultEntityChecked" (change)="onDefaultEntityCheckboxChange($event)"
                                [disabled]="selectedRbacFileName === ''">
                            Is Default Entity
                        </label>
                    </div>
                    <div *ngIf="defaultEntityChecked">Default entity flag can't connected to any diagnostic
                        specification.</div>

                </div>

            </div>
            <br>
            <div class="divRow">
                <div class="divCell">
                    <h3 class="form-horizontal">ECU Family</h3>
                </div>
                <div class="divCell">
                    <div class="sdds-dropdown sdds-dropdown-small">
                        <select (change)="onFamilyChange()" [(ngModel)]="SelectedFamilyId" name="familyName"
                            [disabled]="makeDisable">
                            <option *ngFor="let family of availablefamilies" value={{family.id}}>
                                {{family.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <br>
            <div class="divRow">
                <div class="divCell">
                    <h3 class="form-horizontal">Generation(s)</h3>
                </div>
                <div class="divCell">
                    <div class="sdds-dropdown sdds-dropdown-small">
                        <form [formGroup]="form">
                            <label formArrayName="genCheckboxes"
                                *ngFor="let gen of generationFormArray.controls; let i = index">
                                <div class="float-left m-5">
                                    <input type="checkbox" [formControlName]="i" id="{{generations[i].name}}"
                                        name="{{generations[i].name}}" (click)="onCheckboxItemChange($event)"
                                        style="size: 10px;margin: 3px;">
                                    {{generations[i].name}}<br>
                                </div>

                            </label>
                        </form>
                    </div>
                </div>
            </div>
            <br>
            <div class="divRow">
                <div class="divCell">
                    <h3 class="form-horizontal">ECU Diagnostic Protocol</h3>
                </div>
                <div class="divCell">
                    <div class="sdds-dropdown sdds-dropdown-small">
                        <select (change)="onProtocolChange($event)" [(value)]="SelectedProtocolId" name="protocolName">
                            <option *ngFor="let protocol of diagnosticProtocolNames  | keyvalue"
                                value="{{protocol.key}}">
                                {{protocol.value }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <br>
            <div class="divRow">
                <div class="divCell">
                    <h3 class="form-horizontal">ECU For Sec GW Sec Diag </h3>
                </div>
                <div class="divCell">
                    <div class="sdds-dropdown sdds-dropdown-small">
                        <select (change)="onSecurityTypeChange()" [(ngModel)]="SelectedDiagnosticSecurityTypeId"
                            name="protocolName">
                            <option *ngFor="let securityType of ecuDiagnosticSecurityTypeNames  | keyvalue"
                                value="{{securityType.key}}">
                                {{securityType.value }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <br>
            <div class="divRow">
                <div class="divCell">
                    <h3 class="form-horizontal">ECU Flashable</h3>
                </div>
                <div class="divCell">
                    <div class="sdds-dropdown sdds-dropdown-small">

                        <select (change)="onECUTypeChange()" [(ngModel)]="SelectedRbaccEcuTypeId" name="protocolName">
                            <option *ngFor="let ecuType of ecuTypeNames  | keyvalue" value="{{ecuType.key}}">
                                {{ecuType.value }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="divRow">
            <div class="divCell float-left">
                <button type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm"
                    [disabled]="(!isValid || isFileUploaded)" (click)="addRbaccInfo()"> Upload </button>

                <button *ngIf="isFileUploaded" type="button" class="sdds-btn sdds-btn-secondary sdds-btn-sm"
                    [disabled]="(!isValid || !isRbacInWork())" (click)="UpdateRbaccInfo()"> Update </button>

            </div>

            <div class="float-left" style="width: 330px;">
                <button type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm"
                    [disabled]="submitRbaccForReviewDisabled()" (click)="submitRbaccInfoForReview()">Submit for
                    review</button>
            </div>
            <div class="float-right">
                <button type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm" (click)="resetAll()"
                    [disabled]="selectedRbacGUID!==''"> Reset All </button>
            </div>
        </div> <br>
        <div class="divRow" *ngIf="canApproveRbacc() && isRbacSubmitted()">
            <div class="divCell">
                &nbsp;
            </div>
            <div class="divCell">
                <button type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm float-left"
                    (click)="rejectRbacc()">Reject </button>
                <button type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm float-right"
                    (click)="approveRbacc()">Approve</button>
            </div>

        </div>

    </form>

</div>