import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryItemsService } from 'app/app-services/category-items-service';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { Subject, Subscription } from 'rxjs';

import { Specification } from '../app-model/specification';
import { SpecificationVersion } from '../app-model/specification-version';
import { SpecificationVersionTreeNode } from '../modules/shared/components/tree/model/specification-version-tree-node';
import { Tree } from '../modules/shared/components/tree/model/tree';
import { TreeService } from '../modules/shared/components/tree/service/tree.service';
import { MessageService } from '../modules/shared/services/message-service.service';
import { SpecificationType } from 'app/app-model/enums';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-specification-versions',
  templateUrl: './specification-versions.component.html',
  styleUrls: ['./specification-versions.component.css']
})
export class SpecificationVersionsComponent implements OnInit, OnDestroy {
  @ViewChild('versionReleaseModalRef', { static: true })
  versionReleaseModalRef: ElementRef;

  @Output()
  specificationVersionSelectedEventEmitter: EventEmitter<SpecificationVersion> = new EventEmitter<SpecificationVersion>();

  @Output()
  specificationVersionsEventEmitter: EventEmitter<SpecificationVersion[]> = new EventEmitter<SpecificationVersion[]>();

  @Output()
  showReleaseEventEmitter = new EventEmitter<void>();

  versionTree: Tree;
  specification: Specification;
  loading: boolean;
  versions: SpecificationVersion[];
  latestVersion: SpecificationVersion;
  openModalEventEmitter: EventEmitter<void> = new EventEmitter<void>();
  storedSpecificationVersion: SpecificationVersion;
  specificationSubscription: Subscription;
  specificationVersionRemoveSubscription: Subscription;
  specificationUpdatedSubscription: Subscription;
  specificationName: string;
  releaseModalClose: Subject<void> = new Subject();
  releaseModalCloseSubscription: Subscription;

  private _specificationId: number;
  private _selectedSpecificationVersion: SpecificationVersion;

  constructor(
    private categoryItemsService: CategoryItemsService,
    private dataCategoriesService: DataCategoriesService,
    private specificationService: SpecificationService,
    private router: Router,
    private messageService: MessageService,
    private locationService: Location,
    private authService: AuthService) { }

  ngOnInit() {
    this.specificationUpdatedSubscription = this.specificationService.specificationUpdatedEventEmitter.subscribe(
      () => {
        this.refresh();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.specificationVersionRemoveSubscription) {
      this.specificationVersionRemoveSubscription.unsubscribe();
    }

    if (this.releaseModalCloseSubscription) {
      this.releaseModalCloseSubscription.unsubscribe();
    }

    if (this.specificationUpdatedSubscription) {
      this.specificationUpdatedSubscription.unsubscribe();
    }
  }

  get doYouWantToDeleteMessage() {
    return this.storedSpecificationVersion
      ? 'Do you want to delete specification version ' +
      this.storedSpecificationVersion.name +
      '?'
      : '';
  }

  get specificationId(): number {
    return this._specificationId;
  }

  @Input()
  set specificationId(specificationId: number) {
    if (this._specificationId !== specificationId) {
      this._specificationId = specificationId;
      this.selectedSpecificationVersion = null;
      this.refresh(false);
    }
  }

  @Input()
  set selectedSpecificationVersion(specificationVersion: SpecificationVersion) {
    this.selectSpecificationVersionTreeNode(specificationVersion);
    this._selectedSpecificationVersion = specificationVersion;
  }

  get selectedSpecificationVersion(): SpecificationVersion {
    return this._selectedSpecificationVersion;
  }

  get versionIsLegacy(): boolean {
    const specVersions = this.specificationService.currentSpecification
      .specificationVersions;
    if (specVersions) {
      this.latestVersion = this.specificationService.currentSpecification.specificationVersions[
        specVersions.length - 1
      ];
      return this.latestVersion.specificationType === SpecificationType.Legacy ? true : false;
    }

    return undefined;
  }

  get versionIsreleased(): boolean {
    const specVersions = this.specificationService.currentSpecification
      .specificationVersions;
    if (specVersions) {
      this.latestVersion = this.specificationService.currentSpecification.specificationVersions[
        specVersions.length - 1
      ];
      return this.latestVersion.releaseStatus === 1;
    }

    return undefined;
  }

  get anyInWorkVersions(): boolean {
    const inWorkVersions = this.versions
      ? this.versions.filter(v => v.releaseStatus === 0)
      : [];
    return inWorkVersions.length > 0;
  }

  buildVersionTree(specification: Specification) {
    let versions = [];

    specification.specificationVersions.forEach(v => {
      this.getVersions(v, versions);
    });

    versions = versions.sort(
      (a: SpecificationVersion, b: SpecificationVersion) => +a.name - +b.name);

    const treeService = new TreeService();

    const tree = new Tree(treeService);
    versions.reverse().forEach((specVersion: SpecificationVersion) => {
      const navigateAction = (specificationVersion: SpecificationVersion) =>
        this.navigateToSelectedSpecification(specificationVersion);
      const deleteAction = (specificationVersion: SpecificationVersion) =>
        this.deleteSpecificationVersion(specificationVersion);
      const releaseAction = (specificationVersion: SpecificationVersion) =>
        this.releaseSpecificationVersion(specificationVersion);
      const canOpen = () => true;
      const canDelete = () =>
        versions.length > 1;

      const vTreeNode = new SpecificationVersionTreeNode(
        specVersion,
        navigateAction,
        deleteAction,
        releaseAction,
        canOpen,
        canDelete,
        treeService,
        this.specificationService,
        this.messageService
      );

      tree.addRootNode(vTreeNode);
    });

    this.releaseModalCloseSubscription = this.releaseModalClose.subscribe(
      () => {
        tree.rootNodes.forEach(treeNode => {
          if (treeNode instanceof SpecificationVersionTreeNode) {
            treeNode.onReleaseModalClose();
          }
        });
      }
    );

    tree.selectFirstNode();
    this.versionTree = tree;
  }

  getVersions(specificationVersion: SpecificationVersion, versions: SpecificationVersion[]) {
    versions.push(specificationVersion);

    if (specificationVersion.children) {
      specificationVersion.children.forEach(c => {
        this.getVersions(c, versions);
      });
    }
  }

  navigateToSelectedSpecification(specificationVersion: SpecificationVersion) {
    this.dataCategoriesService.resetCategories(true);
    this.categoryItemsService.resetCategories();
    this.router.navigate([
      '/specification',
      this.specification.id,
      specificationVersion.id
    ]);
  }

  deleteStoredSpecificationVersion() {
    this.loading = true;

    this.specificationVersionRemoveSubscription = this.specificationService
      .deleteSpecificationVersion(this.storedSpecificationVersion)
      .subscribe(
        () => {
          this.selectedSpecificationVersion = null;
          this.refresh();
          this.loading = false;
        },
        e => {
          this.loading = false;
        }
      );
  }

  deleteSpecificationVersion(specificationVersion: SpecificationVersion) {
    this.storedSpecificationVersion = specificationVersion;
    this.openModalEventEmitter.emit();
  }

  releaseSpecificationVersion(specificationVersion: SpecificationVersion) {
    this.specificationService
      .canReleaseSpecificationVersion(specificationVersion.id)
      .subscribe(canRelease => {
        if (canRelease && this.specificationService.isRbacConnected) {
          this.specificationService.setCurrentSpecification(
            specificationVersion.specification,
            specificationVersion
          );
          this.versionReleaseModalRef.nativeElement.click();
          this.showReleaseEventEmitter.emit();
        } if (!canRelease) {
          this.messageService.dispatchErrorMessage(
            'Cannot release this version due to issues found in the specification. Please check the ISSUES tab, solve the listed issues and try again'
          );
        }
        if (!this.specification.isTemplate && !this.specificationService.isRbacConnected) {
          this.messageService.dispatchErrorMessage(
            'Cannot release this version. Please connect Rbacc file to the specification'
          );
        }
        this.specificationService.notifyReleaseCheckDone();
      });
  }


  refresh(openlatest: boolean = true) {
    this.loading = true;

    if (this.specificationSubscription) {
      this.specificationSubscription.unsubscribe();
    }

    this.specificationSubscription = this.specificationService
      .getSpecification(this.specificationId)
      .subscribe(
        specification => {
          this.specification = specification;
          this.specificationName = specification.name;
          this.versions = specification.specificationVersions;
          this.specificationVersionsEventEmitter.emit(specification.specificationVersions);
          this.specificationService.currentSpecification = specification;

          if (openlatest) {
            this.selectedSpecificationVersion = this.specificationService.openlatestVersionOfCurrentSpecification();
            this.locationService.go(`specification/${this.specification.id}/${this.selectedSpecificationVersion.id}`);
          }

          this.buildVersionTree(specification);
          this.selectedTreeNodeSubscribe();
          this.selectSpecificationVersionTreeNode(this.selectedSpecificationVersion);

          this.loading = false;
        },
        e => {
          this.loading = false;
        }
      );
  }

  filter(text: string): void {
    if (this.versionTree) {
      this.versionTree.showTreeNodesThatContainsText(text);
    }
  }

  collapseTree() {
    if (this.versionTree) {
      this.versionTree.collapseEntireTree();
    }
  }

  expandTree() {
    if (this.versionTree) {
      this.versionTree.expandEntireTree();
    }
  }

  newVersionCreated(specificationVersion: SpecificationVersion) {
    this.selectedSpecificationVersion = specificationVersion;
    this.specificationService
      .getSpecification(this.specificationService.currentSpecification.id)
      .subscribe(specification => {
        this.specificationService.currentSpecification = specification;
        this.refresh();
      });
    this.dataCategoriesService.resetCategories(true);
    this.categoryItemsService.resetCategories();
    this.router.navigate([
      '/specification',
      this.specification.id,
      specificationVersion.id
    ]);
  }

  onAppReleaseClose() {
    this.releaseModalClose.next();
  }

  private selectedTreeNodeSubscribe() {
    this.versionTree.selectedTreeNodeEventEmitter.subscribe(
      (versionTreeNode: SpecificationVersionTreeNode) => {
        if (versionTreeNode) {
          this._selectedSpecificationVersion = versionTreeNode.specificationVersion;
          this.specificationVersionSelectedEventEmitter.emit(
            versionTreeNode.specificationVersion
          );
        }
      },
      () => {
        this.selectedSpecificationVersion = null;
        this.specificationVersionSelectedEventEmitter.emit(null);
      }
    );
  }

  private selectSpecificationVersionTreeNode(specificationVersion: SpecificationVersion) {
    if (specificationVersion && this.versionTree) {
      this.versionTree.selectFirstTreeNodeThatMatchesPredicate(
        t =>
          t instanceof SpecificationVersionTreeNode &&
          t.specificationVersion.id === specificationVersion.id
      );
    }
  }
  canEditCategoryItems(){
    return this.authService.canEditCategoryItems();
  }
}
