<div class="sdds-row" [ngClass]="{'loading':model.loading}">
    <div *ngIf="!isImportAllowed"
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <div class="sdds-banner">
            <div class="sdds-banner-body">
                {{importNotAllowedReason}}
            </div>
        </div>
    </div>

    <div *ngIf="isImportAllowed && !model.loading"
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <div class="sdds-row">
            <div
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-12">
                <button class="sdds-btn sdds-btn-primary sdds-btn-sm mr-5" id="odxFileSelectorModal" [disabled]="!canEditCategoryItems()">
                  Select a new ODX File
                </button>
                <app-odx-file-selector-dialog (applySelection)="whenOdxFileSelected($event)"></app-odx-file-selector-dialog>
            </div>
        </div>

        <ng-container *ngIf="odxUpdateAffectedCategoryItem">
            <app-import-preview [odxUpdateAffectedCategoryItem]="odxUpdateAffectedCategoryItem"></app-import-preview>
        </ng-container>

        <!-- <div class="sdds-row" *ngIf="!importedODXFile">
            <div *ngIf="model.odxFile"
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-12">
                <div class="sdds-banner">
                    <div class="sdds-banner-body">
                        Select a layer from below and press on the "Import" button to proceed.
                    </div>
                </div>
            </div>

            <div *ngIf="model.odxFile"
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Layer Type</th>
                            <th>Short Name</th>
                            <th>Long Name</th>
                            <th>Services</th>
                            <th>Charts</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let layer of model.layers" (click)="model.setLayer(layer)"
                            [class.active]="model.selectedLayer && layer.shortName === model.selectedLayer.shortName">
                            <td>{{layer.type}}</td>
                            <td>{{layer.shortName}}</td>
                            <td>{{layer.longName}}</td>
                            <td>{{layer.serviceCount}}</td>
                            <td>{{layer.stateChartCount}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="model.odxLayer"
                class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-12">
                <div class="sdds-row">
                    <div
                        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                        <h4>{{model.odxLayer.longName}}</h4>
                    </div>

                    <div
                        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                        <p>{{model.odxLayer.desc}}</p>
                    </div>

                    <div
                        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                        <h5>Layer Issues</h5>
                    </div>

                    <div
                        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-12">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Service</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of model.pageItems; let i=index">
                                    <td>{{(model.currentPage-1)*model.pageSize + i + 1}}</td>
                                    <td>{{item.serviceName}}</td>
                                    <td>{{item.message}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div
                        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                        <ul class="pagination">
                            <li *ngFor="let page of model.pages" [ngClass]="{'active': (model.currentPage ===page)}">
                                <a role="button" (click)="model.setIssuePage(page) ">{{page}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->
    </div>

    <!-- <div *ngIf="isImportAllowed && model.odxFile && !importedODXFile"
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding mb-12">
        <button type="button" class="sdds-btn sdds-btn-primary sdds-btn-sm"
            [disabled]="!model.odxLayer || model.loading" (click)="model.importSelectedOdxLayer()">Import</button>
    </div> -->
</div>
