<div class="sdds-card mr-4">
    <div class="sdds-card-header">
        <h5 class="sdds-card-headline">Parameter Info</h5>
    </div>

    <div class="sdds-card-body mt-16">
        <div class="sdds-row mb-16">
            <div
                class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                <label for="parameterTypeSelector">Type</label>
            </div>

            <div
                class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-dropdown sdds-dropdown-small sdds-on-white-bg">
                <select name="parameterTypeSelector" [(ngModel)]="parameter.type" (change)="parameterTypeChanged.next()"
                    [disabled]="isOdxBasedSpecification || !isAllowed">
                    <option *ngIf="!parameter.type" selected value></option>
                    <option *ngFor="let parameterType of parameterTypes">{{parameterType}}</option>
                </select>
            </div>
        </div>

        <div class="sdds-row" *ngIf="isValueParameter || isReservedParameter">
            <div
                class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                <label for="parameterName">Name</label>
            </div>

            <div
                class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-on-white-bg">
                <input name="parameterName" class="sdds-textfield-input-md" type="text" [(ngModel)]="paramName"
                    (change)="parameterChanged.next()" [readonly]="isOdxBasedSpecification || !isAllowed">
            </div>
        </div>

        <div class="sdds-row" *ngIf="isConstantParameter">
            <div
                class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-10">
                <label for="parameterName">Name</label>
            </div>

            <div
                class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-on-white-bg">
                <input name="parameterName" class="sdds-textfield-input-md" type="text" [(ngModel)]="paramName"
                    (change)="parameterChanged.next()" [readonly]="isOdxBasedSpecification || !isAllowed">
            </div>

            <div
                class="sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 mt-12">
                <label for="parameterRawVal">Value</label>
            </div>

            <div
                class="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8 sdds-on-white-bg mt-10">
                <input name="parameterRawVal" class="sdds-textfield-input-md" type="text" [value]="paramRawVal"
                    (focusout)="setParameterValueFromInput($event.target.value)"
                    [readonly]="isOdxBasedSpecification || !isAllowed">
            </div>
        </div>
    </div>
</div>
