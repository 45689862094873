<div *ngIf="rbacItem" class="sdds-col-max-12">
    <div class="flex flex-wrap mt-16 p-5" style="border-style: solid; border-width: 0px 0px 0px 5px; border-color:#2B70D3;">
      <div>
        <p class="sdds-headline-05 mt-15">File Name:</p>
        <p class="sdds-body-01 sdds-text-grey-700 mt-5 mr-14">{{rbacItem.name}}</p>
      </div>
      <div class="mr-16">
        <p class="sdds-headline-05 mt-15">Type:</p>
        <p class="sdds-body-01 sdds-text-grey-700 mt-5">{{rbacItem.defaultEntity ? 'Default Entity' : 'Project Specific'}}</p>
      </div>
      <div class="mr-16">
        <p class="sdds-headline-05 mt-15">Rbacc Id:</p>
        <p class="sdds-body-01 sdds-text-grey-700 mt-5">{{rbacItem.rbacGuid}}</p>
      </div>
      <div class="mr-16">
        <p class="sdds-headline-05 mt-15">Upload date:</p>
        <p class="sdds-body-01 sdds-text-grey-700 mt-5">{{rbacItem.uploadDate|date:'medium'}}</p>
      </div>
      <div class="w-auto">
        <button class="sdds-btn sdds-btn-primary sdds-btn-sm mt-10 opacity-90" (click)="downloadFile()">
          <div class="flex">
            <div class="flex-initial mb-5">
              <mat-icon>download</mat-icon>
            </div>
            <div class="flex-initial">
              Download file
            </div>
          </div>
        </button>
      </div>
      <div class="w-auto ml-10">
        <button class="sdds-btn sdds-btn-primary sdds-btn-sm mt-10 opacity-90" [routerLink]="['./history']">
          <div class="flex">
            <div class="flex-initial mb-5">
              <mat-icon>history</mat-icon>
            </div>
            <div class="flex-initial">
              Changes
            </div>
          </div>
        </button>
      </div>
    </div>
    <br />
    <div *ngIf="rbacItem.defaultEntity">
        <div class="flex-initial">
          <h3 class="sdds-headline-07 mt-20">Linked ECU Generation(s)</h3>
          <br />
          <button class="sdds-btn sdds-btn-secondary sdds-btn-sm opacity-90" (click)="enableAddNewGeneration()">
            <div class="flex">
              <div class="flex-initial">
                <mat-icon class="-mt-3">add</mat-icon>
              </div>
              <div class="flex-initial">
                Add a new ECU generation
              </div>
            </div>
          </button>
        </div>
        <div *ngIf="addNewGenerationEnabled">
          <div class="sdds-row -ml-16 mt-20">
              <div class="sdds-col-max">
                  <div class="sdds-dropdown sdds-dropdown-small">
                      <select (ngModelChange)="familyChanged($event)" [(ngModel)]="selectedFamily">
                          <ng-container *ngIf="families">
                              <option *ngFor="let family of families" [ngValue]="family">
                                  {{family.name}}
                              </option>
                          </ng-container>
                      </select>
                  </div>
              </div>
              <div class="sdds-col-max">
                  <div class="sdds-dropdown sdds-dropdown-small">
                      <select (ngModelChange)="generationChanged($event)" [(ngModel)]="selectedGeneration">
                          <ng-container *ngIf="generations">
                              <option *ngFor="let generation of generations" [ngValue]="generation">{{ generation.name }}
                              </option>
                          </ng-container>
                      </select>
                  </div>
              </div>
              <div class="sdds-col-max">
                  <button class="sdds-btn sdds-btn-primary sdds-btn-sm mt-5 opacity-90" (click)="addGeneration()">
                      Link to ECU generation
                  </button>
              </div>
            </div>
        </div>

    <div class="flex flex flex-wrap" *ngIf="rbacItem.connectedGenerations">
      <div class="sdds-card sdds-col-max-3 mr-10 mt-14 mb-2 sdds-background-blue-50"
        *ngFor="let item  of rbacItem.connectedGenerations">
        <div class="float-right mt-8">
          <button id="deleteBtn-spec" class="sdds-btn sdds-btn-ghost sdds-btn-sm"
            matTooltip="Remove linked item from Rbacc file" (click)="removeConnectedGeneration(item.generationId)"
            [disabled]="!canEditRbacc()">
            <mat-icon class="md-18">close</mat-icon>
          </button>
        </div>

                <div class="flex">
                  <div class="flex-initial">
                    <mat-icon class="mt-5 mr-10">description</mat-icon>
                  </div>
                  <div class="flex-initial">
                    <p class="sdds-headline-06 mt-10">ECU Generation</p>
                  </div>
                </div>
                <div class="flex justify-center mb-5">
                  <p class="sdds-headline-04 sdds-text-grey-700">{{item.familyName}} - {{item.generationName}}</p>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div *ngIf="!rbacItem.defaultEntity">
        <div class="sdds-col-max-12 mt-20 -ml-20">
            <h3>Linked diagnostic specifications</h3>
            <br />
            <div class="flex-initial ml-5">
              <button class="sdds-btn sdds-btn-secondary sdds-btn-sm opacity-90" (click)="enableAddNewSpecification()"
              [disabled]="!canEditRbacc()">
                <div class="flex">
                  <div class="flex-initial">
                    <mat-icon class="-mt-3">add</mat-icon>
                  </div>
                  <div class="flex-initial">
                    Add a new specification
                  </div>
                </div>
              </button>
            </div>

      <div *ngIf="addNewSpecificationEnabled" class="mt-20 mb-10 -ml-26">
        <div class="w-2/6">
          <app-specification-selector #specificationSelector multiSelect="true"
            [selectedSpecifications]="selectedSpecifications"></app-specification-selector>
        </div>

        <div class="ml-10">
          <button class="align-bottom sdds-btn sdds-btn-primary sdds-btn-sm mt-5 opacity-90"
            (click)="connectSelectedSpecifications()" [disabled]="!canEditRbacc()">
            <div class="flex">
              <div class="flex-initial mr-5 -mt-3">
                <mat-icon class="md-18">link</mat-icon>
              </div>
              <div class="flex-initial">
                Link to selected specification(s)
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex flex-wrap" *ngIf="rbacItem.connectedSpecificationVersions">
      <div class="sdds-card sdds-background-blue-50 sdds-col-max-3 mr-10 mt-14 mb-2"
        *ngFor="let spec  of rbacItem.connectedSpecificationVersions">
        <div class="float-right mt-8 mb-2">
          <button id="deleteBtn-spec" class="sdds-btn sdds-btn-ghost sdds-btn-sm"
            matTooltip="Remove linked item from Rbacc file"
            (click)="removeConnectedSpecification(spec.specificationVersionGuid)" [disabled]="!canEditRbacc()">
            <mat-icon class="md-18">close</mat-icon>
          </button>
        </div>

        <div class="flex">
          <div class="flex-initial">
            <mat-icon class="mt-5 mr-10">description</mat-icon>
          </div>
          <div class="flex-initial">
            <p class="sdds-headline-06 mt-10">Specification</p>
          </div>
        </div>
        <div class="mt-5">
          <a class="sdds-detail-04 sdds-text-grey-700"
            [routerLink]="['/specification', spec.specificationId, spec.specificationVersionId]">
            {{spec.specificationName}}
            <mat-icon class="md-18 ml-5">open_in_new</mat-icon>
          </a>
          <p class="sdds-detail-04 sdds-text-grey-700">Version {{spec.specificationVersionName}}</p>
        </div>
      </div>
    </div>
  </div>
</div>