import { FunctionBase } from 'app/modules/shared/components/function/function-base';
import { FunctionType } from 'app/modules/shared/components/function/functions';
import { BaseProperty, PropertyType } from 'app/modules/shared/model/properties/base-property';
import { InputProperty } from 'app/modules/shared/model/properties/input-property';

export class FunctionControl extends FunctionBase {

  constructor(enabled: boolean, properties: Array<BaseProperty>) {
    super(enabled, properties);
    this.name = 'Control';
    this.propertyType = PropertyType.Target;
    this.type = FunctionType.Control;
  }

  addProperty(propertyName: string): void {
    const inputProperty = new InputProperty();
    inputProperty.name = propertyName;
    inputProperty.setServiceExecutionSequenceModelSilent(this.serviceExecutionSequence);
    this.properties.push(inputProperty);
  }

  syncFromModel(): void {
    this.properties.forEach(property => {
      property.setServiceExecutionSequenceModelSilent(this.serviceExecutionSequence);
    });
  }
}
