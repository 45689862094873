import { Component, OnInit, Input } from '@angular/core';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { LengthKind, TerminationType } from 'app/app-model/diagnostic-service/length.model';

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.css']
})
export class ParameterComponent {
  @Input()
  parameter: ParameterData;

  @Input()
  isAllowed: boolean;

  get hasMinMaxLength(): boolean {
    return this.parameter.model.length.kind === LengthKind.MinMax;
  }

  get termination(): string {
    return TerminationType[this.parameter.model.length.termination];
  }
}
