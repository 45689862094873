import { environment } from 'environments/environment';

import { Injectable, NgZone } from '../../../../node_modules/@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject } from '../../../../node_modules/rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpecificationStatusHubService {

  zone: NgZone;
  connected = false;
  odxFileCreatedEvent: BehaviorSubject<{ taskId: string; odxFileId: string }> = new BehaviorSubject({ taskId: '', odxFileId: '' });
  getAllLayersDoneEvent: BehaviorSubject<{ taskId: string }> = new BehaviorSubject({ taskId: '' });
  getLayerDoneEvent: BehaviorSubject<{ taskId: string }> = new BehaviorSubject({ taskId: '' });
  odxImportSequenceFailedEvent: BehaviorSubject<{ taskId: string; errorMsg: string }> = new BehaviorSubject({ taskId: '', errorMsg: '' });
  importLayerDoneEvent: BehaviorSubject<{ taskId: string }> = new BehaviorSubject({ taskId: '' });
  importLayerFailedEvent: BehaviorSubject<{ taskId: string; errorMsg: string }> = new BehaviorSubject({ taskId: '', errorMsg: '' });

  private _hubConnection: HubConnection;

  constructor() {
    this.zone = new NgZone({ enableLongStackTrace: false });
    //this.setupConnection();
  }

  setupConnection(): Promise<void> {
    if (this.connected) {
      return new Promise<void>(() => { });
    }

    this._hubConnection = new HubConnectionBuilder().withUrl(environment.hubUrl + 'specificationStatus', { transport: HttpTransportType.WebSockets }).build();

    this._hubConnection.on('OdxFileCreated', (taskId: string, odxFileId: string) => {
      this.odxFileCreatedEvent.next({ taskId, odxFileId });
    });

    this._hubConnection.on('OdxImportFileFailed', (taskId: string, errorMessage: string) => {
      this.odxImportSequenceFailedEvent.next({ taskId, errorMsg: errorMessage });
    });

    this._hubConnection.on('GetAllLayerDone', (taskId: string) => {
      this.getAllLayersDoneEvent.next({ taskId });
    });

    this._hubConnection.on('GetAllLayerFailed', (taskId: string, errorMessage: string) => {
      this.odxImportSequenceFailedEvent.next({ taskId, errorMsg: errorMessage });
    });

    this._hubConnection.on('GetLayerDone', (taskId: string) => {
      this.getLayerDoneEvent.next({ taskId });
    });

    this._hubConnection.on('GetLayerFailed', (taskId: string, errorMessage: string) => {
      this.odxImportSequenceFailedEvent.next({ taskId, errorMsg: errorMessage });
    });

    this._hubConnection.on('ImportLayerDone', (taskId: string) => {
      this.importLayerDoneEvent.next({ taskId });
    });

    this._hubConnection.on('ImportLayerFailed', (taskId: string, errorMessage: string) => {
      this.importLayerFailedEvent.next({ taskId, errorMsg: errorMessage });
    });

    this._hubConnection.onclose(() => {
      this.connected = false;
    });

    return this._hubConnection
      .start()
      .then(() => {
        this.connected = true;
      })
      .catch(error => console.log('Error with Specification Status Hub. Got error ' + error));
  }
}
