<h1>Precondition Simulation (beta)</h1>

<div class="row">
  <div class="col-md-6">
    <h2>Precondition States</h2>
    <div class="alert alert-info">
      Precondition states can be used to define the allowed states for the execution of a service. There can be any
      number of precondition states for each state chart.
    </div>

    <div *ngFor="let chart of getCharts()">
      <b>{{chart}}</b>
      <ul class="list-inline">
        <li *ngFor="let state of getStates(chart)" (click)="togglePrecondition(chart,state)" class="label" [ngClass]="{'bg-success': isPrecondition(chart,state),'bg-danger': !isPrecondition(chart,state)}">
          {{state}}
        </li>
      </ul>

    </div>

    <button type="button" class="btn btn-sm btn-secondary" data-toggle="modal" data-target="#serviceSelectModal"
      [disabled]="isReadOnly||!canEditCategoryItems()">
      Use Service Preconditions
    </button>

    <app-service-selector [okButtonName]="'Use'" [title]="'Select service to use'" (diagnosticServicesAdded)="onDiagnosticServicesAdded($event)"></app-service-selector>

  </div>
  <div class="col-md-6">
    <h2>Active States</h2>
    <div class="alert alert-info">
      Select the active (current) state of the ECU for each state chart. Default state is the start state. There can be
      only one active state for each state chart.
    </div>
    <div *ngFor="let chart of getCharts()">
      <b>{{chart}}</b>
      <ul class="list-inline">
        <li *ngFor="let state of getStates(chart)" (click)="setActive(chart,state)" class="label" [ngClass]="{'bg-primary': isActive(chart,state),'bg-light': !isActive(chart,state)}">
          {{state}}
        </li>
      </ul>

    </div>
  </div>
</div>

<h2>Precondition Transitions</h2>
<div class="alert alert-info">
  Precondition transitions are the full set of transitions required to meet all preconditions (based on the ECU
  active states). After executing the sequence of transitions the ECU will operate in one of the allowed states for
  each chart.
</div>
<button class="btn btn-primary" (click)="loadTransitions()" [disabled]="!canEditCategoryItems()">Load Transitions</button>
<br />
<br />

<div class="alert alert-success" *ngIf="!transitions || transitions.length === 0">
  No transitions.
</div>

<table class="table" *ngIf="transitions && transitions.length > 0">
  <thead>
    <tr>
      <th>#</th>
      <th>Transition</th>
      <th>Source State</th>
      <th>Target State</th>
      <th>Service To Execute</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let transition of transitions; let i = index">
      <td>{{i}}</td>
      <td>{{transition.shortName}}</td>
      <td>{{transition.sourceState.shortName}}</td>
      <td>{{transition.targetState.shortName}}</td>
      <td [ngClass]="{'danger': transition.services && transition.services.length > 0 && !transition.services[0].resolved, 'success': transition.services && transition.services.length > 0 && transition.services[0].resolved}">{{transition.services.length===0
        ? "??" : transition.services[0].name}}</td>
    </tr>
  </tbody>
</table>
