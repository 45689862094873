<div *ngIf="otherFiles.length > 0">
    <h1>Other referenced shared files</h1>
    <div class="sdds-row">
        <div class="sdds-col-max-1">
        </div>
        <div class="sdds-col-max-5  ml-5">
            <table class="table">
                <thead>
                    <tr>
                        <th>File Type</th>
                        <th>Shared File</th>
                        <th *ngIf="!isReadOnly">Options</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let file of otherFiles let idx=index" [attr.data-index]="idx">
                        <ng-container>
                            <td>
                                Shared File
                            </td>
                            <td>
                                <a [routerLink]="['/content/shared-files']">{{file.name}}</a>
                            </td>
                            <td *ngIf="!isReadOnly">
                                <button  class="curson-icon" (click)="openDialog(file, SharedFileType.Other)" [disabled]="!canEditSCOMMFiles()">
                                    <mat-icon svgIcon="trash" style="color:red; cursor: pointer;"></mat-icon>
                                </button>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
        <div
            class="sdds-col-max-1 sdds-col-xxlg-1 sdds-col-xlg-1 sdds-col-lg-1 sdds-col-md-1 sdds-col-sm-1 sdds-col-xs-1">
        </div>
    </div>
</div>