import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedFile } from 'app/app-model/legacy-file';
import { SpecificationVersion } from 'app/app-model/specification-version';
import { SharedFilesEventsService } from 'app/app-services/shared-files-events.service';
import { SharedFilesService } from 'app/app-services/shared-files.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SharedFileTreeNode } from 'app/modules/shared/components/tree/model/shared-file-tree.node';
import { Tree } from 'app/modules/shared/components/tree/model/tree';
import { TreeService } from 'app/modules/shared/components/tree/service/tree.service';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-file-browser',
  templateUrl: './file-browser.component.html',
  styleUrls: ['./file-browser.component.css']
})
export class FileBrowserComponent implements OnInit {


  @ViewChild('fileReferenceBrowserRef', { static: false }) fileReferenceBrowserRef: ElementRef;

  @Input()
  isAllowed :boolean;

  fileTree: Tree;
  selectedSharedFileSpecification: SharedFile;
  loadedSpecs = 0;
  loading: boolean;
  viewFilterText: boolean;
  sharedFileTag = 'always include';
  referencedDcodeSpecifications: SpecificationVersion[];

  private readonly deleteAlwaysIncludeFileMessage = `You are about to delete an "Always Include" file.
  Deleting "Always Include" files could have side effects if they are expected to be in the AppDatabase. Do you want to proceeed?`;

  constructor(private specificationService: SpecificationService, private router: Router,
    private messageService: MessageService,
    private sharedFilesService: SharedFilesService,
    private sharedFilesEventsService: SharedFilesEventsService
   ) { }


  ngOnInit() {
    this.updateSharedFilesTree();
  }

  buildFileSpecificationsTree(files: SharedFile[]) {
    const treeService = new TreeService();
    this.fileTree = new Tree(treeService);
    files.sort((first, second) => {
      if (first.name < second.name) {
        return -1;
      }
      if (first.name > second.name) {
        return 1;
      }
      return 0;
    }).forEach(file => {
      const treeNode = new SharedFileTreeNode(file,
        treeService,
        this.specificationService,
        this.sharedFilesService,
        (specificationIds => this.showReferencedSpecifications(specificationIds)));
      this.fileTree.addRootNode(treeNode);
    });

    this.fileTree.selectedTreeNodeEventEmitter.subscribe({
      next: (treeNode: any) => {
        if (treeNode) {
          if (this.selectedSharedFileSpecification !== treeNode.specificationFile) {
            this.selectedSharedFileSpecification = treeNode.specificationFile;
            this.sharedFilesEventsService.sharedFileSpecificationSelected.next(this.selectedSharedFileSpecification);
          }
        }
      },

      error: (error) => { }
    });
  }

  filter(text: string): void {
    if (!text) {
      this.fileTree.reset();
      return;
    }

    if (this.sharedFileTag.search(text.trim().toLowerCase()) >= 0) {
      this.fileTree.showSharedFileTreeNodesWithAlwaysInclude();
      this.fileTree.showTreeNodesThatContainsText(text, true);
      this.clearSelections();
    } else {
      if (this.fileTree) {
        this.fileTree.showTreeNodesThatContainsText(text);
        this.clearSelections();
      }
    }
  }

  updateSharedFilesTree() {
    this.loading = true;
    this.sharedFilesService.getSharedFileSpecifications().pipe(tap(files => {
      this.buildFileSpecificationsTree(files);
      this.loading = false;
    }, e => {
      this.loading = false;
      this.messageService.dispatchErrorMessageFromApi(e);
    })).subscribe();
  }

  removeSelectedView() {
    this.hasReferencesToSpecificationVersions().subscribe(hasReferences => {
      if (hasReferences) {
        this.messageService.dispatchErrorMessage('Shared files with references to specification versions cannot be removed.');
      } else {
        if (this.selectedSharedFileSpecification.alwaysInclude && this.showConfirmDeleteAlwaysIncludeFileDialog()) {
          this.deleteSelectedVersion();
        } else if (!this.selectedSharedFileSpecification.alwaysInclude) {
          this.deleteSelectedVersion();
        }
      }
    });
  }

  hasReferencesToSpecificationVersions(): Observable<boolean> {
    return this.sharedFilesService.getDcodeSpecificationsUsedBySharedFile(this.selectedSharedFileSpecification.id).pipe(
      map(versionIds => versionIds.length > 0));
  }

  onFileCreated(createdSharedFileName: string) {
    this.updateSharedFilesTree();
  }

  clearSelections() {
    this.sharedFilesEventsService.sharedFileSpecificationSelected.next(undefined);
  }

  private deleteSelectedVersion() {
    this.sharedFilesService.deleteSharedFileSpecification(this.selectedSharedFileSpecification.id).subscribe(() => {
      this.updateSharedFilesTree();
      this.selectedSharedFileSpecification = null;
      this.sharedFilesEventsService.sharedFileSpecificationSelected.next(this.selectedSharedFileSpecification);
    });
  }

  private showConfirmDeleteAlwaysIncludeFileDialog(): boolean {
    return window.confirm(this.deleteAlwaysIncludeFileMessage);
  }

  private showReferencedSpecifications(specificationIds: number[]) {
    specificationIds.forEach(specificationId => {
      this.referencedDcodeSpecifications = [];
      this.specificationService.getSpecificationVersion(specificationId).subscribe({
        next: (version) => {
          this.referencedDcodeSpecifications.push(version);
          this.loadedSpecs++;
          if (this.loadedSpecs >= specificationIds.length) {
            // Trigger dialog to show referenced specs
            this.fileReferenceBrowserRef.nativeElement.click();
            this.loadedSpecs = 0;
          }
        }
      });
    });
  }
}
