/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum FunctionType {
    Read,
    Write,
    Control,
    Reset,
    RequestSeed,
    SendKey
}
