<app-category-header [text]="'IOLists'"></app-category-header>

<div class="sdds-row">
    <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <!-- [ngClass]="{'loading': !category}"> -->
        <app-category-items *ngIf="category" [specification]="specification"
            [specificationVersion]="specificationVersion" [category]="category"
            [isAllowed]="canEditCategoryItems()"></app-category-items>
    </div>

    <div
        class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
         <div *ngIf="iolistItem"> <!--[ngClass]="{'loading': isLoading}"> -->

            <app-editable-textbox [overrideFont]="true" [text]="iolistItem.name"
                (textEditedEventEmitter)="editingNameDone($event)" enclosingElement="h1"
                [editingNotAllowed]="!isEditingAllowed" [editingNotAllowedReason]="editingNotAllowedReason"
                enclosingElement="h2" [isAllowed]="canEditCategoryItems()">
            </app-editable-textbox>

            <div class="my-10 w-2/5 sdds-on-white-bg">
                <sdds-textarea ngDefaultControl size="md" [(ngModel)]="iolistItem.model.comment" type="text"
                    placeholder="Enter comment" label="Comment" [disabled]="!isEditingAllowed|| !canEditCategoryItems()"
                    (customChange)="this.updateComment()">
                </sdds-textarea>
            </div>

            <app-assigned-ios-viewer (iosChangedEvent)="iosChanged($event)" (ioRemovedEvent)="ioRemoved($event)"
                [ioRelations]="iolistItem.model.listOfIoRelations" [isReadOnly]="!isEditingAllowed">
            </app-assigned-ios-viewer>
        </div>
    </div>
</div>