import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { Encipher } from '../app-model/key/encipher';
import { ApiProxyService } from '../modules/shared/services/api-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class EncipherService {

  constructor(private apiProxy: ApiProxyService) { }

  getEnciphers(): Observable<Encipher[]> {
    return this.apiProxy.get(environment.apiUrl + 'encipher');
  }

  getEncipher(id: number): Observable<Encipher> {
    return this.apiProxy.get(environment.apiUrl + 'encipher/' + id);
  }
}
