import { ValueConstraintItem, ValueConstraintType } from 'app/app-model/diagnostic-service/value.constraint.item.model';
import { RangeData } from 'app/modules/shared/model/service/parameters/range-data';

export class ValueConstraintItemData {
    private _model: ValueConstraintItem;
    private _range: RangeData;

    constructor(valueConstraint: ValueConstraintItem) {
        this.model = valueConstraint;
        this.range = new RangeData(this.model.range);
    }

    public get model(): ValueConstraintItem {
        return this._model;
    }
    public set model(v: ValueConstraintItem) {
        this._model = v;
    }

    public get range(): RangeData {
        return this._range;
    }
    public set range(v: RangeData) {
        this._range = v;
    }

    get type() {
        return ValueConstraintType[this.model.type];
    }
    set type(type: string) {
        this.model.type = ValueConstraintType[type];
    }
}