import { Component, Input, OnInit } from '@angular/core';
import { PathStep, StepType } from 'app/app-model/diagnostic-service/path-step.model';
import {
  ServiceExecutionRequestValue,
} from 'app/app-model/diagnostic-service/sequence-execution-request-parameter-data.model';
import {
  ServiceExecutionResponseValue,
  TargetVariable,
} from 'app/app-model/diagnostic-service/sequence-execution-response-parameter-data.model';
import { ServiceExecutionModel } from 'app/app-model/diagnostic-service/service-execution.model';
import { DiagnosticServiceProvider } from 'app/app-services/diagnostic.service.service';
import { PropertyType } from 'app/modules/shared/model/properties/base-property';
import { ServiceExecutionDirective } from 'app/modules/shared/model/service-execution/service-execution';
import { ServiceExecutionSequence } from 'app/modules/shared/model/service-execution/service-execution-sequence';
import { DiagnosticServiceCategoryItem } from 'app/modules/shared/model/service/diagnostic-service';

import { TypedValueData } from '../../model/service/parameters/typed-value-data';
import { SpecificationService } from 'app/app-services/specification-service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { FunctionBase } from '../function/function-base';

@Component({
  selector: 'app-assigned-services-viewer',
  templateUrl: './assigned-services-viewer.component.html',
  styleUrls: ['./assigned-services-viewer.component.css']
})
export class AssignedServicesViewerComponent {

  @Input()
  propertyType: PropertyType;

  @Input()
  isReadOnly: boolean;

  @Input()
  hideAddButtonWithOneService: boolean;


  private _activefunction: FunctionBase;

  @Input()
  public set activefunction(v: FunctionBase) {
    this._activefunction = v;
  }
  public get activefunction(): FunctionBase {
    return this._activefunction;
  }

  getActiveFunctionName() {
    if (this.activefunction) {
      return (this.activefunction.name.toUpperCase() == 'READ' || this.activefunction.name.toUpperCase() == 'WRITE') ? this.activefunction.name : '';
    } else {
      return '';
    }
  }

  private _serviceExecutionSequence: ServiceExecutionSequence;

  @Input()
  public set serviceExecutionSequence(v: ServiceExecutionSequence) {
    this._serviceExecutionSequence = v;

  }
  public get serviceExecutionSequence(): ServiceExecutionSequence {
    return this._serviceExecutionSequence;
  }

  constructor(private servicesProvider: DiagnosticServiceProvider, 
    private specificationService: SpecificationService,
    private authService:AuthService) { }

  onDiagnosticServicesAdded(addedServices: Array<DiagnosticServiceCategoryItem>) {
    if (!this.serviceExecutionSequence) {
      return;
    }
    if (this.serviceExecutionSequence.serviceExecutions.length >= this.serviceExecutionSequence.maxAllowedServiceExecutions &&
      this.serviceExecutionSequence.maxAllowedServiceExecutions > -1) {
      return;
    }

    addedServices.forEach(item => {
      this.servicesProvider.getItem(this.specificationService.currentSpecificationVersion.id, item.name).subscribe((service) => {

        if (this.serviceExecutionSequence.hasServiceExecutionWithServiceName(service.name)) {
          return;
        }

        const serviceExecutionToAssign = this.createServiceExecution(service);
        this.serviceExecutionSequence.addServiceExecution(serviceExecutionToAssign);
        this.serviceExecutionSequence.notifySequenceChanged();
      });
    });
  }

  /** Creates a new Service Execution item for the specified diagnostic service item. */
  createServiceExecution(diagnosticServiceItem: DiagnosticServiceCategoryItem): ServiceExecutionDirective {
    const serviceExecutionModel = new ServiceExecutionModel();
    serviceExecutionModel.service = diagnosticServiceItem.name;
    diagnosticServiceItem.filteredRequestValueParameters.forEach(requestParamFromService => {
      const pathStep = new PathStep();
      pathStep.step = requestParamFromService.name;
      pathStep.stepType = StepType.StepByName;

      const requestParam = new ServiceExecutionRequestValue();
      requestParam.path = [pathStep];
      requestParam.value = new TypedValueData();

      serviceExecutionModel.requestParameters.push(requestParam);
    });

    diagnosticServiceItem.filteredResponseValueParameters.forEach(responseParamFromService => {
      const pathStep = new PathStep();
      pathStep.step = responseParamFromService.name;
      pathStep.stepType = StepType.StepByName;

      const responseParam = new ServiceExecutionResponseValue();
      responseParam.path = [pathStep];
      responseParam.target = new TargetVariable();

      serviceExecutionModel.responseParameters.push(responseParam);
    });

    const serviceExecution = new ServiceExecutionDirective();
    serviceExecution.diagnosticServiceProvider = this.servicesProvider;
    serviceExecution.diagnosticService = diagnosticServiceItem;
    serviceExecution.model = serviceExecutionModel;

    return serviceExecution;
  }

  get isAddButtonDisabled(): boolean {
    return this.isReadOnly ||
      (this.hideAddButtonWithOneService && this.hasMoreThanOneServiceExecution);
  }

  get hasMoreThanOneServiceExecution() {
    // Service execution with commands are excluded from this check since those are not connected to diagnostic services.
    return this.serviceExecutionSequence &&
      this.serviceExecutionSequence.serviceExecutions.filter(serviceExecution => !serviceExecution.model.command).length > 0;
  }

  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
