<app-category-header [text]="'Security Access'"></app-category-header>

<div class="sdds-row">
    <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <!-- [ngClass]="{'loading': !category}"> -->
        <app-category-items *ngIf="category" [specification]="specification"
            [specificationVersion]="specificationVersion" [category]="category" [dataTarget]="dataTarget"
            [isAllowed]="canEditCategoryItems()">
        </app-category-items>
    </div>

    <div
        class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
         <div *ngIf="securityAccessItem"> <!--[ngClass]="{'loading': isLoading}"> -->
            <h2>General Settings</h2>
            <div class="my-10 w-1/5 sdds-on-white-bg">
                <span slot="sdds-label">Timeout handling</span>
                <sdds-textfield ngDefaultControl size="md" [(ngModel)]="securityAccessItem.model.timeoutHandling"
                    type="text" placeholder="Enter timeout values"
                    [disabled]="!isEditingAllowed || !canEditCategoryItems()"
                    (customChange)="this.updateTimeoutHandling()">
                </sdds-textfield>

            </div>

            <hr style="margin-bottom: 20px;" />
            <app-editable-textbox [text]="securityAccessItem.name" (textEditedEventEmitter)="editingNameDone($event)"
                enclosingElement="h1" [editingNotAllowed]="!isEditingAllowed"
                [editingNotAllowedReason]="editingNotAllowedReason" enclosingElement="h2"
                [isAllowed]="canEditCategoryItems()"></app-editable-textbox>

            <div>
                <div class="sdds-checkbox-item">
                    <input type="checkbox" class="sdds-form-input"
                        [disabled]="!isEditingAllowed||!canEditCategoryItems()"
                        [(ngModel)]="flashDatabaseKeyFilePathChecked" id="flashDatabaseKeyFilePath"
                        [readOnly]="!isEditingAllowed">
                    <label class="sdds-form-label ml-10">Use FlashDatabaseKeyFilePath?</label>
                </div>
                <br />

                <div class="my-10 w-1/5 sdds-on-white-bg">
                    <sdds-textfield *ngIf="flashDatabaseKeyFilePathChecked" ngDefaultControl size="md"
                        [(ngModel)]="securityAccessItem.model.flashDatabaseKeyFilePath" type="text"
                        placeholder="Name of FlashDatabaseKeyFilePath"
                        (customChange)="securityAccessItem.notifyItemChanged()"
                        [disabled]="!isEditingAllowed||!canEditCategoryItems()">
                    </sdds-textfield>
                </div>
            </div>
            <app-function-dashboard [source]="securityAccessItem" *ngIf="securityAccessItem.activeFunction"
                [isReadOnly]="!isEditingAllowed" [hideAddButtonWithOneService]="true"></app-function-dashboard>
        </div>
    </div>
</div>
