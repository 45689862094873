import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-editable-textbox',
  templateUrl: './editable-textbox.component.html',
  styleUrls: ['./editable-textbox.component.css']
})
export class EditableTextboxComponent {

  @Output()
  textEditedEventEmitter: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  isEditable = true;

  @Input()
  onlyPencilClickable = false;

  public get text(): string {
    return this._text;
  }

  @Input()
  public set text(v: string) {
    this._text = v;
    this.isEditing = false;
    this.pendingValue = this._text;
  }

  @Input()
  enclosingElement = 'span';

  @Input()
  editingNotAllowedReason = '';

  @Input()
  editingNotAllowed = false;

  @Input()
  placeHolder = 'Enter text here';

  @Input()
  overrideFont = false;

  @Input()
  isAllowed: boolean;

  mouseHovering: boolean;
  isEditing: boolean;
  pendingValue: string;

  private _text: string;

  constructor() {
    this.isEditing = false;
    this.pendingValue = this.text;
  }

  get hasText() {
    return this.text && this.text !== '';
  }

  public cancel(): void {
    this.isEditing = false;
  }

  public edit(): void {
    this.pendingValue = this.text;
    this.isEditing = true;
  }

  public processChanges(): void {
    if (this.pendingValue !== this.text) {
      this.textEditedEventEmitter.emit(this.pendingValue);
    }
    this.isEditing = false;
  }
}
