<div class="sdds-row">
    <div class="sdds-col-md-12">
        <h4>Affected Category Items</h4>
    </div>

    <div class="sdds-col-md-12 resolve-btn" *ngIf="odxUpdateAffectedCategoryItem.affectedCategoryItems.length > 0">
        <button class="sdds-btn sdds-btn-primary sdds-btn-md" (click)="resolveaffecteditems()" [disabled]="btnDisabled">Resolve Affected Category Items</button>
    </div>

    <div class="sdds-col-md-12" *ngIf="odxUpdateAffectedCategoryItem.affectedCategoryItems.length > 0">
        <p><b>Total affected category items: {{odxUpdateAffectedCategoryItem.affectedCategoryItems.length}}</b></p>
        <p *ngIf="resolveResult.resolved">{{resolveResult.resolved}} out of {{resolveResult.totalAffectedCategoryItem}} affected category items are resolved successfully!</p>
    </div>

    <div class="sdds-col-md-12" *ngIf="odxUpdateAffectedCategoryItem">
        <table class="table">
            <thead>
                <tr>
                    <th>Item Name</th>
                    <th>Category</th>
                    <th>Missing Service in Incoming ODX</th>
                    <th>Service To Resolve With</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="odxUpdateAffectedCategoryItem.affectedCategoryItems.length === 0">
                    <td colspan="4">
                        No affected category items found
                    </td>
                </tr>
                <tr *ngFor="let categoryItem of odxUpdateAffectedCategoryItem.affectedCategoryItems">
                    <td>{{categoryItem.itemName}}</td>
                    <td>{{parseEnum(categoryItem.itemCategory)}}</td>
                    <td>{{categoryItem.lostDiagnosticServiceReference}}</td>
                    <td>{{categoryItem.serviceToResolveWith}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
