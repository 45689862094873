<div class="sdds-row">
  <div class="mt-10 mb-10">
    <div class="sdds-dropdown sdds-dropdown-small sdds-on-white-bg">
      <span class="sdds-dropdown-label-outside"> <b>Enumeration Type </b> </span>
      <select [(ngModel)]="selectedFreezeFrameEnumType" name="freezeframeEnumType"
        [disabled]="isReadOnly||!canEditCategoryItems()" (change)="freezeFrameEnumTypeChanged()">
        <option *ngFor="let freezeFrameEnumType of availableFreezeFrameEnumType" [ngValue]="freezeFrameEnumType">
          {{freezeFrameEnumType}}</option>
      </select>
    </div>
  </div>

  <span class="mt-20 ml-20">
    <button color="primary" aria-label="Add a new enumeration item" #tooltip="matTooltip"
      matTooltip="Add a new enumeration item" mat-mini-fab (click)="addEnumerationItem()" *ngIf="!isReadOnly"
      [disabled]="!canEditCategoryItems()">
      <mat-icon>add</mat-icon>
    </button>
  </span>

  <table *ngIf="hasValueEnumerationEnabled" class="table table-borderless">
    <thead>
      <th scope="col"><small>Ecu Value</small></th>
      <th scope="col"><small>Logical Value</small></th>
      <th scope="col"><small>Name</small></th>
      <th scope="col"></th>
    </thead>
    <tbody>
      <tr *ngFor="let freezeFrameEnumValue of freezeFrameEnumValues; let indexOfItem=index">
        <td><sdds-textfield size="md" type="number" ngDefaultControl [(ngModel)]="freezeFrameEnumValue.ecuValue"
            (customChange)="freezeFrameDefinitionUpdate.next($event)"
            [disabled]="isReadOnly||!canEditCategoryItems()"></sdds-textfield></td>

        <td><sdds-textfield size="md" type="number" ngDefaultControl [(ngModel)]="freezeFrameEnumValue.logicalValue"
            (customChange)="freezeFrameDefinitionUpdate.next($event)"
            [disabled]="isReadOnly||!canEditCategoryItems()"></sdds-textfield></td>

        <td><sdds-textfield size="md" type="text" ngDefaultControl [(ngModel)]="freezeFrameEnumValue.name"
            (customChange)="freezeFrameDefinitionUpdate.next($event)"
            [disabled]="isReadOnly||!canEditCategoryItems()"></sdds-textfield></td>

        <td>
          <button class="sdds-btn sdds-btn-ghost sdds-btn-sm" *ngIf="!isReadOnly" (click)="removeValueEnum(indexOfItem)"
            [disabled]="!canEditCategoryItems( )">
            <mat-icon fontSet="material-icons-outlined" class="md-18">delete_forever</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <table *ngIf="hasIntervalEnumerationEnabled" class="table table-borderless">
    <thead>
      <th scope="col"><small>Lower limit</small></th>
      <th scope="col"><small>Upper limit</small></th>
      <th scope="col"><small>Ecu value to write</small></th>
      <th scope="col"><small>Logical value</small></th>
      <th scope="col"><small>Name</small></th>
      <th scope="col"></th>
    </thead>
    <tbody>
      <tr *ngFor="let freezeFrameEnumInterval of freezeFrameEnumIntervals; let indexOfItem=index">
        <td><input type="number" name="lowerlimit" [(ngModel)]="freezeFrameEnumInterval.lowerlimit"
            (customChange)="freezeFrameDefinitionUpdate.next($event)"
            [disabled]="isReadOnly||!canEditCategoryItems()" /></td>

        <td><input type="number" name="upperLimit" [(ngModel)]="freezeFrameEnumInterval.upperLimit"
            (customChange)="freezeFrameDefinitionUpdate.next($event)"
            [disabled]="isReadOnly||!canEditCategoryItems()" /></td>

        <td><input type="number" name="ecuValueToWrite" [(ngModel)]="freezeFrameEnumInterval.ecuValueToWrite"
            (customChange)="freezeFrameDefinitionUpdate.next($event)"
            [disabled]="isReadOnly||!canEditCategoryItems()" /></td>

        <td><input type="number" name="logicalValue" [(ngModel)]="freezeFrameEnumInterval.logicalValue"
            (customChange)="freezeFrameDefinitionUpdate.next($event)"
            [disabled]="isReadOnly||!canEditCategoryItems()" /></td>

        <td><input type="text" name="intervalName" [(ngModel)]="freezeFrameEnumInterval.name"
            (customChange)="freezeFrameDefinitionUpdate.next($event)"
            [disabled]="isReadOnly||!canEditCategoryItems()" /></td>
        <td>
          <button class="sdds-btn sdds-btn-ghost sdds-btn-sm" *ngIf="!isReadOnly"
            (click)="removeIntervalEnum(indexOfItem)" [disabled]="!canEditCategoryItems()">
            <mat-icon fontSet="material-icons-outlined" class="md-18">delete_forever</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>