<div *ngIf="identificationGroup">
  <div *ngIf="identificationGroupItems.length === 0">None</div>
  <div *ngIf="identificationGroupItems.length > 0">
    <div class="sdds-banner sdds-banner-info">
      <div class="sdds-banner-body">
        The order below should be sorted so that the most general expression is at
        the bottom of the list. The
        <b>ordering</b>
        is done using
        <b>drag and drop</b>.
        <br />
        Only <b>In Work</b> server identifications can be reordered.
      </div>
    </div>
    <br />
    <div *ngIf="showFilter" style="width: 400px;">
      <div class="sdds-dropdown sdds-dropdown-small" >
        <span class="sdds-dropdown-label-outside">Filter</span>
        <select id="filtrationSelection" [(ngModel)]="selectedFilterType" [disabled]="!canEditIdentificationsGroup()">
          <option [ngValue]="0">Show latest and no in work server identifications</option>
        <option [ngValue]="1">Show latest and all in work server identifications</option>
        <!-- <optgroup label="Specific">
          <option *ngFor="let x of inWorkIdentifications" [ngValue]="x">
            Show latest together with in work specification ({{ x }})
          </option>
        </optgroup> -->
        </select>
      </div>
      <br />
      <div class="sdds-on-white-bg">
        <sdds-textfield placeholder="Enter text to filter..." size="md" [value]="filterText" (input)="filterTextChanged.next($event.target.value)" [disabled]="!canEditIdentificationsGroup()">
          <span slot="sdds-label">Filter by text</span>
        </sdds-textfield>
      </div>
    </div>

    <div>
      <app-common-list
        [isDragList]="true"
        [isSelectable]="false"
        [commonListElementSettingsInitiator]="settingsInitiatorForServerIdentifications"
        [list]="filteredIdentificationGroups"
        [itemTemplate]="serverIdentificationTemplate"
        (orderChangedEventEmitter)="orderChanged($event)"
        [refreshEventEmitter]="refreshServerListEventEmitter">
      </app-common-list>
    </div>
  </div>
</div>

<ng-template #serverIdentificationTemplate let-item>
  <div class="flex flex-wrap">
    <div>
      <span
          *ngIf="item.specificationVersion.releaseStatus === 0"
          class="badge badge-pill badge-success releaseBadge sdds-body-02">In work</span>
        <span
          *ngIf="item.specificationVersion.releaseStatus === 1"
          class="badge badge-pill badge-primary releaseBadge sdds-body-02">Released</span>
    </div>

    <div>
      <span class="sdds-headline-07 mr-10 ml-10">Specification:</span>
      <span>{{ item.specificationVersion.specification.name }}-{{item.specificationVersion.name}}</span>
    </div>

    <div>
      <span class="sdds-headline-07 mr-10 ml-10">Server:</span>
      <span><a class="text-blue-600" [routerLink]="['/specification', item.specificationVersion.specification.id, item.specificationVersion.id, 'servers']"
        [queryParams]="{ itemId: item.id }">{{ item.diagnosticFamily }} - {{ item.generation }}</a></span>
    </div>

    <div>
      <span class="sdds-headline-07  mr-10 ml-10">Expression:</span>
      <span>{{ item.formattedExpression }}</span>
    </div>
  </div>

  <!-- <table>
    <tr>
      <td class="text-right"><span class="sdds-headline-07  mr-10">Server:</span></td>
      <td>
        <span class="float-left">{{ item.diagnosticFamily }} - {{ item.generation }}</span>
        <span class="float-left ml-10">
          <a *ngIf="showJumpToGroup" [routerLink]="['/specification', item.specificationVersion.specification.id, item.specificationVersion.id, 'servers']"
              [queryParams]="{ itemId: item.id }">
              <img src="font/iconsrc/link.png" class="navigateToGroup"/>
          </a>
        </span>
        <span class="clear-both"></span>
      </td>
    </tr>
    <tr>
      <td class="text-right"><span class="sdds-headline-07 mr-10">Specification:</span></td>
      <td>
        <span>
          <span>{{ item.specificationVersion.specification.name }}-{{item.specificationVersion.name}}</span>
          <span
            *ngIf="item.specificationVersion.releaseStatus === 0"
            class="badge badge-pill badge-success releaseBadge sdds-body-02">In work</span>
          <span
            *ngIf="item.specificationVersion.releaseStatus === 1"
            class="badge badge-pill badge-primary releaseBadge sdds-body-02">Released</span>
        </span>
      </td>
    </tr>
    <tr>
      <td class="text-right"><span class="sdds-headline-07  mr-10">Expression:</span></td>
      <td>{{ item.formattedExpression }}</td>
    </tr>
  </table> -->
</ng-template>
