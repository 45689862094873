/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';
import { Subject } from 'rxjs';

// eslint-disable-next-line no-shadow
enum ChangeType {
    ParameterPhysicalType,
    ParameterInternalType
}

@Injectable({
    providedIn: 'root'
})
export class ServiceModificationService {

    requestChange: Subject<{ changeType: ChangeType; valueToChange: OdxDataType }> = new Subject();

    public changePhysicalDataType(valueToChange: OdxDataType) {
        this.requestChange.next({ changeType: ChangeType.ParameterPhysicalType, valueToChange });
    }
}

