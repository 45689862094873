import { Component, Input, OnInit } from '@angular/core';
import { SpecificationService } from 'app/app-services/specification-service';
import { DiagnosticServiceTreeNode } from '../../model/diagnostic-service-tree-node';
import { SharedFileVersionTreeNode } from '../../model/shared-file-version-tree-node';
import { SpecificationVersionTreeNode } from '../../model/specification-version-tree-node';
import { StructureSpecificationTreeNode } from '../../model/structure-specification-tree-node';
import { TreeNode } from '../../model/tree-node';
import { TreeNodeService } from 'app/app-services/tree-node.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-node-description',
  templateUrl: './node-description.component.html',
  styleUrls: ['./node-description.component.css']
})

export class NodeDescriptionComponent implements OnInit {
  @Input()
  treeNode: TreeNode;
  releaseCheck: boolean;

  constructor(private specificationService: SpecificationService, private treeNodeService: TreeNodeService, private authService: AuthService) { }

  ngOnInit() {
    this.specificationService.releaseCheckDoneEventEmitter.subscribe(_ => {
      this.releaseCheck = false;
    });
  }

  viewDeleteSpecificationModal(specificationToDelete: StructureSpecificationTreeNode) {
    this.treeNodeService.viewDeleteSpecificationModal.next(specificationToDelete);
  }

  get isSpecificationOrVersionNode() {
    return this.treeNode.isSpecificationVersionTreeNode || this.treeNode.isStructureSpecificationTreeNode;
  }

  get specificationVersionTreeNode(): SpecificationVersionTreeNode {
    return this.treeNode as SpecificationVersionTreeNode;
  }

  get structureSpecificationTreeNode(): StructureSpecificationTreeNode {
    return this.treeNode as StructureSpecificationTreeNode;
  }

  get sharedFileVersionTreeNode(): SharedFileVersionTreeNode {
    return this.treeNode as SharedFileVersionTreeNode;
  }

  get copyServiceEnabled() {
    if (!this.treeNode.isDiagnosticServiceTreeNode) {
      return false;
    }

    const serviceTreeNode = this.treeNode as DiagnosticServiceTreeNode;
    return serviceTreeNode.isDiagnosticServiceTreeNode &&
      serviceTreeNode.isSelected &&
      serviceTreeNode.isInWorkStatus &&
      serviceTreeNode.isServiceFromDbSource;
  }

  release() {
    this.releaseCheck = true;
    this.specificationVersionTreeNode.performReleaseAction();
  }

  canReleaseVersion() {
    return this.authService.canReleaseVersion();
  }

  canCreateDeleteSpecification() {
    return this.authService.canCreateDeleteSpecification();
  }

  canEditSpecificationVersion() {
    return this.authService.CanEditSpecificationVersion();
  }

  canEditDiagnosticService(): boolean {
    return this.authService.canEditDiagnosticService();
  }
}
