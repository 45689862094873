export class IoListModel {
  specificationVersionId: number;
  name: string;
  comment: string;
  listOfIoRelations: IoListIdCategoryItemId[];
}

export class IoListIdCategoryItemId{
  ioName: string;
  ioListName: string;
}
