import { DataLimitType, RangeModel } from 'app/app-model/diagnostic-service/range.model';
import { ValueConstraintItem, ValueConstraintType } from 'app/app-model/diagnostic-service/value.constraint.item.model';
import { ValueConstraint } from 'app/app-model/diagnostic-service/value.constraint.model';
import { RangeData } from 'app/modules/shared/model/service/parameters/range-data';
import { ValueConstraintItemData } from 'app/modules/shared/model/service/parameters/value-contraint-item-data';

import { OdxDataType, TypedValueData } from './typed-value-data';

export class ValueConstraintData {

    private _model: ValueConstraint;
    private _validRangeData: RangeData;
    private _valueConstraintItems: ValueConstraintItemData[];

    constructor(valueConstraint: ValueConstraint) {
        this.model = valueConstraint;
        this.validRangeData = new RangeData(this.model.validRange);
        if (this.model.items) {
            this.updateValueConstriantItems();
        }
    }

    public get model(): ValueConstraint {
        return this._model;
    }
    public set model(v: ValueConstraint) {
        this._model = v;
    }

    public get validRangeData(): RangeData {
        return this._validRangeData;
    }
    public set validRangeData(v: RangeData) {
        this._validRangeData = v;
    }

    public get valueConstraintItems(): ValueConstraintItemData[] {
        return this._valueConstraintItems;
    }
    public set valueConstraintItems(v: ValueConstraintItemData[]) {
        this._valueConstraintItems = v;
    }

    addNewItem(dataType: OdxDataType) {
        const constraintItem = new ValueConstraintItem();
        constraintItem.range = {
            id: 0,
            lowerType: DataLimitType.Closed,
            lowerValue: this.createDefaultValue(dataType),
            upperType: DataLimitType.Closed,
            upperValue: this.createDefaultValue(dataType)
        };
        constraintItem.type = ValueConstraintType.NotValid;

        this.model.items.push(constraintItem);
        this.updateValueConstriantItems();
    }

    addDefaultValidRange(dataType: OdxDataType) {
        const validRangeModel = new RangeModel();
        validRangeModel.id = 0;
        validRangeModel.lowerType = DataLimitType.Infinite;
        validRangeModel.lowerValue = this.createDefaultValue(dataType);
        validRangeModel.upperType = DataLimitType.Infinite;
        validRangeModel.upperValue = this.createDefaultValue(dataType);

        this.model.validRange = validRangeModel;
    }

    removeItem(itemToRemove: ValueConstraintItemData) {
        const indexOfItemToRemove = this.model.items.findIndex(item => item === itemToRemove.model);
        this.model.items.splice(indexOfItemToRemove, 1);
        this.updateValueConstriantItems();
    }

    changeDataType(val: OdxDataType) {
        const targetDataType = val;

        if (this.validRangeData.model && this.validRangeData.model.lowerValue) {
            const sourceDataType = this.validRangeData.model.lowerValue.dataType;
            this.validRangeData.changeDataType(targetDataType);

            if (!this.isSwitchingBetweenNumericalDataTypes(sourceDataType, targetDataType)) {
                this.validRangeData.setRangeDefaultValue(this.createDefaultValue(targetDataType));
            }
        }

        this.valueConstraintItems.forEach(item => {
            const sourceDataType = item.range.model.lowerValue.dataType;
            item.range.changeDataType(targetDataType);

            if (!this.isSwitchingBetweenNumericalDataTypes(sourceDataType, targetDataType)) {
                item.range.setRangeDefaultValue(this.createDefaultValue(targetDataType));
            }
        });
    }

    private isSwitchingBetweenNumericalDataTypes(sourceDataType: OdxDataType, targetDataType: OdxDataType): boolean {
        return this.isNumericDataType(sourceDataType) && this.isNumericDataType(targetDataType);
    }

    private isNumericDataType(dataType: OdxDataType): boolean {
        return dataType === OdxDataType.Float32 ||
            dataType === OdxDataType.Float64 ||
            dataType === OdxDataType.Int32 ||
            dataType === OdxDataType.UInt32;
    }

    private updateValueConstriantItems() {
        this.valueConstraintItems = this.model.items.map(i => new ValueConstraintItemData(i));
    }

    private createDefaultValue(dataType: OdxDataType): TypedValueData {
        switch (dataType) {
            case OdxDataType.UInt32:
            case OdxDataType.Int32:
            case OdxDataType.Float32:
            case OdxDataType.Float64:
                return { data: '0', dataType };
            case OdxDataType.AsciiString:
            case OdxDataType.ByteField:
                return { data: '', dataType };
            default:
                return { data: '', dataType };
        }
    }
}
