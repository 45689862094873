import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { DiagnosticProtocolType, ServerExecutionModeType, SpecificationType } from 'app/app-model/enums';
import { LabelType } from 'app/app-model/label/label-type';
import { OdxFile } from 'app/app-model/odx-import/odx-file';
import { OdxImport } from 'app/app-model/odx-import/odx-import';
import { OdxImportIssue } from 'app/app-model/odx-import/odx-import-issue';
import { ServerIdentificationModel } from 'app/app-model/server-identification/server-identification.model';
import { Specification } from 'app/app-model/specification';
import { SpecificationStatisticItem } from 'app/app-model/specification-statistic-item.model';
import { CategoryItemsService } from 'app/app-services/category-items-service';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { OdxImportViewService } from 'app/app-services/odx-import-view.service';
import { OdxService } from 'app/app-services/odx-service';
import { RbacService } from 'app/app-services/rbac-file-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationVersionValidationService } from 'app/app-services/specification-version-validation.service';
import {
  ServerIdentificationCategoryItemComponent,
} from 'app/data-categories/server-identification/server-identification-category-item';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import {
  MessageTemplateComponent,
} from 'app/modules/shared/components/message-handler/message-templates/message-templates.component';
import { Rbac } from 'app/modules/shared/model/rbac';
import { Category } from 'app/specification-structure/category-items/category.enum';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';

import { SpecificationVersion } from '../app-model/specification-version';
import { MessageService } from '../modules/shared/services/message-service.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-specification-version-details',
  templateUrl: './specification-version-details.component.html',
  styleUrls: ['./specification-version-details.component.css', '../styles/popup.css']
})

export class SpecificationVersionDetailsComponent implements OnInit, OnDestroy {

  odxImportIssues: OdxImportIssue[];
  serverIdentifications: ServerIdentificationModel[] = [];
  selectedSpecificationSubscription: Subscription;
  statisticsSubscription: Subscription;
  issueChangedSubcription: Subscription;
  serverFileInfoSubscription: Subscription;
  importDoneOdxIssuesSubscription: Subscription;
  specificationVersionReleasedSubscription: Subscription;
  diagnosticProtocol: string;
  legacyVersionEnum = SpecificationType;
  dataCategoryStatistics: SpecificationStatisticItem[] = [];
  serverExecutionMode: string;
  errors = 0;
  loadingLabels: boolean;
  loadingOdxFile: boolean;
  odxFilePreview: OdxFile;
  odxFileComplete: OdxFile;
  modalClosing: EventEmitter<void> = new EventEmitter<void>();
  statusMessageToken: MatSnackBarRef<MessageTemplateComponent>;
  dtcExtended: string;
  handleNegativeRes: boolean;
  rbacFile: Rbac;

  private _specificationVersion: SpecificationVersion;
  private _odxFileSubscription: Subscription;
  private _specification: Specification;
  constructor(private messageService: MessageService,
    private specificationService: SpecificationService,
    private categoryItemsService: CategoryItemsService,
    private dataCategories: DataCategoriesService,
    private validationService: SpecificationVersionValidationService,
    private odxService: OdxService,
    private odxImportService: OdxImportViewService,
    private authService: AuthService,
    private rbacService: RbacService
  ) { }

  ngOnInit() {
    this.odxFileComplete = undefined;
    this.specification = this.specificationService.currentSpecification;

    if (this.specification && this.specificationVersion) {
      this.loadServerFileInfo();
    }

    this.subscribeToEvents();
  }

  ngOnDestroy(): void {
    if (this.importDoneOdxIssuesSubscription) {
      this.importDoneOdxIssuesSubscription.unsubscribe();
    }
    if (this.selectedSpecificationSubscription) {
      this.selectedSpecificationSubscription.unsubscribe();
    }
    if (this.statisticsSubscription) {
      this.statisticsSubscription.unsubscribe();
    }
    if (this.serverFileInfoSubscription) {
      this.serverFileInfoSubscription.unsubscribe();
    }
    if (this.issueChangedSubcription) {
      this.issueChangedSubcription.unsubscribe();
    }
    if (this.specificationVersionReleasedSubscription) {
      this.specificationVersionReleasedSubscription.unsubscribe();
    }
  }

  get specificationVersion(): SpecificationVersion {
    return this._specificationVersion;
  }

  @Input()
  set specificationVersion(specificationVersion: SpecificationVersion) {
    this._specificationVersion = specificationVersion;

    if (this._specificationVersion == null || !this._specificationVersion) {
      return;
    }

    if (!this.specificationService.isLegacyVersion) {
      this.loadOdxFile(specificationVersion.id);
    }

    this.specificationService.getDtc(specificationVersion.specification.id, specificationVersion.id).subscribe(res => {
      this.dtcExtended = res;
    });

    this.specificationService.getHandleNegativeResponse(specificationVersion.specification.id, specificationVersion.id)
      .subscribe(res => this.handleNegativeRes = res);

    this.updateDetails();
  }

  get specification(): Specification {
    return this._specification;
  }

  set specification(specification: Specification) {
    this._specification = specification;
  }

  get writtenByText(): string {
    if (this.specificationVersion.commentByUser) {
      return 'Written by ' + this.specificationVersion.commentByUser;
    }
    return '';
  }

  get currentSpecificationVersionIsLegacy(): boolean {
    return this.specificationVersion.specificationType === SpecificationType.Legacy;
  }

  get currentBuildLabels() {
    return this.specificationVersion.labels.filter(l => l.type === LabelType.Build);
  }

  get currentBranchLabels() {
    return this.specificationVersion.labels.filter(l => l.type === LabelType.Branch).sort((l1, l2) => {
      if (l1.name === l2.name) {
        return 0;
      }

      if (l1.name > l2.name) {
        return 1;
      }

      if (l1.name < l2.name) {
        return -1;
      }
    });
  }

  get serverIdentificationsPreview() {
    this.serverIdentifications = [];
    this.dataCategories.getCategory(Category.Servers).items.forEach(item => {
      const serverIdentificationModel = (item as ServerIdentificationCategoryItemComponent).model;
      this.serverIdentifications.push(serverIdentificationModel);
    });
    return this.serverIdentifications.filter(serverId => serverId !== undefined);
  }

  getDataCategoryId(categoryName: string): string {
    if (this.dataCategories.getCategoryByName(categoryName)) {
      return this.dataCategories.getCategoryByName(categoryName).id;
    }
  }

  downloadOdx() {
    this.loadingOdxFile = true;
    this.odxService.getOdxFile(this.odxFilePreview.id).subscribe(odxFile => {
      this.odxFileComplete = odxFile;
      this.saveOdxFileToDisc(this.odxFileComplete);
      this.loadingOdxFile = false;
    }, e => {
      this.loadingOdxFile = false;
      this.messageService.dispatchErrorMessageFromApi(e);
    });
  }

  trackByServerIdentificationId(index: number, item: ServerIdentificationModel) {
    return item.id;
  }

  getSpecificationVersionsReversed(): SpecificationVersion[] {
    if (!this.specification.specificationVersions) {
      return [];
    }

    const versionsCopy = this.specification.specificationVersions.slice();
    const versionsSorted = versionsCopy.sort((ver1, ver2) => {
      if (+ver1.name < +ver2.name) {
        return 1;
      }
      if (+ver1.name > +ver2.name) {
        return -1;
      }
      return 0;
    });
    return versionsSorted;
  }

  updateDtc(newDtc: string) {
    this.specificationService.updateDtc(this.specification.id, newDtc).subscribe(res => {
      this.dtcExtended = res;
    });
  }

  updateHandleNegativeResponse(event: CustomEvent) {    
    this.specificationService.updateHandleNegativeResponse(this.specification.id, event.detail.value)
      .subscribe(res => {
        this.handleNegativeRes = res;
      });
  }

  private subscribeToEvents() {
    this.issueChangedSubcription = this.validationService.issueChangedEventEmitter.subscribe(data => {
      this.errors = data.errors;
    });

    this.importDoneOdxIssuesSubscription = this.odxImportService.model.importDoneOdxIssues.subscribe(
      issues => {
        this.odxImportIssues = issues;
      }
    );

    this.specificationVersionReleasedSubscription = this.specificationService.specificationUpdatedEventEmitter.subscribe(_ => this.loadOdxFile(this.specificationVersion.id))
  }

  private loadOdxFile(specificationVersionId: number) {
    this.odxFilePreview = undefined;
    this.loadingOdxFile = true;

    if (this._odxFileSubscription != null) {
      this._odxFileSubscription.unsubscribe();
    }

    this._odxFileSubscription = this.specificationService.getSpecificationVersionOdxImport(specificationVersionId).subscribe(odxImport => {
      this.whenSpecificationVersionOdxImportLoaded(odxImport);
    }, e => {
      this.loadingOdxFile = false;
      this.messageService.dispatchErrorMessageFromApi(e);
    });
  }

  private loadStatistics() {
    this.statisticsSubscription = this.specificationService
      .getStatistics(this.specificationVersion.id)
      .subscribe(statistics => {
        this.dataCategoryStatistics = statistics;
      });
  }

  private updateDetails() {
    if (this.specificationVersion) {
      this.specification = this.specificationService.currentSpecification;
      this.diagnosticProtocol = DiagnosticProtocolType[this.specification.diagnosticProtocol];
      this.serverExecutionMode = ServerExecutionModeType[this.specification.serverExecutionMode];
      this.serverIdentifications = this.serverIdentificationsPreview;
      if (this._specificationVersion.specificationType !== SpecificationType.Legacy) {
        this.loadOdxFile(this.specificationVersion.id);
      } else {
        this.odxFilePreview = undefined;
      }
      this.loadStatistics();
      this.checkRbacFileConnected();
    }
  }

  private loadPreviewItems() {
    this.categoryItemsService.getPreviewItems(this.specificationVersion.id, Category.Servers).subscribe(items => {
      const category = this.dataCategories.getCategory(Category.Servers);
      category.setItems(items);
    });
  }

  private loadServerFileInfo() {
    this.serverFileInfoSubscription = this.specificationService
      .getServerFileInfo(this.specificationVersion.id)
      .subscribe(fileInfo => {
        this.diagnosticProtocol = fileInfo.diagnosticProtocol;
        this.serverExecutionMode = fileInfo.serverExecutionMode;
      });
  }

  private whenSpecificationVersionOdxImportLoaded(odxImport: OdxImport) {
    this.odxService.getOdxFilePreview(odxImport.odxFileId).subscribe(odxFilePreview => {
      this.odxFilePreview = odxFilePreview;
      this.loadingOdxFile = false;
    },
      e => {
        this.loadingOdxFile = false;
        this.messageService.dispatchErrorMessageFromApi(e);
      });
  }

  private saveOdxFileToDisc(odxFile: OdxFile) {
    FileSaver.saveAs(new Blob([atob(odxFile.data)]), odxFile.fileName);
  }

  canEditCategoryItems() {
    return this.authService.canEditCategoryItems();
  }

  canCreateDeleteSpecification() {
    return this.authService.canCreateDeleteSpecification();
  }

  public checkRbacFileConnected() {
    this.rbacService.getRbacVersionGuid(this.specificationVersion.code).subscribe(data => {
      if (data === undefined) {
        this.rbacFile = undefined;
        return false;
      }
      else {
        this.rbacFile = data;
        return true;
      }
    })
    return false;
  }

  rbaccDetails(rbacGuid: string){
    const baseUrl = `${environment.newUiUrl}rbacc/${rbacGuid}`;
    const queryParams = new URLSearchParams({ mode: 'edit' });
    const fullUrl = `${baseUrl}?${queryParams.toString()}`;
    window.location.href = fullUrl;
  }
}
