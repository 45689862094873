<div *ngIf="!hasLoadedFiles" class="loader-container">
  <div class="loader"></div>
</div>

<div>
  <table>
  <!-- <table [ngClass]="{'loading':!hasLoadedFiles}"> -->
    <ng-container *ngIf="currentSpecification && specificationService.currentSpecificationVersion && isLegacyVersion">
      <tr>
        <td>
          <span class="mt-10">
            <b>Server file: </b>
          </span>
        </td>
        <td>
          <ng-container *ngIf="!hasLegacyServerFile">
            <label class="sdds-btn sdds-btn-primary sdds-btn-sm ml-14">
              Upload file
              <input class="hidden" id="fileAttach" type="file" [disabled]="!isEditingAllowed||!canEditSCOMMFiles()"
                (change)="xmlFileChanged($event)">
            </label>
          </ng-container>
          <b><a *ngIf="hasLegacyServerFile" class="cursor-pointer ml-10"
              (click)="downloadFile(legacyServerFile)">{{legacyServerFile.name}}</a></b>
        </td>
        <td *ngIf="!hasLegacyServerFile">
          <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" (click)="createServerFile()" [disabled]="!canEditSCOMMFiles()">
            Create file in editor
          </button>
        </td>
        <td *ngIf="hasLegacyServerFile && isEditingAllowed">
          <button id="areYouSureModal">
            <mat-icon *ngIf="canEditSCOMMFiles()" svgIcon="trash" style="width:20px;color:red"></mat-icon>
          </button>
          <app-confirm-dialogue [message]="doYouWantToDeleteMessage"
            (yesEventEmitter)="deleteSelectedFile(legacyServerFile)">
          </app-confirm-dialogue>
        </td>
        <td>
          <ng-container *ngIf="hasLegacyServerFile">
            <button class="sdds-btn sdds-btn-secondary sdds-btn-sm ml-10" [ngClass]="{'loading':loading}"
              (click)="openServerFile()" [disabled]="!canEditSCOMMFiles()">
              Open file
            </button>
          </ng-container>
        </td>
      </tr>
    </ng-container>

    <ng-container>
      <tr>
        <td>
          <b [ngClass]="{'hidden': (!keyFile && !isEditingAllowed)}">Keyfile: </b>
        </td>
        <td [ngClass]="{'hidden': (keyFile || !isEditingAllowed)}">

          <ng-container>
            <button (click)="onSelectKeySharedFiles()" class="sdds-btn sdds-btn-primary sdds-btn-sm mb-10 ml-14"
              id="selectKeySharedFilesModal" [disabled]="!canEditSCOMMFiles()">
              Link to shared file <span class='sdds-btn-icon'><i class='sdds-icon scania-external_link'></i></span>
            </button>
          </ng-container>
        </td>
        <div class="mt-5 ml-14" *ngIf="keyFile">
          <a [routerLink]="['/content/shared-files']" [queryParams]="{treeNodeName: keyFile.name}">{{keyFile.name}}</a>
        </div>
        <td>
          <a *ngIf="keyFile && isEditingAllowed" class="cursor-pointer"
            (click)="unlinkSelectedFileSpecification(keyFile, SharedFileType.KeyFile)">
            <mat-icon *ngIf="canEditSCOMMFiles()"svgIcon="trash" style="width:20px; color:red;"></mat-icon>
          </a>
        </td>
      </tr>
      <tr>
        <td>
          <b [ngClass]="{'hidden': (!hasOtherFiles &&!isEditingAllowed)}">Other:</b>
        </td>
        <td [ngClass]="{'hidden': (!isEditingAllowed)}">
          <button (click)="onSelectOtherSharedFiles()" class="sdds-btn sdds-btn-primary sdds-btn-sm ml-14"
            id="selectOtherSharedFilesModal" [disabled]="!canEditSCOMMFiles()">
            Link to shared file <span class='sdds-btn-icon'><i class='sdds-icon scania-external_link'></i></span>
          </button>
        </td>
      </tr>
    </ng-container>
  </table>
</div>

<app-select-shared-files componentId="#selectKeySharedFilesModal" #selectSharedFiles
  (assignSharedFilesEvent)="onAssignSharedFilesEvent($event)"></app-select-shared-files>
<app-select-shared-files componentId="#selectOtherSharedFilesModal" #selectOtherSharedFiles
  (assignSharedFilesEvent)="onAssignSharedFilesEvent($event)"></app-select-shared-files>