import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Keyset } from 'app/app-model/key/keyset';
import { ScommFileType } from 'app/app-model/legacy-file';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { EcuResetService } from 'app/app-services/ecu-reset.service';
import { SecurityAccessService } from 'app/app-services/security-access.service';
import { SharedFilesService } from 'app/app-services/shared-files.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { MessageService } from 'app/modules/shared/services/message-service.service';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';

import { KeysetService } from '../../app-services/keyset.service';
import { CategoryComponentBaseComponent } from '../../modules/shared/model/category-component-base';
import { SecurityAccessCategoryItemDirective } from './security-access-category-item';
import { NavigationService } from 'app/app-services/navigation.service';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-security-access',
  templateUrl: './security-access.component.html',
  styleUrls: ['./security-access.component.css']
})
export class SecurityAccessComponent extends CategoryComponentBaseComponent<any> implements OnInit, OnDestroy {

  securityAccessItem: SecurityAccessCategoryItemDirective;
  dataTarget = '#createSecurityAccessItem';
  referencedKeyset: Keyset;
  isReadonly: true;
  keyFileName = 'Name-Of-Keyfile';
  specificationSubscription: Subscription;
  sharedFilesSubscription: Subscription;

  private _flashDatabaseKeyFilePathChecked: boolean;
  public get flashDatabaseKeyFilePathChecked(): boolean {
    return this._flashDatabaseKeyFilePathChecked;
  }
  public set flashDatabaseKeyFilePathChecked(v: boolean) {
    this._flashDatabaseKeyFilePathChecked = v;
    this.updateFlashDatabaseKeyFilePath();
  }

  constructor(activatedRoute: ActivatedRoute,
    private specificationStatusService: SpecificationStatusService,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    private keysetService: KeysetService,
    private securityAccessService: SecurityAccessService,
    private shareFilesService: SharedFilesService,
    messageService: MessageService, private ecuResetService: EcuResetService,
    navigationService: NavigationService,
    private authService: AuthService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.SecurityAccess, navigationService);
  }

  ngOnInit() {
    this.componentSubscriptions.push(
      this.itemChanged.subscribe(item => {
        this.securityAccessItem = item;
        this.flashDatabaseKeyFilePathChecked = this.selectedSecurityAccessItemHasFlashKeyDefined;
      }));

    this.specificationSubscription = this.specificationService.selectedSpecificationEventEmitter
      .pipe(filter(spec => spec.specificationVersion !== null))
      .subscribe(spec => {
        this.sharedFilesSubscription = this.shareFilesService.getSharedFilesForSpecificationVersion(spec.specificationVersion.id)
          .subscribe(sharedFiles => {
            const keyFile = sharedFiles.find(sharedFile => sharedFile.fileType === ScommFileType.KeyFile);
            if (keyFile) {
              this.keyFileName = keyFile.name;
            }
          });
      });
  }

  ngOnDestroy() {
    if (this.specificationSubscription) {
      this.specificationSubscription.unsubscribe();
    }
    if (this.sharedFilesSubscription) {
      this.sharedFilesSubscription.unsubscribe();
    }
  }

  get selectedSecurityAccessItemHasFlashKeyDefined() {
    return this.securityAccessItem &&
      this.securityAccessItem !== null &&
      this.securityAccessItem.model &&
      this.securityAccessItem.model.flashDatabaseKeyFilePath;
  }

  get isEditingAllowed(): boolean {
    if (this.specificationService.currentSpecificationVersion.releaseStatus === 1) {
      return false;
    }
    return true;
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  editingNameDone(name: string) {
    if (name.trim().length > 0) {
      this.securityAccessItem.setName(name.trim());
    } else {
      this.messageService.dispatchErrorMessage('Security Access name can not be empty!');
    }
  }

  onResetChanged() {
    this.securityAccessItem.notifyItemChanged();
  }

  updateFlashDatabaseKeyFilePath() {
    if (!this.selectedSecurityAccessItemHasFlashKeyDefined) { return; }
    if (!this.flashDatabaseKeyFilePathChecked) {
      this.securityAccessItem.model.flashDatabaseKeyFilePath = '';
      this.securityAccessItem.notifyItemChanged();
    } else if (this.flashDatabaseKeyFilePathChecked && !this.securityAccessItem.model.flashDatabaseKeyFilePath) {
      this.securityAccessItem.model.flashDatabaseKeyFilePath = 'F_' + this.keyFileName;
      this.securityAccessItem.notifyItemChanged();
    }
  }

  updateTimeoutHandling() {
    this.securityAccessItem.notifyItemChanged();
  }

  onSecurityAccessItemApplied(item: { accessMode: string; keyssetId: number }) {
    this.categoryService.createAndAddItemToActiveCategory(item.accessMode, this.specificationService.currentSpecificationVersion.id)
      .subscribe(categoryItem => {
        const createdCategoryItem = categoryItem as SecurityAccessCategoryItemDirective;
        createdCategoryItem.model.keysetId = item.keyssetId;
        createdCategoryItem.notifyItemChanged();

      }, err => {
        this.messageService.dispatchErrorMessageFromApi(err);
      });
  }


  canEditCategoryItems(): boolean {
    return this.authService.canEditCategoryItems();
  }
}
