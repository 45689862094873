import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OperationalDataAxisBreakpointModel, OperationalDataAxisModel } from 'app/app-model/operational-data/operational-data.model';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-breakpoints',
  templateUrl: './breakpoints.component.html',
  styleUrls: ['./breakpoints.component.css']
})
export class BreakpointsComponent {

  @Input()
  axisTag = '';

  @Input()
  isReadOnly: boolean;

  @Input()
  axisModel: OperationalDataAxisModel;

  @Output()
  breakpointChanged: EventEmitter<void> = new EventEmitter();

  constructor( private authService:AuthService) { }

  onBreakpointChange() {
    this.breakpointChanged.next();
  }

  breakpointName(index: number, item: OperationalDataAxisBreakpointModel) {
    return item.value ?? '';
  }

  canEditCategoryItems():boolean{
    return this.authService.canEditCategoryItems();
  }
}
