<app-category-header [text]="'Sessions'"></app-category-header>

<div class="sdds-row">
    <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
        <!-- [ngClass]="{'loading': !category}"> -->
        <app-category-items *ngIf="category" [specification]="specification"
            [specificationVersion]="specificationVersion" [category]="category"
            [isAllowed]="canEditCategoryItems()"></app-category-items>
    </div>

    <div
        class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
         <div *ngIf="sessionItem"> <!--[ngClass]="{'loading': isLoading}"> -->
            <app-editable-textbox [text]="sessionItem.name" (textEditedEventEmitter)="editingNameDone($event)"
                [editingNotAllowed]="!isEditingAllowed" [editingNotAllowedReason]="editingNotAllowedReason"
                enclosingElement="h2" [isAllowed]="canEditCategoryItems()"></app-editable-textbox>

            <div class="sdds-banner">
                <div class="sdds-banner-body">
                    The list bellow shows all diagnostic services that are going to be executed by this Ecu Session.
                </div>
            </div>

            <app-assigned-services-viewer [serviceExecutionSequence]="sessionItem.serviceExecutionSequence"
                [propertyType]="sessionItem.propertyType" [isReadOnly]="!isEditingAllowed"
                [hideAddButtonWithOneService]="true">
            </app-assigned-services-viewer>

            <app-assigned-security-access-items-viewer
                (securityAccessItemsChangedEvent)="availableSecurityAccessItemsChanged($event)"
                (securityAccessRemovedEvent)="availableSecurityAccessItemRemoved($event)"
                [securityAccessItems]="sessionItem.model.availableSecurityAccessModes" [isReadOnly]="!isEditingAllowed">
            </app-assigned-security-access-items-viewer>
        </div>
    </div>
</div>