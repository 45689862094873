<div class="modal fade" id="removeDiagnosticFamilyGenerationConfirm">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Remove the diagnostic family generation</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Are you sure to remove the diagnostic family generation: <b>"{{itemToRemoveName}}" </b> ?</label>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
          <button type="button" class="btn btn-primary" (click)="confirmRemove()" data-dismiss="modal">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>