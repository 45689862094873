<div *ngIf="isLoading" class="loader-container">
    <div class="loader"></div>
</div>

<div class="sdds-row">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <ng-container *ngIf="list">
            <div *ngIf="isDragList" cdkDropList [cdkDropListData]="internalList" class="example-list"
                (cdkDropListDropped)="drop($event)">
                <div [ngClass]="{'selectedItemStyle': item === internalSelectedItem, 'example-box': true}" cdkDrag
                    [ngStyle]="{'height: 0; visibility: hidden; z-index: -1': !item.isVisible}"
                    [cdkDragDisabled]="isDragDisabled(item)" 
                    [cdkDragData]="item" (click)="selectItem(item)" cdkDrag
                    *ngFor="let item of internalList; trackBy: trackItemById">
                    <ng-container
                        *ngTemplateOutlet="itemTemplate ? itemTemplate : defaultItemTemplate; context: { $implicit: item.element, data: data }">
                    </ng-container>
                </div>
            </div>

            <div class="overflow-y-auto max-h-v-50">
                <ul class="list-group list" *ngIf="!bubbleMode && !isDragList && internalList.length > 0">
                    <li #currentListItem tabindex="0" [class.highlighted]="item.highlighted"
                        [class.selectable]="isSelectable" [class.hide]="!item.isVisible" [draggable]="isDraggable"
                        *ngFor="let item of internalList" class="list-group-item link" (dragstart)="drag($event, item)"
                        [class.drag-over]="item.isDragOver" (dragenter)="item.dragEnter($event)"
                        (dragleave)="item.dragLeave()" (dragend)="dragEnd($event)"
                        (drop)="item.dragLeave(); drop($event, item)" [class.active]="internalSelectedItem === item"
                        (click)="selectItem(item)" (keydown)="keydown($event)"
                        [appFocus]="internalSelectedItem === item">
                        <div *ngIf="copyEnabled && item === internalSelectedItem" class="mr-5"
                            (click)="copyItem(item.element)">
                            <i *ngIf="isAllowed" class="material-icons">content_copy</i>
                        </div>
                        <ng-container
                            *ngTemplateOutlet="itemTemplate ? itemTemplate : defaultItemTemplate; context: { $implicit: item.element, data: data }">
                        </ng-container>
                    </li>
                </ul>
            </div>

            <div *ngIf="bubbleMode && !isDragList">
                <button class="sdds-btn sdds-btn-sm mr-5 mb-5"
                    [ngClass]="{'sdds-btn-secondary': internalSelectedItem !== item, 'sdds-btn-primary': internalSelectedItem === item}"
                    *ngFor="let item of internalList" (click)="selectItem(item)">
                    <ng-container
                        *ngTemplateOutlet="itemTemplate ? itemTemplate : defaultItemTemplate; context: { $implicit: item.element, data: data }">
                    </ng-container>
                </button>
            </div>
        </ng-container>

        <ng-template #defaultItemTemplate>
            No template defined!
        </ng-template>
    </div>
</div>
