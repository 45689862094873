import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rbaccFilter' })
export class RbaccFilterPipe implements PipeTransform {

  transform(value: any[], filterText: string, propertyName = ''): any[] {
    if (!value || value.length <= 0) {
      return [];
    }
    if (!filterText || filterText === '') {
      return value;
    }

    const filteredItems = value.filter(item =>
      item.name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.rbacGuid.toLowerCase().includes(filterText.toLowerCase()));

    return filteredItems;
  }
}
