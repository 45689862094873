<div class="mb-20">
    <app-category-header [text]="'Operational Datas'"></app-category-header>
    <div class="mb-16">
        <label class="sdds-btn sdds-btn-primary sdds-btn-sm ml-14"
            [ngClass]="!canEditCategoryItems() ? 'disabled' : ''">
            Import JSON
            <input #importJsonBtn class="hidden" id="fileAttach" type="file"
                [disabled]="!isEditingAllowed || !canEditCategoryItems()" accept=".json"
                (change)="importJSONFile($event)">
        </label>
    </div>
    <div class="sdds-row">
        <div class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
            <!-- [ngClass]="{'loading': !category}"> -->
            <app-category-items *ngIf="category" [specification]="specification"
                [specificationVersion]="specificationVersion" [category]="category"
                [isAllowed]="canEditCategoryItems()"></app-category-items>
        </div>
        <div
            class="sdds-col-max-9 sdds-col-xxlg-9 sdds-col-xlg-9 sdds-col-lg-8 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 mb-16">
            <div *ngIf="operationalDataItem">
                <app-editable-textbox [overrideFont]="true" [text]="operationalDataItem.name"
                    (textEditedEventEmitter)="editingNameDone($event)" enclosingElement="h1"
                    [editingNotAllowed]="!isEditingAllowed" [editingNotAllowedReason]="editingNotAllowedReason"
                    enclosingElement="h2" [isAllowed]="canEditCategoryItems()">
                </app-editable-textbox>

                <app-operational-data-definition [operationalDataItem]="operationalDataItem"
                    (definitionChanged)="operationalDataItem.onOperationalDataDefinitionChanged($event)"
                    [isReadOnly]="!isEditingAllowed"></app-operational-data-definition>
                <br />

                <div *ngIf="operationalDataItem.hasMinimumValidDefinition()">
                    <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start">
                        <mat-tab label="Breakpoints">
                            <div *ngIf="operationalDataItem.hasNoBreakpointsDefinition()" class="sdds-banner my-10">
                                <div class="sdds-banner-body">
                                    This operational data variable has no breakpoints.
                                </div>
                            </div>

                            <div class="sdds-row my-10">
                                <div
                                    class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-">
                                    <app-breakpoints *ngIf="operationalDataItem.model.xAxis" axisTag="X"
                                        [axisModel]="operationalDataItem.model.xAxis"
                                        (breakpointChanged)="operationalDataItem.notifyOperationalDataChanged()"
                                        [isReadOnly]="operationalDataItem.shouldBeReadOnlyForCurrentODType(OperationalDataDefinitionSection.XAxis) || !isEditingAllowed">
                                    </app-breakpoints>
                                </div>
                                <div
                                    class="sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-6">
                                    <app-breakpoints *ngIf="operationalDataItem.model.yAxis" axisTag="Y"
                                        [axisModel]="operationalDataItem.model.yAxis" [isReadOnly]="!isEditingAllowed"
                                        (breakpointChanged)="operationalDataItem.notifyOperationalDataChanged()">
                                    </app-breakpoints>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Data">
                            <div class="my-10">
                                <app-property-table [properties]="operationalDataItem.properties"
                                    [serviceExecutionSequence]="operationalDataItem.serviceExecutionSequence"
                                    [propertyType]="operationalDataItem.propertyType" [isReadOnly]="!isEditingAllowed">
                                </app-property-table>

                                <app-assigned-services-viewer
                                    [serviceExecutionSequence]="operationalDataItem.serviceExecutionSequence"
                                    [propertyType]="operationalDataItem.propertyType" [isReadOnly]="!isEditingAllowed">
                                </app-assigned-services-viewer>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>

                <div class="sdds-banner my-10" *ngIf="!operationalDataItem.hasMinimumValidDefinition()">
                    <div class="sdds-banner-body">
                        Select an operational data variable type above in order to continue.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>