import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CardReaderData, ProcessMessage, SigningMessage, WebSocketStatus } from 'app/app-model/e-id/e-id.model';
import { SpecificationType, OdxFileDialogStep, SocketConnectionState } from 'app/app-model/enums';
import { OdxFile } from 'app/app-model/odx-import/odx-file';
import { EIdService } from 'app/app-services/e-id.service';
import { FileService } from 'app/app-services/file.service';
import { OdxService } from 'app/app-services/odx-service';
import { SpecificationService } from 'app/app-services/specification-service';
import { Observable, Subscription } from 'rxjs';

import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-odx-file-selector-dialog',
  templateUrl: './odx-file-selector-dialog.component.html',
  styleUrls: ['./odx-file-selector-dialog.component.css'],
  providers: [EIdService]
})
export class OdxFileSelectorDialogComponent extends DialogBase implements OnInit, OnDestroy {

  @ViewChild('odxFileSelector', { static: false })
  odxFileSelector: ElementRef;

  @Output()
  applySelection: EventEmitter<OdxFile> = new EventEmitter();

  currentOdxFileDialogStep = OdxFileDialogStep.SelectFile;
  selectedOdxFile: { fileName: string, fileData: File };
  odxFileSignature: string;
  isValidOdxFile: boolean;
  hasValidOdxFileContents: boolean;
  odxFileValidationMessage: string;

  isCardReaderConnected: boolean;
  currentCardState: WebSocketStatus;
  currentSocketState: { state: SocketConnectionState, message: string };
  socketConnectionState = SocketConnectionState;

  cardConnectionSubscription: Subscription;
  cardStateDubscription: Subscription;
  socketStateSubscription: Subscription;
  availableCardsSubscription: Subscription;
  signStatusSubscription: Subscription;

  cardPin: string;
  signResult: { success: boolean, message: SigningMessage };
  signStarted: boolean;
  signCompleted: boolean;

  private _selectedCard: CardReaderData;

  public get selectedCard(): CardReaderData {
    return this._selectedCard;
  }
  public set selectedCard(v: CardReaderData) {
    this._selectedCard = v;
  }

  private _availableCardReaders: CardReaderData[];
  public get availableCardReaders(): CardReaderData[] {
    return this._availableCardReaders;
  }
  public set availableCardReaders(v: CardReaderData[]) {
    this._availableCardReaders = v;
  }

  constructor(private eidService: EIdService,
    private specificationService: SpecificationService,
    private odxService: OdxService,
    private fileService: FileService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToEidServiceEvents();
    this.eidService.initWebSocket();
  }

  ngOnDestroy(): void {
    this.resetDialog();
    this.unsubscribeToEvents();
  }

  get currentSpecificationVersionIsUpgradedType(): boolean {
    return this.specificationService.currentSpecificationVersion.specificationType === SpecificationType.Upgraded;
  }

  get canGoToNext(): boolean {
    if (this.currentOdxFileDialogStep === OdxFileDialogStep.ConfirmSelection) {
      return false;
    }

    if (this.currentOdxFileDialogStep === OdxFileDialogStep.SelectFile &&
      this.selectedOdxFile && this.isValidOdxFile) {
      return true;
    }

    if (this.currentOdxFileDialogStep === OdxFileDialogStep.SignOdx && this.signCompleted && this.signResult.success) {
      return true;
    }

    return false;
  }

  get canGoToPrevious(): boolean {
    return this.currentOdxFileDialogStep === OdxFileDialogStep.SelectFile ? false : true;
  }

  get canConfirmOdxFileSelection(): boolean {
    return this.currentOdxFileDialogStep === OdxFileDialogStep.ConfirmSelection;
  }

  get isShowingSelectOdxFileView(): boolean {
    return this.currentOdxFileDialogStep === OdxFileDialogStep.SelectFile;
  }

  get isShowingSignOdxFileView(): boolean {
    return this.currentOdxFileDialogStep === OdxFileDialogStep.SignOdx;
  }

  get isShowingConfirmOdxFileView(): boolean {
    return this.currentOdxFileDialogStep === OdxFileDialogStep.ConfirmSelection;
  }

  get selectedOdxFileIsUnCompressed(): boolean {
    return !this.selectedOdxFile.fileName.endsWith('pdx');
  }

  get signEnabled() {
    return this.selectedCard && this.cardPin && !this.signStarted;
  }

  get connectedToEidService(): boolean {
    return this.currentSocketState.state === SocketConnectionState.Connected;
  }

  get cardIsInserted(): boolean {
    return this.currentCardState === WebSocketStatus.Inserted;
  }

  get canShowCardSelectorAndSign(): boolean {
    return this.connectedToEidService && this.cardIsInserted;
  }

  signSelectedOdxFile(): void {
    this.signStarted = true;
    const message: ProcessMessage = new ProcessMessage();
    message.command = 'SignHash';
    message.readerName = this.selectedCard.name;
    message.pinCode = this.cardPin;

    this.fileService.getOdxFileContentsAsBase64(this.selectedOdxFile.fileData).subscribe(odxFileAsBase64 => {
      this.eidService.sign(this.selectedCard.email, message, odxFileAsBase64);
    });
  }

  odxFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;

    if (element.files) {
      this.selectedOdxFile = { fileName: element.files[0].name, fileData: element.files[0] };
      this.resetSignatureFields();
      this.validateSelectedOdxFile();
    }
  }

  getContentsFromSelectedOdxFile(file: File): Observable<string> {
    if (this.selectedOdxFileIsUnCompressed) {
      return this.fileService.getOdxFileContentsAsBase64(file);
    }

    return this.fileService.getOdxFileContentsFromPdxAsBase64(file);
  }

  validateSelectedOdxFile(): void {
    this.odxService.validateOdxFile(this.selectedOdxFile).subscribe(validationResult => {
      this.isValidOdxFile = validationResult.isValidFile;
      this.hasValidOdxFileContents = validationResult.isValidContent;
      this.odxFileValidationMessage = validationResult.validationMessage;
    });
  }

  apply() {
    const odxFileToUse = new OdxFile();
    this.fileService.getOdxFileContentsAsBase64(this.selectedOdxFile.fileData).subscribe(base64Data => {
      odxFileToUse.data = base64Data;
      odxFileToUse.fileName = this.selectedOdxFile.fileName;
      odxFileToUse.size = this.selectedOdxFile.fileData.size;
      odxFileToUse.certificate = this.signResult.message.Certificate;
      odxFileToUse.signature = this.signResult.message.Signature;
      this.resetDialog();
      this.applySelection.emit(odxFileToUse);
    });
  }

  previous() {
    if (this.currentOdxFileDialogStep === OdxFileDialogStep.SignOdx) {
      this.currentOdxFileDialogStep = OdxFileDialogStep.SelectFile;
    }
    else if (this.currentOdxFileDialogStep === OdxFileDialogStep.ConfirmSelection) {
      this.currentOdxFileDialogStep = OdxFileDialogStep.SignOdx;
    }
  }

  next() {
    if (this.currentOdxFileDialogStep === OdxFileDialogStep.SelectFile) {
      this.currentOdxFileDialogStep = OdxFileDialogStep.SignOdx;
    }
    else if (this.currentOdxFileDialogStep == OdxFileDialogStep.SignOdx) {
      this.currentOdxFileDialogStep = OdxFileDialogStep.ConfirmSelection;
    }
  }

  cancel() {
    this.resetDialog();
  }

  unsubscribeToEvents() {
    this.cardConnectionSubscription.unsubscribe();
    this.cardStateDubscription.unsubscribe();
    this.socketStateSubscription.unsubscribe();
    this.availableCardsSubscription.unsubscribe();
    this.signStatusSubscription.unsubscribe();
  }

  close() {
  }

  private resetDialog() {
    this.resetSignatureFields();
    this.isValidOdxFile = false;
    this.selectedOdxFile = undefined;
    this.odxFileValidationMessage = undefined;
    this.currentOdxFileDialogStep = OdxFileDialogStep.SelectFile;

    if (this.odxFileSelector) {
      this.odxFileSelector.nativeElement.value = '';
    }
  }

  private resetSignatureFields() {
    this.signStarted = false;
    this.signCompleted = false;
    this.signResult = undefined;
    this.cardPin = undefined;
  }

  private subscribeToEidServiceEvents(): void {
    this.cardConnectionSubscription =
      this.eidService.cardReaderConnectionChanged.subscribe(isCardReaderConnected => this.isCardReaderConnected = isCardReaderConnected);

    this.cardStateDubscription =
      this.eidService.cardStateChanged.subscribe(currentCardState => this.currentCardState = currentCardState);

    this.socketStateSubscription =
      this.eidService.socketConnectionStateChanged.subscribe(currentSocketState => this.currentSocketState = currentSocketState);

    this.availableCardsSubscription =
      this.eidService.availableCardReadersChanged.subscribe(_ => this.updateAvailableCardReaders());

    this.signStatusSubscription =
      this.eidService.signStatusChanged.subscribe(status => {
        this.signResult = status;
        this.signStarted = false;
        this.signCompleted = true;
      });
  }

  private updateAvailableCardReaders(): void {
    this.availableCardReaders = this.eidService.cardInfo();
    if (this.availableCardReaders) {
      this.selectedCard = this.availableCardReaders[0];
    }
  }
}
