import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  OperationalDataDefinitionSection,
  OperationalDataSection,
} from 'app/app-model/operational-data/operational-data-enums';
import { OperationalDataFile } from 'app/app-model/operational-data/operational-data.model';
import { DataCategoriesService } from 'app/app-services/data-categories-service';
import { NavigationService } from 'app/app-services/navigation.service';
import { OperationalDataService } from 'app/app-services/operational.data.service';
import { SpecificationService } from 'app/app-services/specification-service';
import { SpecificationStatusService } from 'app/app-services/specification-status.service';
import { OperationalDataCategoryItem } from 'app/data-categories/operational-data/operational-data-category-item';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { Category } from 'app/specification-structure/category-items/category.enum';
import { CategoryComponentBaseComponent } from '../../modules/shared/model/category-component-base';
import { MessageService } from '../../modules/shared/services/message-service.service';

@Component({
  selector: 'app-operational-data',
  templateUrl: './operational-data.component.html',
  styleUrls: ['./operational-data.component.css']
})
export class OperationalDataComponent extends CategoryComponentBaseComponent<OperationalDataCategoryItem> implements OnInit {
  @ViewChild('importJsonBtn') importJsonBtn: ElementRef;
  operationalDataItem: OperationalDataCategoryItem;
  currentSection: OperationalDataSection = OperationalDataSection.Breakpoints;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OperationalDataSection = OperationalDataSection;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OperationalDataDefinitionSection = OperationalDataDefinitionSection;

  constructor(private specificationStatusService: SpecificationStatusService,
    activatedRoute: ActivatedRoute,
    navigationService: NavigationService,
    specificationService: SpecificationService,
    categoryService: DataCategoriesService,
    messageService: MessageService,
    private operationalDataService: OperationalDataService,
    private authService: AuthService) {
    super(specificationService, activatedRoute, categoryService, messageService, Category.OperationalData, navigationService);
  }

  ngOnInit() {
    this.componentSubscriptions.push(this.itemChanged.subscribe(item => this.operationalDataItem = item));
  }

  editingNameDone(name: string) {
    if (name.trim().length > 0) {
      this.operationalDataItem.setName(name.trim());
    } else {
      this.messageService.dispatchErrorMessage('Operational Data name can not be empty!');
    }
  }

  importJSONFile(event: any) {
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile, 'UTF-8');
    fileReader.onload = () => {
      const file = new OperationalDataFile();
      file.data = btoa(unescape(encodeURIComponent(fileReader.result as string)));

      this.operationalDataService.importJSONFile(this.specificationService.currentSpecificationVersion.id, file).subscribe(_ => {
        this.refreshAllOperationalAndReload();
      });
    };
    this.importJsonBtn.nativeElement.value = '';
  }

  get isEditingAllowed(): boolean {
    return !this.specificationStatusService.isInRelease(this.specificationService.currentSpecificationVersion);
  }

  get editingNotAllowedReason(): string {
    return this.specificationStatusService.notAllowedInReleaseText;
  }

  private refreshAllOperationalAndReload() {
    this.operationalDataService.reset();
    this.categoryService.getCategory(Category.OperationalData).reset();
    this.categoryService.updateCategory(Category.OperationalData, this.specificationService.currentSpecificationVersion).subscribe(_ => {
      this.navigationService.navigateToFirstAvailableItem(this.category.category);
    });
  }

  canEditCategoryItems(): boolean {
    return this.authService.canEditCategoryItems();
  }
}

