<ng-container *ngIf="category">
    <app-toolbar *ngIf="toolbarEnabled" #apptoolbar filterEnabled="true" [removeDataTargetEnabled]="canRemove  && category.activeItem"
        [addDataTarget]="dataTarget" [addDataTargetEnabled]="canAdd && !category.addWithoutPromptEnabled"
        (filterTextEventEmitter)="filter($event);" [addNotAllowed]="!isEditingAllowed" [dataTargetEnabledAdd]="true"
        [addNotAllowedReason]="editingNotAllowedReason" [removeNotAllowed]="!isEditingAllowed"
        [removeNotAllowedReason]="editingNotAllowedReason"
        [addEventEmitterEnabled]="canAdd && category.addWithoutPromptEnabled" (addEventEmitter)="createItem('')"
        [createItemId]="'createItemModal'" [removeItemId]="'removeItemConfirmModal'"
        [isAllowed]="canEditCategoryItems()"></app-toolbar>

    <app-remove-item-confirm (removeConfirm)="removeSelectedItem()"
        [itemToRemoveName]="category.activeItem ? category.activeItem.name: ''"></app-remove-item-confirm>

    <app-create-item (applyChangesEventEmitter)="createItem($event)"></app-create-item>

    <app-common-list [list]="category.items | categoryItemFilter: filterText"
        [itemTemplate]="showHexcode ? categoryItemTemplateWithHexcode : categoryItemTemplate"
        (selectedItemEventEmitter)="itemSelected($event);" [selectedItem]="category.activeItem"></app-common-list>

    <ng-content></ng-content>

    <ng-template #categoryItemTemplate let-item>
        <div>{{item.name ? item.name : item.displayName}}</div>
    </ng-template>

    <ng-template #categoryItemTemplateWithHexcode let-item>
        <div *ngIf="item.ecuIdentifierHexCodePadded">{{'('+item.ecuIdentifierHexCodePadded+') '+item.name}}</div>
        <div *ngIf="!item.ecuIdentifierHexCodePadded">{{item.name}}</div>
    </ng-template>
</ng-container>