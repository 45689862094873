<b>{{axisTag}}-Axis breakpoints</b>

<table class="table">
  <thead>
    <tr>
      <th class="text-center">Description</th>
      <th class="text-center">Value</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let breakpoint of axisModel.breakpoints; trackBy:breakpointName">
      <td>
        <div class="sdds-on-white-bg">
          <!-- <sdds-textfield ngDefaultControl
                  [(ngModel)]="breakpoint.description"
                  type="text"
                  size="md"
                  placeholder="Enter breakpoint description"
                  [disabled]="isReadOnly"
                  (customChange)="onBreakpointChange()">
          </sdds-textfield> -->
          <input class="sdds-textfield-input-md" [(ngModel)]="breakpoint.description" type="text"
            placeholder="Enter breakpoint description" [disabled]="isReadOnly" (change)="onBreakpointChange()" [readonly]="!canEditCategoryItems()">
        </div>
      </td>
      <td>
        <div class="sdds-on-white-bg">
          <!-- <sdds-textfield ngDefaultControl [(ngModel)]="breakpoint.value" type="number" size="md"
            placeholder="Enter breakpoint description" [disabled]="isReadOnly" (customChange)="onBreakpointChange()">
          </sdds-textfield> -->
          <input class="sdds-textfield-input-md" [(ngModel)]="breakpoint.value" type="number"
            placeholder="Enter breakpoint description" [disabled]="isReadOnly" (change)="onBreakpointChange()" [readonly]="!canEditCategoryItems()">
        </div>
      </td>
    </tr>
  </tbody>
</table>
