<div class="sdds-row mt-16 mb-16">
    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <h3>Available Security Access Modes</h3>
    </div>

    <div
        class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding">
        <button class="sdds-btn sdds-btn-secondary sdds-btn-sm mb-16" id="securityAccessSelectModal"
            [disabled]="isAddButtonDisabled||!canEditCategoryItems()">
            <mat-icon fontSet="material-icons-outlined" class="md-18">add</mat-icon> Add
        </button>
    </div>

    <app-security-access-selector (securityAccessItemsAdded)="onSecurityAccessItemsAdded($event)" [currentSecurityAccessItems]="securityAccessItems"></app-security-access-selector>

    <div class="column" *ngFor="let securityAccess of securityAccessItems">

        <div class="sdds-card sdds-clickable sdds-on-white-bg">
            <div class="sdds-card-body" style="padding:0">
              <div class="flex justify-end">
                <span>
                  <a id="deleteBtn-spec"
                    matTooltip="Disconnect the security access from this item"
                    [ngClass]="{'hidden': isAddButtonDisabled||!canEditCategoryItems()}"
                    class="cursor-pointer ml-12 mt-2"
                    (click)="removeSecurityAccessFromList(securityAccess)">
                    <mat-icon class="text-red-500">close</mat-icon></a>
                </span>
              </div>
              <div class="flex flex-nowrap pr-10 pl-10 pb-10" [ngClass]="{'pt-10': isAddButtonDisabled||!canEditCategoryItems()}">
                <span class="sdds-detail-02 ml-5 mr-5 mt-2">{{securityAccess}}</span>
                <span>
                  <a matTooltip="Navigate to item"
                    [routerLink]="['/specification',specificationId,specificationVersionId,'security-access']"
                    [queryParams]="{itemId: securityAccess}">
                    <mat-icon class="text-gray-600">arrow_forward</mat-icon>
                  </a>
                </span>
              </div>
            </div>
        </div>
    </div>

    <div class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-no-padding"
        *ngIf="!securityAccessItems || securityAccessItems.length <= 0">
        <div class="sdds-banner sdds-banner-info">
            <div class="sdds-banner-body">
                No available security access items were found. Add a new security access item by selecting the <i>Add</i> option above.
            </div>
        </div>
    </div>
</div>
