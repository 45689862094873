import { Specification } from './specification';
import { Label } from './label/label';
import { SpecificationType } from './enums';
import { User } from 'app/modules/shared/model/user';

export class SpecificationVersion {
  id: number;
  code: string;
  name: string;
  comment: string;
  commentByUser: string;
  specificationType: SpecificationType;
  ivdOverrideEnabled: boolean;
  type: number;
  specification: Specification;
  parentId: number;
  parent: SpecificationVersion;
  children: SpecificationVersion[];
  activities: any;
  labels: Label[];
  releaseStatus: number;
  releaseDate: string;
}
