import { OperationalDataAxisType, OperationalDataVariableType } from 'app/app-model/operational-data/operational-data-enums';
import { OperationalDataModel, OperationalDataAxisBreakpointModel, OperationalDataAxisModel } from 'app/app-model/operational-data/operational-data.model';

export class BreakpointGenerator {

  generateBreakpoints(model: OperationalDataModel) {
    if (model.xAxis) {
      // this.assignBreakpointsForAxis(model.xAxis, this.getNumberOfBreakpointsToGenerate(model.type, model.xAxis.type, model.cols));
      this.assignBreakpoints(model.xAxis, model.type, model.cols);
    }

    if (model.yAxis) {
      // this.assignBreakpointsForAxis(model.yAxis, this.getNumberOfBreakpointsToGenerate(model.type, model.yAxis.type, model.rows));
      this.assignBreakpoints(model.yAxis, model.type, model.rows);
    }
  }

  private assignBreakpoints(axis: OperationalDataAxisModel, odType: OperationalDataVariableType, count: number) {
    switch (odType) {
      case OperationalDataVariableType.MinMaxValueCollection:
        axis.breakpoints = [
          { description: 'Min', value: 0 },
          { description: 'Max', value: 1 }
        ];
        break;
      case OperationalDataVariableType.AccumulatedSlidingBarChart:
        this.assignBreakpointsForAxis(axis, odType, this.getNumberOfBreakpointsToGenerate(odType, axis.type, count), true);
        break;
      default:
        this.assignBreakpointsForAxis(axis, odType, this.getNumberOfBreakpointsToGenerate(odType, axis.type, count), false);
        break;
    }
  }

  private assignBreakpointsForAxis(axis: OperationalDataAxisModel, odType: OperationalDataVariableType, numberOfBreakpoints: number, setValueToIndex: boolean) {
    if (axis.breakpoints.length === numberOfBreakpoints) {
      return;
    }

    if (numberOfBreakpoints < axis.breakpoints.length) {
      const numberOfbreakpointsToRemove = axis.breakpoints.length - numberOfBreakpoints;
      const removingStartIndex = axis.breakpoints.length - numberOfbreakpointsToRemove;
      axis.breakpoints.splice(removingStartIndex, numberOfbreakpointsToRemove);
      return;
    }

    const numberOfBreakpointsToCreate = numberOfBreakpoints - axis.breakpoints.length;
    const creatationStartIndex = numberOfBreakpoints - numberOfBreakpointsToCreate;

    for (let i = creatationStartIndex; i < creatationStartIndex + numberOfBreakpointsToCreate; i++) {
      const breakpoint = new OperationalDataAxisBreakpointModel();
      // breakpoint.index = i;
      breakpoint.value = setValueToIndex ? i : 0;
      this.setBreakpointDescription(odType, breakpoint);
      axis.breakpoints.push(breakpoint);
    }
  }

  /**
   * Sets the description of a breakpoint depending on the operational data variable type.
   */
  private setBreakpointDescription(odType: OperationalDataVariableType, breakpoint: OperationalDataAxisBreakpointModel) {
    breakpoint.description = '';
    // switch (odType) {
    //     case OperationalDataVariableType.AccumulatedSlidingBarChart:
    //         // breakpoint.description = breakpoint.index === 0 ? 'CurrentPeriod' : `Period${breakpoint.index}`;
    //         // breakpoint.description = 'Default breakpoint description';
    //         breakpoint.description = '';
    //         break;
    //     default:
    //         breakpoint.description = '';
    //         break;
    // }
  }

  private getNumberOfBreakpointsToGenerate(variableType: OperationalDataVariableType, axisType: OperationalDataAxisType, axisLength: number): number {
    if (variableType === OperationalDataVariableType.AccumulatedScalar ||
      variableType === OperationalDataVariableType.MomentaryScalar) {
      return 0;
    }

    if (axisLength < 1) {
      return 0;
    }

    switch (axisType) {
      case OperationalDataAxisType.Open:
        return axisLength - 1;
      case OperationalDataAxisType.Exact:
      case OperationalDataAxisType.UpwardsOpen:
      case OperationalDataAxisType.DownwardsOpen:
        return axisLength;
      case OperationalDataAxisType.Closed:
        return axisLength + 1;
      default:
        return 0;
    }
  }
}
