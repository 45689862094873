<div class="row">
  <div class="col-md-3" [class.loading]="loading">
    <div style="background-color: #f5f5f5; padding:10px;">
      <h4>Security Access Keysets</h4>
      <app-toolbar [filterEnabled]="true" [addDataTargetEnabled]="true" addDataTarget="#addKeysetModal"
        [removeEventEmitterEnabled]="true" (removeEventEmitter)="removeSelectedKeyset();"
        [dataTargetEnabledRemove]="false" (filterTextEventEmitter)="keysetFilter = $event"></app-toolbar>
      <div *ngIf="keysets && keysets.length ===0" class="alert alert-info">
        <span>Empty.</span>
      </div>
      <div class="content-list-container">
        <app-common-list [list]="keysets | nameFilter: keysetFilter" [itemTemplate]="keysetTemplate"
          (selectedItemEventEmitter)="keysetSelected($event)" [selectedItem]="selectedKeyset"></app-common-list>
      </div>
    </div>
  </div>
    <div class="col-md-9" *ngIf="selectedKeyset">
      <div>
        <app-editable-textbox [overrideFont]="true" [text]="selectedKeyset.name" enclosingElement="h1"
          (textEditedEventEmitter)="keysetNameChanged($event);"></app-editable-textbox>
      </div>
      <div>
        <h4>
          Valid for ECU software generations
        </h4>
        <app-family-generation [canAdd]="true" [familyGenerationPairs]="selectedKeyset.familyGenerationPairs"
          (update)="familyGenerationsUpdated($event);"></app-family-generation>
      </div>
      <div>
        <div>
          <h4>Security access modes</h4>
          <app-toolbar [addDataTargetEnabled]="true" addDataTarget="#addKeysetItemModal"
            [removeEventEmitterEnabled]="true" [dataTargetEnabledRemove]="false"
            (removeEventEmitter)="removeSelectedKeysetItem();"></app-toolbar>
          <div *ngIf="selectedKeyset.items && selectedKeyset.items.length === 0" class="alert alert-info">
            <span>Empty.</span>
          </div>
          <app-common-list [list]="selectedKeyset.items" [itemTemplate]="keysetItemTemplate"
            (selectedItemEventEmitter)="keysetItemSelected($event)" [selectedItem]="selectedKeysetItem">
          </app-common-list>
        </div>
      </div>
</div>


<ng-template #keysetTemplate let-item>
  {{item.name}}
</ng-template>

<ng-template #keysetItemTemplate let-item>
  {{item.name}}
  <table class="default-font-family">
    <tr>
      <td>
        Key:
      </td>
      <td>
        {{item.key.keyValue}}
      </td>
    </tr>
    <tr>
      <td>
        Encipher:
      </td>
      <td>
        {{item.key.encipher.name}}
      </td>
    </tr>
  </table>
</ng-template>

<app-add-keyset (addDoneEventEmitter)="keysetAdded($event);"></app-add-keyset>
<app-add-keyset-item (addDoneEventEmitter)="keysetItemAdded($event);" [selectedKeyset]="selectedKeyset">
</app-add-keyset-item>