import { DiagnosticServiceGroupType } from '../../../../../app-model/enums';
import {
    DiagnosticServiceCategoryItemGroup,
} from 'app/modules/shared/model/service/diagnostic-service-category-item-group';
import { TreeService } from '../service/tree.service';
import { TreeNode } from './tree-node';

export class DiagnosticServiceGroupTreeNode extends TreeNode {
    diagnosticServiceCategoryItemGroup: DiagnosticServiceCategoryItemGroup;

    constructor(diagnosticServiceCategoryItemGroup: DiagnosticServiceCategoryItemGroup, treeService: TreeService) {
        super(diagnosticServiceCategoryItemGroup.name, treeService);
        this.diagnosticServiceCategoryItemGroup = diagnosticServiceCategoryItemGroup;
        this.isDiagnosticServiceTreeNode = true;
    }

    get icon() {
        switch (this.diagnosticServiceCategoryItemGroup.type) {
            case DiagnosticServiceGroupType.SubFunction:
                return 'SF';
            case DiagnosticServiceGroupType.ProtocolService:
                return 'S';
            case DiagnosticServiceGroupType.DataIdentifierType:
                return 'DT';
            case DiagnosticServiceGroupType.DataIdentifiers2Byte:
                return 'ID';
            case DiagnosticServiceGroupType.DataIdentifiers1Byte:
                return 'ID';
        }

        return '';
    }
}
