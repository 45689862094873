import { ServiceExecutionSequenceModel } from 'app/app-model/diagnostic-service/service-execution-sequence.model';

export class EcuIdentifierModel {
  code: number;
  name: string;
  readSequence: ServiceExecutionSequenceModel;
}

export const KWP_IDENTIFIERS: any[] = [
  {
    name: '(0x0087) VehicleManufacturerSparePartNumber',
    identifierName: 'VehicleManufacturerSparePartNumber',
    id: '0x0087'
  },
  {
    name: '(0x0088) VehicleManufacturerEcuSoftwareNumber',
    identifierName: 'VehicleManufacturerEcuSoftwareNumber',
    id: '0x0088'
  },
  {
    name: '(0x0089) VehicleManufacturerEcuSoftwareVersionNumber',
    identifierName: 'VehicleManufacturerEcuSoftwareVersionNumber',
    id: '0x0089'
  },
  {
    name: '(0x008A) SystemSupplier',
    identifierName: 'SystemSupplier',
    id: '0x008A'
  },
  {
    name: '(0x008B) EcuManufacturingDate',
    identifierName: 'EcuManufacturingDate',
    id: '0x008B'
  },
  {
    name: '(0x008C) EcuSerialNumber',
    identifierName: 'EcuSerialNumber',
    id: '0x008C'
  }
];
