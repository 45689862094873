/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */

export class OdxUpdate {
    specificationVersionId: number;
    incomingOdxFile: IncomingOdxFile;
}

export class IncomingOdxFile {
    fileName: string;
    size: number;
    data: string;
}

export class OdxUpdateAffectedCategoryItem {
    affectedCategoryItems: AffectedCategoryItem[];
    odxUpdateResolveResult: OdxUpdateResolveResult;
}

export class AffectedCategoryItem {
    itemName: string;
    itemCategory: CategoryName;
    lostDiagnosticServiceReference: string;
    serviceToResolveWith: string;
}

export class OdxUpdateResolveResult {
    totalAffectedCategoryItem: number;
    resolved: number;
    notResolved: number;
    addedRequestParameterCount: number;
    addedResponseParameterCount: number;
    removedRequestParameterCount: number;
    removedResponseParameterCount: number;
}

export enum CategoryName {
    CategoryItem = 0,
    OperationalDataVariable,
    Io,
    EcuIdentifier,
    Fingerprint,
    EcuValue,
    EcuReset,
    EcuSession,
    SecurityAccessMode
}