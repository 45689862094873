import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DiagnosticProtocolType, ServerExecutionModeType } from 'app/app-model/enums';
import { Specification } from 'app/app-model/specification';
import { CategoryItemsService } from 'app/app-services/category-items-service';
import { SpecificationService } from 'app/app-services/specification-service';

@Component({
  selector: 'app-manage-templates',
  standalone: true,
  templateUrl: './manage-templates.component.html',
  styleUrls: ['./manage-templates.component.css'],
  imports: [CommonModule, MatIconModule, RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ManageTemplatesComponent implements OnInit {

  availableTemplates: Specification[];
  diagnosticProtocolEnum: typeof DiagnosticProtocolType = DiagnosticProtocolType;
  serverExecutionEnum: typeof ServerExecutionModeType = ServerExecutionModeType;

  constructor(
    private specificationService: SpecificationService,
    private categoryItemsService: CategoryItemsService) { }

  ngOnInit(): void {
    this.categoryItemsService.resetCategories();
    this.specificationService.reset();
    this.specificationService.getAlltemplates().subscribe(templates => {
      this.availableTemplates = templates;
    });
  }
}
