import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ParameterService } from 'app/app-services/parameter-service';
import { ParameterData } from 'app/modules/shared/model/service/parameters/parameter-data';
import { OdxDataType } from 'app/modules/shared/model/service/parameters/typed-value-data';

@Component({
  selector: 'app-physical-value',
  templateUrl: './physical-value.component.html',
  styleUrls: ['./physical-value.component.css']
})
export class PhysicalValueComponent {

  @Input()
  isReservedParameter: boolean;

  @Input()
  isValueParameter: boolean;

  @Input()
  isOdxBasedSpecification: boolean;

  @Input()
  parameter: ParameterData;

  @Input()
  isAllowed: boolean;

  @Output()
  parameterChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  physicalDataTypeChanged: EventEmitter<void> = new EventEmitter();

  constructor(private parameterService: ParameterService) { }

  public get physicalValueDataType(): string {
    return this.parameter.getPhysicalDataType();
  }

  public set physicalValueDataType(valueType: string) {
    this.parameter.model.scaling.physicalDataType = OdxDataType[valueType];
    setTimeout(() => {
      // this.onPhysicalDataTyeChanged();
      this.physicalDataTypeChanged.next();
    }, 500);
  }

  public get hasPhysicalValueConstraint(): boolean {
    return this.parameter.scalingData.physicalConstraintData !== undefined;
  }

  public set hasPhysicalValueConstraint(v: boolean) {
    if (v) {
      this.setDefaultPhysicalValueConstraint();
    } else {
      this.parameter.scalingData.physicalConstraintData = undefined;
    }
  }

  public get physicalValueDataTypeEnum(): OdxDataType {
    return this.parameter.scalingData.model.physicalDataType;
  }

  setDefaultPhysicalValueConstraint() {
    const constraintData = this.parameterService.createNewValueConstraint(this.physicalValueDataTypeEnum);
    this.parameter.scalingData.physicalConstraintData = constraintData;
  }
}
