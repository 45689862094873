<sdds-modal size="lg" [attr.selector]="componentId">
  <h5 slot="sdds-modal-headline">Select Scomm Shared Files</h5>
  <div slot="sdds-modal-body">
    <div class="sdds-banner my-10">
      <div class="sdds-banner-body">
        Select which files that should be referenced by this specification. Already assigned files will not be listed in the "Available Files" view. When you are done, press on "Apply Changes" to assign the specified files.
      </div>
    </div>

    <app-shared-files-assigner #sharedFilesAssigner [filterOptions]="filterOptions"></app-shared-files-assigner>

  </div>

  <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-primary sdds-btn-md mr-5" (click)="apply();" [disabled]="!canApply">Apply</button>
  <button slot="sdds-modal-actions" data-dismiss-modal class="sdds-btn sdds-btn-secondary sdds-btn-md" [disabled]="loading">Cancel</button>
</sdds-modal>
